'use strict';

angular.module('pulse')
    .directive('search',
    ['$log', '$signalProvider', '$timeout', '$window', 
        function($log, $signalProvider, $timeout, $window){

            return {
                restrict: 'E',
                transclude: true,
                scope: {
                    searchMetadata: '=',
                    context: '@'
                },
                controller: ['$scope', function($scope){

                }],
                link: function(scope, element, attrs){
                    scope.search = {};
                    scope.search.selected = [];
                    scope.search.viewItems = [];
                    scope.search.processing = false;
                    scope.search.initalize = true;
                    scope.matchSectionStatus = {};
                    scope.updateViewtimeoutId = null;
                    scope.setSearchInputTimeoutId = null;

                    //This checks if the user double clicks to edit a search term
                    /*scope.$watch('search.selected', function(newVal, oldVal){
                      if(newVal !== oldVal){
                        if(newVal.length === oldVal.length){ //If the search text was modified, but nothing was added/removed from the search...
                          scope.selectChange(newVal, true);
                        }
                      }
                    }, true);*/

                    var searchType = { Metadata: 'Metadata', SearchTerm: 'SearchTerm' };
                    function parseSearch(selectedItems){
                        var search = []; //The search values entered
                        var groups = []; //A distinct set of metadata values used in the search
                        var result = []; //The temporary storage where the algorithm does most of its processing
                        var finalSearch = []; //Parses the result into the correct format and concatenates the sub-searches

                        // If no items set the search to nothing
                        if(!selectedItems.length) {
                            return '';
                        }

                        //Step 1) Match up key/value pairs
                        for(var i = 0; i < selectedItems.length; i++){
                            var item = selectedItems[i];

                            //var regexp = new RegExp("");
                            if(!/^[\x20-\x7F]+$/.test(item.name) || item.name.indexOf(';') != -1) { //are there any non-printing chars? ( characters OUTSIDE the range of  'space' (hex 20) to '~' (hex 7e)'. )
                                //search.push({field: item.name});
                                search = [{error: 'Invalid characters in search query.', code: '1'}];
                                item.invalid = true;
                            }


                            //Boolean metadata types don't have an associated search term. If they exist, the value is true.
                            if(item.type === searchType.Metadata && item.isBool === true && !item.invalid){
                                search.push({field: item.name, value: true});
                            }

                            //Regular metadata types, if followed by a search term, are associated to each other. Otherwise, the value doesn't exist for that metadata.
                            if(item.type === searchType.Metadata && item.isBool !== true){ // && !item.invalid
                                var nextItem = selectedItems[i+1];
                                if(nextItem && nextItem.type === searchType.SearchTerm){
                                    delete item.invalid; 
                                    var nextTerm;

                                    if(nextItem.search) {
                                        nextTerm = nextItem.search;
                                    }else{
                                        nextTerm = nextItem.name;

                                        //Remove first and last quotation mark (if applicable)
                                        if(nextTerm.length > 0 && nextTerm[0] === '"'){
                                            nextTerm = nextTerm.substring(1);
                                        }

                                        if(nextTerm.length > 0 && nextTerm[nextTerm.length - 1] === '"'){
                                            nextTerm = nextTerm.substring(0, nextTerm.length - 1);
                                        }
                                    }

                                    search.push({field: item.name, value: nextTerm});
                                    i++;
                                    continue;
                                }
                                else{
                                    //search.push({field: item.name});
                                    search = [{error: 'The field ' + item.name + ' does not have a value. Please modify your search.', code: '1'}];
                                    item.invalid = true;
                                }
                            }

                            //If a search term exists without an associated metadata term, then the "field" is blank.
                            if(item.type === searchType.SearchTerm && !item.invalid){
                                var term;

                                if(item.search) {
                                    term = item.search;
                                }else{
                                    term = item.name;

                                    //Remove first and last quotation mark (if applicable)
                                    if(term.length > 0 && term[0] === '"'){
                                        term = term.substring(1);
                                    }

                                    if(term.length > 0 && term[term.length - 1] === '"'){
                                        term = term.substring(0, term.length - 1);
                                    }

                                }

                                search.push({field: '', value: term});
                            }
                        }

                        //Error...invalid search
                        if(search[0] && search[0].code === "1"){
                          return search;
                        }else{
                          delete item.invalid;
                        }

                        //Step 2) Group key/value pairs by key
                        for(var j = 0; j < search.length; j++){
                          var pair = search[j];
                          if(pair.field !== ''){
                            if(!groups[pair.field]){
                              groups[pair.field] = [];
                            }

                            groups[pair.field].push(pair.value);
                          }else{
                            //wildcard
                            if(!groups['']){
                              groups[''] = [];
                            }
                            groups[''].push(pair.value);
                          }
                        }

                        //Step 3) Generate all combinations
                        for(var key in groups) {
                          //Need to save the length because we will be modifying the result array and don't want the up-to-date value of the length
                          var length = result.length;

                          for(var m = 0; m < groups[key].length; m++){
                            var entity = groups[key][m];

                            if(length > 0){
                              for(var r = 0; r < length; r++){
                                //Iterate over the existing results and create new entries with the current term appended to it
                                //We will keep appending the next item and removing the temporary values from the array
                                //At the end, we will have iterated through all combinations
                                  if(Array.isArray(result[r])){
                                    var tempObject = [];
                                    for(var t = 0; t < result[r].length; t++){
                                      tempObject.push(result[r][t]);
                                    }

                                    tempObject.push({field: key, value: entity});
                                    result.push(tempObject);
                                  }
                                  else{
                                    result.push([result[r], {field: key, value: entity}]);
                                  }
                              }
                            }
                            else{
                              result.push({field: key, value: entity});
                            }
                          }

                          result.splice(0, length);
                        }

                        //Step 4) Format and combine search into one search
                        for(var n = 0; n < result.length; n++){
                          if(n !== 0){
                            finalSearch.push({'plus': true})
                          }

                          if(Array.isArray(result[n])){
                            for(var p = 0; p < result[n].length; p++){
                              if(Array.isArray(result[n][p])){
                                for(var z = 0; z < result[n][p].length; z++){
                                  finalSearch.push(result[n][p][z]);
                                }
                              }
                              else{
                                finalSearch.push(result[n][p]);
                              }
                            }
                          }else{
                            //If only one pair is searched
                            finalSearch.push(result[n]);
                          }
                        }

                        return finalSearch;
                    }

                    /**
                     * sets the tag result in the search bar
                     *
                     * @param selected
                     * @param dirty
                     * @returns {boolean}
                     */
                    scope.selectChange = function(selected, dirty) {
                      // We need this processing flag because an on change event will
                      // happen every time an item is added to the list
                      if(scope.search.processing === false) {
                        $log.info('Done Processing');

                        //This is the initalize of the directive -- dont do anything
                        if(scope.search.initalize) {
                          scope.search.initalize = false;
                          return false;
                        }

                        //If we start typing in something to the search bar and we press tab or enter
                        //we dirtied the prebuild queries
                        if(dirty) {
                          $signalProvider.signal(scope.context+'_dirty', selected);
                        }

                        //I dont want to save the actual query for smart search
                        //we need to return the selected cached version
                        $signalProvider.signal(scope.context+'_cached', selected);

                        //Parse our current array to create a nice search query
                        var search = parseSearch(selected);

                        if(search.length > 0 && search[0].error && search[0].code === '1'){
                          //do nothing, but need to handle this better
                          $signalProvider.signal(scope.context+'_error', search);
                        }else{
                          $signalProvider.signal(scope.context, search);
                        }
                      }
                    };

                    //If you need to set a selected item from outside the directive
                    function setSelected(name, selected) {
                        //Error handling for not having an array
                        if(!angular.isArray(selected)) {
                            $log.error('Need to pass array of objects to search directive');
                            return false;
                        }

                        //Error handling for not having anything in the array
                        if(!selected.length>0) {
                            $log.error('Query array can not be empty');
                            return false;
                        }

                        //Flag processing so no change events will happen
                        scope.search.processing = true;

                        //Find out how many items we are adding
                        for(var i=0;i<selected.length;i++) {

                            //TODO: Find a better way so this isnt so hacky.
                            //Shouldnt need to add the select to smart search but select module requires it
                            scope.searchMetadata.push(selected[i]);
                            scope.search.selected.push(selected[i]);

                        }

                        //TODO: Find a better way so this isnt so hacky.
                        //Will probably have to modify the select module
                        $timeout(function() {

                            // Done Processing
                            scope.search.processing = false;

                            //Remove the fake data from the searchMetadata
                            var searchIndex = scope.searchMetadata.length - selected.length;
                            scope.searchMetadata.splice(searchIndex,selected.length);
                            // Change doesnt run because we need to delete the fake data first
                            //manually run change after everything is done
                            scope.selectChange(scope.search.selected, false);
                        });

                    }

                    //If you need to set a selected item from outside the directive
                    function deleteSearch(name, selected) {

                        //Error handling for not having an array
                        if(!angular.isArray(selected)) {
                            $log.error('Need to pass array of objects to search directive');
                            return false;
                        }

                        //Error handling for not having anything in the array
                        if(!selected.length>0) {
                            $log.error('Query array can not be empty');
                            return false;
                        }

                        //Flag processing so no change events will happen
                        scope.search.processing = true;

                        //Find out how many items we are adding
                        for(var i=0;i<selected.length;i++) {

                            //Find the correct objects
                            var found = scope.search.selected.indexOf(selected[i]);

                            //if found delete them from the array
                            if(found!==-1) {
                                scope.search.selected.splice(found,1);
                            }
                        }

                        //TODO: Find a better way so this isnt so hacky.
                        //Will probably have to modify the select module
                        $timeout(function() {

                            // Done Processing
                            scope.search.processing = false;

                            // Change doesnt run because we need to delete the fake data first
                            //manually run change after everything is done
                            scope.selectChange(scope.search.selected, false);
                        });

                    }

                    //Clear the selected objects from the query builder
                    scope.search.clearSelected = function(name, selected) {
                        scope.search.selected = [];
                        $signalProvider.signal(scope.context+'_dirty', ['clear']); //This is kinda just a hack to make things dirty
                    };

                    function setPlaceholder(name, text) {
                         scope.search.placeholder = text;
                    }

                    function resetSearch(){
                      scope.selectChange(scope.search.selected, true);
                    }
                    
                    function updateView(name, searchInputWidth) {       
                        
                        if (scope.updateViewtimeoutId) {
                            window.clearTimeout(scope.updateViewtimeoutId);
                        }
                        scope.updateViewtimeoutId = window.setTimeout(function () {
                            
                            updateSearchMatchView(false, true);
                            
                        }, 500);
                                                               
                    }
                    
                    function sizeSearchInput() {       
                        
                        if (scope.setSearchInputTimeoutId) {
                            window.clearTimeout(scope.setSearchInputTimeoutId);
                        }
                        scope.setSearchInputTimeoutId = window.setTimeout(function () {
                            
                            var selectMatch = $(element).find('.ui-select-match');
                            var selectMultiple = selectMatch.closest('.ui-select-multiple');  //the outer container of everything in the search bar
                            var selectSearch = $(element).find('.ui-select-search');
                            var elementWidth = $(selectMultiple).width();
                            var matchContainerWidth = selectMatch.width();
                            var controlsWidth = 200;
                            
                            var newWidth = elementWidth -  (matchContainerWidth + controlsWidth);
                            if (newWidth < 300) {
                                newWidth = 300;
                            }
                            
                            selectSearch.css('width', newWidth + "px" );
                            updateView('on window resize');
                            
                        }, 500);
                                                               
                    }
                    
                    /*
                        Update the search match section. Show/hide items as required.
                        trimRight - remove items that do not fit from the right side (the latest items) instead of the oldest items on the left
                        restoreToLatest - restore the view so all latest items are visible (hide any left items that don't fit, in the process)
                     */
                    var updateSearchMatchView = function(trimRight, restoreToLatest) {
                        var selectMatch = $(element).find('.ui-select-match');
                        var selectMultiple = selectMatch.closest('.ui-select-multiple');  //the outer container of everything in the search bar
                        var selectSearch = $(element).find('.ui-select-search');
                        var elementWidth = $(selectMultiple).width();
                        var containerWidth = selectMatch.width();
                        var btnsWidth = 200;
                        var placeholderAndCommandWidth = 500; //space the match section can't use
                        var closeButtonWidth = 50; //the X on each query item

                        if (restoreToLatest) {
                            //show all right-side items that are currently hidden
                            searchMatchResetRight();
                            containerWidth = selectMatch.width();
                        }
                        
                        var childrenWidth=0;                            
                        var children = selectMatch.children(":visible");

                        //adjust oversized item if needed
                        var allChildren =selectMatch.children();
                        if (allChildren && allChildren.length) {
                            var maxWidth = (elementWidth - placeholderAndCommandWidth);
                            for (var i=0; i<allChildren.length; i++) {
                                var latestItem = $(allChildren[i]).find('.ui-select-match-item');                                
                                if (latestItem.parent().width() + closeButtonWidth > maxWidth) {
                                    latestItem.css({
                                        'overflow': 'hidden', 
                                        'text-overflow':'ellipsis',
                                        'display':'inline-block',
                                        'max-width' : parseInt((maxWidth - 80)).toString() + "px"
                                    });
                                    //latestItem.width(maxWidth - 80);

                                    var target = latestItem.children();
                                    var next = target;
                                    while( next.length ) {
                                        target = next;
                                        next = next.children();
                                    }
                                    latestItem.attr('title', target.text());
                                } else {
                                    latestItem.css({
                                        'max-width' : "none"
                                    });
                                }
                            }
                        }

                        children.width(function(i,w){
                                childrenWidth += w; 
                        });
                                                
                        //if ( (containerWidth + placeholderAndCommandWidth) > elementWidth) { 
                        containerWidth = elementWidth - 500;                        
                                                                                    
                        if (childrenWidth > containerWidth + 10) {
                            $log.log('Search Criteria Width Exceeded Available Space in ui-select-match');                                                        
                            if (trimRight) {
                                $log.log('Trimming search criteria on the right');
                                for (var i=children.length-1; i>=0; i--) {
                                    var newChildrenWidth=0;                            
                                    var visibleChildren = selectMatch.children(":visible");
                                    visibleChildren.width(function(i,w){
                                            newChildrenWidth += w;
                                    });
                                    
                                    var node = $(visibleChildren[i]);
                                    if (node && node.css('display') != 'none') {
                                        $log.log('Hiding node ', node);
                                        selectMatch.css('left','10px');
                                        var newWidth = newChildrenWidth - node.width();
                                        selectSearch.css('width', (selectMultiple.width() -  (newWidth + btnsWidth) ) + "px" ); //resize up the search input.
                                        node.css('display', 'none');    
                                        $timeout(function() { selectMatch.css('left','0px'); }, 300);                   
                                        if (newWidth < (containerWidth) || i === 0  ) {
                                            break;
                                        }
                                    }
                                }
                            } else {
                                $log.log('Trimming search criteria on the left');
                                for (var i=0; i<children.length; i++) {
                                    var newChildrenWidth=0;                            
                                    var visibleChildren = selectMatch.children(":visible");
                                    visibleChildren.width(function(i,w){
                                            newChildrenWidth += w;
                                    });
                                    
                                    var node = $(visibleChildren[0]);
                                    if (node && node.css('display') != 'none') {
                                        $log.log('Hiding node ', node);
                                        selectMatch.css('left','-10px');
                                        var newWidth = newChildrenWidth - node.width();
                                        selectSearch.css('width', (selectMultiple.width() -  (newWidth + btnsWidth) ) + "px" ); //resize up the search input.
                                        node.css('display', 'none');    
                                        $timeout(function() { selectMatch.css('left','0px'); }, 300);                   
                                        if (newWidth < (containerWidth) || i === children.length - 1 ) { //stop when it all fits, or when we have only one visible item. 
                                            break;
                                        }
                                    }
                                }                               
                            }
                        }
                        else { //return hidden items if any exist and can fit into current width                            
                            children = selectMatch.children(); //all 
                            if (restoreToLatest) { //only reintroduce right-hidden items if the user didn't just click 'go left / right' buttons. If user clicks a 'go' button only move 1 item at a time.                                
                                for (var i=children.length-1; i>=0; i--) {
                                    var newChildrenWidth=0;                            
                                    var visibleChildren = selectMatch.children(":visible"); 
                                    visibleChildren.width(function(i,w){
                                            newChildrenWidth += w;
                                    });
                                    
                                    var node = $(children[i]);
                                    if (node && node.css('display') === 'none') {                                                                           
                                        var newWidth = newChildrenWidth + node.width();      
                                        var selectMultiple = selectMatch.closest('.ui-select-multiple');                              
                                        if (newWidth > (elementWidth - placeholderAndCommandWidth)) {
                                            break;
                                        }
                                        else {
                                            $log.log('Re-displaying right-hidden node due to available space', node);
                                            selectSearch.css('width', (selectMultiple.width() -  (newWidth + 200) ) + "px" ); //resize down the search input. 
                                            selectMatch.css('left','10px');
                                            node.css('display', 'inline');
                                            $timeout(function() { selectMatch.css('left','0px'); }, 300);  
                                        }
                                    }
                                }
                            } else { //the user was navigating left / right. See if we can restore additional items
                                var hiddenSelector=":hidden";
                                var leftHiddenItems = [];
                                for (var i=0; i<children.length-1; i++) { //get all left hidden items
                                    var node = $(children[i]);
                                    if (node && node.is(hiddenSelector)) { 
                                        leftHiddenItems.push(node);
                                    }
                                    else {
                                        break; 
                                    }
                                }
                            
                                for (var j=leftHiddenItems.length -1; j>=0; j--) {
                                    var newChildrenWidth=0;                            
                                    var visibleChildren = selectMatch.children(":visible"); 
                                    visibleChildren.width(function(i,w){
                                            newChildrenWidth += w;
                                    });                                    
                                    
                                    var newWidth = newChildrenWidth + leftHiddenItems[j].width();      
                                    var selectMultiple = selectMatch.closest('.ui-select-multiple');                              
                                    if (newWidth > (elementWidth - placeholderAndCommandWidth)) {
                                        break;
                                    }
                                    else {
                                        $log.log('Re-displaying left-hidden node due to available space', leftHiddenItems[j]);
                                        selectSearch.css('width', (selectMultiple.width() -  (newWidth + 200) ) + "px" ); //resize down the search input. 
                                        selectMatch.css('left','10px');
                                        leftHiddenItems[j].css('display', 'inline');
                                        $timeout(function() { selectMatch.css('left','0px'); }, 300);  
                                    }
                                }
                            }
                        }
                        
                        //verify that everything fits properly                        
                        if ( (selectSearch.width() + btnsWidth +  selectMatch.width()) >= elementWidth) {
                            selectSearch.css('width',  (elementWidth - (btnsWidth +  selectMatch.width()) ) + "px" );
                        }
                        
                        
                        updateArrowView(); //show/hide the buttons with left/right arrow as needed. Ensure this is the last call of this func.
                    }
                    
                    
                    //show all right-side items that are currently hidden
                    var searchMatchResetRight = function() {
                        $log.log('Resetting Search Match Section (show right-side hidden items)');
                        var selectMatch = $(element).find('.ui-select-match');
                        var children = selectMatch.children(); 
                        for (var i=children.length-1; i>=0; i--) {                            
                            var node = $(children[i]);
                            if (node && node.css('display') === 'none') {
                                $log.log('Restoring node ', node);
                                node.show();                       
                            }
                            else if (i !== children.length - 1) { //exit as soon as first non-hidden item is discovered
                                break;
                            }
                        }
                    }
                    
                    
                    
                    /*
                        Arrow Buttons View Update Logic:
                        - see if there is at least one visible item. If not, simply hide the arrows.
                        - If there is at least one item:
                        -- Check for hidden items to the left of the first visible item. If there is at least one, show the left arrow.
                        -- Check for hidden items to the right of the last visible item. If there is at least one, show the right arrow.
                    */
                    var updateArrowView = function() {                                                              
                        var selectMatch = $(element).find('.ui-select-match');
                        var children = selectMatch.children(); 
                        
                        //no match criteria, just ensure arrows are hidden
                        if (!children || children.length === 0) {                                                        
                            //disable both arrows (color and cursor)
                            setArrowState('both', false);
                        }
                        else {
                            var leftHidden =-1;
                            var firstVisible =-1;
                            var lastVisible =-1;
                            var rightHidden =-1;
                            var visibleSelector = ":visible";
                            for (var i=0; i<children.length; i++) { //find any left hidden items, and the first visible.
                                var child = children[i];                                
                                if ( $(child).is(visibleSelector) ) { //wwe have a visible item
                                    if (firstVisible === -1) { 
                                        firstVisible = i;
                                    }
                                } else  { //we have a  hidden item
                                    if (leftHidden === -1 && firstVisible === -1) {
                                        leftHidden = i;    
                                    }                                    
                                }
                                
                                if (i===0 && firstVisible === i) { //no hidden left items, the first item is visible
                                    break;
                                }
                                
                                if (leftHidden > -1 && firstVisible > -1) { //we have our left hidden and our first visible.
                                    break;
                                }                                                                
                            }
                            
                            for (var i = children.length-1; i>=0; i--) { //find any right hidden items, and the last visible.
                                var child = children[i];
                                if ( $(child).is(visibleSelector) ) { //wwe have a visible item
                                    if (lastVisible === -1) { 
                                        lastVisible = i;
                                    }
                                } else  { //we have a  hidden item
                                    if (rightHidden === -1 && lastVisible === -1) {
                                        rightHidden = i;    
                                    }                                    
                                }
                                
                                if (i === (children.length-1) && lastVisible === i) { //no hidden right items, the last item is visible
                                    break;
                                }
                                
                                if (rightHidden > -1 && lastVisible > -1) { //we have our right hidden and our last visible.
                                    break;
                                }                                                                
                            }
                            
                            if (leftHidden > -1 || rightHidden > -1) { //at least one item on at least one side is hidden                                                                
                                if (leftHidden > -1) {  
                                    //enable left arrow
                                    setArrowState('left', true);
                                }
                                else {                                      
                                    //disable left arrow
                                    setArrowState('left', false);
                                }
                                
                                if (rightHidden > -1) {                                      
                                    //enable right arrow
                                    setArrowState('right', true);
                                }
                                else {  
                                    //disable right arrow
                                    setArrowState('right', false);
                                }
                                   
                            } else {                                
                                //disable both arrows (color and cursor)
                                setArrowState('both', false);
                            }                                                           
                        }
                                                                        
                    }

                    //enable/disable arrow navigation buttons
                    var setArrowState = function(position, enable) {
                        switch (position) {
                            case 'left':
                                var arrowLeft = $(element).find('#arrowLeft');
                                if (enable) {
                                    $(arrowLeft).css( {
                                        'color': 'white',
                                        'cursor': 'pointer'
                                    });
                                    $(arrowLeft).attr('enabled', '1');
                                } else {
                                    $(arrowLeft).css( {
                                        'color': 'darkslategrey',
                                        'cursor': 'not-allowed'
                                    });
                                    $(arrowLeft).attr('enabled', '0');
                                }
                                break;
                            case 'right':
                                var arrowRight = $(element).find('#arrowRight');
                                if (enable) {
                                    $(arrowRight).css( {
                                        'color': 'white',
                                        'cursor': 'pointer'
                                    });
                                    $(arrowRight).attr('enabled', '1');
                                } else {
                                    $(arrowRight).css( {
                                        'color': 'darkslategrey',
                                        'cursor': 'not-allowed'
                                    });
                                    $(arrowRight).attr('enabled', '0');
                                }
                                break;
                            case 'both':                                
                                setArrowState('left', enable);
                                setArrowState('right', enable);
                                break;
                        }
                    }
                    
                    //move into view the first hidden match item on the left (if any)
                    scope.search.moveLeft = function() {
                        var arrowLeft = $(element).find('#arrowLeft');
                        if ( $(arrowLeft).attr('enabled') != "0") {
                            var selectMatch = $(element).find('.ui-select-match');
                            var children = selectMatch.children(); 
                            
                            if (children && children.length) {
                                var visibleSelector = ":visible";
                                var hiddenSelector = ":hidden";
                                var previousChild = null;
                                for (var i=0; i<children.length; i++) { //find any left hidden items, and the first visible.
                                    var child = children[i];                                
                                    if ( $(child).is(visibleSelector) ) { //wwe have a visible item
                                        if (previousChild && $(previousChild).is(hiddenSelector)) { 
                                            $(previousChild).show();                                        
                                            break;
                                        }
                                    }
                                    previousChild = child;
                                }
                            }
                            
                            updateSearchMatchView(true, false);
                        }
                    }
                    
                    
                    //move into view the first hidden match item on the right (if any)
                    scope.search.moveRight = function() {
                        var arrowRight = $(element).find('#arrowRight');
                        if ( $(arrowRight).attr('enabled') != "0") {
                            var selectMatch = $(element).find('.ui-select-match');
                            var children = selectMatch.children(); 
                            
                            if (children && children.length) {
                                var visibleSelector = ":visible";
                                var hiddenSelector = ":hidden";
                                var previousChild = null;
                                for (var i=children.length; i>=0; i--) { //find any right hidden items, and the first visible.
                                    var child = children[i];                                
                                    if ( $(child).is(visibleSelector) ) { //wwe have a visible item
                                        if (previousChild && $(previousChild).is(hiddenSelector)) { 
                                            $(previousChild).show();                                        
                                            break;
                                        }
                                    }
                                    previousChild = child;                                                               
                                }
                            }
                            
                            updateSearchMatchView(false, false);
                        }
                    }

                    //reinitialize on browser window resize
                    angular.element($window).on('resize', function () {
                        try {
                            $timeout(function() {
                                scope.$apply(sizeSearchInput());
                            }); //the resize event is outside of angular domain, so wrapping in $apply. Alternatively use $timeout to initiate a digest...
                        } catch(e) {
                            sizeSearchInput();                            
                        }
                    });

                    //The signal listener -- returns and index so it can be deleted on view destroy.
                    var searchPlaceholder = $signalProvider.listen(scope.context + '_placeholder', setPlaceholder);
                    var currentSearch = $signalProvider.listen(scope.context + '_add', setSelected);
                    var clearSearch = $signalProvider.listen(scope.context + '_clear', scope.search.clearSelected);
                    var removeSearch = $signalProvider.listen(scope.context + '_delete', deleteSearch);
                    var refreshSearch = $signalProvider.listen(scope.context + '_refresh', resetSearch);
                    var refreshSearch = $signalProvider.listen(scope.context + '_searchResize', updateView);

                    scope.$on('$destroy', function(){
                        $log.info('Destroy Search Directive:' + scope.context);
                        $signalProvider.unlisten(scope.context + '_placeholder',searchPlaceholder);
                        $signalProvider.unlisten(scope.context + '_add',currentSearch);
                        $signalProvider.unlisten(scope.context + '_clear',clearSearch);
                        $signalProvider.unlisten(scope.context + '_delete',removeSearch);
                        $signalProvider.unlisten(scope.context + '_refresh',refreshSearch);
                    });

                },
                
                templateUrl: 'views/common/search/search.html',

                /*template:

                    '<div class="clearSearch" ng-if="search.selected.length > 0" ng-click="search.clearSelected()"><span class="icon-x"></span></div>' +
                    '<ui-select multiple ng-model="search.selected" ng-change="selectChange(search.selected, true)">'+
                    '<ui-select-match placeholder="{{search.placeholder}}">{{$item.name}}</ui-select-match> {{search.placeholder}}' +
                    '<ui-select-choices repeat="item in searchMetadata | propsFilter: {name: $select.search}">' +
                    '<div ng-bind-html="item.name | highlight: $select.search"></div>' +
                    '</ui-select-choices>' +
                    '</ui-select>'*/

            };
        }
    ]
);
