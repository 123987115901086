/**
 * @ngdoc object
 * @name ImportsCtrl
 * @requires $scope, $state, $stateParams
 */

'use strict';

angular.module('pulse')
    .controller('ImportsV2Ctrl',
    ['$scope', '$signalProvider', '$stateParams', '$log', '$state', 'Projects',
        function ($scope, $signalProvider, $stateParams, $log, $state, Projects) {

            $log.info('Imports V2 Controller');

            $scope.imports = {};
            $scope.imports.loading = false;

            //Search the groups with the given search params
            function search(name, search){
                $log.info('Searching Users ' + name);
                $scope.imports.loading = true;

                $signalProvider.signal('imports_placeholder', 'Searching...');

                //Query for the count
                var promise = Projects.Project.getImports({
                    projectId: $stateParams.projectID
                }).$promise;

                promise.then(function (imports) {
                  $log.info('Done Searching Imports');
                  $scope.imports.loading = false;
                  $signalProvider.signal('imports_placeholder', imports.length +' imports listed.');
                  $scope.imports.list = imports;
                },
                  function(error) {
                    $log.error('Failed Searching Imports');
                    $log.error(error);
                    $scope.imports.loading = false;
                });
            };

            search('init', ''); // Initialize the search

            // //NOTE: we needed to do this because ui-router doesnt add href tags if drawer is refreshed :/
            $scope.imports.createImport = function() {
              $state.go($state.current.name.replace(/[.]/gi, "/") + '/edit.import', { importID: 'create' });
            }

            // //NOTE: we needed to do this because ui-router doesnt add href tags if drawer is refreshed :/
            $scope.imports.editImport = function(the_import) {
              $state.go($state.current.name.replace(/[.]/gi, "/") + '/edit.import', { importID: the_import.id });
            }

            //listen for when to search
            var currentSearch = $signalProvider.listen('imports', search);
            var editSearch = $signalProvider.listen('import_edited', search);

            //Destroy the function on search
            $scope.$on('$destroy', function(){
                $log.info('Destroy Imports Controller');
                $signalProvider.unlisten('imports', currentSearch);
                $signalProvider.unlisten('import_edited', editSearch);
            });

        }]);
