/**
 * @ngdoc service
 * @name AuthenticationService
 * @module pulse
 * @description
 * `AuthenticationService` authenticates the user or logs them out.
 *
 * @usage
 * login - logs user into the applicaiton
 * logout - logs user out of the applicaiton
 * findMe - finds the user
 *
 */

(function() {
  'use strict';

  angular
    .module('pulse')
    .service('AuthenticationService', AuthenticationService);

  AuthenticationService.$inject = ['$q', '$log', '$rootScope', '$idle', '$window', 'Users', 'Authentication', 'pollingManager'];

  /* @ngInject */
  function AuthenticationService($q, $log, $rootScope, $idle, $window, Users, Authentication, pollingManager) {
    //START REVEALED METHODS
    var service = {
      login: login,
      logout: logout,
      findMe: findMe
    };

    return service;
    //END REVEALED METHODS

    //START METHODDS
    /**
     * @ngdoc function
     * @name login
     * @description This authenticates the user within Pulse database
     * and returns a redirect link to authenticate with OKTA
     */
    function login(form) {

      $log.info('Authenticate User');

      //We need to logout first, this helps with switch user and maybe the user logged into the wrong account.
      //this.logout();

      var result = $q.defer();

      //Add the submitted user name to localStorage so we can use it later OR prepopulate the user name field in case they error
      localStorage.setItem('email_address', form.email_address.$viewValue);

      // The backend is looking for a field named user_name with a string
      var promise = Authentication.checkUser({'email_address': form.email_address.$viewValue}).$promise;
      promise.then(function success(authentication) {

        $log.info('Authentication.checkUser Success', authentication);

        //TODO: Find out what these do
        var lastAttemptedURL = localStorage.getItem('previousAttemptURL');

        //This is the url the person tried to go to but was redirected back
        //TODO: this will go away once we put the login modal on all applications
        if (lastAttemptedURL) {
          localStorage.setItem('previousAttemptURL', lastAttemptedURL);
        }

        //We need to resolve the deffer before we redirect
        result.resolve(authentication);

        //TODO: Give more information about what this is doing
        //TODO: it might be better to put this in an iframe and have the front end redirect when it is ready

        if(!authentication.message) {
          window.location = authentication.redirect; //window change location to no account
        }

      }, function error(error) {
        $log.info('Authentication.checkUser Failure', error);

        //Send the error down the chain
        result.reject(error);

      });

      return result.promise;

    }

    /**
     * @ngdoc function
     * @name logout
     * @description This removes all cache and logs out the user completely from the backend and the front end
     */
    function logout() {

      //No cache makes sure we don't put more data when we leave into localStorage
      $rootScope.nocache = true; // Prevents unload cache from happening within pulse_before_unload.js
      $idle.unwatch(); // Unwatch the idle process

      //Store the users login before we remove all other localStorage
      var email_address = localStorage.getItem('email_address');

      //clear all local storage data
      localStorage.clear();

      //Add the user name back into the localStorage
      localStorage.setItem('email_address',email_address); //Have to save the user login info

      //Kill all pollers
      pollingManager.terminateAllActivePollers();

      //When the user has gone idle and has timeout we log them out with the logout function
      // however we dont want to automatically redirect them until they click logout
      if(!$rootScope.timeout) {
        //TODO: we need to make the logout not redirect at all.
        // we should make this defer to the calling function
        $window.location = config.baseURL + '/logout';// hard redirect to the login page
      }

    }

    /**
     * @ngdoc function
     * @name findMe
     * @description We use this when we init any application to find the user and to set sentry's user
     */
    function findMe() {
      
      var defer = $q.defer();
      var promise = Users.User.findMe().$promise;
      promise.then(function (user) {
        
        // console.log('FIND ME >>>>> ', user);
        //We are allowing this only for builds that have debugging disabled
        if(config.disableDebugging) {}

        window.pulseUser = user;

        //Resolve after sentry sets data
        defer.resolve(user);

      }, function (error) {
        $log.log('Users.User.findMe Failure', error);
        defer.reject(error);
      });

      return defer.promise;

    }

    //END METHODS

  }
})();
