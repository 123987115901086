/**
 * @ngdoc directive
 * @name pulseLogout
 * @module pulse
 * @restrict A
 * @description
 * `pulseLogout` a button to logout users out.
 *
 * @usage
 * <hljs lang="html">
 *     <div pulse-logout></div>
 * </hljs>
 *
 */
(function(){
  'use strict';
  angular
    .module('pulse')
    .directive('pulseLogout', pulseLogout);

  pulseLogout.$inject = ['$log', 'AuthenticationService'];

  function pulseLogout($log, AuthenticationService) {
    var directive = {
      restrict: 'A',
      link: PulseLogoutLink,
      controller: PulseLogoutCtrl
    };
    return directive;

    function PulseLogoutLink(scope, element, attr, ctrl) {
        function logout(name, value) {
            $log.info('Logging out from' + name);
            sessionStorage.setItem('session-expire-type', 'logout');
            AuthenticationService.logout();
        }

        element.bind('click', function(){
            logout('button');
        });

        scope.$on('$destroy', function(){
            $log.info('Destroy Logout Directive');
        });
    }
  }

  PulseLogoutCtrl.$inject = ['$scope', '$rootScope', '$log', 'AuthenticationService'];

  /* @ngInject */
  function PulseLogoutCtrl($scope, $rootScope, $log, AuthenticationService) {}

})();
