/**
 * @ngdoc object
 * @name MainNavigationComponent
 * @requires $state
 * @description A resuable compent for Pulse navigation
 */

(function(){
  'use strict';

  angular.module('pulse').component('pulseMainNav', {
    templateUrl : 'views/common/components/mainnav.html',
    bindings : {'applications': '<',
                'user': '<',
                'projects': '<',
                'applinks': '<',
                'settingsmenuitems': '<'},
    controller : Controller
  });

  Controller.$inject = ['$log', '$state', 'Users'];

  function Controller($log, $state, Users){
    var ctrl = this;

    ctrl.vm = {};

    ctrl.vm.nav = {};

    ctrl.vm.nav.state = $state;

    ctrl.vm.nav.applications = ctrl.applications;

    ctrl.vm.nav.features = {};

    const isImpersonateBanner = localStorage.getItem('toggle-impersonate-banner');

      if (isImpersonateBanner === null) {
          ctrl.vm.nav.showImpersonateBanner = true;
      } else {
          ctrl.vm.nav.showImpersonateBanner = isImpersonateBanner === 'true';
      }

      ctrl.vm.nav.toggleUserBanner = function() {
          ctrl.vm.nav.showImpersonateBanner = !ctrl.vm.nav.showImpersonateBanner;
          localStorage.setItem('toggle-impersonate-banner', ctrl.vm.nav.showImpersonateBanner);
      }


    ctrl.$onInit = function(){
       ctrl.vm.nav.features = (ctrl.user.hasOwnProperty('features') === true) ? ctrl.user.features : {};
    };

    ctrl.vm.nav.saveFileManagerFeature = function (){
      $log.log('saveFileManagerFeature');

      var params = {
          'organizationID': ctrl.user.organization.id,
          'userID': ctrl.user.id
      };

      params.features = {'new_filemanager': false};
      var promise = Users.OrganizationUser.saveUser(params).$promise;
      promise.then(function(user) {
          $log.log('Users.OrganizationUser.saveUser Success', user);
          ctrl.vm.nav.features = user.features;

          // if the old file manager feature is set to false we redirect to the old file manager app
          if(!user.features.new_filemanager) {
              var new_location = window.location.href;
              new_location = new_location.replace('/filemanagerv2/#', '/filemanager/#');
              window.location.href = new_location;
          }
      },
      function(error) {
          $log.log('Users.OrganizationUser.saveUser Error', error);
      });
    };


  }
})();
