/**
 * @ngdoc directive
 * @name pulseInlineTextarea
 * @module pulse
 * @restrict E
 * @description
 * `pulseInlineTextarea` this an textarea that saves on blur if the value changes.
 *
 * @usage
    <pulse-inline-textarea
        model="model.type.priority"
        on-submit="vm.service[init.service].setPriority"
        on-success="vm.service[init.service].success"
        on-error="vm.service[init.service].error"
        item="model"
        placeholder="Enter Text"
    ></pulse-inline-textarea>
 *
 * @param {string|integer} model - (required) This is the value to display and change
 * @param {function} onSubmit - (required) This is the function to be executed when submitted. it need to return a promise to process success and error
 * @param {function} onSuccess - (optional) This is the function to be executed when submitted succeeds.
 * @param {function} onError - (optional) This is the function to be executed when submitted errors.
 * @param {function} onChange - (optional) This is the function to be executed when input value changes.
 * @param {object} item - (optional) the item object that might be changing. This gets passed to the onSuccess funciton
 * @param {string} placeholder - (optional) the placeholder text to display in the input field
 *
 */

(function() {
    'use strict';

    angular
        .module('pulse')
        .directive('pulseInlineTextarea', directive);

    /* @ngInject */

    directive.$inject = ['$log', '$timeout', '$filter'];

    function directive($log, $timeout , $filter) {
        var directive = {
            restrict: 'E',
            templateUrl: function(element, attribute){
                if(typeof attribute.template !== 'undefined'){
                    return attribute.template;
                }else{
                    return 'views/common/textareas/inline_textarea.html';
                }
            },
            scope: {
                model : '=',
                onSubmit : '=',
                onSuccess : '=',
                onError : '=',
                onChange : '=',
                item : '=',
                placeholder: '@',
            },
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attribute) {
            // getting the textarea field in the template. to handle more easily to add event listeners and update the classes.

            scope.sanitizePullNote = function () {
              if (scope.model && scope.model.length > 0) {
                scope.model = $filter('sanitizeText')(scope.model, "<[^<]+?>|[^a-zA-Z0-9.,!?\\s]+");
              }
            }
            var textarea_element = element[0].getElementsByTagName("textarea")[0];
            var current_value = angular.copy(scope.model); // this is to check on save()
            scope.debounce = false;

            // this helps in case the parent also has a click event. this will stop it from been triggered
            element[0].addEventListener('click', function (event) {
                event.stopPropagation();
            }, false);

            // this will execute the function onChange if it exists
            textarea_element.addEventListener('keypress', function(event) {
                if(scope.onChange) {
                    scope.onChange(event);
                }
            }, false);

            // this adds a class to the textarea to show the save was either successfull or failure and removes after
            function updateClassName(className, milliseconds) {
                textarea_element.classList.add(className);
                if(milliseconds) {
                    setTimeout(function() {
                        textarea_element.classList.remove(className);
                    }, milliseconds);
                }
            }

            // this process the submit of the form or on blur from the textarea field
            scope.save = function () {
                if(scope.debounce) return;
                if(!scope.onSubmit) return;

                // checks if the value has changed in order to send the save
                if(current_value !== scope.model) {
                    // this locks the textarea so you cant change anything until the call is proccessed
                    scope.debounce = true;

                    // runs the funciton that was passed and sends the new value and the data you might need to process the submitted
                    var promise = scope.onSubmit(scope.model, scope.item);
                    promise.then(function (item) {
                        // this adds the success class to the textarea to show the save was successfull and removes it 2.5 seconds after
                        updateClassName('success', 2500);

                        // this unlocks the textarea and resets the new current_value and checks if onSuccess function was passed and runs it if it has
                        scope.debounce = false;
                        current_value = scope.model;
                        if(scope.onSuccess) {
                            scope.onSuccess(item);
                        }
                    }, function (error) {
                        // this adds the error class to the textarea to show the save was unsuccessfull and removes it 2.5 seconds after
                        updateClassName('error', 2500);

                        // this unlocks the textarea and resets the model with the old value and checks if onError function was passed and runs it if it has
                        scope.debounce = false;
                        scope.model = current_value;
                        if(scope.onError) {
                            scope.onError(error);
                        }
                    });
                }
            };
        }
    }
})();
