/**
 * @ngdoc directive
 * @name pulseInlineInput
 * @module pulse
 * @restrict E
 * @description
 * `pulseFocusInput` this is a directive used to focus the input text field
 *  
 *
 * @usage
 *  <input type="text" pulse-focus-input>
 *
 *
 */

(function(){

    'use strict';
  
    angular
    .module('pulse')
    .directive('pulseFocusInput', pulseFocusInput);
  
    /* @ngInject */
    pulseFocusInput.$inject = ['$log', '$timeout', '$window'];
  
    function pulseFocusInput($log, $timeout, $window) {

        var directive = {
          restrict: 'EA',
          link: linkFunction
        };
    
        return directive;

        function linkFunction(scope, element, attribute) {
            $log.log('Focus input',element);
            //Events fire too fast and we need a second to focus on the input
             $timeout(function() {
               element.focus();
           }, 100);
        }
    } 
})();
  