'use strict';

angular.module('pulse')
    .filter('userContact', [function() {
            return function(key, value) {

              var array = ['id','organizationID','createdOn','lastUpdatedOn','timezone','isActive'];

              if(!value) {
                return false;
              }

              for(var i = 0, length = array.length; i<length;i++) {
                if(key === array[i]) {
                  return false;
                  break;
                }
              }



              return true;

            };
        }]);
