/**
 * @ngdoc filter
 * @name excludeArticlesSort
 * 
 * @module pulse
 * @description
 * `excludeArticlesSort` This ia a custom filter to exclude "the", "a", or "an" while sorting the projects Alphabetically 
 *
 * @usage
 * ng-repeat="data in data_List | excludeArticlesSort"
 *
 */
'use strict';

angular.module('pulse').
filter('excludeArticlesSort',['$log', function ($log) {
    return function (string) {
       return string.sort(compare);
    };
}]);

var compare = function(param1, param2) {
    var param1_name = param1.name.toLowerCase(),
        param2_name = param2.name.toLowerCase();
        
    param1_name = removeArticles(param1_name);
    param2_name = removeArticles(param2_name);
    
    if (param1_name > param2_name) return 1;
    if (param1_name < param2_name) return -1; 
    return 0;
};

function removeArticles(str) {
    var words = str.split(" ");
    if(words.length <= 1) return str;
    if( words[0] == 'a' || words[0] == 'the' || words[0] == 'an' )
        return words.splice(1).join(" ");
    return str;
}
  