'use strict';

angular.module('pulse')
    .filter('findParentState',
     ['$log',
        function($log) {

        return function fileSize(state){

            if(!angular.isString(state)) {
                $log.error('findParentState must be a pasted a state string');
                return false;
            }

            var splitState = state.split('.');

            if(splitState.length>2) {
               return splitState[2];
            }else{
                $log.error('Can not use parent state with only 2 states');
                return false;
            }

        };
}]);
