/**
 * @ngdoc object
 * @name ServerEditCtrl
 * @requires $scope, $stateParams, signalProvider
 */

'use strict';

angular.module('pulse')
    .controller('ServerEditCtrl',
    ['$scope', '$q', '$state', '$signalProvider', '$stateParams', '$log', '$timeout', '$rootScope', 'modalManager', 'Pools', 'Users', 'Groups', 'pool', 'application', 'storageDevices', 'userGroupSearchMetadata', 'poolTypes', 'poolMembers',
        function ($scope, $q, $state, $signalProvider, $stateParams, $log, $timeout, $rootScope, modalManager, Pools, Users, Groups, pool, application, storageDevices, userGroupSearchMetadata, poolTypes, poolMembers) {

            $log.info('Server Edit Controller');

            $scope.$stateParams = $stateParams;
            $scope.serverSettingsEdit = {};
            $scope.serverSettingsEdit.data = {};

            $scope.serverSettingsEdit.pool = pool;
            $scope.serverSettingsEdit.data.list = [];
            $scope.serverSettingsEdit.data.currentPoolName = pool.name;

            var storage_device_list = [];
            for (var i=0; i<storageDevices.backends.length; i++) {
                storage_device_list[i] = {name: storageDevices.backends[i]};
            }
            $scope.serverSettingsEdit.data.storageDevices = storage_device_list;

            $scope.serverSettingsEdit.data.searchMetadata = userGroupSearchMetadata.metadata;

            $scope.serverSettingsEdit.types = {};
            $scope.serverSettingsEdit.types.users = {};
            $scope.serverSettingsEdit.types.groups = {};

            $scope.serverSettingsEdit.types.users.name = 'Users';
            $scope.serverSettingsEdit.types.groups.name = 'Groups';
            $scope.serverSettingsEdit.loading = true;
            $scope.serverSettingsEdit.saving = false;
            $scope.serverSettingsEdit.deleting = false;


            //  validity flags for form-less input fields
            $scope.serverSettingsEdit.err = {};
            $scope.serverSettingsEdit.err.valid = [
                { 'host'   : null },
                { 'root'   : null },
                { 'port'   : null },
                { 'secure' : null }
            ];
            $scope.serverSettingsEdit.err.invalidText = [
                'Bad host.',
                'Bad root.',
                'You cannot enter a number less than 1 or greater than 65536.',
                'Bad secure.'
            ];
            $scope.serverSettingsEdit.err.getError = function(i) {
                if(i === 'host')    return $scope.serverSettingsEdit.err.invalidText[0];
                if(i === 'root')    return $scope.serverSettingsEdit.err.invalidText[1];
                if(i === 'port')    return $scope.serverSettingsEdit.err.invalidText[2];
                if(i === 'secure')  return $scope.serverSettingsEdit.err.invalidText[3];
                return '';
            };



            //$scope.serverSettingsEdit.types.users.list = projectUsers;
            //$scope.serverSettingsEdit.types.groups.list = projectGroups;

            if(poolMembers.users){
                for(var i = 0; i < poolMembers.users.length; i++){
                    $scope.serverSettingsEdit.data.list.push(poolMembers.users[i].id);
                }
            }

            if(poolMembers.groups){
                for(var j = 0; j < poolMembers.groups.length; j++){
                    $scope.serverSettingsEdit.data.list.push(poolMembers.groups[j].id);
                }
            }

            $scope.serverSettingsEdit.data.updateServerInfo = function(){
                var type = $scope.serverSettingsEdit.pool.type;

                if(type && poolTypes[type.name] && poolTypes[type.name].properties.credential && poolTypes[type.name].properties.credential.properties){
                    var credential = [];
                    var credentialProperties = poolTypes[type.name].properties.credential.properties;

                    for (var credKey in credentialProperties) {
                        if (type.name.toLowerCase() === "aspera" && credKey.toLowerCase() === 'issystem') {
                            //skip isSystem - hardcoding. Remove when implemented on backend.
                        }else if (credentialProperties.hasOwnProperty(credKey)) {
                            credentialProperties[credKey].property_name = credKey;
                            credential.push(credentialProperties[credKey]);
                        }
                    }

                    $scope.serverSettingsEdit.data.credential = credential;
                }
                else{
                    $scope.serverSettingsEdit.data.credential = null;
                }

                if(type && poolTypes[type.name] && poolTypes[type.name].properties.connectionInformation && poolTypes[type.name].properties.connectionInformation.properties){
                    var connectionInformation = [];
                    var connectionInformationProperties = poolTypes[type.name].properties.connectionInformation.properties;

                    for (var connkey in connectionInformationProperties) {
                        if (connectionInformationProperties.hasOwnProperty(connkey)) {
                            connectionInformationProperties[connkey].property_name = connkey;
                            connectionInformation.push(connectionInformationProperties[connkey]);
                        }
                    }

                    $scope.serverSettingsEdit.data.connectionInformation = connectionInformation;
                }
                else{
                    $scope.serverSettingsEdit.data.connectionInformation = null;
                }

                $signalProvider.signal('settingsScrollbar');
            };

            $scope.serverSettingsEdit.data.updateServerInfo();

            $scope.serverSettingsEdit.back = function() {
                var dummyID = Math.random(); //Changing this here forces the parent state to reload
                $state.go('^', {'dummyID': dummyID}, {reloadOnSearch: true});
            };

            $scope.serverSettingsEdit.save = function() {

                $scope.serverSettingsEdit.saving = true;

                $scope.serverSettingsEdit.toggleEditName = false;
                var groups_list = $scope.serverSettingsEdit.types.groups.list;
                var recipients;
                if(angular.isArray(groups_list)){
                  recipients = $scope.serverSettingsEdit.types.users.list.concat($scope.serverSettingsEdit.types.groups.list);
                }else{
                  recipients = $scope.serverSettingsEdit.types.users.list;
                }

                var userArray = [];
                var groupArray = [];

                for(var i = 0; i < $scope.serverSettingsEdit.data.list.length; i++) {
                    for(var j = 0; j < recipients.length; j++){
                        if(recipients[j].id === $scope.serverSettingsEdit.data.list[i]){
                            if(recipients[j].users){
                                groupArray.push($scope.serverSettingsEdit.data.list[i]);
                            }else{
                                userArray.push($scope.serverSettingsEdit.data.list[i]);
                            }
                        }
                    }
                }

                $scope.serverSettingsEdit.pool.projectID = $stateParams.projectID;
                $scope.serverSettingsEdit.pool.applicationID = application.id;
                //$scope.serverSettingsEdit.pool.storageID = $scope.serverSettingsEdit.data.storageDevice.id;
                //Override the object to match the schema
                var pool = angular.copy($scope.serverSettingsEdit.pool);
                pool.type = $scope.serverSettingsEdit.pool.type.name;

                if($stateParams.poolID === 'create') {
                    //If we are creating a new pool, first save the pool, then save the users

                    //Save pool
                    Pools.updatePool(pool).$promise.then(function (data) {
                            //Creating the pool was successful, now we need to save the users
                            $log.info('Created Pool');
                            $log.log(data);

                            //Save users and groups
                            Pools.savePoolMembers(
                                {projectID: $stateParams.projectID, poolID: data.id},
                                {users: userArray, groups: groupArray})
                                .$promise.then(function(){
                                    $log.log('Users saved');
                                    $signalProvider.signal('feedback', [ 'success', 'PoolCreated']);

                                    $rootScope.debounce = false;
                                    $rootScope.dirty = false;

                                    $state.go($state.current,{
                                        organizationID: $stateParams.organizationID,
                                        divisionID: $stateParams.divisionID,
                                        projectID: $stateParams.projectID,
                                        poolID: data.id
                                    });
                                }, function(){
                                    //Saving users/groups failed
                                    $log.log('Error saving users/groups');

                                    $scope.serverSettingsEdit.saving = false;
                                    //Display message that creating the pool succeeded, but saving the users/groups failed
                                    $signalProvider.signal('feedback', [ 'failure', 'PoolCreatedMembersFailed']);

                                    $rootScope.debounce = false;

                                    $state.go($state.current,{
                                        organizationID: $stateParams.organizationID,
                                        divisionID: $stateParams.divisionID,
                                        projectID: $stateParams.projectID,
                                        poolID: data.id
                                    });
                                });
                        },
                        //Creating the pool failed
                        function(error){
                            $log.log(error);
                            $scope.serverSettingsEdit.saving = false;
                            $signalProvider.signal('feedback', [ 'failure', 'PoolCreated']);

                            $rootScope.debounce = false;
                        });
                }else{
                    var promises = [];

                    //Save pool
                    var poolPromise = Pools.updatePool({poolID: $stateParams.poolID}, pool).$promise;

                    //Save users and groups
                    var poolMembersPromise = Pools.savePoolMembers({
                            projectID: $stateParams.projectID,
                            poolID: $stateParams.poolID
                        },
                        {users: userArray, groups: groupArray}).$promise;

                    promises.push(poolPromise);
                    promises.push(poolMembersPromise);

                    $q.all(promises).then(function(allResponses){
                        $log.log('Pool fully saved');
                        $scope.serverSettingsEdit.pool = allResponses[0];
                        $scope.serverSettingsEdit.saving = false;
                        $signalProvider.signal('feedback', [ 'success', 'ProjectServer']);

                        $rootScope.debounce = false;
                        $rootScope.dirty = false;

                    }, function(error){
                        $log.log(error);
                        $scope.serverSettingsEdit.saving = false;
                        $signalProvider.signal('feedback', [ 'failure', 'ProjectServer']);

                        $rootScope.debounce = false;

                        $state.go($state.current,{
                            organizationID: $stateParams.organizationID,
                            divisionID: $stateParams.divisionID,
                            projectID: $stateParams.projectID,
                            poolID: $stateParams.poolID
                        });
                    });
                }
            };

            $scope.serverSettingsEdit.toggle = function(recipient) {
                var index = $scope.serverSettingsEdit.data.list.indexOf(recipient.id);

                // We found this item, lets remove them from the selected array
                if(index!==-1) {
                    $scope.serverSettingsEdit.data.list.splice(index,1);
                }else{
                    $scope.serverSettingsEdit.data.list.push(recipient.id);
                }

                $rootScope.dirty = true;
            };

            $scope.serverSettingsEdit.delete = function(){
                var item = $scope.serverSettingsEdit.pool;
                item.deleteEntityType = 'pool';

                modalManager.openModal({
                    templateUrl: 'views/common/modals/confirm.html',
                    controller: 'ConfirmModalCtrl',
                    windowClass: 'confirm',
                    resolve: {
                        title: function () {
                            return 'Are you sure you want to delete this server?';
                        },
                        body: function () {
                            //This can accept html
                            return 'You will be deleting server \"'+ item.name +'\" from this project.';
                        },
                        button: function () {
                            return 'Delete';
                        }
                    }
                }).result.then(function () {
                        $scope.serverSettingsEdit.deleting = true;
                        Pools.deletePool({ projectID: $stateParams.projectID, poolID: $stateParams.poolID}).$promise.then(function(){
                            $signalProvider.signal('feedback', [ 'success', 'DeletePool']);
                            $scope.serverSettingsEdit.deleting = false;
                            $scope.serverSettingsEdit.back();
                        }, function(){
                            $scope.serverSettingsEdit.deleting = false;
                            $signalProvider.signal('feedback', [ 'failure', 'DeletePool']);
                        });
                    });
            };

            //Search the groups and users with the given search params
            function search(name, search){
                $log.info('Searching: ' + name);

                $scope.serverSettingsEdit.loading = true;
                $signalProvider.signal('searchUsers_placeholder', 'Searching...');

                var usersCountPromise = Users.ProjectUser.getUsersCount({projectID: $stateParams.projectID, query: JSON.stringify(search)}).$promise;
                var groupsCountPromise = Groups.ProjectGroups.getGroupsCount({projectID: $stateParams.projectID, query: JSON.stringify(search)}).$promise;

                $q.all([usersCountPromise, groupsCountPromise]).then(function (allResponses){

                    $log.info('Done finding the counts');

                    var count = 0;
                    for(var i = 0; i < allResponses.length; i++){
                        count += allResponses[i].count;
                    }
                    $log.log(count);

                    $scope.serverSettingsEdit.data.membersCount = count;
                    $signalProvider.signal('searchUsers_placeholder', $scope.serverSettingsEdit.data.membersCount + ' users and groups found');

                    //If the count is falsey just return and dont do the actual search
                    if(!$scope.serverSettingsEdit.data.membersCount) {
                        $scope.serverSettingsEdit.types.users.list = [];
                        $scope.serverSettingsEdit.types.groups.list = [];
                        return false;
                    }

                    var usersSearchPromise = Users.ProjectUser.getUsers({projectID: $stateParams.projectID, query: JSON.stringify(search)}).$promise;
                    var groupsSearchPromise = Groups.ProjectGroups.getGroups({projectID: $stateParams.projectID, query: JSON.stringify(search)}).$promise;

                    $q.all([usersSearchPromise, groupsSearchPromise]).then(function(allMembersResponses){
                        $log.info('Done searching');
                        $log.log(allMembersResponses[0]);
                        $log.log(allMembersResponses[1]);

                        //Add the files to angular
                        $scope.serverSettingsEdit.types.users.list = allMembersResponses[0];
                        $scope.serverSettingsEdit.types.groups.list = allMembersResponses[1];

                        $scope.serverSettingsEdit.loading = false;

                    });

                });

            };

            search('init','');

            $scope.serverSettingsEdit.hasErrors = function(){

                if($scope.serverSettingsEdit.saving){
                    return true;
                }

                if(!$scope.serverSettingsEdit.pool){
                    return false;
                }

                if(!$scope.serverSettingsEdit.pool.name || $scope.serverSettingsEdit.pool.name.length === 0){
                    return true;
                }

                if(!$scope.serverSettingsEdit.pool.type){
                    return true;
                }

                if($scope.serverSettingsEdit.data.credential && $stateParams.poolID === 'create'){
                    for(var i = 0; i < $scope.serverSettingsEdit.data.credential.length; i++){
                        var requiredCredProperty = $scope.serverSettingsEdit.data.credential[i].property_name;
                        if(!$scope.serverSettingsEdit.pool.credential || !$scope.serverSettingsEdit.pool.credential.hasOwnProperty(requiredCredProperty) || typeof $scope.serverSettingsEdit.pool.credential[requiredCredProperty] === 'undefined'){
                            return true;
                        }
                    }
                }

                if($scope.serverSettingsEdit.data.connectionInformation){
                    for(var j = 0; j < $scope.serverSettingsEdit.data.connectionInformation.length; j++){
                        var requiredConnProperty = $scope.serverSettingsEdit.data.connectionInformation[j].property_name;
                        if(!$scope.serverSettingsEdit.pool.connectionInformation || !$scope.serverSettingsEdit.pool.connectionInformation.hasOwnProperty(requiredConnProperty) || typeof $scope.serverSettingsEdit.pool.connectionInformation[requiredConnProperty] === 'undefined'){
                            return true;
                        }
                    }
                }

                return false;
            };

            $scope.serverSettingsEdit.validatePort = function(e) {
                if( e.currentTarget.value < 1 || e.currentTarget.value > 65536) {
                    return false;
                }
                return true;
            };

            $scope.serverSettingsEdit.updateStorageDeviceList = function(search) {
                if(search) {
                    var searchData = [];
                    angular.forEach(storageDevices, function(value) {
                        var typed = search.toLowerCase();
                        var name = value.name.toLowerCase();
                        var index = name.indexOf(typed);
                        if(index!==-1) {
                            searchData.push(value);
                        }
                    });
                    $scope.serverSettingsEdit.data.storageDevices = searchData;
                }else{
                    $scope.serverSettingsEdit.data.storageDevices = storageDevices;
                }
            };

            //listen for when to search
            var currentSearch = $signalProvider.listen('searchUsers', search);
            $scope.serverSettingsEdit.data.poolTypeChanged = $signalProvider.listen('serverEditPoolTypeContext', $scope.serverSettingsEdit.data.updateServerInfo);

            //Destroy the function on search
            $scope.$on('$destroy', function(){
                $log.info('Destroy Controller');
                $signalProvider.unlisten('searchPoolGroupsAndUsers', currentSearch);
                $signalProvider.unlisten('serverEditPoolTypeContext', $scope.serverSettingsEdit.data.poolTypeChanged);
            });

            $scope.$watch('serverSettingsEdit.pool.name', function(newValue, oldValue) {
                if(!$rootScope.dirty && newValue && newValue !== oldValue) {
                    $log.log('Form is Dirty');
                    $rootScope.dirty = true;
                }
            });

            $scope.$watch('serverSettingsEdit.pool.credential["username"]', function(newValue, oldValue) {
                if(!$rootScope.dirty && newValue && newValue !== oldValue) {
                    $log.log('Form is Dirty');
                    $rootScope.dirty = true;
                }
            });

            $scope.$watch('serverSettingsEdit.pool.credential["password"]', function(newValue, oldValue) {
                if(!$rootScope.dirty && newValue && newValue !== oldValue) {
                    $log.log('Form is Dirty');
                    $rootScope.dirty = true;
                }
            });

            $scope.$watch('serverSettingsEdit.pool.connectionInformation["host"]', function(newValue, oldValue) {
                if(!$rootScope.dirty && newValue && newValue !== oldValue) {
                    $log.log('Form is Dirty');
                    $rootScope.dirty = true;
                }
            });

            $scope.$watch('serverSettingsEdit.pool.connectionInformation["root"]', function(newValue, oldValue) {
                if(!$rootScope.dirty && newValue && newValue !== oldValue) {
                    $log.log('Form is Dirty');
                    $rootScope.dirty = true;
                }
            });

            $scope.$watch('serverSettingsEdit.pool.connectionInformation["port"]', function(newValue, oldValue) {
                if(!$rootScope.dirty && newValue && newValue !== oldValue) {
                    $log.log('Form is Dirty');
                    $rootScope.dirty = true;
                }
            });

            $scope.$watch('serverSettingsEdit.pool.connectionInformation["secure"]', function(newValue, oldValue) {
                if(!$rootScope.dirty && newValue && newValue !== oldValue) {
                    $log.log('Form is Dirty');
                    $rootScope.dirty = true;
                }
            });



        }]);
