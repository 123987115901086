/**
 * @ngdoc object
 * @name ServerCtrl
 * @requires $scope, $stateParams, signalProvider
 */

'use strict';

angular.module('pulse')
    .controller('ServerCtrl',
    ['$scope', '$stateParams', '$signalProvider', '$state', '$log', '$timeout', 'pools',
        function ($scope, $stateParams, $signalProvider, $state, $log, $timeout, pools) {

            $log.info('Servers Controller');
            $scope.servers = {};

            var addTile = [{name:'Add Server', id:'create'}];
            $scope.servers.list = addTile.concat(pools);

            $scope.servers.back = function() {
              $state.go('^');
            };

        }]);
