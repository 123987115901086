/**
 * @ngdoc object
 * @name Metadata
 * @requires $resource
 */

(function(){

    'use strict';

    angular
        .module('pulse')
        .factory('Metadata', Metadata);

    Metadata.$inject = ['$resource'];

    function Metadata ($resource) {

        return $resource (
            config.baseURL + '/projects/:projectID/:ext',
            {ext: '@ext', projectID: '@projectID'},
            {
                getEpisodes: {method: 'GET', params: {ext:'episodes'}},
                getEpisodesNames:{method:'GET', params: {ext:'episodes'}, transformResponse: function(data){
                  data = angular.fromJson(data);
                  var array = [];
                  if(data.episodes){
                      var length = data.episodes.length;
                      for(var i=0;i<length;i++) {
                          array.push({name: data.episodes[i]});
                      }
                  }
                  return array;
                }, isArray:true},
                getCategories: {method: 'GET', params: {ext:'fileCategories'}},
                getShootDates: {method: 'GET', params: {ext:'shootDates'}},
                getMetadataFields: {method: 'GET', params: {ext:'metadataFields'}}
            }
        );
    }
})();
