(function(window, angular, undefined) {
    'use strict';

    /** 
     * @type {boolean}
     * To track user last activity has been stored in local storage or not
     */
    let isLastActivitySet = false;

    /** 
     * @type {boolean}
     * To track user session watcher has been started or not
     */
    let isUserSessionTracked = false;

    /**
     * @type {string}
     * Local storage key for user last activity
     * @readonly
     */
    const USER_LAST_ACTIVITY = 'userLastActivity';

    // $keepalive service and provider
    function $KeepaliveProvider() {
        var options = {
            http: null,
            interval: 10 * 60
        };

        this.http = function(value) {
            if (!value) {throw new Error('Argument must be a string containing a URL, or an object containing the HTTP request configuration.');}
            if (angular.isString(value)) {
                value = {
                    url: value,
                    method: 'GET'
                };
            }

            value.cache = false;

            options.http = value;
        };

        this.interval = function(seconds) {
            seconds = parseInt(seconds);

            if (isNaN(seconds) || seconds <= 0) {throw new Error('Interval must be expressed in seconds and be greater than 0.');}
            options.interval = seconds;
        };

        this.$get = ['$signalProvider', '$log', '$interval', '$http',
            function($signalProvider, $log, $interval, $http) {

            var lastInterrupt;

            var state = {
                ping: null
            };

            function handleResponse(data, status) {

                var response;
                if(data && data !== null) {

                    if (data.status === 403) {
                        $log.error("Access Forbidden, user has been logged out.");

                        // remove the last activity timestamp, so when user become active on the tab user will be logged out inside body event handler
                        window.localStorage.removeItem(USER_LAST_ACTIVITY);
                        isLastActivitySet = true;
                    }

                    data.status = status;
                    response = data;
                }

                $signalProvider.signal("$keepaliveResponse", response);

            }

            function ping() {

                var data;

                if(lastInterrupt) {
                    data = lastInterrupt;
                }

                $signalProvider.signal("$keepalive", data);
                //$rootScope.$broadcast('$keepalive',data);

                if (angular.isObject(options.http)) {
                    $http(options.http).then(handleResponse,handleResponse);
                }
            }

            return {
                _options: function() {
                    return options;
                },
                setLastInterrupt: function(time) {
                    lastInterrupt = time;
                },
                start: function() {
                    $interval.cancel(state.ping);

                    state.ping = $interval(ping, options.interval * 1000);
                },
                stop: function() {
                    $interval.cancel(state.ping);
                },
                ping: function() {
                    ping();
                }
            };
        }];
    }

    angular.module('ngIdle.keepalive', [])
        .provider('$keepalive', $KeepaliveProvider);

    // $idle service and provider
    function $IdleProvider() {

        var options = {
            idleDuration: 20 * 60, // in seconds (default is 20min)
            warningDuration: 30, // in seconds (default is 30sec)
            autoResume: true, // lets events automatically resume (unsets idle state/resets warning)
            events: 'mousemove keydown DOMMouseScroll mousewheel mousedown touchstart',
            keepalive: true
        };

        this.activeOn = function(events) {
            options.events = events;
        };

        this.idleDuration = function(seconds) {
            if (seconds <= 0) {throw new Error('idleDuration must be a value in seconds, greater than 0.');}

            options.idleDuration = seconds;
            const lastActiveTime = new Date().getTime();
            window.localStorage.setItem(USER_LAST_ACTIVITY, lastActiveTime);
        };

        this.warningDuration = function(seconds) {
            if (seconds < 0) {throw new Error('warning must be a value in seconds, greater than 0.');}

            options.warningDuration = seconds;
        };

        this.autoResume = function(value) {
            options.autoResume = value === true;
        };

        this.keepalive = function(enabled) {
            options.keepalive = enabled === true;
        };

        this.$get = ['$interval', '$log', '$signalProvider', '$document', '$keepalive', '$window', '$rootScope',
            function($interval, $log, $signalProvider, $document, $keepalive, $window, $rootScope) {
            var state = {
                idle: null,
                warning: null,
                idling: false,
                running: false,
                countdown: null
            };

            function startKeepalive() {
                if (!options.keepalive) {return false;}

                if (state.running) {$keepalive.ping();}

                $keepalive.start();
            }

            function stopKeepalive() {
                if (!options.keepalive) {return false;}

                $keepalive.stop();
            }

            function toggleState() {
                state.idling = !state.idling;
                var name = state.idling ? 'Start' : 'End';

                $signalProvider.signal('$idle' + name, true);
                //$rootScope.$broadcast('$idle' + name);

                if (state.idling) {
                    if (isSessionActiveInAnotherTab()) {
                        $log.log('User session active found in another tab, we will keep you logged in');
                        startKeepalive();
                        const lastActiveTime = new Date().getTime();
                        $keepalive.setLastInterrupt(lastActiveTime);
                        svc.interrupt(true);
                        return;
                    }
            
                    stopKeepalive();
                    state.countdown = options.warningDuration;
                    countdown();
                    state.warning = $interval(countdown, 1000, options.warningDuration, false);
                } else {
                    startKeepalive();
                }

                $interval.cancel(state.idle);
            }

            function countdown() {
                // countdown has expired, so signal timeout
                if (state.countdown <= 0) {
                    $log.log('User warning modal timeout');
                    timeout();
                    return;
                }
            
                if (isSessionActiveInAnotherTab()) {
                    $log.log('Modal appears but user found active in another tab');
                    startKeepalive();
                    const lastActiveTime = new Date().getTime();
                    $keepalive.setLastInterrupt(lastActiveTime);
                    svc.interrupt(true);
                    return;
                }
            
                $signalProvider.signal('$idleWarn' + name, state.countdown);
                // countdown hasn't reached zero, so warn and decrement
                //$rootScope.$broadcast('$idleWarn', state.countdown);
                state.countdown--;
            }

            /**
             * Check if a user session is active in another tab. 
             * yes - Compare current time with last time user was active in another tab difference should be less than idleLogoutTime
             * no - Return false
             * @returns {boolean}
             */
            function isSessionActiveInAnotherTab() {
                const now = new Date().getTime();
                const lastActiveTime = parseInt($window.localStorage.getItem(USER_LAST_ACTIVITY));
                const idleLogoutTime = options.idleDuration * 1000;
            
                return !!lastActiveTime && now - lastActiveTime < idleLogoutTime;
            }

            function timeout() {
                stopKeepalive();
                $interval.cancel(state.idle);
                $interval.cancel(state.warning);

                state.idling = true;
                state.running = false;
                state.countdown = 0;

                $signalProvider.signal('$idleTimeout' + name, true);
                //$rootScope.$broadcast('$idleTimeout');
            }

            var svc = {
                _options: function() {
                    return options;
                },
                _getNow: function() {
                    return new Date();
                },
                isExpired: function() {
                    return state.expiry && state.expiry <= this._getNow();
                },
                running: function() {
                    return state.running;
                },
                idling: function() {
                    return state.idling;
                },
                watch: function() {
                    isUserSessionTracked = true;
                    $interval.cancel(state.idle);
                    $interval.cancel(state.warning);

                    // calculate the absolute expiry date, as added insurance against a browser sleeping or paused in the background
                    state.expiry = new Date(new Date().getTime() + ((options.idleDuration + options.warningDuration) * 1000));

                    if (state.idling) {
                        toggleState(); // clears the idle state if currently idling
                    }else if(!state.running){ startKeepalive(); } // if about to run, start keep alive

                    state.running = true;

                    state.idle = $interval(toggleState, options.idleDuration * 1000, 0, false);
                },
                unwatch: function() {
                    $interval.cancel(state.idle);
                    $interval.cancel(state.warning);

                    state.idling = false;
                    state.running = false;
                    state.expiry = null;
                },
                interrupt: function(skipExpiryCheck) {
                    if (!state.running) {return false;}
                    
                    if (this.isExpired() && !skipExpiryCheck) {
                        $log.log('The user sesion has been expired because of timeout');
                        timeout();
                        return;
                    }

                    // note: you can no longer auto resume once we exceed the expiry; you will reset state by calling watch() manually
                    if (options.autoResume) {this.watch();}
                }
            };

            let isTimeoutRunning = null;

            $document.find('body').on(options.events, function() {

                if (!isUserSessionTracked) {
                    return;
                }

                // interrupt the idle timer if the user interacts with the document
                if (isTimeoutRunning) {
                    clearTimeout(isTimeoutRunning);
                }

                isTimeoutRunning = setTimeout(function() {

                    const isLastActivity = window.localStorage.getItem(USER_LAST_ACTIVITY);

                    if (isLastActivity === null && isLastActivitySet === true) {
                        $log.log('The user has been logged out from other tabs');
                        $rootScope.sessionExpireType = 'user-interaction';
                        window.localStorage.removeItem(USER_LAST_ACTIVITY);
                        timeout();
                        return;
                    }

                    const lastActiveTime = new Date().getTime();
                    $keepalive.setLastInterrupt(lastActiveTime);
                    svc.interrupt();
                    window.localStorage.setItem(USER_LAST_ACTIVITY, lastActiveTime);
                    isLastActivitySet = true;
                }, 200);

            });

            return svc;
        }];
    }

    angular.module('ngIdle.idle', [])
        .provider('$idle', $IdleProvider);

    angular.module('ngIdle.ngIdleCountdown', [])
        .directive('ngIdleCountdown',
        ['$signalProvider', '$log',
        function($signalProvider, $log) {
            return {
                restrict: 'A',
                scope: {
                    value: '=ngIdleCountdown'
                },
                link: function($scope) {

                    function setCountdown(name, countdown) {
                        $scope.$apply(function() {
                            $scope.value = countdown;
                        });
                    }

                    function setTimeout(name, countdown) {
                        $scope.$apply(function() {
                            $scope.value = 0;
                        });
                    }

                    var $idleWarn = $signalProvider.listen('$idleWarn', setCountdown);
                    var $idleTimeout = $signalProvider.listen('$idleTimeout', setTimeout);

                    //Destroy the function on search
                    $scope.$on('$destroy', function(){
                        $log.info('Destroy Idle Countdown');
                        $signalProvider.unlisten("$idleWarn",$idleWarn);
                        $signalProvider.unlisten("$idleTimeout",$idleTimeout);
                    });
                }
            };
        }]);

    angular.module('ngIdle', ['ngIdle.keepalive', 'ngIdle.idle', 'ngIdle.ngIdleCountdown']);

})(window, window.angular);
