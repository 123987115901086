/**
 * @ngdoc object
 * @name PullsResource
 * @requires $resource
 */

(function(){

    'use strict';

    angular
        .module('pulse')
        .factory('PullsResource', PullsResource);

    PullsResource.$inject = ['$log', '$resource', '$rootScope'];

    function PullsResource ($log, $resource, $rootScope) {
        $log.log('PullsResource --- ', $rootScope.application_name);
        var applicationID;
        // TODO: remove this once vfx and preconfrom are done
        if($rootScope.application_name === 'vfxpulls') {
            applicationID = $rootScope.storage.vfx_application.id;
        } else if ($rootScope.application_name === 'filemanager') {
            $log.log("PullsResource ---- yes is FM");
            applicationID = $rootScope.storage.application.id;
        } else {
            applicationID = $rootScope.storage.preconform_application.id;
        }

        return $resource(
            config.baseURL + '/pulls/' + applicationID + '/:projectID/:assetID/:action/:eventID',
            {applicationID: '@applicationID', projectID: '@projectID', assetID: '@assetID', eventID: '@eventID'},
            {
                getPull: {method: 'GET', isArray: true},
                getPulls: {method: 'GET', isArray: true},
                getPullEvents: {method: 'GET', isArray: false},
                postReport: {method: 'POST', params: {action: 'report'}},
                contactSupport: {method: 'POST', params: {action: 'contactSupport'}},
                updatePull: {method: 'POST'},
                deletePull: {method: 'DELETE'},
                clonePull: {method: 'POST', params: {action: 'duplicate'}},
                sendPull: {method: 'POST', params: {action: 'send'}},
                updatePullSelect: {method: 'POST', params: {action: 'selects'}},
            }
        );
    }
})();
