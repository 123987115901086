///need to add ng-doc stuff
// I would like to move this Dir to the commons folder
// this dir ver. does not support polling.

(function() {
    'use strict';

    angular
        .module('pulse')
        .directive('pulseDisableElem', directive);

    /* @ngInject */

    directive.$inject = ['$log', '$timeout'];

    function directive($log, $timeout) {
        var directive = {
            restrict: 'A',
            scope: {pulseDisableElem: '='},
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, el, attr) {
            $log.log('disable', scope)
            // el[0].style.userSelect = 'all';
            // el[0].draggable = false;
            // attr.draggable = false;
            //
            el.on('click', function(event){
              $log.log(scope);
              $log.log('disable elem onclick _____ ', scope.pulseDisableElem)
              if (scope.pulseDisableElem){
                event.preventDefault();
                event.stopPropagation();
              }
            });

        }
    }
})();
