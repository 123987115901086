(function(){

'use strict';

angular
    .module('scrollbarz', [])
    .directive('scrollbarz', scrollbarz);
    scrollbarz.$inject = ['$parse','$window', '$interval', '$log', '$signalProvider']
    function scrollbarz($parse, $window, $interval, $log, $signalProvider) {
            var directive = {
                restrict: 'A',
                replace: true,
                transclude: true,
                scope:{
                    'showYScrollbar': '=?isBarShown',
                    'useParentHeight': '=',
                    'broadcast': '@',
                    'wheelSpeed' : '='
                },
                link: linkFunc,
                template: '<div>' +
                '<div class="ngsb-wrap">' +
                '<div class="ngsb-container"><div ng-transclude></div></div>' +
                '<div class="ngsb-scrollbar" style="position: absolute; display: block;" ng-show="showYScrollbar">' +
                '<div class="ngsb-thumb-container">' +
                '<div class="ngsb-thumb-pos" oncontextmenu="return false;">' +
                '<div class="ngsb-thumb" ></div>' +
                '</div>' +
                '<div class="ngsb-track"></div>' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</div>'
            };

            return directive;

            function linkFunc(scope, element, attrs) {

                var mainElm, transculdedContainer, tools, thumb, thumbLine, track, items, viewItemsCount, colCount, rollToBottom, rollToTop;
                var preventOverride = false;
                var touchStartY = -1;

                var win = angular.element($window);

                var hasAddEventListener = !!win[0].addEventListener;
                var hasRemoveEventListener = !!win[0].removeEventListener;

                // Elements
                var dragger = {
                    top: 0
                }, page = {
                    top: 0
                };

                // Styles
                var scrollboxStyle, draggerStyle, draggerLineStyle, pageStyle;

                var calcStyles = function () {
                    scrollboxStyle = {
                        position: 'relative',
                        //overflow: 'hidden',
                        'max-width': '100%',
                        height: '100%'
                    };

                    if (page.height) {
                        scrollboxStyle.height = page.height + 'px';
                    }

                    draggerStyle = {
                        position: 'absolute',
                        height: (dragger.height) + 'px'
                        //top: 0 + 'px'
                    };

                    draggerLineStyle = {
                        position: 'relative',
                        'line-height': dragger.height + 'px'
                    };

                    pageStyle = {
                        position: 'relative',
                        top: page.top + 'px'
                        //padding: '0 0 50px 0'
                        //overflow: 'hidden'
                    };
                };

                var redraw = function(init, preventSignal) {
                    thumb.css('top', dragger.top + 'px');
                    var draggerOffset = dragger.top / page.height;
                    page.top = -Math.round(page.scrollHeight * draggerOffset);
                    transculdedContainer.css('top', page.top + 'px');

                    if (!!preventSignal && preventSignal === true) {
                        return;
                    }
                    else {
                        $signalProvider.signal('scrollbarz:scroll', attrs.scrollData);
                    }
                };

                var trackClick = function(event) {
                    var offsetY = event.hasOwnProperty('offsetY') ? event.offsetY : event.layerY;
                    var newTop = Math.max(0, Math.min(parseInt(dragger.trackHeight, 10) - parseInt(dragger.height, 10), offsetY));
                    dragger.top = newTop;
                    redraw(false);
                    event.stopPropagation();
                };

                var wheelHandler = function(event) {
                    var wheelDivider = 5;
                    if (!!attrs.wheelSpeed && attrs.wheelSpeed > 0) { //allow for slower, per-item, scrolling experience
                        wheelDivider = attrs.wheelSpeed;
                    }
                    var deltaY = event.wheelDeltaY !== undefined ?
                    event.wheelDeltaY / wheelDivider :
                        event.wheelDelta !== undefined ?
                        event.wheelDelta / wheelDivider :
                        -event.detail * (wheelDivider / 10);
                    //Height - Scrollbar, draggerTop - the one scroll
                    dragger.top = Math.max(0, Math.min(parseInt(page.height, 10) - parseInt(dragger.height, 10), parseInt(dragger.top, 10) - deltaY));
                    redraw(false);
                    if (!!event.preventDefault) {
                        event.preventDefault();
                    } else {
                        return false;
                    }
                };

                var scrollTo = function(event, data) {
                    var deltaY;
                    if(data.direction==='bottom') {
                        deltaY = -120/5;
                    }else{
                        deltaY = 120/5;
                    }
                    //Height - Scrollbar, draggerTop - the one scroll
                    dragger.top = Math.max(0, Math.min(parseInt(page.height, 10) - parseInt(dragger.height, 10), parseInt(dragger.top, 10) - deltaY));
                    redraw(false);
                    if (!!event.preventDefault) {
                        event.preventDefault();
                    } else {
                        return false;
                    }
                };

                var lastOffsetY = 0;

                var thumbDrag = function (event, offsetX, offsetY) {
                    dragger.top = Math.max(0, Math.min(parseInt(dragger.trackHeight, 10) - parseInt(dragger.height, 10), offsetY));
                    event.stopPropagation();
                };

                var dragHandler = function (event) {
                    var newOffsetX = 0;
                    var newOffsetY = event.pageY - thumb[0].scrollTop - lastOffsetY;
                    thumbDrag(event, newOffsetX, newOffsetY);
                    redraw(false);
                };

                var _mouseUp = function (event) {
                    win.off('mousemove', dragHandler);
                    win.off('mouseup', _mouseUp);
                    event.stopPropagation();
                };

                var _touchDragHandler = function (event) {
                    var newOffsetX = 0;
                    var newOffsetY = event.originalEvent.changedTouches[0].pageY - thumb[0].scrollTop - lastOffsetY;
                    thumbDrag(event, newOffsetX, newOffsetY);
                    redraw(false);
                };

                var _touchEnd = function (event) {
                    win.off('touchmove', _touchDragHandler);
                    win.off('touchend', _touchEnd);
                    event.stopPropagation();
                };

                function registerEvent(elm) {
                    var wheelEvent = win[0].onmousewheel !== undefined ? 'mousewheel' : 'DOMMouseScroll';
                    if (hasAddEventListener) {
                        elm.addEventListener(wheelEvent, wheelHandler, false);
                    } else {
                        elm.attachEvent('onmousewheel', wheelHandler);
                    }
                };

                function removeEvent(elm) {
                    var wheelEvent = win[0].onmousewheel !== undefined ? 'mousewheel' : 'DOMMouseScroll';
                    if (hasRemoveEventListener) {
                        elm.removeEventListener(wheelEvent, wheelHandler, false);
                    } else {
                        elm.detachEvent('onmousewheel', wheelHandler);
                    }
                };


                //------- touch scrolling simulation using drag events on the content-----------------------
                function registerScrollEvent() {
                    if (('ontouchstart' in window) ||
                        (navigator.maxTouchPoints > 0) ||
                        (navigator.msMaxTouchPoints > 0)) {
                        $log.log('Registering Container Touch Events');

                        transculdedContainer.bind('touchstart', touchStartHandler);
                        transculdedContainer.bind('touchmove', touchMoveHandler);
                        transculdedContainer.bind('touchend', touchEndHandler);
                        transculdedContainer.bind('touchcancel', touchCancelHandler); //finger may wander into the browser's ui, must cancel then


                    }
                };

                function preventDocumentTouchMove(event){
                    //if (!event.originalEvent.path.somethingThatListensToTouchStartMove) {
                        event.preventDefault();
                    //}
                }

                function touchStartHandler(event) {
                    $(document).bind('touchmove', preventDocumentTouchMove); //prevent springy bouncy behavior in iDevices (safari)
                    event.stopPropagation();
                    var touch = event.originalEvent.targetTouches[0];
                    $log.log("Touch START - Page Y on START MOVE: ", touch.pageY);
                    touchStartY = touch.pageY;
                };

                function touchMoveHandler(event) {
                    //event.preventDefault();
                    //event.stopPropagation();
                    var touch = event.originalEvent.changedTouches[0]; //we only care about 1 touch, if they had 4 fingers down this will be pretty random

                    if (touchStartY >= 0) {
                        var delta = touch.pageY - touchStartY;
                        touchStartY = touch.pageY;
                        $log.log('Touch MOVE Detected. Activating Scroll, the Delta Value is ', delta);
                        dragger.top = Math.max(0, Math.min(parseInt(page.height, 10) - parseInt(dragger.height, 10), parseInt(dragger.top, 10) - delta));
                        redraw(false);
                    }
                };

                function touchEndHandler(event) {
                    //event.preventDefault();
                    //event.stopPropagation();
                    var touch = event.originalEvent.changedTouches[0]; //we only care about 1 touch, if they had 4 fingers down this will be pretty random

                    if (touchStartY >= 0) {
                        var delta = touch.pageY - touchStartY;
                        $log.log('Touch END Detected. Activating Scroll, the Delta Value is ', delta);
                        dragger.top = Math.max(0, Math.min(parseInt(page.height, 10) - parseInt(dragger.height, 10), parseInt(dragger.top, 10) - delta));
                        redraw(false);
                    }

                    $(document).unbind('touchmove', preventDocumentTouchMove);
                };

                function touchCancelHandler(event) {
                    //event.preventDefault();
                    $log.warn("Touch Cancel Detected");

                    touchStartY = -1;
                    $(document).unbind('touchmove', preventDocumentTouchMove);
                };

                function removeScrollEvent(elm) {
                    $log.log('+++++++transculdedContainer+++++', transculdedContainer != undefined);
                    $log.log('Removing Container Touch Events (if any)');
                    if (transculdedContainer != undefined || transculdedContainer != 'undefined'){
                      transculdedContainer.unbind('touchstart', touchStartHandler);
                      transculdedContainer.unbind('touchmove', touchMoveHandler);
                      transculdedContainer.unbind('touchend', touchEndHandler);
                      transculdedContainer.unbind('touchcancel', touchCancelHandler);
                      $(document).unbind('touchmove', preventDocumentTouchMove);
                    };
                };
                //------- end touch scrolling on the content-----------------------


                function buildScrollbar(init) {

                    // Getting top position of a parent element to place scroll correctly
                    //var parentOffsetTop = element[0].parentElement.offsetTop;
                    mainElm = angular.element(element.children()[0]);
                    transculdedContainer = angular.element(mainElm.children()[0]);
                    itemContainer = angular.element(mainElm.children()[0]);
                    items = angular.element(itemContainer.children());
                    tools = angular.element(mainElm.children()[1]);
                    thumb = angular.element(angular.element(tools.children()[0]).children()[0]);
                    thumbLine = angular.element(thumb.children()[0]);
                    track = angular.element(angular.element(tools.children()[0]).children()[1]);

                    // Check if scroll bar is needed
                    page.height = element[0].offsetHeight;
                    if (page.height < 0) {
                        page.height = element[0].offsetHeight;
                    }
                    page.scrollHeight = transculdedContainer[0].scrollHeight;
                    if (page.height < page.scrollHeight) {

                        scope.showYScrollbar = true;
                        scope.$emit('scrollbar.show');

                        // Calculate the dragger height
                        dragger.height = Math.round(page.height / page.scrollHeight * page.height);
                        dragger.trackHeight = page.height;

                        // update the transcluded content style and clear the parent's
                        calcStyles();

                        //element.css({overflow: 'hidden'});
                        mainElm.css(scrollboxStyle);
                        transculdedContainer.css(pageStyle);
                        thumb.css(draggerStyle);
                        thumbLine.css(draggerLineStyle);

                        // Bind scroll bar events
                        track.bind('click', trackClick);
                        transculdedContainer[0].addEventListener('webkitTransitionEnd',function( event ) {
                            scope.$emit('scrollbar.done');
                        }, false );

                        // Handle mousewheel
                        registerEvent(mainElm[0]); //IG 8.20.15 - The event was being registered on the ngsb-container, which may be partially scrolled out of the view, and the mouse scroll would not register then. Registering on its parent now, which has a static position.
                        registerScrollEvent();

                        // Drag the scroller with the mouse
                        thumb.on('mousedown', function (event) {
                            lastOffsetY = event.pageY - thumb[0].offsetTop;
                            win.on('mouseup', _mouseUp);
                            win.on('mousemove', dragHandler);
                            event.preventDefault();
                        });

                        // Drag the scroller by touch
                        thumb.on('touchstart', function (event) {
                            lastOffsetY = event.originalEvent.changedTouches[0].pageY - thumb[0].offsetTop;
                            win.on('touchend', _touchEnd);
                            win.on('touchmove', _touchDragHandler);
                            event.preventDefault();
                        });

                        if (rollToBottom) {
                            dragger.top = parseInt(page.height, 10) - parseInt(dragger.height, 10);
                        }
                        else if (rollToTop) {
                            dragger.top = 0;
                        }
                        redraw(true, rollToTop);
                    } else {
                        scope.showYScrollbar = false;
                        scope.$emit('scrollbar.hide');

                        thumb.off('mousedown');

                        removeEvent(transculdedContainer[0]);
                        removeScrollEvent();

                        transculdedContainer.attr('style', 'position:relative;top:0;height:100%;'); // little hack to remove other inline styles
                        mainElm.css({height: '100%%'});

                    }

                    if (!!rebuildTimer && preventOverride) { preventOverride = false;  }
                };

                var rebuildTimer;

                function rebuild(e, data) {

                    if (!!rebuildTimer && preventOverride) {
                        return;
                    }

                    /* jshint -W116 */
                    if (rebuildTimer != null) {
                        clearTimeout(rebuildTimer);
                    }
                    /* jshint +W116 */

                    if(data) {
                        if (data.bottom) {
                            rollToBottom = true;
                        } else if (data.top) {
                            rollToTop = true;
                        }
                    }else{
                        rollToBottom = false;
                        rollToTop = false;
                    }

                    rebuildTimer = setTimeout(function () {

                        page.height = null;
                        buildScrollbar();

                        //Make sure to que digest to rebuild scrollbar
                        if (!scope.$$phase) {
                            scope.$digest();
                        }
                        // update parent for flag update
                        if(!scope.$parent.$$phase){
                            scope.$parent.$digest();
                        }

                    });

                    if ( !!data && (data.top || data.bottom) ) { //do not allow the timer to get replaced until 'scroll to top / bottom' operation completes
                        preventOverride = true;
                    }

                    $signalProvider.signal('scrollbarz:reset', {'id':attrs.scrollData, 'top': (!!data && data.top)});
                };

                //buildScrollbar();

                if (!!attrs.rebuildOn) {
                    attrs.rebuildOn.split(' ').forEach(function (eventName) {
                        scope.$on(eventName, rebuild);
                    });
                }

                if (!!attrs.scrollTo) {
                    attrs.scrollTo.split(' ').forEach(function (eventName) {
                        scope.$on(eventName, scrollTo);
                    });
                }

                if (attrs.hasOwnProperty('rebuildOnResize')) {
                    win.on('resize', rebuild);
                }

                var mainElm = angular.element(element.children()[0]);
                var scrollContainer = angular.element(mainElm.children()[0]);
                var itemContainer = angular.element(scrollContainer.children()[0]);

                //var watch;
                //scope.startInterval = function() {
                //    var i = 0;
                //    //TODO: this breaks in scrollbars in drawers
                //    //if ( angular.isDefined(watch) ) return;
                //    watch = $interval(function() {
                //
                //        if(i>=2) {
                //            //$log.log('stop');
                //            scope.stopInterval()
                //        }else{
                //
                //            //$log.log('start');
                //            //$log.log(attrs.rebuildOn +" height");
                //            //var itemContainer = angular.element(scrollContainer.children()[0]);
                //            //$log.log(itemContainer[0].offsetHeight);
                //            buildScrollbar();
                //            i++;
                //        }
                //    }, 100);
                //};
                //
                //scope.stopInterval = function() {
                //    $interval.cancel(watch);
                //};
                //
                //scope.$watch(function() {
                //    //console.log('Digest Is Running');
                //    itemContainer = angular.element(scrollContainer.children()[0]);
                //    return itemContainer[0].offsetHeight;
                //},function() {
                //    //$log.log('Trigger Scrollbar Build');
                //    scope.startInterval();
                //}, true);


                //TODO: i think this is better then we we have currently, it updates faster too -discuss later
                var oldHeight, watch;
                scope.startInterval2 = function() {
                    //var i = 0;
                    //TODO: this breaks in drawers -- we will come back to this
                    //if ( angular.isDefined(watch) ) return;
                    watch = $interval(function() {
                        var itemContainer = angular.element(scrollContainer.children()[0]);

                        //if(attrs.rebuildOn==='rebuild:metadata') {
                        ////   $log.log(itemContainer[0].offsetHeight);
                        ////}

                        if(typeof oldHeight === 'undefined' ) {
                            //$log.log('First Build');
                            oldHeight =  itemContainer[0].offsetHeight;
                            buildScrollbar();
                        }else if(oldHeight !== itemContainer[0].offsetHeight) {
                            //$log.log('Rebuild!!');
                            oldHeight =  itemContainer[0].offsetHeight;
                            buildScrollbar();
                        }

                    }, 100);
                };

                scope.startInterval2();

                scope.$on('$destroy', function() {
                    $interval.cancel(watch);
                    $log.log('scrollbarz ON destroy++++++++++++')
                    ///removeScrollEvent();
                });



            }

    }

})();
