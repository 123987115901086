/**
 * @ngdoc directive
 * @name pulseSettingsMenu
 * @module pulse
 * @restrict E
 * @description
 * `pulseSettingsMenu` a dropdown menu for settings within an application
 *
 * @param {object} permissions - A list of all permissions the user has
 * @param {object} items - this is the list of items to be shown in the settings dropdown
 * name (string) - The name to be displayed
 * callback (function) - This is used to navigate to a specific state for the item, applys $state.go
 * params (object) -  Params that need to be passed when we go to a specific state
 * context (object) - This is the object's content TODO: is this needed?
 * permission (string) - This is the permission that the directive is checking against
 *
 * @usage
 * var settings_items = [{
 *       name: "Roles",
 *       callback: function() {
 *           $state.go($state.current.name + '.settings.roles');
 *       },
 *       params: [],
 *       context: this,
 *       permission: $rootScope.permissionNames.PreconformAdmin
 *   }]
 *
 * $root.storage.project.permissions = [
 *   "admin structure"
 * ]
 *
 * <hljs lang="html">
 *   <pulse-settings-menu
 *      ng-if="$root.storage.project.permissions.indexOf('admin structure')>-1"
 *      project="$root.storage.project.permissions"
 *      items='settings_items'
 *   ></pulse-settings-menu>
 * </hljs>
 *
 */

(function() {
    'use strict';

    angular
        .module('pulse')
        .directive('pulseSettingsMenu', pulseSettingsMenu);

    pulseSettingsMenu.$inject = ['$log', '$state'];
    function pulseSettingsMenu($log, $state) {

        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: "views/common/navigation/pulse_settings_menu.html",
            scope: {
                items: '=',
                permissions: '='
            },
            controller: PulseSettingsMenuCtrl,
            link: link
        };
        return directive;

        function link(scope, element, attrs) {

            //We must pass in function to navigate to the correct view
            scope.navigate = function(item) {
              $log.log("navigate -- ", item);
                if (!!item.callback) {
                    item.callback.apply(item.context || null, item.params);
                }
            };

            //check each item to see if we need to display them.
            var length = (!scope.items) ? 0 : scope.items.length;
            for (var i=0; i<length; i++) {
                var item = scope.items[i];
                item.enabled = true;

                //If we dont pass in a permission then everyone has permissions to this
                if (item.permission && scope.permissions) {

                  //Check to see if this permission is within the project permissions and enabled if it is true
                  if(scope.permissions.indexOf(item.permission)>-1){
                      $log.log('Check Permissions - Access granted for ' + item.name + ', with permission ' + item.permission);
                      item.enabled = true;
                  } else {
                     $log.warn('Check Permissions - NO ACCESS for ' + item.name + ', with permission ' + item.permission);
                  }

                } else {
                    //$log.log('Check Permissions - Access granted for ' + item.name + ', with permission ' + item.permission);
                    item.enabled = true;
                }
            }

        }
    }

    function PulseSettingsMenuCtrl() {}

})();
