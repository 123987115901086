/**
 * @ngdoc object
 * @name PreconformResource
 * @requires $resource
 */

(function(){

  'use strict';

  angular
    .module('pulse')
    .factory('PreconformResource', PreconformResource);

  PreconformResource.$inject = ['$resource', '$rootScope'];

  function PreconformResource ($resource, $rootScope) {

      var application_name;
      // TODO: remove this once vfx and preconfom are done
      if($rootScope.application_name === 'vfxpulls') {
          application_name = 'vfx';
      } else if($rootScope.config.application_name==='dipulls') {
          application_name = 'preconform';
      }else{
          application_name = $rootScope.application_name;
      }

    return $resource(
      //TODO: not sure if eventID is the best name for this.
      //TODO: This should also be pullID instead of asssetID
      //TODO: this route hopefully wont be preconform but until the backend fixes their api we have to stick with this

      config.baseURL + '/'+application_name+'/:projectID/:ext/:assetID/:action/:eventID',
      {projectID: '@projectID', ext: '@ext', assetID: '@assetID', eventID: '@eventID'},
      {

        //TODO: Remove these and put use the VFX resource instead
        getVendors: {method: 'GET', params: {ext:'vendors'}, isArray:true},
        saveVendor: {method: 'POST', params: {ext:'vendors'}},
        deleteVendor: {method: 'DELETE', params: {ext:'vendors'}},

        getProfiles: {method: 'GET', params: {ext:'transkoderconfigs'}, isArray:true},
        getProfile: {method: 'GET', params: {ext:'transkoderconfigs'}},
        saveProfile: {method: 'POST', params: {ext:'transkoderconfigs'}},
        deleteProfile: {method: 'DELETE', params: {ext:'transkoderconfigs'}},
        getProfileOptions: {method: 'GET', params: {ext:'transkoderconfigs/options'}, isArray:true},
        getFormOptions: {method: 'GET', params: {ext:'transkoderconfigs/formoptions'}},
        downloadFile: {
          method: 'GET',
          params: {ext:'transkoderconfigs', action:'download'},
          isArray: false,
          responseType: "arraybuffer",
          transformResponse: function(data, headers){
              var response = {};
              response.data = data;
              response.headers = headers();
              return response;
          }},


        //generate report
        //preconform/<project_id>/pulls/<pull_id>/report
        postReport : {method: 'POST', params : {ext:'pulls', action:'report'}},

        savePullRequest: {method: 'POST', params: {ext:'pulls'}}, //TODO: is this being used?

        getPull: {method: 'GET', params: {ext:'pulls'}, isArray:true},
        getPulls: {method: 'GET', params: {ext:'pulls'}, isArray:true},
        clonePull: {method: 'POST', params: {ext:'pulls', action: 'duplicate'}},
        getPullEvents: {method: 'GET', params: {ext:'pulls'}, isArray:false},
        sendPull: {method: 'POST', params: {ext:'pulls', action: 'send'}},
        updatePull: {method: 'POST', params: {ext:'pulls'}},
        updatePullSelect: {method: 'POST', params: {ext:'pulls', action:'selects'}},
        deletePull: {method: 'DELETE', params: {ext:'pulls'}},
        finishPullRequest: {method: 'GET', params: {ext:'pulls', action: 'finish'}},
        getPullRequests: {method: 'GET', params: {ext:'pulls'}, isArray:true},
        getPullRequestsCount: {method:'GET', params: {ext:'pulls', 'countOnly': true}, isArray: false},

        getMappingNames:{method: 'GET', params: {ext:'setup/names'}},
        saveMapping: {method: 'POST', params: {ext:'mappings'}},
        getMapping: {method: 'GET', params: {ext:'mappings'}, isArray:true},

        getImportTypes: {method: 'GET', params: {ext:'importTypes'}},

        /*getCameraTypes: {method: 'GET', params: {ext:'cameraTypes'}, isArray:false},*/
        getFramerates: {method: 'GET', params: {ext:'vfxsetup/framerates'}, isArray:false},
        getLensTypes: {method: 'GET', params: {ext:'lensTypes'}, isArray:false},


        /* TODO: check if the following resources are in use in preconform */
        getClips: {method: 'GET', params: {ext:'clips'},  isArray:true},
        getClipsCount: {method: 'GET', params: {ext:'clips'}},

        getReels: {method: 'GET', params: {ext:'reels'}, isArray:true},
        addReel: {method:'POST', params: {ext:'reels'}},
        updateReel: {method:'POST', params: {ext:'reels'}},

        getReelShots: {method: 'GET', params: {ext:'reels', action:'shots'}, isArray:true},
        getReelShotsCount: {method: 'GET', params: {ext:'reels', action:'shots', 'countOnly': true}},

        releaseReel: {method:'POST', params: {ext:'reels', action:'release'}},
        transferReel: {method:'POST', params: {ext:'reels', action:'transfer'}},

        getSetup:{method: 'GET', params: {ext:'vfxsetup'}},
        saveSetup:{method: 'POST', params: {ext:'vfxsetup'}},
        updateSetup:{method: 'POST', params: {ext:'vfxsetup', action:'update'}},
        saveSetupVendor:{method: 'POST', params: {ext:'setup', action:'vendors'}}

      }
    );
  }
})();
