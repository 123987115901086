/**
 * @ngdoc directive
 * @name pulseUploadLoader
 * @module pulse
 * @restrict E
 * @description
 * `pulseUploadLoader` this show a loading bar and then a message when finish its set to true. This helps to show when a file its uploaded.
 *
 * @usage
    <pulse-upload-loader 
        start="vm.service[init.service].start"
        finish="vm.service[init.service].finish"
        error="false"
        message="This is finished"
        close-button="true"
        file-quantity="file_quantity"
        open="false"
    ></pulse-upload-loader>
 *
 * @param {boolean} start - (required) This starts the loading bar.
 * @param {boolean} finish - (required) This finishes the loading bar by setting it to 100% and then shows a message if it was set.
 * @param {boolean} error - (optional) This is makes the loading show error colors
 * @param {string} message - (optional) This is the message to show when the loading finishes
 * @param {boolean} close-button - (optional) This set to true keeps the message open and shows a close button to manually close it
 * @param {integer} file-quantity - (optional) This splits the progress line to show more accurate when uploading multiple files
 * @param {boolean} open - (optional) This controls the showing of the message. By default its set to false. Normaly when the loading ends its opens the message but this can open it before it ends
 *
 */

(function() {
    'use strict';

    angular
        .module('pulse')
        .directive('pulseUploadLoader', directive);

    /* @ngInject */

    directive.$inject = ['$log', '$timeout', '$interval', '$window'];

    function directive($log, $timeout, $interval, $window) {
        var directive = {
            restrict: 'E',
            templateUrl: 'views/common/loading/upload_loading.html',
            scope: {
                start: '=',
                finish: '=',
                error: '=',
                message: '=',
                closeButton: '=',
                fileQuantity: '=',
                open: '=',
                warning: '='
            },
            link: linkFunction,
        };

        return directive;

        function linkFunction(scope, element, attr) {
            var load_interval;
            var progress_bar = element.find('.progress-bar');

            // This hangles the progess percentage
            scope.progress = '0%';

            // this is to know how many times to split the progress bar. this helps with multiple files to show a more accurate progress bar
            var file_quantity = scope.fileQuantity || 1;

            /*
             * This handles the logic to update the progress bar
             */
            var progress = function () {
                var window_width = parseInt($window.innerWidth, 10);
                var progress = (parseInt(progress_bar.css('width'), 10) / window_width) * (100 / file_quantity);
                progress += Math.random() * Math.abs(99-progress);

                scope.progress = progress + '%';
            };

            /*
             * This starts the animation of the progress bar and it randomly fills in the bar but will never 
             * reach the end until finish function is run. We do this since we cant calculate the exact porcentage of 
             * the progress but with this the user can know that its on its way
             */
            var start = function () {
                $interval.cancel(load_interval);
                load_interval = $interval(progress, 250);
            }

            /*
             * This finishes the progress bar and opens the message banner
             */
            var finish = function () {
                $interval.cancel(load_interval);
                scope.progress = '100%';
                
                $timeout(function () {
                    scope.open = true;
                }, 500);

                if(!scope.closeButton) {
                    // we wait 5 second to close the finished message
                    $timeout(function () {
                        scope.close();
                    }, 5000);
                }
            }

            /*
             * This closes the message banner and removes the progress bar
             */
            scope.close = function() {
                scope.open = false;
                scope.progress = '0%';

                $timeout(function () {
                    scope.error = false;
                }, 1000);
            }

            // This watches for changes on the start variable to run the start function
            scope.$watch('start', function(new_start) {
                $log.log('upload loader start', new_start);
                if(new_start) {
                    start();
                    scope.start = false;
                }
            }, true);

            // This watches for changes on the finish variable to run the finish function
            scope.$watch('finish', function(new_finish) {
                $log.log('upload loader finish', new_finish);
                if(new_finish) {
                    finish();
                    scope.finish = false;
                }
            }, true);


            // This watches for changes on the multiple variable and runs the progress function to update the bar
            scope.$watch('fileQuantity', function(new_fileQuantity, old_fileQuantity) {
                $log.log('upload loader fileQuantity', new_fileQuantity);
                $log.log('upload loader old fileQuantity', old_fileQuantity);
                if(new_fileQuantity && new_fileQuantity !== old_fileQuantity) {
                    file_quantity = new_fileQuantity;
                    progress();
                }
            }, true);


            // This watches for changes on the open variable that handles the showing of the message
            scope.$watch('open', function(new_open, old_open) {
                $log.log('upload loader open', new_open);
                $log.log('upload loader old open', old_open);
                if(new_open && new_open !== old_open) {
                    file_quantity = new_open;
                }
            }, true);
        }
    }

})();
