'use strict';

/**
 *
 */
angular.module('pulse')
  .filter('nTimeOnly', function() {
    return function(input) {
        var dateObject = new Date(input);
        var tmp = dateObject.getHours();
        var hour = (tmp == 0) ? 12 : ((tmp > 12) ? tmp-12 : tmp);
        var min = (dateObject.getMinutes() < 10) ? '0'+dateObject.getMinutes() : dateObject.getMinutes();
        var meridiem = (tmp > 11) ? 'PM' : 'AM';
        return hour+':'+min+' '+meridiem;
    };
});
