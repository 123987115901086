'use strict';

angular.module('pulse')
    .filter('findParentStateFromSlashes',
     ['$log',
        function($log) {

        return function findParentStateFromSlashes(state){

            if(!angular.isString(state)) {
                $log.error('findParentStateFromSlashes must be a pasted a state string');
                return false;
            }

            var splitState = state.split('/');

            //We are assuming all of our routes are only go two states deep
            //Example: root.app.events  -- we are always looking for the 3rd state
            if(splitState.length>2) {
               return splitState[2];
            }else{
                $log.error('Can not use parent state with only 2 states');
                return false;
            }

        };
}]);
