/**
 * @ngdoc directive
 * @name pulseApplicationMenu
 * @module pulse
 * @restrict E
 * @description
 * `pulseApplicationMenu` a dropdown menu for all pulse applications and authentication
 *
 * @param {string} application - The name of the current application
 * @param {object} items - this is the list of items to be shown in the settings dropdown
 *
 * @usage
 * $root.storage.applications = [{
 *  "lastUpdatedOn": 1498599825751,
 *  "name": "File Manager",
 *  "perProject": true,
 *  "url": "/filemanager/",
 *  "createdOn": 1498599825750,
 *  "id": "5952d19184ef394658fc0abe",
 *  "isActive": true
 * }]
 *
 * <hljs lang="html">
 *      <pulse-application-menu
 *        current-app="DI Pulls"
 *        items="$root.storage.applications"
 *      ></pulse-application-menu>
 * </hljs>
 *
 */

(function() {
    'use strict';

    angular
        .module('pulse')
        .directive('pulseApplicationMenu', pulseApplicationMenu);

    function pulseApplicationMenu() {

        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: "views/common/navigation/applications_menu.html",
            scope: {
                items: '=',
                application: '=',
                toggleImpersonateBanner: '&',
                showImpersonateBanner: '='
            },
            controller: PulseApplicationMenuCtrl,
            link: link
        };
        return directive;

        function link(scope, element, attrs) {}
    }

    PulseApplicationMenuCtrl.$inject = ['$log', '$scope', '$rootScope', '$filter', 'modalManager', '$location'];

    function PulseApplicationMenuCtrl($log, $scope, $rootScope, $filter, modalManager, $location) {

        //A few hardcoded links
        $scope.help = { 'name':'Help', 'icon':'icon-help' };
        $scope.about = { 'name':'About Pulse', 'icon':'icon-FileInfo', 'url':'/profile/#!/about' };
        $scope.logout = { 'name':'Sign Out', 'icon':'icon-logout'};

        //Accounts are private applications per person
        $scope.accounts = [];
        //Applications are all the usable applications for projects
        $scope.applications = [];

        const user = $rootScope.storage.user;
        $scope.isSwitchUserView = user.switchMode ? true : false;

        for(var i=0,l=$scope.items.length; i<l; i=i+1) {

            //ORDER MATTERS FOR THESE applications.
            if($scope.items[i].name === 'Dashboard') {
              $scope.dashboard = $scope.items[i];
            }else{

              //TODO: this is still hack until we do a migration
              // 9/25/2020
              if($scope.items[i].name === 'VFX') {
                $scope.items[i].name = 'VFX Pulls';
                $scope.items[i].url = '/vfxpulls/';
              }

              // TODO: new_filemanager hack! This is if the user is asking for new_filemanager
              // Delete this and the $rootScope once we launch the new file manager
              if($rootScope.storage.user.features && $rootScope.storage.user.features.new_filemanager && $scope.items[i].name === 'File Manager') {
                $scope.items[i].url = '/filemanagerv2/';
              }

              //PerProject are for applications that require projects
              if($scope.items[i].perProject) {
                // TODO: this is a hack to not display Preconform apps. Remove this one we completely remove it from the system
                if($scope.items[i].name !== 'Preconform') {
                    $scope.applications.push($scope.items[i]);
                }
              }else{
                $scope.accounts.push($scope.items[i]);
              }

            }

        }

        //Sort all the applications so they dont shift around everytime someone loads the drawer
        $scope.accounts = $filter('orderBy')($scope.accounts, 'name');
        $scope.applications = $filter('orderBy')($scope.applications, 'name');
        $scope.applications.unshift($scope.dashboard);

        //To check for "Select a project" label into navigation header
        $scope.checkForProjectListPage = $location.path().search('projects');

        //Open the help modal
        //TODO: This should probably be its own directive
        $scope.helpOpen = function() {
            modalManager.openModal({
                templateUrl: 'views/common/modals/help.html',
                controller: 'HelpCtrl',
                windowClass: "help",
                resolve: {
                  body: function () {
                    return '';
                  }
                }
            });
        }
    }
})();
