/**
 * @ngdoc service
 * @name UserPreferenceService
 * @requires UserPreference, $log, $q
 * @description
 * Service for managing user preferences.
 */
(function () {
  "use strict";

  angular
    .module("pulse")
    .service("UserPreferenceService", UserPreferenceService);

  UserPreferenceService.$inject = ["$log", "$q", "UserPreference", '$rootScope'];

  /* @ngInject */
  function UserPreferenceService($log, $q, UserPreference, $rootScope) {

    var service = {
      save: save,
      get: get,
    };

    return service;

    /**
     * This method save user preference for the login user.
     * @param {object} payload 
     * @param { { applicationName: String } } data
     * @returns {Promise}
     */
    function save(payload, data) {

      $log.info("Saving user preference", data);

      const postData = {
        applicationName: data.applicationName,
        data: payload
      };
      
      let result = $q.defer();

      const promise = UserPreference.saveUserPreference(postData).$promise;

      promise.then(response => {

        if (response.code !== 200) {
          throw new Error('API error: ' + response.message);
        }

        result.resolve(response);

      }).catch(err => result.reject(err));

      return result.promise;
    }

    /**
     * This method returns user preferences
     * @returns {Object}
     */
    function get() {

      try {
        
        let userPreferences = $rootScope.storage.user.preferences;
        return userPreferences;

      } catch (error) {
        $log.error('User preferences not available', error);
        return {};
      }
   
    }


  }
})();
