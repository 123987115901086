(function() {
    'use strict';

    angular.module('pulse').directive('pulseDropdownModal', directive);

    directive.$inject = ['$log', 'ServiceManager'];

    function directive($log, ServiceManager) {
        var config = {
            restrict: 'E',
            templateUrl: function(el, attr) {
                if (attr.template !== undefined) {
                    return attr.template;
                } else {
                    return '<h1>error loading template</h1>';
                }
            },
            scope: {
                init: '=',
            },
            link: linkFunc,
            controller: Controller
        };
        return config;

        function linkFunc(scope, el, attr) {
          scope.vm = {
            service : ServiceManager
          };
        }
    }

    Controller.$inject = ['$scope', '$log', 'ServiceManager'];


    function Controller($scope, $log, ServiceManager) {

        if ($scope.init) {
            init();
        }

        function init() {
            $log.log('INFO: Init Download Modal Directive');
            $log.log($scope.init);
        }

        $scope.hideNonPullModal = function() {
            $scope.init.toggleDropdownModal($scope.init.key, false);
            $scope.init.toggleDropdownModal('PULL_ORDER', true);

            $log.log("START PULL_ORDER Download");

            // START PULL_ORDER Download
            ServiceManager.Pulls.downloadOriginalPullFiles($scope.init.responseData);
            return true;
        };

    }
})();
