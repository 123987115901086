/**
 *
 * @ngdoc object
 * @name TaskControlsResource
 * @requires $resource
 *
 * @params {string} - taskControlID, the task controller object ID
 */
(function(){
'use strict';
angular
    .module('pulse')
    .factory('TaskControlsResource', TaskControlsResource);

    TaskControlsResource.$inject = ['$resource', '$log'];

    function TaskControlsResource($resource, $log) {
        return $resource(
            config.baseURL +'/taskcontrols/:taskControlID',
            {taskControlID: '@taskControlID'},
            {
              getTaskControllers: {method: 'GET', isArray: true},
              getTaskController: {method: 'GET', isArray: false},
              deleteTaskController: {method: 'DELETE', isArray: false},
              updateTaskController: {method: 'POST', isArray: false},
            }
        );
    }

})();
