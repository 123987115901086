/**
 * @ngdoc directive
 * @name pulseDragdrop
 * @module pulse
 * @restrict EA
 * @description
 * `pulseDragdrop` provides a dropzone for files and then ingests it into the angular interface.
 *
 * This will also create the overlay when dragged over the specified area.
 *
 * @required
 * init is required with service, method, context_key, resource
 *
 * @usage
 * <hljs lang="html">
 *  <pulse-dragdrop
 *    overlay-is-disabled=false
 *    overlay-html='/views/template.html'
 *    init = "{'service' : 'UploadsParse',
                    'method' : 'filesCheck',
                    'context_key' : 'edls_list',
                    'params' : {type: 'dailies'},
                    TODO: We might make a resource manager to make this cleaner and more centrally located.
                    'resource': {'service': 'Pulls', 'method': 'createUploadResource'},
                    'callback': {'service': 'Pulls', 'method': 'createUploadCallback'},
                    'accepted_extensions': {}
            }">

 *    <div>Content</div>
 *
 *  </pulse-dragdrop>
 * </hljs>
 *
 * @param {boolean} overlayIsDisabled - disable overlay
 * @param {boolean} overlayHtml - in case you want a different template when the overlay appears
 * @param {object} multiple - only one file uploadable unless this is marked as true
 * TODO: it might be better to split this in explicate names?
 * @param {object} init - this takes a few arguments
 * service - {object} the service you want to run
 * method - {object} the method on that service
 * context_key - {string} the context for this feature (usually unique)
 * params - {object} any kind of specific params you need to send with the request
 * resource - {object} the resource for the data to be sent, must have a service and a method in object
 * callback - {function} callback function to tie data back to a service
 * accepted_extensions - {object} the acceptable file extensions this drag and drop can accept
 */

(function() {
  'use strict';

  angular
    .module('pulse')
    .directive('pulseDragdrop', directive);

  /* @ngInject */

  directive.$inject = ['$log'];

  function directive($log) {

    var directive = {
      restrict: 'EA',
      scope: {
        overlayIsDisabled: '=',
        overlayHtml: '=',
        init: '='
      },
      //It appears we need both transclude value true and ng-transclude element in order inject the html correctly.
      transclude: true,
      template: function(el, attr){
        if(typeof attr.multiple === 'undefined' || attr.multiple === false){
          return '<div ngf-drop ngf-change="vm.uploads.onFileSelect($files)">' +'<ng-transclude></ng-transclude>' + '</div>';
        }else{
          return '<div ngf-drop ngf-multiple="true" ngf-change="vm.uploads.onFileSelect($files)">' +'<ng-transclude></ng-transclude>' + '</div>';
        }
      },
      link: linkFunc,
      controller: Controller
    };

    return directive;

    function linkFunc(scope, elem, attr, ctrl) {

      //Create and inject html overlay into the DOM
      var appendTo = angular.element(elem[0].childNodes[0]);
      var template = '<div class="pulseOverlay"><div>Drag &amp; Drop</div></div>';
      var overlayHTML = angular.element(template);
      var overlayIsOn = false;

      elem[0].addEventListener('dragenter', function () {
        if (scope.overlayIsDisabled === true) return;

        if(!overlayIsOn) {
          appendTo.append(overlayHTML);
          overlayIsOn = true;
        }
      }, false);

      elem[0].addEventListener('dragleave', function (event) {
        if (scope.overlayIsDisabled === true) return;

        if(event.target === overlayHTML[0]) {
          if(overlayIsOn) {
            overlayHTML.remove();
            overlayIsOn = false;
            }
        }
      }, false);

      elem[0].addEventListener('drop', function () {
        if (scope.overlayIsDisabled === true) return;

        if(overlayIsOn) {
          overlayHTML.remove();
          overlayIsOn = false;
        }
      }, false);
    }
  }

  Controller.$inject = ['$scope', '$log', 'ServiceManager', 'modalManager'];

  /* @ngInject */
  function Controller($scope, $log, ServiceManager, modalManager) {

    $log.info('Pulse Dragdrop Controller');

    //We need this on the change event for the dragged file
    $scope.vm = {
      uploads: {
        onFileSelect: onFileSelect,
        files: null
      },
      service : ServiceManager
    };

    //This is required
    if ($scope.init){
      init();
    }

    function init(){
      //setContext
      $scope.vm.service[$scope.init.service]['setContext']($scope.init.context_key);

      //setResource
      //TODO: we should find another way to set resources
      if($scope.init.resource.method && $scope.init.resource.service) {
        $log.log('Setting Upload Resource', $scope.init.resource.method, $scope.init.resource.service);
        $scope.init.resource.function = $scope.vm.service[$scope.init.resource.service][$scope.init.resource.method];
        $scope.vm.service[$scope.init.service]['setResource']($scope.init.context_key, $scope.init.resource.function);
      }


      //In case we need special params to the upload requests
      if ( $scope.init.params ){
        $log.log('Add these params to upload requests');
        $scope.vm.service[$scope.init.service]['setParams']($scope.init.context_key, $scope.init.params);
      }

       //In case we need special params to the upload requests
      if ( $scope.init.accepted_extensions ){
        $log.log('Allow only these accepted extensions to upload requests');
        $scope.vm.service[$scope.init.service]['setAcceptedExtensions']($scope.init.context_key, $scope.init.accepted_extensions);
      }

      //In case we need to callback after we finish the upload
      if ( $scope.init.callback ){
        $log.log('Add the callback method to the upload service');
        $scope.init.callback.function = $scope.vm.service[$scope.init.callback.service][$scope.init.callback.method];
        $scope.vm.service[$scope.init.service]['setCallback']($scope.init.context_key, $scope.init.callback.function);
      }


    }

    // Get the file from the uploader input
    function onFileSelect(files) {
      $log.log('On File Select');
      if (files.length == 0) {
        return
      }

      if ($scope.init.context_key == 'files_list' || $scope.init.context_key == 'secondary_list') {
        $scope.vm.service['Metadata'].files = files;
        $scope.vm.service['Metadata'].context_key = $scope.init.context_key;
        if($scope.init.show_modal == 'false') {
          console.log('here')
          $scope.vm.service['Metadata'].updateChosenMapping($scope.init.mapping_val)
        }else{
          chooseMapping();
        }
      }
      // else if ($scope.init.context_key == 'files_list2') {
      //   $scope.vm.service['Metadata'].files = files;
      //   $scope.vm.service['Metadata'].context_key = $scope.init.context_key;
      //   // vm.service[init.service].updateChosenMapping(mapping_val)
      // }
      else {
        $scope.vm.service[$scope.init.service][$scope.init.method]($scope.init.context_key, files);
      }
    }

    /**
    * Opens modal with mapping dropdown to choose
     */
    function chooseMapping() {
      $log.log('chooseMapping');

      modalManager.openModal({
        templateUrl: 'views/templates/files/mapping_type_modal/mapping_type_modal.html',
        windowClass: 'metadata-modal',
        keyboard: false,
        force: false,
      });
    }

    $scope.$on('$destroy', function(){
      $log.info('Destroy Pulse Dragdrop Controller');
    });
  }
})();
