'use strict';

/* Common Services: URL */

angular.module('pulse')
    .factory('URLBuilderSvc',
    [
        function () {
            return {
                GetProjectHandoverURL: function(orgId, divId, projId){
                  return config.baseURL + '/organizations/' + orgId + '/divisions/' + divId + '/projects/' + projId + '/handover';
                },
                GetAddFreelancerURL: function(orgId, divId, projId){
                  return config.baseURL + '/organizations/' + orgId + '/divisions/' + divId + '/projects/' + projId + '/freelancer';
                },
                GetOrganizationLogoUploadURL: function(orgId){
                  return config.baseURL + '/organizations/' + orgId + '/logo';
                },
                GetDivisionLogoUploadURL: function(orgId, divId){
                  return config.baseURL + '/organizations/' + orgId + '/divisions/' + divId + '/logo';
                },
                GetProjectLogoUploadURL: function(orgId, divId, projId){
                  return config.baseURL + '/organizations/' + orgId + '/divisions/' + divId + '/projects/' + projId + '/logo';
                },
                GetOnesheetUploadURL: function(orgId, divId, projId){
                  return config.baseURL + '/organizations/' + orgId + '/divisions/' + divId + '/projects/' + projId + '/oneSheet';
                }
            };
        }
    ]
);
