/**
 *
 * @ngdoc object
 * @name TasksResource
 * @requires $resource
 *
 * @params {string} - extension, extension to the tasks query
 */
(function(){
'use strict';
angular
    .module('pulse')
    .factory('TasksResource', TasksResource);

    TasksResource.$inject = ['$resource', '$log'];

    function TasksResource($resource, $log) {
        return $resource(
            config.baseURL +'/tasks/:extension/:action',
            {extension: '@extension', action: '@action'},
            {
              getTasks: {method: 'GET', params:{extension: 'runners'}, isArray: true},
              restartWorkflows: {method: 'POST', params:{extension: 'workflows', action: 'restart'}, isArray: true},
              pauseWorkflows: {method: 'POST', params:{extension: 'workflows', action: 'pause'}, isArray: true},
              getWorkflow: {method: 'GET', params:{ extension: 'workflows' }, isArray: true},
            }
        );
    }

})();
