/**
 * @ngdoc service
 * @name UserPreference
 * @requires $resource
 * @description
 * Factory that provides methods for interacting with user preferences API.
 */
(function () {
  "use strict";

  angular.module("pulse").factory("UserPreference", UserPreference);

  UserPreference.$inject = ["$resource"];

  /**
   * @ngInject
   * @param {object} $resource - AngularJS $resource service.
   * @returns {object} A $resource object for interacting with user preferences.
   * @description
   * Factory function for creating a $resource object to interact with user preferences.
   */
  function UserPreference($resource) {
    return $resource(config.baseURL + "/users/me", {}, {
      saveUserPreference: {
        method: 'POST',
        isArray: false,
        params: {}
      }
    });
  }
})();
