/*
    @ngdoc overview
    @name ConfirmModalCtrl
    @param {string} title - The title for what the modal is doing.
    @param {string} body - Extra information for the actions of the modal.
    @param {string} button - The executable action.

*/

'use strict';

angular.module('pulse')
    .controller('ConfirmModalCtrl',
    ['$scope', '$log', '$rootScope', '$modalInstance', '$interval', 'title', 'body', 'button',
        function ($scope, $log, $rootScope, $modalInstance, $interval, title, body, button) {

            $log.log('Confirm Modal Controller');

            $rootScope.debounce = false;

            $scope.confirm = {};
            $scope.confirm.title = title;
            $scope.confirm.body = body;
            $scope.confirm.button = button;
            $scope.confirm.closed = false;

            //If we send timeout
/*            $scope.confirm.timer = 30;
            var interval = $interval(function() {

                //If we already went somewhere else -- end the interval
                if($scope.confirm.closed) {
                    $interval.cancel(interval);
                    return false;
                }

                //Times up -- clean yourself up and redirect
                if($scope.confirm.timer === 0 && !$scope.confirm.closed) {
                    $scope.confirm.closed = true;
                    $interval.cancel(interval);
                    $modalInstance.dismiss('cancel');
                }

                if( $scope.confirm.timer>0) {
                    $scope.confirm.timer = $scope.confirm.timer - 1;
                }

            }, 1000);*/


            $scope.confirm.confirm = function() {
                $rootScope.debounce = true;
                $scope.confirm.closed = true;
                //$interval.cancel(interval);
                $modalInstance.close();
                $rootScope.debounce = false;
            };

            $scope.confirm.cancel = function () {
                $scope.confirm.closed = true;
                $rootScope.showSpinner = !$rootScope.showSpinner;
                //$interval.cancel(interval);
                $modalInstance.dismiss('cancel');
            };

            var stateChange = $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
                $log.info('Confirm Controller State Change');
                if(!$scope.confirm.closed) {
                    $scope.confirm.closed = true;
                    $modalInstance.close();
                }
            });

            //Destroy the function on search
            $scope.$on('$destroy', stateChange);

        }]);
