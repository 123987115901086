/**
 * @ngdoc service
 * @name UploadsParseService
 * @module pulse
 * @description
 * `UploadsParseService` provides uploading for files that need to be parsed on the server.
 *
 *  These files are not actually saved in the backend, but the data is.
 *
 * @usage
 * `service.error` major error with the uploading, no files, uploading a file failed
 * `service.bad_files` files that are either hidden, or dont match the import type
 * `service.good_files` files that are that have passed the filesCheck function
 *
 */

(function() {
  'use strict';

  angular
      .module('pulse')
      .service('UploadsParseService', UploadsParseService);

  //TODO: we need to user pulseStorage instead of $rootScope in the future

  UploadsParseService.$inject = ['$log', '$state', '$filter', '$rootScope', '$signalProvider', 'Upload', 'pulseStorage', 'modalManager'];

  /* @ngInject */
  function UploadsParseService($log, $state, $filter, $rootScope, $signalProvider, Upload, pulseStorage, modalManager) {
    //START REVEALED METHODS
    var service = {
      setContext: setContext,
      setParams: setParams,
      setResource: setResource,
      setCallback: setCallback,
      setErrorCallback: setErrorCallback,
      setAcceptedExtensions: setAcceptedExtensions,
      filesCheck: filesCheck
    };

    return service;
    //END REVEALED METHODS

    //START METHODS

    //TODO: we might be able to consolidate setContext, setParams, setResource, setRedirect
    //TODO: Probably should label what params you can set within the parse service

    //This sets the context of the service
    function setContext(context_key) {
      $log.log('setContext', context_key);
      try {

        if (typeof context_key !== 'string' || !context_key){
          throw 'context_key is required';
        }

        //If the context is already created dont override the other one, the developer probably did something wrong or they wanted to use the same context
        if(service[context_key]) {
           $log.log('context_key already has been created');
        }else{
           service[context_key] = {};
        }

      } catch (e) {
        $log.error(e);
      }

    }

    //This sets the params for additional upload information: Example: limit or skip
    function setParams(context_key, params) {
      $log.log('setParams', context_key, params);
      try {

        if (typeof params !== 'object'){
          throw 'params must be an object';
        }

        if(service[context_key]) {
          service[context_key].params = params;
        }else{
          throw 'can not find service with context_key ' + context_key;
        }

      } catch (e) {
        $log.error(e);
      }

    }

    //This sets the resource and service for the upload method, this is usually created in the parent service
    function setResource(context_key, method) {
      $log.log('setResource', context_key, method);
      try {

        if (typeof method !== 'function'){
          throw 'resource must be an function';
        }

        //If the context is already created dont override the other one, the developer probably did something wrong or they wanted to use the same context
        if(service[context_key] && service[context_key].method) {
          $log.log('resource already has been created');
        }else{

          if(service[context_key]) {
            service[context_key].resource = method;
          }else{
            throw 'can not find service with context_key ' + context_key;
          }

        }

      } catch (e) {
        $log.error(e);
      }

    }

    //Sets the redirect once upload is completed (optional)
    function setCallback(context_key, method) {
      $log.log('setCallback', context_key, method);
      try {

        if (typeof method !== 'function'){
          throw 'method name must be an function';
        }

        //If the context is already created dont override the other one, the developer probably did something wrong or they wanted to use the same context
        if(service[context_key] && service[context_key].method) {
          $log.log('state already has been created');
        }else{

          if(service[context_key]) {
            service[context_key].callback = method;
          }else{
            throw 'can not find service with context_key ' + context_key;
          }

        }

      } catch (e) {
        $log.error(e);
      }

    }

    //Sets the redirect once upload errors (optional)
    function setErrorCallback(context_key, method) {
      $log.log('setErrorCallback', context_key, method);
      try {

        if (typeof method !== 'function'){
          throw 'method name must be an function';
        }

        if(service[context_key]) {
          service[context_key].errorCallback = method;
        }else{
          throw 'can not find service with context_key ' + context_key;
        }

      } catch (e) {
        $log.error(e);
      }

    }

    //Sets accepted extensions that are valid for upload: Example: edl, csv can only be uploaded (optional)
    function setAcceptedExtensions(context_key, extensions) {
      $log.log('setAcceptedExtensions', context_key, extensions);
      try {

        if (typeof extensions !== 'object'){
          throw 'extensions name must be an object';
        }

        if(service[context_key]) {
          service[context_key].accepted_extensions = extensions;
        }else{
          throw 'can not find service with context_key ' + context_key;
        }

      } catch (e) {
        $log.error(e);
      }

    }

    //Runs through some basic file checking before uploading
    function filesCheck(context_key, files) {
      $log.info('filesCheck', context_key, files);

      //Make sure we actually have files to process
      if(files.length<=0) {
        return false;
      }

      service[context_key].errors = false;
      service[context_key].bad_files = [];
      service[context_key].good_files = [];
      var init_files_length = files.length;

      for(var i=0;i<init_files_length;i++) {

        //Check to see if it is a hidden file, if it is just ignore it overall
        if(!$filter('hiddenFile')(files[i].name)) {

          files[i].extension = $filter('findExtension')(files[i].name).toLowerCase();

          //Check to see if we passed in any accepted extensions
          if(service[context_key].accepted_extensions) {

            //We need to pass this in to the directive
            if(service[context_key].accepted_extensions.indexOf(files[i].extension) > -1) {
              service[context_key].good_files.push(files[i]);
            }else{
              service[context_key].bad_files.push(files[i]);
            }

          }else{
            //If no extensions, all files are good to go
            service[context_key].good_files.push(files[i]);
          }

        }

      }

      //They didnt upload any correct files stop everything and throw and error.
      if(service[context_key].good_files.length<1) {
        $signalProvider.signal("feedback", ['failure', 'UploadError', 'Uploading wrong file type.']);
        return;
      }

      $rootScope.uploading.status = true; // We are now uploading so status is true
      $rootScope.uploading.done = false; // If we have finished the upload 100%

      $log.info('Load Files for upload');
      var load_file_length = service[context_key].good_files.length;
      for (var i = 0; i < load_file_length; i++) {
        (function (file) {
          upload(context_key, file);
        })(service[context_key].good_files[i]);
      }
    }

    //END METHODS


    //START UTILS

    function upload(context_key, file) {

      $log.log('upload', file);

      //Build the correct object to send
      var file_data = {
        url: service[context_key].resource(context_key),
        method: 'POST',
        withCredentials: true,
        'file': file,
        sendFieldsAs: 'form' // we need to set this so the upload knows to send param data correctly
      };

      //Check for set params
      if(service[context_key].params) {
        file_data.data = service[context_key].params;
      }

      Upload.upload(file_data).success(function (response) {
        $log.log('Upload.upload Success', response);

        $rootScope.uploading.done = true; // We have finished uploading so we are now done
        $rootScope.uploading.status = false; // We are currently not uploading any more

        //Finishes file upload, allows DOM elements to update
        file.done = true;

        //TODO: We need to find a better way to do this
        $rootScope.uploading.cache = $rootScope.uploading.cache || {};
        $rootScope.uploading.cache[context_key] = $rootScope.uploading.cache[context_key] || {};
        $rootScope.uploading.cache[context_key].files = $rootScope.uploading.cache[context_key].files || [];
        $rootScope.uploading.cache[context_key].files.push(file);

        if(service[context_key].callback) {
          service[context_key].callback(context_key, response, file);
        }else{
          $log.warning('No upload callback was set.');
        }

        modalManager.closeCurrentModal();

      }).error(function (error) {
        $log.error('Error Parse File Upload', error);

        if(service[context_key].errorCallback) {
          service[context_key].errorCallback(context_key, file);
        }else{
          $log.warning('No upload errorCallback was set.');
        }

        file.error = error;

        modalManager.closeCurrentModal();

        //Remove the file from the array
        //TODO: Why are we doing this? Should we just mark the file as error?
        var filesLength = service[context_key].good_files.length;
        for(var i=0;i<filesLength;i++) {
          if(service[context_key].good_files[i].name === file.name) {
            service[context_key].good_files.splice(i,1);
            break;
          }
        }

        $rootScope.uploading.done = true; // We have finished uploading so we are now done
        $rootScope.uploading.status = false; // We are currently not uploading any more

        $signalProvider.signal("feedback", ['failure', 'UploadError', 'An error has occurred. Your upload was unsuccessful. If the problem persists, please contact your systems administrator']);
        service[context_key].error = 'An upload was unsuccessful. If problem persists please contact your administrator.';
      });
    }

    //Reset all things on uploads
    function reset() {
      $log.log('Reset Parse Uploads');

      $rootScope.dirty = false; // In case we became dirty some how.
      $rootScope.uploading.done = true; // We have finished uploading so we are now done
      $rootScope.uploading.status = false; // We are currently not uploading any more

      service[context_key].files = [];

      service[context_key].errors = false; // Reset any errors
      service[context_key].bad_files = []; //Reset and incorrect files

      $rootScope.uploading.cache = {}; // Reset all the uploading cache

      //TODO: find out what this is doing.
      $rootScope.storage.uploads = []; //Not sure if this is being used for anything
    }

    //END UTILS


  }
})();
