'use strict';
angular.module('pulse.accordion', ['ui.bootstrap.collapse'])

    .constant('pulseAccordionConfig', {
        closeOthers: true,
        fullHeight: true
    })

    .controller('pulseAccordionController',
    ['$scope', '$attrs', '$log', '$timeout', 'pulseAccordionConfig',
        function ($scope, $attrs, $log, $timeout, pulseAccordionConfig) {

            if(!$scope.config) {
                $log.error('Accordion must have a config attribute object.');
                return false;
            }

            if(!$scope.config.view) {
                $log.error('Accordion must have a config view object.');
                return false;
            }

            // This array keeps track of the accordion groups
            this.groups = [];

            // Ensure that all the groups in this accordion are closed, unless close-others explicitly says not to
            this.closeOthers = function(openGroup) {
                var closeOthers = angular.isDefined($attrs.closeOthers) ? $scope.$eval($attrs.closeOthers) : pulseAccordionConfig.closeOthers;
                if ( closeOthers ) {
                    angular.forEach(this.groups, function (group) {
                        if ( group !== openGroup ) {
                            group.isOpen = false;
                        }
                    });
                }
            };

            //var fullHeight = angular.isDefined($attrs.fullHeight) ? $scope.$eval($attrs.fullHeight) : pulseAccordionConfig.fullHeight;

            // Create watcher for height changes if needed
            //if(fullHeight){
            var that = this;
            $scope.$watch('config.view.height', function(value) {
                $log.log("Height changed...");
                $log.log(value);

                //that.height = value;
                $timeout(function() {
                    that.calcHeight(value);
                });

            }, true);
            //}

            this.calcHeight = function(screenHeight){
                var height = 0;

                if(this.groups.length<1) {
                    $log.log('No Groups have loaded yet');
                    return false;
                }

                for (var k=this.groups.length - 1; k >= 0; k--) {
                    $log.log('Group Height');
                    if(!this.groups[k].isOpen || 1 == 1){
                        var tmpH = this.groups[k].returnHeight();
                        height += tmpH;
                    }
                }

                $log.log('Parent Div');
                $log.log(screenHeight);
                $log.log('Groups Combine Height');
                $log.log(height);
                var panelHeight = screenHeight-height;
                $log.log('Panel Height');
                $log.log(panelHeight);
                $scope.config.activePanelHeight = panelHeight;
                this.panelHeight = panelHeight;
            };

            // This is called from the accordion-group directive to add itself to the accordion
            this.addGroup = function(groupScope) {
                var that = this;
                this.groups.push(groupScope);

                groupScope.$on('$destroy', function (event) {
                    that.removeGroup(groupScope);
                });
            };

            // This is called from the accordion-group directive when to remove itself
            this.removeGroup = function(group) {
                var index = this.groups.indexOf(group);
                if ( index !== -1 ) {
                    this.groups.splice(index, 1);
                }
            };

        }])

// The accordion directive simply sets up the directive controller
// and adds an accordion CSS class to itself element.
    .directive('pulseAccordion',
    function () {
        return {
            restrict:'EA',
            controller:'pulseAccordionController',
            transclude: true,
            replace: true,
            scope: {
                config:'='
            },
            templateUrl: 'views/common/accordion/accordion.html'
        };
    })

// The accordion-group directive indicates a block of html that will expand and collapse in an accordion
    .directive('pulseAccordionGroup',
    function() {
        return {
            require:'^pulseAccordion',         // We need this directive to be inside an accordion
            restrict:'EA',
            transclude:true,              // It transcludes the contents of the directive into the template
            replace: true,                // The element containing the directive will be replaced with the template
            templateUrl:'views/common/accordion/accordion-group.html',
            scope: {
                heading: '@',               // Interpolate the heading attribute onto this scope
                isOpen: '=?',
                isDisabled: '=?',
                isHidden: '=?'
            },
            controller: function() {
                this.setHeading = function(element) {
                    this.heading = element;
                };
            },
            link: function(scope, element, attrs, pulseAccordionCtrl) {

                if(scope.isHidden) {
                    element.hide();
                    return false;
                }

                pulseAccordionCtrl.addGroup(scope);

                scope.$watch('isOpen', function(value) {
                    if ( value ) {
                        pulseAccordionCtrl.closeOthers(scope);
                    }
                });

                scope.toggleOpen = function() {
                    if ( !scope.isDisabled ) {
                        //scope.isOpen = !scope.isOpen;
                        scope.isOpen = true;
                    }
                };

                scope.returnHeight = function(){
                    return element.find(".panel-heading").outerHeight(true);
                };

                // Watch for screen height changes
                scope.$watch(function() {return pulseAccordionCtrl['panelHeight'];}, function(value) {
                    if ( value ) {
                        scope.styles = {
                            "height": pulseAccordionCtrl.panelHeight+"px"
                        }
                    }
                }, true);
            }
        };
    })

// Use accordion-heading below an accordion-group to provide a heading containing HTML
// <accordion-group>
//   <accordion-heading>Heading containing HTML - <img src="..."></accordion-heading>
// </accordion-group>
    .directive('pulseAccordionHeading',
    function() {
        return {
            restrict: 'EA',
            transclude: true,   // Grab the contents to be used as the heading
            template: '',       // In effect remove this element!
            replace: true,
            require: '^pulseAccordionGroup',
            link: function(scope, element, attr, pulseAccordionGroupCtrl, transclude) {
                // Pass the heading to the accordion-group controller
                // so that it can be transcluded into the right place in the template
                // [The second parameter to transclude causes the elements to be cloned so that they work in ng-repeat]
                pulseAccordionGroupCtrl.setHeading(transclude(scope, function() {}));
            }
        };
    })

// Use in the accordion-group template to indicate where you want the heading to be transcluded
// You must provide the property on the accordion-group controller that will hold the transcluded element
// <div class="accordion-group">
//   <div class="accordion-heading" ><a ... accordion-transclude="heading">...</a></div>
//   ...
// </div>
    .directive('pulseAccordionTransclude', function() {
        return {
            require: '^pulseAccordionGroup',
            link: function(scope, element, attr, controller) {
                scope.$watch(function() { return controller[attr.pulseAccordionTransclude]; }, function(heading) {
                    if ( heading ) {
                        element.html('');
//
//                        element.append('<span class="icon-arrowDown" ng-if="isOpen"></span>');
//                        element.append('<span class="icon-arrowRight" ng-if="!isOpen"></span>');

                        element.append(heading);
                    }
                });
            }
        };
    })

    .directive('pulseAccordionWrapper',
    ['$window', '$log', '$timeout', '$interval',
        function($window, $log, $timeout, $interval){
            return {
                scope: {
                    config: '='
                },
                link:function(scope, elem, attrs ) {

                    //TODO: make this the parent of the of accordion
                    //TODO: pass in all the arrays we need to show as an array and determine height based on array count

                    if(!scope.config) {
                        $log.error('Accordion Wrapper must have a config attribute object.');
                        return false;
                    }

                    if(!scope.config.view) {
                        $log.error('Accordion Wrapper must have a config view object.');
                        return false;
                    }

                    $timeout(function() {
                        scope.config.view.height = elem.height();
                    });

                    //TODO: i think this is better then we we have currently, it updates faster too -discuss later
                    var watch;
                    scope.startInterval = function() {
                        //if ( angular.isDefined(watch) ) return;
                        watch = $interval(function() {
                            scope.config.view.height = elem.height();
                        }, 100);
                    };

                    scope.startInterval();

                    scope.$on('$destroy', function() {
                        $interval.cancel(watch);
                    });


                }
            }
        }]);

