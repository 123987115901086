/**
 * @ngdoc directive
 * @name pulseTutorial
 * @module pulse
 * @restrict A
 * @description
 * `pulseTutorial` this is directive that will detect if a user has viewed the application's
 * tutorial, if they have not they will get the applications tutorial
 *
 * @usage
  <div
    id="navigation"
    pulse-tutorial
    user="$root.storage.user"
    application="$root.storage.application"
    service="service"></div> (this is within the navigation html file)
 *
 * @params
 * user (object) - This is the signed in user object most likely from the root scope storage
 * application (object) - This is the current application object most likely from root scope storage
 * service (object) - This is the service where we can run the start tutorial code
 *
 */

(function() {
    'use strict';

    angular
        .module('pulse')
        .directive('pulseTutorial', directive);

    /* @ngInject */

    directive.$inject = ['$log'];

    function directive($log) {
        var directive = {
            restrict: 'A',
            scope:{
              user: '=',
              application: '=',
              service: '='
            },
            link: linkFunction
        };

        return directive;

        function linkFunction(scope, element, attribute) {
            $log.log('Check For Tutorial', scope.user, scope.application, scope.service);

            // Dynamically create the name for look up in features object
            var tutorial_name = scope.application.name + "_tutorial";
            tutorial_name = tutorial_name.toLowerCase();

            // Check the user features and tutorial_name for falsely values
            if(typeof scope.user.features === "undefined" || typeof scope.user.features[tutorial_name] === "undefined" ||  scope.user.features[tutorial_name] === false) {

              //Make sure we have a function to start the tutorial
              if(scope.service.tutorialModal) {
                //Start the tutorial
                scope.service.tutorialModal();
              }else{
                $log.warn('No Tutorial Modal function for this application');
              }

            }

        }
    }
})();
