/**
 * @ngdoc object
 * @name SearchData
 * @requires $resource
 */

(function(){

    'use strict';

    angular
        .module('pulse')
        .factory('SearchData', SearchData);

    SearchData.$inject = ['$resource'];

    function SearchData ($resource) {

        return $resource (
            config.baseURL + '/searchData/:ext',
            {ext: '@ext'},
            {
                pulls: {method: 'GET', params: {ext:'pulls'}},
                clips: {method: 'GET', params: {ext:'clips'}},
                playlists: {method: 'GET', params: {ext:'playlists'}},
                dashboard: {method: 'GET', params: {ext:'dashboard'}},
                usermessages: {method: 'GET', params: {ext:'usermessages'}},
                project: {method: 'GET', params: {ext:'project'}},
                usergroups: {method: 'GET', params: {ext:'usergroups'}}
            }
        );
    }
})();
