/**
 * @ngdoc directive
 * @name pulseTerms
 * @module pulse
 * @restrict E
 * @description
 * `pulseTerms` provides a form for accepting or declining Pulse terms of service.
 *
 * @requires
 *  'nonce' to be within url for the stateparams, to save the to the correct user
 *
 * @usage
 * <hljs lang="html">
 *     <pulse-terms></pulse-terms>
 * </hljs>
 *
 */
(function(){
  'use strict';
  angular
    .module('pulse')
    .directive('pulseTerms', pulseTerms);

  function pulseTerms() {
    var directive = {
      restrict: 'E',
      templateUrl: 'views/directives/login/pulse_terms.html',
      link: PulseTermsLink,
      controller: PulseTermsCtrl
    };
    return directive;

    function PulseTermsLink(scope, elem, attr, ctrl) {}
  }

  PulseTermsCtrl.$inject = ['$scope', '$rootScope', '$log', '$stateParams', '$window', 'Users'];

  /* @ngInject */
  function PulseTermsCtrl($scope, $rootScope, $log, $stateParams, $window, Users) {

    /**
     * @ngdoc function
     * @name accept
     * @description Updating user account with isRegistered. Requires a nonce from the backend.
     *
     * @param (boolean) acceptOrDecline
     * @param (string) nonce
     *
     */
    $scope.updateUserTerms = function() {
      var registration = {};
      registration.acceptOrDecline = true;
      registration.nonce = $stateParams.nonce;

      //Clear out the messages
      $scope.message = '';

      var save = Users.User.registration(registration).$promise;
      save.then(function(user) {

          $log.log('Users.User.registration Success', user);

          // This happens after we go through okta, probably to make sure we validate
          // the user first before we ask them to accept the terms of service
          $window.location = '/dashboard';

        },
        function(error) {

          $log.log('Users.User.registration Failure', error);

          //Give the user information on what is happening.
          $scope.message = 'Sorry, there has been an error. If the problem persists please contact an administrator.';

        });

    };

  }

})();
