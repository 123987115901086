'use strict';

/*
    IG 11.5.15 - Feature flags module
*/
angular.module('pulse.featureFlags', ['ngStorage'])


.run(['$rootScope', '$state', 'featuresSvc', overrideUIRouter])


/*.config(['featuresSvcProvider', initFeatures])*/
/*.config(['$injector', 'featuresSvcProvider', overrideUIRouterStateFn])*/
 
/**
* @ngdoc factory
* @name featuresSvc
* @module pulse.featureFlags
* @description
* Service responsible for maintaining a list of features and conditions under which they are enabled or disabled.
* Provides methods to get a specific feature info, and to determine if a given feature is enabled in the product.
*
*/
.factory('featuresSvc',  ['$localStorage', '$q', featuresSvc])



/**
* @ngdoc directive
* @name featureEnabled
* @module pulse.featureFlags
* @description
* Directive responsible for removing features from the DOM that are not yet enabled in the product.
*
* Note: This is very useful in continuous deployment scenarios.
*
*/
.directive('featureEnabled', ['$log', '$window', 'featuresSvc', featureEnabled]);



/*
  UI-Router overrides to prevent state changes to incomplete (in-development) states
*/
function overrideUIRouter($rootScope, $state, featuresSvc) {

  $rootScope.$on('$stateChangeStart', function(evt, to, params) {    
    if (window.config.features === true) {
      var stateEnabled = (!featuresSvc.getFeature(to.name) || featuresSvc.isEnabled(to.name)); //if state is not in the features array, its enabled, unlike actual 'features'.
      var state =  featuresSvc.getFeature(to.name);
      var stateRedirectRequired = (state && state.enabled && state.hasOwnProperty('redirectState'));
      
      if (stateEnabled && stateRedirectRequired) {        
        evt.preventDefault();
        if (state.reuseParams) {
          $state.go( state.redirectState, params );
        }
        else {
          $state.go( state.redirectState, (state.redirectParams ? state.redirectParams : {}) );
        }
      } 
      else if (!stateEnabled) { //state is disabled but no redirect override has been specified
        evt.preventDefault();
      }
    };
  });
}




/*
  The featuresSvc provider implementation. A provider is required for pre-run-phase configuration.
*/ 
function featuresSvc($localStorage, $q) {
  
  /*
    Initialize the features list. The list can contain IDs of the features that should be hidden or displayed, as well as state names that must be redirected or disabled. 
    -Use the 'name' property for both types (features and states).
    -If a state name is specified:
    --- The state can be disabled using the 'enabled' property - same as for a feature. If a state is disabled, it will simply be ignored. Set enabled to true if you wish to redirect instead. See redirect properties below.
    --- A redirectState property can be specified to redirect to another (e.g. more stable versionX-1) state.
    --- A reuseParams property specifies if original route's parameters should be reused on a redirect.
    --- Use the redirectParams property to override the original route's parameters. Set reuseParams to false in this case.
    ---
    List format: [ { feature: 'featureID or state name', enabled: true/false, redirectState: 'StateredirectStatePossible', reuseParams: true/false, redirectParams = object } ... ]  
    It is possible to initialize the list from a server-based JSON file. If this is required, preload the file and store its content as a global window object.  
    *
    * Sample data:
      [     
        {name: 'virtualScrollPullRequestsVFX',  enabled: true}, //allow feature to render
        {name: 'virtualScrollPullRequestsVFX_Ver2',  enabled: false}, //feature still in development, do not render
        {name: 'asset.metadata', enabled: true, redirectState: 'asset.recipients.overview', reuseParams: true} //asset.metadata here is still in development, so redirect to previous version            
      ];
  */
  var features =  $localStorage.features;
  if (!features) {
    $localStorage.features = [];
    features = $localStorage.features;
  }
  
  
  
  //provider configuration (Configuration phase of app life cycle)
  function init(featuresObj) {
    //if featuresObj is provided, it will override the 'hardcoded' feature list in this service.
    if (!!featuresObj) {
      features = featuresObj;
    }
  }
    
  
  function getFeature(featureName) {
    var retVal = null;
    if (!!featureName) {
      for (var i=0; i<features.length; i++) {
        if (features[i].name === featureName) {
          retVal = features[i];
          retVal.index = i;
          break;
        }
      }
    }
    return retVal;
  }
  

  function isEnabled(feature) {    
    var thisFeature = getFeature(feature);
    var isFeatureEnabled = (thisFeature && thisFeature.enabled);    
    return isFeatureEnabled;
  }
  
  function addFeature(feature) {
    var deferred = $q.defer();

    $localStorage.features.push(feature);   
    deferred.resolve(true);
    
    return deferred.promise;
  }
  
  function updateFeature(feature) {
    var deferred = $q.defer();
    var found = false;
    
    if (!!feature && !!feature.name) {
      for (var i=0; i<features.length; i++) {
        if (features[i].name === feature.name) {
          features[i] = feature;
          found = true;
          break;
        }
      }
    }
    
    if (found) {
      deferred.resolve(true);
    } else {
      deferred.resolve(false);
    }
    
    return deferred.promise;
  }
  
  function deleteFeature(feature) {
    var deferred = $q.defer();

    $localStorage.features.splice( (getFeature(feature.name)).index, 1 );
    deferred.resolve(true);
    
    return deferred.promise;
  }


  return {
    init: init,
    features: features,
    isEnabled: isEnabled,
    getFeature: getFeature,
    addFeature: addFeature,
    updateFeature: updateFeature,
    deleteFeature: deleteFeature
  };  
}




//feature-enabled directive implementation
function featureEnabled($log, $window, featuresSvc) {
  
  var feDirective = {
    restrict: 'AE',
    transclude: 'element',  //entire element, not just its content
    terminal: true,
    priority: 999,
    link: link
  };

  return feDirective;

  function link(scope, element, attrs, ctrl, $transclude) {
    var featureEl, childScope, featureName;
    
    if ($window.config.features === true) {
      var args = attrs.featureEnabled.split(/\s+/);
      featureName = args[0];
      
      if (featuresSvc.isEnabled(featureName)) {
          childScope = scope.$new();
          $transclude(childScope, function(clone) {
              featureEl = clone;
              element.after(featureEl).remove();
          });
      } else {
          if(childScope) { //this is esentially a copy of what ng-if does, as it fits perfectly into our scenario. The scope should always be destroyed before the element is removed. See http://www.bennadel.com/blog/2706-always-trigger-the-destroy-event-before-removing-elements-in-angularjs-directives.htm.
              childScope.$destroy();
              childScope = null;
          }
          if(featureEl) {
              featureEl.after(element).remove();
              featureEl = null;
          }
      }
    }
  }
}



/*
  Initialize the features list. The list can contain IDs of the features that should be hidden or displayed, as well as state names that must be redirected or disabled. 
  -Use the 'name' property for both types (features and states).
  -If a state name is specified:
  --- The state can be disabled using the 'enabled' property - same as for a feature. If a state is disabled, it will simply be ignored. Set enabled to true if you wish to redirect instead. See redirect properties below.
  --- A redirectState property can be specified to redirect to another (e.g. more stable versionX-1) state.
  --- A reuseParams property specifies if original route's parameters should be reused on a redirect.
  --- Use the redirectParams property to override the original route's parameters. Set reuseParams to false in this case.
  ---
  List format: [ { name: 'featureID or state name', enabled: true/false, redirectState: 'StateredirectStatePossible', reuseParams: true/false, redirectParams = object } ... ]  
  It is possible to initialize the list from a server-based JSON file. If this is required, preload the file and store its content as a global window object.  
*/
/*function initFeatures(featuresSvcProvider) {
  featuresSvcProvider.init(window.featureConfigurationJSON ? window.featureConfigurationJSON : null);
}*/