/**
 * @ngdoc object
 * @name feedback
 * @requires $resource
 */

'use strict';

angular.module('pulse')
  .factory('feedback',
    [ function () {

      var _PHRASE01_ = ' has been updated.',
        _PHRASE11_ = ' have been updated.',
        _PHRASE21_ = ' has been created.',
        _PHRASE31_ = ' has been added.',
        _PHRASE41_ = ' has been removed.',
        _PHRASE51_ = ' has been deleted.',
        _PHRASE02_ = ' has not been updated.',
        _PHRASE12_ = ' have not been updated.',
        _PHRASE22_ = ' has not been created.',
        _PHRASE32_ = ' has not been added.',
        _PHRASE42_ = ' has not been removed.',
        _PHRASE52_ = ' has not been deleted.',
        _PHRASE03_ = 'Error: ';

      var notifications = {
        success: {
          TierAsset: 'Tier Success',
          GenerateReportRequest: 'Your report has been sent.',
          Organization: 'Organization' + _PHRASE01_,
          OrganizationAdd: 'Organization' + _PHRASE21_,
          OrganizationUpdate: 'Organization' + _PHRASE01_,
          OrganizationDelete: 'Organization' + _PHRASE51_,

          DivisionAdd: 'Division' + _PHRASE21_,
          DivisionUpdate: 'Division' + _PHRASE01_,
          DivisionDelete: 'Division' + _PHRASE51_,

          DivisionLocation: 'Division location' + _PHRASE31_,
          DivisionProject: 'Division project' + _PHRASE31_,
          DivisionUser: 'Division user' + _PHRASE31_,

          DeleteDivisionLocation: 'Division location' + _PHRASE41_,
          DeleteDivisionProject: 'Division project' + _PHRASE41_,
          DeleteDivisionUser: 'Division user' + _PHRASE41_,

          Project: 'Project' + _PHRASE01_,
          ProjectClone: 'Project has been cloned.',
          ProjectDelete: 'Project' + _PHRASE51_,
          ProjectServer: 'Server' + _PHRASE01_,
          ProjectRegion: 'Region' + _PHRASE01_,

          User: 'User' +  _PHRASE01_,
          UserLocation: 'User location' + _PHRASE01_,
          UserContact: 'User contact' + _PHRASE01_,
          UserProject: 'User project' + _PHRASE01_,
          UserRole: 'User role association' + _PHRASE01_,

          Right: 'Right' + _PHRASE01_,
          RightDelete: 'Right' + _PHRASE51_,

          Role: 'Role' + _PHRASE01_,
          RoleDelete: 'Role' + _PHRASE51_,

          RoleAssigned: 'Role has been assigned.',

          Location: 'Location' + _PHRASE21_,
          LocationDelete: 'Location' + _PHRASE51_,

          Contact: 'Contact' + _PHRASE01_,
          ImageUpload: 'Image' + _PHRASE01_,
          ImportFile: 'File' + _PHRASE01_,
          PlaylistDuplicates : 'Duplicate clips will be omitted.',
          PlaylistCreated: 'Playlist' + _PHRASE21_,
          PlaylistUpdated: 'Playlist' + _PHRASE01_,
          Release: 'Playlist was released successfully.',
          Comment: 'Comment has been added.',
          Message: 'Message has been archived.',
          App: 'Application' + _PHRASE01_,
          InformationSettings: 'Information Settings' + _PHRASE11_,
          SettingsEmailNotification: 'Email Notification Setting' + _PHRASE01_,
          SettingsMobileNotification: 'Mobile Notification Setting' + _PHRASE01_,
          SettingsMetadata: 'Metadata Setting' + _PHRASE01_,
          Clips: 'Clips' + _PHRASE11_,
          ImportCSV: 'CSV import was successful.',
          SentProjectAccess: 'Access to the project has been sent.',
          AddFreelancer: 'New freelancer info has been sent.',
          SentHandoverConfirmAccept: 'Project handover confirmation acceptance has been sent.',
          SentHandoverConfirmReject: 'Project handover confirmation rejection has been sent.',
          UserTermsAccepted: 'User has accepted the terms.',
          UserTermsDeclined: 'User has declined the terms.',
          OktaURL: 'Admin has updated OKTA url.',
          StorageDeviceSave: 'A storage device' + _PHRASE21_,
          StorageDeviceDelete: 'The storage device has been deleted.',
          Categories: 'Categories' + _PHRASE11_,
          FilesUpload: 'The files have been successfully uploaded.',
          SmartSearchSave: 'Smart Search has been saved.',
          SmartSearchDelete: 'Smart Search has been deleted.',
          ReleaseSent: 'File(s) have been released.',
          GroupCreated: 'The group' + _PHRASE21_,
          PoolCreated: 'The server' + _PHRASE21_,
          Group: 'The group' + _PHRASE01_,
          DeleteGroup: 'The group has been deleted.',
          DeletePool: 'The server has been deleted.',
          UpdatePool: 'The server has been updated.',
          DeleteUser: 'The user has been deleted.',
          MetadataUpdated: 'Metadata' + _PHRASE01_,
          Download: 'File download started.',
          Transfer: 'File transfer started.',
          UploadedFiles: 'Files have been uploaded.',
          ReleasePlaylist: 'Playlist has been released.',
          NotificationRulesAdded: 'Rule has been added.',
          NotificationRulesUpdated: 'Rule has been updated.',
          NotificationRuleDelete: 'Rule has been deleted.',
          DeleteItem: 'Selected item(s) have been deleted.',
          AddToPlaylist: 'Selected clip(s) have been added to the playlist.',
          ContactMail: 'Thank you for contacting Technicolor PULSE. Someone will get back to you soon.',
          HelpEmail: 'Your message was emailed to PULSE Help.',
          PullRequest: 'Your pull request has started.',
          AddProjectProducer: 'User assigned as a Producer.',
          RemoveProducerFromProject: 'The Producer has been removed from the project.',
          PullRequestUpdate: 'Your pull request has been updated.',
          VendorAdded: 'Vendor has been added to the project.',
          VendorUpdated: 'Vendor has been updated.',
          VendorDeleted: 'Vendor has been deleted.',
          SetProjectStoragePlan: 'Project storage plan has been updated.',

          FeatureSave: 'Feature Flag has been saved.',
          FeatureUpdate: 'Feature Flag has been updated.',
          FeatureDelete: 'Feature Flag has been deleted.',

          VFX_ProfileSave: "Profile has been saved.",
          VFX_ProfileDelete: "Profile has been deleted.",

          ResolutionDelete: "Resolution deleted successfully.",
          CameraTypeDelete: "Camera Type deleted successfully.",
          ResolutionAdd: "Resolution added successfully.",
          CameraTypeAdd: "Camera Type added successfully.",
          DefaultHandlesSave: "Default Handles saved successfully.",
          DefaultRenderStartSave: "Default Start Frame saved successfully.",
          DefaultProjectCodeSave: "Project Code saved successfully.",
          DefaultFPSSave: "Default FPS saved successfully.",
          DefaultResolutionSave: "Default Resolution saved successfully.",
          OSDVersionSaved: "OSD Version saved successfully.",
          transcodeSetupSaved: "Transcode Setup saved successfully",


          CommentUpdate: "Comment updated successfully.",
          CommentDelete: "Comment deleted successfully.",

          pullUpdated: 'Pull request' + _PHRASE01_,
          pullDelete: 'Pull request' + _PHRASE51_,

          EDL_Upload: "EDL file uploaded successfully.",
          ReelReleased: "Reel has been released.",
          ReelTransfered: "Reel has been transfered.",

          clonePull: 'Pull request has been cloned.',
          vendorUpdated: "Vendor has been updated",

          createRegion: 'Region has been created.',
          deleteRegion: "Region has been deleted."

        },
        failure: {
          TierAsset: 'Tier Error',
          GenerateReportRequest:'Sorry, your report was not sent.',

          Organization: _PHRASE03_ + 'organization' + _PHRASE02_,
          OrganizationAdd: _PHRASE03_ + 'organization' + _PHRASE22_,
          OrganizationUpdate: _PHRASE03_ + 'organization' + _PHRASE02_,
          OrganizationDelete: _PHRASE03_ + 'organization' + _PHRASE52_,

          AddOrganizationDefaultRole: _PHRASE03_ + 'default role has not been added to the project.',
          DivisionAdd: _PHRASE03_ + 'division' + _PHRASE22_,
          DivisionUpdate: _PHRASE03_ + 'division' + _PHRASE02_,
          DivisionDelete: _PHRASE03_ + 'division' + _PHRASE52_,

          DivisionLocation: _PHRASE03_ + 'division location' + _PHRASE32_,
          DivisionProject: _PHRASE03_ + 'division project' + _PHRASE32_,
          DivisionUser: _PHRASE03_ + 'division user' + _PHRASE32_,

          DeleteDivisionLocation: _PHRASE03_ + 'division location' + _PHRASE42_,
          DeleteDivisionProject: _PHRASE03_ + 'division project' + _PHRASE42_,
          DeleteDivisionUser: _PHRASE03_ + 'division user' + _PHRASE42_,

          Project: _PHRASE03_ + 'project' + _PHRASE02_,
          ProjectDelete: _PHRASE03_ + 'project' + _PHRASE52_,

          AddProjectApp: _PHRASE03_ + 'app' + _PHRASE02_,
          ProjectServer: _PHRASE03_ + 'server' + _PHRASE02_,
          ProjectRegion: _PHRASE03_ + 'region' + _PHRASE02_,
          AddProjectDefaultRole: _PHRASE03_ + 'default role has not been added to the project.',
          AddProjectUser: _PHRASE03_ + 'user has not been added to the project.',
          RemoveUserFromProject: _PHRASE03_ + 'user has not been removed from the project.',
          RemoveAppFromProject: _PHRASE03_ + 'app has not been removed from the project.',

          User: _PHRASE03_ + 'user' + _PHRASE02_,
          UserLocation: _PHRASE03_ + 'user location' + _PHRASE02_,
          UserContact: _PHRASE03_ + 'user contact' + _PHRASE02_,
          UserProject: _PHRASE03_ + 'user project' + _PHRASE02_,
          UserRole: 'User role association' + _PHRASE02_,

          ImpersonateUser: _PHRASE03_ + 'Issue encountered while closing impersonation. Please log out and try again.',

          Right: _PHRASE03_ + 'right' + _PHRASE02_,
          RightDelete: _PHRASE03_ + 'right' + _PHRASE52_,

          AddRightToRole: _PHRASE03_ + 'right has not been added to the role.',
          RemoveRightFromRole: _PHRASE03_ + 'right has not been removed from the role.',

          Role: _PHRASE03_ + 'role' + _PHRASE02_,
          RoleDelete: _PHRASE03_ + 'role' + _PHRASE52_,

          AddRoleToUser: _PHRASE03_ + 'role has not been added to this user.',
          RemoveRoleFromUser: _PHRASE03_ + 'role has not been removed from the user.',

          Location: _PHRASE03_ + 'location' + _PHRASE02_,
          LocationDelete: _PHRASE03_ + 'location' + _PHRASE52_,

          Contact: _PHRASE03_ + 'contact' + _PHRASE02_,
          ImageUpload: _PHRASE03_ + 'image' + _PHRASE02_,
          ImportFile: _PHRASE03_ + 'file' + _PHRASE02_,
          Playlist: _PHRASE03_ + 'playlist' + _PHRASE02_,
          PlaylistCreated: _PHRASE03_ + 'playlist was not created.',
          PlaylistUpdated: _PHRASE03_ + 'playlist was not updated.',
          PlaylistNew: _PHRASE03_ + 'new playlist was not created.',
          PlaylistName: _PHRASE03_ + 'playlist must have a name.',
          PlaylistNameChar: _PHRASE03_ + 'playlist name must be longer then one letter.',
          PlaylistClips: _PHRASE03_ + 'playlist must have clip(s).',
          Release: _PHRASE03_ + 'playlist was not released successfully.',
          ReleaseEmpty: _PHRASE03_ + 'release Description cannot be empty .',
          Comment: _PHRASE03_ + 'comment has not been added.',
          Message: _PHRASE03_ + 'message was not archived.',
          App: _PHRASE03_ + 'application' + _PHRASE02_,
          InformationSettings: _PHRASE03_ + 'Information Settings' + _PHRASE12_,
          SettingsEmailNotification: _PHRASE03_ + 'Email Notification Setting' + _PHRASE02_,
          SettingsMobileNotification: _PHRASE03_ + 'Mobile Notification Setting' + _PHRASE02_,
          SettingsMetadata: _PHRASE03_ + 'Metadata Setting' + _PHRASE02_,
          Clips: _PHRASE03_ + 'clips' + _PHRASE12_,
          ImportCSV: _PHRASE03_ + 'CSV import failed.',
          MissingNameCVS: _PHRASE03_ + 'CSV is missing the name field.',
          MissingEmailCVS: _PHRASE03_ + 'CSV is missing the email field.',
          SentProjectAccess: _PHRASE03_ + 'access to the project has not been sent. ',
          AddFreelancer: _PHRASE03_ + 'new freelancer info has not been sent.',
          SentHandoverConfirm: _PHRASE03_ + 'project handover confirmation has not been sent.',
          SentHandoverConfirmInvalid: _PHRASE03_ + 'confirmation for the project handover is invalid. Contact the administrator.',
          UserTerms: _PHRASE03_ + 'the terms were not set.',
          Search: _PHRASE03_ + 'search has failed.',
          Dashboard: 'Search has failed: ',
          OktaURL: _PHRASE03_ + 'admin was unable to update OKTA url.',
          StorageDeviceSave: _PHRASE03_ + 'the storage device' + _PHRASE03_,
          StorageDeviceDelete: _PHRASE03_ + 'the storage device was not deleted.',
          Categories: 'Some categories' + _PHRASE12_,
          //FileUpload: 'The file has not been uploaded.',
          //FilesUpload: 'The file(s) have not been successfully processed.',
          FileComplete: 'The file(s) have not been completed.',
          SmartSearchName: 'Smart Search must have a name.',
          SmartSearchEmpty: 'Can not save an empty search.',
          SmartSearchSave: _PHRASE03_ + 'Smart Search has not been saved.',
          SmartSearchDelete: _PHRASE03_ + 'Smart Search has not been deleted.',
          ReleaseSent: _PHRASE03_ + 'File(s) have not been released.',
          GroupCreated: _PHRASE03_ + 'group was not created.',
          PoolRequired: _PHRASE03_ + 'server is required',
          PoolCreated: _PHRASE03_ + 'server was not created.',
          GroupCreatedUsersFailed: _PHRASE03_ + 'group was created, but users were not saved.',
          PoolCreatedMembersFailed: _PHRASE03_ + 'pool was created, but users/groups were not saved.',
          Group: _PHRASE03_ + 'group' + _PHRASE02_,
          DeleteGroup: _PHRASE03_ + 'group has not been deleted.',
          DeletePool: _PHRASE03_ + 'server has not been deleted.',
          UpdatePool: _PHRASE03_ + 'server has not been updated.',
          DeleteUser: _PHRASE03_ + 'user has not been deleted.',
          MetadataUpdated: 'Metadata' + _PHRASE02_,
          Download: _PHRASE03_ + 'File download can not be started.',
          Transfer: _PHRASE03_ + 'File transfer can not be started.',
          UploadedFiles: _PHRASE03_ + 'Files have been uploaded.',
          ReleasePlaylist: _PHRASE03_ + 'Playlist has not been released.',
          NotificationRulesAdded: 'Rule has not been added.',
          NotificationRulesUpdated: 'Rule has not been updated.',
          NotificationRuleDelete: 'Rule has not been deleted.',
          DeleteItem: 'Selected item(s) have not been deleted.',
          AddToPlaylist: 'Selected clip(s) were not added to the playlist.',
          ContactMail: 'The contact email was not sent.',
          HelpEmail: _PHRASE03_ + 'your Help message was not sent.',
          PullRequest: _PHRASE03_ + 'Your pull request has an error and has not started.',
          PullTransferRequest: _PHRASE03_ + 'Your pull request has an error. Please generate report.',
          AddProjectProducer: _PHRASE03_ + 'User not assigned as a Producer.',
          RemoveProducerFromProject: _PHRASE03_ + 'Producer has not been removed from project.',
          PullRequestUpdate: _PHRASE03_ + 'Your pull request has an error and has not been updated.',
          VendorAdded: _PHRASE03_ + 'Vendor has not been added to the project.',
          VendorUpdated: _PHRASE03_ + 'Vendor has not been updated.',
          VendorDeleted: _PHRASE03_ + 'Vendor has not been deleted.',
          SetProjectStoragePlan: _PHRASE03_ + 'Project storage plan was not updated.',

          FeatureSave: _PHRASE03_ + 'Feature Flag' + _PHRASE32_,
          FeatureUpdate: _PHRASE03_ + 'Feature Flag' + _PHRASE02_,
          FeatureDelete: _PHRASE03_ + 'Feature Flag' + _PHRASE52_,

          VFX_ProfileSave: "Profile has not been saved.",
          VFX_ProfileDelete: "Profile has not been deleted.",

          ResolutionDelete: "Resolution has not been deleted.",
          CameraTypeDelete: "Camera Type has not been deleted.",
          ResolutionAdd: "Resolution has not been added.",
          CameraTypeAdd: "Camera Type has not been added.",
          DefaultHandlesSave: "Default Handles was not saved.",
          DefaultRenderStartSave: "Default Start Frame was not saved.",
          DefaultProjectCodeSave: "Project Code was not saved.",
          DefaultFPSSave: "Default FPS was not saved.",
          DefaultResolutionSave: "Default Resolution was not saved.",
          OSDVersionSaved: "OSD Version was not saved.",
          transcodeSetupSaved: "Transcode Setup was not saved.",

          CommentUpdate: "Comment was not updated.",
          CommentDelete: "Comment was not deleted.",

          pullUpdated: "Pull request was not updated.",
          pullDelete: "Pull request was not deleted.",

          approval: "Error Judging clip.",

          EDL_Upload: "EDL file upload failed.",
          Invalid_File_Type: "",

          ReelReleased: "Reel has not been released.",
          ReelTransfered: "Reel has not been transfered.",

          clonePull: 'Pull could not be cloned',
          vendorUpdated: "Vendor has not been updated",

          createRegion: 'An error occured. Region has not been created.',
          deleteRegion: 'An error occured. Region has not been deleted.',

          apiError: 'Something went wrong, please try again.',
          serverError: 'Sorry, something went wrong. Please try again in a few moments.'

        },
        warning: {
          BigFileUpload: 'The file is greater than 500MB and may take time to upload: ',
        }
      };

      return {
        getNotification :function(data) {
          if(data.length === 2) {
            data[2] = notifications[data[0]][data[1]];
          } else {
            var additional_text = '';

            //Sometimes we just want the response to show the error message
            if(notifications[data[0]][data[1]]) {
              additional_text = notifications[data[0]][data[1]];
            }

            data[3] = additional_text + data[2];
          }
          return data;
        }

      };
    }
    ]
  );
