'use strict';

angular.module('pulse')
    .filter('filterMessages',
    ['$log',
    function($log) {
        return function(array, namespace, context) {

            return array.filter(function(object) {

                if(typeof context === 'string') {

                    var splitNamespace = namespace.split('.');
                    var namespaceLength = splitNamespace.length;
                    if(namespaceLength>1) {

                        for(var i=0;i<namespaceLength;i++) {

                            if(typeof object === 'undefined') {
                                return false;
                                break;
                            }

                            object = object[splitNamespace[i]];
                        }

                        if(object === context) {
                            return true;
                        }

                    }else if (object[namespace] === context) {
                        return true;
                    }

                }else{

                    if(context) {
                        if (!object[namespace]) {
                            return true;
                        }
                    }else{
                        if(object[namespace]) {
                            return true;
                        }
                    }
                }

            });
        }
    }]);
