/**
 * @ngdoc object
 * @name Groups
 * @requires $resource
 */

(function(){

    'use strict';

    angular
        .module('pulse')
        .factory('Groups', Groups);

    Groups.$inject = ['$resource'];

    function Groups ($resource) {

        return {
            ProjectGroups: $resource(
                config.baseURL +'/projects/:projectID/groups/:groupID',
                {projectID: '@projectID', groupID: '@groupID'},
                {
                    getGroup: {method:'GET'},
                    getGroups: {method:'GET', params: {'groupID': 'search'}, isArray: true},
                    getGroupsCount: {method:'GET', params: {'groupID': 'search', 'countOnly': true}},
                    updateGroup: {method:'POST'},
                    deleteGroup: {method:'DELETE'},
                }
            )
        }
    }
})();