/**
 *
 * @ngdoc object
 * @name Pulse Storage Service
 * @description Store data in rootScope, localstorage, indexDB
 * @requires $log, $rootScope
 *
 */

//TODO: These are not complete!!! We need to make these correctly.

(function(){
    "use strict";
    angular.module('pulse')
        .factory('pulseStorage', pulseStorage);

    pulseStorage.$inject = ['$log', '$rootScope'];

    function pulseStorage($log, $rootScope){

        var storage = {
            project : {}
        };

        var service = {
            init: init
        };

        return service;

        //TODO: Functions to set and get stored data.

        function init() {
            checkLocalStorage();
            $log.log('Application Storage', storage);
            $rootScope.storage = storage;
        }

        function checkLocalStorage() {
            //Check to see if we stored a user on unload
            if (localStorage.length > 0 && localStorage.getItem('storage') !== null) {

                var local_storage = JSON.parse(localStorage.getItem('storage')); //unpack data

                //TODO: we should loop through these and add them if they exist.
                storage.uploads = local_storage.uploads || [];
                storage.recipients = local_storage.recipients || [];
                storage.groups = local_storage.groups || [];

                //Clean up the storage since we have it in browser now
                localStorage.removeItem('storage');

            } else {
                storage.uploads = [];
                storage.recipients = [];
                storage.groups = [];
            }
        }
    }

})();
