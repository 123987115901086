/*
    @ngdoc overview
    @name ConfirmModalCtrl
    @param {string} title - The title for what the modal is doing.
    @param {string} body - Extra information for the actions of the modal.
    @param {string} button - The executable action.

*/

'use strict';

angular.module('pulse')
    .controller('InformativeModalCtrl',
    ['$scope', '$log', '$rootScope', '$modalInstance', '$interval', 'title', 'body',
        function ($scope, $log, $rootScope, $modalInstance, $interval, title, body) {

            $log.log('Informative Modal Controller');

            $rootScope.debounce = false;

            $scope.infomative = {};
            $scope.infomative.title = title;
            $scope.infomative.body = body;
            $scope.infomative.closed = false;

            $scope.infomative.confirm = function() {
                $rootScope.debounce = true;
                $scope.infomative.closed = true;
                //$interval.cancel(interval);
                $modalInstance.close();
                $rootScope.debounce = false;
            };

            $scope.infomative.cancel = function () {
                $scope.infomative.closed = true;
                //$interval.cancel(interval);
                $modalInstance.dismiss('cancel');
            };

            var stateChange = $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
                $log.info('Confirm Controller State Change');
                if(!$scope.infomative.closed) {
                    $scope.infomative.closed = true;
                    $modalInstance.close();
                }
            });

            //Destroy the function on search
            $scope.$on('$destroy', stateChange);

        }]);
