/**
 * @ngdoc directive
 * @name pulseCookieBanner
 * @module pulse
 * @restrict E
 * @description
 * `pulseCookieBanner` informs user about cookie policy
 *
 * @usage
 * <hljs lang="html">
 *     <pulse-cookie-banner></pulse-cookie-banner>
 * </hljs>
 *
 */
(function(){
  'use strict';
  angular
    .module('pulse')
    .directive('pulseCookieBanner', pulseCookieBanner);

  function pulseCookieBanner() {
    var directive = {
      restrict: 'E',
      templateUrl: 'views/directives/login/pulse_cookie_banner.html',
      link: PulseCookieBannerLink,
      controller: PulseCookieBannerCtrl
    };
    return directive;

    function PulseCookieBannerLink(scope, elem, attr, ctrl) {}
  }

  PulseCookieBannerCtrl.$inject = ['$scope', '$cookies'];

  /* @ngInject */
  function PulseCookieBannerCtrl($scope, $cookies) {

    $scope.collapse = true;

    var cookie = $cookies.get('gdpr_cookie');

    if(cookie) {
      $scope.collapse = false;
    }

    $scope.closeBanner = function() {
      $scope.collapse = false;

      var forever = new Date('Fri, 31 Dec 9999 23:59:59 GMT')
      $cookies.put('gdpr_cookie', true, {'expires': forever});


    }

  }

})();
