/**
 * @ngdoc directive
 * @name pulsePlaceholder
 * @module pulse
 * @restrict E
 * @description
 * `pulsePlaceholder` provides a placeholder for loading components.
 *
 * template attr is required
 *
 * @usage
 * <hljs lang="html">
 *     <pulse-placeholder total='10' timer="500" template="views/templates/manager_placeholder.html"></pulse-placeholder>
        <tbody
            total="20"
            template="views/templates/pulls/pulls_placeholder.html"
            pulse-placeholder>
        </tbody>
 * </hljs>
 *
 */
(function(){
'use strict';
angular
    .module('pulse')
    .directive('pulsePlaceholder', pulsePlaceholder);

    //loadingPlaceholder.$inject = ['$log', '$timeout'];

    function pulsePlaceholder() {
      var directive = {
        restrict: 'EA',
        //replace: true, //It seems as though replacing causes more probablems then helps
        scope: {
          total : '=', //Must be a int
          timer: '=?', //Must be a int
          init : '=',
          stop : '='
        },
        templateUrl: function(el, attr){
          if(attr.template != undefined){
            return attr.template;
          }else{
            return '<h1>error loading template</h1>';
          }
        },
        link: linkFunc,
        controller: PulsePlaceholderCtrl
      };

      return directive;

      function linkFunc(scope, elem, attr, ctrl) {}

    }

    PulsePlaceholderCtrl.$inject = ['$scope', '$log', '$interval', '$timeout', 'ServiceManager'];

    /* @ngInject */
    function PulsePlaceholderCtrl($scope, $log, $interval, $timeout, ServiceManager) {
      var ctrl = this;

      $scope.vm = {
        service : ServiceManager
      };


      var master_count = 0;

      if(typeof $scope.total === 'Number') {
       $log.error('Placeholder count must be a number.');
       return;
      }

      if(typeof $scope.timer === 'Number') {
       $log.error('Placeholder timer must be a number.');
       return;
      }

      //If the developer wants more items, they can change the count size
      if(!$scope.total) {
        $scope.total = 10;
      }

      //If the developer wants more items, they can change the count size
      if(!$scope.timer) {
        $scope.timer = 200;
      }

      var count = -1; // Start the count at -1.
      var animate = false

      //Create fake items
      $scope.items = [];
      for(var i=0; i < $scope.total; i++) {
        $scope.items.push({id:i, fade: false});
      }


      ctrl.$onInit = function(){
        if (animate === false){
          animate = $interval(toggleFade, $scope.timer);
        }
      }

      //Resets the everything and restarts the $interval
      function resetAll() {
        count = -1;

        $interval.cancel(animate);
        $log.log('stop interval', $scope.stop, animate)
        if ($scope.stop === false){
          angular.forEach($scope.items, function(val, key){
            val.fade = false;
          });
          animate = $interval(toggleFade, $scope.timer);
        }
      }

      function toggleFade() {
        //If we have reached the end of the items reset and return.
        $log.log("toggleFade ----", count, master_count)

        if(count + 1 > $scope.total) {
          resetAll();
          return;
        }

        //Add one to count
        count += 1;
        master_count += 1
        //Make sure we have an item to change fade for.
        if($scope.items[count]) {
          $scope.items[count].fade = true;
        }

      }

      //Destroy controller
      $scope.$on('$destroy', function(){
        $log.log('destory place holder');
        $interval.cancel(animate);
      });

    }

})();
