'use strict';

/**
 *
 * @param txt
 * @returns {*}
 */
var removeChars = function(txt) {
    return txt.replace( /\_/g, ' ' );
};


/**
 *
 */
angular.module('pulse')
    .filter('toSpaces', function() {
            return function(text) {
                if(typeof(text) === 'string') {
                    text = removeChars(text);
                }
                return text;
            };
        });
