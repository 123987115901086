/**
 * @ngdoc object
 * @name VendorsV2Ctrl
 * @requires $log, $stateParams, signalProvider
 */


(function(){
'use strict';

angular.module('pulse')
  .controller('VendorsEditV2Ctrl',
  ['$log', '$scope', 'VendorsService',
  function($log, $scope, VendorsService){

    // {
    //   'service' :'Vendors',
    //   'method' :'initServer',
    //   'context_key' : 'vendors_list',
    //   'context_val' : {},
    //   'disable_setContext' : true
    // }

    var ctrl = this;

    $scope.vm = {
      context_key: 'vendors_list',
      vendors_service: VendorsService
    };


    ctrl.$onInit = function(){
      $log.log('onInit - VendorsEditV2Ctrl', VendorsService);
      VendorsService.initServer('vendors_list');
    }

  }]);

})();
