/**
 * @ngdoc object
 * @name ProjectsCtrl
 * @requires $scope, $state, $stateParams
 */

'use strict';

angular.module('pulse')
    .controller('ProjectsCtrl',
    ['$scope', '$log', '$timeout', '$state', '$rootScope', 'Users', 'PermissionsSvc', 'S3Upload', 'modalManager', 'user', 'projects',
        function ($scope, $log, $timeout, $state, $rootScope, Users, PermissionsSvc, S3Upload, modalManager, user, projects) {

            $scope.projects = {};
            $scope.projects.projects = projects;

            // removed Favorites from the list until we have an endpoint to support it -->  add this object to sort_projects array:
            // {'active':false, 'name':'Favorite'}

            $scope.search_text = '';
            $scope.sort_projects = [{'active':true, 'name':'all'}];

            function checkProjectPermissions(project) {
              $log.log('check project permissions <<<<<<<<<<<<<<<<<<')
              var found = false;
              //This is the length of permissions that our application can accept
              var length = $rootScope.permissions.length;

              //If the application has not permissions then it is probably an application that
              //doesnt need to check projects since the application is super user
              if(!project.permissions) {
                found = true;
              }else {
                //Loop through the project permissions and the accepted application permissions and see if we have one.
                for (var i = 0; i < length; i++) {
                  if (project.permissions.indexOf($rootScope.permissions[i]) > -1) {
                    found = true;
                    break;
                  }
                }
              }

              //If we do have a passing project permission then lets send the user to the application.
              if(found) {
                //Find out where the user needs to go
                //TODO: this is messy for multiple applications, since a lot of our apps arent layout out correctly
                angular.forEach($rootScope.rootStates, function(toState, permission) {

                  //Check the root state and see if we where we can redirect them.
                  //TODO: idk if we even needs this
                  //if(project.permissions.indexOf(permission)>-1) {

                      //TODO: This sucks, mostly because we have two apps in one. Hopefully we can remove this in the future
                      if(project.review && $rootScope.application_name === 'reviewandapprove') {
                        toState  = 'root.app.dashboard.views';
                      }

                      //division and organization id sometimes comes back as one string, we need to check here for that
                      if(project.division) {
                        project.divisionID = project.division.id;
                      }
                      if(project.organization) {
                        project.organizationID = project.organization.id;
                      }
                      $state.go(toState, {
                        projectID: project.id,
                        divisionID: project.divisionID,
                        organizationID: project.organizationID
                      });

                  //}

                });

              }else{
                //If they dont have any permissions for the app, send them to the access page
                $state.go('root.access', {type: 'project'});
              }

            }

            $scope.projects.selectProject = function(project) {

                // we start the loading state when changing the project, this gets set to false when the transition finishes (on pulse_global.state.js)
                $rootScope.project_loading = true;

                $('#slash-id').hide(); // Hide slash
                $('#select-a-project-id').hide(); // Hide "Select a project" label on other pages

                //If we switch projects lets clear out a few things.
                //We are using navigations scope to figure out the previous project
                if(typeof $rootScope.storage.project !== 'undefined' && project.id !== $rootScope.storage.project.id) {
                    //If they are uploading something and its not finished
                    if(!$rootScope.uploading.done) {

                        modalManager.openModal({
                            templateUrl: 'views/common/modals/confirm.html',
                            controller: 'ConfirmModalCtrl',
                            windowClass: 'confirm',
                            resolve: {
                                title: function () {
                                    return 'Are you sure you want to cancel your current uploads?';
                                },
                                body: function () {
                                    //This can accept html
                                    return "Moving to a new project will cancel your current uploads? <br /> Are you sure you want to proceed?";
                                },
                                button: function () {
                                    return 'Confirm';
                                }
                            }
                        }).result.then(function() {
                                S3Upload.dispose(); //reset everything upload related

                                //If they accept
                                // Clear out what they had and update the new project
                                $rootScope.uploading.reset = true;
                                $rootScope.uploading.cache = {};
                                $rootScope.uploading.status = false;
                                $rootScope.uploading.done = true;
                                $rootScope.storage.uploads = [];

                                checkProjectPermissions(project);

                            });

                    }else{

                        S3Upload.dispose(); //reset everything upload related

                        // Clear out what they had and update the new project
                        $rootScope.uploading.reset = true;
                        $rootScope.uploading.cache = {};
                        $rootScope.uploading.status = false;
                        $rootScope.uploading.done = true;
                        $rootScope.storage.uploads = [];

                        checkProjectPermissions(project);

                    }

                }else{
                  checkProjectPermissions(project);
                }

            };
        }]);
