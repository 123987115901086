'use strict';

angular.module('pulse')
    .controller('errorLogModalCtrl',
    ['$scope', '$log', '$rootScope', '$modalInstance', '$interval', 'title', 'errorItems', 'button',
        function ($scope, $log, $rootScope, $modalInstance, $interval, title, errorItems, button) {

            $log.log('errorLog Modal Controller');

            $rootScope.debounce = false;

            $scope.errorLog = {};
            $scope.errorLog.title = title;
            $scope.errorLog.errorItems = errorItems;
            $scope.errorLog.button = button;
            $scope.errorLog.closed = false;

            $scope.errorLog.cancel = function () {
                $scope.errorLog.closed = true;
                $modalInstance.dismiss('cancel');
            };

            var stateChange = $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
                $log.info('errorLog Controller State Change');
                if(!$scope.errorLog.closed) {
                    $scope.errorLog.closed = true;
                    $modalInstance.close();
                }
            });

            $scope.$on('$destroy', stateChange);

        }]);