'use strict';

angular.module('pulse')
    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider

            .state('root.projects', {
                url: '/projects',
                templateUrl: 'views/common/projects.html',
                controller: 'ProjectsCtrl',
                resolve: {
                    projects:
                        ['projects',
                            function (projects) {
                                return projects;
                            }]
                },
                onEnter:
                    ['$q', '$stateParams', '$state', '$rootScope', 'Users', 'PermissionsSvc', 'projects',
                        function($q, $stateParams, $state, $rootScope, Users, PermissionsSvc, projects) {

                            //If they dont have any projects they shouldnt be here.
                            if(!projects.length>0) {
                                window.location = '/dashboard';
                                return false;
                            }

                            //TODO: This is a temp storage device
                            $rootScope.storage.projects = projects;

                            //IG 8.8.16 - REMOVED FOR NOW, ITS NOT WORKING PROPERLY
                            //Automatically put the user into the project since they only have one project
                            /*if(projects.length===1) {


                                //TODO: We need a better way to determine this.
                                var application = $rootScope.rootState;
                                if(projects[0].review && $rootScope.app === 'reviewandapprove') {
                                  application  = 'root.app.dashboard.views';
                                }

                                $state.go(application, {projectID: projects[0].id, divisionID: projects[0].division.id, organizationID: projects[0].organization.id});
                            }*/

                        }]
            });
    }]);
