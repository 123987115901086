'use strict';

/**
 * @name localSearch
 * @description a resuable frontend filter search directive
 * @file /common/search/localSearch.html
 * @param {string} placeHolder - //A placeholder for input box
 * @param {object} sortList -(optional) An array of Object(s) [{'name':'<string>', active:'<boolean>'}].
 * @param {object} list - //the list being filtered by the search term.
 * @param {string} searchModel - Data binding property.  the var should also be in the controller.
 */


angular.module('pulse')

.directive('localSearch', ['$log',
function ($log) {
    return {
        restrict: 'E',
        scope: {
          placeHolder : '@?', //a placeholder for input box
          sortList : '=', // a list of sorting options.  the options must match whats in the controller.
          list: '=', // the list being filtered by the search term
          textString: '@?',
          activeClass: '@?',
          showCustom: '@'
        },
        templateUrl: "views/common/search/localSearch.html",
        controller: ['$log', '$scope', '$filter', '$timeout', function($log, $scope, $filter, $timeout) {
          var timer;
          if(!Array.isArray($scope.list)) {
            $log.error('Local Search requires list attribute to be passed as an array only.');
          }

          if($scope.sortList && !Array.isArray($scope.sortList)) {
            $log.error('Local Search requires sort list attribute to be passed as an array only.');
          }

          $scope.length = $scope.list.length;
          $scope.listCache = angular.copy($scope.list);


          // if we are using sort list select the first filter
          if($scope.sortList && $scope.sortList.length <= 1){
            var listLength = $scope.sortList.length;
            $scope.sortList[0].active = true;

            //Additional quick sorting actions
            //TODO: once we have more use of this we should use the filterList function to actually filter the list
            $scope.sortAction = function(name){
              for(var i=0; i<listLength; i++) {
                if($scope.sortList[i].name === name && $scope.sortList[i].active !== true){
                  $scope.sortList[i].active = !$scope.sortList[i].active;
                  filterList(name);
                }
              }
            }

          }

          function filterList(search_value) {
            if(search_value!=='') {
              $scope.list = $filter('filter')($scope.listCache, {'name':search_value});
              $scope.textString = "SHOWING "+ $scope.list.length +($scope.list.length > 1 ? " RESULTS" : " RESULT");
            }else{
              $scope.list = $scope.listCache;
              if($scope.showCustom != 'true'){
                $scope.textString = '';
              }
            }
          }

          //TODO: we might want to add a collection watcher for lazy loading data.

          //We want to minipulate the array to show the new search results when the model changes.
          $scope.$watch('searchModel',function(new_value, old_value){
            if (new_value !== 'undefined' && new_value !== old_value) {
              filterList(new_value);
            }
          });

          $scope.activeSearch = function() {
            if($scope.showCustom == 'true') {
              $scope.activeClass = 'active-search';
              $scope.placeHolder = 'TYPE TO SEARCH';
            }
          }

          $scope.blurSearch = function($evt) {
            if($scope.showCustom == 'true') {
              var promise = $timeout(function() { $scope.closeSearch($evt); }, 500);
            }
          }

          $scope.cancelSearch = function ($evt) {
            if($evt.keyCode == 27 && $scope.showCustom == 'true'){
              $scope.placeHolder = 'SEARCH';
              $scope.activeClass = '';
              $evt.target.blur();
              $scope.searchModel = '';
            }
          }

          $scope.closeSearch = function ($evt) {
            if($scope.showCustom == 'true'){
              $scope.placeHolder = 'SEARCH';
              $scope.activeClass = '';
              $evt.target.blur();
              $scope.searchModel = '';
            }
          }

        }],
        link: function(scope, element, attrs) {}
    }

}])
