'use strict';

angular.module('pulse')
    .filter('fileSize', function() {
        var units = [
          'bytes',
          'KB',
          'MB',
          'GB',
          'TB',
          'PB'
        ];

        return function fileSize(bytes, precision){
            if ( isNaN( parseFloat( bytes )) || ! isFinite( bytes ) ) {
              return '?';
            }

            var unit = 0;

            while ( bytes >= 1024 ) {
              bytes /= 1024;
              unit ++;
            }

            return bytes.toFixed( + precision ) + ' ' + units[ unit ];
        };
});
