/**
 * @ngdoc object
 * @name Uploads
 * @requires $resource
 */

(function(){

'use strict';

angular
    .module('pulse')
    .factory('UploadsResource', UploadsResource);

    UploadsResource.$inject = ['$resource', '$rootScope'];

    function UploadsResource ($resource, $rootScope) {

        return $resource(
            //TODO: not sure if eventID is the best name for this.
            //TODO: we have $rootScopes in resource paths, probably should remove them when we can
            config.baseURL + '/'+$rootScope.application_name+'/:projectID/:ext/:assetID/:action/:eventID',
            {projectID: '@projectID', ext: '@ext', assetID: '@assetID', eventID: '@eventID'},
            {
                /* TODO: check if the following resources are in use in preconform */
                allocate: {url: config.baseURL + '/filemanager/:projectID/:ext/:assetID/:action', method:'POST', params: {ext:'files'}}, //pass project
                finish: {url: config.baseURL + '/filemanager/:projectID/:ext/:assetID/:action', method:'POST', params: {ext:'files', action:'finish'}}, // pass project and asset
                completed: {url: config.baseURL + '/filemanager/:projectID/:ext/:assetID/:action', method:'POST', params: {ext:'files', action:'completed'}} // Once all uploads have been complete pass project -- need to pass an array of assetIDs
            }
        );
    }
})();
