'use strict';

angular.module('pulse')
    .directive('isDirty', ['$log','$rootScope',
        function ($log, $rootScope) {
            return  {
                restrict: 'A',
                link: function (scope, elm, attrs) {

                    $log.log(elm);

                    elm.on('change', function (event) {

                        $log.log('Changed Information:[' + event + ']');
                        $log.log(event);
                        $rootScope.dirty = true;

                    });
                }
            }
        }]);
