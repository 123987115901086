'use strict';

(function() {

    /**
     * @ngdoc overview
     * @name ngSignaler
     */

    angular.module('ngSignal', []).

    factory('$signalProvider',
        ['$log',
            function($log){

                this.services = {};
                this.pendingSignals = {}; //IG 6.10.15 -- signals waiting for a listener to be registered

                this.listen = function(name, func) { //Add a listener for a specific signal (event). On 'name' event, 'func' function will be executed.

                    if(typeof this.services[name] === 'undefined') {
                        $log.info('Signal - Create New Service: ' + name);
                        this.services[name] = [];
                    }

                    this.services[name].push(func); //Add the function to the object

                    //IG 6.10.15 Execute a pending signal for this listener, if one exists
                    if (this.pendingSignalExists(name)) {
                        this.signal(name, this.pendingSignals[name], false);
                        $log.info('Signal - Executed pending signal ' + name);
                        this.deregisterPendingSignal(name);
                    }
                    
                    return this.services[name].length - 1; // Return the index so we can clean up later;

                };

                //IG 6.10.15 -- waitForListener param added. If a listener has not been registered yet, the signal will wait for a listener to be attached.
                this.signal = function(name, value, waitForListener) { //Signal an event. All pre-registered listeners will be executed immediately.

                    if(typeof this.services[name] === 'undefined' || this.services[name].length === 0) {
                        //$log.info('Signal - No listeners are registered for ' + name);
                        
                        if (waitForListener) {
                            this.registerPendingSignal(name, value);
                        }
                        
                        return;
                    }

                    var services = this.services[name].length;

                    for(var i= 0; i < services; i++) {
                        if (this.services[name][i] != null) {
                            this.services[name][i](name, value);
                        }
                    }

                };
                
                //+ IG 6.10.15 Register and unregister pending signal functions (wait for listener feature)
                this.registerPendingSignal = function(name, value) {
                  this.pendingSignals[name] = value;  
                  $log.info('Signal - Registered pending signal ' + name);
                };
                
                this.deregisterPendingSignal = function(name) {
                  delete this.pendingSignals[name];  
                  $log.info('Signal - Deregistered pending signal ' + name);
                };
                
                this.pendingSignalExists =function(name)
                {
                    return (typeof this.pendingSignals[name] != 'undefined' && this.pendingSignals[name] != null);
                };
                //- IG 6.10.15 Register and unregister pending signal functions (wait for listener feature)

                this.unlisten = function(name, index, all) {

                    if(typeof this.services[name] === 'undefined') {
                        $log.info('Signal - Cannot find service' + name);
                        return;
                    }

                    if(all) {
                         delete this.services[name];
                    }else{
                        this.services[name][index] = null; //IG 3.11.16 - preserve the item at a given index; if we remove array item 0, and then attempt the same on item 1, its now item 0 and not found. //.splice(index, 1);
                    }

                };

                return this;

            }
        ]);

})();