/**
 * @ngdoc object
 * @name PulseStickyFooterComponent
 * @requires $state
 * @description A resuable compent for Pulse navigation
 */

(function(){
  'use strict';

  angular.module('pulse').component('pulseStickyFooter', {
    templateUrl : 'views/common/components/pulse_sticky_footer.html',
    bindings : {},
    controller : Controller
  });

  Controller.$inject = ['$log', '$state'];

  function Controller($log, $state){
    var ctrl = this;

    ctrl.vm = {'show_footer': true,
               'hide_footer': false,
               'animateFooter': animateFooter};

    ctrl.$onInit = function(){
      $log.log('$onInit - pulse sticky footer');
    }

    function animateFooter(show){
        if (show) {
          ctrl.vm.show_footer = true;
          ctrl.vm.hide_footer = false;
        }else{
          ctrl.vm.show_footer = false;
          ctrl.vm.hide_footer = true;
        }
    }

  }
})();
