/**
 * @ngdoc object
 * @name ApplicationRoutes
 * @requires $stateProvider
 * @description Routing for root level application
 */

(function() {
  'use strict';
  angular
    .module('pulse')
    .config(applicationRoutes);

  applicationRoutes.$inject = ['$stateProvider'];

  /* @ngInject */
  function applicationRoutes($stateProvider) {

    $stateProvider
      .state('onboarding', {
        url: '/vfx-pre-pro',
        templateUrl: 'views/onboarding/vfxPreProStart.html',
        resolve:{
          //url verification in base_url(https://vagrant-web-dev-001.local)/landing/#!/vfx-pre-pro/22334234455?token=token
          //jwt_token={
          //  auth_token
          // }
          //$stateParams  - url i
          pools:
            ['$log', '$stateParams', '$rootScope', 'onBoarding',
              function ($log, $stateParams, $rootScope, onBoarding) {
                $rootScope.storage.verificationToken = onBoarding.verifyToken({projectID: $stateParams.queryParma}).$promise;
              }]

        }
      });



  }

})();
