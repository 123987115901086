'use strict';

angular.module('pulse.modalHelper', [])

/**
 * @ngdoc service
 * @name modalManager
 * @module pulse.modalHelper
 * @description
 * Manager service for modal dialogs. Only one modal instance can be active at any given time, and it must provide
 * a mechanism to autoclose as needed.
 *
 *
 * openModal: function(options)     Open a new modal instance. If 'force' option is specified, any existing modal instance will
 *                                  be closed automatically through a 'dismiss' operation. Returns the new modal instance
 *                                  (see getCurrentModal description for list of returned properties/methods).
 *
 * closeCurrentModal: function(dismiss, result)      Close the currently displayed modal instance, if any. No return value.
 *
 *  getCurrentModal: function()     Get the current displayed modal instance. The returned object provides the following members:
 *      close(result) - a method that can be used to close a modal, passing a result
 *      dismiss(reason) - a method that can be used to dismiss a modal, passing a reason
 *      result - a promise that is resolved when a modal is closed and rejected when a modal is dismissed
 *      opened - a promise that is resolved when a modal gets opened after downloading content's template and resolving all variables
 *      rendered - a promise that is resolved when a modal is rendered
 */
.factory('modalManager', ['$log', '$modal', '$templateCache', function($log, $modal, $templateCache) {

    var currentModal = null; //the currently opened modal instance

    var createModalInstance = function(options) {
        var modalInstance = null;
        if (!!options && typeof options === 'object') {

            var modalProperties = {
                //controller: options.controller, //the controller is required
                windowClass: (options.windowClass ? options.windowClass : ''), //any additional window class(es)
                backdrop: (!!options.backdrop ? options.backdrop : 'static'), //making this static background the default for our modals
                keyboard: (!!options.keyboard ? options.keyboard : false), //keyboard is false by default, unless overridden by the consumer
                resolve: ((!!options.resolve ? options.resolve : {})) //if any resolves are provided they will be included in modal instance instantiation
            };

            if (options.controller != undefined){
              modalProperties['controller'] = options.controller;
            };

            if (!!options.templateUrl) { //provide either the template URL or the template, not both
                if ($templateCache.get(options.templateUrl)) { //is the template in our cache? If so, grab its content.

                    var cache = $templateCache.get( options.templateUrl)
                    $log.log('templateCache', cache);
                    $log.log('type', typeof cache);
                    modalProperties.template = (typeof cache === 'string')? cache : cache[1];
                } else {
                      $log.log('templateCache not is cache');
                      modalProperties.templateUrl = options.templateUrl;
                }

            } else if (!!options.template) {
                $log.log('options.template', options.template);
                modalProperties.template = options.template;
            } else {
                $log.warn("Warning: No template was provided for a modal dialog");
            }

            modalInstance = $modal.open(modalProperties);

            /* Wait for any external user action on the modal */
            modalInstance.result.then(function (result) { //If closed externally, clear the currentModal instance
                if (!result || !result.internalModalDestroy) {
                    $log.log('Modal closed with', result);
                    if (!!currentModal) {
                        currentModal = null;
                    }
                }
            }, function (result) { //if dismissed externally, clear current model
                if (!result || !result.internalModalDestroy) {
                    $log.log('Modal dismissed at:', new Date());
                    if (!!currentModal) {
                        currentModal = null;
                    }
                }
            });
        }

        return modalInstance;
    };

    var destroyCurrentModalInstance = function(dismiss, result) {
        if (currentModal) {

            //close the open modal
            if (dismiss) {
                currentModal.dismiss({internalModalDestroy : true}); //reject the 'result' promise of the modal instance
            } else {
                currentModal.close({internalModalDestroy : true}); //resolve the 'result' promise of the modal instance
            }

            currentModal = null;
        }
    };

    return {

        /* Open a new modal instance. If 'force' option is specified, any existing modal instance will be closed automatically with a 'cancel' status. */
        openModal: function(options) {

            if (!options || typeof options != 'object') {
                $log.log('Options for creating a new modal dialog are required and must be provided as an object');
                return;
            }

            if (!!options.force && currentModal) {
                $log.log('Forcefuly terminating existing modal instance', currentModal, 'due to priority dialog with options', options);

                destroyCurrentModalInstance(true, null);

                //open the new modal and save it as current instance
                currentModal = createModalInstance(options);

            } else { //forcefull termination for previous modal instance is disabled, so if another modal is opened already, prevent opening another modal dialog.

                if (currentModal) {
                    $log.log('Unable to open modal dialog with options', options, 'due to existing modal instance', currentModal, " To automatically close any existing modal specify 'force' option.");
                } else {
                    //open the new modal and save it as current instance
                    currentModal = createModalInstance(options);
                }
            }

            return currentModal;
        },

        /* Close the currently displayed modal instance, if any */
        closeCurrentModal: function(dismiss, result) {
            destroyCurrentModalInstance(dismiss, result);
        },

        /* Get the currently displayed modal instance, if any */
        getCurrentModal: function() {
            return currentModal;
        }
    };
}]);

