/**
 * @ngdoc directive
 * @name pulseDetectScrollbar
 * @module pulse
 * @restrict E
 * @description
 * `pulseDetectScrollbar` detects when a scrollbar is added or removed from the DOM
 *
 * @usage
 * <hljs lang="html">
    <table pulse-table-height offset="37">
        <thead class="edl-table">
            <tr>
                <th class="thead--1 field--center"></th>
            </tr>
        </thead>

        <tbody pulse-detect-scrollbar>
            <tr>
                <td class="tbody--1 field--center"></td>
            </tr>
        </tbody>
    </table>
 * </hljs>
 *
 */

(function() {
    'use strict';

    angular
        .module('pulse')
        .directive('pulseDetectScrollbar', directive);

    /* @ngInject */

    directive.$inject = ['$log'];

    function directive($log) {
        var directive = {
            restrict: 'A',
            scope: {},
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attr) {

            //TODO: we could make this more dynamic for horizontal scrollbar too if we wanted too
            var has_horizontal_scrollbar = element[0].scrollWidth > element[0].clientWidth;

            //Watch the height of the element on scrollbar
            scope.$watch(
              function() {
                  return element[0].clientHeight; // This is the height of the scrolling area
              },
              function(new_height, old_height) {
                  $log.log('Client Height changed', new_height, old_height);
                  $log.log('element[0].clientHeight', element[0].clientHeight);
                  $log.log('element[0].scrollHeight', element[0].scrollHeight);

                  //We need to check if scrollbar height is larger then the client height.
                  //The client height is the height of the scroll container, the window that doesnt move when scrolling
                  if(element[0].scrollHeight > element[0].clientHeight) {
                    $log.log('Add scrollbar class to', angular.element(element[0].parentElement));
                    angular.element(element[0].parentElement).addClass('scrollbar');
                  }else{
                    $log.log('remove scrollbar class from', angular.element(element[0].parentElement));
                    angular.element(element[0].parentElement).removeClass('scrollbar');
                  }

              }
            );

        }
    }
})();
