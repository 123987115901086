/**
 * @ngdoc object
 * @name CredentialsResource
 * @requires $resource
 */

(function(){

    'use strict';

    angular
        .module('pulse')
        .factory('CredentialsResource', CredentialsResource);

    CredentialsResource.$inject = ['$resource'];

    function CredentialsResource($resource) {

        return $resource (
            config.baseURL + '/credentials/:credentialID/:ext/:action',
            {ext: '@ext', credentialID: '@credentialID', assetID: '@assetID', action: '@action'},
            {
              getCredentials: {method: 'GET', isArray: true},
              getCredential: {method: 'GET', isArray: false},
            }
        );
    }
})();
