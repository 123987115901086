/**
 * @ngdoc object
 * @name loadingRoute
 * @requires none
 */



(function(){

'use strict';
angular
    .module('pulse')
    .directive('loadingRoute', loadingRoute);
    function loadingRoute() {

        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'views/common/loading/loadingRoute.html'
        };

        return directive;
    }

})();
