'use strict';

/**
 * pulse-carousel directive
 * A general purpose horizontal carousel for viewing a subset of items from a collection (a javascript array).
 *
 */

angular.module('pulse')

.directive('pulseCarousel',
    [ '$filter', '$log','$window','$signalProvider', '$timeout', function ($filter, $log,$window,$signalProvider, $timeout) {
            return {
                restrict: 'AE',
                replace: true,
                templateUrl: 'views/common/carousel/items.html',
                scope: {
                    itemsTemplate: '@', //  the URL for the template for the carousel to use
                    items : '=',        //  the collection of objects to be viewed
                    itemClick: '=',     //  the method to call when each item is clicked - NOT IMPLEMENTED
                    viewCount: '=',      //  the number of items for a single view in the carousel
                    orderBy: '@?', //predicate for the order by - optional - text binding
                    reverseOrder: '@?',   //reverse the order by (true/false) - optional - text binding,
                    cslHeight: '@?', //height of the carousel,
                    alignment: '@?', //  align center. this is optional.  if the attr is not set it will default to left
                    setWidth: '='
                },

                link: function (scope, element, attrs) {

                    element.attr('id', "carousel_" + attrs['items']); //IG 7.16.15 - multiple carousels can exist on the same page. Provide them with unique IDs based on their data set.

                    $log.info('Carousel Directive initialized with id ', element.attr('id'));

                    var viewport = null,
                        itemCount = scope.items.length, //  the number of items in the carousel
                        viewportWidthInPixels = 0,
                        containerLeftPosition = 0,
                        containerRightPosition = 0,
                        containerWidthInPixels = 0,
                        itemWidthInPixels = 0,
                        viewportLeftEdge = 0;

                    var orderBy = $filter('orderBy');
                    scope.orderByPredicate = scope.orderBy || ''; //IG 7.17.15 - removed hardcoded order by clause from the template
                    scope.reverseOrder = scope.reverseOrder || false;
                    scope.selectedItem = null;
                    scope.maxHeight = scope.cslHeight || '100px';
                    scope.maxHeightImportant = 'height : ' + scope.maxHeight.concat(" !important");

                    var buildSortedArray = function() {
                        if (scope.orderByPredicate !== '') { //don't attempt to sort by nothing, the results are not going to be what you expect
                            scope.sortedItemArray = orderBy(scope.items, scope.orderByPredicate, scope.reverseOrder);  //the template has this ordering built-in, so we must repeat it here
                        }
                        else {
                            scope.sortedItemArray = scope.items;
                        }
                        itemCount = scope.items.length;
                    }
                    buildSortedArray();

                    //IG 7.16.15 - provide details of the currently displayed items on this carousel
                    var getActiveItems = function() {
                        var currentView = {};
                        currentView.carouselId = element.attr('id');
                        currentView.count = scope.viewCount > itemCount ? itemCount : scope.viewCount;
                        currentView.activeItems = [];
                        $log.log('containerLeftPosition', containerLeftPosition);
                        currentView.firstVisibleItem = Math.abs(Math.round(containerLeftPosition / itemWidthInPixels)); //zero based indexes throughout
                        currentView.lastVisibleItem = currentView.firstVisibleItem + scope.viewCount;
                        if (currentView.lastVisibleItem > itemCount) {
                            currentView.lastVisibleItem = itemCount;
                        }

                        currentView.activeItems = scope.sortedItemArray.slice(currentView.firstVisibleItem, currentView.lastVisibleItem);
                        $log.info('Active (visible) items in carousel', element.attr('id'), 'are ', currentView.activeItems);

                        return currentView;
                    }


                    /*
                        Adjust the positioning if it starts to get out of sync due to the decimals in our calculations.
                        Calculate the right edge of the last visible item. This is var x = containerLeftPosition + (itemWidthInPixels * currentView.lastVisibleItem).
                        Compare that number to the width of the viewPort. This is viewportWidthInPixels. If the right edge # is larger, the item will be cut off. Move the
                        containerLeftPosition to the left by that difference. containerLeftPosition -=  (x-viewportWidthInPixels);
                        //currentView.lastVisibleItem //right-most item we see
                        //itemWidthInPixels //width of a single item
                        //viewportWidthInPixels //available display space
                        //containerLeftPosition //left edge of the invisible, large container
                    */
                    var adjustFit = function(currentView) {
                        var rightEdge = containerLeftPosition + (itemWidthInPixels * currentView.lastVisibleItem);
                        if (rightEdge > viewportWidthInPixels) {
                            containerLeftPosition -=  (rightEdge-viewportWidthInPixels);
                            scope.containerLeftPositionSTR = containerLeftPosition + 'px';
                            containerRightPosition = containerLeftPosition + containerWidthInPixels;
                        }
                    }

                    var positionButtons = function() {
                        var itemContainer = $(element).find('.carouselItem').first();
                        var rightArrow = $(element).find('.carouselRight').first();
                        var leftArrow = $(element).find('.carouselLeft').first();

                        var itemHeight = parseInt(itemContainer.css('height')); //we want to position in vert center of this. Verify its larger than 1px after removing 'px'

                        if (!isNaN(itemHeight) && itemHeight > 1) {
                            var newTop = (itemHeight/2) - (leftArrow.height() / 2);

                            leftArrow.css('top', newTop); //update the position of the arrows
                            rightArrow.css('top', newTop); //update the position of the arrows
                        }
                    }

                    var unwatchItems = scope.$watch(function() { //watch for changes to the rendered items, and update the button positions
                        return $('.carouselItem').css('height')
                    },
                    function(newValue, oldValue) {
                        if (newValue != oldValue ) {
                            positionButtons();
                        }
                    });

                    var getViewportWidth = function() {
                        return viewport.width();
                    };

                    var updateView = function(resize) {
                        var currentView = getActiveItems();
                        adjustFit(currentView); //adjust the positioning if it starts to get out of sync due to the decimals in our calculations

                        if(!resize) {
                          $signalProvider.signal('carousel:activeItems', currentView);
                        }

                        scope.showLeftArrow = (Math.round(containerLeftPosition) < viewportLeftEdge);  //IG 7.16.15 show left arrow when the container's left edge is to the left of the viewport's left edge
                        scope.showRightArrow = (Math.round(containerRightPosition) > Math.round(getViewportWidth())); //IG 7.16.15 show right arrow when the container's right edge is further than the viewport's right edge
                    };

                    var clickLeft = function() {
                        if( Math.round(containerLeftPosition+itemWidthInPixels) <= viewportLeftEdge) { //IG 7.16.15 - new positioning logic based on the viewport's left edge
                            containerLeftPosition += itemWidthInPixels;
                            scope.containerLeftPositionSTR = containerLeftPosition + 'px';
                            containerRightPosition = containerLeftPosition + containerWidthInPixels;
                            updateView();
                        }
                    };

                    var clickRight = function() {
                        if( Math.round(containerLeftPosition-itemWidthInPixels) < viewportLeftEdge) { //IG 7.16.15 - new positioning logic based on the viewport's left edge
                            containerLeftPosition -= itemWidthInPixels;
                            scope.containerLeftPositionSTR = containerLeftPosition + 'px';
                            containerRightPosition = containerLeftPosition + containerWidthInPixels;
                            updateView();
                        }
                    };

                    //expose the arrow buttons to the client
                    scope.move = function(direction, index) {
                      if (direction === 'left') {
                        clickLeft();
                      } else if (direction === 'right') {
                        clickRight();
                      } else if (direction === 'center') {
                        center(index);
                      }
                    };

                    //user clicked an item. We will track the selected item, but visual indication of what's selected is up to the template (simply applying class 'active' by index will work).
                    scope.itemSelect = function(index, signal) {
                        // set index to 0 when signal is anything other then true.
                        index = (signal) ? index : 0;
                        scope.selectedItem = {'index': index, 'item': scope.sortedItemArray[index]}; //we have an isolate scope, so this variable is unique to our directive's instane. Thus its ok to have more than one carousel on a page and not worry about clashes
                        //if(signal) {
                          $log.info('Selected item', index, 'in carousel', element.attr('id'), 'with value', scope.sortedItemArray[index]);
                          $signalProvider.signal('carousel:selectedItem', {'carouselId': element.attr('id'), 'selectedItem':  scope.selectedItem });
                        //}
                    }

                    //IG 7.17.15 - center an item (by its index) in the view. Used by the Theater Player.
                    var center = function(index) {
                      updateView();


                      //var firstVisibleItem = Math.abs(Math.round(containerLeftPosition / itemWidthInPixels)); //the current (pre-shift) first item's true index


                      /// recentering the selectedItem is no longer a requirement.
                      /// comment this code out for now.  When center is called it will just update the view.
                      // var halfOfVisibleItems = Math.floor(scope.viewCount / 2);
                      // var newLeftMostItemIndex = (index === 0 ? 0 : index - halfOfVisibleItems);
                      //
                      //
                      // if (index === itemCount-1 || index >= (itemCount - halfOfVisibleItems)) { //right-most (last) item or the few right before that one. We can't center on these, but we can make sure the item is in the view
                      //   newLeftMostItemIndex = itemCount - scope.viewCount;
                      // }
                      //
                      // var proposedContainerLeftPosition = -1 * (newLeftMostItemIndex * itemWidthInPixels); //move the new left-most item to the left edge of the viewport  //(index - middleIndex) * -1 * itemWidthInPixels;
                      // var proposedContainerRightPosition = Math.ceil(proposedContainerLeftPosition + containerWidthInPixels);
                      // if (proposedContainerLeftPosition <= viewportLeftEdge && proposedContainerRightPosition >= getViewportWidth()) { //do not attempt to center if we will end up with blank items in the view
                      //     containerLeftPosition = proposedContainerLeftPosition;
                      //     containerRightPosition = proposedContainerRightPosition;
                      //     scope.containerLeftPositionSTR = containerLeftPosition + 'px';
                      //     updateView();
                      // }
                    };


                    //Initialize the directive. Calculate the size and position values needed for carousel-ing. Set initial state of the command buttons, and move to a pre-selected item (if any).
                    scope.init = function(resize) {
                        $log.info('Initializing carousel ', element.attr('id'), ' with ', itemCount, ' item(s)', ' alignment ' , scope.alignment);

                        viewport = element;

                        viewportWidthInPixels = getViewportWidth();
                        $log.log('viewportWidthInPixels', viewportWidthInPixels > 0);
                        if (viewportWidthInPixels > 0) {


                          if(scope.setWidth) {
                            itemWidthInPixels = scope.setWidth;
                          }else{
                            itemWidthInPixels = viewportWidthInPixels / scope.viewCount;
                          }


                          //itemWidthInPixels = viewportWidthInPixels / scope.viewCount;

                          //containerWidthInPercent = (((itemWidthInPixels * itemCount) / viewportWidthInPixels)) * 100.0;
                          //itemWidthInPercent = (itemWidthInPixels / (itemWidthInPixels * itemCount)) * 100.0;

                          scope.containerWidthInPixels = (itemWidthInPixels * itemCount) + 'px';
                          scope.itemWidthInPixels = itemWidthInPixels + 'px';

                          containerWidthInPixels = itemWidthInPixels * itemCount;

                          //containerLeftBoundary = (containerWidthInPixels - (itemWidthInPixels * scope.viewCount)) * -1.0; //left boundary of the larger container
                          containerLeftPosition = viewportLeftEdge; //initial state - left-most (first) item is displayed. Scrolling to the right brings into view the items hidden to the right of the viewport.
                          scope.containerLeftPositionSTR = containerLeftPosition + 'px';
                          containerRightPosition = containerLeftPosition + containerWidthInPixels;

                          updateView(resize); //IG 7.15.15 - show or hide the navigation arrow buttons on init.

                          if(itemCount <= scope.viewCount) { //hide arrows if data fits completely in the viewport
                              scope.showLeftArrow = false;
                              scope.showRightArrow = false;

                              if(itemCount < scope.viewCount) { //center the container when we don't have enough data to fill the viewport
                                  var center_items = ((1.0 - ((itemCount * itemWidthInPixels) / viewportWidthInPixels)) * 0.5) * viewportWidthInPixels;
                                  containerLeftPosition = (scope.alignment === 'center')? center_items : 0;
                                  scope.containerLeftPositionSTR = containerLeftPosition + 'px';
                              }
                          }

                          // I dont see the need for this code?
                          // if (scope.selectedItem != null) { //bring the previously selected item into the view
                          //     itemSwitchAndCenter('resize', {'index': scope.selectedItem.index, 'carouselId': element.attr('id')});
                          // }

                          //We are resizing -- dont reselect the video
                          if(!resize) {
                            var select_idx = (scope.selectedItem != null) ? scope.selectedItem.index : 0;
                            scope.itemSelect(select_idx);
                          }

                        }
                    };

                    //an external object is signaling that a new item should be selected
                    // I dont think we need this function
                    var itemSwitchAndCenter = function(eventName, itemInfo) {
                        if (itemInfo.carouselId === element.attr('id')) {
                            switch (itemInfo.index) {
                                case "previous":
                                    $log.log('itemSwitchAndCenter previous');
                                    var prevItemIndex = scope.selectedItem.index - 1;
                                    if(prevItemIndex < 0) {
                                        prevItemIndex = itemCount-1; //circular linked-list - move to the last item
                                    }
                                    scope.itemSelect(prevItemIndex);
                                    center(prevItemIndex);
                                    break;
                                case "next":
                                    $log.log('itemSwitchAndCenter next');
                                    var nextItemIndex = scope.selectedItem.index + 1;
                                    if(nextItemIndex > itemCount - 1) {
                                        nextItemIndex = 0; //circular linked-list - back to the first item
                                    }
                                    scope.itemSelect(nextItemIndex);
                                    center(nextItemIndex);
                                    break;
                                case "first":
                                    $log.log('itemSwitchAndCenter first');
                                    scope.itemSelect(0);
                                    center(0);
                                    break;
                                case "last":
                                    $log.log('itemSwitchAndCenter last');
                                    scope.itemSelect(itemCount - 1);
                                    center(itemCount - 1);
                                    break;
                                default:
                                    $log.log('itemSwitchAndCenter default');
                                    scope.itemSelect(itemInfo.index);
                                    center(itemInfo.index);
                                  break;
                            }
                        }
                    }

                    var itemSwitch = function(event, itemInfo) {
                        if (itemInfo.carouselId === element.attr('id')) {
                            scope.itemSelect(itemInfo.index);
                        }
                    }

                    var selectionSwitchListener1 = $signalProvider.listen("carousel:switchAndCenter", itemSwitchAndCenter);         //switch to any item by using its Index
                    // var selectionSwitchListener2 = $signalProvider.listen("carousel:GoToPreviousAndCenter", itemSwitchAndCenter);   //switch to the previous item
                    // var selectionSwitchListener3 = $signalProvider.listen("carousel:GoToNextAndCenter", itemSwitchAndCenter);       //switch to the next item
                    // var selectionSwitchListener4 = $signalProvider.listen("carousel:GoToFirst", itemSwitchAndCenter);               //jump to the first item
                    // var selectionSwitchListener5 = $signalProvider.listen("carousel:GoToLast", itemSwitchAndCenter);                //jump to the last item
                    var itemSelectListener = $signalProvider.listen("carousel:itemSelect", itemSwitch);  //item select only, no attempt will be made to center on it at this moment. Useful for notifying the carousel of what should be selected and wait for the carousel to initialize.

                    scope.$on('$destroy', function(){ //if parent scope goes away, so should our listener
                        $log.info('Cleanup the carousel directive on unload', element.attr('id'));
                        $signalProvider.unlisten("carousel:switchAndCenter",selectionSwitchListener1);
                        // $signalProvider.unlisten("carousel:GoToPreviousAndCenter",selectionSwitchListener2);
                        // $signalProvider.unlisten("carousel:GoToNextAndCenter",selectionSwitchListener3);
                        // $signalProvider.unlisten("carousel:GoToFirst",selectionSwitchListener4);
                        // $signalProvider.unlisten("carousel:GoToLast",selectionSwitchListener5);
                        $signalProvider.unlisten("carousel:itemSelect",itemSelectListener);
                        unwatchItems();
                    });

                    //TODO: This is the same thing as width watch?!
                    //reinitialize on browser window resize
                    angular.element($window).on('resize', function () {
                        try {
                            // Passed true here so we dont rerun select on the clip
                            scope.$apply(scope.init(true)); //the resize event is outside of angular domain, so wrapping in $apply. Alternatively use $timeout to initiate a digest...
                        } catch(e) {
                            $timeout(function() {
                               // Passed true here so we dont rerun select on the clip
                                scope.init(true);
                            });
                        }
                    });


                    //TODO: This is the same thing as window resize?!
                    var unwatch = scope.$watch(function() { //watch for changes to the data to which we applied the endless scroll
                        return element.css('width')
                    },
                    function(newValue, oldValue) {
                        if (newValue != '100%' && newValue != '0px') {
                            //$log.log("Carousel ", element.attr('id'), " - initializing");
                            //unwatch(); //watch once
                            // Passed true here so we dont rerun select on the clip
                            scope.init(true);
                        }
                        else {
                            $log.log("carousel uninitialized");
                        }
                    });

                    scope.$watch(function() { //watch for changes to the data to which we applied the endless scroll
                        return scope.items.length
                    },
                    function(newValue, oldValue) {
                        if (newValue != oldValue && typeof newValue != 'undefined') {
                            buildSortedArray();
                            scope.init();
                        }
                    });

                },

            };
        }
    ]);
