'use strict';

/* Services:Messages */

angular.module('pulse')
    .factory('Messages',
    ['$resource',
        function ($resource) {
            return $resource(
                config.baseURL + '/organizations/:orgID/users/:userID/messages/:messageID',
                {orgID: '@orgID', userID: '@userID', messageID: '@messageID'},
                {
                    getUserMessages: { method: 'GET', isArray: true},
                    getUserMessage: { method: 'GET'},
                    deleteUserMessage: { method: 'DELETE'}
                }
            );
        }
    ]
);
