/**
 * @ngdoc directive
 * @name pulseUploadButton
 * @module pulse
 * @restrict E
 * @description
 * `pulseUploadButton` provides a custom button to upload files.
 *
 * @required
 * init is required with service, method, context_key, resource
 *
 * @usage
 * <hljs lang="html">
 *  <pulse-upload-button
       template="views/templates/upload_button.html"
       ng-disabled="vm.service.Pulls.pulls_list.loading"
       ng-class="{disabled : vm.service.Pulls.pulls_list.loading}"
       params="{'type': di_pulls}"
       init = "{
          'service' : 'UploadsParse',
          'method' : 'filesCheck',
          'context_key' : 'mapping',
          'resource': {'service': 'PullMapping', 'method': 'parseColumnsUploadResource'},
          'callback': {'service': 'PullMapping', 'method': 'parseColumnsUploadCallback'},
          'reset': {'service': 'PullMapping', 'method': 'resetUpload'},
          'accepted_extensions': vm.service.$rootScope.storage.importTypes.names
      }">
    </pulse-upload-button>
 * </hljs>
 *
 * TODO: it might be better to split this in explicate names?
 * TODO: what do we do about multiple files being passed to this? Is it just a part of the template?
 * params - {object} any kind of specific params you need to send with the request
 * @param {object} init - this takes a few arguments
 * service - the service you want to run
 * method - the method on that service
 * context_key - the context for this feature (usually unique)
 * resource - {function} the resource for the data to be sent, must have a service and a method in object
 * callback - {function} callback function to tie data back to a service
 * reset - {function} this resets anything in the service optional
 * accepted_extensions - {object} the acceptable file extensions this drag and drop can accept
 */

(function() {
  'use strict';

  angular
    .module('pulse')
    .directive('pulseUploadButton', directive);

  /* @ngInject */

  directive.$inject = ['$log'];

  function directive($log) {
    var directive = {
      restrict: 'E',
      templateUrl: function(el, attr){
        if(attr.template !== undefined){
          return attr.template;
        }else{
          return '<h1>error loading template</h1>';
        }
      },
      scope: {
        files: '=',
        init: '=',
        params: '='
      },
      link: linkFunc,
      controller: Controller
    };

    return directive;

    function linkFunc(scope, el, attr) {}
  }

  Controller.$inject = ['$scope', '$log', 'ServiceManager', 'modalManager'];

  /* @ngInject */
  function Controller($scope, $log, ServiceManager, modalManager) {

    $log.info('Pulse Upload Button Controller');

    //We need this on the change event for the dragged file
    $scope.vm = {
      uploads: {
        onFileSelect: onFileSelect,
        files: null
      },
      service : ServiceManager
    };

    //This is required
    if ($scope.init){
      init();
    }

    function init(){
      //setContext
      $scope.vm.service[$scope.init.service]['setContext']($scope.init.context_key);

      //setResource
      //TODO: we should find another way to set resources
      if($scope.init.resource.method && $scope.init.resource.service) {
        $log.log('Setting Upload Resource', $scope.init.resource.method, $scope.init.resource.service);
        $scope.init.resource.function = $scope.vm.service[$scope.init.resource.service][$scope.init.resource.method];
        $scope.vm.service[$scope.init.service]['setResource']($scope.init.context_key, $scope.init.resource.function);
      }

      //In case we need special params to the upload requests
      if ( $scope.params ){
        $log.log('Add these params to upload requests');
        $scope.vm.service[$scope.init.service]['setParams']($scope.init.context_key, $scope.params);
      }

      //In case we need special params to the upload requests
      if ( $scope.init.accepted_extensions ){
        $log.log('Allow only these accepted extensions to upload requests');
        $scope.vm.service[$scope.init.service]['setAcceptedExtensions']($scope.init.context_key, $scope.init.accepted_extensions);
      }

      //In case we need to callback after we finish the upload
      if ( $scope.init.callback ){
        $log.log('Add the callback method to the upload service');
        $scope.init.callback.function = $scope.vm.service[$scope.init.callback.service][$scope.init.callback.method];
        $scope.vm.service[$scope.init.service]['setCallback']($scope.init.context_key, $scope.init.callback.function);
      }

      //In case we need to callback after we finish the upload
      if ( $scope.init.error_callback ){
        $log.log('Add the callback method to the upload service');
        $scope.init.error_callback.function = $scope.vm.service[$scope.init.error_callback.service][$scope.init.error_callback.method];
        $scope.vm.service[$scope.init.service]['setErrorCallback']($scope.init.context_key, $scope.init.error_callback.function);
      }

      if($scope.files) {
         $scope.vm.service[$scope.init.service][$scope.init.method]($scope.init.context_key, $scope.files);
      }

    }

    // Get the file from the uploader input
    function onFileSelect(files) {
      $log.log('On File Select');

      if (files.length == 0) {
        return
      }

      if ($scope.init.context_key == 'files_list' || $scope.init.context_key == 'secondary_list') {
        $scope.vm.service['Metadata'].files = files;
        $scope.vm.service['Metadata'].context_key = $scope.init.context_key;
        chooseMapping();
      }
      else {
        $scope.vm.service[$scope.init.service][$scope.init.method]($scope.init.context_key, files);
      }
    }

    /**
    * Opens modal with mapping dropdown to choose
     */
    function chooseMapping() {
      $log.log('chooseMapping');

      modalManager.openModal({
        templateUrl: 'views/templates/files/mapping_type_modal/mapping_type_modal.html',
        windowClass: 'metadata-modal',
        keyboard: false,
        force: false,
      });
    }

    $scope.$on('$destroy', function(){
      $log.info('Destroy Pulse Upload Button Controller');

      //In case we need to callback after we finish the upload
      if ( $scope.init.reset ){
        $log.log('Add the callback method to the upload service');
        $scope.init.reset.function = $scope.vm.service[$scope.init.reset.service][$scope.init.reset.method];
        $scope.init.reset.function();
      }
    });

    // This checks for changes on params and updates the service
    $scope.$watch('params', function(new_params, old_params) {
      $log.log('Pulse Upload Button Watch params', new_params);
      $log.log('Pulse Upload Button Watch Old params', old_params);
      if(new_params && new_params !== old_params) {
        $scope.vm.service[$scope.init.service]['setParams']($scope.init.context_key, new_params);
      }
    }, true);

  }
})();
