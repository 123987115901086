'use strict';

angular.module('pulse.d3Charts', [])


.directive('areaChart', [ '$log', '$window', '$parse', '$interpolate', '$signalProvider', 'modalManager', '$filter' , function ($log, $window, $parse, $interpolate, $signalProvider, modalManager, $filter) {


        //generic, prototype based, object constructor factory
        var construct = function(constructor, args) {
                var obj = Object.create(constructor.prototype);
                constructor.apply(obj, args);
                return obj;
        }

        var AreaChart = function(element, data, baselineData, yAxisName, xAxisName, dataLegend, baselineLegend, baselineLegendLine2, dateFormat) {
                this.d3 = $window.d3;
                if (this.d3) {
                        this.element = element;
                        this.data = data;
                        this.baselineData = baselineData;
                        this.yAxisName = yAxisName;
                        this.xAxisName = xAxisName;
                        this.dataLegend = dataLegend;
                        this.baselineLegend = baselineLegend;
                        this.baselineLegendLine2 = baselineLegendLine2;
                        this.dateFormat = dateFormat;
                }
                else {
                        $log.error('d3 library is not available');
                }
        };

        AreaChart.prototype = {
                generateGraph: function() {
                        var thisInstance = this;
                        var margin = { top: 20, right: 60, bottom: 180, left: 120 };
                        var width = 760 - margin.left - margin.right; //Does the size have to be dynamic?
                        var height = 580 - margin.top - margin.bottom;

                        //pre-process the data, if needed
                        this.data.forEach(function(d) {
                                d.date = new Date(d.date);
                                d.amount = +d.amount;
                        })

                        /*var parseDate = this.d3.time.format(this.dateFormat).parse; //a format function that can be applied to data*/

                        /*
                           We'll require the maximum and minimum value of the available data to create the scales on the axes.
                           D3 provides an API method called d3.scale.linear which we'll use to create scales for the axes.
                           d3.scale.linear uses two properties called range and domain to create the scale. Range defines the area available to render the graph,
                           and Domain defines the maximum and minimum values we have to plot in the available space.
                        */
                        var y = this.d3.scaleLinear()
                                .range([height, 0]); //set available space for axis

                        var x = this.d3.scaleTime()
                                .range([0, width]);



                        /*
                           Create the axis using the scales defined above.
                           D3 provides an API method called d3.svg.axis for this.
                        */
                        var xAxis = this.d3.axisBottom()
                                .scale(x)
                                //.orient("bottom") //x-axis on the bottom
                                .tickSize(0, 0)
                                .tickFormat(function(d) {
                                        /*var val = 0;
                                        thisInstance.data.forEach(function(item) { //don't create axis labels for data points that are not part of the dataset.
                                                var formattedDate = thisInstance.d3.time.format("%m/%y")(d);
                                                if(thisInstance.d3.time.format("%m/%y")(item.date) == formattedDate) {
                                                        val = formattedDate;
                                                }
                                        });
                                        return val == 0 ? "" : val;*/

                                        return thisInstance.d3.timeFormat("%m/%y")(d);
                                });


                        var yAxis = this.d3.axisLeft()
                                .scale(y)
                                //.orient("left") //change orientation of y-axis so that its on the left and vertical
                                .tickSize(0, 0)
                                .tickFormat(function(d) {
                                  return $filter('fileSize')(d);
                                });

                        if (thisInstance.baselineData) {
                        //func to render the current plan
                        var currentPlan = this.d3.area()
                                .x(function(d) { return x(d.date); })
                                .y0(height)
                                .y1(function(d) { return y(thisInstance.baselineData.max); })
                                //.interpolate('linear'); //basis for rounded
                        }

                        //define a function to plot the sample data. Will be applied to each data point.
                        var area = this.d3.area()
                                .x(function(d) { return x(d.date); })
                                .y0(height)
                                .y1(function(d) { return y(d.amount); })
                                //.interpolate('linear'); //basis for rounded


                        //line func to work together with the area for highlighting of the actual curve.
                        var lineFunc = this.d3.line()
                                .x(function(d) { return x(d.date); })
                                .y(function(d) { return y(d.amount); })
                                //.interpolate('linear');


                        //create and append the svg drawing area to our element
                        var svg = this.d3.select(this.element).append("svg")
                                .attr("width", width + margin.left + margin.right)
                                .attr("height", height + margin.top + margin.bottom)
                                .attr('class', 'd3GraphSvg')
                                .append("g")
                                .attr("transform", "translate(" + margin.left + "," + margin.top + ")"); //traslate repositions the g element t0 top/left, g is where everything will be rendered




                        /*Dynamically set the scale min/max values. Domain defines the maximum and minimum values we have to plot in the available space.*/
                        x.domain(this.d3.extent(this.data, function(d) { return d.date; })); /*Returns the minimum and maximum value in the given array using natural order. This is equivalent to calling d3.min and d3.max simultaneously.*/

                        var maxY = this.d3.max(this.data, function(d) { return d.amount; });
                        var realMaxY = (this.baselineData ? this.d3.max([maxY, this.baselineData.max], function(d) { return d; }) : maxY );
                        /*if (realMaxY === this.baselineData.max) */
                        realMaxY += (realMaxY * 0.25); //room to grow visible to end users
                        y.domain([0, realMaxY]); //get the max value of data to define top of the range. use min if not starting at zero...


                        //---- Define a gradient for the area chart ----
                        var gradient = svg.append("svg:defs")
                        .append("svg:linearGradient")
                        .attr("id", "gradient")
                        .attr("x1", "0%")
                        .attr("y1", "0%")
                        .attr("x2", "100%")
                        .attr("y2", '100%')
                        /*.attr("gradientUnits", "userSpaceOnUse")*/

                        // Define the gradient colors
                        gradient.append("svg:stop")
                        .attr("offset", "40%")
                        .attr("stop-color",  "rgb(0,83,126)")
                        .attr("stop-opacity", 1);

                        gradient.append("svg:stop")
                        .attr("offset", "80%")
                        .attr("stop-color", "rgb(58,161,126)")
                        .attr("stop-opacity", 1);
                        //---- END Gradient ----------------------------

                        //render the area graph
                        svg.append("path")
                                .datum(this.data) //provide the data for the area() function that will plot the graph
                                .attr("d", area)
                                .attr("class", "area"); //add 'area' class to the generated area object, that way we can define css classes to drive the look and feel of the graphs


                        //append axis
                        svg.append("g")
                                .attr("class", "x axis")
                                .attr("transform", "translate(0," + height + ")")
                                .call(xAxis)
                                .selectAll("text")
                                .attr("transform", "rotate(-45)") //rotate the label on y axis, vertically
                                .attr("y", 12)
                                .attr("x", -30)


                        svg.append("text") //append a 'text' element into this 'g' element of the svg.
                                /*.attr("transform", "rotate(-90)")*/
                                .attr("y", '440') //x and y are absolute
                                .attr("x", '207') //x and y are absolute
                                .attr("dy", "1em") //x and y are absolute coordinates and dx and dy are relative coordinates (relative to the specified x and y)
                                //.style("text-anchor", "end")
                                .attr("class", "label")
                                .text(this.xAxisName);


                        //append axis
                        svg.append("g")
                                .attr("class", "y axis")
                                .call(yAxis) //append the generated y axis. The below lines that follow the .call(render method), apply to the y-axis only
                                .selectAll("text")
                                .attr("x", -14)


                        svg.append("text") //append a 'text' element into this 'g' element of the svg for the y-axis label
                                .attr("transform", "rotate(-90)") //rotate the label on y axis, vertically
                                .attr("y", '-100') //x and y are absolute
                                .attr("x", '-117') //x and y are absolute
                                .attr("dy", "1em") //x and y are absolute coordinates and dx and dy are relative coordinates (relative to the specified x and y)
                                .style("text-anchor", "end")
                                .attr("class", "label")
                                .text(this.yAxisName);





                        //render the line graph. Important: Order matters. Render line last to ensure its on top of the area.
                        svg.append('path')
                                .attr('d', lineFunc(this.data))
                                .attr("class", "line");


                        //---- data legend -------
                        svg.append("rect")
                                .attr("x", 320)
                                .attr("y", 490)
                                .attr("width", 25)
                                .attr("height", 25)
                                .attr("class", "legendRectData");

                        svg.append("text") //append a 'text' element into this 'g' element of the svg for the data legend
                                .attr("y", '490') //x and y are absolute
                                .attr("x", '355') //x and y are absolute
                                .attr("dy", "1em") //x and y are absolute coordinates and dx and dy are relative coordinates (relative to the specified x and y)
                                .attr("class", "label")
                                .text(this.dataLegend);
                        //--- end data legend -----


                        if (thisInstance.baselineData) {
                                //--- baseline legend line 1---
                                svg.append("rect")
                                        .attr("x", 0)
                                        .attr("y", 490)
                                        .attr("width", 25)
                                        .attr("height", 25)
                                        .attr("class", "legendRectBaseline");
                                svg.append("text") //append a 'text' element into this 'g' element of the svg.
                                        .attr("y", '490') //x and y are absolute
                                        .attr("x", '35') //x and y are absolute
                                        .attr("dy", "1em") //x and y are absolute coordinates and dx and dy are relative coordinates (relative to the specified x and y)
                                        .attr("class", "label")
                                        .text(thisInstance.baselineLegend);
                                //--end baseline legend line 1 ----

                                //--- baseline legend line 2 ---
                                svg.append("rect")
                                        .attr("x", 0)
                                        .attr("y", 530)
                                        .attr("width", 25)
                                        .attr("height", 25)
                                        .attr("class", "legendRectBaseline");
                                svg.append("text") //append a 'text' element into this 'g' element of the svg.
                                        .attr("y", '530') //x and y are absolute
                                        .attr("x", '35') //x and y are absolute
                                        .attr("dy", "1em") //x and y are absolute coordinates and dx and dy are relative coordinates (relative to the specified x and y)
                                        .attr("class", "label")
                                        .text(thisInstance.baselineLegendLine2);
                                //--end baseline legend line 2 ----
                        }

                        /* ANIMIATION */
                        /* Add 'curtain' rectangle to hide entire graph */
                        var curtain = svg.append('rect')
                                .attr('x', (-1 * width) -1 )
                                .attr('y', -1 * height)
                                .attr('height', height)
                                .attr('width', width - 1)
                                .attr('class', 'curtain')
                                .attr('transform', 'rotate(180)')
                                .style('fill', 'rgb(12, 30, 53)'); //'rgba(255, 255, 255, 0.1)');

                        /* Optionally add a guideline */
                        /*var guideline = svg.append('line')
                                .attr('stroke', '#333')
                                .attr('stroke-width', 0)
                                .attr('class', 'guide')
                                .attr('x1', 1)
                                .attr('y1', 1)
                                .attr('x2', 1)
                                .attr('y2', height)*/

                        /* Create a shared transition for anything we're animating */
                        var t = svg.transition()
                                .delay(400)
                                .duration(900)
                                .ease(d3.easeCubicInOut)
                                .on('end', function() {
                                   if (thisInstance.baselineData) {         
                                        //render the current plan area
                                        svg.append("path")
                                            .attr("class", "baseline") //add 'area' class to the generated area object, that way we can define css classes to drive the look and feel of the graphs                                                        
                                            .datum(thisInstance.data) //provide the data for the area() function that will plot the graph                                                        
                                            .transition()
                                            .style("opacity",0)
                                            .on('end', function() {
                                            thisInstance.d3.select('path.baseline')
                                                .transition()
                                                .ease(d3.easeLinear, 1, .4)
                                                .duration(500)
                                                .style("opacity", 0.2)
                                                .attr("d", currentPlan)
                                            });
                                    }
                                });

                        t.select('rect.curtain')
                                .attr('width', 0);


                         svg.append("path")
                            .attr("class", "baseline") //add 'area' class to the generated area object, that way we can define css classes to drive the look and feel of the graphs
                            .datum(thisInstance.data) //provide the data for the area() function that will plot the graph
                            .transition()
                            .style("opacity",0);

                        // t.select('rect.curtain')
                        //         .attr('width', 0);

                        /*t.select('line.guide')
                                .attr('transform', 'translate(' + width + ', 0)')*/

                        thisInstance.d3.select("#show_guideline").on("change", function(e) {
                                /*guideline.attr('stroke-width', thisInstance.showGuide ? 1 : 0);*/
                                curtain.attr("opacity", thisInstance.showGuide ? 0.75 : 1);
                        })




                        /*
                            Additional things to remmeber:

                            * Documentation: https://github.com/mbostock/d3/wiki/Gallery

                            * Great tutorial: http://code.tutsplus.com/tutorials/building-a-multi-line-chart-using-d3js-part-2--cms-22973

                            * D3 provides a method called d3.nest which helps to arrange data based on a particular key field. Multiple graphs can be output that way simultaneously.

                            * Add text elements ( .text() ) to provide a 'legend'.

                            * D3 has events. You can have elements respond to clicks. E.g. - click on legend highlights the appropriate graph.

                            * The area generator is designed to work in conjunction with the line generator. For example, when producing an area chart, you might use an area generator with a fill style,
                              and a line generator with a stroke style to emphasize the top edge of the area. Since the area generator is only used to set the d attribute, you can control the appearance
                              of the area using standard SVG styles and attributes, such as fill.

                        */
                }
        }

        return {
                restrict: 'EA',
                replace: true,
                transclude: false,
                template: '<div></div>',
                scope: {
                        chartdata: "=",
                        baselinedata: "=",
                        options: "="
                },
                link: function (scope, element, attrs) {
                        $log.log('Chart', attrs.id, scope.chartdata);

                        element.attr('id', attrs.id);
                        element.addClass('d3Graph');
                        element.css('width', '100%');
                        element.css('height', '100%');

                        var dataLegend = $interpolate(attrs.legend, false)(scope);
                        var baselineLegend = $interpolate(attrs.baselineLegend, false)(scope);
                        var baselineLegendLine2 = $interpolate(attrs.baselineLegend2, false)(scope);
                        var graph = construct(AreaChart, ['#'+attrs.id, scope.chartdata, scope.baselinedata, attrs.yaxisName, attrs.xaxisName, dataLegend, baselineLegend, baselineLegendLine2, attrs.dateFormat]);
                        graph.generateGraph();
                }
        }
}])


