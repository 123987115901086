/**
 * @ngdoc object
 * @name HelpSrv
 * @requires $resource
 */

(function(){

    'use strict';

    angular
        .module('pulse')
        .factory('HelpSrv', HelpSrv);

    HelpSrv.$inject = ['$resource'];

    function HelpSrv ($resource) {

        return $resource (
            config.baseURL + '/help',
            // {message: '@message', frontendHash: '@frontendHash'},
            {},
            {
                saveMessage: {method: 'POST', params: {message:'',url:'',frontendHash:'',agent:''} }
            }
        );
    }
})();