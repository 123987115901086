/**
 * @ngdoc object
 * @name ApplicationModule
 * @requires LOVE
 * @description This file needs _ because order matters when loading the application.
 * We load all our modules here.
 *
 */

(function() {
  'use strict';

  const modules = [
    'ngCookies',
    'ngResource',
    'ngSanitize',
    'ngAnimate',
    'ui.router',
    'ui.bootstrap',
    'ngSignal',
    'ngIdle',
    'pulse.modalHelper',
    'pulse.featureFlags',
    'angular-inview',
    'pulse.polling',
    'pulse.fastClick'
  ];

  //When you want to use mock end to end, we dont want this in real builds.
  if(config.mock) {
    modules.push('ngMockE2E');
  }

  //Init the modules.
  angular.module('pulse', modules);

})();
