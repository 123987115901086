/**
 * @ngdoc object
 * @name RedirectRoutes
 * @requires $urlRouterProvider
 * @description Routing for anything coming to the app from a weird url
 */

(function() {
  'use strict';

  angular
    .module('pulse')
    .config(redirectRoutes);

  redirectRoutes.$inject = ['$urlRouterProvider'];

  /* @ngInject */
  function redirectRoutes($urlRouterProvider) {

    $urlRouterProvider.otherwise('/');

  }

})();
