/**
 * @name pulseInfiniteScroll
 * @description a resuable directive to track scroll and trigger an callback to load more rows
 * @file /lsg/common/directives/pulse_infinte_scroll.directive.js
 * @param {boolean} loadMoreDebounce - //if true, the callback function is working on loading rows
 * @param {function} loadMoreCallback -  a callback function to load more rows.
 * @param {string} loadMoreStatus - // status will be equal to 'list' when a row is done loading.
 * @param {boolean} loadMoreStop - // when the count and table lenght are equal
 */



(function(){
  'use strict';

   angular.module('pulse')
   .directive('pulseInfiniteScroll', pulse_directive)

   pulse_directive.$inject = ['$log', '$window', '$document', '$compile']

   function pulse_directive($log, $window, $document, $compile){
      return {
        restrict: 'A',
        scope: {
          loadMoreDebounce: '=',
          loadMoreStatus: '=',
          loadMoreCallback: '=',
          loadMoreStop: '=',
        },
        link : function (scope, element, attrs){
          var elem = element[0];
          var offsetTop = elem.offsetTop;
          var scroll_type = false;
          var clientHeight = 0;
          var parent_elem;
          var load_more_elem;
          var load_more_elem_id = 'load-more-rows';
          var infinite_loader_id = 'infinte-loader-id';
          var scrollData = undefined;

          angular.forEach(elem.childNodes, function(val, key){
            if (val.nodeName === 'TABLE'){
              scroll_type = val.nodeName;
              parent_elem = val;
            }else if (val.nodeName === 'UL'){
              scroll_type = val.nodeName;
              parent_elem = val;
            }
          });

          /// this will create the elements to load the next table_rows
          /// and load tracker for scrolling.
          load_more_elem = createLoadmoreElem();

          if (scroll_type){
            elem.addEventListener('scroll', loadMore, false);
          }


          /**
           * If the user scrolls to the bottom of the table, and the load more button is not in an
           * error state, then call the load more callback function
           * @returns A function that is being called on scroll.
           */
          function manageFMScroll() {
            if (scope.loadMoreDebounce) return;
            if (scrollData) clearTimeout(scrollData);

            $("#" + load_more_elem_id).remove();

            scrollData = setTimeout(function () {
              if (
                Math.ceil($(".table-wrapper").scrollTop() +
                $(".table-wrapper").innerHeight()) >=
                $(".table-wrapper")[0].scrollHeight
              ) {
                if (scope.loadMoreStop === false) {
                  if (scope.loadMoreStatus === "error") {
                    return;
                  } else {
                    updateLoadmoreElem("visible");
                    scope.loadMoreCallback();
                  }
                } else {
                  updateLoadmoreElem("collapse");
                }
              }
            }, 300);
          }

          function loadMore(event){
            if (scope.loadMoreStatus === 'list' || scope.loadMoreDebounce === false){
              $log.log("START >>>>> debounce = ", scope.loadMoreDebounce );
                
              if (location.pathname.indexOf('filemanagerv2') !== -1) {
                return manageFMScroll();
              }

              var scrollTop = event.target.scrollTop;
              var scrollHeight = event.target.scrollHeight;
              var scrolloffsetHeight = event.target.offsetHeight;
              var load_more_diff = Math.ceil(load_more_elem.offsetTop - load_more_elem.offsetHeight- scrollTop)
              var threshold = (scrolloffsetHeight - load_more_diff);
              if (threshold >= 0 && threshold <= (load_more_elem.offsetHeight) && scope.loadMoreDebounce === false) {
                  if (scope.loadMoreStop === false){
                    if (scope.loadMoreStatus === 'error'){
                      return;
                    }else{
                      updateLoadmoreElem('visible');
                      scope.loadMoreCallback();
                    }
                  }else{
                    updateLoadmoreElem('collapse');
                  }
              }
            }
          }

          function createLoadmoreElem(){
             infiniteLoader();
             var load_tracker = $document[0].createElement("TR");
             load_tracker.id = load_more_elem_id;
             load_tracker.setAttribute('style', 'height:60px;');
             parent_elem.appendChild(load_tracker);
             return load_tracker;
          }

          function infiniteLoader(){
            var is_node = $document[0].getElementById(infinite_loader_id);
            var tbody_node = $document[0].createElement("TBODY");
            tbody_node.id = infinite_loader_id;
            tbody_node.style.visibility = 'collapse';
            for (var row=0, len=2; row < len; row++){
              var tr_node = $document[0].createElement('TR');
              tr_node.classList.add('tr--placeholder');
              var th_total = 7;
              for (var idx=0, td_len=th_total; idx < td_len; idx ++){
                var td = tr_node.insertCell(idx);
                td.className = 'tbody--' + (idx+1);
                td.innerHTML = (idx === 0 ) ? "" : "<div class='gray_bar placeholder_fade_in_out_infinite'>";
              }
              tbody_node.appendChild(tr_node);
            }
            parent_elem.appendChild(tbody_node);
            // var comp = $compile(tbody_node)(scope)
            // parent_elem.appendChild(comp[0]);
          }

          function updateLoadmoreElem(visibility, message){
            var node = $document[0].getElementById(infinite_loader_id);
            if (node){
              node.style.visibility = visibility;
            }
          }

      } /// end link func

   } // end pulse_directive

}

})();
