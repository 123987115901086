'use strict';

/**
 *  Is the date relative to TODAY
 */
angular.module('pulse')
  .filter('nDateRelative', function() {
    return function(input) {
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var dateObject = new Date(input), todaysDate = new Date();
        var day;

        if(dateObject.getFullYear() === todaysDate.getFullYear()) {
            if( (dateObject.getMonth() === todaysDate.getMonth()) && (dateObject.getDate() === todaysDate.getDate()) ) {
                    day = 'Today';
                } else {
                    day = months[dateObject.getMonth()] + " " + dateObject.getDate();
                }
        } else {
            day = dateObject.getFullYear() + ' ' + months[dateObject.getMonth()] + " " +dateObject.getDate();
        }

        return day;
    };
});
