/**
 *
 * @ngdoc object
 * @name PipelinesResource
 * @requires $resource
 *
 * @params {string} - pipelineID, individual pipeline id
 * @params {string} - extension, extension on to the specific pipeline
 */
(function(){
'use strict';
angular
    .module('pulse')
    .factory('PipelinesResource', PipelinesResource);

    PipelinesResource.$inject = ['$resource', '$stateParams', '$log'];

    function PipelinesResource($resource, $stateParams, $log) {
        ///api/v1/pipelines/filteroptions
        return $resource(
            config.baseURL +'/pipelines/:pipelineID/:extension',
            {pipelineID: '@pipelineID', extension: '@extension'},
            {
              getPipelines: {method:'GET', isArray:true},
              getPipeline: {method:'GET'},
              getFilters :{method:'GET', isArray:false, url: config.baseURL + '/pipelines/filteroptions'},
              postPipeline : {method:'POST', isArray:false},
              getWorkflows: {method:'GET', params: {extension:'workflows'}, isArray:true}
            }
        );
    }

})();
