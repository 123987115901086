/**
 * @ngdoc service
 * @name pulseGlobalState
 * @module pulse
 * @description
 * `pulseGlobalState` provides init features for preconform.
 *
 * @usage
 *
 */

//TODO: Fill in the docJS for this
//TODO: remove rootScope.loading and create a more global approach to manage loading of data and disabling functionality
//TODO: We probably want a new global service for just the State events

(function() {
    'use strict';

    angular
        .module('pulse')
        .service('pulseGlobalState', Service);

    Service.$inject = ['$rootScope', '$log', '$transitions', '$timeout', 'modalManager', '$state'];

    /* @ngInject */
    function Service($rootScope, $log, $transitions, $timeout, modalManager, $state) {

        var service = {
          init: init,
          rootToState: '',
          rootToParams: '',
          warning: ''
        };

        return service;

        function init(app, config){
            $log.log('init Globals in pulseGlobalState service', app , config);
            $rootScope.application_name = app;

            //Setting the root scope for resolves
            $rootScope.resolving = false; // Lets the interceptor know if we are inbetween states
            $rootScope.loggedOut = false; // Lets the interface know if we have logged the user out
            $rootScope.polling = true; // We can disable polling for the whole application
            $rootScope.nocache = false; // Turn this to true if we dont want to cache anyting in unload
            $rootScope.dirty = false; // This is global to tell the framework that a form has become dirty
            $rootScope.config = window.config; // Attach config object to angular scope.
            $rootScope.timeout = false; // lets auth.completelogout know to not redirect
            $rootScope.downloading = false;

            $rootScope.debounce = false;

            $rootScope.$stateParams = '';
            $rootScope.toState = '';

            // Large views need to store data at the root level
            //TODO: Uploads Service
            $rootScope.uploading = {};

            //Flags for uploads
            //TODO: Uploads Service
            $rootScope.uploading.status = false; // This lets us know if they have uploaded anything
            $rootScope.uploading.done = true; // This lets us know if the uploads have finished

            //Caching for persistant views
            //TODO: Uploads Service
            $rootScope.uploading.cache = {};

            $transitions.onError({}, function(transition) {
                $log.error('Error Tranistioning States', transition);
                $rootScope.showSpinner = false;
            });

            $transitions.onStart({}, function(transition) {
              $rootScope.showSpinner = true;
              $rootScope.resolving = true;
            });

            $transitions.onSuccess({}, function(transition) {
                $rootScope.resolving = false;
                $rootScope.showSpinner = false;

                var toStateParse = transition.to().name.split('.');
                var fromStateParse = transition.from().name.split('.');

                //Collect the states.
                service.rootToState = transition.to();
                service.rootToParams = transition.params();

                if($rootScope.dirty) {

                    event.preventDefault();

                    $log.log('Dirty Modal');
                    var confirm = modalManager.openModal({
                        templateUrl: 'views/common/modals/unsaved.html',
                        controller: 'ConfirmModalCtrl',
                        windowClass: 'confirm',
                        resolve: {
                            title: function () {
                                return 'There are unsaved changes.';
                            },
                            body: function () {
                                //This can accept html
                                return "Are you sure you want to leave without saving your changes?";
                            },
                            button: function () {
                                return 'Continue';
                            }
                        }
                    });
                    //Remove the modal from the dom
                    confirm.result.then(function() {
                        $log.log('Cancel Dirty Modal');

                        //TODO: Probably need to change the root State to the last state.
                        //rootToState = fromState;
                        //rootToParams = fromState;
                        confirm = '';
                        $rootScope.dirty = false;
                        $state.go(service.rootToState.name, service.rootToParams);
                    });
                }

                //We need this for easy permissions
                $rootScope.$stateParams = service.rootToParams;
                $rootScope.toState = service.rootToState;

                // We stop the loading state when project changed finishes
                // We add a second and a half delay just in case the change of projects is super fast.
                // this way the user can know that there was a change.
                $timeout(function() {
                    $rootScope.project_loading = false;
                }, 1500);

            });

        }

    }
})();
