'use strict';

angular.module('pulse')
    .directive('checkPermission', ['PermissionsSvc', '$log', function (PermissionsSvc, $log) {
        return {
            restrict: 'A',
            scope: {
                permissionClass: '@',
                permissionsArray: '=',
                reverse: '=' //boolan
            },
            require: function(scope, element, attrs){
                return '^' + attrs.controller; //Requires the controller that's dynamically passed in.
            },
            link: function(scope, element, attrs) {

                var elementDisplayType = element.css('display');

                function enableFeature() { //SHOW the feature
                    if(scope.permissionClass){
                        element.removeClass(scope.permissionClass);
                    }else{
                        element.css('display', elementDisplayType);
                    }
                }

                function disableFeature() { //HIDE the feature
                    if(scope.permissionClass){
                        element.addClass(scope.permissionClass);
                    }else{
                        element.css('display', 'none');
                    }
                }

                disableFeature(); //initially hidden, displayed if permissions check against DB returns success

                //single permission checking
                if (attrs.checkPermission && attrs.checkPermission.length) {
                    var permKind = 2;
                    if (attrs.kind && attrs.kind > 0) {
                        permKind = attrs.kind;
                    }

                    //If the current user has NO permission for the target, don't do anything (stays hidden). Otherwise, show the feature.
                    PermissionsSvc.hasPermission2(attrs.target, attrs.checkPermission, permKind).then(function(result) {
                        if(result !== true){
                            $log.warn('Check Permissions - NO ACCESS for ' + attrs.target + ', with permission ' + attrs.checkPermission);
                            if(scope.reverse) {
                              enableFeature();
                            }
                        } else {
                            $log.log('Check Permissions - Access granted for ' + attrs.target + ', with permission ' + attrs.checkPermission);
                            if(!scope.reverse) {
                              enableFeature();
                            }else{
                              disableFeature();
                            }
                        }
                    });
                }


                //multiple permissions in an array. Verify that at least one provides access to the feature.
                if (!!scope.permissionsArray && scope.permissionsArray.length) {
                    var atLeastOnePassed = false;
                    var checkPermission = function(perm, counter) {
                        var index = counter;
                        PermissionsSvc.hasPermission2(perm.target, perm.permission, perm.kind).then(function(result) {
                            if(result === true){
                                atLeastOnePassed = true;
                            }

                            if (index === scope.permissionsArray.length -1 && atLeastOnePassed) {
                                $log.log('Check Permissions - Access granted for ' + perm.target + ', with permission ' + perm.displayName);
                                if(!scope.reverse) {
                                  enableFeature();
                                }else{
                                  disableFeature();
                                }
                            }else if (index === scope.permissionsArray.length -1 && !atLeastOnePassed) {
                                $log.warn('Check Permissions - NO ACCESS for ' + perm.target + ', with permission ' + perm.displayName);
                                if(scope.reverse) {
                                  enableFeature();
                                }
                            }
                        });
                    }

                    for (var n=0; n<scope.permissionsArray.length; n++) {
                        var perm = scope.permissionsArray[n];
                        checkPermission(perm, n);
                    }
                }

            }
        };
    }]);
