'use strict';

angular.module('pulse')
    .filter('findExtension', function() {
            return function findExtension(filename){
                var a = filename.split('.');
                if( a.length === 1 || ( a[0] === '' && a.length === 2 ) ) {
                    return '';
                }
                return a.pop();
            };
        });
