/* global angular */
/**
 * IG 9.25.15
 * @ngdoc module
 * @name virtualScroll
 */
VirtualScrollContainerController.$inject = ['$$rAF', '$window', '$log', '$scope', '$element', '$attrs'];
VirtualScrollController.$inject = ['$scope', '$element', '$attrs', '$browser', '$document', '$$rAF'];
configure.$inject = ['$provide'];
angular.module('pulse.virtualScroll', [])

.config(configure)

.directive('virtualScrollContainer', ['$window', '$log', VirtualScrollContainerDirective])

.directive('virtualScroll', ['$parse', '$log', VirtualScrollDirective])

.factory('virtualScrollModel', ['$log', '$signalProvider', '$q', '$rootScope', 'polling', VirtualScrollModel]);


function configure($provide) {
  $provide.decorator('$$rAF', ["$delegate", rAFDecorator]);
}

function rAFDecorator($delegate) {
  /**
   * Use this to throttle events that come in often.
   * The throttled function will always use the *last* invocation before the
   * coming frame.
   *
   * For example, window resize events that fire many times a second:
   * If we set to use an raf-throttled callback on window resize, then
   * our callback will only be fired once per frame, with the last resize
   * event that happened before that frame.
   *
   * @param {function} callback function to debounce
   */
  $delegate.throttle = function(cb) {
    var queuedArgs, alreadyQueued, queueCb, context;
    return function debounced() {
      queuedArgs = arguments;
      context = this;
      queueCb = cb;
      console.log('rAF queued', queueCb);
      if (!alreadyQueued) {
        alreadyQueued = true;
        $delegate(function() {
          queueCb.apply(context, Array.prototype.slice.call(queuedArgs));
          console.log('rAF Executed', queueCb, 'with args', queuedArgs);
          alreadyQueued = false;
        });
      }
    };
  };
  return $delegate;
}


/**
 * @ngdoc directive
 * @name virtualScrollContainer
 * @module pulse.virtualScroll
 * @restrict E
 * @description
 * `virtual-scroll-container` provides the scroll container for virtual-scroll.
 *
 * Virtual scroll is a limited substitute for ng-repeat that renders only
 * enough dom nodes to fill the container and recycling them as the user scrolls.
 *
 * @usage
 * <hljs lang="html">
 *
 * <virtual-scroll-container>
 *   <div virtual-scroll="i in items" item-size="20">Hello {{i}}!</div>
 * </virtual-scroll-container>
 * </hljs>
 *
 * @param {boolean=} orient-horizontal Whether the container should scroll horizontally
 *     (defaults to orientation and scrolling vertically).
 * @param {boolean=} auto-shrink When present, the container will shrink to fit
 *     the number of items when that number is less than its original size.
 * @param {number=} auto-shrink-min Minimum number of items that auto-shrink
 *     will shrink to (default: 0).
 */
function VirtualScrollContainerDirective($window, $log) {
  return {
    controller: VirtualScrollContainerController,
    template: VirtualScrollContainerTemplate,
    compile: function VirtualScrollContainerCompile($element, $attrs) {
      $element
          .addClass('virtual-scroll-container')
          .addClass($attrs.hasOwnProperty('orientHorizontal') ? 'orient-horizontal' : 'orient-vertical');
    }
  };
}


/*
  ! IG 9.9.15 -- Important: The various DIVs in this component are positioned and sized per the following rules:
  - The outermost DIV is virtual-scroll-container, and it is always preset at a specific height or width. This does not change during scroll ops.
  - BELOW the container is the virtual-scroll-scroller, responsible for showing the scrollbar as needed. The scroller's size also does NOT change,
    instead showing a scrollbar when the '...-sizer' inside of it gets changed.
  - INSIDE the scroller is the virtual-scroll-sizer, whose height or width are constantly changed as the user scrolls through the items. If the number of items is predefined, this DIV is
    large enough to fit them all from the start/load. If not, its height or width get updated dynamically as the user scrolls. This DIV purpose in life is to show, hide, or adjust the scrollbar
    in its parent '-scroller' DIV. Note that this DIV may contain multiple DIVs inside to account for the maximum height a single DIV can have, which varies in browsers. That max is preset in our
    directive to 1533917 pixels, and if more is needed additional divs are inserted into the sizer div.
  - ALSO INSIDE the scroller is the virtual-scroll-offsetter, which contains all rendered DOM elements for the items on screen, plus a few that are off-screen, for smoother scrolling.
*/
function VirtualScrollContainerTemplate($element) {
  return '<div class="virtual-scroll-scroller">' +
    '<div class="virtual-scroll-sizer"></div>' +
    '<div class="virtual-scroll-offsetter">' +
      $element[0].innerHTML + //this is the template inside of the container that will be cloned for each item
    '</div></div>';
}

/**
 * Maximum size, in pixels, that can be explicitly set to an element. The actual value varies
 * between browsers, but IE11 has the very lowest size at a mere 1,533,917px. Ideally we could
 * *compute* this value, but Firefox always reports an element to have a size of zero if it
 * goes over the max, meaning that we'd have to binary search for the value.
 * @const {number}
 */
var MAX_ELEMENT_SIZE = 1533917;

/**
 * Number of additional elements to render above and below the visible area inside
 * of the virtual repeat container. A higher number results in less flicker when scrolling
 * very quickly in Safari, but comes with a higher rendering and dirty-checking cost.
 * @const {number}
 */
var NUM_EXTRA = 3;

/** @ngInject */
function VirtualScrollContainerController($$rAF, $window, $log, $scope, $element, $attrs) {
  this.$scope = $scope;
  this.$element = $element;
  this.$attrs = $attrs;

  /** @type {number} The width or height of the container */
  this.size = 0;
  /** @type {number} The scroll width or height of the scroller */
  this.scrollSize = 0;
  /** @type {number} The scrollLeft or scrollTop of the scroller */
  this.scrollOffset = 0;
  /** @type {boolean} Whether the scroller is oriented horizontally */
  this.horizontal = this.$attrs.hasOwnProperty('orientHorizontal');
  /** @type {!VirtualScrollController} The repeater inside of this container */
  this.repeater = null;
  /** @type {boolean} Whether auto-shrink is enabled */
  this.autoShrink = this.$attrs.hasOwnProperty('autoShrink');
  /** @type {number} Minimum number of items to auto-shrink to */
  this.autoShrinkMin = parseInt(this.$attrs.autoShrinkMin, 10) || 0;
  /** @type {?number} Original container size when shrank */
  this.originalSize = null;
  /** @type {number} Amount to offset the total scroll size by. */
  this.offsetSize = parseInt(this.$attrs.offsetSize, 10) || 0;

  this.scroller = $element[0].getElementsByClassName('virtual-scroll-scroller')[0];
  this.sizer = this.scroller.getElementsByClassName('virtual-scroll-sizer')[0];
  this.offsetter = this.scroller.getElementsByClassName('virtual-scroll-offsetter')[0];

  $$rAF(angular.bind(this, this.updateSize));

  // TODO: Come up with a more robust (But hopefully also quick!) way of
  // detecting that we're not visible.
  if ($attrs.ngHide) {
    $scope.$watch($attrs.ngHide, angular.bind(this, function(hidden) {
      if (!hidden) {
        $$rAF(angular.bind(this, this.updateSize));
      }
    }));
  }

  //reinitialize on browser window resize
  var thisInstance = this;
  angular.element($window).on('resize', thisInstance.debounce(function() {
    $log.log('window resize in virtual-scroll', thisInstance);
    $scope.$apply(angular.bind(thisInstance, thisInstance.updateSize)); //the resize event is outside of angular domain, so wrapping in $apply. Alternatively use $timeout to initiate a digest...
  }, thisInstance));
}


/** Called by the virtual-scroll inside of the container at startup. */
VirtualScrollContainerController.prototype.register = function(repeaterCtrl) {
  this.repeater = repeaterCtrl; //the controller of the Repeater (scroll) directive inside of the Container. This is called in link_ function of the repeater's controller.

  angular.element(this.scroller) //the element with the scrollbar, also inside of the outer container directive.
      .on('scroll wheel touchmove touchend', angular.bind(this, this.handleScroll_)); //the Container's controller will respond to the scroll events - the scroller itself is just an element OUTSIDE of the container, no directive/controller there.
};


/** @return {boolean} Whether the container is configured for horizontal scrolling. */
VirtualScrollContainerController.prototype.isHorizontal = function() {
  return this.horizontal;
};

/* IG 9.22.15 - debounce browser events and such. 600ms seems like a good timeout period for such events... */
VirtualScrollContainerController.prototype.debounce = function (func, context, threshold) {
    var timeout;

    return function debounced () {
        var obj = context, args = arguments;
        function delayed () {
          console.log('Virtual-Scroll executed delayed func', func, 'on', context);
          func.apply(obj, args);
          timeout = null;
        };

        if (timeout) {
          clearTimeout(timeout);
        }

        threshold =threshold || 600;
        timeout = setTimeout(delayed, threshold);
        console.log('Virtual-Scroll debounced func', func, 'on', context, 'Threshold set at', threshold);
    };
}



/** @return {number} The size (width or height) of the container. */
VirtualScrollContainerController.prototype.getSize = function() {
  return this.size;
};


/**
 * Resizes the container.
 * @private
 * @param {number} The new size to set.
 */
VirtualScrollContainerController.prototype.setSize_ = function(size) {
  this.size = size;
  this.$element[0].style[this.isHorizontal() ? 'width' : 'height'] = size + 'px';
};


/** Instructs the container to re-measure its size. */
VirtualScrollContainerController.prototype.updateSize = function() {
  if (this.originalSize) return;

  this.size = this.isHorizontal()
      ? this.$element[0].clientWidth
      : this.$element[0].clientHeight;
  this.repeater && this.repeater.containerUpdated(); //short-circuit -- equiv to if (x) y();. Inform the Repeater that the container has changed, so it can update what's visible.
};


/** @return {number} The container's scrollHeight or scrollWidth. */
VirtualScrollContainerController.prototype.getScrollSize = function() {
  return this.scrollSize;
};


/**
 * Sets the scroller element to the specified size.
 * @private
 * @param {number} size The new size.
 */
VirtualScrollContainerController.prototype.sizeScroller_ = function(size) {
  var dimension =  this.isHorizontal() ? 'width' : 'height';
  var crossDimension = this.isHorizontal() ? 'height' : 'width';

  // If the size falls within the browser's maximum explicit size for a single element, we can
  // set the size and be done. Otherwise, we have to create children that add up the the desired
  // size.
  if (size < MAX_ELEMENT_SIZE) {
    this.sizer.style[dimension] = size + 'px';
  } else {
    // Clear any existing dimensions.
    this.sizer.innerHTML = '';
    this.sizer.style[dimension] = 'auto';
    this.sizer.style[crossDimension] = 'auto';

    // Divide the total size we have to render into N max-size pieces.
    var numChildren = Math.floor(size / MAX_ELEMENT_SIZE);

    // Element template to clone for each max-size piece.
    var sizerChild = document.createElement('div');
    sizerChild.style[dimension] = MAX_ELEMENT_SIZE + 'px';
    sizerChild.style[crossDimension] = '1px';

    for (var i = 0; i < numChildren; i++) {
      this.sizer.appendChild(sizerChild.cloneNode(false));
    }

    // Re-use the element template for the remainder. Account for the fact that in the above children-element insertion some space maybe left due to the math.floor calculation.
    sizerChild.style[dimension] = (size - (numChildren * MAX_ELEMENT_SIZE)) + 'px';
    this.sizer.appendChild(sizerChild);
  }
};


/**
 * If auto-shrinking is enabled, shrinks or unshrinks as appropriate.
 * @private
 * @param {number} size The new size.
 */
VirtualScrollContainerController.prototype.autoShrink_ = function(size) { //Adjusts the size of the Container, not the scroller.
  var shrinkSize = Math.max(size, this.autoShrinkMin * this.repeater.getItemSize()); //size of a single repeated item
  if (this.autoShrink && shrinkSize !== this.size) {
    if (shrinkSize < (this.originalSize || this.size)) {
      if (!this.originalSize) {
        this.originalSize = this.size;
      }

      this.setSize_(shrinkSize);
    } else if (this.originalSize) {
      this.setSize_(this.originalSize);
      this.originalSize = null;
    }
  }
};


/**
 * Sets the scrollHeight or scrollWidth. Called by the repeater based on its item count and item size.
 * @param {number} itemsSize The total size of the items.
 */
VirtualScrollContainerController.prototype.setScrollSize = function(itemsSize) { //Sets the scroll [height/width] of the scroller div. This drives the appearance of the scrollbar.
  var size = itemsSize + this.offsetSize;
  if (this.scrollSize === size) return;

  this.sizeScroller_(size);
  this.autoShrink_(size); //shrinks down the Container to the scroller div's height (or width if horizontal)
  this.scrollSize = size;
};


/** @return {number} The container's current scroll offset. */
VirtualScrollContainerController.prototype.getScrollOffset = function() {
  return this.scrollOffset; //This really represents the Scroller div's scrollTop or scrollLeft.  An element's scrollTop is a measurement of the distance of an element's top to its topmost visible content. When an element content does not generate a vertical scrollbar, then its scrollTop value defaults to 0.
};

/**
 * IG 9.10.15 Scrolls to a given scrollTop position. Primarily used in initialization to scroll to 0px or to a specific item's position.
 * @param {number} position
 */
VirtualScrollContainerController.prototype.scrollTo = function(position) {
  this.scroller[this.isHorizontal() ? 'scrollLeft' : 'scrollTop'] = position;
  this.handleScroll_();
};

VirtualScrollContainerController.prototype.resetScroll = function() {
  this.scrollTo(0);
};


VirtualScrollContainerController.prototype.handleScroll_ = function() {
  var offset = this.isHorizontal() ? this.scroller.scrollLeft : this.scroller.scrollTop;
  if (offset === this.scrollOffset) return;

  var itemSize = this.repeater.getItemSize(); //size of a single repeated item
  if (!itemSize) return;

  var numItems = Math.max(0, Math.floor(offset / itemSize) - NUM_EXTRA); //offset is how far the user has scrolled. offset/itemSize is how many repeater items have scrolled out of the view. NUM_EXTRA is the pre/post items for smooth scrolling.

  /*
  	The translate() CSS function moves the position of the element on the plane. This transformation is characterized by a vector whose coordinates define how much it moves in each direction.
  */
  var transform = this.isHorizontal() ? 'translateX(' : 'translateY(';
      transform +=  (numItems * itemSize) + 'px)'; //translate (move) by enough pixels to cover all items that have been scrolled out of the view. Once the Offsetter is higher than the Scroller DIV around it, the scroller will show a scrollbar.

  this.scrollOffset = offset;
  this.offsetter.style.webkitTransform = transform; //offsetter is a wrapper around the data, but not the container, which is outer-most container.
  this.offsetter.style.transform = transform;

  this.repeater.containerUpdated(); //Inform the repeater that the Container has changed, so that the repeater can update what's shown.
};


/**
 * @ngdoc directive
 * @name virtualScroll
 * @module pulse.virtualScroll
 * @restrict A
 * @priority 1000
 * @description
 * `virtual-scroll` specifies an element to repeat using virtual scrolling.
 *
 * Virtual repeat is a limited substitute for ng-repeat that renders only
 * enough dom nodes to fill the container and recycling them as the user scrolls.
 * Arrays, but not objects are supported for iteration.
 * Track by, as alias, and (key, value) syntax are not supported.
 *
 * @usage
 * <hljs lang="html">
 * <virtual-scroll-container>
 *   <div virtual-scroll="i in items">Hello {{i}}!</div>
 * </virtual-scroll-container>
 *
 * <virtual-scroll-container orient-horizontal>
 *   <div virtual-scroll="i in items" item-size="20">Hello {{i}}!</div>
 * </virtual-scroll-container>
 * </hljs>
 *
 * @param {number=} item-size The height or width of the repeated elements (which
 *     must be identical for each element). Optional. Will attempt to read the size
 *     from the dom if missing, but still assumes that all repeated nodes have same
 *     height or width.
 * @param {string=} extra-name Evaluates to an additional name to which
 *     the current iterated item can be assigned on the repeated scope. (Needed
 *     for use in autocomplete).
 * @param {boolean=} on-demand When present, treats the virtual-scroll argument
 *     as an object that can fetch rows rather than an array.
 *     NOTE: This object must implement the following interface with two (2) methods:
 *     getItemAtIndex: function(index) -> item at that index or null if it is not yet
 *         loaded (It should start downloading the item in that case).
 *     getLength: function() -> number The data legnth to which the repeater container
 *         should be sized. Ideally, when the count is known, this method should return it.
 *         Otherwise, return a higher number than the currently loaded items to produce an
 *         infinite-scroll behavior.
 */
function VirtualScrollDirective($parse, $log) {
  return {
    controller: VirtualScrollController,
    priority: 1000,
    require: ['virtualScroll', '^^virtualScrollContainer'],
    restrict: 'A',
    terminal: true,
    transclude: 'element', //transclude the whole of the directive's element including any directives on this element that defined at a lower priority than this directive. The template property is ignored.
    compile: function VirtualScrollCompile($element, $attrs) { //not sure why compile is used, could have used Link just as well...
      var expression = $attrs.virtualScroll; //e.g. item in items
      var match = expression.match(/^\s*([\s\S]+?)\s+in\s+([\s\S]+?)\s*$/);
      var repeatName = match[1];
      var repeatListExpression = $parse(match[2]); //create an executable expression for the 'items', so we can execute it against a scope later to get the actual item array
      var extraName = $attrs.ExtraName && $parse($attrs.ExtraName); //TODO: What's this expression for?

      return function VirtualScrollLink($scope, $element, $attrs, ctrl, $transclude) { //returning the Link function
        ctrl[0].link_(ctrl[1], $transclude, repeatName, repeatListExpression, extraName); //link_ function is defined on the VirtualScrollController.prototype, below
      };
    }
  };
}


/** @ngInject */
function VirtualScrollController($scope, $element, $attrs, $browser, $document, $$rAF) { //The controller of the actual Virtual Repeater (the items)
  this.$scope = $scope;
  this.$element = $element;
  this.$attrs = $attrs;
  this.defaultClass = this.$attrs.class; //whatever was preset in the template
  this.oddRowClass = this.$attrs.oddrowclass;
  this.evenRowClass = this.$attrs.evenrowclass;
  this.activeClass = this.$attrs.activeclass;
  this.$browser = $browser;
  this.$document = $document;
  this.$$rAF = $$rAF; //request animation frame

  /** @type {boolean} Whether we are in on-demand mode. */
  this.onDemand = $attrs.hasOwnProperty('onDemand'); //this means consumer controller supplies a method for fetching additional data, rather than providing entire dataset to the client at once.
  /** @type {!Function} Backup reference to $browser.$$checkUrlChange */
  this.browserCheckUrlChange = $browser.$$checkUrlChange; //we temprorarily stop url checks when rendering, and this allows us to restore the behavior when we are done.
  /** @type {number} Most recent starting repeat index (based on scroll offset) */
  this.newStartIndex = 0;
  /** @type {number} Most recent ending repeat index (based on scroll offset) */
  this.newEndIndex = 0;
  /** @type {number} Most recent end visible index (based on scroll offset) */
  this.newVisibleEnd = 0;
  /** @type {number} Previous starting repeat index (based on scroll offset) */
  this.startIndex = 0;
  /** @type {number} Previous ending repeat index (based on scroll offset) */
  this.endIndex = 0;
  // TODO: measure width/height of first element from dom if not provided.
  // getComputedStyle?
  /** @type {?number} Height/width of repeated elements. */
  this.itemSize = $scope.$eval($attrs.ItemSize) || null;

  /** @type {boolean} Whether this is the first time that items are rendered. */
  this.isFirstRender = true;

  /** @type {number} Most recently seen length of items. */
  this.itemsLength = 0;

  /**
   * @type {!Function} Unwatch callback for item size (when items-size is
   *     not specified), or angular.noop otherwise.
   */
  this.unwatchItemSize_ = angular.noop;

  /**
   * Presently rendered blocks by repeat index.
   */
  this.blocks = {};
  /** @type {Array<!VirtualScrollController.Block>} A pool of presently unused blocks. */
  this.pooledBlocks = [];

  this.NUM_EXTRA = NUM_EXTRA;
}


/**
 * An object representing a repeated item.
 * @typedef {{element: !jqLite, new: boolean, scope: !angular.Scope}}
 */
VirtualScrollController.Block;


/**
 * Called at startup by the virtual-scroll postLink function.
 * @param {!VirtualScrollContainerController} container The container's controller.
 * @param {!Function} transclude The repeated element's bound transclude function.
 * @param {string} repeatName The left hand side of the repeat expression, indicating
 *     the name for each item in the array.
 * @param {!Function} repeatListExpression A compiled expression based on the right hand side
 *     of the repeat expression. Points to the array to repeat over.
 * @param {string|undefined} extraName The optional extra repeatName.
 */
VirtualScrollController.prototype.link_ =
    function(container, transclude, repeatName, repeatListExpression, extraName) {
  this.container = container;
  this.transclude = transclude;
  this.repeatName = repeatName;
  this.rawRepeatListExpression = repeatListExpression;
  this.extraName = extraName;
  this.sized = false;

  this.repeatListExpression = angular.bind(this, this.repeatListExpression_);

  this.container.register(this); //provide a reference to this repeater to its container
};


/** @private Attempts to set itemSize by measuring a repeated element in the dom */
VirtualScrollController.prototype.readItemSize_ = function() {
  if (this.itemSize) {
    // itemSize was successfully read in a different asynchronous call.
    return;
  }

  this.items = this.repeatListExpression(this.$scope); //execute the pre-compiled expression for 'items' against the repeater's scope
  this.parentNode = this.$element[0].parentNode;
  var block = this.getBlock_(0); //get one row of data, prepopulated into a DIV
  if (!block.element[0].parentNode) { //if a block does not have a parent already, insert it into the 'offset' div, which is the parent DIV of the repeater items. Otherwise getBlock() returned an existing element (the template).
    this.parentNode.appendChild(block.element[0]);
  }

  this.itemSize = block.element[0][this.container.isHorizontal() ? 'offsetWidth' : 'offsetHeight'] || null;

  this.blocks[0] = block;
  this.poolBlock_(0);

  if (this.itemSize) {
    this.containerUpdated(); //container changed, notify the repeat controller (self really)
  }
};


/**
 * Returns the user-specified repeat list, transforming it into an array-like
 * object in the case of infinite scroll/dynamic load mode.
 * @param {!angular.Scope} The scope.
 * @return {!Array|!Object} An array or array-like object for iteration.
 */
VirtualScrollController.prototype.repeatListExpression_ = function(scope) {
  var repeatList = this.rawRepeatListExpression(scope); //evaluate 'items' against scope, get the array or class

  if (this.onDemand && repeatList) {
    var virtualList = new VirtualScrollModelArrayLike(repeatList, this); //The VirtualScrollModelArrayLike class enforces the interface requirements for infinite scrolling within a virtualScrollContainer, by requiring 2 methods...
    virtualList.$$includeIndexes(this.newStartIndex, this.newVisibleEnd); //Create items on the VirtualScrollModelArrayLike object using the external model's required getItemAtIndex() and getLength() mehtods.
    return virtualList;
  } else {
    return repeatList;
  }
};


/**
 * Called by the container and the readItemSize (set single item height/width) on the repeater. Informs us that the containers scroll or size has changed.
 *
 * Here we perform following actions:
 *  - If item size is unknown (not preset, and must be calculated):
 *    - Start watching the items collection for changes. On change of the items array we trigger readItemSize to update our internal understanding of item height/width.
 *    - Activate scope digest to ensure everything is in-sync on item height / width.
 *  - Else:
 *    - reevaluate the items against the scope
 *
 *  - Start the items watch if this is our first container update, which will trigger VirtualScrollerUpdate_ when items change.
 *
 *  - Update the indexes
 *
 *  - Create items on the VirtualScrollModelArrayLike object using the external model's required getItemAtIndex() and getLength() mehtods.
 *
 *  - Update what's in the DOM and visible
 */
VirtualScrollController.prototype.containerUpdated = function() {
  // If itemSize is unknown, attempt to measure it.
  if (!this.itemSize) {
    this.unwatchItemSize_ = this.$scope.$watchCollection( //this is likely first page load, wait for the first batch of items to be retrieved
        this.repeatListExpression,
        angular.bind(this, function(items) {
          if (items && items.count) {
            this.$$rAF(angular.bind(this, this.readItemSize_)); //get a height or width of a single item
          }
        }));

    if(!this.$scope.$$phase) { //IG 10.9.15 - check for digest in progress
      this.$scope.$digest();
    }

    return;
  } else if (!this.sized) {
    this.items = this.repeatListExpression(this.$scope);
  }

  if (!this.sized) {
    this.unwatchItemSize_();
    this.sized = true;
    this.$scope.$watchCollection(this.repeatListExpression, //This will watch our array, or object, or class istance (in which case it will watch all properties for changes)
        angular.bind(this, this.VirtualScrollUpdate_)); //Update the order and visible offset of repeated blocks in response to scrolling
  }

  this.updateIndexes_(); //Updates start and end indexes based on length of repeated items and container size.

  if (this.forceUpdate || this.newStartIndex !== this.startIndex ||
      this.newEndIndex !== this.endIndex ||
      this.container.getScrollOffset() > this.container.getScrollSize()) {
    this.forceUpdate = void(0);
    if (this.items instanceof VirtualScrollModelArrayLike) {
      this.items.$$includeIndexes(this.newStartIndex, this.newEndIndex);
    }
    this.VirtualScrollUpdate_(this.items, this.items); //Update the order and visible offset of repeated blocks in response to scrolling
  }
};


/**
 * IG 9.10.15 Called by the container. Returns the size of a single repeated item.
 * @return {?number} Size of a repeated item.
 */
VirtualScrollController.prototype.getItemSize = function() {
  return this.itemSize;
};


/**
 * Updates the order and visible offset of repeated blocks in response to scrolling or items updates.
 * IG Note that this will reset or update the repeater and the scrollbar when items/count changes.
 * @private
 */
VirtualScrollController.prototype.VirtualScrollUpdate_ = function(items, oldItems) {
  var itemsLength = items ? items.count : 0; //total # of items on the backend
  var lengthChanged = false;

  if (itemsLength !== this.itemsLength) { //number of items has changed
    lengthChanged = true;
    this.itemsLength = itemsLength;
  }

  // If the number of items shrank, scroll up to the top, update the items collection, and exit this func.
  if (this.items && itemsLength < this.items.count && this.container.getScrollOffset() !== 0) {
    this.items = items;
    this.container.resetScroll();
    return;
  }

  this.items = items;
  if (items !== oldItems || lengthChanged) { //IG The items collection changed or the # of items is different.
    this.updateIndexes_(); //Updates start and end indexes based on length of repeated items and container size.
  }

  this.parentNode = this.$element[0].parentNode; //the offsetter DIV

  if (lengthChanged) {
    this.container.setScrollSize(itemsLength * this.itemSize); //the container DIV's scrollHeight or sWidth
  }

  if (this.isFirstRender) { //on first render check if consumer wants to start at any index other than one by checking start-index attribute - UNDocumented.
    this.isFirstRender = false;
    var startIndex = this.$attrs.StartIndex ? this.$scope.$eval(this.$attrs.StartIndex) : 0;
    this.container.scrollTo(startIndex * this.itemSize); //sets the container's scrollHeight or sWidth
  }

  // Detach and pool any blocks that are no longer in the viewport.
  var that = this;
  //This prevents timing issues with on focus html elements within VS
  //TODO: seems a bit choppy to me
  setTimeout(function() {
    Object.keys(that.blocks).forEach(function(blockIndex) { //enumerate all properties of 'blocks' object, but don't go up the prototype chain. Blocks object contains integer indexes of objects (items) that have been rendered.
      var index = parseInt(blockIndex, 10);
      if (index < that.newStartIndex || index >= that.newEndIndex) { //a rendered block (item) is no longer in the view - pool it, so we know that its already been fetched and available for render.
        that.poolBlock_(index);
      }
    }, that);
  });

  // Object.keys(this.blocks).forEach(function(blockIndex) { //enumerate all properties of 'blocks' object, but don't go up the prototype chain. Blocks object contains integer indexes of objects (items) that have been rendered.
  //   var index = parseInt(blockIndex, 10);
  //   if (index < this.newStartIndex || index >= this.newEndIndex) { //a rendered block (item) is no longer in the view - pool it, so we know that its already been fetched and available for render.
  //     this.poolBlock_(index);
  //   }
  // }, this);

  // Add needed blocks.
  // For performance reasons, temporarily block browser url checks as we digest
  // the restored block scopes ($$checkUrlChange reads window.location to
  // check for changes and trigger route change, etc, which we don't need when
  // trying to scroll at 60fps).
  this.$browser.$$checkUrlChange = angular.noop; //IG - This is an undocumented Angular framework API and may change in the future, don't attempt to use elsewhere!

  var i, block,
      newStartBlocks = [],
      newEndBlocks = [];

  // Collect blocks at the top. Note that this isn't executed for existing blocks on the repeater controller.
  for (i = this.newStartIndex; i < this.newEndIndex && this.blocks[i] == null; i++) {
    block = this.getBlock_(i);
    this.updateBlock_(block, i); //Updates and if not in a digest cycle, digests the specified block's scope to the data at the specified index.
    newStartBlocks.push(block);
  }

  // Update blocks that are already rendered.
  for (; this.blocks[i] != null; i++) {
    this.updateBlock_(this.blocks[i], i);
  }
  var maxIndex = i - 1;

  // Collect blocks at the end.
  for (; i < this.newEndIndex; i++) {
    block = this.getBlock_(i);
    this.updateBlock_(block, i);
    newEndBlocks.push(block);
  }

  // Attach collected blocks to the document.
  if (newStartBlocks.length) {
    this.parentNode.insertBefore(
        this.domFragmentFromBlocks_(newStartBlocks),
        this.$element[0].nextSibling);
  }
  if (newEndBlocks.length) {
    this.parentNode.insertBefore(
        this.domFragmentFromBlocks_(newEndBlocks),
        this.blocks[maxIndex] && this.blocks[maxIndex].element[0].nextSibling); //remember that && returns expr1 if it can be converted to false; otherwise, returns expr2.
  }

  // Restore $$checkUrlChange.
  this.$browser.$$checkUrlChange = this.browserCheckUrlChange;

  this.startIndex = this.newStartIndex;
  this.endIndex = this.newEndIndex;

  if (this.items && this.items.model) { //IG 9.15.15 - current items list exposed to the consumer
    this.items.model.visibleItems = this.items;
    if (!!this.items.model.visibleItems[this.endIndex-1] && typeof this.items.model.refreshCheck === 'function') {
      this.items.model.refreshCheck.apply(this.items.model, [this]);
    }
  }
};


/**
 * @param {number} index Where the block is to be in the repeated list.
 * @return {!VirtualScrollController.Block} A new or pooled block to place at the specified index.
 * @private
 */
VirtualScrollController.prototype.getBlock_ = function(index) {
  if (this.pooledBlocks.length) {
    var pBlock = this.pooledBlocks.pop(); //remove the last block from the pool and return it.

    return pBlock;
  }

  var block;

  /*
  	IG 9.10.15 That anonymous callback function below is called the 'clone attach function'. What happens as a side effect when you use a clone attach function
	is the transcluded content gets cloned before it's linked and given back to you. The clone parameter's value is a clone of the transcluded DOM content.
	So, wherever the virtual-scroll directive is applied in your HTML, that DOM node (e.g. DIV) and its attributes will get cloned for each new item, which we are calling a Block.
  */
  this.transclude(angular.bind(this, function(clone, scope) {

    block = {
      element: clone,
      new: true,
      scope: scope
    };

    this.updateScope_(scope, index); //update the scope to the data (Item) at specified index
    this.parentNode.appendChild(clone[0]); //insert this new row (BLOCK) into the DOM
  }));

  return block;
};


/**
 * Updates and if not in a digest cycle, digests the specified block's scope to the data at the specified index.
 * @param {!VirtualScrollController.Block} block The block whose scope should be updated.
 * @param {number} index The index to set.
 * @private
 */
VirtualScrollController.prototype.updateBlock_ = function(block, index) {
  this.blocks[index] = block;

  //IG 9.16.15 - reset, and then add/remove required classes
  this.blocks[index].element[0].className = this.defaultClass; //IG 9.15.15 reset the class to the default values, removing any 'ng-class' dynamic insertions as well as odd/even highlighting
  block.element[0].className += " " + (index % 2 == 0 ?
    (!!this.evenRowClass ? this.evenRowClass : '')  :
    (!!this.oddRowClass  ? this.oddRowClass : '')); //IG 9.15.15 - table striping
    if (!!this.items[index] && this.items.model.isActiveItem(this.items[index])) { //IG 9.16.15 - active items highlight
      block.element[0].className += " " + this.activeClass;
    }

  if (!block.new && (block.scope.$index === index && block.scope[this.repeatName] === this.items[index])) { //block is unchanged
    return;
  }
  block.new = false;

  // Update and digest the block's scope.
  this.updateScope_(block.scope, index);

  // Perform digest before reattaching the block.
  // Any resulting synchronous dom mutations should be much faster as a result.
  // This might break some directives, but I'm going to try it for now.
  if (!this.$scope.$root.$$phase) {
    block.scope.$digest();
  }
};


/**
 * Updates a given block's scope to the data at the specified index (just takes a DOM element (block) and replaces its scope (data) [the element content will reflect the new data after a digest] with the right stuff from the backend (items).
 * @param {!angular.Scope} scope The scope which should be updated.
 * @param {number} index The index to set.
 * @private
 */
VirtualScrollController.prototype.updateScope_ = function(scope, index) {
  scope.$index = index; //store the last index. This can be used for getting a specific item's index in a template (e.g. ng-click=select($index))
  scope[this.repeatName] = this.items && this.items[index]; //store the last item
  if (this.extraName) scope[this.extraName(this.$scope)] = this.items[index];
};


/**
 * Pools the block at the specified index (Pulls its element out of the dom and stores it in memory). This block can be reused anywhere after that.
 * @param {number} index The index at which the block is stored.
 * @private
 */
VirtualScrollController.prototype.poolBlock_ = function(index) {
  this.blocks[index].element[0].className = this.defaultClass; //IG 9.15.15 reset the class to the default values, removing any 'ng-class' dynamic insertions as well as odd/even highlighting
  this.pooledBlocks.push(this.blocks[index]);
  this.parentNode.removeChild(this.blocks[index].element[0]);
  delete this.blocks[index];
};


/**
 * Produces a dom fragment containing the elements from the list of blocks.
 * @param {!Array<!VirtualScrollController.Block>} blocks The blocks whose elements
 *     should be added to the document fragment.
 * @return {DocumentFragment}
 * @private
 */
VirtualScrollController.prototype.domFragmentFromBlocks_ = function(blocks) {
  /*
    DocumentFragments are DOM Nodes. They are never part of the main DOM tree. The usual use case is to create the document fragment, append elements to the document
    fragment and then append the document fragment to the DOM tree. In the DOM tree, the document fragment is replaced by all its children.
  */
  var fragment = this.$document[0].createDocumentFragment();
  blocks.forEach(function(block) {
    fragment.appendChild(block.element[0]);
  });
  return fragment;
};


/**
 * Updates start and end indexes based on length of repeated items and container size.
 * @private
 */
VirtualScrollController.prototype.updateIndexes_ = function() {
  var itemsLength = this.items ? this.items.count : 0;
  var containerLength = Math.ceil(this.container.getSize() / this.itemSize);


  this.newStartIndex = Math.max(0, Math.min(
      itemsLength - containerLength, //total # of items on server minus # of items that can fit into the container, e.g. 200 - 25, so start index is 175, if scroll position is farther than index 175
      Math.floor(this.container.getScrollOffset() / this.itemSize))); // scrollTop divide by item height in pixels, e.g. 0/50 initially or 600/50 when scrolled
  this.newVisibleEnd = this.newStartIndex + containerLength + NUM_EXTRA; //add NUM_EXTRA to have a few items hidden from the view preloaded, to smooth out the scrolling process
  this.newEndIndex = Math.min(itemsLength, this.newVisibleEnd);
  this.newStartIndex = Math.max(0, this.newStartIndex - NUM_EXTRA);
};

/**
 * This VirtualScrollModelArrayLike class enforces the interface requirements
 * for infinite scrolling within a virtualScrollContainer. An object with this
 * interface must implement the following interface with two (2) methods:
 *
 * getItemAtIndex: function(index) -> item at that index or null if it is not yet
 *     loaded (It should start downloading the item in that case).
 *
 * getLength: function() -> number The data legnth to which the repeater container
 *     should be sized. Ideally, when the count is known, this method should return it.
 *     Otherwise, return a higher number than the currently loaded items to produce an
 *     infinite-scroll behavior.
 *
 * @usage
 * <hljs lang="html">
 *  <virtual-scroll-container orient-horizontal>
 *    <div virtual-scroll="i in items" on-demand>
 *      Hello {{i}}!
 *    </div>
 *  </virtual-scroll-container>
 * </hljs>
 *
 */
function VirtualScrollModelArrayLike(model, vsCtrl) {
  if (!angular.isFunction(model.getItemAtIndex) ||
      !angular.isFunction(model.getLength) ||
      !angular.isFunction(model.isActiveItem) ||
      !model.hasOwnProperty('activeItemsChange')) {
    throw Error('When on-demand is enabled, the Object passed to virtual-scroll must implement ' +
        'functions getItemAtIndex(), getLength(), and isActiveItem(). Additionally, a property activeItemsChange ' +
        'must be defined to allow directive update when the active items list changes.');
  }

  this.model = model;
  model.virtualScrollCtrl = vsCtrl;
}


//Create items on the VirtualScrollModelArrayLike object using the external model's required getItemAtIndex() and getLength() mehtods.
//If a page with desired item has already been downloaded from the backend we use that item. Else, we start download of the page with that item. All of that in getItemAtIndex() method in consumer controller.
VirtualScrollModelArrayLike.prototype.$$includeIndexes = function(start, end) {
  for (var i = start; i < end; i++) {
    if (!this.hasOwnProperty(i)) { //Note that this looks like it will trigger page retrieval over and over for subsequent items, but there is a check for 'loading in progress' in getItemAtIndex in consumer controller.
      this[i] = this.model.getItemAtIndex(i);
    }
  }

  this.count = this.model.getLength();
  this.activeItemsChange = this.model.activeItemsChange;  //timestamp of the last user-selection of items will be tracked to tigger directive updates.
};


function abstractMethod() {
  throw Error('Non-overridden abstract method called.');
}



/*
  Virtual Scroll Model Service
  Provides an object-based model that confirms to the Virtual-Scroll directive requirements, and integrates additional features,
  including select/deselect, select all, polling, reporting and search-error handling.
*/
function VirtualScrollModel($log, $signalProvider, $q, $rootScope, polling) {

  //generic, prototype based, object constructor factory
  var construct = function(constructor, args) {
      var obj = Object.create(constructor.prototype);
      constructor.apply(obj, args);
      return obj;
  }


  /*
      IG 9.18.15 Selection class responsible for handling user selection/deselection of items in the virtual scroll
      * @param {bool} disabled   Enable / Disable selection ability on the model
      * @param {!vsModel} model   A parent, model object.
      * @param {object} selectionStorage   External storage mechanism for the user-selected records
      * @param {string|undefined} signalSelection   Name of the signal that will report when user selection of items in the virtual-scroll changes.
  */
  var Selection = function(disabled, model, selectionStorage, signalSelection) {

      this.selectAllText = "Select All";
      this.deselectAllText = "Deselect All";

      /* Is user able to select 1 or more items */
      this.enabled = !disabled;

      /* The parent Virtual Scroll Model */
      this.model = model;

      /* Is Selection operation in progress */
      this.inProgress = false;

      /* last select file (1). Requred to perform multi selection and unselect operations. */
      this.lastSelectedFile = null;
      this.lastSelectedIndex = null;

      /* A pointer to the external storage array for user-selected data or a local store if external not specified */
      this.storage = (!!selectionStorage ? selectionStorage : []);
      this.storage.length = 0; //no values are to be preselected on first initialization of this class, as the search criteria might change from the previous selection

      /* Name of the signal to report results of a select operation */
      this.signalSelection = signalSelection;
  };

  /* Set or update the parent vsModel reference */
  Selection.prototype.setModel = function(model) {
      this.model = model;
  };


  Selection.prototype.isSelectAllDisabled = function() { //disable the 'select all' button while fetching select-all data
      return this.inProgress;
  };

  Selection.prototype.selectAll = function() { //initiate the selectAll operation
    if (this.enabled && !this.model.loading) {
      this.lastSelectedFile = this.lastSelectedIndex = null;

      //If everything selected already, deselect all, else select all
      if (this.storage.length === this.model.numItems) {
          this.storage.length = 0; //clear but don't create another array
          this.model.activeItemsChange = new Date().getTime();
      } else {
          if (this.isSelectAllDisabled()) {
              $log.warn('Selection service is still collecting select-all data, incoming select-all request ignored');
          } else {
              this.inProgress = true;
              $log.log('Select-All started');
              this.storage.length = 0; //clear data but don't create another array
              var startPage = this.model.loadedPages.length;

              for (var n=0; n<this.model.loadedPages.length; n++) {
                  if (!!this.model.loadedPages[n]) { //make sure we really have the intermediate pages. If we don't, fetch the entire set
                    this.storage.push.apply(this.storage, this.model.loadedPages[n]);
                  }
                  else {
                      this.storage.length = 0;
                      startPage = 0;
                      break;
                  }
              }

              var endPage = Math.floor(this.model.numItems / this.model.PAGE_SIZE);
              this.fetchPages(startPage, endPage);
          }
      }
    }
  };

  //fetch all data that's not yet loaded from the backend
  Selection.prototype.fetchPages = function(startPage, endPage) {

      var thisInstance = this;
      if (endPage >= startPage && this.inProgress) {
          this.model.fetchPage_(startPage).then(function (response){ //one request at a time
              thisInstance.storage.push.apply(thisInstance.storage,  response); //push to the array of 'selected items'
              thisInstance.fetchPages( ++startPage, endPage); //recursively fetch all remaining data
          }, function(reason) { //Failure
              thisInstance.inProgress = false; //unable to fetch the data
              $log.error('SelectAll data retrieval failed with the following error', reason);
          });
      }
      else {
          if (this.selectOverride && !this.inProgress) { //select all terminated due to single item selection by the user
            this.storage.length = 0; //clear but don't create another array
            $log.log('SelectAll Terminated due to user selection of a single item');
            this.select(this.selectOverride.index, this.selectOverride.file, this.selectOverride.event);
            delete this.selectOverride;
          }
          else {
            thisInstance.inProgress = false; //all done fetching full dataset
            thisInstance.model.activeItemsChange = new Date().getTime();
            if (!!this.signalSelection) { $signalProvider.signal(thisInstance.signalSelection, thisInstance.storage); }
            $log.log('SelectAll finished successfully');
          }
      }
  };

  //button text getter
  Selection.prototype.getSelectAllButtonText = function() {
      var retVal = this.selectAllText;
      if (this.storage.length >= this.model.numItems) { //'greater than' is used as the 'select all' may fetch more records than what we have in the UI at the moment, if recs were uploaded behind scenes
          retVal = this.deselectAllText;
      }
      return retVal;
  };

  //Select one or more items in the view
  Selection.prototype.select = function(index, file, event) {
      var retVal = false;
      if (this.enabled) {
        if (!(this.model instanceof vsModel)) { return; } //VirtualScrollModel class instance is yet to be initialized

        if (this.inProgress) { //select all operation is in progress and must be stopped first
            this.selectOverride = {index: index, file: file, event: event};
            this.inProgress = false; //this will signal the fetchPages method to stop and perform a single item selection
            return;
        }

        if (index >= 0 && !!file) {
            // if (this.storage.length === this.model.numItems)  {
            //     this.storage.length = 0; //clear the array without replacing the reference to external storage
            // }

            if (typeof this.lastSelectedIndex === 'undefined' || this.lastSelectedIndex === '' || this.lastSelectedIndex === null) {

                $log.log('this is select ++++', index, file);

                if(event.ctrlKey || event.metaKey) {

                  var foundCtrl = this.storage.indexOf(file);

                  if(foundCtrl === -1) {
                      this.storage.push(file);
                  }else{
                      this.storage.splice(foundCtrl, 1);
                  }
              }else{

                this.lastSelectedIndex = index;
                this.lastSelectedFile = file;
                this.storage.length = 0; //clear the array without replacing the reference to external storage
                this.storage.push(file);
              }

                if (!!this.signalSelection) { $signalProvider.signal(this.signalSelection, this.storage); }
            }
            else {
                //If shift is pressed we are actually adding it to playlist
                $log.log('this is ELSE select +++++');
                if(event.shiftKey) {
                    var selIndex = index;
                    var lastIndex = this.lastSelectedIndex;

                    var end, start;
                    start = Math.min(selIndex,lastIndex);
                    end = Math.max(selIndex,lastIndex);

                    for(var indx=start; indx<=end; indx++) {
                        //get the item and store it
                        var pageNumber = Math.floor(indx / this.model.PAGE_SIZE);
                        var page = this.model.loadedPages[pageNumber];
                        if (page) {
                            var item = page[indx % this.model.PAGE_SIZE];
                            var found = this.storage.indexOf(item);
                            if(found === -1) {
                                this.storage.push(item);
                            }
                        }
                    }
                  }// Control key and command key for mac ** metakey. TODO: we might need to map out keys for this stuff because some browsers dont use metakey
                  else if(event.ctrlKey || event.metaKey) {

                    var foundCtrl = this.storage.indexOf(file);

                    if(foundCtrl === -1) {
                        this.storage.push(file);
                    }else{
                        this.storage.splice(foundCtrl, 1);
                    }
                  } else {
                    this.storage.length =0;
                    if(this.lastSelectedFile !== file) {
                        this.lastSelectedFile = file;
                        this.storage.push(file);
                    }else{
                        this.lastSelectedFile = null;
                    }
                  }

                this.lastSelectedIndex = index;
                if (!!this.signalSelection) { $signalProvider.signal(this.signalSelection, this.storage); }
            }

            this.model.activeItemsChange = new Date().getTime();
        }
      }
      return retVal;
  };

  /* If old item was preselected by the user, replace it with a new item. Useful for data refresh operaitons. */
  Selection.prototype.replaceIfSelected = function(oldItem, newItem) {
    if (this.enabled) {
      var index = this.storage.indexOf(oldItem);
      if (!!this.model.dataKeyField) { //we have a unique key field to compare on
        if(index != -1) { //The old item was part of selection. If new item is the same (but updated), select it (replace the old selected item in this.storage).
            if (newItem[this.model.dataKeyField] === oldItem[this.model.dataKeyField]) {
                this.storage[index] = newItem;
            }
        }
        else { //the old item was not selected previously, but items may have shifted if new items were added in fetch, and we need to see if the newItem was part of this.storage. If it was, update it with the updated item.
            for (var n=0; n<this.storage.length; n++) {
                if (!!this.storage[n] && this.storage[n][this.model.dataKeyField] === newItem[this.model.dataKeyField]) {
                    this.storage[n] = newItem;
                    break;
                }
            }
        }
      }
      else {
        $log.error('VirtualScroll - Unable to update selected items as dataKeyField is not available. Selection will be cleared instead.');
        this.storage.length = 0;
        this.model.activeItemsChange = new Date().getTime();
      }
    }
  }

  Selection.prototype.dispose = function() {
    //cleanup for the selection instance should go here
    $log.log('Disposing of Selection instance', this);
    //do not  cleanup the selection array here as it is used outside of the file browser itself. It will get cleaned up on the next load of the file browser.
  };

  /*
      IG 9.10.15 Virtual Scroll Model Class. Confirms to VirtualScrollModelArrayLike interface.
      * @param {object} searchQuery   Required. The search query parameters.
      * @param {!Function} countSearchFunc   Required. The function responsible for fetching the count (length) of the available data from the backend.
      * @param {!Function} recordSearchFunc   Required. The function responsible for fetching the actual recordset data from the backend.
      * @param {string|null} signalSearchResult   Name of the signal that will report on the status of the current search.
      * @param {string|null} signalSelection   Name of the signal that will report when user selection of items in the virtual-scroll changes.
      * @param {object|null} selectionStore   The external storage mechanism that will be filled with user-selected records (if any) for processing by external entities.
      * @param {object|null} staticRequestParams   Any additional request parameters that should be inserted into the query that will be submitted to the backend for data/count retrievals.
      * @param {number|undefined} pageSize   The number of records in a single page of data. Defaults to 50 records.
      * @param {bool|undefined} disableSelection   If true, user will not be allowed to select records (1, many, or all)
      * @param {!Function|undefined} postProcessPageFunc  If post-processing of the retrieved data is requred, this function will be invoked, and output used for data binding.
      * @param {!Function|undefined} postProcessRecordFunc  If post-processing of the retrieved data is requred, this function will be invoked, and output used for data binding.
      * @param {string|null} dataKeyField   Name of the field that uniquely identifies records in our dataset
      * @param {string|null} context   The type of data we are displaying (e.g. messages, assets, etc.)
  */
  var vsModel = function(searchQuery, countSearchFunc, recordSearchFunc, signalSearchResult, signalSelection, selectionStore,
                         staticRequestParams, pageSize, disableSelection, postProcessPageFunc, postProcessRecordFunc, dataKeyField, context) {

      if (!recordSearchFunc) {
          $log.error('Unable to construct the Virtual Scroll model, the record-search function was not specified');
          return;
      }

      /*Name of the signal to report on results of a search operation*/
      this.signalSearchResult = signalSearchResult;
      if (!!this.signalSearchResult ) { $signalProvider.signal(this.signalSearchResult , 'Searching...'); } /* Signal initialization of the model */

      /* The search parameters */
      this.searchQuery = searchQuery;

      /* The count-only search service function */
      this.countSearchFunc = countSearchFunc;

      /* The record search service function */
      this.recordSearchFunc = recordSearchFunc;

      /*The Unique Identifier field of the dataset (optional)*/
      this.dataKeyField = dataKeyField;

      /* The type of data consumed by this virtual scroller model*/
      this.context = (!!context ? context : "records");

      /** If post-processing of the retrieved data is requred, this function will be invoked, and output used for data binding. */
      this.postProcessPageFunc = postProcessPageFunc;
      this.postProcessRecordFunc = postProcessRecordFunc;

      // Data pages, keyed by page number (0-based index).
      this.loadedPages = [];
      //this.cleanDataPages = []; //for use in data post-processing scenarios where loadedPages may not reflect the true dataset.

      /* Total number of items (backend). When this value changes (e.g. new records loaded into a project), we must throw away our pooled blocks, and refresh the data. */
      this.numItems = 0;
      //this.cleanDataLength = 0; //for use in data post-processing scenarios where loadedPages may not reflect the true dataset.

      /** Number of items to fetch per request. Defaults to 50.*/
      this.PAGE_SIZE = pageSize || 50;

      /* additional request parameters for building up a query */
      this.staticRequestParams = staticRequestParams;

      /* IG 9.15.15 Bool - data is being loaded or service being initialized */
      this.loading = true;

      /* added count only loading, because sometimes count only comes back slower then count */
      this.count_loading = true;

      /* IG 9.15.15 String - error condition (any) */
      this.error = null;

      /* IG 9.15.15 current items in the view */
      this.visibleItems = {};

      /*
          When to initiate a check for length changes of the backend data. The value is the # of records from last known data item.
          When this record is scrolled into view we check for backend data (# of records only), and update the client-side stored data pages as needed.
       */
      this.dataChangeCheckThreshold = 5;
      this.thresholdItemInView = false; //is threshold item already in view? We must wait for it to be offscreen before allowing another refresh
      this.refreshInProgress = false; //Is refresh operation in progress

      /* Time stamp of the last change to the active (selected) items list */
      this.activeItemsChange = null;

      /* User record selection methods and data */
      var disableSelect = (typeof disableSelection === 'undefined' ? false : disableSelection);
      this.selection = construct(Selection, [disableSelect, this, selectionStore, signalSelection]); //class responsible for the user selection of records

      /* Queued Polling */
      this.pollQueue = null;

      /* Is Class Instance being disposed of */
      this.disposing = false;

      this.errorState = false; //did this thing kick the bucket?

      var thisInstance = this;

      if (!!this.countSearchFunc) {
        this.fetchNumItems_().then(function() { thisInstance.startPolling(); }); //get the total # of items from DB, then initiate polling
      } else {
        $log.log('The \"get data length\" method was not specified in constructor, please set the countSearchFunc and initiate fetchNumItems_() to continue.');
      }

  };


  /*
      Required by the VirtualScroll directive. Get a single item at a specific index.
      If the page containing an item with that index has not been fetched yet we start the download of that page. The DOM will reflect the downloaded data whenever its available.
  */
  vsModel.prototype.getItemAtIndex = function(index) {
    if (!this.disposing && !this.errorState) {
      var pageNumber = Math.floor(index / this.PAGE_SIZE);
      var page = this.loadedPages[pageNumber];
      /*if (this.postProcessPageFunc && this.cleanDataPages.length-1 < pageNumber) {
        if (page != null) { page = void(0); } //if page isn't being loaded already, fetch the page
      }*/

      if (page) {
        var item = page[index % this.PAGE_SIZE];
        if (!!item && !!this.postProcessRecordFunc && typeof this.postProcessRecordFunc === 'function') {
          this.postProcessRecordFunc(item, index, page, this);
        }
        return item;
      } else if (page !== null) { //if null, its already being loaded.
          this.fetchPage_(pageNumber); //When the page is done loading, the items object (an instance of this Files class) will change, and virtualScroller has a watchCollection on this 'Files' instance, which will resume the UI update.
      }
    }
  };


  //Fetch a single page of data for the virtual scroll.
  vsModel.prototype.fetchPage_ = function(pageNumber, caller) {
    if (!this.disposing && !this.errorState) {
      var refresh = !!caller;

      // Set the page to null so we know it is already being fetched and don't try to start another fetch.
      if (!refresh) { this.loadedPages[pageNumber] = null; }

      this.loading = true;

      var deferred = $q.defer();

      //setup a query
      var pageOffset = pageNumber * this.PAGE_SIZE;
      var query = {
          query: JSON.stringify(this.searchQuery),
          limit: this.PAGE_SIZE,
          skip: pageOffset
      };

      if (!!this.staticRequestParams) {
        angular.extend(query, this.staticRequestParams);
      }

      //execute the query
      var that = this;
      var promise = this.recordSearchFunc(query).$promise;
      promise.then(function (response){
          $log.info( (!!caller ? caller : ''), 'Search complete with params', that.searchQuery, 'loaded', response.length, 'records');

          if (that.postProcessPageFunc && typeof this.postProcessPageFunc === 'function') {
            that.postProcessPageFunc(response, pageNumber, that);
          }

          if (refresh && that.loadedPages[pageNumber]) { //we're polling and we have existing data in the polled page
            that.processPage(pageNumber, response); //process the loaded data one record at a time, only update what has to be updated to avoid page flicker
          }
          else {
            that.loadedPages[pageNumber] = response;
          }

          /*that.cleanDataPages[pageNumber] = response;  //full replace even when polling is OK, because post-processing will update the UI-bound this.loadedPages one rec at a time.
            that.postProcessPageFunc(that.loadedPages, that.cleanDataPages, that.PAGE_SIZE);
            var recordCount = 0;
            for (var n=0; n<that.loadedPages.length; n++){
                recordCount += (!!that.loadedPages[n] ? that.loadedPages[n].length : 0);
            }
            if (recordCount > that.numItems) { //after the most recent data-retrieval, the UI-bound record count has changed - inform the virtual-scroll controller.
              that.numItems = recordCount;
            }
          }*/

          that.loading = false; //hide the loading widget from the view and notify any queued searches they are OK to proceed
          deferred.resolve(response);

      }, function(reason) { //Failure retrieving data
          $log.error('Search failed', reason);
          that.errorState = true;
          delete that.loadedPages[pageNumber];
          that.loading = false;
          deferred.resolve(null);
      });

      return deferred.promise;
    } else {
      var deferred = $q.defer();
      deferred.reject('Either the virtual scroller was disposing at time of fetch or an error condition was detected. Disposing flag value: ' + this.disposing.toString() + '. Error flag value: ', this.errorState.toString());
      return deferred.promise;
    }
  };

  /* Polling - Process new page data, one record at a time. */
  vsModel.prototype.processPage = function(pageNumber, newData) {
    var pageData = this.loadedPages[pageNumber];
    for (var index=0; index<newData.length; index++) {
      if (!angular.equals(newData[index], pageData[index])) {
        this.selection.replaceIfSelected(pageData[index], newData[index]);
        pageData[index] = newData[index];
      }
    }
  }

  /* If queryOnly is true, do not set this.numItems */
  vsModel.prototype.fetchNumItems_ = function(queryOnly) {
    if (!this.disposing && !this.errorState) {
      var deferred = $q.defer();

      this.count_loading = true;

      //Build the query to find out if there is any files for this.
      var query = {
          query: JSON.stringify(this.searchQuery)
      };

      if (!!this.staticRequestParams) {
        angular.extend(query, this.staticRequestParams);
      }

      //Query for the count
      var that = this;
      var promise = this.countSearchFunc(query).$promise;
      promise.then(function (response) {
          $log.info('Search count only - found ' + response.count + ' ' + that.context);
          if (!!that.signalSearchResult) {
            $signalProvider.signal(that.signalSearchResult, {'count': response.count, 'context': that.context });
          }

          if (!queryOnly) {
            that.numItems = response.count; //this will trigger the watchCollection in virtualScrol directive
          }
          //that.cleanDataLength = response.count;

          if (response.count <= 0) {
            that.loading = false; //we are done for now, no page will be fetched.
          }

          that.count_loading = false; //we are done for now, no page will be fetched.

          deferred.resolve(response.count);

      }, function(reason) { //Failure
          that.errorState = true;
          that.count_loading = false; //we are done for now, no page will be fetched.
          that.loading = false; //we are done for now, no page will be fetched.
          that.numItems = 0; // that.cleanDataLength = 0;
          deferred.reject("Unable to fetch record count"); //.resolve(0);
          $log.error('Search - count only search failed', reason);
      });

      return deferred.promise;
    }
  };


  /* recursively fetch all data pages in the array 'pages', one at a time. the Deferred is resolved when all pages have been fetched. */
  vsModel.prototype.fetchPages = function(pages, refresh, deferred) {
      if (typeof deferred == 'undefined') { deferred = $q.defer(); }
      var thisInstance = this;
      if (pages.length) {
        var page = pages.splice(0, 1);
        this.fetchPage_(page[0], refresh).then(function (response){ //one request at a time
            thisInstance.fetchPages(pages, refresh, deferred); //recursively fetch all remaining data
        }, function(reason) { //Failure
            $log.error('DataModel - FetchPages data retrieval failed with the following error', reason);
        });
      }
      else {
        deferred.resolve(1);
      }
      return deferred.promise;
  };

  // Required by the VirtualScroll directive. Total # of items on the backend.
  vsModel.prototype.getLength = function() {
      if (this.error && this.error.length && this.numItems) { //error condition (e.g. search failed)
          this.numItems = 0;  // this.cleanDataLength = 0;
      }

      return this.numItems;
  };

  //Required by the directive. Returns true for items that are selected (active)
  vsModel.prototype.isActiveItem = function(item) {
      var retVal = false;
      if (!!item) {
          var found = this.selection.storage.indexOf(item);
          if(found != -1) {
              retVal = true;
          }
          else if (!!this.dataKeyField) { //we have a unique key field to compare on
              for (var n=0; n<this.selection.storage.length; n++) {
                  if (!!this.selection.storage[n] && this.selection.storage[n][this.dataKeyField] === item[this.dataKeyField]) {
                      retVal = true;
                      break;
                  }
              }
          }
      }
      return retVal;
  };

  vsModel.prototype.setError = function(error) {
      this.error = error;
      this.stopPolling();
  };


  /* Check if we should refresh the numItems from the backend. We may want to throttle this so its not executed again for at least 5 seconds. */
  /*
  If data post-processing is enabled:
    - 1. Do not update numItems in fetchNumItems as it will reset the scroll (the # of items will have decreased).
      Have fetchNumItems_ not set the numItems. We will calculate the right value for numItems here.
    - 2. We must compare the cleanDataLength (previousCount) and the newCount returned by fetchNumItems_. If it is different:
    - 3. Fetch missing data. This is the same procedure used with standard data. Once data-retrieval finishes fetching the needed
          pages up to the last page, the post-processing has already ran, having been executed from each fetchPage_ call,
          so no need to re-run the post-processing at that point.
    - 4. After all pages have been updated and postProcessing is done, we update this.numItems, just like we do in fetchPage_.
    - 5. Call container.update to refresh the view.
  */
  vsModel.prototype.refreshCheck = function() {
    if (!this.disposing) {
      if (!this.thresholdItemInView) {
        if (!!this.visibleItems[this.numItems-this.dataChangeCheckThreshold] && !this.refreshInProgress) { //threshold item is in the view
          this.refreshInProgress = true;
          this.thresholdItemInView = true;
          this.stopPolling();
          var thisInstance = this;
          var previousCount =this.numItems; //(this.postProcessPageFunc ?  this.cleanDataLength : this.numItems);

          var refreshComplete = function(thisInstance) {
              thisInstance.refreshInProgress = false;
              thisInstance.startPolling();
          }

          this.fetchNumItems_().then(function(newCount) { //get the total # of items from DB, then initiate polling and refresh the UI

            if (newCount != previousCount) { //backend data (clean dataset) has changed (recs added or removed)
              thisInstance.refreshCurrentView().then(function() {
                refreshComplete(thisInstance);
              });
            } else {
              refreshComplete(thisInstance);
            }
          }, function(reason) { //error fetching data length - refresh complete (with errors).
            refreshComplete(thisInstance);
          });
        }
      }
      else if (!this.visibleItems[this.numItems-this.dataChangeCheckThreshold]) {  //check if the threshold item went off-screen, and the next time its back on-screen we can refresh again if needed.
        this.thresholdItemInView = false;
      }
    }
  };

  /* Refresh the page(s) that are currently visible, throw away the rest of the data */
  vsModel.prototype.refreshCurrentView = function() {
    var deferred = $q.defer();
    var thisInstance = this;
    var refreshPages = [];

    if(typeof thisInstance === 'undefined' || typeof thisInstance.virtualScrollCtrl === 'undefined' || (!!thisInstance && thisInstance.disposing)) {
      deferred.resolve('view refresh failed');
      return deferred.promise;
    }

    var update = angular.bind(thisInstance.virtualScrollCtrl.container, thisInstance.virtualScrollCtrl.container.updateSize); //update everything
    update();

    var visibleStartPage = Math.floor(this.virtualScrollCtrl.newStartIndex / this.PAGE_SIZE);
    var visibleEndPage = Math.floor(this.virtualScrollCtrl.newEndIndex / this.PAGE_SIZE);

    for (var n=visibleStartPage; n<=visibleEndPage; n++) { //examine previous pages for incomplete datasets (e.g. data being loaded in real time)
      refreshPages.push(n);
    }

    for (var i=0; i<this.loadedPages.length; i++) { //clear the pages that are not in the view. What's in the view will be refreshed gracefully (item comparison).
      if (refreshPages.indexOf(i) === -1) {
        this.loadedPages[i] = void(0);
      }
    }

    this.fetchPages(refreshPages, true).then(function() {
      deferred.resolve('view refresh complete');
    });

    return deferred.promise;
  }

  /* Reset polling if active, and start from node zero. */
  vsModel.prototype.startPolling = function() {
    $log.log('Polling initiated on data model', this);

    if (this.pollQueue === null) {
        this.pollQueue = polling.createQueue(); //First run
    } else {
        this.pollQueue.clear(true);
    }

    var pollFunc = function(page, thisInstance) {
      return function() {
        var deferred = $q.defer();

        //This should never happen, but never say never...
        if(typeof thisInstance === 'undefined' || (!!thisInstance && thisInstance.disposing)) {
          deferred.resolve('polling attempt failed');
          return deferred.promise;
        }

        var prevCount = thisInstance.numItems;
        thisInstance.fetchNumItems_().then(function(newCount) {
          if (prevCount === newCount) { //no change in data length, refresh the page only
            thisInstance.fetchPage_(page, 'Polling').then(function (result){
              deferred.resolve(result);
            }, function(reason) { //Failure
              thisInstance.stopPolling();
              $log.error('Failed to fetch data. Stopping polling. Additional details: ', reason);
            });
          }
          else {  //data length has changed, throw away all pages, refresh the current view
            if (thisInstance) {
              thisInstance.refreshCurrentView().then(function() {
                deferred.resolve('');
              });
            } else {
              deferred.resolve('');
            }
          }
        });
        return deferred.promise;
      }
    }

    //If VS comes back with no items we need to still keep trying to poll for new items
    if(this.numItems===0) {

      var pollingFunc = pollFunc(0, this);
      var pollItem = polling.createPoller(0*this.PAGE_SIZE, pollingFunc, this.pollQueue, this.searchQuery);
      this.pollQueue.enqueue(pollItem);
      this.pollQueue.start();

    }else{

      var pollItemsToCreate = Math.ceil(this.numItems / this.PAGE_SIZE); //how many poll items do we need
      for (var i=0; i<pollItemsToCreate; i++) {
        var pollingFunc = pollFunc(i, this);
        var pollItem = polling.createPoller(i*this.PAGE_SIZE, pollingFunc, this.pollQueue, this.searchQuery);
        this.pollQueue.enqueue(pollItem);
      }
      if (!this.pollQueue.isEmpty()) {
        this.pollQueue.start();
      }

    }

  };

  vsModel.prototype.stopPolling = function() {
      if (!!this.pollQueue) {
          this.pollQueue.clear();
          this.pollQueue = null;
      }
      $log.log('Polling stopped on data model', this);
  };

  vsModel.prototype.dispose = function() {
      //cleanup for the vsModel instance should go here
      this.disposing = true;
      $log.log('Disposing of data model', this);
      this.numItems = 0; // this.cleanDataLength = 0;
      this.loadedPages = [];
      //this.cleanDataPages = [];
      if (!!this.virtualScrollCtrl) { this.virtualScrollCtrl.items = null; } //remove the model from the virtual scroll controller for now, it will be updated when new data is fetched.
      this.stopPolling();
      this.selection.dispose();
  };



  //the service object
  return {
      //get a new instance of the VirtualScrollModel class. This method can be overloaded with a single configuration object parameter instead of individual options
      createVirtualScrollModel: function(searchQuery, countSearchFunc, recordSearchFunc, signalSearchResult, signalSelection, selectionStore, staticRequestParams,
                                         pageSize, disableSelection, postProcessPageFunc, postProcessRecordFunc, dataKeyField, context) {

        var configuration = [searchQuery, countSearchFunc, recordSearchFunc, signalSearchResult, signalSelection,
                             selectionStore, staticRequestParams, pageSize, disableSelection, postProcessPageFunc,
                             postProcessRecordFunc, dataKeyField, context];

        if (arguments.length === 1 && arguments[0] !== null && (typeof arguments[0] === 'object')) { //configuration object provided instead of individual parameters
            var config = arguments[0];
            configuration = [
                config.searchQuery, config.countSearchFunc, config.recordSearchFunc, config.signalSearchResult, config.signalSelection,
                config.selectionStore, config.staticRequestParams, config.pageSize, config.disableSelection, config.postProcessPageFunc,
                config.postProcessRecordFunc, config.dataKeyField, config.context
            ];
        }

        return construct(vsModel, configuration);
      }

  }
};
