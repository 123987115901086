(function() {
    'use strict';

    angular.module('pulse').directive('pulseDropdownButton', directive);

    directive.$inject = ['$log'];

    function directive($log) {
        var config = {
            restrict: 'E',
            templateUrl: function(el, attr) {
                if (attr.template !== undefined) {
                    return attr.template;
                } else {
                    return '<h1>error loading template</h1>';
                }
            },
            scope: {
                init: '=',
                isDownloadEnable: '=',
            },
            link: linkFunc,
            controller: Controller
        };
        return config;

        function linkFunc(scope, el, attr) {}
    }

    Controller.$inject = ['$scope', '$log', 'ServiceManager'];


    function Controller($scope, $log, ServiceManager) {


        if ($scope.init) {
            init();
        }

        function init() {

            $scope.isDownloadEnable = ($scope.isDownloadEnable && $scope.isDownloadEnable.length > 0) ? $scope.isDownloadEnable : [];
            $scope.isDownloadOptions = false;
            $scope.isDownloadModal = false;
            $scope.isLoading = false;
            $scope.responseData = {};

            $scope.error = {
                isError: false,
                title: '',
                description: '',
                errorType: '',
                errorTitle: '',
            };

            $scope.showDropdownModal = {
                CDL_EDL: false,
                ORIGINAL_PULL_ORDER: false,
                CSV_DOWNLOAD: false,
                SUPPORT_FILES: false,
                NON_PULL_ORDER: false,
                METADATA: false
            };
        }



        $scope.toggleDropdownModal = function(KEY, VALUE) {

            if ($scope.isLoading === true) {
                $log.log('Wait for Data is loading...');
                return;
            }

            for (var key in $scope.showDropdownModal) {
                $scope.showDropdownModal[key] = false;
            }
            $scope.showDropdownModal[KEY] = VALUE;
            $scope.isDownloadModal = VALUE;
        }


        $scope.toggleDownloadOptions = function() {

            // if button is disabled
            if ($scope.isDownloadEnable.length === 0) {
                $log.info('Download button is disabled');
                return false;
            }

            $scope.isDownloadOptions = !$scope.isDownloadOptions;
        }

        $scope.downloadCDLEDL = function() {
            $log.log('Downloading CDL');
            $scope.toggleDropdownModal('CDL_EDL', true);

            $scope.isLoading = true;
            $scope.error.isError = false;

            // START download CDL_EDL files`
            ServiceManager.Pulls.downloadPullFiles('CDL_EDL')
                .then(function(response) {

                    $log.log('Download Response: ' + response);

                    $scope.isLoading = false;
                    $scope.responseData = response;

                    // @note: This code is commented intentionally
                    // $scope.error.isError = true;
                    // $scope.error.title = response.title || '',
                    // $scope.error.description = response.description;
                    // $scope.error.errorType = response.type;

                }).catch(function(error) {
                    /**
                     * @params {object} | { title: string, text: string, errorType: string }
                     */

                    $scope.isLoading = false;
                    $scope.error.isError = true;
                    $scope.error.title = error.title || '',
                    $scope.error.description = error.description;
                    $scope.error.errorType = error.type;
                    $scope.error.errorTitle = error.errorTitle || "";
                });

        };

        $scope.downloadCSV = function () {
            $log.log('Downloading CSV');
            $scope.toggleDropdownModal('CSV_DOWNLOAD', true);
            $scope.isLoading = true;
            $scope.error.isError = false;
            // Start Download CSV Files
            ServiceManager.Pulls.downloadPullFiles('CSV_DOWNLOAD')
                .then(function (response) {
                    $log.log('Download Response: ' + response);
                    $scope.isLoading = false;
                    $scope.responseData = response;
                    // @note: This code is commented intentionally
                    // $scope.error.isError = true;
                    // $scope.error.title = response.title || '',
                    // $scope.error.description = response.description;
                    // $scope.error.errorType = response.type;
                }).catch(function (error) {
                    /**
                     * @params {object} | { title: string, text: string, errorType: string }
                     */
                    $scope.isLoading = false;
                    $scope.error.isError = true;
                    $scope.error.title = error.title || '',
                    $scope.error.description = error.description;
                    $scope.error.errorType = error.type;
                    $scope.error.errorTitle = error.errorTitle || "";
                });
        };

        $scope.checkNonDownloadPackages = function () {
            $log.log('Check non download packages');

            $("#pulse-loader").fadeIn(600);
            $(".project-change-loading__text").text("Loading...");
            $scope.isLoading = true;
            $scope.error.isError = false;

            // START download Pull Order files
            ServiceManager.Pulls.checkNonDownloadPackages("PULL_ORDER")
              .then(function (response) {
                $log.log("Download Response: ");
                $log.log(response);

                $scope.responseData = response;
                $scope.isLoading = false;
                $("#pulse-loader").fadeOut(600);

                // check if there are non download packages
                if (response.packages && response.packages.length > 0) {
                  // show non download packages modal and hide the current modal
                  $log.log("NON DOWNLOAD packages found");
                  $scope.toggleDropdownModal("NON_PULL_ORDER", true);
                } else {
                    $scope.toggleDropdownModal("NON_PULL_ORDER", false);
                    $scope.downloadOriginalPullOrder();
                }

                // @note: This code is commented intentionally
                // $scope.error.isError = true;
                // $scope.error.title = response.title || '',
                // $scope.error.description = response.description;
                // $scope.error.errorType = response.type;
              })
              .catch(function (error) {
                /**
                 * @params {object} | { title: string, text: string, errorType: string }
                 */

                $scope.isLoading = false;
                $scope.error.isError = true;
                $scope.error.title = error.title || "";
                $scope.error.description = error.description;
                $scope.error.errorType = error.type;
                $scope.error.errorTitle = error.errorTitle || "";
              });

        };

        $scope.downloadOriginalPullOrder = function () {
          $log.log("Download Pulse Pulls");

          $scope.toggleDropdownModal("ORIGINAL_PULL_ORDER", true);
          $scope.isLoading = true;
          $scope.error.isError = false;

          // START download Pull Order files
          ServiceManager.Pulls.downloadPullFiles("ORIGINAL_PULL_ORDER")
            .then(function (response) {
              $log.log("Download Response: ");
              $log.log(response);

              $scope.responseData = response;
              $scope.isLoading = false;

              // @note: This code is commented intentionally
              // $scope.error.isError = true;
              // $scope.error.title = response.title || '',
              // $scope.error.description = response.description;
              // $scope.error.errorType = response.type;
            })
            .catch(function (error) {
              /**
               * @params {object} | { title: string, text: string, errorType: string }
               */

              $scope.isLoading = false;
              $scope.error.isError = true;
              $scope.error.title = error.title || "";
              $scope.error.description = error.description;
              $scope.error.errorType = error.type;
              $scope.error.errorTitle = error.errorTitle || "";
            });
        };

        $scope.downloadSupportFiles = function() {
            $log.log('Download Support Files');
            $scope.toggleDropdownModal('SUPPORT_FILES', true);

            $scope.isLoading = true;
            $scope.error.isError = false;

            // START download Pull Order files
            ServiceManager.Pulls.downloadPullFiles('PULL_ORDER')
                .then(function(response) {

                    $log.log('Download Response: ' + response);

                    $scope.isLoading = false;
                    $scope.responseData = response;

                    // @note: This code is commented intentionally
                    // $scope.error.isError = true;
                    // $scope.error.title = response.title || '',
                    // $scope.error.description = response.description;
                    // $scope.error.errorType = response.type;

                }).catch(function(error) {
                    /**
                     * @params {object} | { title: string, text: string, errorType: string }
                     */

                    $scope.isLoading = false;
                    $scope.error.isError = true;
                    $scope.error.title = error.title || '',
                    $scope.error.description = error.description;
                    $scope.error.errorType = error.type;
                    $scope.error.errorTitle = error.errorTitle || "";
                });
        };

        $scope.deleteMetadataConfirmation = function() {
            $log.log('Delete Metadata Confirmation');
            $scope.toggleDropdownModal('METADATA', true);

            $scope.error.isError = false;
        };

        $scope.deleteMetadata = function(type, context_key, service) {
            $log.log('Delete Metadata');

            ServiceManager[service].deleteMetadata(type, context_key)
                .then(function(response) {

                    $log.log('Delete Response: ' + response);

                    $scope.isLoading = false;
                    $scope.responseData = response;
                    $scope.toggleDropdownModal('METADATA', false);
                }).catch(function(error) {
                    /**
                     * @params {object} | { title: string, text: string, errorType: string }
                     */

                    $scope.isLoading = false;
                    $scope.error.isError = true;
                    $scope.error.title = error.title || '',
                    $scope.error.description = error.description;
                    $scope.error.errorType = error.type;
                    $scope.error.errorTitle = error.errorTitle || "";
                });

        };

        $scope.clearBtn = function (service, context_key) {
            $log.log('Clear Button Click')
            ServiceManager[service].clearButton(context_key);
            $scope.toggleDownloadOptions();
        }

    }
})();
