/**
 * @ngdoc object
 * @name RegionsResource
 * @requires $resource
 */

(function(){

    'use strict';

    angular
        .module('pulse')
        .factory('RegionsResource', RegionsResource);

    RegionsResource.$inject = ['$resource'];

    function RegionsResource ($resource) {

        return $resource(
            config.baseURL + '/regions/:regionID/:ext',
            {ext: '@ext'},
            {
                getRegions: {method:'GET', isArray: true},
                createRegion: {method:'POST', isArray: false},
                updateRegion: {method:'POST', isArray: false},
                deleteRegion: {method:'DELETE', isArray: false}
            }
        );
    }
})();
