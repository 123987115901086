'use strict';

function maybeAppendZ(input)  {
	//append Z to iso8601 strings without timezones 
	// so that ng's date parser does not assume they're in browser tz
	if (!input) return input; // sometimes is undefined
	if (parseInt(input) == input) { 
		return input; // unix timestamp, nothing to do
	}
	// need to check if datetime string has tz info in it, and append Z if it does not
	const splits = input.split(":");
	if (splits.length < 2 ||
		splits[2].includes('Z') ||
		splits[2].includes('+') ||
		splits[2].includes('-')) {
		return input;	// has no time component or has tz, pass
	}
	console.debug('no TZ supplied, assuming UTC');
	return input + 'Z'; //assume UTC otherwise
}


function getTimezoneOffset(timeZone) {
	// US/Eastern => -0400
  try {
	// console.log('trying to parse', timeZone);
	const now = new Date();
	const tzString = now.toLocaleString('en-US', { timeZone });
	const utcString = now.toLocaleString('en-US', {timeZone: 'UTC'});
	const offset_sec = (Date.parse(utcString) - Date.parse(tzString)) / 1000;
	// console.log(tzString, utcString, 'offset sec', offset_sec);

	const offset_sec_abs = Math.abs(offset_sec);
	const offset_hr=('000' + Math.floor(offset_sec_abs/3600)).slice(-2); //3600 => 01
	const offset_min = ('000' + Math.floor((offset_sec_abs - offset_hr*3600)/60)).slice(-2); //1800 => 30, 0=>00

	const sign = offset_sec>0 ? '-' : '+'; 
	const offset = sign + offset_hr + offset_min;

	// console.log("tz parsed ", timeZone, offset);
	return offset;  
  } catch (e) {
	console.error('bad timezone ', timeZone, e);
	return timeZone;
  }
}


/**
 *
 */
angular.module('pulse')
	.filter('pulseDate', pulseDate);

pulseDate.$inject = ['$filter'];

function pulseDate($filter) {
	return function (input) {
		// console.log('pulseDate filter:', input, typeof input);
		input = maybeAppendZ(input);
		// dateobj = new Date(input);
		const fmt =  !!window.pulseUser ? window.pulseUser.dateFormat : null;
		let tz = !!window.pulseUser ? window.pulseUser.timezone: null;
		if (tz && tz.startsWith('browser')) {
			console.log('tz is ', tz, ' so null tz');
			tz = '';
		};
		if (!!tz) {
			tz = getTimezoneOffset(tz); // need numeric offset as Angular doesn't understand US/Central
		}
		const res = $filter('date')(input, fmt, tz);
		return res;
	};
};




angular.module('pulse')
	.filter('pulseTime', pulseTime);

pulseTime.$inject = ['$filter'];

function pulseTime($filter) {
	return function (input) {
		// console.log('pulseTime filter:', input, typeof input);

		input = maybeAppendZ(input);

		const fmt =  !!window.pulseUser ? window.pulseUser.timeFormat : null;
		let tz = !!window.pulseUser ? window.pulseUser.timezone: null;
		if (tz && tz.startsWith('browser')) {
			console.log('tz is ', tz, ' so null tz');
			tz = '';
		};
		if (!!tz) {
			tz = getTimezoneOffset(tz); // need numeric offset as Angular doesn't understand US/Central
		}
		const res = $filter('date')(input, fmt, tz);
		return res;
	};
};


angular.module('pulse')
	.filter('pulseDateTime', pulseDateTime);

pulseDateTime.$inject = ['$filter'];

function pulseDateTime($filter) {
	return function (input) {
		// console.log('pulseTime filter:', input, typeof input);
		input = maybeAppendZ(input);

		let fmt =  !!window.pulseUser ? window.pulseUser.dateFormat : null;
		let tz = !!window.pulseUser ? window.pulseUser.timezone: null;


		if (window.pulseUser &&  window.pulseUser.timeFormat) {
			fmt += '@' + window.pulseUser.timeFormat;
		}
		if (tz && tz.startsWith('browser')) {
			// console.log('tz is ', tz, ' so null tz');
			tz = '';
		};

		if (!!tz) {
			tz = getTimezoneOffset(tz); // need numeric offset as Angular doesn't understand US/Central
		}

		var res = $filter('date')(input, fmt, tz);
		return res;
	};
};
