/**
 * @ngdoc directive
 * @name pulseFilterSelect
 * @module pulse
 * @restrict E
 * @description
 * `pulseFilterSelect` this is a custom select dropdown with list filtering capabilities.
 *
 * @usage
  <pulse-filter-select
    ng-model="event.transkoderConfig"
    items = "event.transkoderConfigs"
    placeholder-text="Select A Config"
    text-field="name"
    callback = "vm.service[init.service].updateEventOutputProfile"
    data = "event"
  ></pulse-filter-select>
 *
 * @require ngModal
 *
 * @param {object} items - (required) This is the list of select items to display
 * @param {string} textField - (optional) This is the name of the text you want to display
 * @param {object} ngModel - This is the model for the directive, basically what is selected
 * @param {function} callback - This is the callback function that will run once data has changed
 * @param {string} placeholderText - Sets the placeholder text, if not set it will use the first item in the list
 * @param {object} data - This is the selected project's data from the dropdown that is sent as a parameter to the callback function
 * @params {boolean} reset - Set this param to true if you want the dropdown to show the placeholder text after each selection
 *
 */

(function(){

  'use strict';

  angular
    .module('pulse')
    .directive('pulseFilterSelect', pulseFilterSelect);

  pulseFilterSelect.$inject = ['$log', '$timeout', '$window'];

  function pulseFilterSelect($log, $timeout, $window) {

    var directive = {
      restrict: 'EA',
      scope: {
        items: '=',
        textField: '@',
        ngModel:'=',
        placeholderText: '@',
        callback: '=',
        data: '=',
        setDisabled: '=?',
        invalid: '=?',
        reset: "=?",
      },
      templateUrl: function(el, attr) {
        if(typeof attr.template !== 'undefined') {
          return attr.template;
        } else {
          return 'views/common/dropdowns/pulse_filter_select.html';
        }
      },
      link: linkFunction
    };

    return directive;
    function linkFunction(scope, element, attribute, controller) {

      //Make sure dropdown is hidden at that start
      scope.isDropUp = false;

      //Always come in with scope dirty as false
      scope.dirty = false;

      // Check if ngModel has already been initialized
      if (scope.ngModel && Object.keys(scope.ngModel).length > 0) {
        scope.dirty = true;
      }

      // We are creating out own instance of the model so that we can tell when someone dirties the existing model
      var original_model = angular.copy(scope.ngModel);
      //Check to see if we have text or value fields to use within out list
      var textField;
      if(scope.textField) {
        textField = scope.textField.toString().trim();
      }

      buildItems();

      function buildItems(){
        if (typeof scope.items !== 'undefined'){
          if (scope.items.length >= 1 && typeof(scope.items[0]) == 'object'){
            // $log.log('data structure - array with object');
          }else{
            // $log.log('data structure - array with string', textField);
            $log.log('else --- scope.items', scope.items[idx]);
            for (var idx=0, len=scope.items.length; idx < len; idx++){
              scope.items[idx] = {'name': scope.items[idx]}
            }
          }
        }
      }

      //This setups what is currently being viewed in the select.
      function setLabel(reset) {
        $log.log('placeholder reset ====',scope.reset);
        reset = (scope.reset === true) ? scope.reset : reset;
        //If we have a placeholder set it, if we are reseting force the placeholder text
        if(scope.placeholderText || (reset && scope.placeholderText)) {
          scope.currentItemLabel = scope.placeholderText;
        } else {
          scope.currentItemLabel = "-- Select Item --";
        }

        // Remove placeholder text if we have a selected item on the model
        if (scope.ngModel && Object.keys(scope.ngModel).length >= 1){
          if(scope.ngModel && typeof scope.ngModel[textField] !== 'undefined' && !reset) {
            scope.currentItemLabel = scope.ngModel[textField].toString();
          } else if(scope.ngModel && typeof scope.ngModel !== 'undefined' && !reset) {
            scope.currentItemLabel = scope.ngModel.toString();
          }
        }

        /// the dirty state should reset for FilemanagerV2
        if (reset){
          scope.dirty = false;
        }
      }

      //Setup the label on init
      setLabel();


      //This checks if we need to drop up instead of dropdown because the window will cut off the list
      //TODO: we might want to make this run on resize and init rather then on click, it would be better optimized
      function checkDropUp() {
        scope.isDropUp = false;

        // We will have to assume that we know the max dropdown height is 165
        // otherwise we can not get the height since the dropdown could still be hidden here.
        var dropdown_menu_height = 170; //dropdown_menu.outerHeight();
        var window_height = angular.element($window).height();
        var dropdown_element_position = element[0].getBoundingClientRect().top;
        var dropup = window_height - (dropdown_element_position + dropdown_menu_height);

        if (dropup<50) {
          scope.isDropUp = true;
        }
      }

      //When click toggle the drop down
      scope.toggleDropDown = function(event) {

        buildItems();
        //This is to find out if this directive needs to drop up because it is too close to the bottom of the browser.
        checkDropUp();

        var input = element.find('input');
        scope.search = '';

        //Events fire too fast and we need a second to focus on the input
        $timeout(function() {
          input.focus();
        }, 100);
      };

      //On Click we are gonna set the value of the select
      scope.selectValue = function (item, index, reset) {
        $log.log('selectValue', item, index, reset);
        // if (!reset) {
        //   reset = scope.reset;
        // }

        $log.log("reset", reset);
        scope.invalid = false;
        scope.ngModel = item;
        setLabel(reset);
        //If there is a callback function run it and pass the data
        $timeout(function() {
          if(scope.callback && typeof scope.callback === 'function') {
            scope.callback(scope.data, item, index);
          }
        }, 0);
      };

      //Reset the data in the model and put the placeholder back in
      scope.cancelClose = function($event){
        $log.log('cancelClose --->', $event);
        $event.stopPropagation();
        scope.selectValue(false, null, true);
        scope.dirty = false;
        scope.search = '';
      };

      //We need to watch the model because if we change the model and we need to determine if the data is dirty
      scope.$watch('ngModel', function(new_model, old_model) {

        if(new_model === '' && new_model !== old_model) {
          //Reset the dirty state
          scope.dirty = false;
          setLabel();
        }
        if(new_model && !angular.equals(new_model, old_model)) {
          //Set the dirty flag, we arent tracking if they have the same information but if they
          if(!angular.equals(new_model, original_model)) {
            scope.dirty = true;
            setLabel();
          } else {
            scope.dirty = false;
          }
        }
      }, true);

      scope.$watch('setDisabled',function(new_value) {
        scope.setDisabled = new_value;
      },true);

      //Sometimes we need to clear the dirty state from events outside of the directive
      scope.$watch('reset', function(new_reset, old_reset) {
        $log.log('reset watch ', new_reset, old_reset);
        if(new_reset && new_reset !== old_reset) {
          //Reset the dirty state
          if(typeof new_reset === "object") {
            scope.dirty = true;
            setLabel();
          } else {
            setLabel();
            scope.dirty = false;
          }
        }
      }, true);
    }
  }
})();
