/**
 * @ngdoc filter
 * @name metadataKeysFilter
 * 
 * @module pulse
 * @description
 * `metadataKeysFilter` This ia a custom filter for the search drop-down metadata keys to be search as per character specified by user
 *
 * @required
 * DropDown keys as string, keys and values should be seperated by colon ':', each key-value pair should be seperated by space 
 *
 * @usage
 * ng-repeat="data in data_List | metadataKeysFilter:<ngModel>"
 *
 */

'use strict';

angular.module('pulse').
filter('metadataKeysFilter', function () {
    return function (metadata, input_text) {
        var last_key, last_value, keys, values;
        var key_value_pair = getKeyValuePair(input_text);
        if(key_value_pair){                               //check if the string has key and value
            var metadata_object = getKeyAndValue(key_value_pair); 
            var keys = metadata_object.keys;
            var values = metadata_object.values;
        }
        if(keys) {                                      // get the last key from list
            var last_key = keys.pop(keys.length);
        }
        if(values){                                     // get the last value from list
            var last_value = values.pop(values.length);
        }
        var update_filter = updateFilter(metadata, last_key, last_value, input_text);
        return update_filter;
    };
});
//return keyvalue pair as a list
function getKeyValuePair(input_text){
    var key_value_pair;
    if(input_text){
        key_value_pair = input_text.split(" ");
    }
    return key_value_pair;
}
//return all the keys and values as an nested array
function getKeyAndValue(key_value_pair) {
    var keys = [];
    var values = [];
    var temp = [];
    if(key_value_pair) {
        for (var index = 0; index < key_value_pair.length; index++) {  //looping to find all the keys 
            if (key_value_pair[index]) {
                var key_to_string = key_value_pair[index].toString();
                var temp = key_to_string.split(":");
                keys[index] = temp[0];
                values[index] = temp[1];
            }
        }
        return { "keys":keys, "values":values };
    }
}
// filter metadata 
function updateFilter(metadata, last_key, last_value, input_text) {
    var data;
    var filtered_metadata = [];
    if(!input_text || last_value || last_key == undefined){
        return metadata;                                //resets metadata keys
    }else{
        for (var index = 0; index < metadata.length; index++) {
            data = metadata[index].name;            
            data = data.toUpperCase();          
            if (last_key) {
                last_key = last_key.toUpperCase();     
            }
            if (data.startsWith(last_key)) {
                filtered_metadata[index] = metadata[index];
            };
        }
        //to remove all the empty elements in the array filtered_metadata
        var filtered_metadata = filtered_metadata.filter(function (array_element) {  
            return (array_element !== (undefined || null || ''));
        });
        return filtered_metadata;
    }
}
