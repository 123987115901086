/**
 * @ngdoc object
 * @name Divisions
 * @requires $resource
 */

(function(){

    'use strict';

    angular
        .module('pulse')
        .factory('Divisions', Divisions);

    Divisions.$inject = ['$resource'];

    function Divisions ($resource) {

        return $resource(
            config.baseURL + '/organizations/:id/divisions/:divisionId/:ext',
            {id: '@id', divisionId: '@divisionId', userId: '@userId', ext: '@ext'},
            {
                /*getDivision: {url: config.baseURL + '/organizations/:id/divisions/:divisionId', method:'GET'},
                getDivisions: {url: config.baseURL + '/organizations/:id/divisions', method:'GET', isArray: true},
                saveDivision: {url: config.baseURL + '/organizations/:id/divisions/:divisionId', method:'POST'},
                deleteDivision: {url: config.baseURL + '/organizations/:id/divisions/:divisionId', method:'DELETE'},
                saveDivisionOktaURL: {url: config.baseURL + '/organizations/:id/divisions/:divisionId/oktaURL', method:'POST'},
                getUserDivisions:{url: config.baseURL + '/organizations/:id/users/:userId/divisions', method:'GET', isArray: true} <--- moved into users service */

                getDivision: {method:'GET'},
                getDivisions: {method:'GET', isArray: true},
                saveDivision: {method:'POST'},
                deleteDivision: {method:'DELETE'},
                // saveDivisionOktaURL: {method:'POST', params: {ext:'oktaURL'}},

                //Division Project / ProjectLink resources - moved from projects.js
                addDivisionProject: {params: {ext:'addProject'}, method: 'POST'},
                addDivisionProjectLink: {params: {ext:'addProjectLink'}, method: 'POST'}
            }
        );
    }
})();
