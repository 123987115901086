/**
 * @ngdoc directive
 * @name PulseStatusBadge
 * @module pulse
 * @restrict E
 * @description
 *  `PulseStatusBadge` this is a basic status UI element.
 *
 * @usage

 <pulse-status-badge text="{{text}}"></pulse-status-badge>

 * **NOTE** The developer needs to put the colors on the CSS for the view. The names and colors change often, so this is the easiest way.
 * @param {string} text - This is the text that is input into the status box
 */

(function() {
  'use strict';

  angular
    .module('pulse')
    .directive('pulseStatusBadge', pulseStatusBadge);

  pulseStatusBadge.$inject = ['$log'];

  /* @ngInject */
  function pulseStatusBadge($log) {

    var directive = {
      restrict: 'E',
      scope: {
        text : '@text'
      },
      templateUrl: 'views/common/status/pulse_status.html',
      link: linkFunc
    };

    return directive;

    function linkFunc(scope, el, attr, ctrl) {

      if (typeof scope.text === 'undefined'){
        $log.warning('No Text Field for PulseStatusBadge');
      }

    }
  }

})();
