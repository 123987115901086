/**
 * @ngdoc directive
 * @name pulseOverlay
 * @module pulse
 * @restrict EA
 * @description
 * `pulseOverlay` this is a custom overlay directive, used to pop up overlay on top of another modal.
 *
 * @usage
  <pulse-overlay
  template="views/templates/mapping/close_modal/close_modal.html"
  ></pulse-overlay>
 *
 * @require template
 *
 * @param {string} template - (required) You can change whatever template you want with this
 *
 *
 */

(function(){

  'use strict';
  
  angular
    .module('pulse')
    .directive('pulseOverlay', pulseOverlay);
  
    /* @ngInject */
    pulseOverlay.$inject = ['$log', '$timeout', '$window'];
  
    function pulseOverlay($log, $timeout, $window) {
      
      var directive = {
        restrict: 'EA',
        templateUrl: function(el, attr){
          if(attr.template !== undefined){
            return attr.template;
          }else{
            return '<h1>error loading template</h1>';
          }
        }
      };
      return directive;
    }
})();