/**
 * @ngdoc directive
 * @name pulseSelectDropdown
 * @module pulse
 * @restrict E
 * @description
 * `pulseSelectDropdown` this is a custom select to replicate the select html dropdown.
 *
 * @usage
    <pulse-select-dropdown
        ng-class="{'disabled': vm.service[init.service][init.context_key]['event_obj'].status === 'done'}"
        ng-model="event.transkoderConfig"
        items = "event.transkoderConfigs"
        text-field="name"
        callback = "vm.service[init.service].updateEventOutputProfile"
        block-change = "vm.service[init.service].edited"
    ></pulse-select-dropdown>
 *
 * @param {object} items - (required) This is the list of select items to display
 * @param {string} textField - This is the name of the text you want to display
 * @param {string} valueField - This will be use to target the data to be shown
 * @param {object} ngModel - (required) This is the model for the directive, basically what is selected
 * @param {boolean} setDisabled - This will disable the dropdown
 * @param {function} onChange - This is the function to be executed when input value changes.
 * @param {boolean} blockChange - This will block the change from happening. This is different to disabled. The user can still select thing but it wont change on click .
 *
 */

'use strict';

angular.module('pulse')
  .directive('pulseSelectDropdown', ['$log','$stateParams', function ($log, $stateParams) {

    return {
      restrict: 'E',
      require:'^ngModel',
      scope: {
        items: '=',
        defaultOption: '=',
        textField: '@?',
        valueField: '@?',
        ngModel:'=',
        setDisabled: '=?', //We need to make the name different then "disabled" because IE gets confused with the web attribute.
        useValue: '=?',
        showValue: '=',
        onChange: '=',
        blockChange: '=?',
        isItemsLoading: '=?'
      },
      templateUrl: function(el, attr){
        if(typeof attr.template !== 'undefined'){
          return attr.template;
        }else{
          return 'views/common/dropdowns/select_dropdown.html';
        }
      },
      link: function (scope, element, attrs, ngModelCtrl) {

        scope.blockChange = false;

        scope.isDropUp = false;
        var valueField, textField, currentValue = angular.copy(scope.ngModel);
        if(scope.valueField) {
          valueField = scope.valueField.toString().trim();
        }
        if(scope.textField) {
          textField = scope.textField.toString().trim();
        }

        // set default option
        if (scope.defaultOption) {
          scope.items.unshift(scope.defaultOption);
        }

        scope.toggleDropDown = function($event) {
          $log.log('A dropdown is being toggled ', $event);
          scope.isDropUp = false;
          var remainingDownSpace = 0;
          var containerHeight = 0;
          var trueDropDownPosition = 0;

          var dropdownToggle = $event.currentTarget;
          var buttonHeight = dropdownToggle.getBoundingClientRect().height;

          var dropdownMenu = $(dropdownToggle).closest('.drop-down-container').find('.dropdown-menu');
          var menuHeight = dropdownMenu.outerHeight();
          var totalDropDownHeight = buttonHeight + menuHeight;

          var scrollContainer = $(dropdownToggle).closest('.ngsb-wrap');
          if (!!scrollContainer && scrollContainer.length) {
            containerHeight = scrollContainer.height();
            var dropdownTrueTop = $(dropdownToggle).offset().top;
            var childOffset = dropdownTrueTop - scrollContainer.offset().top; //where is the child within its container
            trueDropDownPosition = childOffset;
            remainingDownSpace = containerHeight - childOffset; //do we have enough space left below?
          }
          else {
            var $win = $(window);
            containerHeight = $win.height();
            trueDropDownPosition = dropdownToggle.getBoundingClientRect().top;
            remainingDownSpace = containerHeight - trueDropDownPosition;
          }

          if ( (trueDropDownPosition > menuHeight) && remainingDownSpace < totalDropDownHeight ) {
              scope.isDropUp = true;
          }
        };

        scope.$watch('setDisabled',function(){
          if(scope.setDisabled) {
            angular.element(element).find('.form-control').addClass('disabled');
          }else{
            angular.element(element).find('.form-control').removeClass('disabled');
          }
        },true);

        var modelIsValid = false;
        var selectedItemIsValid = false;

        //added a watch to update the text of the select
        scope.$watch('ngModel',function(new_value, old_value){
          $log.log('Changed Model', new_value);

          //We need to check validity as we change models
          modelIsValid = scope.checkModelValidity(new_value);
          ngModelCtrl.$setValidity('noItemsSet!', scope.setFormValidity());

          if (new_value.hasOwnProperty('errors') && new_value.errors[0].message == "Duplicate mapping name"){
            //No need to set label
          }else{
            scope.setLabel();
          }


          if (scope.ngModel && valueField && typeof scope.ngModel[valueField] !== 'undefined' && scope.useValue) {
            scope.ngModel = scope.ngModel[valueField];
          }

          // we need to update the current value to keep track of changes
          currentValue = angular.copy(scope.ngModel[valueField]);

        },true);

        //This is the validity check for angulars form
        scope.checkModelValidity = function (items) {
          if (typeof(items) === 'undefined' || !items) {
            return false;
          }

          if (items.length < 1) {
            return false;
          }

          return true;
        };

        modelIsValid = scope.checkModelValidity(scope.ngModel);

        //if something is selected and is valid then check validity; **NOTE we dont give them the option to select something false
        scope.setFormValidity = function () {
          if (typeof (attrs.required) !== 'undefined') {
            return modelIsValid;//modelIsValid must be set before we setFormValidity
          }
          return true;
        };

        ngModelCtrl.$setValidity('noItemsSet!', scope.setFormValidity());

        //This is probably overkill but it checks to item is a valid item
        scope.checkSelectedItemValidity = function (item) {
          if (!item) {
            return false;
          }
          if (valueField && !item[valueField]) {
            return false;
          }
          if (valueField && !item[valueField].toString().trim()) {
            return false;
          }
          return true;
        };

        //What is already selected will be displayed
        scope.setChecked = function (_item) {
          var found = false;
          if (typeof scope.ngModel !== 'undefined') {
            if(typeof _item[valueField] !== 'undefined') {

              if (_item[valueField] === scope.ngModel[valueField]) {
                found=true;
              }

              if(scope.useValue && _item[valueField] === scope.ngModel) {
                found=true;
              }

            }else{

              if (_item === scope.ngModel) {
                found=true;
              }

            }
          }
          return found;
        };

        scope.getItemName = function (item) {
          return item[textField];
        };

        
        /**
         * This is the function to check value for 
         * RAW METADATA COLUMN NAME dropdown is in option list or not
         * if not set to default text
         * @param {string} textFieldValue - Already set value for dropdown
         */
        scope.checkSelectedFieldExist=function(textFieldValue){
          var isFieldExist = false;
          if (scope.items && scope.items.length) {
            for (var element = 0; element < scope.items.length; element++) {
              if (scope.items[element].name == textFieldValue) {
                isFieldExist = true;
                break;
              }
            }
            if(!isFieldExist){
              scope.ngModel=''
            }
          }
        }

        //This setups what is currently being viewed in the select.
        scope.setLabel = function() {
          $log.log('Set Label');
          
          if (typeof (scope.ngModel) === 'undefined'
          || scope.ngModel === false
          || scope.ngModel === ''
          || scope.ngModel[textField] === ''
          || scope.ngModel[valueField] === ''
          || scope.ngModel.length < 1
          && !scope.useValue) {
            $log.log('Default Label', attrs.defaultText);
            scope.currentItemLabel = attrs.defaultText;
          } else {
            if(typeof scope.ngModel[textField] !== 'undefined') {
              var textFieldValue = scope.ngModel[textField].toString();
              $log.log('Model textfield', textFieldValue);
              scope.currentItemLabel = textFieldValue;
              if($stateParams && $stateParams.profileID!=='create' && attrs.checkSelectedFieldExist==="true"){
                scope.checkSelectedFieldExist(textFieldValue);
              }
            }else if(typeof scope.ngModel !== 'undefined'){
              $log.log('Model', scope.ngModel.toString());
              scope.currentItemLabel = scope.ngModel.toString();
            }else{
              $log.error('Can not find data');
            }
          }


          ///set an empty string when ngModel is undefined
          if(typeof scope.ngModel === 'undefined'){
              $log.log('scopengMOdel is undefined+++++');
              scope.ngModel = '';
          }

        };

        scope.setLabel();

        //On Click we are gonna set the value of the select
        scope.selectVal = function (_item) {
          $log.log('selectVal', _item);

          currentValue = angular.copy(scope.ngModel);
          // If we set the blockChange to true we stop the selection process and trigger onChange with status blocked.
          if(scope.blockChange) {
            if (typeof scope.onChange === "function") {
              scope.onChange(_item, currentValue, 'blocked');
            }
            return;
          };

          var found = false;
          scope.ngModel = '';

          if (!found && valueField && scope.useValue) {
            scope.ngModel = _item[valueField];
          }else{
            scope.ngModel = _item;
          }

          modelIsValid = scope.checkModelValidity(scope.ngModel);
          selectedItemIsValid = scope.checkSelectedItemValidity(_item);
          ngModelCtrl.$setValidity('noItemsSet!', scope.setFormValidity() && selectedItemIsValid);
          scope.setLabel();
          ngModelCtrl.$setViewValue(scope.ngModel);

          if (typeof scope.onChange === "function") {
            scope.onChange(_item, currentValue);
          }

          if (!found && valueField && scope.useValue) {
            currentValue = angular.copy(_item[valueField]);
          } else {
            currentValue = angular.copy(_item);
          }

        };

        scope.cancelClose = function($event) {
          $event.stopPropagation();
        };


      }
    };
  }]);
