'use strict';

(function() {

    /**
     * @ngdoc overview
     * @name pulse.text

     //Test cases
       var test;
       test = $text.upper('foo_bar')
       $log.log(test);
       test = $text.lower('fooBar')
       $log.log(test);
       test = $text.snake('Foo bar!')
       $log.log(test);
       test = $text.squish('foo.bar')
       $log.log(test);
       test = $text.camel('foo, bar')
       $log.log(test);
       test = $text.constant('Foo-Bar')
       $log.log(test);
       test = $text.title('foo v. bar')
       $log.log(test);
       test = $text.capital('foo_v_bar')
       $log.log(test);
       test = $text.sentence('"foo!" said bar', ['Bar'])
       $log.log(test);

     */

    angular.module('pulse.text', []).

    factory('$text',
        ['$log',
            function($log){

              var regex= {
                  capitalize: /(^|\W|_)([a-z])/g,
                  squish: /(^|[\W_])+([a-zA-Z])/g,
                  fill: /[\W_]+(.|$)/g,
                  sentence: /(^\s*|[\?\!\.]+"?\s+"?|,\s+")([a-z])/g,
                  improper: /\b(A|An|And|As|At|But|By|En|For|If|In|Of|On|Or|The|To|Vs?\.?|Via)\b/g,
                  relax: /([^A-Z])([A-Z]*)([A-Z])(?=[a-z]|$)([^A-Z]?)/g,
                  //relax: /([^A-Z])([A-Z]*)([A-Z])(?=[a-z]|$)/g,
                  upper: /^[^a-z]+$/,
                  hole: /\s/,
                  room: /[\W_]/
              };

              //List of local functions to use/
              var utility = {

                up: String.prototype.toUpperCase,
                low: String.prototype.toLowerCase,
                capitalize: function(string) {
                    return utility.up.call(string.charAt(0))+string.slice(1);
                },
                decapitalize: function(string) {
                    return utility.low.call(string.charAt(0))+string.slice(1);
                },
                fill: function(string, fill) {
                    return !string || fill == null ? string : string.replace(regex.fill, function(m, next) {
                        return next ? fill + next : '';
                    });
                },
                prep: function(string, fill, squish, upper) {
                    if (!string){ return string || ''; }
                    if (!upper && regex.upper.test(string)) {
                        string = utility.low.call(string);
                    }
                    if (!fill && !regex.hole.test(string)) {
                        string = utility.fill(string, ' ');
                    }
                    if (!squish && !regex.room.test(string)) {
                        string = string.replace(regex.relax, utility.relax);
                    }
                    return string;
                },
                //This needs some refinement to make it better
                relax: function(m, before, acronym, caps, after) {
                    // We need to determine if after has anything to find out if the acronym is terminated.
                    if(after) {
                      return before + ' ' + (acronym ? acronym+' ' : '') + caps + after;
                    }else{
                      return before + ' ' + (acronym ? acronym+caps : '');
                    }
                }
                // relax: function(m, before, acronym, caps, after) {
                //     return before + ' ' + (acronym ? acronym+' ' : '') + caps;
                // }

              };

              var that = this;
              this.lower = function(string, fill) {
                  return utility.fill(utility.low.call(utility.prep(string, fill)), fill);
              };
              this.upper = function(string, fill) {
                  return utility.fill(utility.up.call(utility.prep(string, fill, false, true)), fill);
              };
              this.capital = function(string, fill) {
                  return utility.fill(utility.prep(string).replace(regex.capitalize, function(m, border, letter) {
                      return border+utility.up.call(letter);
                  }), fill);
              };
              this.squish = function(string) {
                  return utility.fill(utility.prep(string, false, true).replace(regex.squish, function(m, border, letter) {
                      return utility.up.call(letter);
                  }), '');
              };
              this.title = function(string) {
                  return that.capital(string).replace(regex.improper, function(small) {
                      return utility.low.call(small);
                  });
              };
              this.sentence = function(string, names) {
                  string = that.lower(string).replace(regex.sentence, function(m, prelude, letter) {
                      return prelude + utility.up.call(letter);
                  });
                  if (names) {
                      names.forEach(function(name) {
                          string = string.replace(new RegExp('\\b'+that.lower(name)+'\\b', "g"), utility.capitalize);
                      });
                  }
                  return string;
              };
              this.snake = function(string) {
                return that.lower(string, '_');
              };
              this.constant = function(string){ return that.upper(string, '_'); };
              this.camel = function(string){ return utility.decapitalize(that.squish(string)); };

              return this;
    }]);

})();
