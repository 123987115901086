/**
 * @ngdoc object
 * @name UsersCtrl
 * @requires $scope, $state, $stateParams
 */

'use strict';

angular.module('pulse')
    .controller('UsersCtrl',
    ['$scope', '$signalProvider', '$stateParams', '$log', '$state', 'modalManager', 'Users', 'Projects', 'usersSearchMetadata',
        function ($scope, $signalProvider, $stateParams, $log, $state, modalManager, Users, Projects, usersSearchMetadata) {

            $log.info('Users Controller');

            $scope.users = {};

            $scope.users.searchMetadata = usersSearchMetadata.metadata;
            $scope.users.loading = false;

            function getListIndex(list, item) {
              for (var i = 0; i < list.length; i++) {
                if (list[i].id === item.id) {
                  return i;
                }
              }
            }

            //Search the groups with the given search params
            function search(name, search){
                $log.info('Searching Users ' + name);
                $scope.users.loading = true;

                $signalProvider.signal('users_placeholder', 'Searching...');

                //Query for the count
                var promise = Projects.Project.getUsers({
                    projectId: $stateParams.projectID,
                    //query: JSON.stringify(search)
                }).$promise;

                promise.then(function (response){
                  $log.info('Done Searching Groups');
                  $scope.users.loading = false;
                  $signalProvider.signal('users_placeholder', response.length +' users listed.');
                  $scope.users.list = response;
                  //$signalProvider.signal('feedback', [ 'success', 'ReleaseSent']);
                },
                  function(error) {
                    $log.error('Failed Searching Groups');
                    $log.error(error);
                    $scope.users.loading = false;

                    //$signalProvider.signal('feedback', [ 'failure', 'ReleaseSent']);
                });
            };

            search('init', ''); // Initialize the search

            //NOTE: we needed to do this because ui-router doesnt add href tags if drawer is refreshed :/
            $scope.users.addUser = function() {
              $state.go($state.current.name.replace(/[.]/gi, "/") + '/add.user');
            }

            //NOTE: we needed to do this because ui-router doesnt add href tags if drawer is refreshed :/
            $scope.users.editRoles = function(user) {
              $state.go($state.current.name.replace(/[.]/gi, "/") + '/roles.user',{userOrganizationID: user.organization.id, userID:user.id });
            }

            $scope.users.confirmDelete = function(object, $event) {    //  1
                $log.log('Toggle Delete Modal');

                var index = getListIndex($scope.users.list, object);
                var item = $scope.users.list[index];
                item.unassignEntityType = 'user';
                item.index = index; //Add it to the object

                var modal = modalManager.openModal({
                    templateUrl: 'views/common/modals/confirm.html',
                    controller: 'ConfirmModalCtrl',
                    windowClass: 'confirm',
                    resolve: {
                        title: function () {
                            return 'Are you sure you want to remove the assignment of this user?';
                        },
                        body: function () {
                            //This can accept html
                            return 'You will be removing the assignment of the user ' + item.name + '.';
                        },
                        button: function () {
                            return 'Unassign';
                        }
                    }
                });

                modal.result.then(function () {

                  var promise = Projects.DivisionProject.deleteUserProject({id: $stateParams.organizationID, divisionId: $stateParams.divisionID, projectId: $stateParams.projectID, actionId: item.id}).$promise;

                  promise.then(function() {
                    $scope.users.list.splice(item.index, 1);
                    $signalProvider.signal('feedback', [ 'success', 'Project' ]);
                  }, function(error) {
                      // error handler
                    $log.log(' Not Deleted');
                    $log.log(error);
                    $signalProvider.signal('feedback', [ 'failure', 'RemoveUserFromProject' ]);
                  });

              });

                //Prevent all other events
                if ($event.stopPropagation) {
                    $event.stopPropagation();
                }
                if ($event.preventDefault) {
                    $event.preventDefault();
                }
                $event.cancelBubble = true;
                $event.returnValue = false;
            };

            //listen for when to search
            var currentSearch = $signalProvider.listen('users', search);
            var addSearch = $signalProvider.listen('users_added', search);

            //Destroy the function on search
            $scope.$on('$destroy', function(){
                $log.info('Destroy Users Controller');
                $signalProvider.unlisten('users', currentSearch);
                $signalProvider.unlisten('users_added', addSearch);
            });

        }]);
