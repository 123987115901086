/**
 * @ngdoc object
 * @name BootStrap
 * @requires The Will Of Fire
 * @description This is everything that happens when the application starts up.
 */

//TODO: We want to use this for loading all applications.

(function() {
    'use strict';

    angular.element(document).ready(function() {
      //console.log('Running BootStrap');
      // setTimeout(
      //   function() {
            angular.bootstrap(document, ['pulse']);
          // var angular_loader = angular.element('#app_loader');
          // angular_loader.addClass('app-fade');
        // },
        // ( 0 )
      // );
    });

})();
