/**
 * @ngdoc object
 * @name Roles
 * @requires $resource
 */

(function(){

    'use strict';

    angular
        .module('pulse')
        .factory('Roles', Roles);

    Roles.$inject = ['$resource'];

    function Roles ($resource) {

        return {

            OrganizationRoles: $resource(
                config.baseURL + '/organizations/:organizationID/roles/:roleID/:ext/:extID',
                {organizationID: '@organizationID', roleID: '@roleID', ext: '@ext', extID: '@extID'},
                {
                    getList: {method: 'GET', isArray: true},
                    deleteRight:{method:'DELETE', params:{ext: 'rights'}}, //remove a Right from a Role (only)
                    removeRightFromRole: {method:'DELETE', params:{ext: 'rights'}}, //duplicate of deleteRight above, not used
                    addRightToRole: {method:'POST', params:{ext: 'rights'}},
                    getRights: {method: 'GET', params:{ext: 'rights'}, isArray: true}
                }
            ),

            OrganizationRightsToRole: $resource(
              config.baseURL + '/organizations/:organizationID/roles/:ext',
              {organizationID: '@organizationID',name: '@name', rightIDs: '@rightIDs', ext: '@ext', extID: '@extID'},
              {
                addRightsToRole: {method:'POST', params:{ext: 'addrightstorole'}},
              }

            ),

            ProjectRoles: $resource(
                config.baseURL + '/projects/:projectID/roles/:roleID/:ext/:extID',
                {projectID: '@projectID', roleID: '@roleID', ext: '@ext', extID: '@extID'},
                {
                    getList: {method: 'GET', isArray: true},
                    getRights: {method: 'GET', params:{ext: 'rights'}, isArray: true},
                    deleteRight:{method:'DELETE', params:{ext: 'rights'}}, //remove a Right from a Role (only)
                }
            ),

            ProjectUsersRoles: $resource(
                config.baseURL + '/projects/:projectID/users/:userID/roles/:roleID',
                {projectID: '@projectID', userID: '@userID', ext: '@ext', extID: '@extID'},
                {
                    getUserRoles: {method:'GET', isArray: true},
                    assignRoleToUser: {method: 'POST'},
                    unassignRoleFromUser: {method: 'DELETE'}
                }
            ),

            UserRoles: $resource(
                config.baseURL + '/organizations/:id/users/:userId/roles/:roleId',
                {id: '@id', roleID: '@roleID', userId: '@userId'},
                {
                    /*getUserRoles: {url: config.baseURL + '/organizations/:id/users/:userId/roles', method:'GET', isArray: true},
                    assignRoleToUser: {url: config.baseURL + '/organizations/:id/users/:userId/roles', method: 'POST'},
                    unassignRoleFromUser: {url: config.baseURL + '/organizations/:id/users/:userId/roles/:roleId', method: 'DELETE'}*/

                    getUserRoles: {method:'GET', isArray: true},
                    assignRoleToUser: {method: 'POST'},
                    unassignRoleFromUser: {method: 'DELETE'}
                }
            )
        }
    }
})();
