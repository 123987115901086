"use strict";

angular.module("pulse").controller("OfflineCtrl", [
  "$scope",
  "$log",
  "$window",
  "$signalProvider",
  function ($scope, $log, $window, $signalProvider) {
    $log.log("Offline Controller");

    $scope.displayModal = false;
    $scope.information = "Unable to reach PULSE — Please check your internet connection and try again.";
    $scope.buttonText = "I'M ONLINE";
    $scope.header = "It looks like you may be offline!";

    $scope.signal = {
      display: false,
      message: ''
    };


    $scope.refresh = function () {
      $window.location.reload();
    };

    $scope.close = function () {
      $scope.displayModal = false;
    };

    $signalProvider.listen("showOfflineModal", function (error) {
        $log.log('Handle offline error', error);

        if ($scope.displayModal) {
            $log.log('Offline Modal already open');
            return;
        }

        $scope.displayModal = true;
    });

    $signalProvider.listen("showServerErrorSignal", function (type, error) {
        $log.log('Handle server error', error, type);

        $scope.signal = {
            display: true,
            message: error
        };


        setTimeout(function () {
            $scope.signal.display = false;
            $scope.signal.message = '';
        }, 4500);

    });

  },
]);
