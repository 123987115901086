/**
 * @ngdoc object
 * @name MessageRules
 * @requires $resource
 */

(function(){

    'use strict';

    angular
        .module('pulse')
        .factory('MessageRules', MessageRules);

    MessageRules.$inject = ['$resource'];

    function MessageRules ($resource) {

        return $resource(
            config.baseURL + '/organizations/:organizationID/users/:userID/messageFilters/:messageFilterID',
            {organizationID: '@organizationID', userID: '@userID', messageFilterID: '@messageFilterID'},
            {
                getUserMessageRules: { method: 'GET', isArray: true},
                getUserMessageRule: { method: 'GET'},
                saveUserMessageRule: { method: 'POST'},
                deleteUserMessageRule: { method: 'DELETE'},
                /* This is a duplicate of what's already, properly, in the Users.js/project users/  services -- thus removed. saveUser: {url: config.baseURL + '/organizations/:organizationID/users/:userId', method: 'POST'}*/
            }
        );
    }
})();