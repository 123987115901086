/**
 * @ngdoc filter
 * @name validateRegex
 * @module pulse
 * @requires {String} text
 * @requires {String} pattern
 * @description
 * `validateRegex` provides the filter to validate the given text matches the pattern or not.
 * @Author AYUSHMAN TIWARI
 *
 **/

'use strict';
angular.module('pulse')
  .filter('validateRegex', ['$log', '$filter',function () {
    return function (text,pattern) {
      if (!text || typeof text !== 'string') {
        throw new Error('Expected text of type string');
      }
      if (!pattern) {
        throw new Error('Expected pattern of type string');
      }
      var regexPattern = new  RegExp(pattern,'g')
      return regexPattern.test(text)
    }
  }]);
