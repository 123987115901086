'use strict';

angular.module('pulse')
  .directive('multiSelect', ['$log', function ($log) {

    return {
      restrict: 'E',
      require:'^ngModel',
      scope: {
        items: '=',
        textField: '@',
        valueField: '@',
        ngModel:'=',
        setDisabled: '=?', //We need to make the name different then "disabled" because IE gets confused with the web attribute.
        callback: '=?',
        readOnly: '=?'
      },

      templateUrl: 'views/common/multiSelect/select.html',

      link: function (scope, element, attrs) {

        scope.isDropUp = false;
        scope.$watch('setDisabled',function(){
          if(scope.setDisabled) {
            angular.element(element).find('.form-control').addClass('disabled');
          }else{
            angular.element(element).find('.form-control').removeClass('disabled');
          }
        },true);
        
        
        scope.toggleDropDown = function($event) {
          $log.log('A multi-select is being toggled ', $event);
          scope.isDropUp = false;
          var remainingDownSpace = 0;
          var containerHeight = 0;
          var trueDropDownPosition = 0;
          
          var dropdownToggle = $event.currentTarget;          
          var buttonHeight = dropdownToggle.getBoundingClientRect().height;
          
          var dropdownMenu = $(dropdownToggle).closest('.drop-down-container').find('.dropdown-menu'); 
          var menuHeight = dropdownMenu.outerHeight();
          var totalDropDownHeight = buttonHeight + menuHeight;
                    
          var scrollContainer = $(dropdownToggle).closest('.ngsb-wrap');          
          if (!!scrollContainer && scrollContainer.length) {
            containerHeight = scrollContainer.height();
            var dropdownTrueTop = $(dropdownToggle).offset().top;
            var childOffset = dropdownTrueTop - scrollContainer.offset().top; //where is the child within its container     
            trueDropDownPosition = childOffset;        
            remainingDownSpace = containerHeight - childOffset; //do we have enough space left below?
          } 
          else {
            var $win = $(window);
            containerHeight = $win.height();
            trueDropDownPosition = dropdownToggle.getBoundingClientRect().top;
            remainingDownSpace = containerHeight - trueDropDownPosition;
          }                              

          if ( (trueDropDownPosition > menuHeight) && remainingDownSpace < totalDropDownHeight ) {
              scope.isDropUp = true;
          }
        }

        scope.$watch('ngModel',function(){
          scope.setLabel();
        },true);
        
        scope.getUniqueId = function(index) {
          return index + '_' + (new Date().getTime()) + '_' + Math.random();
        }

        scope.disableClose = function($event) {
          $event.stopPropagation();
        }

        var valueField = scope.valueField.toString().trim();
        var textField = scope.textField.toString().trim();

        //What is already selected will be displayed
        scope.setChecked = function (item) {
          var found = false;
          if (scope.ngModel) {
            var found = false;
            var length = scope.ngModel.length;
            for(var i=0; i<length;i++) {
              if(scope.ngModel[i][textField]===item[textField]) {
                found = true;
                break;
              }
            }
          }
          return found;
        };

        //This setups what is currently being viewed in the select.
        scope.setLabel = function() {
          //TODO: not sure how to solve this yet.
          if (typeof (scope.ngModel) ==='undefined' || !scope.ngModel || scope.ngModel.length < 1) {
            scope.currentItemLabel = attrs.defaultText;
          } else if(scope.ngModel.length===1) {
            scope.currentItemLabel = scope.ngModel[0].name;
          }else{
            scope.currentItemLabel = scope.ngModel.length + ' items selected';
          }

        };

        scope.setLabel();

        //On Click we are gonna set the value of the select
        scope.selectValue = function (item, $event) {
          //$log.log(item);
          //$log.log($event);

          $event.stopPropagation();
          if($event.target.nodeName==='LABEL') {
            return false;
          }

          //Force an array if there isnt one.
          if(typeof scope.ngModel === 'undefined') {
            scope.ngModel = [];
          }


          if(scope.ngModel.length){
            var found = false;
            var length = scope.ngModel.length;
            for(var i=0; i<length;i++) {
              if(scope.ngModel[i][textField]===item[textField]) {
                found = i
                break;
              }
            }

            if(found===false) {
              scope.ngModel.push(item);
            }else{
              scope.ngModel.splice(found,1);
            }

          }else{
            scope.ngModel.push(item);
          }

          scope.setLabel();
          $log.log('Model');
          $log.log(scope.ngModel);

          if(scope.callback) {
            scope.callback();
          }

        };

      }
    };
  }]);
