/*@module pulse
* @restrict A
* @description
* `pulseBackdrop` this is directive that will detect if the the backdrop is set or not.
*
* @usage
  <pulse-backdrop service="MappingService"></pulse-backdrop>
*
* @params
* service (object) - This is the service where we can identify whether the backdrop parameter has been set or not.
*
*/

(function () {
    'use strict';
    angular
        .module('pulse')
        .directive('pulseBackdrop', pulseBackdrop);

    pulseBackdrop.$inject = ['$log', 'ServiceManager'];

    function pulseBackdrop($log, ServiceManager) {

        var directive = {
            templateUrl: 'views/common/backdrop/backdrop.html',
            restrict: 'E',
            scope: {
                service: '@service'
            },
            link: linkFunction
        };

        return directive;

        function linkFunction(scope, element, attribute) {

          scope.vm = {
              service : ServiceManager[scope.service]
          };

        }
    }
})();
