/**
 * @ngdoc object
 * @name ApplicationRoutes
 * @requires $stateProvider
 * @description Routing for root level application
 */

(function() {
  'use strict';
  angular
    .module('pulse')
    .config(applicationRoutes);

  applicationRoutes.$inject = ['$stateProvider'];

  /* @ngInject */
  function applicationRoutes($stateProvider) {

    $stateProvider
      .state('root', {
        url: '/',
        templateUrl: 'views/layouts/landing.html',
        resolve : {
          loading:
            ['$timeout',
              function ($timeout) {
                var angular_loader = angular.element('#main_pulse_loader');

               $timeout(function(){
                  var angular_loaderImage = angular.element('#loaderImage');
                  angular_loaderImage.addClass('hide-loader');
                  angular_loader.remove();

                  //TODO: Find a better way to add these animations
                  var pulse_login_input = angular.element('#input');
                  var pulse_login_button = angular.element('#button');
                  var pulse_bottom_links = angular.element('.bottom-links');
                  pulse_login_button.addClass('fade-slide');
                  pulse_login_input.addClass('fade-slide');
                  pulse_bottom_links.addClass('fade-slide');

              }, 1500);

            }]
        }
      })

      .state('root.terms', {
          url: 'terms/:nonce',
          onEnter:
              ['$stateParams', '$state', '$log', 'modalManager',
                  function($stateParams, $state, $log, modalManager) {
                    $log.log('root.terms modal', $stateParams.nonce);

                      modalManager.openModal({
                          templateUrl: 'views/layouts/terms.html',
                          windowClass: 'terms',
                          backdrop: 'static',
                          keyboard: false,
                          resolve: {
                            nonce: function () {
                              return $stateParams.nonce;
                            }
                          }
                      }).result.finally(function() {
                          $state.go('root');
                      });

                  }]
      })

      .state('root.privacy', {
          url: 'privacy',
          onEnter:
              ['$state', '$log', 'modalManager',
                  function($state, $log, modalManager) {
                    $log.log('root.privacy modal');

                      modalManager.openModal({
                          templateUrl: 'views/layouts/privacy.html',
                          windowClass: 'privacy',
                          backdrop: true,
                          keyboard: true
                      }).result.finally(function() {
                          $state.go('root');
                      });

                  }]
      });

  }

})();
