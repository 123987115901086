'use strict';

angular.module('pulse')
    .controller('ErrorCtrl',
    ['$scope', '$log', '$modalInstance', '$window', '$interval', '$location', '$rootScope', '$state', 'error',
        function ($scope, $log, $modalInstance, $window, $interval, $location, $rootScope, $state, error) {

            $log.log('Error Controller');

            $scope.error = error;
            $scope.error.message = '';
            $scope.error.solution = 'If problem persists contact your administrator.';
            //$scope.error.redirect= true;
            $scope.error.closed = false;

            //Add other special cases here.
            switch($scope.error.status) {
                case 403:
                    $scope.error.message = 'You do not have authorization to view information on this page.';
                    $scope.error.solution = 'If you received this in error, please contact your administrator.';
                    $scope.error.redirect = false;
                    break;
                case 404:
                    $scope.error.message = 'The information you requested does not exist.';
                    $scope.error.solution = 'If you received this in error, please contact your administrator.';
                    $scope.error.redirect = false;
                    break;
                default:
            }

            //This is currently not being used for anything
            //TODO: we might come back and make error handling a little more dynamic
            if($scope.error.redirect) {

                $scope.error.timer = 15;
                var interval = $interval(function() {

                    //If we already went somewhere else -- end the interval
                    if($scope.error.closed) {
                        $interval.cancel(interval);
                        return false;
                    }

                    //Times up -- clean yourself up and redirect
                    if($scope.error.timer === 0 && !$scope.error.closed) {
                        $interval.cancel(interval);
                        $scope.error.closed = true;
                        $modalInstance.close();
                        $log.log($state);
                        //$window.history.back();
                    }

                    if( $scope.error.timer>0) {
                        $scope.error.timer = $scope.error.timer - 1;
                    }

                }, 1000);

            }

            $scope.error.ok = function() {
                $log.log($window.location);
                //we need to clear out the local storage states to make sure we dont get stuck in a loop
                //TODO: maybe put a count into localstorage for how many times they click ok -- if more then 2 send them to dashboard.
                $rootScope.nocache = true;
                localStorage.removeItem('previousAttemptURL'); // Remove any kind of deep link that the user might have tried
                localStorage.removeItem(error.app+'toState'); // We want to remove state
                localStorage.removeItem(error.app+'toParams'); // We also want to remove specific app params
                $window.location = $window.location.protocol + '//' + $window.location.hostname +$window.location.pathname;
            };


            //$scope.back = function() {
            //    $modalInstance.close();
            //};

            $scope.refresh = function() {
                $window.location.reload();
            };

            var stateChange = $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
                $log.info('Error Controller State Change');
                if(!$scope.error.closed) {
                    $scope.error.closed = true;
                    $modalInstance.close();
                }
            });

            //Destroy the function on search
            $scope.$on('$destroy', stateChange);

        }]);
