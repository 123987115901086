/**
 * @ngdoc object
 * @name EditImportCtrl
 * @requires $scope, $signalProvider, $stateParams, $log, $rootScope, $state
 */

'use strict';

angular.module('pulse')
    .controller('EditImportCtrl',
    ['$scope', '$signalProvider', '$stateParams', '$log', '$rootScope', '$state', 'modalManager', 'Projects', 'importSetup',
        function ($scope, $signalProvider, $stateParams, $log, $rootScope, $state, modalManager, Projects, importSetup) {

            $log.info('Edit Import Controller');

            $rootScope.debounce = false;
            $scope.$stateParams = $stateParams;

            $scope.deleting = false;
            $scope.edit = {};

            $scope.edit.transferFolder = '';
            $scope.edit.fileCategory = '';
            $scope.edit.priority = 'medium';
            // $scope.edit.setupType = 'Automatic'; // comment out to enable setup type feature 
            $scope.edit.generateALE = false;
            $scope.edit.deleteFiles = true;
            $scope.edit.ignoreMD5 = false;
            $scope.edit.reuse = true;
            $scope.edit.replace = false;
            $scope.edit.projectID = $stateParams.projectID;

            // if we are editing the import we populate the edit object
            if($stateParams.importID !== 'create') {
                $scope.edit = importSetup;
            }

            // dropdown values
            $scope.file_categories = [{ 'name': 'ocf' }, { 'name': 'vfx' }];
            $scope.priorities = [{ 'name': 'low' }, { 'name': 'medium' }, { 'name': 'high' }];
            // $scope.setup_types = [{ 'name': 'Automatic' }, { 'name': 'Manual' }]; // comment out to enable setup type feature 

            // changes the value on the edit object to be in the correct format
            $scope.changeFileCategory = function() {
                $scope.edit.fileCategory = $scope.edit.fileCategory.name;
            };

            // changes the value on the edit object to be in the correct format
            $scope.changePriority = function() {
                $scope.edit.priority = $scope.edit.priority.name;
            };

            // comment out to enable setup type feature 
            // changes the value on the edit object to be in the correct format
            // $scope.changeSetupType = function() {
            //     $scope.edit.setupType = $scope.edit.setupType.name;
            // };

            // Saves the import. Either new or editing
            $scope.save = function() {
                $log.info('Saving...');
                $rootScope.debounce = true;

                var params = {
                    projectId: $stateParams.projectID
                };

                if($stateParams.importID !== 'create') {
                    params.typeId = $stateParams.importID;
                }

                var save = Projects.Project.updateImport(params, $scope.edit).$promise;
                save.then(function(response) {
                    $log.log('Added Import', response);
                    $signalProvider.signal('feedback', [ 'success', 'Project']);
                    $signalProvider.signal('import_edited', response);

                    if($stateParams.importID==='create') {
                        $scope.back();
                        return;
                    }
                    $rootScope.debounce = false;
                }, function(error) {
                    $log.log('Import Not Saved To Project');
                    $signalProvider.signal('feedback', [ 'failure', 'Project']);
                    $rootScope.debounce = false;
                });
            };

            // goes back to the parent drawer
            $scope.back = function() {
                $state.go('^.^');
            };

            // deletes the import and redirects to the parent drawer
            $scope.delete = function() {
                modalManager.openModal({
                    templateUrl: 'views/common/modals/confirm.html',
                    controller: 'ConfirmModalCtrl',
                    windowClass: 'confirm',
                    resolve: {
                        title: function () {
                            return 'Are you sure you want to delete this import?';
                        },
                        body: function () {
                            //This can accept html
                            return 'You will be deleting the import from this project.';
                        },
                        button: function () {
                            return 'Delete';
                        }
                    }
                }).result.then(function () {
                    $rootScope.debounce = true;
                    $scope.deleting = true;
                    Projects.Project.deleteImport({ projectId: $stateParams.projectID, typeId: $stateParams.importID }).$promise.then(function() {
                        $signalProvider.signal('feedback', [ 'success', 'DeleteImport']);
                        $signalProvider.signal('imports', {});
                        $scope.deleting = false;
                        $rootScope.debounce = false;
                        $scope.back();
                    }, function(){
                        $scope.deleting = false;
                        $rootScope.debounce = false;
                        $signalProvider.signal('feedback', [ 'failure', 'DeleteImport']);
                    });
                });
            };

            //Destroy the function on search
            $scope.$on('$destroy', function() {
                $log.info('Destroy Import Controller');
            });
        }]);