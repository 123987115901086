/**
 * @ngdoc directive
 * @name pulseFileSize
 * @module pulse
 * @restrict EA
 * @description
 * `pulseFileSize` formats the number of bytes into readable files sizes.
 *
 * @param {html} template - (required) This is what will be displayed in the directive
 * @param {int} bytes - (required) This is the number that will be converted to readable format
 * @param {int} precision - This is the amount of decimals yo to display
 *
 * @usage
 * <pulse-file-size 
        class = "textStyles"
        precision = "0"
        bytes = "8568743687"
        template = "views/templates/file_size.html"
 * ></pulse-file-size>
 *
 */

(function() {
    'use strict';

    angular
        .module('pulse')
        .directive('pulseFileSize', pulseFileSize);

    /* @ngInject */

    pulseFileSize.$inject = ['$log'];

    function pulseFileSize($log) {
        var directive = {
            restrict: 'EA',
            templateUrl: function(el, attr){
                if(attr.template != undefined){
                    return attr.template;
                }else{
                    return 'views/common/error.html';
                }
            },
            scope: {
                precision : '=',
                bytes : '='
            },
            link: linkFunc,
            controller: Controller
        };

        return directive;

        function linkFunc(scope, el, attr) {}
    }

    Controller.$inject = ['$scope', '$log'];

    /* @ngInject */
    function Controller($scope, $log) {
        $log.log('pulseFileSize Dir Controller ');

        $scope.vm = {
            units : 'bytes',
            size : $scope.bytes
        };

        var units = [
            'bytes',
            'KB',
            'MB',
            'GB',
            'TB',
            'PB',
            'EB',
            'ZB',
            'YB'
        ];

        function fileSize(bytes){
            if ( isNaN( parseFloat( bytes )) || ! isFinite( bytes ) ) {
              return '?';
            }
            var unit = 0;
            var precision = $scope.precision || 0;
            
            while ( Math.abs(bytes) >= 1024 ) {
              bytes /= 1024;
              unit ++;
            }

            $scope.vm.units = units[ unit ];
            $scope.vm.size = bytes.toFixed( + precision );
        };

        $scope.$watch('bytes', function(newValue, oldValue) {
            if (typeof newValue === "undefined") return;
            fileSize(newValue);
        });
    }

})();