/**
 * @ngdoc directive
 * @name pulseFilterMultipleSelect
 * @module pulse
 * @restrict E
 * @description
 * `pulseFilterMultipleSelect` this is a custom select dropdown with list filtering capabilities.
 *
 * @usage
  <pulse-filter-multiple-select
    ng-model="event.transkoderConfig"
    items = "event.transkoderConfigs"
    placeholder-text="Select A Config"
    text-field="name"
    callback = "vm.service[init.service].updateEventOutputProfile"
    data = "event"
  ></pulse-filter-multiple-select>
 *
 * @require ngModal
 *
 * @param {object} items - (required) This is the list of select items to display
 * @param {string} textField - (optional) This is the name of the text you want to display
 * @param {object} ngModel - This is the model for the directive, basically what is selected
 * @param {function} callback - This is the callback function that will run once data has changed
 * @param {string} placeholderText - Sets the placeholder text, if not set it will use the first item in the list
 * @param {object} data - This is the selected project's data from the dropdown that is sent as a parameter to the callback function
 *
 *
 */

(function(){

  'use strict';

  angular
    .module('pulse')
    .directive('pulseFilterMultipleSelect', pulseFilterMultipleSelect);

  pulseFilterMultipleSelect.$inject = ['$log', '$timeout', '$window'];

  function pulseFilterMultipleSelect($log, $timeout, $window) {

    var directive = {
      restrict: 'EA',
      scope: {
        items: '=',
        textField: '@',
        ngModel:'=',
        placeholderText: '@',
        callback: '=',
        data: '=',
        setDisabled: '=?',
      },
      templateUrl: function(el, attr){
        if(typeof attr.template !== 'undefined'){
          return attr.template;
        }else{
          return 'views/common/combobox/pulse_filter_multiple_select.html';
        }
      },
      link: linkFunction
    };

    return directive;
    function linkFunction(scope, element, attribute, controller) {

      //Make sure dropdown is hidden at that start
      scope.isDropUp = false;

      //Always come in with scope dirty as false
      scope.dirty = false;

      //Always come in with scope reset as false
      scope.reset = false;
      // We are creating out own instance of the model so that we can tell when someone dirties the existing model
      var original_model = angular.copy(scope.ngModel);
      
      //Check to see if we have text or value fields to use within out list
      var textField;
      if(scope.textField) {
        textField = scope.textField.toString().trim();
      }

      //This setups what is currently being viewed in the select.
      function setLabel(reset) {
        console.log('setLabel :>> ');
        //If we have a placeholder set it, if we are reseting force the placeholder text
        if(scope.placeholderText || (reset && scope.placeholderText)) {
          scope.currentItemLabel = scope.placeholderText;
        }else{
          scope.currentItemLabel = "-- Select Item --";
        }

        // Remove placeholder text if we have a selected item on the model
        if(scope.ngModel && typeof scope.ngModel[textField] !== 'undefined' && !reset) {
          scope.currentItemLabel = scope.ngModel[textField].toString();
        }else if(scope.ngModel && typeof scope.ngModel !== 'undefined' && !reset){
          scope.currentItemLabel = scope.ngModel.toString();
        }
      }

      //Setup the label on init
      setLabel();

      //This checks if we need to drop up instead of dropdown because the window will cut off the list
      //TODO: we might want to make this run on resize and init rather then on click, it would be better optimized
      function checkDropUp() {
        scope.isDropUp = false;

        // We will have to assume that we know the max dropdown height is 165
        // otherwise we can not get the height since the dropdown could still be hidden here.
        var dropdown_menu_height = 170; //dropdown_menu.outerHeight();
        var window_height = angular.element($window).height();
        var dropdown_element_position = element[0].getBoundingClientRect().top;
        var dropup = window_height - (dropdown_element_position + dropdown_menu_height);

        if (dropup<50) {
          scope.isDropUp = true;
        }
      }

      //When click toggle the drop down
      scope.toggleDropDown = function(event) {
        console.log(' :>> TOGGLE DROPDOWN');
        //This is to find out if this directive needs to drop up because it is too close to the bottom of the browser.
        checkDropUp();

        var input = element.find('input');
        scope.search = '';

        //Events fire too fast and we need a second to focus on the input
        $timeout(function() {
            input.focus();
        }, 100);
        setCheckboxesFromModel();
      };

      scope.stopProp = function() {
        event.stopPropagation();
      };


      //On Click we are gonna set the value of the select
      scope.selectValue = function (item, index, reset) {
        $log.log('selectValue in pulse_filter_multiple_select', element, item, reset, item.selected);
        // scope.ngModel = [];
        //Stop all other events! When the input is focused or clicked we are going to open the list
        event.stopPropagation();
        $log.log('scope.ngModel before', scope.ngModel);

        if (!scope.ngModel.includes(item))
          scope.ngModel.push(item)
        else 
          scope.ngModel.splice(scope.ngModel.indexOf(item), 1);  // remove item

        $log.log('scope.ngModel after', scope.ngModel);
        scope.dirty = true;
        setLabel(reset);
        //If there is a callback function run it and pass the dat

        $timeout(function() {
          if(scope.callback && typeof scope.callback === 'function') {
              scope.callback(scope.data, item, index);
          }
        }, 0);
        // setCheckboxesFromModel();
      };

      // We compare the ngModel and items to know whats selected
      function setCheckboxesFromModel() {
        // console.log('setCheckboxesFromModel');
        if(!textField || !scope.ngModel || !scope.items) return;
        var itemsInModel = new Set(scope.ngModel);
        // console.log('setCheckboxesFromModel itemsInModel :>> ', itemsInModel);
        // for (var item of scope.items) {          
        for (var i = 0; i<scope.items.length;i++) { 
          var item = scope.items[i];
          item.selected = itemsInModel.has(item);
        }
      }

      scope.clearAllCheckboxes = function() {
        scope.ngModel.forEach(col => col.selected = false);
        scope.dirty = true;
      }

      //Reset the data in the model and put the placeholder back in
      scope.cancelClose = function($event) {
        $log.log('cancelClose', $event);
        $event.stopPropagation();
        scope.selectValue(false, null, true);
        scope.ngModel = [];
        scope.dirty = false;
        scope.search = '';
      };

      //watch the model to sync the checkboxes
      scope.$watch('ngModel', function(new_model, old_model) {
        $log.log('Pulse Filter Multiple Select Watch Model', new_model);
        $log.log('Pulse Filter Multiple Select Watch Old Model', old_model);
        $log.log('Pulse Filter Multiple Select Watch Clean Model',  original_model);

        // if we have set a text field then we check that instead of the whole object
        if(textField) {
          new_model = new_model ? new_model[textField] : null;
          old_model = old_model ? old_model[textField] : null;
        }

        if(new_model && !angular.equals(new_model, old_model)) {
          //Set the dirty flag, we arent tracking if they have the same information but if they
          setCheckboxesFromModel();
          if(!angular.equals(new_model, original_model)) {
            scope.dirty = true;
            setLabel();
          }else{
            scope.dirty = false;
          }
        }
      }, true);

      // //We need to watch the items because if we change the items and we need to update the selected items
      // scope.$watch('--items', function(new_items, old_items) {
      //   $log.log('Pulse Filter Multiple Select Watch new items', new_items);
      //   $log.log('Pulse Filter Multiple Select Watch Old items', old_items);

      //   if(new_items && !angular.equals(new_items, old_items)) {
      //     setCheckboxesFromModel();
      //   }
      // }, true);

      scope.$watch('setDisabled',function(new_value){
        scope.setDisabled = new_value;
      },true);

      //Sometimes we need to clear the dirty state from events outside of the directive
      scope.$watch('reset', function(new_reset, old_reset) {
        $log.log('Pulse Filter Multiple Select Watch Reset', new_reset);
        $log.log('Pulse Filter Multiple Select Watch Old Reset', old_reset);
        if(new_reset && new_reset !== old_reset) {
          //Reset the dirty state
          scope.dirty = false;
        }
      }, true);

    }
  }
})();
