/**
 * @ngdoc module
 * @name RoutingProvider
 * @requires ui.router, $stateProvider
 */
(function (angular) {
  'use strict';

  function $RoutingProvider() {

    //TODO: make this more generic so the whole application can use it.
    var self = this;

    //Add custom states to the dropdown.
    var addedSettings = [];
    this.addToSettings = function(stateName, state) {
      addedSettings.push({'stateName':stateName, 'state': state})
    };

    this.createDrawerState = function(parent, context, size) {

      var drawerState = {
        abstract: true,
        parent: parent,  //When the provider runs and creates this state, it actually modifies the parent state to the full path.
        onEnter:
          ['$stateParams', '$state', '$drawer',
            function($stateParams, $state, $drawer) {

              var that = drawerState; // We need to declare that because we need to come back to this state later.

              //TODO: we can handle this better by building a $drawer[n] object in the provider router
              $drawer.open({
                templateUrl: 'views/common/settings/drawer.html',
                windowClass: size ? size : 'quarter',
                controller: ['$scope', '$log', '$signalProvider', '$modalInstance', '$state', '$stateParams', '$rootScope', 'modalManager',
                  function ($scope, $log, $signalProvider, $modalInstance, $state, $stateParams, $rootScope, modalManager) {

                    //TODO: clean this up -- this will cause conflicts between other views.
                    $scope.$state = $state;
                    $scope.$stateParams = $stateParams;

                    $scope.view = context;

                    function drawerClose(name,value) {
                      $modalInstance.close();
                    }

                    $scope.close = function() {

                      if($rootScope.dirty) {

                        var confirm = modalManager.openModal({
                          templateUrl: 'views/common/modals/unsaved.html',
                          controller: 'ConfirmModalCtrl',
                          windowClass: 'confirm',
                          resolve: {
                            title: function () {
                              return 'There are unsaved changes.';
                            },
                            body: function () {
                              //This can accept html
                              return "Are you sure you want to leave without saving your changes?";
                            },
                            button: function () {
                              return 'Continue';
                            }
                          }
                        });
                        //Remove the modal from the dom
                        confirm.result.then(function() {
                          $rootScope.dirty = false;
                          $modalInstance.close();
                        });
                      }else{
                        $modalInstance.close();
                      }

                    }

                    //listen for when to search
                    var drawerClose = $signalProvider.listen(context+'_close', drawerClose);
                    //Destroy the function on search
                    $scope.$on('$destroy', function(){
                      $signalProvider.unlisten(context+'_close',drawerClose);
                    });

                  }]
              }).result.finally(function() {
                //TODO: This is a hack but better then what we had before
                //When the provider runs and creates this state, it actually modifies the parent state to the full path.
                // $state.go(that.parent, $stateParams, { notify: false }); //come back to that/this state
                $state.go('^.^', $stateParams, { notify: false }); // This will go to the parent state

              });

            }],
        onExit:
          ['$signalProvider', '$rootScope',
            function($signalProvider, $rootScope) {
              if(!$rootScope.dirty) {
                $signalProvider.signal(context+'_close', true); //Close the drawer
              }
            }]
      };


      return drawerState;

    };

    this.addDrawerStates = function(currentState, $stateProvider) {
      var rootState = {
        abstract: true,
        parent: currentState,
        onEnter: ['$stateParams', '$state', '$drawer', '$filter', '$signalProvider',
          function ($stateParams, $state, $drawer, $filter, $signalProvider) {

            //Turn off polling for everything under the drawer
            $signalProvider.signal("polling", false);

            $drawer.open({
              templateUrl: 'views/common/settings/drawer.html',
              controller: 'SettingsDrawerCtrl',
              keyboard: false,
              windowClass: 'halfResponsive'
            }).result.finally(function () {

              //TODO: we need to rethink this now that we have / in our state names
              var toParent = $filter('findParentStateFromSlashes')($state.current.name);
              var fromParent = $filter('findParentState')(currentState);

              //We need to check to see if we are going to the parent state.
              if (!toParent || !fromParent || toParent === fromParent) {
                $state.go(currentState);
              }

            });
          }
        ],
        onExit: ['$signalProvider',
          function ($signalProvider) {

            $signalProvider.signal("polling", true); //Turn on polling
            $signalProvider.signal('drawerClose', true); //Close the drawer

          }
        ]
      };

      var base = currentState.replace(/[.]/gi, "/") + '/onboarding';
      //Chain the provider.
      $stateProvider.state(base, rootState);

      //Check all added states
      for(var i=0;i<addedSettings.length;i++) {
        var state = angular.copy(addedSettings[i].state); // We need to copy this object because each name extended to THIS current object

        // If we get an object with a parent scope, add on the current scope we are trying to attach.
        if(state.hasOwnProperty('parent')) {
          state.parent = base + state.parent;
        }

        // Add the state to the provider.
        // $stateProvider.state(base + addedSettings[i].stateName, state);
        $stateProvider.state(base + addedSettings[i].stateName, state);

      }
    };

    this.addSettingsStates = function(currentState, $stateProvider) {
      var rootState = {
        abstract: true,
        parent: currentState,
        onEnter: ['$stateParams', '$state', '$drawer', '$filter', '$signalProvider',
          function($stateParams, $state, $drawer, $filter, $signalProvider) {

            //Turn off polling for everything under the drawer
            $signalProvider.signal("polling", false);

            $drawer.open({
              templateUrl: 'views/common/settings/drawer.html',
              controller: 'SettingsDrawerCtrl',
              keyboard: false,
              windowClass: 'halfResponsive'
            }).result.finally(function() {

              //TODO: we need to rethink this now that we have / in our state names
              var toParent = $filter('findParentStateFromSlashes')($state.current.name);
              var fromParent = $filter('findParentState')(currentState);

              //We need to check to see if we are going to the parent state.
              if (!toParent || !fromParent || toParent === fromParent) {
                $state.go(currentState);
              }

            });
          }
        ],
        onExit: ['$signalProvider',
          function($signalProvider) {

            $signalProvider.signal("polling", true); //Turn on polling
            $signalProvider.signal('drawerClose', true); //Close the drawer

          }
        ]
      };

      var groupsState = {
        //TODO:  Find a way to remove this, probably used in old applicatons
        url: '/groups/:dummyID', //The dummyID is to trigger this state to reload from a child state (ie when clicking "Back" from the Edit screen)
        params: {
          dummyID: { squash: true, value: null }
        },
        resolve: {
          groups: ['$stateParams', 'Groups',
            function($stateParams, Groups) {
              return Groups.ProjectGroups.getGroups({
                projectID: $stateParams.projectID
              }).$promise;
            }
          ],
          userGroupSearchMetadata: ['$q', '$stateParams', 'SearchData',
            function($q, $stateParams, SearchData) {

              var defer = $q.defer();

              //Gathers the smart data for the search bar.
              var promise = SearchData.usergroups({}).$promise;
              promise.then(function(response) {

                //This should be done in the directive possibly
                //Or handed to us in a better way.
                for (var i = 0; i < response.metadata.length; i++) {
                  response.metadata[i].type = 'Metadata';
                }

                defer.resolve(response);

              });

              return defer.promise;
            }
          ]
        },
        views: {
          'body@': {
            templateUrl: 'views/common/settings/groups/groups.html',
            controller: 'GroupsCtrl'
          }
        }
      };

      var groupsEditState = {
        url: '/:groupID',
        projectSpecificParams: true,
        params: {
          groupID: { squash: true, value: null }
        },
        resolve: {
          group: ['$stateParams', 'Groups',
            function($stateParams, Groups) {
              if ($stateParams.groupID === 'create') {
                return [];
              } else {
                return Groups.ProjectGroups.getGroup({
                  projectID: $stateParams.projectID,
                  groupID: $stateParams.groupID
                }).$promise;
              }
            }
          ],
          userGroupSearchMetadata: ['$q', '$stateParams', 'SearchData',
            function($q, $stateParams, SearchData) {

              var defer = $q.defer();

              //Gathers the smart data for the search bar.
              var promise = SearchData.usergroups({}).$promise;
              promise.then(function(response) {

                //This should be done in the directive possibly
                //Or handed to us in a better way.
                for (var i = 0; i < response.metadata.length; i++) {
                  response.metadata[i].type = 'Metadata';
                }

                defer.resolve(response);

              });

              return defer.promise;
            }
          ]
        },
        views: {
          'body@': {
            templateUrl: 'views/common/settings/groups/edit.html',
            controller: 'GroupEditCtrl'
          }
        }

      };

      var serversState = {
        url: '/servers/:dummyID', //The dummyID is to trigger this state to reload from a child state (ie when clicking "Back" from the Edit screen)
        params: {
          dummyID: { squash: true, value: null }
        },
        views: {
          'body@': {
            templateUrl: 'views/common/settings/servers/server.html',
            controller: 'ServerCtrl',
          }
        },
        resolve: {
              pools: ['Pools', '$stateParams',
                function(Pools, $stateParams) {
                  return Pools.getPools({
                    projectID: $stateParams.projectID
                  }).$promise;
                }
              ]
            }
      };

      var serversEditState = {
        url: '/:poolID',
        projectSpecificParams: true,
        params: {
          poolID: { squash: true, value: null }
        },
        views: {
          'body@': {
            templateUrl: 'views/common/settings/servers/edit.html',
            controller: 'ServerEditCtrl',

          }
        },
        resolve: {
              poolTypes: ['Pools', '$stateParams',
                function(Pools, $stateParams) {
                  return Pools.getPoolTypes({
                    projectID: $stateParams.projectID
                  }).$promise;
                }
              ],
              storageDevices: ['Storage',
                function(Storage) {
                  return Storage.getStorageBackends().$promise;
                }],
              pool: ['Pools', '$stateParams',
                function(Pools, $stateParams) {
                  if ($stateParams.poolID === 'create') {
                    return {};
                  } else {
                    return Pools.getPool({
                      projectID: $stateParams.projectID,
                      poolID: $stateParams.poolID
                    }).$promise.then(function(pool) {
                      return pool;
                    });
                  }
                }
              ],
              poolMembers: ['Pools', '$stateParams',
                function(Pools, $stateParams) {
                  if ($stateParams.poolID === 'create') {
                    return [];
                  } else {
                    return Pools.getPoolMembers({
                      projectID: $stateParams.projectID,
                      poolID: $stateParams.poolID
                    }).$promise;
                  }
                }
              ],

              /*storageDevices: [function() {
               return [{
               name: 'S3'
               }, {
               name: 'Aspera'
               }, {
               name: 'ExternalVolume'
               }];
               }],*/

              userGroupSearchMetadata: ['$q', '$stateParams', 'SearchData',
                function($q, $stateParams, SearchData) {

                  var defer = $q.defer();

                  //Gathers the smart data for the search bar.
                  var promise = SearchData.usergroups({}).$promise;
                  promise.then(function(response) {
                    //This should be done in the directive possibly
                    for (var i = 0; i < response.metadata.length; i++) {
                      response.metadata[i].type = 'Metadata';
                    }
                    defer.resolve(response);
                  });

                  return defer.promise;

                }
              ]
            }
      };

      var usersState = {
        url: '/users',
        views: {
          'body@': {
            templateUrl: 'views/common/settings/users/users.html',
            controller: 'UsersCtrl'
          }
        },
        resolve: {
          usersSearchMetadata: ['$q', '$stateParams', 'SearchData',
            function($q, $stateParams, SearchData) {

              var defer = $q.defer();

              //Gathers the smart data for the search bar.
              var promise = SearchData.usergroups({}).$promise;
              promise.then(function(response) {
                for (var i = 0; i < response.metadata.length; i++) {
                  response.metadata[i].type = 'Metadata';
                }
                defer.resolve(response);
              });

              return defer.promise;
            }
          ]
        }
      };

      var addUserState = {
        url: '/add/user',
        views: {
          'add@': {
            templateUrl: 'views/common/settings/users/add.html',
            controller: 'AddUserCtrl',

          }
        },
        resolve: {
          user: ['user',
            function(user) {
              return user;
            }
          ],
          projectUsers: ['$stateParams', 'Users',
            function($stateParams, Users) {
              return Users.ProjectUser.getUsers({projectID: $stateParams.projectID}).$promise;
            }
          ],
          organizations: ['Organizations',
            function(Organizations) {
              return Organizations.query({}).$promise;
            }
          ]
        }
      };

      var rolesUserState = {
        url: '/roles/user/:userID/organization/:userOrganizationID',
        views: {
          'roles@': {
            templateUrl: 'views/common/settings/users/roles.html',
            controller: 'RolesUserCtrl',

          }
        },
        resolve: {
          userEdit: ['$stateParams', 'Users',
            function($stateParams, Users) {
              return Users.User.getUser({userID: $stateParams.userID}).$promise;
            }
          ],
          userRoles: ['$stateParams', 'Roles',
            function($stateParams, Roles) {
              return Roles.ProjectUsersRoles.getUserRoles({projectID: $stateParams.projectID, userID: $stateParams.userID}).$promise;
            }
          ],
          roles: ['$stateParams', 'Roles',
            function($stateParams, Roles) {
              return Roles.ProjectRoles.getList({projectID: $stateParams.projectID}).$promise;
            }
          ]
        }
      };

      var rolesState = {
        url: '/roles',
        views: {
          'body@': {
            templateUrl: 'views/common/settings/roles/roles.html',
            controller: 'RolesCtrl',

          }
        },
        resolve: {
          usersSearchMetadata: ['$q', '$stateParams', 'SearchData',
            function($q, $stateParams, SearchData) {

              var defer = $q.defer();

              //Gathers the smart data for the search bar.
              var promise = SearchData.usergroups({}).$promise;
              promise.then(function(response) {
                for (var i = 0; i < response.metadata.length; i++) {
                  response.metadata[i].type = 'Metadata';
                }
                defer.resolve(response);
              });

              return defer.promise;
            }
          ]
        }
      };

      var editRoleState = {
        url: '/role/:roleID',
        params: {
          roleID: { squash: true, value: null }
        },
        views: {
          'edit@': {
            templateUrl: 'views/common/settings/roles/edit.html',
            controller: 'EditRoleCtrl',

          }
        },
        resolve: {
          projectApplications: ['applications',
            function(applications) {

              var array = [];
              for (var i = 0; i < applications.length; i++) {
                if(applications[i].perProject === true) {
                  array.push(applications[i]);
                }
              }

              return array;

            }
          ],
          role: ['$stateParams', 'Roles',
            function($stateParams, Roles) {

              if($stateParams.roleID !== 'create') {
                return Roles.ProjectRoles.get({projectID:$stateParams.projectID, roleID: $stateParams.roleID }).$promise;
              }else{
                return '';
              }

            }
          ],
          roleRights: ['$stateParams', 'Roles',
            function($stateParams, Roles) {

              if($stateParams.roleID !== 'create') {
                return Roles.ProjectRoles.getRights({projectID:$stateParams.projectID, roleID: $stateParams.roleID }).$promise;
              }else{
                return [];
              }

            }
          ]
        }
      };

      var importsState = {
        url: '/imports',
        views: {
          'body@': {
            templateUrl: 'views/common/settings/imports/imports.html',
            controller: 'ImportsCtrl',
          }
        }
      };

      var editImportState = {
        url: '/import/:importID',
        params: {
          importID: { squash: true, value: null }
        },
        views: {
          'edit@': {
            templateUrl: 'views/common/settings/imports/edit.html',
            controller: 'EditImportCtrl',

          }
        },
        resolve: {
          importSetup: ['$stateParams', 'Projects',
            function($stateParams, Projects) {
              if($stateParams.importID !== 'create') {
                return Projects.Project.getImport({projectId:$stateParams.projectID, typeId: $stateParams.importID }).$promise;
              }else{
                return '';
              }
            }
          ]
        }
      };


      var base = currentState.replace(/[.]/gi, "/") + '/settings';
      //Chain the provider.
      $stateProvider.state(base, rootState)
        .state(base + '.servers', serversState)
        .state(base + '.servers.edit', serversEditState)
        .state(base + '.groups', groupsState)
        .state(base + '.groups.edit', groupsEditState)
        .state(base + '.users', usersState)
        .state(base + '/users/add', self.createDrawerState(base + '.users','add'))
        .state(base + '/users/add.user', addUserState)
        .state(base + '/users/roles', self.createDrawerState(base + '.users','roles'))
        .state(base + '/users/roles.user', rolesUserState)
        .state(base + '.roles', rolesState)
        .state(base + '/roles/edit', self.createDrawerState(base + '.roles','edit'))
        .state(base + '/roles/edit.role', editRoleState)
        .state(base + '.imports', importsState)
        .state(base + '/imports/edit', self.createDrawerState(base + '.imports','edit'))
        .state(base + '/imports/edit.import', editImportState);

      //Check all added states
      for(var i=0;i<addedSettings.length;i++) {
        var state = angular.copy(addedSettings[i].state); // We need to copy this object because each name extended to THIS current object

        // If we get an object with a parent scope, add on the current scope we are trying to attach.
        if(state.hasOwnProperty('parent')) {
          state.parent = base + state.parent;
        }

        // Add the state to the provider.
        $stateProvider.state(base + addedSettings[i].stateName, state);

      }

    };

    this.addSettingsStatesV2 = function(currentState, $stateProvider) {

      var rootState = {
        abstract: true,
        parent: currentState,
        onEnter: ['$stateParams', '$state', '$drawer', '$filter', '$signalProvider',
          function($stateParams, $state, $drawer, $filter, $signalProvider) {

            //Turn off polling for everything under the drawer
            $signalProvider.signal("polling", false);

            $drawer.open({
              templateUrl: 'views/common/settingsv2/drawer.html',
              controller: 'SettingsDrawerV2Ctrl',
              keyboard: false,
              windowClass: 'halfResponsive'
            }).result.finally(function() {

              //TODO: we need to rethink this now that we have / in our state names
              var toParent = $filter('findParentStateFromSlashes')($state.current.name);
              var fromParent = $filter('findParentState')(currentState);

              //We need to check to see if we are going to the parent state.
              if (!toParent || !fromParent || toParent === fromParent) {
                $state.go(currentState);
              }

            });
          }
        ],
        onExit: ['$signalProvider',
          function($signalProvider) {

            $signalProvider.signal("polling", true); //Turn on polling
            $signalProvider.signal('drawerClose', true); //Close the drawer

          }
        ]
      };


      //DO NOT USE! THIS CONFLICTS w/ dipulls router. We need migrate DIpulls first.
      //
      // var vendorsState = {
      //   url: '/vendors/:vendorID',
      //   views: {
      //     'body@': {
      //       templateUrl: 'views/common/settingsv2/vendors/vendors_layout.html',
      //       controller: 'VendorsV2Ctrl'
      //     }
      //   },
      //   params: {
      //     vendorID: { squash: true, value: null }
      //   }
      // };
      /// this is not a vendor edit.  it is a server CRUD
      // var vendorsEditState = {
      //   url: '/servers/:poolID',
      //   views: {
      //     'server@': {
      //       templateUrl: 'views/common/settingsv2/vendors/vendors_server_layout.html',
      //       controller: 'VendorsEditV2Ctrl'
      //     }
      //   },
      //   params: {
      //     poolID: { squash: true, value: null }
      //   }
      // };

      var groupsState = {
        //TODO:  Find a way to remove this, probably used in old applicatons
        url: '/groups/:dummyID', //The dummyID is to trigger this state to reload from a child state (ie when clicking "Back" from the Edit screen)
        params: {
          dummyID: { squash: true, value: null }
        },
        resolve: {
          groups: ['$stateParams', 'Groups',
            function($stateParams, Groups) {
              return Groups.ProjectGroups.getGroups({
                projectID: $stateParams.projectID
              }).$promise;
            }
          ],
          userGroupSearchMetadata: ['$q', '$stateParams', 'SearchData',
            function($q, $stateParams, SearchData) {

              var defer = $q.defer();

              //Gathers the smart data for the search bar.
              var promise = SearchData.usergroups({}).$promise;
              promise.then(function(response) {

                //This should be done in the directive possibly
                //Or handed to us in a better way.
                for (var i = 0; i < response.metadata.length; i++) {
                  response.metadata[i].type = 'Metadata';
                }

                defer.resolve(response);

              });

              return defer.promise;
            }
          ]
        },
        views: {
          'body@': {
            templateUrl: 'views/common/settingsv2/groups/groups.html',
            controller: 'GroupsV2Ctrl'
          }
        }
      };

      var groupsEditState = {
        url: '/:groupID',
        projectSpecificParams: true,
        params: {
          groupID: { squash: true, value: null }
        },
        resolve: {
          group: ['$stateParams', 'Groups',
            function($stateParams, Groups) {
              if ($stateParams.groupID === 'create') {
                return [];
              } else {
                return Groups.ProjectGroups.getGroup({
                  projectID: $stateParams.projectID,
                  groupID: $stateParams.groupID
                }).$promise;
              }
            }
          ],
          userGroupSearchMetadata: ['$q', '$stateParams', 'SearchData',
            function($q, $stateParams, SearchData) {

              var defer = $q.defer();

              //Gathers the smart data for the search bar.
              var promise = SearchData.usergroups({}).$promise;
              promise.then(function(response) {

                //This should be done in the directive possibly
                //Or handed to us in a better way.
                for (var i = 0; i < response.metadata.length; i++) {
                  response.metadata[i].type = 'Metadata';
                }

                defer.resolve(response);

              });

              return defer.promise;
            }
          ]
        },
        views: {
          'body@': {
            templateUrl: 'views/common/settingsv2/groups/edit.html',
            controller: 'GroupEditV2Ctrl'
          }
        }

      };

      var serversState = {
        url: '/servers/:dummyID', //The dummyID is to trigger this state to reload from a child state (ie when clicking "Back" from the Edit screen)
        params: {
          dummyID: { squash: true, value: null }
        },
        views: {
          'body@': {
            templateUrl: 'views/common/settingsv2/servers/server.html',
            controller: 'ServerV2Ctrl',
          }
        },
        resolve: {
              pools: ['Pools', '$stateParams',
                function(Pools, $stateParams) {
                  return Pools.getPools({
                    projectID: $stateParams.projectID
                  }).$promise;
                }
              ]
            }
      };

      var serversEditState = {
        url: '/:poolID',
        projectSpecificParams: true,
        params: {
          poolID: { squash: true, value: null }
        },
        views: {
          'body@': {
            templateUrl: 'views/common/settingsv2/servers/edit.html',
            controller: 'ServerEditV2Ctrl',

          }
        },
        resolve: {
              poolTypes: ['Pools', '$stateParams',
                function(Pools, $stateParams) {
                  return Pools.getPoolTypes({
                    projectID: $stateParams.projectID
                  }).$promise;
                }
              ],
              storageDevices: ['Storage',
                function(Storage) {
                  return Storage.getStorageBackends().$promise;
                }],
              pool: ['Pools', '$stateParams',
                function(Pools, $stateParams) {
                  if ($stateParams.poolID === 'create') {
                    return {};
                  } else {
                    return Pools.getPool({
                      projectID: $stateParams.projectID,
                      poolID: $stateParams.poolID
                    }).$promise.then(function(pool) {
                      return pool;
                    });
                  }
                }
              ],
              poolMembers: ['Pools', '$stateParams',
                function(Pools, $stateParams) {
                  if ($stateParams.poolID === 'create') {
                    return [];
                  } else {
                    return Pools.getPoolMembers({
                      projectID: $stateParams.projectID,
                      poolID: $stateParams.poolID
                    }).$promise;
                  }
                }
              ],

              /*storageDevices: [function() {
               return [{
               name: 'S3'
               }, {
               name: 'Aspera'
               }, {
               name: 'ExternalVolume'
               }];
               }],*/

              userGroupSearchMetadata: ['$q', '$stateParams', 'SearchData',
                function($q, $stateParams, SearchData) {

                  var defer = $q.defer();

                  //Gathers the smart data for the search bar.
                  var promise = SearchData.usergroups({}).$promise;
                  promise.then(function(response) {
                    //This should be done in the directive possibly
                    for (var i = 0; i < response.metadata.length; i++) {
                      response.metadata[i].type = 'Metadata';
                    }
                    defer.resolve(response);
                  });

                  return defer.promise;

                }
              ]
            }
      };

      var usersState = {
        url: '/users',
        views: {
          'body@': {
            templateUrl: 'views/common/settingsv2/users/users.html',
            controller: 'UsersV2Ctrl'
          }
        },
        resolve: {
          usersSearchMetadata: ['$q', '$stateParams', 'SearchData',
            function($q, $stateParams, SearchData) {

              var defer = $q.defer();

              //Gathers the smart data for the search bar.
              var promise = SearchData.usergroups({}).$promise;
              promise.then(function(response) {
                for (var i = 0; i < response.metadata.length; i++) {
                  response.metadata[i].type = 'Metadata';
                }
                defer.resolve(response);
              });

              return defer.promise;
            }
          ]
        }
      };

      var addUserState = {
        url: '/add/user',
        views: {
          'add@': {
            templateUrl: 'views/common/settingsv2/users/add.html',
            controller: 'AddUserV2Ctrl',

          }
        },
        resolve: {
          user: ['user',
            function(user) {
              return user;
            }
          ],
          projectUsers: ['$stateParams', 'Users',
            function($stateParams, Users) {
              return Users.ProjectUser.getUsers({projectID: $stateParams.projectID}).$promise;
            }
          ],
          organizations: ['Organizations',
            function(Organizations) {
              return Organizations.query({}).$promise;
            }
          ]
        }
      };

      var rolesUserState = {
        url: '/roles/user/:userID/organization/:userOrganizationID',
        views: {
          'roles@': {
            templateUrl: 'views/common/settingsv2/users/roles.html',
            controller: 'RolesUserV2Ctrl',

          }
        },
        resolve: {
          userEdit: ['$stateParams', 'Users',
            function($stateParams, Users) {
              return Users.User.getUser({userID: $stateParams.userID}).$promise;
            }
          ],
          userRoles: ['$stateParams', 'Roles',
            function($stateParams, Roles) {
              return Roles.ProjectUsersRoles.getUserRoles({projectID: $stateParams.projectID, userID: $stateParams.userID}).$promise;
            }
          ],
          roles: ['$stateParams', 'Roles',
            function($stateParams, Roles) {
              return Roles.ProjectRoles.getList({projectID: $stateParams.projectID}).$promise;
            }
          ]
        }
      };

      var rolesState = {
        url: '/roles',
        views: {
          'body@': {
            templateUrl: 'views/common/settingsv2/roles/roles.html',
            controller: 'RolesV2Ctrl',

          }
        },
        resolve: {
          usersSearchMetadata: ['$q', '$stateParams', 'SearchData',
            function($q, $stateParams, SearchData) {

              var defer = $q.defer();

              //Gathers the smart data for the search bar.
              var promise = SearchData.usergroups({}).$promise;
              promise.then(function(response) {
                for (var i = 0; i < response.metadata.length; i++) {
                  response.metadata[i].type = 'Metadata';
                }
                defer.resolve(response);
              });

              return defer.promise;
            }
          ]
        }
      };

      var editRoleState = {
        url: '/role/:roleID',
        params: {
          roleID: { squash: true, value: null }
        },
        views: {
          'edit@': {
            templateUrl: 'views/common/settingsV2/roles/edit.html',
            controller: 'EditRoleV2Ctrl',

          }
        },
        resolve: {
          projectApplications: ['applications',
            function(applications) {

              var array = [];
              for (var i = 0; i < applications.length; i++) {
                if(applications[i].perProject === true) {
                  array.push(applications[i]);
                }
              }

              return array;

            }
          ],
          role: ['$stateParams', 'Roles',
            function($stateParams, Roles) {

              if($stateParams.roleID !== 'create') {
                return Roles.ProjectRoles.get({projectID:$stateParams.projectID, roleID: $stateParams.roleID }).$promise;
              }else{
                return '';
              }

            }
          ],
          roleRights: ['$stateParams', 'Roles',
            function($stateParams, Roles) {

              if($stateParams.roleID !== 'create') {
                return Roles.ProjectRoles.getRights({projectID:$stateParams.projectID, roleID: $stateParams.roleID }).$promise;
              }else{
                return [];
              }

            }
          ]
        }
      };

      var importsState = {
        url: '/imports',
        views: {
          'body@': {
            templateUrl: 'views/common/settingsv2/imports/imports.html',
            controller: 'ImportsV2Ctrl',
          }
        }
      };

      var editImportState = {
        url: '/import/:importID',
        params: {
          importID: { squash: true, value: null }
        },
        views: {
          'edit@': {
            templateUrl: 'views/common/settingsv2/imports/edit.html',
            controller: 'EditImportV2Ctrl',

          }
        },
        resolve: {
          importSetup: ['$stateParams', 'Projects',
            function($stateParams, Projects) {
              if($stateParams.importID !== 'create') {
                return Projects.Project.getImport({projectId:$stateParams.projectID, typeId: $stateParams.importID }).$promise;
              }else{
                return '';
              }
            }
          ]
        }
      };


      var base = currentState.replace(/[.]/gi, "/") + '/settings';
      //Chain the provider.
      $stateProvider.state(base, rootState)
        //  CONFLICTS W/ DIPulls - looks at filemnager route setting.js
        // .state(base + '.vendors', vendorsState)
        // .state(base + '/vendors/servers', self.createDrawerState(base + '.vendors', 'server'))
        // .state(base + '/vendors/server.add', vendorsEditState)
        /// STATES CONFLICT W/ DIPulls
        .state(base + '.servers', serversState)
        .state(base + '.servers.edit', serversEditState)
        .state(base + '.groups', groupsState)
        .state(base + '.groups.edit', groupsEditState)
        .state(base + '.users', usersState)
        .state(base + '/users/add', self.createDrawerState(base + '.users','add'))
        .state(base + '/users/add.user', addUserState)
        .state(base + '/users/roles', self.createDrawerState(base + '.users','roles'))
        .state(base + '/users/roles.user', rolesUserState)
        .state(base + '.roles', rolesState)
        .state(base + '/roles/edit', self.createDrawerState(base + '.roles','edit'))
        .state(base + '/roles/edit.role', editRoleState)
        .state(base + '.imports', importsState)
        .state(base + '/imports/edit', self.createDrawerState(base + '.imports','edit'))
        .state(base + '/imports/edit.import', editImportState);

      //Check all added states
      for(var i=0;i<addedSettings.length;i++) {
        var state = angular.copy(addedSettings[i].state); // We need to copy this object because each name extended to THIS current object
        // If we get an object with a parent scope, add on the current scope we are trying to attach.
        if(state.hasOwnProperty('parent')) {
          state.parent = base + state.parent;
        }

        // Add the state to the provider.
        $stateProvider.state(base + addedSettings[i].stateName, state);

      }

    };

    // We need this for the provider but really we are just hacking a provider to add states before application load.
    this.$get = [
      function() {
        return null;
      }
    ];
  }


  angular.module('pulse.routing', ['ui.router'])
    .provider('$routing', $RoutingProvider);

})(window.angular);
