/**
 * @ngdoc object
 * @name PulseModalConfirm
 * @requires $log
 * @description A resuable compent to confirm an action (modal)
 */


 (function(){
   'use strict';

   angular.module('pulse').component('pulseModalConfirm', {
     templateUrl : 'views/common/components/pulse_modal_confirm.html',
     bindings : {
       showConfirmation: '<',
       modalLoading: '<',
       modalHeader: '<',
       modalBody: '<',
       modalActions: '<'
     },
     controller : Controller
   });

   Controller.$inject = ['$log', '$timeout'];

   function Controller($log, $timeout){

      var ctrl = this;

      ctrl.$onInit = function(){
        if (ctrl.modalLoading){
          $timeout(function(){
            ctrl.modalLoading = false;
          }, 500);
        }
      }

   } // end Controller

 })();
