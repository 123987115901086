///need to add ng-doc stuff
// I would like to move this Dir to the commons folder
// this dir ver. does not support polling.

(function() {
    'use strict';

    angular
        .module('pulse')
        .directive('pulseFooter', directive);

    /* @ngInject */

    directive.$inject = ['$log', '$timeout']
    function directive($log, $timeout) {
        var directive = {
            restrict: 'EA',
            templateUrl: function(el, attr){
              if(attr.template != undefined){
                return attr.template;
              }else{
                return 'views/templates/error.html';
              }
            },
            scope: {
              init : '='
            },
            link: linkFunc,
            controller: Controller
        };

        return directive;

        function linkFunc(scope, el, attr) {


        }
    }

    Controller.$inject = ['$scope', '$log','ServiceManager'];

    /* @ngInject */
    function Controller($scope, $log, ServiceManager) {
      $log.log('pulseFooter Dir Controller ');

      $scope.vm = {
        service : ServiceManager
      };

      if($scope.init){
        init();
      }

      function init(){
        //setContext
        //$scope.vm.service[$scope.init.service]['setContext']($scope.init.context_key, $scope.init.context_val);
        //call method
        $scope.vm.service[$scope.init.service][$scope.init.method]($scope.init.context_key);
      };

    }
})();
