/**
 * @ngdoc filter
 * @name sanitizeText
 * @module pulse
 * @requires {String} text
 * @requires {String} pattern
 * @optional {String} replacement
 * @description
 * `sanitizeText` provides the filter to sanitise the text based on the given pattern.
 * @Author AYUSHMAN TIWARI
 *
 **/

'use strict';
angular.module('pulse')
  .filter('sanitizeText',['$log', '$filter' ,function ($log, $sanitize, $filter) {
    return function (text, pattern, replacement) {
      if (!text || typeof text !== 'string') {
        throw new Error('Expected text of type string');
      }
      if (!pattern) {
        throw new Error('Expected pattern of type string');
      }
      var result = ''
      var regexPattern = new  RegExp(pattern, 'g')
      if (regexPattern.test(text)){
        if (!replacement){
          result = text.replace(regexPattern, '');
          if (result.indexOf('\xa0') !== -1) {
            result = result.replace(/\xa0/g,'');
          }
          return result
        }
        else {
          result = text.replace(regexPattern, replacement);
          return result
        }
      }
      else {
        return text
      }
    }
  }]);
