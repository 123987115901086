/**
 * @ngdoc object
 * @name ShotgunExternalApplication
 * @requires $resource
 */

'use strict';

angular.module('pulse')
  .factory('ShotgunExternalApplication',
    ['$resource',
      function ($resource) {
        return $resource(
          config.baseURL + '/shotgun/:ext/:id',
          {ext: '@ext', id: '@id'},
          {
            getConfigs: {method: 'GET', params: {ext:'configs'}, isArray:true},
            getConfig: {method: 'GET', params: {ext:'configs'}},
            createConfig: {method: 'POST', params: {ext:'configs'}},
            updateConfig: {method: 'POST', params: {ext:'configs'}},
            deleteConfig: {method: 'DELETE', params: {ext:'configs'}}
          }
        );
      }
    ]
  );
