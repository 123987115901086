/**
 * @ngdoc object
 * @name runApp
 * @requires $log, $idle, $document, $rootScope, pulseStorage, $signalProvider, pulseGlobalState, pulseErrorModal, pulseBeforeUnload
 * @description This is everything that happens when the application starts up.
 */

(function() {
  'use strict';

  runApp.$inject = ['$log', '$idle', '$document', '$rootScope', '$signalProvider', 'pulseStorage', 'pulseGlobalState', 'pulseErrorModal', 'pulseBeforeUnload'];
  angular
    .module('pulse')
    .run(runApp);

  /* @ngInject */
  function runApp($log, $idle, $document, $rootScope, $signalProvider, pulseStorage,
                  pulseGlobalState, pulseErrorModal, pulseBeforeUnload) {

    $log.info('Run Application', pulseGlobalState);
    $log.info('Error Modal', pulseErrorModal);
    $log.info('Onbeforeunload', pulseBeforeUnload);

    //Start the idle timer!
    // $idle.watch();

    //TODO: this should be done in the permissions service.
    //TODO: r00t state should be made in the config and passed to permission service
    //Landing page does not have permissions, anyone can access it
    $rootScope.rootStates = {};
    //$rootScope.rootStates[] = '';

    //This allows the project common view to find out what permissions we COULD have for this application
    //Checkout common/projects.js
    $rootScope.permissions = [];

    //Listens to all the events happening and signals to the rest of the interface to do something.
    $document.bind('keydown', function(event) {
      $signalProvider.signal('keydown',event);
    });

    //Prevent Drag and Dragover events from firing on anything that doesnt have the correct attributes
    $document.on('drop dragover', function (event) {
      event.preventDefault();
    });

    var app = config.application_name;

    //Storage service
    pulseStorage.init();
    //State and Global variables service
    pulseGlobalState.init(app, {});
    //Error modal service
    pulseErrorModal.init(app);
    //Unload service
    window.onbeforeunload = pulseBeforeUnload.onbeforeunload;

    //TODO: These can probably still go in their respective services?
    var resolveError = $signalProvider.listen("resolveError", pulseErrorModal.resolveError);

    $rootScope.$on('$destroy', function() {
      $signalProvider.unlisten("resolveError", pulseErrorModal.resolveError);
      window.onbeforeunload = undefined;
    });
  }

})();
