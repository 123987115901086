'use strict';

angular.module('pulse')
.filter('trimToLower', function () {
    return function (string) {
      //Check to make sure it is a string otherwise we will loop forever breaking.
      if(typeof string === 'string') {
        var newString = string.replace(/\s/g, '').toLowerCase();
        return newString;
      }else{
        return string;
      }
    };
});
