/**
 * @ngdoc object
 * @name PulseSearchResultActionsComponent
 * @requires $state
 * @description A resuable compent for Pulse navigation
 */

(function(){
  'use strict';

  angular.module('pulse').component('pulseSearchResultActions', {
    templateUrl : 'views/common/components/pulse_search_result_actions.html',
    bindings : {
        releaseDrawer: '<',
        clearRelease: '<',
        clearTransfer: '<',
        openReleaseTransferDrawer: '<',
        releaseRecipients: '<',
        transferVendors: '<',
        tiersSelections: '<',
        selectedRecipients: '<',
        selectedVendor: '<',
        selectedTier: '<',
        searchRecipients: '<',
        searchVendors: '<',
        jobList: '<',
        jobListIds: '<',
        clearJobList: '<',
        tierMoveFiles: '<',
        assetsLimit: '<',
        totalItems: '<',
        totalSize: '<',
        isSelectAll: '<',
    },
    controller : Controller
  });

  Controller.$inject = ['$log', '$state', '$stateParams', '$rootScope'];

  function Controller($log, $state, $stateParams, $rootScope){
    var ctrl = this;

    ctrl.vm = {
        'project_id': false,
        'is_loading': true,
        'show_list': true,
        'tabview': 1,
        'is_release': ($rootScope.storage.project.permissions.indexOf('filemanager:release') >= 0) ? true : false,
        'is_transfer': ($rootScope.storage.project.permissions.indexOf('filemanager:download') >= 0) ? true : false,
        'is_tier': ($rootScope.storage.project.fileManagerPoolType === 'CloudAzure') ? true : false
    };

    ctrl.$onInit = function(){
      $log.log("init Search Results Actions")
      ctrl.vm.project_id = $stateParams.projectID;
      if (ctrl.vm.is_release){
        ctrl.vm.tabview = 0;
      }else if (ctrl.vm.is_transfer){
        ctrl.vm.tabview = 1;
      }else if (ctrl.vm.is_release && ctrl.vm.is_transfer){
        ctrl.vm.tabview = 0;
      }
      ctrl.searchRecipients();
      ctrl.searchVendors();
    }

  }
})();
