/**
 * @ngdoc directive
 * @name pulseLogin
 * @module pulse
 * @restrict E
 * @description
 * `pulseLogin` provides a form for authentication.
 *
 * @usage
 * <hljs lang="html">
 *     <pulse-login></pulse-login>
 * </hljs>
 *
 */
(function(){
  'use strict';
  angular
    .module('pulse')
    .directive('pulseLogin', pulseLogin);

  function pulseLogin() {
    var directive = {
      restrict: 'E',
      replace: true,
      templateUrl: 'views/common/login/login.html',
      link: PulseLoginLink,
      controller: PulseLoginCtrl
    };
    return directive;

    function PulseLoginLink(scope, elem, attr, ctrl) {}
  }

  PulseLoginCtrl.$inject = ['$scope', '$rootScope', '$log', 'AuthenticationService'];

  /* @ngInject */
  function PulseLoginCtrl($scope, $rootScope, $log, AuthenticationService) {

    //If we have a user name lets set it
    //TODO: it might be better to pass this in somehow
    if(localStorage.length > 0 && (localStorage.getItem('email_address') !== null)) {
     $scope.email_address = localStorage.getItem('email_address');

      if ($scope.email_address === 'null') {
        $scope.email_address = '';
      }

    }

    $scope.userSessionEndType = sessionStorage.getItem('session-expire-type') || false;

    if ($scope.userSessionEndType === 'expire') {
      $scope.message = 'Your session has ended because you were inactive for too long — Please log in again.';
    }
    
    if ($scope.userSessionEndType === 'logout') {
      $scope.message = 'You have been successfully logged out.';
    }

    if ($scope.userSessionEndType === 'user-interaction') {
      $scope.message = 'You have been logged out.';
    }

    // Remove the session-expire-type flag after displaying the message
    sessionStorage.removeItem('session-expire-type');


    /**
     * @ngdoc function
     * @name submit
     * @description Submitting the users name to Pulse backend
     */
    $scope.submit = function(form) {

      //Reset the message
      $scope.message = '';
      $scope.isSessionTimeout = false;

      //We set a loading flag for the round trip
      $scope.loading = true;

      //We need to manually defer this within the service se we can display erorrs.
      var promise = AuthenticationService.login(form);
      promise.then(function(authentication) {
        $log.log('AuthenticationService.login Success', authentication);

        $scope.loading = false;

        //Display any message that comes form the backend for login
        //TODO: do we get any other messages form login other then errors?
        if(authentication.message) {
          $scope.message = authentication.message;
        }else{
          //We tell the form to display some kind of information about the
          //application redirecting to the platform
          $scope.redirecting = true;
        }

      }, function(error) {
        $log.log('AuthenticationService.login Failure', error);

        $scope.loading = false;
        $scope.userSessionEndType = null;

        $scope.message = 'Sorry, the system is under maintenance. Please try again later or contact PULSE administrator.';

         // if user got offline or server got timed out
         if (navigator.onLine === false || error.status === 504 || error.status === 502) {
          $scope.message = 'Sorry, unable to reach PULSE — Please check your internet connection, or try again later.';
        }

        //By returning false here we are stopping the chain
        return false;

      });

    };

    /**
     * Checking if there's active auth session, then redirect to dashboard
     */
    function checkAuthSession() {

      $scope.isCheckingSession = true;
      AuthenticationService.findMe().then(function (response){
        $log.log('Success');
        window.location.href = '/dashboard/#!/';
        $scope.isCheckingSession = false;
      }, function (error){
        $log.log('Error', error);
        $scope.isCheckingSession = false;
      });
    }

    checkAuthSession();
  }

})();
