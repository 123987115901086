'use strict';

/**
 * @ngdoc Sorting Service (factory implementation)
 * @name pulse.sort
 * @author IG 9.2.15
 * @summary A sorting service capable of sorting on multiple fields and accepting sort-configuration options, per sort-field.
 * 			Each sort field can specify the following options: 
 * 			- prop: The field name. !Important: This can be a nested property on the object. Use dot notation to specify the property path, e.g.: "metadata.personal.File_Size"
 *  		- numeric: true/false ---> is the field a numeric type. If this is true, the number (integer or float) will be parsed from value, prior to comparisson operation
 * 			- direction: desc/asc or -/+  ---> sort direction for a given field. !Imortant: This is specified per field, not for the entire array.
 * 			- preProcess: any additional pre-processor function that will be executed against the data in a given field. This may be useful for parsing dates, or comparing results of a calculation on multiple fields (e.g. x.y * x.z)
 * 			- customCompareFunc ---> A custom compare function that redefines how this field values are compared. See an example of this in SD/playlist.js.
 */

angular.module('pulse.sort', [])

.factory('sorting', ['$log', function($log) {
	
	//if a sort field is expected to be numeric, ensure that it is.
	var prepNumericData = function(item) { 				
		var retVal = 0;
		if(!isNaN(item) && isFinite(item)) {
			try {
  				retVal = parseFloat(item);
			} catch (e) {
				retVal = 0;
			}
		}
		
 		return retVal;
	}

	//the default comparer function for the sort operation
	var compare = function(itemA, itemB) {		
		if (itemA == itemB) { 
			return 0;
		}
		return (itemA < itemB ? -1 : 1);
	}


    //allow for nested properties to participate in the sort (e.g. item.metadata.sortProps.SortByMe)
    function buildContext(record, fieldPath) {
		var retVal = record;
		var namespaces = fieldPath.split(".");
        for (var i = 0; i < namespaces.length; i++) {
            retVal = retVal[namespaces[i]];
        }
        return retVal; 
    }

    //returns a function that will be called for each sort operation within a data set
    var pulseSort = function(sortFields) {
		var predicates = []; //the list of compare functions to run against
		
        for (var index = 0; index < sortFields.length; index++) { //build an array of compare functions, one for each sort field
			var field = sortFields[index];   					         
            var compareFunc = (!!field.customCompareFunc &&  typeof field.customCompareFunc === 'function' ) ? field.customCompareFunc : compare; //allow for custom compare function
			
			var compareFuncBuilder = function(compFunc) { //closure
				return function(itemA, itemB) { //pre-process the data for the comparison function, and build the compare function for this field
					var reverse = 1;
					var a = itemA;
					var b = itemB;
					
					if (typeof a === 'undefined') { //allow comparison of items with unpopulated fields
						a = '';
					}
					
					if (typeof b === 'undefined') {
						b = '';
					}
					
					if (field.numeric) {
						 a = prepNumericData(a);
						 b = prepNumericData(b);
					}
					
					if (!!field.preProcess && typeof field.preProcess === 'function' ) {
						a = field.preProcess(a);
						b = field.preProcess(b);
					}
					
					if (field.direction.toLowerCase() === 'desc' || field.direction.toLowerCase() === '-') { //reverse the order for this field comparisson
						reverse = -1;
					}
					
					return reverse * compFunc(a, b);
					
				};
			}
			
            predicates.push({
                field : field,
                compareFunc: compareFuncBuilder(compareFunc)
            });
        }


        // the pulseSort comparison function
        return function(itemA, itemB) {
            var retVal, predicate, fieldName;
            for (var index = 0; index < predicates.length; index++) {
                retVal = 0;
                predicate = predicates[index];
                fieldName = predicate.field.prop;

                retVal = predicate.compareFunc(buildContext(itemA, fieldName), buildContext(itemB, fieldName));	//Allow for nested properties to be part of the sort op.
                if (retVal !== 0) break; //once a field's value is different we can stop comparing the two items; otherwise we continue to the next field in the same 2 items
            }
            return retVal; //the final value of the comparison between all desired fields (or some fields if sort determines early which item is lower/higher)
        }
    }
	
	
	return {
		sort : function( data, sortFields ) {
			var sortedData = null;
			try {
				$log.log('Sorting data', data, 'on', sortFields);
				sortedData = data.sort(pulseSort(sortFields));				
			} 
			catch ( sortingError ) {
				$log.error( "Sorting failed, the original dataset will be returned", sortingError );
				sortedData = data;
			}
			
			return sortedData;
		}
	}
}]);



	
/* The below is meant to take advantage of angular's OrderBy, but this will not allow for switching ordering direction on multiple fields (Angular limiation)
	var makeGetterFunc = function(sortField, sortDirection, isNumeric) {
     	return function(item) { //this is the actual getter function that will be setup for each sort field			
			var numeric = isNumeric;
			var field = sortField;
			var direction = sortDirection;
			
			var retVal = item[field];									
        	if (numeric) {
				retVal = numberParser(item, field);
			}
			
			return retVal;
     	};
	};	
	//get a list of functions (one per sort field) that will be executed as 'predicates'.
	var getExpressionArray = function(sortFields) {
		var retVal = []; 
		for (var index=0; index<sortFields.length; index++) {
			var numeric = sortFields[index].numeric;
			var sortField = sortFields[index].prop;
			var sortDirection = sortFields[index].direction;
			retVal.push(makeGetterFunc(sortField, sortDirection, numeric));
		}
		
		return  retVal;
	}*/