/**
 * @ngdoc service
 * @name pulseErrorModal
 * @module pulse
 * @description
 * `pulseErrorModal` shows blanket error modals.
 *
 * @usage
 *
 */

//TODO: Fill in the docJS for this

(function() {
    'use strict';

    angular
        .module('pulse')
        .service('pulseErrorModal', Service);

    Service.$inject = ['$signalProvider', '$idle' , 'modalManager'];

    /* @ngInject */
    function Service($signalProvider, $idle, modalManager) {

        var app, errorModal;
        var service = {
            resolveError : resolveError,
            init : init
        };

        return service;

        //TODO: Error Service
        function resolveError(name, error) {

            $idle.unwatch(); // Unwatch the idle process

            //If it is an not authorized status just let the system wait.
            //They will be punted soon
            if(error.status===401) {
                return;
            }

            //Stop polling everywhere.
            $signalProvider.signal("polling", false);

            //If there is already an error message dont create another one
            if(!errorModal) {

                errorModal = modalManager.openModal({
                        templateUrl: 'views/common/modals/error.html',
                        controller: 'ErrorCtrl',
                        windowClass: 'timeout',
                        backdrop: 'static',
                        keyboard: false,
                        force: true,
                        resolve: {
                            error: function () {
                                error.app = app; // Adding this to the error message so that we can redirect properly
                                return error; // The error we pass in from the interceptor
                            }
                        }
                    }
                );

                //Remove the modal from the dom
                errorModal.result.then(function() {
                    errorModal = '';
                });

            }else{
                //TODO: update the modal with more errors if any
            }
        }

        //init
        function init(app){
            app = app;
        }

    }
})();
