/**
 * @ngdoc object
 * @name RolesUserCtrl
 * @requires $state
 */

'use strict';

angular.module('pulse')
    .controller('RolesUserV2Ctrl',
    ['$scope', '$signalProvider', '$stateParams', '$log', '$rootScope', 'Roles', 'userEdit', 'userRoles', 'roles',
        function ($scope, $signalProvider, $stateParams, $log, $rootScope, Roles, userEdit, userRoles, roles) {

            $log.info('Roles V2 Users Controller');

            $scope.roles = {};
            $scope.roles.list = roles;
            $scope.roles.user = userEdit;
            $scope.roles.data = [];

            $rootScope.debounce = false;

            var length = userRoles.length;
            for(var i=0;i<length;i++) {
              $scope.roles.data[userRoles[i].name] = true;
            }

            //Saves the Role information only (i.e. Role Name)
            $scope.roles.update = function(role) {
                $log.log('Save User Role');
                $rootScope.debounce = true;

                if(!$scope.roles.data[role.name]) {

                  var save = Roles.ProjectUsersRoles.unassignRoleFromUser({projectID: $stateParams.projectID, userID: $scope.roles.user.id, roleID: role.id }).$promise;
                  save.then(function(response) {
                      $log.log('Updated User Roles');

                      $signalProvider.signal('feedback', [ 'success', 'User' ]);
                      $signalProvider.signal('users_added', response);

                  }, function(error) {
                      $log.log('User Role Not Saved:');
                      $log.log(error);
                      $signalProvider.signal('feedback', [ 'failure', 'AddRoleToUser' ]);
                  });

                }else{
                  var rolesArray = [];
                  rolesArray.push(role.id);

                  var save = Roles.ProjectUsersRoles.assignRoleToUser({projectID: $stateParams.projectID, userID: $scope.roles.user.id}, {roleIDs:rolesArray}).$promise;
                  save.then(function(response) {
                      $log.log('Updated User Roles');

                      $signalProvider.signal('feedback', [ 'success', 'User' ]);
                      $signalProvider.signal('users_added', response);

                  }, function(error) {
                      $log.log('User Role Not Saved:');
                      $log.log(error);
                      $signalProvider.signal('feedback', [ 'failure', 'AddRoleToUser' ]);
                  });
                }

            };

            //Destroy the function on search
            $scope.$on('$destroy', function(){
                $log.info('Destroy Roles Users Controller');
            });

        }]);
