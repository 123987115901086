angular.module('pulse')
  .directive('feedback',
  [ function() {
    'use strict';
    return {
      restrict: 'E',
      template: '<div ng-show="feedback.message" ng-class="feedback.flag" ng-click="feedClick();">' +
        '<span>{{feedback.message}}</span>' +
        '</div>',
      controller:
        ['$scope', '$timeout', 'feedback', '$log', '$signalProvider',
          function($scope, $timeout, feedback, $log, $signalProvider) {

            $scope.feedback = {};
            $scope.feedback.flag = '';
            $scope.feedback.message = '';

            function showFeedback(name, note) {
              //Create error handling here
              if(!note || note.length < 2) {
                $log.log('ERROR: bad notification array.');
                return;
              }
              feedback.getNotification(note);
              $scope.feedback.set( note[0], note[note.length-1], 4500);
            }

            $scope.feedbackSession = $signalProvider.listen('feedback', showFeedback);

            $scope.$on('$destroy', function(){
              $signalProvider.unlisten('feedback', $scope.feedbackSession);
            });

            $scope.feedback.set = function(flag,message,time) {
              $scope.feedback.flag = flag;
              $scope.feedback.message = message;
              $timeout(function () {
                $scope.feedback.flag = '';
                $scope.feedback.message = '';
              }, time);
            };

            $scope.feedClick = function() {
              if($scope.feedback.flag !== null || $scope.feedback.flag !== '')
              {
                $scope.feedback.flag = 'quickDissolve';
                $scope.feedback.message = '';
              }
            };
          }]
    };
  }]);



