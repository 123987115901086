'use strict';

angular.module('pulse')

.directive('pulseHorizontalScroll', ['$log', '$signalProvider', scrollDirective])
.directive('pulseHorizontalItem', ['$log', '$signalProvider', itemDirective])

/**
 * @ngdoc directive
 * @name pulseHorizontalScroll
 * @restrict A
 * @description
 * `pulse-horizontal-scroll` provides a horizontal scroll.
 *
 * Repeater is in the template and calls pulseHorizontalItem directive per item
 *
 * @usage
 * <hljs lang="html">
 *
 * <div pulse-horizontal-scroll array="clips"></div>
 *
 * </hljs>
 *
 * @param {array=} array Pass the array of the items you wish to loop over.
 **/

function scrollDirective($log, $signalProvider) {
      return {
          restrict: 'A',
          templateUrl: 'views/common/horizontal_scroll/scroll.html',
          scope: {
              array: '=', // the collection of objects to be viewed
              current: '='
          },
          link: function (scope, element, attrs) {

              element.attr('id', "horizontal_scroll_" + attrs['array']);
              $log.log('Scroll Scope');
              $log.log(scope.current);

              element.bind("DOMMouseScroll mousewheel onmousewheel", function(event) {

                var e = window.event || e; // old IE support
                var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
                $log.log(delta);
                var scroll = element.children();
                // scroll.animate({scrollLeft: scroll[0].scrollLeft + event.wheelDelta},'fast');
                scroll[0].scrollLeft = scroll[0].scrollLeft + -delta*30;

            });

      }
  }
}

/**
 * @ngdoc directive
 * @name pulseHorizontalItem
 * @restrict A
 * @description
 * `pulse-horizontal-item` provides an item within pulseHorizontalScroll.
 *
 * Uses within pulseHorizontalScroll to capture correct click events for item.
 * Inherts scope from parent.
 *
 * @usage
 * <hljs lang="html">
 *
 * <div pulse-horizontal-item ng-repeat="item in array">
 *
 * </hljs>
 *
 **/

function itemDirective($log, $signalProvider) {
      return {
          restrict: 'A',
          scope: false,
          link: function (scope, element, attrs) {

            element.on('click',function() {

              element.parent()[0].scrollLeft = element[0].offsetLeft;
              scope.item.index = scope.$index;
              $signalProvider.signal('horizontal_scroll:select_item', scope.item);

            });

          }

      }
  }
