/**
 * @ngdoc object
 * @name PulseSearchResultsComponent
 * @requires $timeout, $http
 * @description A pulse component for FilemanagerV2 Search results by search request types (all_files, search_files, infinite_files)
 * @param {object} tableState - // this will track state they could be (empty, list, init, error)
 * @param {array} tableHeaders - // list of table headers for table
 * @param {string} searchInput - // ng-model from search bar input
 * @param {function} searchAll - // callback to search for all files and select them
 * @param {function} searchInfinite - // callback to search for next files by skip and limit
 * @param {boolean} searchInfiniteDebounce - // this tracks when a calback function is running. this will stop the request.
 * @param {string} isSearchType - // (all_files, search_files, infinite_files)
 * @param {array}  searchResults - // return data from search request (all_files, search_files, infinite_files)
 * @param {init}  searchResultsCount - // countOnly query param is TRUE.  this tells us what the count is for a search query
 * @param {array} jobList - // files added or removed from Job List.
 * @param {array} jobListIds - // file IDs in Job List.  So we can check whats in the job lists
 * @param {deleteFiles} deleteFiles - // function to delete selected files.
 */

(function(){
  'use strict';

  angular.module('pulse').component('pulseSearchResults', {
    templateUrl : 'views/common/components/pulse_search_results.html',
    bindings : {
      tableState: '<',
      tableHeaders: '<',
      searchInput: '<',
      searchAll: '<',
      searchInfinite: '<',
      searchInfiniteDebounce: '<',
      isSearchType: '<',
      searchResults: '<',
      searchResultsCount: '<',
      jobList: '<',
      jobListIds: '<',
      deleteFiles: '<',
      totalItems: '=',
      totalSize: '=',
      totalSelectedItems: '=',
      totalSelectedSize: '=',
      isItemsInJob: '=',
      isSelectAll: '=',
      fileSortKeys: '=',
      sortOrder: '=',
      excludedItems: '=',
      sortFiles: '=',
      isExistingJobList: '=',
    },
    controller : Controller
  });

  Controller.$inject = ['$log', '$timeout', '$http', '$signalProvider', '$rootScope'];

  function Controller($log, $timeout, $http, $signalProvider, $rootScope){
    var ctrl = this;

    ctrl.vm = {
        tableScroll: tableScroll,
        table_height: 0,
        table_init_load: 50,
        show_theader_dropdown: false,
        bucket: {'is_selected': false, 'in_job_list': false, 'file': {}},
        table_rows: [],
        select_all_load: false,
        select_all: false,
        selected_total: 0,
        selected_ids: [],
        selectRow: selectRow,
        selectAll: selectAll,
        addToJob: addToJob,
        show_del_modal: false,
        confirmDeleteFiles: confirmDeleteFiles,
        is_infinite_scroll: false,
        file_action_idx: {},
        is_release: ($rootScope.storage.project.permissions.indexOf('filemanager:release') >= 0) ? true : false,
        is_transfer: ($rootScope.storage.project.permissions.indexOf('filemanager:download') >= 0) ? true : false,
        is_delete: ($rootScope.storage.project.permissions.indexOf('filemanager:delete') >= 0) ? true : false,
        tempSelected:[]
    };

    ctrl.$onInit = function(){
      copyList(false);
    }

    ctrl.$onChanges = function(changeObj){
      // CopyList when a search request is complete.
      copyList(true);
    }

    function tableScroll(){
      if(ctrl.vm.table_rows.length !== ctrl.searchResultsCount.count){
        $log.log('yes infinite scroll');
        ctrl.searchInfinite();
      }
    }

    function confirmDeleteFiles(){
      ctrl.vm.is_deleting = true;
      ctrl.tableState.state = 'init';
      $timeout(function(){
        ctrl.vm.is_deleting = false;
        ctrl.vm.show_del_modal = false;
      }, 2000);
      ctrl.deleteFiles(ctrl.vm.selected_ids);
    }

    function addToJob(){
      var clear_selection = false;

      angular.forEach(ctrl.vm.table_rows, function(row, key){
        // check if the file is in the job list.
        if (row.is_selected && (!row.in_job_list)){
          clear_selection = true;
          row.is_selected = false;
          row.in_job_list = true;
          ctrl.jobList.push(row.file);
          ctrl.jobListIds.push(row.file.assetID);
        }
      });


      if (ctrl.isSelectAll == true && ctrl.isItemsInJob == false) {
        $log.log('First time add items in the job');

        if (ctrl.jobList.length > 0 && ctrl.isExistingJobList == true) {

          ctrl.totalItems += ctrl.totalSelectedItems;
          ctrl.totalSize += ctrl.totalSelectedSize;

          ctrl.totalSelectedItems = 0;
          ctrl.totalSelectedSize = 0;

          ctrl.isItemsInJob = true;
          ctrl.isExistingJobList = false;
        } else {

          ctrl.totalItems = ctrl.totalSelectedItems;
          ctrl.totalSize = ctrl.totalSelectedSize;

          // after add data in job list reset selected total size and items
          ctrl.totalSelectedItems = 0;
          ctrl.totalSelectedSize = 0;

          ctrl.isItemsInJob = true;
        }


        setTimeout(function () {
          $rootScope.$broadcast('excludedItems:update', ctrl.excludedItems);
        }, 300);
      }

      if (ctrl.isSelectAll == true && ctrl.isItemsInJob == true) {

        $log.log('Update size here');

        ctrl.totalItems += ctrl.totalSelectedItems;
        ctrl.totalSize += ctrl.totalSelectedSize;

        ctrl.totalSelectedItems = 0;
        ctrl.totalSelectedSize = 0;

        setTimeout(function () {
          $rootScope.$broadcast('excludedItems:update', ctrl.excludedItems);
        }, 300);
      }

      /// clear selections if atleast one file was added to job list
      /// clearing the selection will have them unchecked if the
      /// file is removed from the Job List
      if (clear_selection){
        clearSelections();
      }
    }

    function selectAll(select_all){
      if (ctrl.tableState.state !== 'list'){
        return;
      }else{
        var is_selection = (select_all) ? true : !ctrl.vm.select_all;
        ctrl.vm.select_all = is_selection;

        if (is_selection == false) {

          ctrl.vm.tempSelected.forEach(function(item) {
            updateTotalItemsAndSize('remove', item);
          });

          ctrl.vm.tempSelected = [];
        }

        if (ctrl.isItemsInJob && ctrl.excludedItems.length == 0) {
          $log.log('All the items are in the job list');
          return;
        }

        ctrl.searchAll(is_selection);
      }
    }

    function selectRow(row, idx, $event, sorted_list){
      var asset_id_idx = ctrl.vm.selected_ids.indexOf(row.file.assetID)
      row.is_selected = !row.is_selected;
      if (row.is_selected){
        /// increase selected total
        ctrl.vm.selected_total += 1;
        if (asset_id_idx <= -1){
          // add to list
          ctrl.vm.selected_ids.push(row.file.assetID);
          updateTotalItemsAndSize('add', row);
        }
      }else{
        // decrease selected total
        ctrl.vm.selected_total -= 1;
        if (asset_id_idx >= 0){
          // remove from list
          ctrl.vm.selected_ids.splice(asset_id_idx, 1);
          updateTotalItemsAndSize('remove', row);
        }
      }
      ctrl.vm.select_all = (ctrl.vm.selected_total === ctrl.vm.table_rows.length) ? true : false;
      try {
        if ($event && $event.shiftKey) {
          if (ctrl.vm.lastChecked === undefined || typeof ctrl.vm.lastChecked !== 'number') {
            ctrl.vm.lastChecked = idx;
          } else {
            var start = idx;
            var end = ctrl.vm.lastChecked;
            var min = Math.min(start, end);
            var max = Math.max(start, end) + 1;
            var toggle = row.is_selected;
            for (var i = min; i < max; i++) {
              if (sorted_list[i].is_selected !== toggle) {
                selectRow(sorted_list[i], i);
              } else {
                ctrl.vm.select_all = (ctrl.vm.selected_total === ctrl.vm.table_rows.length) ? true : false;
              }
            }
          }
        }
        ctrl.vm.lastChecked = idx;
      } catch (e) {
          $log.log('catch shiftkey error', e);
        }


    }

    function clearSelections(){
        ctrl.vm.select_all = false;
        ctrl.vm.selected_ids = [];
        ctrl.vm.selected_total = 0;
    }

    function jobListCheck(file, assetID){
      // check if the file is in the job list
      if (ctrl.jobListIds.indexOf(assetID) >= 0){
         file.in_job_list = true;
      }

      // if user has select all the items in the job list
      if (ctrl.isSelectAll == true && ctrl.isItemsInJob && ctrl.excludedItems.indexOf(assetID) == -1) {
        file.in_job_list = true;
      }

      if (ctrl.vm.select_all == true && ctrl.searchInput.length > 0 && ctrl.isSelectAll == true && ctrl.isItemsInJob) {

        var len = ctrl.vm.tempSelected.length;

        for (var i = 0; i < len; i++) {
          if (ctrl.vm.tempSelected[i].file.assetID == file.file.assetID) {
            file.in_job_list = false;
            break;
          }
        }
      }
    }

    function updateAllSelection(row){

      if (row.in_job_list === false){
        row.is_selected = ctrl.vm.select_all;
        var asset_id_idx = ctrl.vm.selected_ids.indexOf(row.file.assetID);
        if (row.is_selected && (asset_id_idx <= -1)){
          /// is true - add to selected_ids
          ctrl.vm.selected_ids.push(row.file.assetID);
          ctrl.vm.selected_total += 1;

          if (ctrl.searchInput.length > 0 && ctrl.isSelectAll == true && ctrl.isItemsInJob == true) {
            updateTotalItemsAndSize('add', row);

            // check row is already exists in the tempSelected
            var isExists = false;
            var len = ctrl.vm.tempSelected.length;

            for (var i = 0; i < len; i++) {
              if (ctrl.vm.tempSelected[i].file.assetID == row.file.assetID) {
                isExists = true;
                break;
              }
            }

            if (isExists === false) {
              ctrl.vm.tempSelected.push(row);
            }

          }

        }else if (!row.is_selected && (asset_id_idx >= 0)){
          // if false remove from selected_ids
          ctrl.vm.selected_total -= 1;
          ctrl.vm.selected_ids.splice(asset_id_idx, 1);

          if (ctrl.searchInput.length > 0 && ctrl.isSelectAll == true && ctrl.isItemsInJob == true) {
            updateTotalItemsAndSize('remove', row);
          }
        }
      }
    }

    function selectedRowCheck(row, assetID){

      if (ctrl.vm.select_all){
        updateAllSelection(row);
      }else{
        if (ctrl.vm.selected_ids.indexOf(assetID) >= 0){
          row.is_selected = true;
        }else{
          row.is_selected = false;
        }
      }
    }

    function copyList(on_change){

      if (on_change === false) {
        ctrl.vm.selected_total = 0;
        ctrl.vm.selected_ids = [];
      }

      // create a fresh copy of the search results
      ctrl.vm.file_action_idx = {};
      if (ctrl.isSearchType === 'search_files'){
        clearSelections();
      }else if (ctrl.isSearchType === 'all_files' && ctrl.vm.select_all === false){
        clearSelections();
      }
      ctrl.vm.table_rows = angular.copy(ctrl.searchResults);
      var row;
      var assetID;

      var length = ctrl.vm.table_rows.length;

      for (var i = 0; i < length; i++) {
        ctrl.vm.table_rows[i] = angular.copy(ctrl.vm.bucket)
        ctrl.vm.table_rows[i].file = ctrl.searchResults[i];
        row = ctrl.vm.table_rows[i];
        assetID = ctrl.vm.table_rows[i].file.assetID;
        jobListCheck(row, assetID);
        selectedRowCheck(row, assetID)
      }

    }


    function updateTotalItemsAndSize(action, row) {

      var assetID = row.file.assetID;

      if (ctrl.totalItems <= 0 || ctrl.isSelectAll == false) {
        return false;
      }

      switch (action) {
        case 'add':

          if (ctrl.excludedItems.length > 0) {

            var index = ctrl.excludedItems.indexOf(assetID);

            if (index != -1) {
              ctrl.totalSelectedItems += 1;
              ctrl.excludedItems.splice(index, 1);
              ctrl.totalSelectedSize += row.file.metadata.File_Size;
            }


            setTimeout(function () {
              $rootScope.$broadcast('excludedItems:update', ctrl.excludedItems);
            }, 300);
          }

          break;

        case 'remove':
          ctrl.totalSelectedItems -= 1;
          ctrl.excludedItems.push(assetID);
          ctrl.totalSelectedSize -= row.file.metadata.File_Size;
          setTimeout(function () {
            $rootScope.$broadcast('excludedItems:update', ctrl.excludedItems);
          }, 300);
          break;

        default:
          break;
      }

    }

  }
})();
