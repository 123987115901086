/**
 * @ngdoc object
 * @name VFX
 * @requires $resource
 */

'use strict';

angular.module('pulse')
    .factory('VFX',
        ['$resource', '$rootScope',
            function ($resource, $rootScope) {

                var application_name;
                // TODO: remove this once vfx and preconfom are done
                if($rootScope.application_name === 'vfxpulls') {
                    application_name = 'vfx';
                } else {
                    application_name = $rootScope.application_name;
                }

                return $resource(
                    //TODO: we have $rootScopes in resource paths, probably should remove them when we can
                    config.baseURL + '/'+application_name+'/:projectID/:ext/:assetID/:action',
                    {projectID: '@projectID', ext: '@ext', assetID: '@assetID'},
                    {
                        getVendors: {url: config.baseURL + '/vfx/:projectID/:ext/:assetID/:action', method: 'GET', params: {ext:'vendors'}, isArray:true},
                        saveVendor: {url: config.baseURL + '/vfx/:projectID/:ext/:assetID/:action', method: 'POST', params: {ext:'vendors'}},
                        deleteVendor: {url: config.baseURL + '/vfx/:projectID/:ext/:assetID/:action', method: 'DELETE', params: {ext:'vendors'}},

                        getProfiles: {method: 'GET', params: {ext:'transkoderconfigs'}, isArray:true},
                        getProfile: {method: 'GET', params: {ext:'transkoderconfigs'}},
                        saveProfile: {method: 'POST', params: {ext:'transkoderconfigs'}},
                        deleteProfile: {method: 'DELETE', params: {ext:'transkoderconfigs'}},
                        getProfileOptions: {method: 'GET', params: {ext:'transkoderconfigs/options'}, isArray:true},
                        getFormOptions: {method: 'GET', params: {ext:'transkoderconfigs/formoptions'}},

                        savePullRequest: {method: 'POST', params: {ext:'pulls'}}, //TODO: is this being used?

                        getPull: {method: 'GET', params: {ext:'pulls'}},
                        sendPull: {method: 'POST', params: {ext:'pulls', action: 'send'}},
                        updatePull: {method: 'POST', params: {ext:'pulls'}},
                        clonePull: {method: 'POST', params: {ext:'pulls', action: 'duplicate'}},
                        deletePull: {method: 'DELETE', params: {ext:'pulls'}},
                        finishPullRequest: {method: 'GET', params: {ext:'pulls', action: 'finish'}},
                        getPullRequests: {method: 'GET', params: {ext:'pulls'}, isArray:true},
                        getPullRequestsCount: {method:'GET', params: {ext:'pulls', 'countOnly': true}, isArray: false},

                        getSetup:{method: 'GET', params: {ext:'vfxsetup'}},
                        saveSetup:{method: 'POST', params: {ext:'vfxsetup'}},
                        updateSetup:{method: 'POST', params: {ext:'vfxsetup/update'}},
                        getResolutions:{method: 'GET', params: {ext:'vfxsetup/resolutions'}, isArray:true},
                        getFramerates: {method: 'GET', params: {ext:'vfxsetup/framerates'}, isArray:false},

                        saveMappings: {method: 'POST', params: {ext:'mappings'}},
                        getMappings: {method: 'GET', params: {ext:'mappings'}, isArray:true},

                        saveColumnMatch:{method: 'POST', params: {ext:'vfxsetup', assetID: 'columnMatch'}}, //is not used?
                        parseColumns:{method: 'POST', params: {ext:'vfxsetup', assetID: 'parseColumns'}}, //is not used?

                        //for uploading settings and startup to vfxsetup
                        saveUpload:{method: 'POST', params: {ext:'vfxsetup'}}, //is not used?

                        getImportTypes: {url: config.baseURL + '/vfx/:projectID/:ext/:assetID/:action', method: 'GET', params: {ext:'importTypes'}},

                        /*getCameraTypes: {method: 'GET', params: {ext:'cameraTypes'}, isArray:false},*/
                        getLensTypes: {url: config.baseURL + '/vfx/:projectID/:ext/:assetID/:action', method: 'GET', params: {ext:'lensTypes'}, isArray:false},

                    }
                );
            }
        ]
    );
