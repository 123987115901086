/**
 * @ngdoc service
 * @name PulseIdleState
 * @module pulse
 * @description
 * `PulseIdleState` provides idle status for the application.
 *
 * @usage
 * We mainly use this for popping up a modal when the application hasnt been used int a while.
 * This warns the user that the application is going to sign out in a certain amount of time.
 * Once the time has expire the user will be logged out on the front end ( currently the backend times out on its own)
 * The user can then click out and redirect back to the landing page.
 */

//TODO: remove rootScope once we create pulse Storage

(function() {
  'use strict';

  angular
    .module('pulse')
    .service('pulseIdleState', Service);

  Service.$inject = ['$rootScope', '$log', '$signalProvider', 'AuthenticationService', 'modalManager'];

  /* @ngInject */
  function Service($rootScope, $log, $signalProvider, AuthenticationService, modalManager) {

    var warning;
    var service = {
      idleStart : idleStart,
      idleEnd : idleEnd,
      idleWarn : idleWarn,
      idleTimeout : idleTimeout,
      keepalive : keepalive,
      keepaliveResponse : keepaliveResponse
    };

    return service;

    /**
     * @ngdoc function
     * @name idleStart
     * @description the user appears to have gone idle
     */
    function idleStart(name, data) {
      $log.info('$idleStart ' + new Date().getTime());
    }

    /**
     * @ngdoc function
     * @name idleStart
     * @description  follows after the $idleStart event, but includes a countdown until the user is considered timed out
      the countdown arg is the number of seconds remaining until then.
      you can change the title or display a warning dialog from here.
     */
    function idleWarn(name, data) {

      $log.info('$idleWarn ' + new Date().getTime());

      // Tell other modals that the warning is happening.
      $signalProvider.signal('idleWarn', true);

      if(!warning) {
        warning = modalManager.openModal({
            templateUrl: 'views/common/modals/timeout.html',
            controller: 'TimeoutCtrl',
            windowClass: 'timeout',
            resolve: {},
            force: true
          }
        )
      }
    }

    /**
     * @ngdoc function
     * @name idleStart
     * @description  the user has timed out (meaning idleDuration + warningDuration has passed without any activity)
      this is where you'd log them
     */
    function idleTimeout(name, data) {

      $log.info('$idleTimeout ' + new Date().getTime());
      $log.info('Logging out from ' + name);
      $rootScope.dirty = false; // At this point they forgot to save their work and we have to log them out.
      $rootScope.timeout = true; // turns the logout redirect off

      if (sessionStorage.getItem('session-expire-type') == 'logout') {
        return;
      }
      
      sessionStorage.setItem('session-expire-type', 'expire');

      //We want to logout out here but we dont want to redirect yet
      //TODO: in the future we will just serve up the login form here
      AuthenticationService.logout();
    }

    /**
     * @ngdoc function
     * @name idleStart
     * @description   the user has come back from AFK and is doing stuff.
     * if you are warning them, you can use this to hide the dialog
     */
    function idleEnd(name, data) {
      $log.info('$idleEnd ' + new Date().getTime());

      //Start polling again
      //$signalProvider.signal("polling", true);

      // We have to actually close the modal
      $signalProvider.signal('modalClose', true);

      //On modal close
      warning = false;

    }

    /**
     * @ngdoc function
     * @name idleStart
     * @description do something to keep the user's session alive
     */
    function keepalive(name, data) {

      $log.info('$keepalive ' + new Date().getTime());
      if(data) {

        var now = new Date().getTime();
        var currentTime = now - (10 * 60) *  1000;  // at some point we need to be able to set (10 * 60) in the idle provider
        $log.info('data ' + data + ' > ' + 'currenttime ' + currentTime);

        //Should we keep the session alive?
        if(data>currentTime) {
          $log.log('Hello - still Alive');
        }

      }
    }

    /**
     * @ngdoc function
     * @name idleStart
     * @description do something to keep the user's session alive like typing or moving the mouse or http requests
     */
    function keepaliveResponse(name, data) {
      $log.info('keepaliveResponse ' + new Date().getTime());

      if(!data) {
        $log.error('Keepalive has failed');
      }

    }

  }
})();
