'use strict';

angular.module('pulse')
.filter('breakLongTextProjectListPage', function () {
    return function (string) {

        var new_str;
        if (string.indexOf(' ') >=0 ) {
          new_str = string;
        }else{
          var string_array = string.split('');
          var line_ct = 0;
          var b_point = 23;
          for(var s=0; s < string_array.length; s++){
            if (line_ct === b_point){
              line_ct = 0;
              b_point = 21;
              string_array[s] = ' '+string_array[s]
            }
            line_ct++;
        }

          new_str = string_array.join('');

        }

        if(new_str.length >= 50){
          new_str = new_str.substring(0, 47) + '...';
        }

        return new_str;

    };
});
