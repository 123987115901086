/**
 * @ngdoc object
 * @name AddUserCtrl
 * @requires $state, $signalProvider, $stateParams
 */

'use strict';

angular.module('pulse')
    .controller('AddUserV2Ctrl',
    ['$scope', '$signalProvider', '$stateParams', '$log', '$rootScope', 'modalManager', 'Projects', 'Users', 'user', 'projectUsers', 'organizations',
        function ($scope, $signalProvider, $stateParams, $log, $rootScope, modalManager, Projects, Users, user, projectUsers, organizations) {

            $log.info('Add User V2 Controller');

            $rootScope.debounce = false;

            $scope.add = {};

            $scope.add.organizations = organizations;

            $scope.add.users = [];
            $scope.add.usersCached = [];
            $scope.add.create = [];
            $scope.add.projectUsers = projectUsers;
            $scope.add.disallow = false;
            $scope.add.create.disallow = false;
            $scope.add.reset = false;
            $scope.add.organization = '';
            $scope.add.user = '';

            function getListIndex(list, item) {
              for (var i = 0; i < list.length; i++) {
                if (list[i].id === item.id) {
                  return i;
                }
              }
            }

            function checkExisting(filteredArray) {
              var length = $scope.add.projectUsers.length;
              var array = [];

              if (!filteredArray.length) {
                return [];
              }

              if (!length) {
                return filteredArray;
              }

              for (var i = 0; i < length; i++) {
                var existing = $scope.add.projectUsers[i];
                for (var x = 0; x < filteredArray.length; x++) {
                  if (existing.id === filteredArray[x].id) {
                    filteredArray.splice(x, 1);
                    break;
                  }
                }
              }

              return filteredArray;
            }

            $scope.$watch('add.organization', function(newValue, oldValue) {
                if(newValue && newValue !== oldValue) {

                  $log.log('Changed Organization, get new users');
                  $rootScope.debounce = true;
                  $scope.add.users = [];
                  $scope.add.usersCached = [];

                  if(!newValue.managed || user.organization.id === newValue.id) {

                    $scope.add.disallow = false;

                    var promise = Users.OrganizationUser.getUsers({organizationID:newValue.id}).$promise;
                    promise.then(function(reponse) {
                      var checked = checkExisting(reponse);
                      $scope.add.users = checked;
                      $scope.add.usersCached = angular.copy(checked);
                      $rootScope.debounce = false;
                    });

                  }else{
                    $scope.add.disallow = true;
                  }

                }
            }, true);

            $scope.$watch('add.create.organization', function(newValue, oldValue) {
                if(newValue && newValue !== oldValue) {

                  $log.log('Changed Organization, check for managed');

                  if(newValue.managed) {
                    $scope.add.create.disallow = true;
                  }else{
                    $scope.add.create.disallow = false;
                  }

                }
            }, true);

            $scope.add.createUser = function() {
                $log.log('Create User');

                $rootScope.debounce = true;

                var form = {
                  emailAddress: $scope.add.create.email,
                  name: $scope.add.create.name
                };

                var save = Users.OrganizationUser.saveUser({organizationID: $scope.add.create.organization.id}, form).$promise;
                save.then(function(response) {
                    $log.log('Saved User');
                    $signalProvider.signal('feedback', [ 'success', 'User' ]);
                    $rootScope.debounce = false;

                    $scope.add.create.email = '';
                    $scope.add.create.name = '';
                    $scope.add.create.organization = '';

                    $scope.add.user = response;

                    $scope.add.addUser();

                }, function(error) {
                    $log.log('User Not Saved:');
                    $log.log(error);
                    $signalProvider.signal('feedback', [ 'failure', 'User' ]);
                    $rootScope.debounce = false;
                });

            };

            $scope.add.addUser = function() {
                $log.log('Add User');

                $rootScope.debounce = true;

                var save = Projects.DivisionProject.saveUserProject({
                  id: $stateParams.organizationID,
                  divisionId: $stateParams.divisionID,
                  projectId: $stateParams.projectID
                }, {userID: $scope.add.user.id, projectID:$stateParams.projectID }).$promise;

                save.then(function(response) {
                    $log.log('Added Project User');

                    $signalProvider.signal('feedback', [ 'success', 'Project']);
                    $signalProvider.signal('users_added', response);

                    $rootScope.debounce = false;
                    $scope.add.reset = true;

                    var index1 = getListIndex($scope.add.users, response);
                    if(index1 !== undefined){
                        $scope.add.users.splice(index1, 1);
                    }

                    var index2 = getListIndex($scope.add.usersCached, response);
                    if(index2 !== undefined){
                        $scope.add.usersCached.splice(index2, 1);
                    }

                }, function(error) {
                    $log.log('User Not Saved To Project');

                    $signalProvider.signal('feedback', [ 'failure', 'AddProjectUser']);
                    $rootScope.debounce = false;

                });
            };

            //This is for filtering the rights combobox
            $scope.add.updateUsers = function(search) {
              if (search) {
                var searchData = [];
                angular.forEach($scope.add.usersCached, function(value) {
                  var typed = search.toLowerCase();
                  var name = value.name.toLowerCase();
                  var index = name.indexOf(typed);
                  if (index !== -1) {
                    searchData.push(value);
                  }
                });
                $scope.add.users = searchData;
              } else {
                $scope.add.users = $scope.add.usersCached;

              }
            };

            //Destroy the function on search
            $scope.$on('$destroy', function(){
                $log.info('Destroy User Controller');
            });

        }]);
