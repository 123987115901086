/**
 * @ngdoc directive
 * @name pulseContent
 * @module pulse
 * @restrict E
 * @description
 * `pulseContent` this creates a content area for any layout.
 *
 * @usage
<pulse-content
    template="views/templates/settings/profiles/edit.html"
    init = "{
      'service' :'Setup',
      'method' :'getProfileEdit',
      'context_key' : 'profile',
      'context_val' : {}
    }">
</pulse-content>
 *
 *
 * TODO: This directive also needs a lot of commenting.
 * TODO: This still needs a lot of commenting
 *
 */

(function() {
    'use strict';

    angular
        .module('pulse')
        .directive('pulseContent', directive);

    /* @ngInject */

    directive.$inject = ['$log'];

    function directive($log) {
        var directive = {
            restrict: 'EA',
            templateUrl: function(el, attr) {
                if(attr.template !== undefined){
                    return attr.template;
                }else{
                    return '<h1>error loading template</h1>';
                }
            },
            scope: {
                init : '=',
            },
            link: linkFunc,
            controller: Controller
        };

        return directive;

        function linkFunc(scope, el, attr) {}
    }

    Controller.$inject = ['$scope', '$log','ServiceManager'];

    /* @ngInject */
    function Controller($scope, $log, ServiceManager) {
        $log.log('pulseContent Dir Controller ');

        $scope.vm = {
            service : ServiceManager
        };

        if ($scope.init) {
            init();
        }

        function init() {
            if(!$scope.init.disable_setContext) {
                //setContext
                $scope.vm.service[$scope.init.service]['setContext']($scope.init.context_key, $scope.init.context_val);
            }

            if($scope.init.method) {
              //call method
              $scope.vm.service[$scope.init.service][$scope.init.method]($scope.init.context_key);
            }

        }
    }
})();
