'use strict';

angular.module('pulse')

/*
    A simple notification directive with the following features:
    - Transcludes content provided, adding a 'close' button at top right
    - Closing mode can be set using the 'closingMode' attribute. Closing options are:
    ----  closeButton
    ----  clickAnywhere
    ----  timer (not yet implemented)
    - Accepts a 'containerClass' attribute to define a look and feel for the notification
    - 'show' two-way bound boolean attribute should be used to show or hide the notification. Remember to have a wrapper object around this property on the consuming controller.
*/
.directive('notification', ['$parse','$window', '$interval', '$log', '$signalProvider',
        function ($parse, $window, $interval, $log, $signalProvider) {
            return {
                restrict: 'A',
                replace: true,
                transclude: true,
                scope:{
                    'closingMode': '@',
                    'containerClass': '@',
                    'show': '='
                },
                
                link: function(scope, el, attrs, ctrl, transclude) {
                   el.find('.content').append(transclude());
                },
                
                controller: ['$scope', '$element', function($scope, $element) {
                    $scope.onClick = function(where) {
                        switch (where) {
                            case 0: //click anywhere
                                if ($scope.closingMode === 'clickAnywhere') {
                                    $scope.show = false;
                                }
                                break;
                            case 1: //click on 'close' icon
                                if ($scope.closingMode === 'closeButton') {
                                    $scope.show = false;
                                }
                                break;
                        }
                    }
                }],
                    
                template: '<div class="{{containerClass}}" ng-show="show" ng-click="onClick(0)" >' +
                            '<div class="notificatonHeader">' +
                                '<span ng-click="onClick(1)" class="icon-x" style="font-size:22px; text-align:right; float:right; padding-left:5px; padding-right:5px; cursor:pointer;">' +
                                '</span>' +
                            '</div>' +
                            '<div class="clearfix visible-*"></div>' +
                            '<div class="content">' +
                            '</div>' +
                          '</div>'
            };
        }
    ]);
