'use strict';

angular.module('pulse')
    .controller('HelpCtrl',
    ['$scope', '$log', '$window', '$modalInstance', '$modal', '$signalProvider', '$location', '$rootScope', 'HelpSrv', 'body',
        function ($scope, $log, $window, $modalInstance, $modal, $signalProvider, $location, $rootScope, HelpSrv, body) {

            $log.log('Help Controller');
            $scope.help = {};
            $scope.help.data = {};
            $scope.help.data.message = body;
            $scope.emailPattern = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/
            if ($rootScope.helpUserDetails) {
                $scope.help.data.userName = '';
                $scope.help.data.emailAddress = '';
            }

            $scope.help.submit = function() {

                $rootScope.debounce = true;

                var helpURL = $location.$$absUrl;
                var helpAgent = 'Platform: ' + navigator.platform + '\n' +
                    'Browser: ' + navigator.appCodeName + '; ' + navigator.appName + '; ' + navigator.appVersion + '\n' +
                    'User-Agent: ' + navigator.userAgent + '\n' +
                    'Cookies Enabled: ' + navigator.cookieEnabled + '\n\n';
                var helpFEgithash = !!$window.githash ? $window.githash : "N/A";

                $modalInstance.close();
                var send_data = {
                    'message':this.data.message,
                    'url': helpURL,
                    'frontendHash':helpFEgithash,
                    'agent':helpAgent
                }
                if ($rootScope.helpUserDetails) {
                  send_data['userName'] = this.data.userName;
                  send_data['emailAddress'] = this.data.emailAddress;
                }


                var save = HelpSrv.saveMessage(send_data).$promise;

                save.then(function() {
                    $log.log('HELP EMAIL OK');
                    $signalProvider.signal('feedback', [ 'success', 'HelpEmail']);
                    $rootScope.debounce = false;
                }, function(error) {
                    $log.info('HELP EMAIL ERROR');
                    $log.info(error);
                    $signalProvider.signal('feedback', [ 'failure', 'HelpEmail']);
                    $rootScope.debounce = false;
                });
            };

            $scope.help.cancel = function() {
                $modalInstance.close();
            };
        }]);
