/**
 * @ngdoc filter
 * @name findFirstLetter
 * @module pulse
 * @description
 * `findFirstLetter` finds the first letter of a string and returns it
 *
 * @required
 * filtered item must be a string
 *
 * @usage
 * <hljs lang="html">
 *   {{string | findFirstLetter}}
 * </hljs>
 *
 * $filter('findFirstLetter')(string)
 *
 */

'use strict';
  angular
    .module('pulse')
    .filter('findFirstLetter', filter);

    filter.$inject = ['$log'];

   function filter($log) {
      return function(string) {

        if(typeof string === 'string') {
          return string.charAt(0);
        }else{
          $log.error('Find First Letter requires a string');
          return false;
        }

      };
   }
