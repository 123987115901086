/**
 * @ngdoc directive
 * @name pulseLogin
 * @module pulse
 * @restrict E
 * @description
 * `pulseLogin` provides a form for authentication.
 *
 * @usage
 * <hljs lang="html">
 *     <pulse-login></pulse-login>
 * </hljs>
 *
 */
(function(){
  'use strict';
  angular
    .module('pulse')
    .directive('pulseLogin', pulseLogin);

  function pulseLogin() {
    var directive = {
      restrict: 'E',
      replace: true,
      templateUrl: 'views/common/login/login.html',
      link: PulseLoginLink,
      controller: PulseLoginCtrl
    };
    return directive;

    function PulseLoginLink(scope, elem, attr, ctrl) {}
  }

  PulseLoginCtrl.$inject = ['$scope', '$rootScope', '$log', 'AuthenticationService'];

  /* @ngInject */
  function PulseLoginCtrl($scope, $rootScope, $log, AuthenticationService) {

    //If we have a user name lets set it
    //TODO: it might be better to pass this in somehow
    if(localStorage.length > 0 && localStorage.getItem('email_address') !== null) {
     $scope.email_address = localStorage.getItem('email_address');
    }

    /**
     * @ngdoc function
     * @name submit
     * @description Submitting the users name to Pulse backend
     */
    $scope.submit = function(form) {

      //Reset the message
      $scope.message = '';

      //We set a loading flag for the round trip
      $scope.loading = true;

      //We need to manually defer this within the service se we can display erorrs.
      var promise = AuthenticationService.login(form);
      promise.then(function(authentication) {
        $log.log('AuthenticationService.login Success', authentication);

        $scope.loading = false;

        //Display any message that comes form the backend for login
        //TODO: do we get any other messages form login other then errors?
        if(authentication.message) {
          $scope.message = authentication.message;
        }else{
          //We tell the form to display some kind of information about the
          //application redirecting to the platform
          $scope.redirecting = true;
        }

      }, function(error) {
        $log.log('AuthenticationService.login Failure', error);

        $scope.loading = false;

        //TODO: maybe we should give different feed back based on different http statuses
        $scope.message = 'Sorry, the system is under maintenance. Please try again later or contact Pulse administrator.';

        //By returning false here we are stopping the chain
        return false;

      });

    };
  }

})();
