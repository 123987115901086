/**
 * @ngdoc directive
 * @name pulseLogo
 * @module pulse
 * @restrict E
 * @description
 * `pulseLogo` provides the SVG logo.
 *
 * @usage
 * <hljs lang="html">
 *     <pulse-logo animate="true"></pulse-logo>
 * </hljs>
 *
 */
(function(){
'use strict';
angular
    .module('pulse')
    .directive('pulseLogo', pulseLogo);

    function pulseLogo() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'views/common/logo/logo.html'
        };
        return directive;
    }

})();
