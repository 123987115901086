/**
 * @ngdoc directive
 * @name pulseCombobox
 * @module pulse
 * @restrict E
 * @description
 * `pulseCombobox` this is a custom dropdown input field.
 *
 * @usage
  <pulse-combobox 
    exact="true" 
    ng-model="userGroup" 
    data="projectUserGroups" 
    reset="userGroupsReset" 
    class="combobox" 
    placeholder="Search Groups"
    on-type="updateList"
  ></pulse-combobox>
 *
 *
 * @param {array} list - List for the input to search from
 * @param {function} onType - call back to on text search funcationality
 * @param {object} ngModel - The model for the text input
 * @param {boolean} reset - resets the model and the array listing
 * @param {boolean} disabled - disables the input
 * @param {int} limit - limit the number of items shown in drop down
 * @param {string} context
 * @param {boolean} required - reguired validation
 * @param {function} onSelect - function that gets fired on select 
 * @param {boolean} exact - Has to match something in list data in order to select
 * @param {string} inputid
 * @param {object} itemData - We pass in the items data so we can have the parent controler handle the callback
 * @param {object} approval - turns on approval feature,
 *
 *
 */

(function(){

    'use strict';

    angular
        .module('pulse')
        .directive('pulseCombobox', pulseCombobox);

    pulseCombobox.$inject = ['$log', '$timeout'];

    function pulseCombobox($log, $timeout) {
        var index = -1;
        var timer;

        var directive = {
            restrict: 'E',
            require:'^ngModel',
            templateUrl: 'views/common/combobox/pulse_combobox.html',
            scope: {
                list: '=data',
                onType: '=onType',
                ngModel:'=',
                reset: '=reset',
                disabled: '=',
                limit: '=',
                context: '@',
                required: '=',
                onSelect: '=?onSelect',
                exact: '=',
                inputid: '@',
                itemData: '=',
                approval: '=' 
            },
            link: linkFunc,
            controller: Controller
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            scope.modelReset = false;
            scope.selecting = false;

            // Check if the data list is an actual array, and if not make it an empty array to not break things
            if(!Array.isArray(scope.list)) {
                scope.list = [];
            }

            if(scope.ngModel) {
                scope.searchParam = scope.ngModel.name;
                scope.searchFilter = scope.ngModel.name;
            }

            //This is for filtering the rights combobox
            function updateList(search) {
                if (search) {
                    var searchData = [];
                    angular.forEach(scope.listCache, function(value) {
                        var typed = search.toLowerCase();
                        var name = value.name.toLowerCase();
                        var index = name.indexOf(typed);
                        if (index !== -1) {
                            searchData.push(value);
                        }
                    });
                    scope.list = searchData;
                    scope.list.temp = true;
                } else {
                    scope.list = scope.listCache;
                    delete scope.list.temp;
                }
            }

            //added a watch to update the text of the select
            scope.$watch('list',function(newValue, oldValue){
                if(newValue !== undefined && newValue !== oldValue && !newValue.temp) {
                    $log.log('Update list Cache for correct searching.');
                    scope.listCache = angular.copy(scope.list)
                }
            }, true);

            //added a watch to update the text of the select
            scope.$watch('ngModel',function(newValue, oldValue){
                $log.log('Model Has Changed!! new:' + newValue + ' old:'+ oldValue);
                if(newValue !== oldValue && scope.modelReset !== true && scope.updateSearchParam && scope.ngModel !== null && scope.ngModel && scope.exact) {
                    scope.searchParam = scope.ngModel.name;
                }else if(typeof oldValue !== 'undefined' && typeof newValue === 'undefined') {
                    scope.ngModel = oldValue; // Keep the old value, we should never be reseting the value unless we manually reset
                }

                scope.modelReset = false;
            }, true);

            // starts autocompleting on typing in something
            scope.$watch('searchParam', function(newValue, oldValue){

                if(newValue !== oldValue) {

                    if(scope.searchParam) {
                        scope.searchFilter = scope.searchParam.name;
                        scope.selectedIndex = -1;
                    } else {
                        scope.searchFilter = '';
                    }

                    //Cancel the old time and start again.
                    $timeout.cancel( timer );

                    //set timeout so we hit the data base only a few times for this call
                    timer = $timeout(function() {
                        $log.log('Timeout executed');
                    }, 400);

                    //timer promise
                    timer.then(function() {
                        // function thats passed to on-type attribute gets executed
                        if(scope.onType) {
                            if(scope.status.isopen) { //added the "scope.status.isopen" condition because it doesn't need the onType event if the combobox is not open
                                scope.onType(scope.searchParam);
                            }
                        } else {
                            updateList(scope.searchParam);
                        }
                    }, function() {
                        $log.log('Timer rejected!');
                    });
                }
            }, true);

            //added a watch to update the text of the select
            scope.$watch('reset',function(newValue, oldValue){
                $log.log('Model Has Reset!! new:' + newValue + ' old:'+ oldValue);
                if(newValue !== oldValue) {
                    scope.select();
                    scope.setIndex(-1);
                    scope.reset = false;
                }
            },true);

            //scope.placeholder=attrs['placeholder'];
            scope.placeholder=attrs.placeholder;

            if(scope.placeholder===null||scope.placeholder===undefined) {
                scope.placeholder = 'Search';
            }

            var key = {left: 37, up: 38, right: 39, down: 40 , enter: 13, esc: 27, del: 46, back: 8 };

            document.addEventListener('keydown', function(e){
                var keycode = e.keyCode || e.which;

                switch (keycode){
                    case key.esc:
                        // disable suggestions on escape
                        scope.select();
                        scope.setIndex(-1);
                        scope.$apply();
                        e.preventDefault();
                }
            }, true);

            element[0].addEventListener('keydown',function (e){
                var keycode = e.keyCode || e.which;
                var l = angular.element(this).find('li').length;
                // implementation of the up and down movement in the list of suggestions
                switch (keycode){
                    case key.up:
                        index = scope.getIndex()-1;
                        if(index<-1){
                            index = l-1;
                        } else if (index >= l ){
                            index = -1;
                            scope.setIndex(index);
                            scope.preSelectOff();
                            break;
                        }
                        scope.setIndex(index);
                        if(index!==-1){
                            scope.preSelect(angular.element(this).find('li')[index].innerText);
                        }
                        scope.$apply();
                        break;
                    case key.down:
                        index = scope.getIndex()+1;
                        if(index<-1){
                            index = l-1;
                        } else if (index >= l ){
                            index = -1;
                            scope.setIndex(index);
                            scope.preSelectOff();
                            scope.$apply();
                            break;
                        }
                        scope.setIndex(index);
                        if(index!==-1){
                            scope.preSelect(angular.element(this).find('li')[index].innerText);
                        }
                        break;
                    case key.left:
                        break;
                    case key.right:
                    case key.enter:
                        index = scope.getIndex();
                        // scope.preSelectOff();
                        if(index !== -1){
                            scope.list[index].index = index;
                        }
                        scope.select(scope.list[index]);
                        scope.setIndex(-1);
                        scope.$apply();

                        break;
                    case key.esc:
                        // disable suggestions on escape
                        scope.select();
                        scope.setIndex(-1);
                        scope.$apply();
                        e.preventDefault();
                        break;
                    case key.del:
                        // disable suggestions on escape
                        scope.modelReset = true;
                        if(scope.exact) {
                            scope.ngModel = '';
                        }
                        scope.$apply();
                        break;
                    case key.back:
                        // disable suggestions on escape
                        scope.modelReset = true;
                        if(scope.exact) {
                            scope.ngModel = '';
                        }
                        scope.$apply();
                        break;
                    default:
                        return;
                }

                if(scope.getIndex()!==-1 || keycode === key.enter) {
                    //if it is enter we have a special case to remove the suggestion even if the suggestion isnt highlighted
                    if(keycode === key.enter) {
                        scope.$apply();
                    }
                    //Added the "if" to prevent unnecessarily stopping the event bubbling. Only if the model is tied to an item do we want to stop the events.
                    if(scope.ngModel !== undefined && scope.ngModel !== ''){
                        e.preventDefault();
                    }
                }
            });

            var list = element.find('.dropdown-menu');
            list.on('mouseenter', function() {
                scope.selecting = true;
            });

            list.on('mouseleave', function() {
                scope.selecting = false;
            });
        }
    }

    Controller.$inject = ['$scope', '$log', '$timeout', '$filter', '$signalProvider'];

    function Controller($scope, $log, $timeout, $filter, $signalProvider) {
        $scope.isDropUp = false;
        $scope.updateSearchParam = true;
        $scope.error = false;
        $scope.maxCount = $scope.limit ? $scope.limit : 9999;
        $scope.listCache = angular.copy($scope.list);
        $scope.approvalEnabled = false;
        $scope.template = 'views/common/combobox/buttons.html';
        $scope.tempList = false;

        //$scope.searchParam;
        $scope.searchParam = undefined;

        // with the searchFilter the suggestions get filtered
        //$scope.searchFilter;
        $scope.searchFilter = undefined;

        // the index of the suggestions that's currently selected
        $scope.selectedIndex = -1;

        //Check to see if we need approval
        if(typeof $scope.approval === "object") {

            if(!$scope.approval.enabled) {
                $log.error('Combobox approval object is incorrect, please check directive for instructions.');
                return;
            } else {
                $scope.approvalEnabled = $scope.approval.enabled;

                $scope.acceptText = 'Accept';
                $scope.cancelText = 'Cancel';

                if($scope.approval.acceptText) {
                    $scope.acceptText = $scope.approval.acceptText;
                }

                if($scope.approval.cancelText) {
                    $scope.cancelText = $scope.approval.cancelText;
                }

                if($scope.approval.template) {
                    if(typeof $scope.approval.template === 'string') {
                        $scope.template = $scope.approval.template;
                    } else {
                        $log.error('Combobox approval template is incorrect, please check directive for instructions.');
                        return;
                    }
                }
            }

        } else if(typeof $scope.approval !== "undefined"){
            $log.error('Combobox approval object is incorrect, please check directive for instructions.');
            return;
        }

        // set new index on scope
        $scope.setIndex = function(i){
            $scope.selectedIndex = parseInt(i);
        };

        // Set this directive's index and apply
        this.setIndex = function(i){
            $scope.setIndex(i);
            $scope.$apply();
        };

        $scope.getIndex = function(){
            return $scope.selectedIndex;
        };

        // watches if the parameter filter should be changed
        var watching = true;

        // for hovering over suggestions
        //this.preSelect = function(suggestion){
        this.preSelect = function() {
            watching = false;

            // this line determines if it is shown
            // in the input field before it's selected:
            //$scope.searchParam = suggestion;

            $scope.$apply();
            watching = true;
        };

        $scope.preSelect = this.preSelect;

        this.preSelectOff = function(){
            watching = true;
        };

        $scope.preSelectOff = this.preSelectOff;

        // selecting a suggestion with RIGHT ARROW or ENTER
        $scope.select = function(suggestion, index){
            $log.log('Selection');
            watching = false;
            if(suggestion){
                $log.log('suggestion');
                $scope.searchParam = suggestion.name;
                $scope.searchFilter = suggestion.name;
                suggestion.index = index;
                $scope.ngModel = suggestion;
                if($scope.onSelect && $scope.status.isopen) {
                    $scope.onSelect(suggestion);
                }
            } else {
                $log.log('zero suggestion');
                $scope.searchParam = '';
                $scope.searchFilter = '';
                $scope.ngModel = '';
            }

            //watching = false;
            $scope.status.isopen = false;
            setTimeout(function(){
                watching = true;
            },1000);

            $scope.setIndex(-1);

            $timeout(function() {
                $signalProvider.signal($scope.context);
                $signalProvider.signal($scope.context + '_combobox_error', false);
            });
        };

        //Shows the dropdown
        $scope.status = {
            isopen: false
        };

        $scope.toggleDropdown = function($event) {
            $event.preventDefault();
            $event.stopPropagation();

            $log.log($event.type);

            if($event.type==='blur' && !$scope.selecting) {

                $log.log('Check if empty');
                if(($scope.searchParam==='' || typeof $scope.searchParam==='undefined') && !$scope.required) {
                    $signalProvider.signal($scope.context + '_combobox_error', false);
                    $scope.status.isopen = false;
                    return false;
                }

                $log.log('Check if typed');
                if($scope.exact) {

                    $scope.updateSearchParam = false;
                    $scope.ngModel = '';
                    var length = $scope.list.length;
                    for(var i=0;i<length;i++) {
                        if($scope.list[i].name === $scope.searchParam) {
                            var suggestion = $scope.list[i];
                            $scope.searchFilter = suggestion.name;
                            suggestion.index = i;
                            $scope.updateSearchParam = true;
                            $scope.ngModel = suggestion;
                            break;
                        }
                    }

                    $log.log('Check if error');
                    if(($scope.ngModel === null || $scope.ngModel === '')) {
                        $log.warn('Text does not have any matching ');
                        $scope.error = 'error';
                        $signalProvider.signal($scope.context + '_combobox_error', true);
                    }

                } else {
                    $scope.ngModel = $scope.searchParam;
                }

                $log.log('Check if close');
                $scope.status.isopen = false;

            } else {
                $scope.status.isopen = true;
                $scope.error = false;

                if(($scope.searchParam==='' || typeof $scope.searchParam==='undefined') && ($event.type==='click' || $event.type==='focus')) {
                    $log.log('Order By');
                    $scope.list = $filter('orderBy')($scope.list, 'name');
                }

                $scope.isDropUp = false;
                var remainingDownSpace = 0;
                var containerHeight = 0;
                var trueDropDownPosition = 0;

                var dropdownToggle = $event.currentTarget;
                var buttonHeight = dropdownToggle.getBoundingClientRect().height;

                var dropdownMenu = $(dropdownToggle).closest('.drop-down-container').find('.dropdown-menu');
                var menuHeight = dropdownMenu.outerHeight();
                var totalDropDownHeight = buttonHeight + menuHeight;

                var scrollContainer = $(dropdownToggle).closest('.ngsb-wrap');
                if(!!scrollContainer && scrollContainer.length) {
                    containerHeight = scrollContainer.height();
                    var dropdownTrueTop = $(dropdownToggle).offset().top;
                    var childOffset = dropdownTrueTop - scrollContainer.offset().top; //where is the child within its container
                    trueDropDownPosition = childOffset;
                    remainingDownSpace = containerHeight - childOffset; //do we have enough space left below?
                } else {
                    var $win = $(window);
                    containerHeight = $win.height();
                    trueDropDownPosition = dropdownToggle.getBoundingClientRect().top;
                    remainingDownSpace = containerHeight - trueDropDownPosition;
                }

                if( (trueDropDownPosition > menuHeight) && remainingDownSpace < totalDropDownHeight ) {
                    $scope.isDropUp = true;
                }
            }
        };

        $scope.accept = function() {
            if($scope.approval.acceptCallback) {
                if(typeof $scope.approval.acceptCallback === 'function') {
                    $scope.approval.acceptCallback($scope.ngModel, $scope.itemData);
                } else {
                    $log.error('Combobox accept callback is incorrect, please check directive for instructions.');
                    return;
                }
            }
        }

        $scope.cancel = function() {
            $scope.reset = true;

            if($scope.approval.cancelCallback) {
                if(typeof $scope.approval.cancelCallback === 'function') {
                    $scope.approval.cancelCallback($scope.ngModel, $scope.itemData);
                } else {
                    $log.error('Combobox cancel callback is incorrect, please check directive for instructions.');
                    return;
                }
            }
        }
    }


angular
    .module('pulse')
    .directive('pulseCombolist', pulseCombolist);

    pulseCombolist.$inject = [];

    function pulseCombolist() {
    
        return {
            restrict: 'A',
            require: '^pulseCombobox', // ^look for controller on parents element
            link: function(scope, element, attrs, controllers) {
                var comboboxController = controllers;

                element.bind('mouseenter', function() {
                    comboboxController.preSelect(attrs.val);
                    comboboxController.setIndex(attrs.index);
                });

                element.bind('mouseleave', function() {
                    comboboxController.preSelectOff();
                });
            }
        };
    }

})();
