/**
 * @ngdoc object
 * @name MetadataResource
 * @requires $resource
 */

(function(){

    'use strict';

    angular
        .module('pulse')
        .factory('MetadataResource', MetadataResource);

    MetadataResource.$inject = ['$resource'];

    function MetadataResource ($resource) {

        return $resource (
            config.baseURL + '/projects/:projectID/:ext/:assetID/:action',
            {ext: '@ext', projectID: '@projectID', assetID: '@assetID', action: '@action'},
            {
                getMetadataFiles: {method: 'GET', params: {ext:'ocfmetadatafiles'}, isArray: true},
                getMetadataMapping: {method: 'GET', params: {ext:'ocfmetadatamappings'}, isArray: true},
                postMetadataMapping: {method: 'POST', params: {ext:'ocfmetadatamappings'}, isArray: false},
                postSecondaryMetadataMapping: {method: 'POST', params: {ext:'ocfsecondarymetadatamappings'}, isArray: false},
                deleteFile: {method: 'DELETE', params: {ext:'ocfmetadatafiles'}},
                getMetadataSet: {method: 'GET', params: {ext:'ocfmetadatasets'}, isArray: false},
                getMetadataSets: {method: 'GET', params: {ext:'ocfmetadatasets'}, isArray: true},
                getMetadata: {method: 'GET', params: {ext:'ocfmetadata'}, isArray: true},
                postMetadata: {method: 'POST', params: {ext:'ocfmetadatas'}},
                getMetadataSetMetadatas: {method: 'GET', params: {ext:'ocfmetadatasets', action:'ocfmetadatas'}, isArray: true},
                getSecondaryMetadataSetMetadatas: {method: 'GET', params: {ext:'ocfmetadatasets', action:'ocfsecondarymetadatas'}, isArray: true},
                deleteMetadata: {
                  method: 'POST',
                  params: {ext:'ocfmetadatafiles', assetID:'delete'},
                  isArray: true
                },
                downloadFile: {
                  method: 'GET',
                  params: {ext:'ocfmetadatafiles', assetID:'download'},
                  isArray: false,
                  responseType: "arraybuffer",
                  transformResponse: function(data, headers){
                      var response = {};
                      response.data = data;
                      response.headers = headers();
                      return response;
                  }
                },
            }
        );
    }
})();
