'use strict';

angular.module('pulse')
  .filter('breakLongText', function () {
    return function (string, breakLength) {

      if (breakLength && typeof breakLength !== 'number') {
        console.error('Invalid type of breakLength, breakLength must be a number');
        return false;
      }

      breakLength = breakLength || 60;

      if (!string) {
        console.warn('String can not be a falsy value', string);
        return;
      }

      var new_str;
      if (string.indexOf(' ') >= 0) {
        new_str = string;
      } else {
        var string_array = string.split('');

        // Commenting in order ot fix #3634

        // var line_ct = 0;
        // var b_point = 23;
        // for (var s = 0; s < string_array.length; s++) {
        //   if (line_ct === b_point) {
        //     line_ct = 0;
        //     b_point = 21;
        //     string_array[s] = ' ' + string_array[s]
        //   }
        //   line_ct++;
        // }

        new_str = string_array.join('');

      }

      if (new_str.length >= breakLength) {
        new_str = new_str.substring(0, breakLength).trim() + '...';
      }

      return new_str;

    };
  });
