/**
 * @ngdoc object
 * @name GroupEditCtrl
 * @requires $scope, $stateParams, signalProvider
 */

'use strict';

angular.module('pulse')
    .controller('GroupEditV2Ctrl',
    ['$scope', '$state', '$signalProvider', '$stateParams', '$log', '$rootScope', 'modalManager', 'Groups', 'Users', 'group', 'userGroupSearchMetadata',
        function ($scope, $state, $signalProvider, $stateParams, $log, $rootScope, modalManager, Groups, Users, group, userGroupSearchMetadata) {

            $log.info('Group Edit Controller');

            $scope.groupEdit = {};
            $scope.groupEdit.data = {};

            $scope.groupEdit.group = group || {};
            $scope.groupEdit.group.ccEmails = group.ccEmails || '';
            $scope.groupEdit.data.list = group.users || [];
            $scope.groupEdit.data.currentGroupName = group.name;


            $scope.groupEdit.data.searchMetadata = userGroupSearchMetadata.metadata;

            $scope.groupEdit.loading = false;
            $scope.groupEdit.saving = false;
            $scope.groupEdit.deleting = false;
            $scope.groupEdit.error = false;

            $scope.groupEdit.back = function() {
                $state.go($state.current.name.substring(0, $state.current.name.lastIndexOf('.')), {'dummyID':Math.random()}, {reloadOnSearch: true});
            };

            $scope.groupEdit.save = function() {

                $rootScope.debounce = true;
                $scope.groupEdit.saving = true;


              var userIDs = [];
              //If we are creating a new group, first save the group, then save the users
              for(var x = 0; x < $scope.groupEdit.data.list.length; x++) {
                  userIDs.push($scope.groupEdit.data.list[x].id);
              }

              var data = {
                projectID: $stateParams.projectID
              };

              if($stateParams.groupID!=='create') {
                data.groupID = $stateParams.groupID;
              }

              //Save group
              var promise = Groups.ProjectGroups.updateGroup(data, {userIDs: userIDs, name: $scope.groupEdit.group.name, ccEmails: $scope.groupEdit.group.ccEmails}).$promise;

              promise.then(function (group) {
                  // checking if there were any errors on the textarea emails
                  if(group.errors && group.errors.length > 0) {
                    $log.log(group.errors[0]);
                    $scope.groupEdit.error = group.errors[0].message;
                    $scope.groupEdit.saving = false;
                    $rootScope.debounce = false;
                    $rootScope.dirty = false;
                    return;
                  }

                  //Creating the group was successful, now we need to save the users
                  $log.info('Created Group');
                  $log.log(group);

                  $signalProvider.signal('feedback', [ 'success', 'GroupCreated']);
                  $scope.groupEdit.saving = false;
                  $rootScope.debounce = false;
                  $rootScope.dirty = false;

                  if($stateParams.groupID==='create') {
                    $state.go($state.current,{
                        organizationID: $stateParams.organizationID,
                        divisionID: $stateParams.divisionID,
                        projectID: $stateParams.projectID,
                        groupID: group.id
                    });
                  }

              }, function(error){
                    $log.log(error);
                    $scope.groupEdit.saving = false;

                    $rootScope.debounce = false;

                    $signalProvider.signal('feedback', [ 'failure', 'GroupCreated']);
                });

            };

            $scope.groupEdit.toggle = function(user) {
                var found = false;
                for(var x = 0; x < $scope.groupEdit.data.list.length; x++) {
                    if($scope.groupEdit.data.list[x] && $scope.groupEdit.data.list[x].id === user.id) {
                        $scope.groupEdit.data.list.splice(x,1);
                        found = true;
                        break;
                    }
                }

                if(!found) {
                  $scope.groupEdit.data.list.push(user);
                }

                $rootScope.dirty = true;
            };

            $scope.groupEdit.active = function(user) {
                if (user && !!user.id) {
                    for(var x = 0; x < $scope.groupEdit.data.list.length; x++) {
                        if($scope.groupEdit.data.list[x] && $scope.groupEdit.data.list[x].id === user.id) {
                            return 'active';
                        }
                    }
                }
            };

            $scope.groupEdit.delete = function(){
                $log.log('Toggle Delete Modal');
                var item = $scope.groupEdit.group;
                item.deleteEntityType = 'group';


                modalManager.openModal({
                    templateUrl: 'views/common/modals/confirm.html',
                    controller: 'ConfirmModalCtrl',
                    windowClass: 'confirm',
                    resolve: {
                        title: function () {
                            return 'Are you sure you want to delete this group?';
                        },
                        body: function () {
                            //This can accept html
                            return 'You will be deleting group \"' + item.name + '\" from this project.';
                        },
                        button: function () {
                            return 'Delete';
                        }
                    }
                }).result.then(function (selectedItem) {
                        var promise = Groups.ProjectGroups.deleteGroup({ projectID: $stateParams.projectID,  groupID: $stateParams.groupID}).$promise;
                        promise.then(function(){
                            $signalProvider.signal('feedback', [ 'success', 'DeleteGroup']);
                            $scope.groupEdit.back();
                        }, function(error){
                            $signalProvider.signal('feedback', [ 'failure', 'DeleteGroup']);
                        });

                    });
            };

            //Search the group users with the given search params
            function search(name, search){
                $log.info('Searching Recipients ' + name);
                $scope.groupEdit.loading = true;
                //$log.log(search);

                $signalProvider.signal('searchUsers_placeholder', 'Searching...');

                //Save the search data because we will need it for polling and such.
                $scope.groupEdit.search = search;

                //Query for the count
                var promise = Users.ProjectUser.getUsersCount({
                    projectID: $stateParams.projectID,
                    query: JSON.stringify(search)
                }).$promise;

                promise.then(function (response){
                    $log.info('Found '+ response.count + 'users in group edit');
                    //$log.log(response.count);
                    $scope.groupEdit.usersCount = response.count;
                    $signalProvider.signal('searchUsers_placeholder', $scope.groupEdit.usersCount + ' users found');

                    //If the count is falsey just return and dont do the actual search
                    if(!$scope.groupEdit.usersCount) {
                        $scope.groupEdit.data.users = [];
                        $scope.groupEdit.loading = false;
                        return false;
                    }

                    //Query for the files
                    promise = Users.ProjectUser.getUsers({
                        projectID: $stateParams.projectID,
                        query: JSON.stringify(search)
                    }).$promise;
                    promise.then(function (response){
                        $log.info('Done Searching Recipients');
                        $scope.groupEdit.loading = false;
                        //$log.log(response);

                        //Add the files to angular
                        $scope.groupEdit.data.users = response;

                    });
                });
            };

            search('init', ''); // Initialize the search

            //listen for when to search
            var currentSearch = $signalProvider.listen('searchUsers', search);

            //Destroy the function on search
            $scope.$on('$destroy', function(){
                $log.info('Destroy Group Edit Controller');
                $signalProvider.unlisten('searchUsers', currentSearch);
            });

            $scope.$watch('groupEdit.group.name', function(newValue, oldValue) {
                if(!$rootScope.dirty && newValue && newValue !== oldValue) {
                    $log.log('Form is Dirty');
                    $rootScope.dirty = true;
                }
            });

            $scope.$watch('groupEdit.group.ccEmails', function(newValue, oldValue) {
                if(newValue !== oldValue) {
                    $scope.groupEdit.error = false;
                }
            });

        }]);
