/**
 * @ngdoc object
 * @name EditRoleCtrl
 * @requires $scope, $signalProvider, $stateParams, $log, $rootScope, $filter, $state
 */

'use strict';

angular.module('pulse')
    .controller('EditRoleV2Ctrl',
    ['$scope', '$signalProvider', '$stateParams', '$log', '$rootScope', '$filter', '$state', 'modalManager', 'PermissionsSvc', 'Roles', 'Projects', 'projectApplications', 'role', 'roleRights',
        function ($scope, $signalProvider, $stateParams, $log, $rootScope, $filter, $state, modalManager, PermissionsSvc, Roles, Projects, projectApplications, role, roleRights) {

            $log.info('Edit Role V2 Controller');

            $rootScope.debounce = false;

            $scope.edit = {};

            $scope.$stateParams = $stateParams;

            $scope.edit.applications = projectApplications;
            $scope.edit.roleRights = roleRights;

            $scope.edit.reset = false;
            $scope.edit.reverse = false;
            $scope.edit.predicate = 'name';

            $scope.edit.permissions = []; //All permissions to display in the dropdown.
            $scope.edit.permissionsCached = [];
            $scope.edit.toggledRight = {}; //Stores the state information of each of the assigned rights. This is used to collapse/expand the rights.

            if(role) {
              $scope.edit.name = role.name;
            }

            //Find the current application and show it first.
            for(var i = 0;i<$scope.edit.applications.length;i++) {
              if ($filter('trimToLower')($scope.edit.applications[i].name) === $rootScope.application_name) {
                $scope.edit.application = $scope.edit.applications[i];
                break;
              }
            }

            function getListIndex(list, item) {
              for (var i = 0; i < list.length; i++) {
                if (list[i].id === item.id) {
                  return i;
                }
              }
            }

            function checkExisting(filteredArray) {
              var length = $scope.edit.roleRights.length;
              var array = [];

              if (!filteredArray.length) {
                return [];
              }

              if (!length) {
                return filteredArray;
              }

              for (var i = 0; i < length; i++) {
                var existing = $scope.edit.roleRights[i];
                for (var x = 0; x < filteredArray.length; x++) {
                  if (existing.permission.id === filteredArray[x].id) {
                    filteredArray.splice(x, 1);
                    break;
                  }
                }
              }

              return filteredArray;
            }

            $scope.edit.changeApplication = function(action) {

              if(!action) {
                $scope.edit.permission = '';
                $scope.edit.reset = true;
              }

              var promise = PermissionsSvc.projectPermissions().getList({
                projectID: $stateParams.projectID,
                applicationID: $scope.edit.application.id,
                targetID:$stateParams.projectID,
                kind: 2
              }).$promise;
              promise.then(function(response) {
                response = $filter('orderBy')(response, 'name');
                var filtered = checkExisting(response);
                $scope.edit.permissions = filtered;
                $scope.edit.permissionsCached = angular.copy(filtered);
              });

            };
            $scope.edit.changeApplication('init');

            $scope.edit.editRole = function() {
                $log.log('Edit Role');

                $rootScope.debounce = true;

                var permission = $scope.edit.permission; //Caching this since it just comes back as an array later.
                var params = {
                  projectId: $stateParams.projectID
                };

                var data = {
                  name:$scope.edit.name
                };

                if($scope.edit.permission) {
                  data.permissionID  = $scope.edit.permission.id;
                }

                if($stateParams.roleID!=='create') {
                  params.typeId = $stateParams.roleID;
                }

                var save = Projects.Project.update(params, data).$promise;
                save.then(function(response) {
                    $log.log('Added Role');

                    $signalProvider.signal('feedback', [ 'success', 'Project']);
                    $signalProvider.signal('role_edited', response);

                    if($stateParams.roleID==='create') {
                      $state.go($state.current.name,{roleID:response.id});
                      return;
                    }

                    $scope.edit.roleRights = response.rights;

                    $rootScope.debounce = false;
                    $scope.edit.reset = true;

                    if(permission) {

                      var index1 = getListIndex($scope.edit.permissions, permission);
                      if(index1 !== undefined){
                          $scope.edit.permissions.splice(index1, 1);
                      }

                      var index2 = getListIndex($scope.edit.permissionsCached, permission);
                      if(index2 !== undefined){
                          $scope.edit.permissionsCached.splice(index2, 1);
                      }


                    }

                }, function(error) {
                    $log.log('User Not Saved To Project');
                    $signalProvider.signal('feedback', [ 'failure', 'Project']);
                    $rootScope.debounce = false;
                });
            };

            $scope.edit.toggleRight = function(index) {
              $scope.edit.toggledRight[index] = !$scope.edit.toggledRight[index];
            };

            $scope.edit.confirmDelete = function(right, $event) {
              //We get the index so we can delete later
              //var index = $scope.data.assignedRights.indexOf(object);
              var index = getListIndex($scope.edit.roleRights, right);
              var item = $scope.edit.roleRights[index];
              item.type = 'right';

              item.index = index;

              var modal = modalManager.openModal({
                templateUrl: 'views/common/modals/confirm.html',
                controller: 'ConfirmModalCtrl',
                windowClass: 'confirm',
                resolve: {
                  title: function() {
                    return 'Are you sure you want to remove the assignment of this ' + item.type + '?';
                  },
                  body: function() {
                    //This can accept html
                    return 'You will be removing the assignment of the ' + item.type + ' \"' + item.name + '\".';
                  },
                  button: function() {
                    return 'Unassign';
                  }
                }
              });

              modal.result.then(function() {

                var promise = Roles.ProjectRoles.deleteRight({
                  projectID: $stateParams.projectID,
                  roleID: role.id,
                  extID: item.id
                }).$promise;
                promise.then(function() {
                  $log.log('Right Deleted');

                  $scope.edit.roleRights.splice(item.index, 1); //Delete the submission.
                  $scope.edit.permissions.push(item); // Add back to cached collection
                  $scope.edit.reset = true; //reset the combobox

                  $signalProvider.signal('role_edited', 'deleted');
                  $signalProvider.signal('feedback', ['success', 'Role']);

                }, function(error) {
                  $log.log('Right Not Deleted');
                  $log.log(error);
                  $signalProvider.signal('feedback', ['failure', 'RemoveRightFromRole']);
                });

              });

              if ($event.stopPropagation) {
                $event.stopPropagation();
              }
              if ($event.preventDefault) {
                $event.preventDefault();
              }

              $event.cancelBubble = true;
              $event.returnValue = false;
            };


            //This is for filtering the rights combobox
            $scope.edit.updatePermissionsList = function(search) {
              if (search) {
                var searchData = [];
                angular.forEach($scope.edit.permissionsCached, function(value) {
                  var typed = search.toLowerCase();
                  var name = value.name.toLowerCase();
                  var index = name.indexOf(typed);
                  if (index !== -1) {
                    searchData.push(value);
                  }
                });
                $scope.edit.permissions = searchData;
              } else {
                $scope.edit.permissions = $scope.edit.permissionsCached;

              }
            };

            //Destroy the function on search
            $scope.$on('$destroy', function(){
                $log.info('Destroy User Controller');
            });

        }]);
