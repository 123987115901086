/**
 * @ngdoc object
 * @name Application No Access
 * @requires $stateProvider
 * @description This routing is for the no access screen, this happens when a user has access to an application but not the project
 */

'use strict';
angular.module('pulse')
  .config(['$stateProvider', function ($stateProvider) {

    $stateProvider
      .state('root.access', {
        url: '/access/:type',
        templateUrl: 'views/common/access.html',
        controller: ['$scope', '$stateParams', function($scope, $stateParams){$scope.stateParams = $stateParams}],
        resolve: {}
      })

  }]);

