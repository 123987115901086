/**

 * @ngdoc directive
 * @name PulseCalendar
 * @module pulse
 * @restrict E
 * @description
 *  `PulseCalendar` this is a basic calendar with optinal date picker.
 *
 * @usage

 <pulse-calendar

 start-date="ngModel"
 end-date="ngModel"
 load-today="Boolean"
 call-back="function"
 >

 </pulse-calendar>
 * $required ngModel
 * @param {object} startDate - (optional) if you want to use the date picker you will need startDate
 * @param {object} endDate - (optinal)  if you want to use the date picker you will need endDate
 * @param {string} loadToday - (optinal) this will reload the calendar to the current month day year
 * @param {function} callBack - (optinal)  this is if you would like to trigger a function after a day html element has been clicked
 *
 *
 */

///  TODO: close calendar when you click outside of cal


(function(){

  'use strict';


  angular
    .module('pulse')
    .directive('pulseCalendar', pulseCalendar);

  pulseCalendar.$inject = ['$log'];

  function pulseCalendar($log) {

    var directive = {
      restrict: 'E',
      scope: {
        'startDate': '=',
        'endDate': '=',
        'loadToday': '=',
        'callBack': '&'
      },
      templateUrl: 'views/common/datepicker/pulse_calendar.html',
      link: linkFunc,
      controller: PulseCalendarCtrl
    };

    return directive;

    function linkFunc(scope, elem, attrs) {

      elem.on('click', function (event) {

        try {

          if (scope.startDate === undefined && scope.endDate === undefined) {
            throw 'Date picker is disabled.  Please add startDate and endDate attrs'
            return
          }

          ///get elem id, break it up to get date and index
          $log.log('event target ID', event.target.id);
          var id = event.target.id.split('-');
          var id_day = id[0];
          var id_date = new Date(id[1]);
          var id_index = id[2];
          $log.log('index', id_index);

          ////  if the id has string day and is an instance of Date
          //// considered it a valid day element
          if (id_day === 'day' && id_date instanceof Date) {
            /// get the data and class nodes
            var target_data = event.target.getAttribute('data');
            var target_class = event.target.classList;
            /// lets check for a valid data attr
            if (target_data !== null) {
              if (scope.callBack) {
                scope.callBack();
              }
              ;
              //convert string to JSON object
              var target_data = JSON.parse(target_data);

              /// after a user clears the cal.  we need to set the start and end dates on the next click
              if (!scope.startDate || !scope.endDate) {
                var new_date = new Date(target_data.date);
                var date_string = (new_date.getMonth() + 1) + '/' + new_date.getDate() + '/' + new_date.getFullYear()
                scope.startDate = date_string;
                scope.endDate = date_string;
                return;
              }

              // we need vars for startDate, endDate and picker (mm/dd/yyyy)
              ///we need to convert the strings to Date object
              //we are using the Dates to compare each var
              var start = new Date(scope.startDate);
              var end = new Date(scope.endDate);
              var picker = new Date(target_data.date);
              $log.log('start_day', start);
              $log.log('end_day', end);
              $log.log('pricker_day', picker);


              ///start_from_end : this is the total number of days
              ///picker_from_end :  this is the day of where the picker is in
              /// reference to number of days.
              ///mid_point : this is the middle day between the start and end.

              ///// start_day------------midpoint---------------end_day

              var start_from_end = (parseInt((end - start) / (1000 * 60 * 60 * 24)) + 1);
              var picker_from_end = parseInt((end - picker) / (1000 * 60 * 60 * 24));
              var mid_point = Math.round(start_from_end / 2);
              $log.log('start_from_end', start_from_end);
              $log.log('picker_from_end', picker_from_end);
              $log.log('mid_point', mid_point);


              //select single day when a start or end day is clicked
              if (picker_from_end === 0 || (picker_from_end + 1) === start_from_end) {
                scope.startDate = target_data.date;
                scope.endDate = target_data.date;
                return;
              }

              // increase from start_day out-left  ||  decrease-rigth from start_day to mid_point
              if (picker <= start || picker_from_end >= mid_point) {
                scope.startDate = target_data.date;
                return;

                // increase from end_day out-right  ||  decrease-left from start_day to mid_point
              } else if (picker >= end || picker_from_end < mid_point) {
                scope.endDate = target_data.date;
                return;
              }

            }


          } else {
            /// this is not a calendar day element
            $log.log('this is not a day elem');
            return
          }

        } catch (e) {
          $log.error('click error', e);
        }


      });

      scope.$watchCollection('loadToday', function (newVal, oldVal) {
        $log.log('watch_______loadToday', newVal, oldVal, scope.loadToday);
        if (scope.loadToday === true) {
          scope.vm.getTodaysDate();
        }
      })

      scope.$watchCollection('startDate', function (newVal, oldVal) {
        $log.log('______$watchCollection startDate', scope.startDate);
        scope.vm.validateDate('start_cal', newVal);
        scope.vm.getPrevDate(scope.startDate);
      });

      scope.$watchCollection('endDate', function (newVal, oldVal) {
        $log.log('______$watchCollection endDate', scope.endDate);
        scope.vm.validateDate('end_cal', newVal);
        scope.vm.getPrevDate(scope.endDate);
      });

    }
  }

  PulseCalendarCtrl.$inject = ['$scope', '$log'];

  function PulseCalendarCtrl($scope, $log){

    $log.log('pulseCalendar', $scope.startDate, $scope.endDate , $scope);

    $scope.vm = {
      service : {},
      calendar : {},
      today : {},
      current_cal : {},
      start_cal : null,
      end_cal : null,
      getPrevNextDate : getPrevNextDate,
      getTodaysDate : getTodaysDate,
      validateDate : validateDate,
      selectDateRange : selectDateRange,
      getPrevDate : getPrevDate,
      init : init
    }

    init();

    function selectDateRange(){
      try {
        var start = $scope.vm.start_cal;
        var end = $scope.vm.end_cal;
        $log.log('selectDateRange', start , ' ---- ', end);
        if (!start && !end){
          throw 'start-date and end-date are null or undefined'
          return
        }else{

          var days = $scope.vm.calendar.days;
          var total_days = days.length

          if (end < start){
            var old_end = end;
            var old_start = start;
            start = old_end;
            end = old_start;
          }
          //loop days to find expected daterange
          for (var idx=0, len=total_days; idx < len; idx++){
            var date = new Date(days[idx].date);

            if (date >= start && date <= end){
              //activate css class and selected state

              /// only one day
              if(compareTwoDates(start, end)){
                days[idx].active = true;
                days[idx].active_left = false;
                days[idx].active_center = false;
                days[idx].active_right = false;
                days[idx].is_selected = true;
              }else{
                ///more then one day
                if (compareTwoDates(date,start)){
                  ////start date
                  days[idx].active = false;
                  days[idx].active_left = true;
                  days[idx].active_center = false;
                  days[idx].active_right = false;
                  days[idx].is_selected = true;
                }else if (compareTwoDates(date,end)){
                  ////end date
                  days[idx].active = false;
                  days[idx].active_left = false;
                  days[idx].active_center = false;
                  days[idx].active_right = true;
                  days[idx].is_selected = true;
                }else{
                  /// inbetween start and end
                  days[idx].active = false;
                  days[idx].active_left = false;
                  days[idx].active_center = true;
                  days[idx].active_right = false;
                  days[idx].is_selected = true;
                }
              }

            }else{
              ///basic calendar
              if (start === null || end === null){
                return;
              }
              var valid_start = (!isNaN(start.getTime()))
              var valid_end = (!isNaN(end.getTime()))
              if(valid_start === false && valid_end === true){
                //$log.log('start is empty and end is not');
                //days[idx].is_selected = true;
              }else{
                days[idx].active = false;
                days[idx].is_selected = false;
              }
            }
          }
        }

      } catch (e) {
        $log.error(e);
      }



    }

    function validateDate(start_or_end, date){
      try {
        if (date === null || date === undefined || date === 'undefined'){
          //throw 'input is null or undefined';
          $log.log('input is null or undefined', date);
        }
        if(date === ""){
          date = null;
        }
        var date = new Date(date);
        $scope.vm[start_or_end] = date;
      } catch (e) {
        $log.error('validateDate: error ', e);
      }


    }

    function getPrevNextDate(year, month){
      $scope.loadToday = false;
      if(typeof year != "undefined" && typeof month != "undefined" ){
        var dt = new Date(year, month);
        var month = (dt.getMonth()+1);
        var year = dt.getFullYear();
        var first_of_month = getFirstorLastofMonth(year, month, 1);
        var last_of_prev_month = getFirstorLastofMonth(year, month, 0);
        var prev_next_day = {'month': {'num': month,
          'name': getMonthName(dt.getMonth())},
          'day': {'num' : (dt.getDate()-1),
            'name' : getWeekDayName(dt.getDay())},
          'week': {'num' : (dt.getDay()-1),
            'name' : getWeekDayName(dt.getDay())},
          'year': year ,
          'num_days': getDaysInMonth(month, year),
          'first_of_month' : first_of_month,
          'last_of_prev_month' : last_of_prev_month
        };
        $scope.vm.current_cal = prev_next_day;
        constCalenar();
      }
    }

    function getTodaysDate(){
      $scope.loadToday = true;
      var dt = new Date();
      var month = (dt.getMonth()+1);
      var year = dt.getFullYear();
      //var first_of_month = new Date(year, month-1, 1).getDay();
      var first_of_month = getFirstorLastofMonth(year, month, 1);
      var last_of_prev_month = getFirstorLastofMonth(year, month, 0);
      var today = {'month': {'num': month,
        'name': getMonthName(dt.getMonth())},
        'day': {'num' : (dt.getDate()),
          'name' : getWeekDayName(dt.getDay())},
        'week': {'num' : (dt.getDay()),
          'name' : getWeekDayName(dt.getDay())},
        'year': year ,
        'num_days': getDaysInMonth(month, year),
        'first_of_month' : first_of_month,
        'last_of_prev_month' : last_of_prev_month
      };
      $scope.vm.today = today;
      $scope.vm.current_cal = today;
      constCalenar();
    }

    function genMonths(){
      return [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
    }

    function genWeekDays(){
      return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    }

    function genDays(num_days){
      var todays_month_num = $scope.vm.today.month.num;
      var calendar_month_num = $scope.vm.calendar.month_num;
      var todays_year = $scope.vm.today.year;
      var calendar_year = $scope.vm.calendar.year;
      // get the offset for days before and after the days in the month
      //we get the offset based on the number of days in that calendar month
      // the calendar is a six by seven matrix witha total 42 squares.
      var days_diff = (num_days === 31) ? 11 : (num_days === 30) ? 12 : (num_days === 28) ? 14 : 13;
      var days = [];
      // generate an array with total days in the month and active boolean
      for (var c=1, len=(num_days + 1); c < len; c++){
        var active = false;
        if (todays_month_num === calendar_month_num && todays_year == calendar_year){
          active = (c === $scope.vm.today.day.num)?true:false;
        };
        days.push({'date': calendar_month_num + '/'+ c + '/' + calendar_year,
          'active_left':false,
          'active_center':false,
          'active_right':false,
          'active':active,
          'inactive':false,
          'day':c});
      };
      var first_of_month =  $scope.vm.current_cal.first_of_month;
      var last_of_prev_month = $scope.vm.current_cal.last_of_prev_month;
      /// push the offset days to the days array
      //what is the first weekday for the month
      var days_forward = 0;
      var days_backward = -1;
      for (var c=1, len=(days_diff+1); c < len; c++){
        //find out where does the first day start in the weekday.  start offset there add
        // the rest to the end of array.
        ///switch case

        if (c <= first_of_month){
          days_backward += 1;
          //get the days from prev month
          var calendar_month_adjusment = (calendar_month_num === 1)?12:calendar_month_num-1;
          var calendar_year_adjustment = (calendar_month_num === 1)?calendar_year-1:calendar_year;
          var calc_day = (last_of_prev_month - days_backward);
          days.unshift({'date': calendar_month_adjusment + '/'+ calc_day + '/' + calendar_year_adjustment,
            'active_left':false,
            'active_center':false,
            'active_right':false,
            'active':false,
            'inactive':true,
            'day': calc_day});

        }else if (c >= first_of_month){
          //get the days from the next month
          var calendar_month_adjusment = (calendar_month_num === 12)?1:calendar_month_num+1;
          var calendar_year_adjustment = (calendar_month_num === 12)?calendar_year+1:calendar_year;
          days_forward += 1
          days.push({'date': calendar_month_adjusment + '/'+ days_forward + '/' + calendar_year_adjustment,
            'active_left':false,
            'active_center':false,
            'active_right':false,
            'active':false,
            'inactive':true,
            'day':days_forward});
        }


      }

      return days;
    }

    function getDaysInMonth(month, year){
      var num_days = new Date(year, month, 0).getDate();
      return num_days;
    }

    function getMonthName(month_num){
      var months = genMonths();
      return months[month_num];
    }

    function compareTwoDates(d1, d2){
      var compare = (d1 - d2 === 0);
      return compare;
    }

    function getWeekDayName(day_num){
      var week_days = genWeekDays();
      return week_days[day_num];
    }

    function getFirstorLastofMonth(year, month, first_or_last){
      var num =  new Date(year, month-1, first_or_last)
      if (first_or_last === 1){
        $log.log('getMonth', num.getMonth);
        return num.getDay();
      }else if (first_or_last === 0){
        return num.getDate();
      }
    }

    function isoFormat(date){
      date = new Date(date);
      var now = new Date()
      /// DATE SELECTED
      var month = date.getMonth()
      var day = date.getDate()
      var year = date.getFullYear()
      //// TIME NOW (the cal is only date we need to get their current time)
      var hr = now.getHours()
      var min = now.getMinutes()
      var sec = now.getSeconds()
      ///OFFSETTIMEZONE
      var tzo = -now.getTimezoneOffset()
      var dif = (tzo >= 0) ? '+' : '-';
      var pad =  function(num){
        var norm = Math.floor(Math.abs(num));
        return ((norm < 10) ? '0' : '') + norm;
      }
      var offset = dif + pad(tzo/60) + ':' + pad(tzo % 60)


      ///iso format = 2017-10-16T00:13:51-07:00
      return year + '-' + pad(month+1) + '-' + pad(day) + 'T' + pad(hr) + ':' + pad(min) + ':' + pad(sec) + offset

    }

    function constCalenar(){
      var cal = $scope.vm.calendar;
      cal['weekdays'] = genWeekDays();
      cal['month'] = $scope.vm.current_cal.month.name;
      cal['month_num'] = $scope.vm.current_cal.month.num;
      cal['year'] = $scope.vm.current_cal.year;
      cal['days'] = genDays($scope.vm.current_cal.num_days);
      $scope.vm.validateDate('start_cal', $scope.vm.start_cal);
      $scope.vm.validateDate('end_cal', $scope.vm.end_cal);
      $scope.vm.selectDateRange();

    }

    
    function getPrevDate(date){
      if(typeof date !== "undefined"){
        var date = date.split('/');
        getPrevNextDate(date[2], date[0]-1); 
        selectDateRange();
      }
    }

    function init(){
      // future feature we could start calendar at a specific date
      // defaults to todays date
      getTodaysDate();

    }

  }

})();
