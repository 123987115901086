/**
 * @ngdoc object
 * @name VendorsResource
 * @requires $resource
 */

'use strict';

angular.module('pulse')
  .factory('VendorsResource',
    ['$resource',
      function ($resource) {
        return $resource(
          config.baseURL + '/vendors/:projectID/:vendorID',
          {projectID: '@projectID', vendorID: '@vendorID'},
          {
            getVendors: {method: 'GET', isArray:true},
            saveVendor: {method: 'POST'},
            deleteVendor: {method: 'DELETE'}
          }
        );
      }
    ]
  );
