/**
 * @ngdoc directive
 * @name pulseInlineDropdown
 * @module pulse
 * @restrict E
 * @description
 * `pulseInlineDropdown` this is a custom select that is specific to inline changes on objects.
 *
 * @usage
  <pulse-inline-dropdown
    ng-class="{'disabled': vm.service[init.service][init.context_key]['event_obj'].status === 'done'}"
    ng-model="event.transkoderConfig"
    items = "event.transkoderConfigs"
    placeholder-text="Select An Output Profile"
    text-field="name"
    callback = "vm.service[init.service].updateEventOutputProfile"
    data = "event"
  ></pulse-inline-dropdown>
 *
 * @require ngModal
 *
 * @param {object} items - (required) This is the list of select items to display
 * @param {string} textField - (optional) This is the name of the text you want to display
 * @param {object} ngModel - This is the model for the directive, basically what is selected
 * @param {string} placeholderText - Sets the placeholder text, if not set it will use the first item in the list
 * @param {function} callback - This is the callback function that will run once data has changed
 * @param {object} data - This is the data we need to update
 * @param {boolean} (optional) reset - a flag to reset the dirty state
 *
 */

(function(){

  'use strict';

  angular
    .module('pulse')
    .directive('pulseInlineDropdown', pulseInlineDropdown);

  pulseInlineDropdown.$inject = ['$log', '$timeout'];

  function pulseInlineDropdown($log, $timeout) {

    var directive = {
      restrict: 'EA',
      scope: {
        items: '=',
        textField: '@',
        ngModel:'=',
        placeholderText: '@',
        callback: '=',
        data: '=',
        reset: '=?',
      },
      templateUrl: function(el, attr){
        if(typeof attr.template !== 'undefined'){
          return attr.template;
        }else{
          return 'views/common/dropdowns/inline_dropdown.html';
        }
      },
      link: linkFunction

    };

    return directive;
    function linkFunction(scope, elem, attr, ctrl) {

      //Make sure dropdown is hidden at that start
      scope.isDropUp = false;

      //Always come in with scope dirty as false
      scope.dirty = false;

      //Always come in with scope reset as false
      scope.reset = false;

      // We are creating out own instance of the model so that we can tell when someone dirties the existing model
      var original_model = angular.copy(scope.ngModel);
      //Check to see if we have text or value fields to use within out list
      var textField;
      if(scope.textField) {
        textField = scope.textField.toString().trim();
      }

      //This setups what is currently being viewed in the select.
      function setLabel(reset) {

        //If we have a placeholder set it, if we are reseting force the placeholder text
        if(scope.placeholderText || (reset && scope.placeholderText)) {
          scope.currentItemLabel = scope.placeholderText;
        } else {
          scope.currentItemLabel = "-- Select --";
        }

        // Remove placeholder text if we have a selected item on the model
        if(scope.ngModel && typeof scope.ngModel[textField] !== 'undefined' && !reset) {
          scope.currentItemLabel = scope.ngModel[textField].toString();
        } else if(scope.ngModel && typeof scope.ngModel !== 'undefined' && !reset) {
          scope.currentItemLabel = scope.ngModel.toString();
        }
      }

      //Setup the label on init
      setLabel();

      //When click toggle the drop down
      scope.toggleDropDown = function($event) {
        //This is to find out if this directive needs to drop up because it is too close to the bottom of the browser.
        //TODO: The CSS for this does not work currently

        scope.isDropUp = false;
        var dropdownToggle = $event.currentTarget;
        var buttonHeight = dropdownToggle.getBoundingClientRect().height;

        var dropdownMenu = $(dropdownToggle).closest('.dropdown__container').find('.dropdown__menu');
        var menuHeight = dropdownMenu.outerHeight();
        var totalDropDownHeight = buttonHeight + menuHeight;
        var $win = $(window);
        var containerHeight = $win.height();
        var trueDropDownPosition = dropdownToggle.getBoundingClientRect().top;
        var remainingDownSpace = containerHeight - trueDropDownPosition;

        if ( (trueDropDownPosition > menuHeight) && remainingDownSpace < totalDropDownHeight ) {
          scope.isDropUp = true;
        }
      };

      //On Click we are gonna set the value of the select
      scope.selectValue = function (item, index, reset) {
        $log.log('selectValue', item, reset);
        scope.ngModel = item;
        setLabel(reset);
        //If there is a callback function run it and pass the data
        $timeout(function() {
          if(scope.callback && typeof scope.callback === 'function') {
            scope.callback(scope.data, item, index);
          }
        }, 0);
      };

      //Reset the data in the model and put the placeholder back in
      scope.cancelClose = function($event) {
        $log.log('cancelClose', $event);
        $event.stopPropagation();
        scope.dirty = false;
        scope.selectValue(false, null, true);
      };

      //We need to watch the model because if we change the model and we need to determine if the data is dirty
      scope.$watch('ngModel', function(new_model, old_model) {
        $log.log('Inline Dropdown Input Watch Model', new_model);
        $log.log('Inline Dropdown Input Watch Old Model', old_model);
        $log.log('Inline Dropdown Input Watch Clean Model',  original_model);

        if(typeof new_model === 'undefined' && new_model !== old_model) {
          //Reset the dirty state
          scope.dirty = false; 
          setLabel();
        }

        // if we have set a text field then we check that instead of the whole object
        if(textField) {
          new_model = new_model ? new_model[textField] : null;
          old_model = old_model ? old_model[textField] : null;
        }

        if(new_model && !angular.equals(new_model, old_model)) {
          //Set the dirty flag, we arent tracking if they have the same information but if they
          if(!angular.equals(new_model, original_model)) {
            scope.dirty = true;
            setLabel();
          } else {
            scope.dirty = false;
          }
        }
      }, true);

      //Sometimes we need to clear the dirty state from events outside of the directive
      scope.$watch('reset', function(new_reset, old_reset) {
        $log.log('Inline Dropdown Input Watch Reset', new_reset);
        $log.log('Inline Dropdown Input Watch Old Reset', old_reset);
        if(new_reset && new_reset !== old_reset) {
          //Reset the dirty state
          scope.dirty = false;
        }
      }, true);
    }
  }

})();
