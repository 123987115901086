/**
  * Provides the clone dropdown functionality
  * Selecting dropdown option 'Clone the entire pull' will clone the all events whereas 'Clone only unsbmitted shots' will clone only unsubmitted events once pull has been submitted
  * @file: <html> pulse_single_dropdown.html
  * @param {string} title
  * @param {Array} items [{}]
  * @param {String} contextKey
  * @param {function} onSubmit
  * @param {object} service
  * @author AYUSHMAN TIWARI
 **/
(function () {
    'use strict';

    angular
        .module('pulse')
        .directive('pulseSingleDropdown', directive);

    directive.$inject = ['$log','$window'];

    /* @ngInject */
    function directive($log,$window) {
        var directive = {
            restrict: 'E',
            templateUrl: function (el, attr) {
                if (attr.template !== undefined) {
                    return attr.template;
                } else {
                    return '<h1>error loading searchbar : missing template</h1>';
                }
            },
            scope: {
                title: '@',
                titleIconClass: '@',
                items: '=',
                contextKey: '=',
                onSubmit:'=',
                service: '='
            },
            link: linkFunc,
            controller: Controller
        };

        return directive;

        function linkFunc(scope, element, attr) {
            scope.$watch('items', function() {
            });
        }
    }

    Controller.$inject = ['$scope', '$log', '$window','ServiceManager'];

    /* @ngInject */
    function Controller($scope, $log, $window,ServiceManager) {

        if (!$scope.title || typeof $scope.title !== 'string') {
          throw new Error('Expected title of type string');
        }

        if (!$scope.items || !angular.isArray($scope.items)) {
          throw new Error('Expected items of type [{}]');
        }

        if (!$scope.onSubmit || typeof $scope.onSubmit !== 'function') {
          throw new Error('Expected onsubmit of type function');
        }

        if (!$scope.contextKey || typeof $scope.contextKey !== 'string') {
          throw new Error('Expected contextkey of type string');
        }

        $scope.showCloneDropdown = false;
        $scope.toggleCloneDropdown = function (){
          $scope.showCloneDropdown = !$scope.showCloneDropdown;
        }

        $scope.selectCloneOption = function (item) {
          $scope.showCloneDropdown = false;
          $scope.onSubmit(item,$scope.contextKey);
        }

        $window.addEventListener('click',closeCloneDropdownOutside);

        function closeCloneDropdownOutside(event) {
           if (!event.target.classList.contains('clone') && ($scope.showCloneDropdown)){
             $scope.showCloneDropdown = false;
           }
        }

        $scope.$on('$destroy',function (){
          $window.removeEventListener('click',closeCloneDropdownOutside);
        });

    }
})();
