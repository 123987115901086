///need to add ng-doc stuff
// I would like to move this Dir to the commons folder
// this dir ver. does not support polling.

(function() {
    'use strict';

    angular
        .module('pulse')
        .directive('pulseSelectText', directive);

    /* @ngInject */

    directive.$inject = ['$log', '$timeout'];

    function directive($log, $timeout) {
        var directive = {
            restrict: 'A',
            scope: {},
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, el, attr) {

            el[0].style.userSelect = 'all';
            el[0].draggable = false;
            attr.draggable = false;

            el.on('click', function(event){
              event.preventDefault();
              event.stopPropagation();
            });

        }
    }
})();
