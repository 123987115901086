/*
    @ngdoc overview
    @name tutorialModalCtrl
    @param {string} title - The title for what the modal is doing.
    @param {string} body - Extra information for the actions of the modal.

*/

'use strict';

angular.module('pulse')
    .controller('tutorialModalCtrl',
    ['$scope', '$log', '$rootScope', '$modalInstance', 'title', 'body', 'Users','user', 'application',
        function ($scope, $log, $rootScope, $modalInstance, title, body, Users,user, application) {

            $log.log('Tutorial Modal Controller');

            $rootScope.debounce = false;

            $scope.confirm = {'title':title, 'body':body, 'closed':false};

            $scope.confirm.continue = function () {

                $log.log('Check For Tutorial', user, application);

                //To set the metadata_tutorial flag to true if the user has visited first time
                var params = {
                'organizationID': user.organization.id,
                'userID': user.id
                };

                var tutorial_name = application.name + "_tutorial";
                tutorial_name  = tutorial_name.toLowerCase();
   
                params.features = user.features;

                //If features are not set yet, set them
                if(typeof params.features === "undefined"){
                  params.features = {};
                }

                params.features[tutorial_name] = true;

                var promise = Users.OrganizationUser.saveUser(params).$promise;
                promise.then(function(response) {
                    $log.log('MetadataService Users.OrganizationUser.saveUser Success', response);

                    if(typeof response.features === "undefined") {
                        response.features = {};
                        response.features[tutorial_name] = true;
                    }
                    if(typeof response.features[tutorial_name] === "undefined" ||  response.features[tutorial_name] == false) {
                        user.features[tutorial_name] = true;
                    }
                },
                function(error) {
                    $log.log('MetadataService Users.OrganizationUser.saveUser Error', error);
                });

                $scope.confirm.closed = true;
                $modalInstance.dismiss('cancel');
            };
    }]);
