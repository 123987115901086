/**
 * @ngdoc directive
 * @name pulseFileButton
 * @module pulse
 * @restrict E
 * @description
 * `pulseFileButton` A general purpose file uploader
 *
 * @required
 * init is required with service, method
 *
 * @usage
 * <hljs lang="html">
      <pulse-file-button
        class="button button--file button--green"
        accept=""
        callback="vm.uploads.onFileSelect"
        context="files"
        label="ADD EDL"
      ></pulse-file-button>
 * </hljs>
 *
 * @param {string} accept - the value for accept attribute; default to "";
 * @param {string} completeUrl - the value for accept attribute; default to "";
 * @param {string} destination - the value for accept attribute; default to "";
 * @param {string} callback - the value for accept attribute; default to "";
 * @param {string} label - the value for accept attribute; default to "";
 */


(function() {
  'use strict';

  angular
    .module('pulse')
    .directive('pulseFileButton', directive);

  /* @ngInject */

  directive.$inject = ['$log', '$rootScope'];

  function directive($log , $rootScope) {

    var directive = {
      restrict: 'EA',
      scope: {
        accept: '@',
        callback: '=',
        label: '@'
      },
      templateUrl: function(el, attr){
        if(typeof attr.template !== 'undefined'){
          return attr.template;
        }else{
          return 'views/common/inputs/file_button.html';
        }
      },
      link: linkFunc,
      controller: Controller
    };

    return directive;

    function linkFunc(scope, elem, attr, ctrl) {
      $log.info('pulseFileButton Directive');

      if(typeof attr.multiple !== 'undefined') {
        scope.multiple = attr.multiple;
      }

      if(typeof scope.label === 'undefined') {
        scope.label = 'Select File';
      }

      //  determine callback
      if(typeof scope.callback !== 'function') {
        $log.error('The pulseFileButton attribute "callback" must be a function that takes 1 argument.');
        return;
      }

      /**
       * On Chance/ File Selection, grab all the files and start the upload process
       * @param files
       */
      scope.onFileSelect = function(files) {
        $rootScope.debounce = true;
        scope.callback(files, scope.accept);
        $rootScope.debounce = false;
      };

    }
  }

  Controller.$inject = ['$scope', '$log', 'ServiceManager'];

  /* @ngInject */
  function Controller($scope, $log, ServiceManager) {

    $log.info('pulseFileButton Controller');

    $scope.vm = {
      service : ServiceManager
    };

    $scope.$on('$destroy', function(){
      $log.info('Destroy pulseFileButton Controller');
    });
  }
})();


