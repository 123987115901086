/**
 * @ngdoc object
 * @name Applications
 * @requires $resource
 */

(function(){

    'use strict';

    angular
        .module('pulse')
        .factory('Applications', Applications);

    Applications.$inject = ['$resource'];

    function Applications ($resource) {

        return $resource(
            config.baseURL + '/applications/:id/:ext',
            { id: '@id', ext: '@ext' },
            {
                getApplications: {method:'GET', isArray: true},
                getApplicationProjects: {method:'GET', params: {ext:'projects'}, isArray: true},
                getAvailableApplications: {method:'GET', params:{ext:'available'}, isArray: true}
            }
        );
    }
})();
