/**
 *
 * @ngdoc object
 * @name CloudTakes
 * @requires $resource
 *
 */
(function(){
'use strict';
angular
    .module('pulse')
    .factory('CloudTakesResource', CloudTakes);
    CloudTakes.$inject = ['$resource', '$stateParams', '$log']

    function CloudTakes($resource, $stateParams, $log) {
        return $resource(
            config.baseURL +'/cloudtakes/:projectID/:ext/:assetID/:action',
            {projectID: '@projectID', ext: '@ext', assetID: '@assetID'},
            {
              clip : {method:'GET', params : {ext:'clips'}},
              clips : {method:'GET', params: {ext:'clips', projectID:$stateParams.projectID}, isArray: true},
              clipsSearch :  {method:'GET', params: { ext: 'clips', action:'searchData'}},
            }
        );
    }

})();
