/**
 * @ngdoc directive
 * @name pulseDropdownSearch
 * @module pulse
 * @restrict E
 * @description
 * `pulseDropdownSearch` this is a custom select that is specific to inline changes on objects.
 *
 * @usage
 <pulse-dropdown-search
  search-metadata="browse.searchMetadata"
  context="searchFile"
  template="views/common/search/dropdown_search.html"
 ></pulse-dropdown-search>
 *
 * @require ngModal
 *
 * @param {array} search-metadata - (required) This populates the dropdown list
 * @param {string} context - (required) This is the context for the signaler
 * @param {string} template - (optional) You can change whatever template you want with this
 *
 *
 * TODO: This directive was created fast for security audit, lots of things need to be cleaned up.
 * TODO: This still needs a lot of commenting
 *
 */

(function(){

  'use strict';

  angular
    .module('pulse')
    .directive('pulseDropdownSearch', pulseDropdownSearch);

  pulseDropdownSearch.$inject = ['$log', '$signalProvider', '$timeout', '$window'];

  function pulseDropdownSearch($log, $signalProvider, $timeout, $window) {

    var directive = {
      restrict: 'E',
      transclude: true,
      templateUrl: function(element, attributes){
        if(typeof attributes.template !== 'undefined'){
          return attributes.template;
        }else{
          return 'views/common/search/dropdown_search.html';
        }
      },
      scope: {
        searchMetadata: '=',
        context: '@'
      },
      link: linkFunction

    };

    return directive;
    function linkFunction(scope, element, attributes, controller) {

      scope.search = {};
      scope.dropdown = false;
      var input = element.find('input');


      scope.selectChange = function(selected, dirty) {
        // We need this processing flag because an on change event will
        // happen every time an item is added to the list
        if(scope.search.processing === false) {
          $log.info('Done Processing');

          //This is the initalize of the directive -- dont do anything
          if(scope.search.initalize) {
            scope.search.initalize = false;
            return false;
          }

          //If we start typing in something to the search bar and we press tab or enter
          //we dirtied the prebuild queries
          if(dirty) {
            $signalProvider.signal(scope.context+'_dirty', selected);
          }

          //I dont want to save the actual query for smart search
          //we need to return the selected cached version
          $signalProvider.signal(scope.context+'_cached', selected);

          //Parse our current array to create a nice search query
          //var search = parseSearch(selected);

          if(selected.length > 0 && selected[0].error && selected[0].code === '1'){
            //do nothing, but need to handle this better
            $signalProvider.signal(scope.context+'_error', selected);
          }else{
            $signalProvider.signal(scope.context, selected);
          }
        }
      };

      scope.submittedSearch = function(search) {
        $log.log('submittedSearch', search);

        var search_array = [];

        var quotes_length = search ? search.split('"').length - 1 : 0;

        for(var i=0; quotes_length > i; i+=2) {
          var extracted_text = extractText(search);
          var new_text = extracted_text.replace(/[ ]/gi, '_-_');
          search = search.replace('"'+extracted_text+'"', new_text);
        }

        //This is when they use any kind of compound searching
        var search_space_split = search ? search.split(' ') : [];
        var search_space_split_length = search_space_split.length;
        for(var i=0; search_space_split_length> i; i++) {

          if(search_space_split[i].indexOf(':') !== -1) {

            //build the array
            var search_colon_split = search_space_split[i].split(':');
            var search_query = {'field':search_colon_split[0], 'value': search_colon_split[1].replace(/_-_/g, ' ')};

            search_array.push(search_query);

          }else{

            search_array.push({'field':'', 'value': search_space_split[i].replace(/_-_/g, ' ')});

          }

        }

        //Strip out blank values
        for(var i = search_array.length -1; i >= 0; i--) {
          if(search_array[i].value === '') {
            search_array.splice(i,1);
          }
        }

        $log.log(search_array);

        setSelected('search', search_array);

      };

      function extractText( string ){
        var substring = "";

        if ( /"/.test( string ) ){
          substring = string.match( /"(.*?)"/ )[1];
        } else {
          substring = string;
        }

        return substring;
      }

      function addSelected(name, search) {
        //Error handling for not having an array
        if(!angular.isArray(search)) {
          $log.error('Need to pass array of objects to search directive');
          return false;
        }

        var search_string = search[0].field + ':' + search[0].value + ' ';
        if(!search[0].field && !search[0].value) {
          search_string = '';
        }

        var check_model_for_duplicates = checkModelForDuplicates(scope.searchModel, search_string);
        if(check_model_for_duplicates) {
          return false;
        }else{

          if(scope.searchModel) {
            scope.searchModel =  search_string + ' ' + scope.searchModel;
          } else{
            scope.searchModel =  search_string;
          }

          // Change doesnt run because we need to delete the fake data first
          //manually run change after everything is done
          scope.submittedSearch(scope.searchModel);
        }

      }

      function checkModelForDuplicates(model, search_string) {

        if(!model) {
          return false;
        }

        //This is when they use any kind of compound searching
        var model_space_split = model.split(' ');
        var model_space_split_length = model_space_split.length;
        for(var i=0; model_space_split_length> i; i++) {

          if(model_space_split[i] === search_string) {
            return true;
          }

        }

      }

      //If you need to set a selected item from outside the directive
      function setSelected(name, selected) {
        //Error handling for not having an array
        if(!angular.isArray(selected)) {
          $log.error('Need to pass array of objects to search directive');
          return false;
        }

        //Flag processing so no change events will happen
        scope.search.processing = true;

        //TODO: Find a better way so this isnt so hacky.
        //Will probably have to modify the select module
        $timeout(function() {

          // Done Processing
          scope.search.processing = false;

          // Change doesnt run because we need to delete the fake data first
          //manually run change after everything is done
          scope.selectChange(selected, false);
        }, 1000);

      }

      //Clear the selected objects from the query builder
      scope.clearSearch = function(name, refresh) {
        scope.searchModel = '';
        if(refresh !== false) {
          scope.submittedSearch('');
        }
        $signalProvider.signal(scope.context+'_dirty', ['clear']); //This is kinda just a hack to make things dirty
      };

      // Clears the input field and stops event from triggering the dropdown
      scope.clearInput = function(event) {
        event.preventDefault();
        scope.clearSearch();
      };

      function setPlaceholder(name, value) {
        $timeout(function() {
          if(value.context){
            scope.search.placeholder = value.count + ' ' + value.context + ' found';
          } else {
            scope.search.placeholder = value;
          }
        }, 100);
      }

      function resetSearch(){
        scope.selectChange(scope.search.selected, true);
      }

      scope.selectMetadata = function(metadata) {

        $log.log('selectMetadata', metadata);
        var original_string = scope.searchModel.split(' '); 
        var string_length = original_string.length;   
        if(original_string){
          if(original_string[string_length-1].indexOf(':') > -1 ){
            var temp = original_string[string_length-1].split(':');
            if( temp[1] ){
              scope.searchModel = original_string.join(' ');
              scope.searchModel =  scope.searchModel + ' ' + metadata + ':';  
            }else {
              original_string.pop(string_length);
              scope.searchModel = original_string.join(' ');
              scope.searchModel =  scope.searchModel + ' ' + metadata + ':';
            }
          }else{
            original_string.pop(string_length);
              scope.searchModel = original_string.join(' ');
              scope.searchModel =  scope.searchModel + ' ' + metadata + ':';
          }
        }
        $log.log('model', scope.searchModel);
        input.focus();
      };

      //TODO: This sucks that we have to do this but its the only way
      var search_window = angular.element($window);
      search_window.bind('click', function(event){
        if (event.target !== element[0].querySelector('.dropdown_search__dropdown') && event.target !==  input[0] && !event.target.classList.contains('dropdown_search__dropdown__item')) {
          $timeout(function() {
            scope.dropdown = false;
          }, 100);
        }
      });

      // this blurs the selected element and closes the dropdown on keypress enter, we can add more on the future if needed
      scope.blurOnKeypress = function(event) {
        var keyCode = event.keyCode || event.which;
        if (keyCode === 13) { // Enter Key code
          scope.dropdown = false;
          var target = event.target;
          target.blur();
        }
      }

      //The signal listener -- returns and index so it can be deleted on view destroy.
      var searchPlaceholder = $signalProvider.listen(scope.context + '_placeholder', setPlaceholder);
      var currentSearch = $signalProvider.listen(scope.context + '_add', addSelected);
      var clearSearch = $signalProvider.listen(scope.context + '_clear', scope.clearSearch);
      var refreshSearch = $signalProvider.listen(scope.context + '_refresh', resetSearch);

      scope.$on('$destroy', function(){
        $log.info('Destroy Search Directive:' + scope.context);
        $signalProvider.unlisten(scope.context + '_placeholder',searchPlaceholder);
        $signalProvider.unlisten(scope.context + '_add',currentSearch);
        $signalProvider.unlisten(scope.context + '_clear',clearSearch);
        $signalProvider.unlisten(scope.context + '_refresh',refreshSearch);
      });
    }

  }

})();



