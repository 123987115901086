'use strict';

/**
 * AngularJS default filter with the following expression:
 * 'person in people | filter: {name: $select.search, age: $select.search}'
 * performs a AND between 'name: $select.search' and 'age: $select.search'.
 * We want to perform a OR.
 */
angular.module('pulse').filter('propsFilter', function() {
    return function(items, props) {

        var out = [];

        if (angular.isArray(items)) {
            items.forEach(function(item) {
                var itemMatches = false;
                var keys = Object.keys(props);
                for (var i = 0; i < keys.length; i++) {
                    var prop = keys[i];
                    var text = props[prop].toLowerCase();

                    //Modified for pulse. Added.
                    //Ignores certain characters in the filter.
                    if(text.length >= 1){
                        if(text.substring(0, 1) === '#'){
                            text = text.substring(1);
                        }
                        if(text.substring(0, 1) === '+'){
                            text = '';
                        }
                    }
                    //End Modified for pulse.
                    if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                        itemMatches = true;
                        break;
                    }
                }

                if (itemMatches) {
                    out.push(item);
                }
            });
        } else {
            // Let the output be the input untouched
            out = items;
        }

        return out;
    };
});
