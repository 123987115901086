/**
 * @ngdoc object
 * @name Pools
 * @requires $resource
 */

(function(){

'use strict';

angular
    .module('pulse')
    .factory('PoolsResource', PoolsResource);

    PoolsResource.$inject = ['$resource'];

    function PoolsResource ($resource) {

        return $resource(


            config.baseURL + '/projects/:projectID/pools/:poolID/:action',
            {projectID: '@projectID', action: '@action'},
            {
                /* TODO: check if the following resources are in use in preconform */
                getPools: {
                    method: 'GET',
                    transformResponse: function(data){
                        data = angular.fromJson(data);
                        if(data.length > 0){
                            for(var i = 0; i < data[i].length; i++){
                                if(data[i].type){
                                    data[i].type = {name: data[i].type};
                                }
                            }
                        }

                        return data;
                    },
                    isArray: true
                },

                getPool: {
                    method: 'GET',
                    transformResponse: function(data){
                        data = angular.fromJson(data);
                        if(data.type){
                            data.type = {name: data.type};
                        }
                        return data;
                    }
                },

                updatePool: {
                    method: 'POST',
                    transformResponse: function(data){
                        data = angular.fromJson(data);
                        if(data.type){
                            data.type = {name: data.type};
                        }
                        return data;
                    }
                },

                deletePool: {method: 'DELETE'},
                getPoolTypes: {method: 'GET', params: {action: 'types'}},
                getPoolMembers: {method: 'GET', params: {action: 'acl'}},
                savePoolMembers: {method: 'POST', params: {action: 'acl'}},
                // You also need to pass in path as a query string for this resource
                getPoolsFiles: {method: 'GET', params: {action: 'assets/files'}, isArray: true},

                sendFiles: {url: config.baseURL + '/projects/:projectID/transfers',method: 'POST'},
                getTransfers: {url: config.baseURL + '/projects/:projectID/transfers', method: 'POST', isArray:true},


                // We do not have a project when creating region or system pools
                createAdminPool: {method: 'POST', url: '/api/v1/pools', isArray: false},
                updateAdminPool: {method: 'POST', url: '/api/v1/pools/:poolID', isArray: false},
                getAdminPool: {method: 'GET', url: '/api/v1/pools/:poolID', isArray: true}

            }
        );
    }
})();
