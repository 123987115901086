/**
 * @ngdoc directive
 * @name pulseInlineInput
 * @module pulse
 * @restrict E
 * @description
 * `pulseInlineInput` this an input that saves on enter or on blur if the value changes. 
 *  this site works great to identify the keyCodes http://keycode.info
 *
 * @usage
    <pulse-inline-input 
        model="pipeline.type.priority"
        on-submit="vm.service[init.service].setPriority"
        on-success="vm.service[init.service].success"
        on-error="vm.service[init.service].error"
        item="pipeline"
        defaultValue="0"
        allow-empty="false"
        regex="'^[0-9]+$'"
        options="max,min,source"
    ></pulse-inline-input>
 *
 * @param {string|integer} model - (required) This is the value to display and change
 * @param {function} onSubmit - (required) This is the function to be executed when submitted. it need to return a promise to process success and error
 * @param {function} onSuccess - (optional) This is the function to be executed when submitted succeeds.
 * @param {function} onError - (optional) This is the function to be executed when submitted errors.
 * @param {function} onChange - (optional) This is the function to be executed when input value changes.
 * @param {function} onCheckValidity - (optional) This is the function to be executed when checking the value.
 * @param {object} item - (optional) the item object that might be changing. This gets passed to the onSuccess funciton
 * @param {string} defaultValue - (optional) show a default value in case the model is empty
 * @param {string} label - (optional) a string value/key to pass on OnFunctions to help manage the field 
 * @param {boolean} allowEmpty - (optional) By default we allow empty values unless passing a false
 * @param {regex} regex - (optional) The regular expresison you want to run before trying to save to check the value
 * @param {string|integer} placeholder - (optional) the placeholder text to display in the input field
 * @param {boolean} (optional) reset - a flag to reset the dirty state and to reload the original model caching
 * @param {boolean} (optional) clearOnSubmit - this clears the model value once its submitted
 * @param {string} contextKey - (optional) the context key to manage the state
 * @param {string} options - (optional) this enables a small dropdown with few options to select and populate the input. List comma separated
 *
 */

(function() {
    'use strict';

    angular
        .module('pulse')
        .directive('pulseInlineInput', directive);

    /* @ngInject */

    directive.$inject = ['$log', '$timeout'];

    function directive($log, $timeout) {
        var directive = {
            restrict: 'E',
            templateUrl: 'views/common/inputs/inline_input.html',
            scope: {
                model : '=',
                onSubmit : '=',
                onSuccess : '=',
                onError : '=',
                onChange : '=',
                onCheckValidity: '=',
                item : '=',
                defaultValue: '@',
                label: '@',
                regex: '=',
                placeholder: '@',
                allowEmpty: '=',
                reset: '=?',
                clearOnSubmit: '=?',
                contextKey: '@',
            },
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, el, attr) {
            // getting the input field in the template. to handle more easily to add event listeners and update the classes.
            var input_element = el[0].getElementsByTagName("input")[0];
            var regex = new RegExp(scope.regex);

            // We create the options array
            if(attr.options) {
                scope.options = attr.options.split(',');
            }

            // This checks if the model was set. if not we then check the default value and set it as the main model.
            // If neither the model or the default value were set we set it as an empty string so it wont de undefined.
            if(typeof scope.model !== 'undefined' && typeof scope.model !== null) {
                scope.model = scope.model;
            } else if(typeof scope.defaultValue !== 'undefined' && typeof scope.defaultValue !== null) {
                scope.model = scope.defaultValue;
            } else {
               scope.model = ''; 
            }

            scope.debounce = false;

            // Always come in with scope dirty as false
            scope.dirty = false;

            // Always come in with scope reset as false
            scope.reset = false;

            // To keep track of errors on each input field of the timecode
            scope.error = false;

            // Shows and hides the options dropdown
            scope.showOptions = false;

            // We are creating out own instance of the model so that we can tell when someone dirties the existing model
            var original_model = angular.copy(scope.model);
            var current_model = angular.copy(scope.model); // this is to check on save()
            
            // this helps in case the parent also has a click event. this will stop it from been triggered 
            el[0].addEventListener('click', function (event) {
                event.stopPropagation();
            }, false);

            // this blurs the selected element on keypress enter, we can add more on the future if needed
            // this will also execute the function onChange if it exists 
            scope.blurOnKeypress = function(event) {
                if(scope.onChange) {
                    scope.onChange(event, scope.item);
                }

                var keyCode = event.keyCode || event.which;
                if (keyCode == '13') { // Enter Key code
                    event.target.blur();
                }
            }

            // Updates the model with the selected option from the dropdown
            scope.selectOption = function(option) {
                scope.model = option;
                checkValidity();
                scope.save();
            }

            // this adds a class to the input to show the save was either successfull or failure and removes after
            function updateClassName(className, milliseconds) {
                input_element.classList.add(className);
                if(milliseconds) {
                    setTimeout(function() {
                        input_element.classList.remove(className);
                    }, milliseconds);
                }
            }

            // check if we the value is valid with regex 
            function checkValidity() {
                var is_valid = true;
                scope.error = false;

                // This can override the validity check from the outside for a deeper check on the values.
                if(scope.onCheckValidity) {
                    // if the funciton returns false we show the error state
                    if(!scope.onCheckValidity(scope.contextKey, scope.model, scope.label)){
                        is_valid = false;
                        scope.error = true;
                    }
                } else {
                    // we loop the string characters and check each one with regex
                    for (var i = 0; i < scope.model.length; i++) {
                        if(scope.regex && !regex.test(scope.model[i])){
                            is_valid = false;
                            scope.error = true;
                        }
                    }
                }

                return is_valid;
            }

            // This closses the options dropdown
            function closeOptions() {
                // we a delay in order for selectOption to fire before this to update the model
                $timeout(function() {                
                    scope.showOptions = false;
                }, 200)
            }

            // this process the submit of the form or on blur from the input field
            scope.save = function () {
                closeOptions();
                if(scope.debounce) return;

                // this allows to always have a value on the input.
                // if allowempty is true when saving it will check if its empty and if it is it will put the default value or the original value
                if(scope.allowEmpty === false) {
                    scope.model = String(scope.model);
                    if(!scope.model.length) {
                        if(typeof scope.defaultValue !== 'undefined' && typeof scope.defaultValue !== null) {
                            scope.model = scope.defaultValue;
                        } else {
                            scope.model = original_model; 
                        }
                        scope.dirty = false;
                        return;
                    }
                }

                // This stops the saving if there is no submit function to call
                if(!scope.onSubmit) return;

                // checks if the value has changed in order to send the save
                if(current_model !== scope.model) {
                    // this locks the input so you cant change anything until the call is proccessed
                    scope.debounce = true;

                    // runs the funciton that was passed and sends the new value and the data you might need to process the submitted
                    var promise = scope.onSubmit(scope.model, scope.item, scope.contextKey);
                    promise.then(function (item) {
                        // this adds the success class to the input to show the save was successfull and removes it 2.5 seconds after
                        updateClassName('success', 2500);

                        // this unlocks the input and resets the new current_model and checks if onSuccess function was passed and runs it if it has
                        scope.debounce = false;
                        current_model = scope.model;
                        original_model = scope.model;
                        if(scope.onSuccess) {
                            scope.onSuccess(item, scope.contextKey, scope.label);
                        }

                        // we clear all the values
                        if(scope.clearOnSubmit) {
                            scope.model = '';
                            current_model = '';
                            original_model = '';
                        }
                    }, function (error) {
                        // this adds the error class to the input to show the save was unsuccessfull and removes it 2.5 seconds after
                        updateClassName('error', 2500);

                        // this unlocks the input and resets the model with the old value and checks if onError function was passed and runs it if it has
                        scope.debounce = false;
                        scope.model = current_model;
                        if(scope.onError) {
                            scope.onError(error, scope.contextKey, scope.label);
                        }
                    });
                }
            };

            //We need to watch the model because if we change the model and we need to determine if the data is dirty
            scope.$watch('model', function(new_model, old_model) {
                // $log.log('Inline Input Watch Model', new_model);
                // $log.log('Inline Input Watch Old Model', old_model);
                // $log.log('Inline Input Watch Clean Model',  original_model);

                if(new_model && new_model !== old_model) {
                    //Set the dirty flag, we arent tracking if they have the same information but if they
                    if(new_model !== original_model) {
                        scope.dirty = true;
                    } else {
                        scope.dirty = false;
                    }
                    checkValidity();
                }
            }, true);

            // Sometimes we need to clear the dirty state from events outside of the directive
            scope.$watch('reset', function(new_reset, old_reset) {
                $log.log('Inline Input Reset Watch Reset', new_reset);
                $log.log('Inline Input Reset Watch Old Reset', old_reset);
                if(new_reset && new_reset !== old_reset) {
                    // Reset the dirty state
                    scope.dirty = false;

                    // update the model and remove errors
                    original_model = scope.model;
                    current_model = scope.model;
                    scope.error = false;
                }
            }, true);

            // we check if the first value we get is valid
            checkValidity();
        }
    }
})();

