/**
 * @ngdoc object
 * @name VendorsV2Ctrl
 * @requires $log, $stateParams, signalProvider
 */


(function(){
'use strict';

angular.module('pulse')
  .controller('VendorsV2Ctrl',
  ['$log', '$scope', 'VendorsService',
  function($log, $scope, VendorsService){
    var ctrl = this;

    $scope.vm = {
      context_key: 'vendors_list',
      vendors_service: VendorsService,
    };

    ctrl.$onInit = function(){
      $log.log('onInit - VendorsV2Ctrl', $scope.vm);
      // set the context first before initializing
      //VendorsService.setContext(service.context_key, service.context_val);
      //$log.log('DIPullsService', DIPullsService);
      VendorsService.init('vendors_list');
      // VendorsService.initServer('vendors_list');

    }


  }]);

})();
