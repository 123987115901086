/**
 * @ngdoc object
 * @name HttpInterceptor
 * @requires $resource
 */

'use strict';

angular.module('pulse')
    .config(['$provide', '$httpProvider', function ($provide, $httpProvider) {

        // Intercept http calls.
        $provide.factory('HttpInterceptor',
            ['$q', '$cookies', '$rootScope', '$signalProvider', '$log', '$localStorage',
                function ($q, $cookies, $rootScope, $signalProvider, $log, $localStorage) {
                    return {

                        // On request success
                        request: function (config) {
                            // Return the config or wrap it in a promise if blank.

                            //TODO: Not sure if this is the best way to do this but i see no other way currently.
                            if(config.method === 'DELETE' && typeof $cookies['XSRF-TOKEN'] !== 'undefined') {
                                config.headers['X-CSRF-Token'] = $cookies['XSRF-TOKEN'];
                            }

                            return $q.when(config);
                        },

                        // // On request failure
                        // requestError: function (rejection) {
                        //   //$log.log(rejection); // Contains the data about the error on the request.

                        //   // Return the promise rejection.
                        //   return $q.reject(rejection);
                        // },

                        // On response success
                        response: function (response) {

                            //$log.log('Response');
                            //TODO: Not sure if this is the best way to do this but i see no other way currently.
                            if(typeof $cookies['XSRF-TOKEN'] !== 'undefined') {
                                //$log.log('Cookie Set -- Now check validity');
                                $httpProvider.defaults.headers.post['X-CSRF-Token'] = $cookies['XSRF-TOKEN'];
                            }

                            return $q.when(response);
                        },

                        // On response failture
                        responseError: function (rejection) {
                            $log.log(rejection); // Contains the data about the error.

                            //If i wanted to return specific request errors we could return it here.

                            //Any header that we dont like will redirect to the header from here.
                            // 401 is unauthorized, so the user does not have access to the endpoint
                            if(rejection.status === 401) {     

                                if (config && config.application_name !== 'landing') {
                                    localStorage.setItem('previousAttemptURL', window.location.href);
                                    window.location.href = '/landing/#/login?loggedOut=true';
                                }

                                //window.stop(); //This is suppose to stop all other requests
                                return;
                            }
                                
                            // show 403 modal if user page transistion is completed and 403 is returned
                            if(rejection.status === 403) {
                                $log.error('Error Resolving Modal -- 403', rejection);

                                // ignore 403 response of keepalive as we want to logged out the user after their timer ends
                                // as we don't want an overlapping 403 modal over the timer modal
                                if (rejection.config && rejection.config.url.indexOf('keepalive') === -1) {
                                    $signalProvider.signal("resolveError", rejection);
                                }

                                return $q.reject(rejection);
                            }

                            // no need to check $rootScope.resolving as few pages when transition init step (onStart) will be 404
                            if (rejection.status == 404) {
                                $log.error('Error Resolving Modal -- 404');

                                if (config && config.application_name !== 'landing') {
                                    $signalProvider.signal("resolveError", rejection);
                                }

                                return;
                            }

                            // if user got offline or server got timed out
                            if (navigator.onLine === false || rejection.status === 504 || rejection.status === 502) {
                                $log.error('Error Resolving Modal -- 504 or offline');

                                if (config && config.application_name !== 'landing') {
                                    $signalProvider.signal("showOfflineModal", rejection);
                                }
                                return $q.reject(rejection);
                            }

                            if (rejection.status >= 500 ) {
                                $log.error("Server Error: PULSE is in maintanance mode", rejection);
                                
                                // stop showing server failure signal over landing app and keepalive api calls
                                if (config && config.application_name !== 'landing' && (rejection.config && rejection.config.url.indexOf('keepalive') === -1)) {
                                    $signalProvider.signal('showServerErrorSignal', 'Sorry, something went wrong. Please try again in a few moments.');
                                }

                            }

                            // Return the promise rejection.
                            return $q.reject(rejection); // This will only display things from data object

                        }

                    };

                }]);

        // Add the interceptor to the $httpProvider.
        $httpProvider.interceptors.push('HttpInterceptor');

    }]);
