'use strict';

/* Common Services: Helper */

angular.module('pulse')
  .factory('HelperSvc',
  [
    function () {
      return {
        GetListIndex: function(list, item){
          for(var i = 0; i < list.length; i++){
            if(list[i].id === item.id){
              return i;
            }
          }
        }
      };
    }
  ]
);
