/**
 * @ngdoc object
 * @name PulseSearchBarComponent
 * @requires $state
 * @description A resuable compent for searching the Pulse database
 */

(function(){
  'use strict';

  angular.module('pulse').component('pulseSearchBar', {
    templateUrl : 'views/common/components/pulse_search_bar.html',
    bindings : {
      'projectId': '<',
      'searchActions': '<',
      'searchInput': '<',
      'searchPlaceholder': '<',
      'isLoading': '<',
      'errorMessage': '<',
      'searchEndpoint': '<'},
    controller : Controller
  });
  Controller.$inject = ['$log', '$state'];

  function Controller($log, $state){
    var ctrl = this;

    ctrl.vm = {
      loading: false,
      required_attr: false,
      search_input: '',
      searchData: searchData
    };

    ctrl.$onInit = function(){
      checkAttr();
      searchData();
    }

    /// add function below
    function checkAttr(){
      var is_funct = typeof(ctrl.searchEndpoint)
      if (is_funct === 'function'){
        ctrl.vm.required_attr = true
      }else {
        ctrl.errorMessage = 'Sorry this Component requires the search-enpoint attribute'
      }
    }


    function searchData(){
      $log.log('searchData');
      ctrl.vm.loading = true;
      ctrl.searchEndpoint(ctrl.searchInput);
      ctrl.vm.loading = false;
    }

  }
})();
