/* global AWS */
'use strict';

/*
    - File Uploaders Module
    --- LADC Upload Service responsible for managing the upload process of 1 or more files to a bucket
    --- Upload Allocator (PULSE Notification) Service for obtaining assetID and notifying the backend of upload completion

*/
angular.module('pulse.ladc_uploaders', [])

.factory('uploadAllocator', ['$resource', uploadAllocator ])

.factory('ladc_Upload', ['$log', '$timeout', '$interval', '$signalProvider', '$q', '$rootScope', 'uploadAllocator', '$idle', '$keepalive', 'Upload',  ladc_Upload]);

/*.factory('uploadAllocatorConfig', [uploadAllocatorConfig])*/
/*.directive('fileSelectGeneric', ['$log', FileSelectGeneric])*/


/*
    LADC Upload Service
*/
function ladc_Upload($log, $timeout, $interval, $signalProvider, $q, $rootScope, uploadAllocator, $idle, $keepalive, Upload) {

  this.instance = null; //singleton

  //prototype based object constructor factory
  var construct = function(constructor, args) {
    var obj = Object.create(constructor.prototype);
    constructor.apply(obj, args);
    return obj;
  }

  //single upload-in-progress object constructor
  var uploadInProgress = function(initialInfo, projectID, finalizeData) {

    this.scope = null;

    //this.awsSecurityInfo = null;

    this.projectID = projectID;

    this.finalizeData = finalizeData;

    this.file = initialInfo;

    this.id = initialInfo.name + "_" + new Date().getTime();

    this.fileName = initialInfo.name;

    this.type = initialInfo.type;

    this.size = initialInfo.size;

    this.lastModified = initialInfo.lastModified;

    this.managedUpload = null;

    this.loaded = null;

    this.total = null;

    this.percentComplete = null;

    this.status = null;

    this.errors = [];

    this.aborted = false; //upload aborted flag

    this.done = false; //upload complete flag

    this.doneDate = null; //date & time of upload completion

    this.hidden = false; //UI-only flag to show/hide specific upload records
  }

  /**
   * Report on progress of a given uploadInProgress instance
   */
  uploadInProgress.prototype.updateProgress = function(uploadProgressEvent) {
    $log.log('PULSE Upload Progress for ', this.fileName, ': ', uploadProgressEvent.loaded, '/', uploadProgressEvent.total);
    this.loaded = uploadProgressEvent.loaded;
    this.total = (uploadProgressEvent.total && uploadProgressEvent.total > 0 ? uploadProgressEvent.total : -1);
    this.percentComplete = Math.floor( (this.total > 0 ? ((this.loaded / this.total) * 100) : ((this.loaded / this.size) * 100) ) );
    this.percentComplete = Math.min(this.percentComplete, 100);
    if(!this.scope.$$phase) {
        this.scope.$digest();
    }
  }

  /**
   * Either complete or error-out an upload in progress
   */
  uploadInProgress.prototype.updateStatus = function(error, data) {
    $log.log('PULSE Upload Status for ', this.fileName, " (errror / data): ", (error ? error : "Upload Complete", data));

    if (!!error) {
        this.errors.push(error);

        if (this.errors.length === 1) { //report this file's upload failure once
            $signalProvider.signal('s3UploadFailure', {file: this.file, response: error, error: 'Upload Failed or Skipped.'});
        }
    }
    else {
        if (this.restarted) { //clear any errors for a restarted/retried upload
             $signalProvider.signal('s3UploadRecovery', {file: this.file, response: data});
        }
        this.errors = [];
        this.status = data;
        this.done = true;
        this.aborted = false; //even if user attempted to abort, but the file still had time to finish, remove the abort flag.
        this.doneDate = new Date().getTime();
        if(!this.scope.$$phase) {
            this.scope.$digest();
        }
        //this.finalizeUpload(); //inform PULSE that an S3 Upload is done
    }
  }

  /**
   * Notify the PULSE backend of a single upload completion
   */
  uploadInProgress.prototype.finalizeUpload = function(){
        var self = this;
        var data = {};
        data.projectID = this.projectID;
        //data.assetID = this.awsSecurityInfo.assetID;

        angular.extend(data, this.finalizeData);

        var promise = uploadAllocator.service.finish(data).$promise;
        promise.then(function(data) {
            $log.info('PULSE - upload successfully finalized', self);
            $log.info('PULSE Finalize feedback:', data);
            $rootScope.storage.uploads.push(data); //this is used by other features of the apps, e.g. to organize files
        },
        function(error){ //Error finishing
            $log.info('PULSE - upload finalize failed', error);
        });
    }
  //========================================================

  // S3 Uploader Constructor
  var uploader = function(scope, allocationEndPoint, projectID) {

    this.scope = scope; //The consumer's scope.

    this.projectID = projectID;

    uploadAllocator.configure(allocationEndPoint.endPoint, allocationEndPoint.ext);

    this.endPoint = allocationEndPoint.endPoint;

    this.ext = allocationEndPoint.ext;

    // this.s3 = new AWS.S3( //our S3 instance with logging enabled
    //     {
    //         logger: $log
    //     }
    // );

    this.uploads = []; //1 or more instances of uploadInProgress class

    this.configuration = {};

    this.maxFileSize = null; //Do we want to enforce a max file size?

    this.queueSize = 4; //upload concurrency control default

    this.maxPartsConcurrency = 6;

    this.disposing = false;

    this.keepAliveTimerStop = null; //when keepAlive starts, the ID will be stored here for proper termination

    this.keepAliveInterval = 180000; //default keepAlive pings to every 3 minutes while uploading

    this.additionalFinalizeData = {};
  }

  /*
    Any global configuration is optional (concurrency and part size management can be configured on the upload itself)
  */
  uploader.prototype.configure = function(configuration) {
    //AWS.config.update(configuration);
  }


  /**
   * Add files to the uploads queue, and begin processing them
   */
  uploader.prototype.addFiles = function(files) {

    var startUploadIndex = 0;

    var previousItemCount = this.uploads.length;

    for (var n=0; n<files.length; n++) { //create bare upload records so the UI reflects the 'pending' uploads
        if (!!files[n].type && files[n].type.toLowerCase() === 'directory') {
        }
        else if (files[n].size <= 0) {
            $signalProvider.signal('s3UploadFailure', {file: files[n], response: null, error: 'Zero (0) length files are not allowed.'});
        }
        else {
            var thisUpload = construct(uploadInProgress, [files[n], this.projectID, this.additionalFinalizeData]); //new instance of uploadInProgress class

            var newCount = this.uploads.push(thisUpload);

            if (n==0 && newCount > 1) { //we are adding more files to an existing list of uploads, so we have to start at the new index
                startUploadIndex = --newCount;
            }
        }
    }

    if(!this.scope.$$phase) {
        this.scope.$digest();
    }

    if (this.uploads.length > previousItemCount) { //we've added at least one new upload item and updated the start index
        this.queueUploads(this.uploads, startUploadIndex);

        if (!this.keepAliveTimerStop) {
            this.keepAlive(true);
        }
    }
  }

  //Queue up the uploads to the maximum of the uploader's configured queue size
  uploader.prototype.queueUploads = function(uploadsArray, startIndex) {
        if (uploadsArray && uploadsArray.length && startIndex < uploadsArray.length) {
            var self = this;
            if (this.getInProgressUploadsCount(true) > this.queueSize) { //still too many uploads at the moment to init another one
                $timeout(function() { //retry in a sec
                    self.queueUploads(uploadsArray, startIndex)
                }, 1000);
            }
            else { //ready for another upload
                self.allocateUpload(uploadsArray[startIndex]).then(function (parameters){
                        if (parameters) {
                            self.upload(uploadsArray[startIndex], parameters);
                        }
                        self.queueUploads(uploadsArray, ++startIndex);
                });
            }
        }
    }

  /*
    AWS.S3.upload abstraction
    Enables much more robust upload operations, because if uploading of a single part fails, that individual part can be
    retried separately without requiring the whole payload to be resent.
    Multiple parts can be queued and sent in parallel, allowing for much faster uploads when enough bandwidth is available.
    Most importantly, due to the way the managed uploader buffers data in memory using multiple parts, this abstraction does
    not need to know the full size of the stream, even though Amazon S3 typically requires a content length when uploading data.
    Important Note: In order to support large file uploads in the browser, you must ensure that your CORS configuration exposes
    the ETag header; otherwise, your multipart uploads will not succeed.
    *
    *
    upload(params = {}, [options], [callback]) ⇒ AWS.S3.ManagedUpload
    Uploads an arbitrarily sized buffer, blob, or stream, using intelligent concurrent handling of parts if the payload is large enough.
    http://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#upload-property
  */
  uploader.prototype.upload = function(thisUpload, parameters) {

    if (thisUpload.aborted) { //Cancel-All-Uploads operation may set this flag on upload record(s) that haven't been queued yet
        return null;
    }

    $rootScope.uploading.status = true; //this appears to be the opposite of the 'done' flag. When true, at least one upload still in progress. WTF???
    $rootScope.uploading.done = false; //set to true when ALL uploads have been completed. Reset to false whenever adding additional uploads.
    $rootScope.uploading.reset = false; //this looks like a signaller that the user wants to reset everything Throw away all uploads, the view will reflect...

    thisUpload.allocateInfo = parameters;
    thisUpload.scope = this.scope;


    if(!this.scope.$$phase) {
        this.scope.$digest();
    }

    // var s3Config = { //security configuration
    //     accessKeyId: parameters.AWSAccessKeyId,
    //     secretAccessKey: parameters.SecretAccessKey
    // };

    // if (!!parameters.SessionToken) {
    //     s3Config.sessionToken = parameters.SessionToken;
    // }

    //this.s3.config.update(s3Config);

    var params = {
        //Bucket: parameters.bucket, //e.g 'pulse-test'
        //Key: parameters.key, //essentially the file path
        Body: thisUpload.file //the file content
    };

    var initUpload = function(thisUpload, uploader) {

        // thisUpload.managedUpload = uploader.s3.upload(params); //, {queueSize: uploader.maxPartsConcurrency}); //create a AWS Managed Upload object
        //
        // thisUpload.managedUpload.on('httpUploadProgress', function(progressEvent) { //handle progress update events
        //     thisUpload.updateProgress(progressEvent);
        // });
        //
        // thisUpload.managedUpload.send(function(error, data) { //initiate the upload, and setup a callback handler
        //     thisUpload.updateStatus(error, data);
        //     uploader.checkIfDone(); //check if we are done with all uploads
        // });

        var endPointCopy = angular.copy(uploader.endPoint);

        endPointCopy = endPointCopy.replace(/:projectID/g, uploader.projectID);
        endPointCopy = endPointCopy.replace(/:ext/g, uploader.ext);
        endPointCopy = endPointCopy.replace(/:assetID/g, thisUpload.allocateInfo.assetID);
        endPointCopy = endPointCopy.replace(/:action/g, 'finish');

        thisUpload.managedUpload = Upload.upload({
            url: config.baseURL + endPointCopy,
            method: 'POST',
            withCredentials: true,
            'file': thisUpload.file
        });

        thisUpload.managedUpload.progress(function (progressEvent) {
            $log.log('progressEvent');
            $log.log(progressEvent);
            thisUpload.updateProgress(progressEvent);
        });

        thisUpload.managedUpload.success(function (data) {
            $log.log('Success');
            $log.log(data);
            $rootScope.uploading.done = true; // We have finished uploading so we are now done
            $rootScope.uploading.status = false; // We are currently not uploading any more
            thisUpload.updateStatus(false, data);
            uploader.checkIfDone(); //check if we are done with all uploads

        });

        thisUpload.managedUpload.error(function (error) {
            $log.error('Error status');
            $log.error(error);
            $rootScope.uploading.done = true; // We have finished uploading so we are now done
            $rootScope.uploading.status = false; // We are currently not uploading any more
            thisUpload.updateStatus(error, data);
            uploader.checkIfDone(); //check if we are done with all uploads
        });

    }

    initUpload(thisUpload, this);
  }

  //Allocate an upload to retrieve allocate data
  uploader.prototype.allocateUpload = function(upload) {
        var deferred = $q.defer();

        var allocatePromise = uploadAllocator.service.allocate(
            {
                projectID: this.projectID,
                'name': upload.fileName,
                'size': upload.size
            }
        ).$promise;

        allocatePromise.then(
            function(response) {
                $log.log('Allocate successfull for ', upload, ' with response ', response);
                deferred.resolve(response);
            },
            function(error){
                $log.warn('Allocate failed for ', upload, ' with response ', error);

                //Notify of allocate failures
                $signalProvider.signal('s3UploadFailure', {file: upload.file, response: error, error: 'Unable to start upload (allocation failed)'});

                deferred.resolve(null);
            }
        );

        return deferred.promise;
    }


    /**
     * Prevent auto-logout while uploading is in progress
     */
    uploader.prototype.keepAlive = function(start) {
        if (start) {
            this.keepAliveTimerStop = $interval(function() {
                $keepalive.ping();
                $idle.watch();
            }, this.keepAliveInterval); //reset every N seconds
        }
        else { //stop the keepAlive pings
            $interval.cancel(this.keepAliveTimerStop);
            this.keepAliveTimerStop = null;
        }
    }


    uploader.prototype.addFinalizeData = function(data) {
        this.additionalFinalizeData = data;
    }

    /**
     * Notify PULSE backend that the scheduled uploads have been completed.
     */
    uploader.prototype.finalizeAll = function(){
        var assets = [];
        for(var x = 0; x < this.uploads.length; x++) {
            var thisUpload = this.uploads[x];
            if(thisUpload.done && !thisUpload.aborted && !thisUpload.errors.length && !!thisUpload.awsSecurityInfo){
                assets.push(thisUpload.awsSecurityInfo.assetID);
            }
        }

        var promise = uploadAllocator.service.completed({
            projectID: this.projectID,
            assetIDs:  assets //assets
        }).$promise;

        promise.then(function(data) {
                $log.info('PULSE - All Uploads Completed', data);
            },
            function(error){ //Error completing
                $log.warn('PULSE - FinalizeAll Uploads Completion Failed', error);
                $signalProvider.signal('feedback', [ 'failure', 'FileComplete']);
            });
    }

  /*
    Check if all uploads have been completed or errored or aborted.
    If all done, set the required rootscope flag(s)
  */
  uploader.prototype.checkIfDone = function() {
    var done = true;
    for (var n=0; n<this.uploads.length; n++) {
        if (!this.uploads[n].aborted && !this.uploads[n].done && !this.uploads[n].errors.length) {
            done = false;
            break;
        }
    }

    $rootScope.uploading.done = done;
    if (done) {
        var self = this;
        $rootScope.uploading.status = false; //another seemingly highly unnecessary flag...
        self.keepAlive(false); //stop the keepAlive pings
        $timeout(function() {
            self.finalizeAll(); //Inform the PULSE backend that all uploads are done
        }, 300);
    }

    return done;
  }

  /**
   * Report on overall (all upload instances) progress
   */
  uploader.prototype.totalProgress = function() {
      return this.getAbortedUploadsCount(true) + this.getFinishedUploadsCount();
  }

  /*
    Get the count of uploads that have been canceled (aborted) by the user
  */
  uploader.prototype.getAbortedUploadsCount = function(includeHidden) {
      var count = 0;
      for (var n=0; n<this.uploads.length; n++) {
          if (this.uploads[n].aborted && (includeHidden || !this.uploads[n].hidden)) {
              count += 1;
          }
      }
      return count;
  }

  /*
    Get the count of uploads that are done
  */
  uploader.prototype.getFinishedUploadsCount = function() {
    var count = 0;
    for (var n=0; n<this.uploads.length; n++) {
        if (this.uploads[n].done) {
            count += 1;
        }
    }
    return count;
  }

  /*
    Get the count of uploads that are still in progress (not errored out or aborted or done)
  */
  uploader.prototype.getInProgressUploadsCount = function(skipPending) {
    var count = 0;
    if (!skipPending) {
        for (var n=0; n<this.uploads.length; n++) {
            if (!this.uploads[n].aborted && !this.uploads[n].done && !this.uploads[n].errors.length) {
                count += 1;
            }
        }
    }
    else {
        for (var n=0; n<this.uploads.length; n++) {
            if (!!this.uploads[n].managedUpload && !this.uploads[n].aborted && !this.uploads[n].done && !this.uploads[n].errors.length) {
                count += 1;
            }
        }
    }
    return count;
  }

  /*
    Retry all uploads that have errors or have been aborted by the user
  */
  uploader.prototype.retryAll= function() {
    for (var n=0; n<this.uploads.length; n++) {
        if ( (this.uploads[n].errors.length || this.uploads[n].aborted) && (!this.uploads[n].done) && (!this.uploads[n].hidden) ) { //only retry errored or aborted uploads that aren't hidden
            this.restart(this.uploads[n]);
        }
    }
  }

  /*
    Cancel (abort) all uploads that are currently in progress or ready to be queued
  */
  uploader.prototype.cancelAll = function() {
    for (var n=0; n<this.uploads.length; n++) {
        this.abort(this.uploads[n]);
    }
  }

  /*
    Remove all 'done' items from the view
  */
  uploader.prototype.hideCompleted = function() {

    for (var n=0; n<this.uploads.length; n++) {
        if (this.uploads[n].done) {
            this.uploads[n].hidden = true;
        }
    }

    /*var toRemove = [];
    for (var n=0; n<this.uploads.length; n++) {
        var thisUpload = this.uploads[n];
        toRemove.push(thisUpload); }
    for (var n=0; n<toRemove.length; n++) {
        if (toRemove[n].done) {
            this.remove(toRemove[n]); } }
    toRemove = void(0);*/
  }

  /*
    Aborts a multipart upload.To verify that all parts have been removed, so you don't get charged for
    the part storage, you should call the List Parts operation and ensure the parts list is empty.
  */
  uploader.prototype.abort = function(uploadItem) {
    if (!uploadItem.aborted && !uploadItem.done) {
        uploadItem.aborted = true;
        // if (uploadItem.managedUpload) {
        //     uploadItem.managedUpload.abort();
        // }
    }
    this.checkIfDone(); //check if we are now done with all uploads and need to update the rootScope flag(s)
    return uploadItem;
  }

  /*
    Clean up the abort / error flags and initiate a send() on the managedUpload
  */
  uploader.prototype.restart = function(uploadItem) {
    uploadItem.restarted = true;
    uploadItem.aborted = false;
    uploadItem.errors = [];
    uploadItem.percentComplete = 0;
    uploadItem.loaded = 0;
    uploadItem.total = 0;
    uploadItem.status = null;
    uploadItem.done = false;
    uploadItem.doneDate = null;
    /*if (uploadItem.managedUpload) { //restart a previously queued upload
        uploadItem.managedUpload.send(function(error, data) { //initiate the upload
            uploadItem.updateStatus(error, data);
        });
    }
    else { //the upload was never queued */
    var self = this;
    this.allocateUpload(uploadItem).then(function (parameters){
        if (parameters) {
            self.upload(uploadItem, parameters);
        }
    });

    return uploadItem;
  }

  /*
    Remove an upload item completely from our list of uploads
  */
  uploader.prototype.remove = function(uploadItem) {
    uploadItem.hidden = true;
  }

  /**
   * Clean-up
   */
  uploader.prototype.dispose = function() {
    this.disposing = true;
    $log.log('Disposing of uploader', this);
    $interval.cancel(this.keepAliveTimerStop);
  }

  /**
   * Retrieve any and all errors on any of the uploads processed by the uploader
   */
  uploader.prototype.getAllErrors = function() {
    var errors = [];
    for (var n=0; n<this.uploads.length; n++) {
        errors = errors.concat(this.uploads[n].errors);
    }
    return errors;
  }

  /**
   * Clean-up all uploads
   */
  uploader.prototype.reset = function() {
    this.cancelAll();
    this.additionalFinalizeData = {};
    $rootScope.storage.uploads = [];
    $rootScope.uploading.status = false;
    $rootScope.uploading.done = true;
    this.uploads = [];
    this.keepAlive(false);
 }

  var self = this;
  //the service object
  return {
    getUploader: function(scope, allocationEndPoint, projectID) {
        if (!!self.instance) {
            return self.instance;
        }
        else {
            self.instance = construct(uploader, [scope, allocationEndPoint, projectID]);
            return self.instance;
        }
    },
    dispose: function() {
        if (!!self.instance) {
            self.instance.reset(); //reset and kill the instance, if one exists
            delete self.instance;
        }
    }
  }
}


/**
 * PULSE upload allocation and finalization services
 */
function uploadAllocator($resource) {

    return {
      service: null,

      configure: function(endPoint, ext) {
          this.service = $resource(
            config.baseURL + endPoint,
            {projectID: '@projectID', ext: '@ext', assetID: '@assetID',action: '@action'},
            {
                allocate: {method:'POST', params: {ext:ext}}, //pass project
                finish: {method:'POST', params: {ext:ext, action:'finish'}}, // pass project and asset
                completed: {method:'POST', params: {ext:ext, action:'completed'}} // Once all uploads have been complete pass project -- need to pass an array of assetIDs
        });
      }
    }
}




/**
 * Configuration service for the PULSE allocation services
 */
/*function uploadAllocatorConfig() {
    return {
        setConfig: function(newEndPoint, ext) {
            this.endPoint = newEndPoint;
            this.ext = ext;
        },

        getEndPoint: function() {
            return this.endPoint;
        },

        getExt: function() {
            return this.ext;
        }
    }
}*/


/*//generic file select directive - not necessary at the moment
var FileSelectGeneric = function($log) {
  return {
    restrict: 'AE',
    scope: {
      file: '@'
    },
    link: function(scope, el, attrs){
      el.bind('change', function(event){
        var files = event.target.files;
        var file = files[0];
        scope.file = file;
      });
    }
  };
};*/
