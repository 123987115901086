'use strict';

angular.module('pulse')
.filter('paddednumber', function () {
    return function (number, padding) {
		var number = Math.round(number);
	    var output = number + '';

	    while (output.length < padding) {
	        output = '0' + output;
	    }

	    return output;
	};
});
