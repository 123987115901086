/**
 * @ngdoc directive
 * @name pulseComboboxMultipleSelect
 * @module pulse
 * @restrict E
 * @description
 * `pulseComboboxMultipleSelect` this directive allows filtering on a list and the ability to add multiple objects at an array.
 * The items in the array are isolated and are copies of the actual listing.
 * In order to update listings you need to update the master list, which is watched by this directive
 *
 * @usage
  <pulse-combobox-multiple-select
    ng-if="vm.service.Pulls.vendors_list.vendors_obj.length && vm.service.Pulls.pull_event_list.event_obj.status==='draft'"
    callback="vm.service.Pulls.updateEventVendors"
    ng-model="array"
    list="vm.service.Pulls.vendors_list.vendors_obj"
    placeholder="Vendors"
    template="views/common/combobox/pulse_combobox_multiple_select_clear_all.html"
    reset="vm.service[init.service][init.context_key].loading"
    sort-by="name"
  ></pulse-combobox-multiple-select>
 *
 *
 * @param {array} list - List for the input to search from
 * @param {object} ngModel - must be an array, the model that the selected data is pushed too
 * @param {int} (optional) limit - limit the number of items shown in drop down
 * @param {string} (optional) placeholder - text for the input placeholder
 * @param {string} (optional) sortBy - tells the list what expression to order by
 * @param {function} (optional) onSubmit - a callback function after all selections have been made and the menu closes
 * @param {object} (optional) onSelect - a callback function that runs after an item has been selected
 * @param {boolean} (optional) reset - a flag to reset the dirty state and to reload the original model caching
 * @param {path} (optional) template - you can add a custom template to this directive
 * @param {string} contextKey - (optional) the context key to manage the state
 *
 *
 * //TODO: maybe make keyboard short cuts for up and down arrows and enter key
 *
 */

(function() {

  'use strict';

  angular
    .module('pulse')
    .directive('pulseComboboxMultipleSelect', pulseComboboxMultipleSelect);

  pulseComboboxMultipleSelect.$inject = ['$log', '$timeout', '$filter', '$rootScope'];

  function pulseComboboxMultipleSelect($log, $timeout, $filter, $rootScope) {

    var directive = {
      restrict: 'E',
      templateUrl: function(el, attr){
        if(typeof attr.template !== 'undefined'){
          return attr.template;
        }else{
          return 'views/common/combobox/pulse_combobox_multiple_select.html';
        }
      },
      scope: {
        list: '=',
        ngModel:'=',
        limit: '=?',
        placeholder: '@',
        sortBy: '@',
        onSubmit: '=',
        onSelect: '=',
        reset: '=?',
        contextKey: '@',
        isDropupList: '=',
      },
      link: link,
      controller: Controller
    };

    return directive;

    function link(scope, element, attrs) {

      var SPEC_SHEET_ID = 'specSheets';
      var isSpecSheet = false;

      if ($rootScope.vfxsetup && $rootScope.vfxsetup.generateSpecSheets) {
        isSpecSheet = $rootScope.vfxsetup.generateSpecSheets;
      }

      //Developer warnings incase they do something weird
      if(scope.ngModel && !Array.isArray(scope.ngModel)) {
         $log.warn('pulseComboboxMultipleSelect callback should be an array');
         scope.ngModel = [];
      }
      if(scope.onSubmit && typeof scope.onSubmit !== 'function') {
        $log.warn('pulseComboboxMultipleSelect onSubmit should be a function');
      }

      //If there is no placeholder we will just have it say "-- Search --"
      scope.placeholder = scope.placeholder ? scope.placeholder : '';

      //If there is no orderby is set, we just default to "name"
      scope.orderby = scope.sortBy ? scope.sortBy : 'name';

      //Lets load in a ton of items unless we specify a smaller amount show them a message
      scope.limit = isNaN(scope.limit) ? 9999 : scope.limit;

      //Always come in with scope dirty as false
      scope.dirty = false;

      //Always come in with scope reset as false
      scope.reset = false;

      // We are creating out own instance of the model so that we can tell when someone dirties the existing model
      var original_model = angular.copy(scope.ngModel);

      //Find the input for focusing, used in the toggleDropdown function
      var input = element.find('input');

      //Hoist and init directive
      init();

      // selecting an item from the list
      scope.selectItem = function(item, event){
        $log.log('selectItem', item, event);

        //Stop all other events! When the input is focused or clicked we are going to open the list
        event.preventDefault();
        event.stopPropagation();

        //Find the index index if the item already exists in the model
        var found_item_index = scope.findSelectedIndex(item);

        //since we copy the array, we can modify it how we see fit
        if(typeof found_item_index !== 'undefined') {
          $log.log('Item Remove from array. Item:', item);
          scope.ngModel.splice(found_item_index, 1);
        }else{
          $log.log('Item Added to array. Item:', item);
          scope.ngModel.push(item);
        }

        $log.log('Selected Array Items:', scope.ngModel);

        //Sometimes we need a callback for items after they have been selected
        if(scope.onSelect) {
          scope.onSelect(item, scope.contextKey);
        }
      };

      // selecting all items from the list
      scope.selectAllItems = function(event){
        $log.log('selectAllItems', event);

        //Stop all other events! When the input is focused or clicked we are going to open the list
        event.preventDefault();
        event.stopPropagation();

        scope.ngModel = angular.copy(scope.list);
      };

      // selecting all items from the list
      scope.clearAllItems = function(event){
        $log.log('clearAllItems', event);

        //Stop all other events! When the input is focused or clicked we are going to open the list
        event.preventDefault();
        event.stopPropagation();

        scope.ngModel = [];
      };

      // saving items from the list
      scope.save = function(){
        // we update the original model to be the saved model
        original_model = angular.copy(scope.ngModel);
        //save items to the model
        if(scope.onSubmit) {
          var selected = angular.copy(scope.ngModel);
          scope.onSubmit(selected, scope, scope.contextKey);

        }
        
      };


      scope.clearFilter = function (event) {
        event.preventDefault();
        event.stopPropagation();

        // clear user selecttion
        scope.ngModel = [];
        original_model = [];
        scope.dirty = false;

        setTimeout(function () {
          if (scope.onSubmit) {
            scope.onSubmit(scope.ngModel, scope, scope.contextKey);
          }
        }, 300);
      };

      // cacnceling the selected items
      scope.cancel = function(event){
        $log.log('cancel', event);

        //Reset the dirty state
        scope.dirty = false;

        // We return the model to the original value or empty state
        if (original_model) {
          scope.ngModel = angular.copy(original_model);
        } else {
          scope.ngModel = [];
        }

      };

      //Whenever we want to disable the dropdown we just run this function and that element
      scope.disableToggleDropdown = function(event) {
        event.preventDefault();
        event.stopPropagation();
      };

      //Toggles the dropdown on focus and click from the input
      scope.toggleDropdown = function(event) {

        $log.log('toggleDropdown', event);

        if ($("#dropdown__menu").is(":visible")) {
            $("#dropdown__menu").removeClass('open');
        }

        //Stop all other events! When the input is focused or clicked we are going to open the list
        event.preventDefault();
        event.stopPropagation();

        //We make the status open, this is for  bootstraps dropdown maybe?
        //TODO: What is really using this?
        scope.status.isopen = !scope.status.isopen;

        //We need to clear out the search if we are toggling the dropdown
        scope.search_filter = '';

        //Events fire too fast and we need a second to focus on the input
        $timeout(function() {
          input.focus();

          //Check to see if we should drop up
          if (scope.isDropupList) {
            scope.dropup = isDropup(event);
          }

        }, 100);

      };

      //This finds the index of ngModel items, helps with selection within the DOM and for selection updates
      scope.findSelectedIndex = function(item) {
        if(scope.ngModel) {
          var selected_length = scope.ngModel.length;
          for(var selected_index = 0; selected_length > selected_index; selected_index++) {
            if(item.id === scope.ngModel[selected_index].id) {
              return selected_index; // Return the index
            }
          }
        }
      };

      //private function that determines with the drop down should drop up
      function isDropup(event) {

        $log.log('check isDropup');

        //Reset the dropup incase it has already been set
        scope.dropup = false;

        /** @type HTMLElement */
        var vendorEl = event.target.closest('#vendor-inline-dropdown');

        /** @type HTMLElement */
        var vendorOptions = element.find("#multiple-select-options");

        if (!vendorEl || !vendorOptions) {
          $log.warn('Target elements not found', vendorEl, vendorOptions);
          return;
        }

        var vendorRect   = vendorEl.getBoundingClientRect();
        var windowHeight = window.innerHeight;

        var dropdownContainer = $(vendorEl).find("#multiple-select-container");

        if (!dropdownContainer) {
          $log.warn('Dropdown containe el not found');
          return;
        }

        if (vendorRect.bottom + vendorOptions.height() > windowHeight) {
          $log.log('Not enough space below, position above the input');

          return true;

        }

        $log.log('Enough space below, position below the input');
        return false;

      }

      function init() {
        $log.log('Init Combobox Multiple Select', original_model);
        $log.log(scope);

        if ($rootScope.vfxsetup && $rootScope.vfxsetup.generateSpecSheets) {
          isSpecSheet = $rootScope.vfxsetup.generateSpecSheets;
        }

        // We need to create our own instance of the list so we dont have name space issues
        // check isSpec Sheet is disabled in the project
        if (!isSpecSheet && scope.list) {
          for (var i = 0; i < scope.list.length; i++) {
            if (scope.list[i].id === SPEC_SHEET_ID) {
              // remove the spec sheet option
              scope.list.splice(i, 1);
              break;
            }
          }
        }


        scope.items = angular.copy(scope.list);

      }

      //We need to watch the master list in case it gets updated and we need to create new cached data
      scope.$watchCollection('list', function(new_list, old_list) {
        $log.log('Combobox Multiple Select Watch Collection', new_list, old_list);
        if(new_list && new_list !== old_list) {
          init();
        }
      }, true);

      //We need to watch the model because if we change the model and we need to determine if the data is dirty
      scope.$watch('ngModel', function(new_model, old_model) {
        $log.log('Combobox Multiple Select Watch Model', new_model);
        $log.log('Combobox Multiple Select Watch Old Model', old_model);
        $log.log('Combobox Multiple Select Watch Clean Model',  original_model);

        //Sort the objects so when we compare it is comparing correctly

        new_model = $filter('orderBy')(new_model, scope.orderby);
        old_model = $filter('orderBy')(old_model, scope.orderby);

        if(new_model && !angular.equals(new_model, old_model)) {
          //Set the dirty flag, we arent tracking if they have the same information but if they
          if(!angular.equals(new_model, original_model)) {
            scope.dirty = true;
          }else{
            scope.dirty = false;
          }
        }
      }, true);

      //Sometimes we need to clear the dirty state from events outside of the directive
      scope.$watch('reset', function(new_reset, old_reset) {
        $log.log('Combobox Multiple Select Watch Model', new_reset);
        $log.log('Combobox Multiple Select Watch Old Model', old_reset);
        if(new_reset && new_reset !== old_reset) {

          //Reset the dirty state
          scope.dirty = false;

          //Once we "reset" the dirty state for the directive we need to we cache and store the original model
          original_model = angular.copy(scope.ngModel);
          original_model = $filter('orderBy')(original_model, scope.orderby);

        }
      }, true);

      //If there is a callback function, set a watcher for when the menu closes, this is when we want to run our callback
      if(scope.onSubmit) {
        scope.$watch('status.isopen', function(new_status, old_status) {

          if (new_status === false) {
            $log.log('User close the modal by clicking outside the modal');

            if (original_model && Array.isArray(original_model)) {
              scope.ngModel = angular.copy(original_model);
            }
          }

          $log.log('Combobox Multiple Select Status Watch', new_status, old_status);
          //The status should be false( closed menu ) and we should have some data selected
          if(!new_status && scope.ngModel && scope.ngModel.length>0) {
            scope.cancel();
          }
        });
      }

    }

  }

  Controller.$inject = ['$scope', '$log'];
  function Controller($scope, $log) {}

})();
