/**
 * @ngdoc object
 * @name pulseTabs
 * @requires $state, $timeout
 * @description A resuable compent for Pulse Filemanager Release Assets
 */


 (function(){
   'use strict';
   angular.module('pulse').component('pulseTabs', {
     templateUrl: 'views/common/components/pulse_tabs.html',
     bindings: {
       tabs: '<',
       tabView: '<'
     },
     controller: Controller
   });

   Controller.$inject = ['$log'];

   function Controller($log){
     var ctrl = this;

     ctrl.vm = {
       selected_tab: 0,
       selectTab: selectTab
     };

     ctrl.$onint = function(){
       $log.log('load init');
     }

     function selectTab(idx){
       $log.log('selectTab', idx);
       ctrl.vm.selected_tab = idx;
       ctrl.tabView.name = ctrl.tabs[idx].name;
       $log.log('tabView', ctrl.tabView);
     }

   }

 })();
