/**
 *
 * @ngdoc object
 * @name ClipsMock
 * @requires $resource
 *
 */

//need to add a polling function

 (function(){

'use strict';

angular
    .module('pulse')
    .factory('CloudTakesService', CloudTakes)
    CloudTakes.$inject = ['$log', 'CloudTakesResource', '$q','pollingManager', 'polling', '$stateParams']

    function CloudTakes($log, CloudTakesResource, $q, pollingManager, polling, $stateParams) {

        //START REVEALED METHODS
        var service = {
            getClips : getClips,
            setContext : setContext,
            filterClips : filterClips,
            pollingResources : pollingResources,
            pollingGetClips : pollingGetClips
        };

        return service
        //END REVEALED METHODDS

        //START METHODDS
        function pollingGetClips(poll, context_key){
          $log.log('pollingGetClips called...', context_key);
          var deferred = $q.defer();
          var promise = deferred.promise

          deferred.resolve('poll done');

          getClips({'search':true}, context_key);
          return promise
        }


        function pollingResources(context_key, polling_key, polling_method){
          /// polling_key and polling_method are required
          $log.log('pollingResource', polling_key, polling_method);
          try {
              if (polling_key === undefined || polling_method === undefined){
                  throw 'polling_key and polling_method are required';
              };
              service[polling_key] = polling.createQueue(); //First run
              ///args for createPoller - id, method, parentQueue, query, additionalMethods
              var pollItemGetClips = polling.createPoller(service[polling_key], service[polling_method], service[polling_key], context_key);
              service[polling_key].enqueue(pollItemGetClips);
              service[polling_key].start();
          } catch (e) {
              $log.error(e);
          };
        }

        function setContext(context_key, context_val){
          try {
            if (typeof context_key !== 'string' || !context_key){
              throw 'context_key is required';
            };
            if (typeof context_val !== 'object'){
              context_val = {};
            }

            ///name : {limit:0, skip:0, search:true, list:[]}
            $log.log('+++++++setContext', context_key, context_val);
            context_val['limit'] = (!context_val.hasOwnProperty('limit')) ? 0 : context_val.limit;
            context_val['skip'] = (!context_val.hasOwnProperty('skip')) ? 0 : context_val.skip;
            context_val['load_count'] = 0;
            context_val['search'] = (!context_val.hasOwnProperty('search')) ? false : context_val.search;
            context_val['list'] = (!context_val.hasOwnProperty('list')) ? [] : context_val.list;
            service[context_key] = context_val;
          } catch (error) {
            $log.error(error);
          }
        }



        function filterClips(params, context_key, lazyload, filter_type){
          $log.log('filter',filter_type, params, context_key, lazyload);
          service[context_key].filter_type = filter_type;
          getClips(params, context_key, lazyload);
        }


        function getClips(params, context_key, lazyload){


          try{
              $log.log('get clips+++++', arguments);
              $log.log('context' , service[context_key]);

              //start validation
              //lazyload is an optional argument


              if (context_key === undefined || !service.hasOwnProperty(context_key)){
                throw 'missing context_key';
              }else if(params === undefined){
                throw 'missing method arguments';
              };
              //end validation

              /// check for search param
              var search_is_true = false;
              if(params.hasOwnProperty('search')){
                search_is_true = true;
              }

              // new params obj
              //params will over-write context_val

              var final_params = {};

              //over-write limit
              if(params.hasOwnProperty('limit')){
                final_params['limit'] = params.limit;
                service[context_key].limit = params.limit;
              }else{
                final_params['limit'] = service[context_key].limit;
              };

              //over-write skip
              if(params.hasOwnProperty('skip')){
                final_params['skip'] = params.skip;
                service[context_key].skip = params.skip;
              }else{
                final_params['skip'] = service[context_key].skip;
              };

              /// increment skip if lazyload is true
              /// lazyload will over write params object
              if(lazyload){
                final_params['skip'] = lazyLoadUtil(params, context_key, lazyload);
              };

              //reset skip & limit if search is true;
              if(search_is_true){
                final_params['skip'] = 0;
                final_params['limit'] = 0;
                final_params['query'] = (params.hasOwnProperty('query')) ? angular.toJson(params.query) : (service[context_key].hasOwnProperty('query')) ? service[context_key].query : {};
                service[context_key].limit = 0;
                service[context_key].skip = 0;
                service[context_key].list = [];
                service[context_key].load_count = 0;
                service[context_key]['query'] = final_params.query;
              };

              $log.log('final_params', final_params);

              final_params['projectID'] = $stateParams.projectID;

              var promise = CloudTakesResource.clips(final_params).$promise;
              promise.then(function(success){
                $log.log('++++success++++', success.length);

                if(service[context_key].limit <= 0){
                  service[context_key].limit = success.length;
                  service[context_key].skip = success.length;
                }

                if(search_is_true){
                  $log.log('search len', service[context_key].list.length);
                  service[context_key].load_count += 1;
                }
                /// decrement skip and load_count if no results
                if (success.length <= 0){
                  service[context_key].skip -= service[context_key].limit;
                  service[context_key].load_count -= 1;
                }
                /// add results to context list
                for (var s=0, len=success.length; s < len; s++){
                  service[context_key].list.push(success[s]);
                };

              }, function(error){
                $log.error('error', error);
              });

            }catch(error){
                $log.error('error' , error);
            }


        };
        //END METHODDS


        //START UTILS
        function lazyLoadUtil(params, context_key, lazyload){
          var skip;
          if(service[context_key].load_count <= 0){
            if (params.hasOwnProperty('skip')){
              skip = params.skip;
            }else{
              skip = 0;
            }
          }else if(service[context_key].load_count ===  1){
            service[context_key].skip = service[context_key].limit;
            skip = service[context_key].skip;
          }else{
            skip = (service[context_key].skip += service[context_key].limit);
          }
          service[context_key].load_count++;
          return skip;
        }
        //END UTILS



    }

})();
