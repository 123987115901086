/**
 * @ngdoc directive
 * @name pulseList
 * @module pulse
 * @restrict E
 * @description
 * `pulseList` pulse list is a wrapper for lists
 *
 * @usage
 * <hljs lang="html">
      <pulse-list
          template = "views/templates/events/event_list.html"
          lazyload = "{'service': 'Pulls',
                      'method' : 'loadMoreEventsList',
                      'params' : {},
                      'context_key' : 'pull_event_list',
                      'context_val' : false
                      }"
          polling = "false"
          init = "{'service' : 'Pulls',
                  'method' : 'getPullEvents',
                  'params' : {},
                  'context_key' : 'pull_event_list',
                  'context_val' : false
                   }"
      ></pulse-list>
 * </hljs>
 *
 * TODO: this directive needs a clean up, we should find a better way to pass all the data to this directive
 */
(function() {
    'use strict';

    angular
        .module('pulse')
        .directive('pulseList', directive);

    /* @ngInject */

    directive.$inject = ['$log', '$timeout', 'ServiceManager'];

    function directive($log, $timeout, ServiceManager) {
        var directive = {
            restrict: 'EA',
            templateUrl: function(element, attr){
              if(attr.template !== undefined){
                return attr.template;
              }else{
                return '<h1>error loading template</h1>';
              }
            },
            scope: {
              init : '=',
              params : '=',
              lazyload : '=',
              polling : '=',
            },
            link: linkFunc,
            controller: Controller
        };

        return directive;

        function linkFunc(scope, element, attr) {

          scope.lazyLoadObj = {
            width : 0,
            height : 0,
            scrollHeight : 0,
            scrollTop : 0,
            scrollLeft: 0,
            element : false,
            debounce: false,
            checkHeight : function(){

              $log.log('Lazy Load Element', element[0].children[0]);

              if (!this.element){
                scope.element = angular.element( document.querySelector( '#lazy_loader' ) );
              }

              var self = this;

              if (scope.element[0] && scope.element !== null){
                this.width = scope.element[0].clientWidth;
                this.height = scope.element[0].clientHeight;
                self.debounce = false;
                
                scope.element[0].addEventListener("scroll", function(event){
                  var vm = scope.lazyLoadObj;

                  //If the user is scrolling left and right do not lazy load
                  if(event.target.scrollLeft !== vm.scrollLeft) {
                    vm.scrollLeft = event.target.scrollLeft;
                    return false;
                  }

                  //Offset the scroll height
                  vm.scrollHeight = event.target.scrollHeight;
                  vm.scrollTop = event.target.scrollTop;

                  // $log.log('Scroll Top',vm.scrollTop);
                  // $log.log('Height Container',vm.height);
                  // $log.log('Scroll Height',vm.scrollHeight);
                  // $log.log('Total Height', vm.scrollTop + vm.height);

                  //Do not load if we arent at the bottom
                  //Do not load if our last position when triggering the loading is less then last load height
                  //Do not load if we are already loading something
                  if(vm.scrollTop + vm.height >= vm.scrollHeight && self.debounce === false) {
                    $log.log('Bottom of Scroll...Load more content');

                    //This is where the loading was triggered
                    //we check for this so that when scrolling up we do not trigger the load again
                    self.debounce = true;

                    scope.$apply(function () {
                      var promise = ServiceManager[scope.lazyload.service][scope.lazyload.method](scope.init.context_key);
                      
                      if (!promise) {
                        self.debounce = false;
                        return;
                      }

                      promise.then(function (response) {
                        $log.log('Lazy Loading Success', response);
                         self.debounce = false;
                      }, function (error) {
                        $log.log('Lazy Loading Error', error);
                      });

                    });

                  }


                });
              }
            },

            init : function(){
              $log.log('lazy load init function');
              this.checkHeight();
            }

          };

          //Do we want to run lazy loading here
          if(scope.lazyload){
            $log.log('init lazyload');
            scope.lazyLoadObj.init();
          }

        }
    }

    Controller.$inject = ['$scope', '$log','ServiceManager', '$signalProvider', '$rootScope'];

    /* @ngInject */
    function Controller($scope, $log, ServiceManager, $signalProvider, $rootScope) {
          $log.log('listDir Controller ');

          $scope.vm = {
            params : $scope.params,
            service : ServiceManager,
            table_height: 0
          };
          $scope.stateParams = ServiceManager.$stateParams

          if($scope.init){init();}

          function init(){
            //setContext
            $scope.vm.service[$scope.init.service]['setContext']($scope.init.context_key, $scope.init.context_val);
            //call method
            $scope.vm.service[$scope.init.service][$scope.init.method]($scope.init.params, $scope.init.context_key, $scope.lazyload,  $scope.init.filter_type);

            /// if pooling is active create new polling item
            if ( $scope.polling ){
              $log.log('polling is true');
              $scope.vm.service[$scope.init.service]['pollingResources']($scope.init.context_key, $scope.init.polling_key, $scope.init.polling_method);
            }

            //We reset the scroll bar when there is a new query for anything
            //We are attaching this to the service so it can be controlled from there
            $scope.vm.service[$scope.init.service].resetScrollbar = function() {

              if($scope.element) {
                $scope.element[0].scrollTop = 0;
              }

            };

            setTimeout(function() {
              console.log($scope.lazyLoadObj);
              $scope.vm.service[$scope.init.service].lazyLoadObj = $scope.lazyLoadObj;
            }, 2000);

          }

          $scope.$on('$destroy', function(){
            $log.log('Destroy Pulse List dir......stop polling.');
            //if polling active destroy pooling item by polling_key
            if ($scope.polling){
              $log.log('polling_key', $scope.init.polling_key, 'polling obj ', $scope.vm.service[$scope.init.service][$scope.init.polling_key]);
              $scope.vm.service[$scope.init.service][$scope.init.polling_key].stop();
            }
          });
    }
})();
