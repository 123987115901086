(function() {
    'use strict';
    
    angular.module('pulse')
        .component('pulseImpersonateBanner', {
            templateUrl: 'views/common/components/pulse-impersonate-banner.html',
            controller: impersonateCtrl,
            bindings: {
                isBannerVisible: '<'
            }
        });
    
        impersonateCtrl.$inject = ['$log', '$rootScope', '$window', '$signalProvider', 'Organizations']
    
        function impersonateCtrl($log, $rootScope, $window, $signalProvider, Organizations) {
    
            try {
    
                const user = $rootScope.storage.user;
                this.name = user.name;
                this.isSwitchUserView = user.switchMode ? true : false;
    
                this.closeImpersonateView = function() {
                    $log.log('Close impersonate view');
    
                    const payload = {
                        loginUser: user.switchMode.loginUserEmail,
                        switchUserTo: user.switchMode.switchUserEmail,
                    }
    
                    var promise = Organizations.reverseUser({ id: user.organization.id, userId: user.id }, payload).$promise;
                    promise.then(function(response) {
    
                        if (response.status === true) {
                            $log.log('User Switched', response);
                            localStorage.removeItem('toggle-impersonate-banner');
                            $window.location = window.location.origin + '/dashboard';
                            return;
                        }
    
                        $log.log('User Not Switched:', response);
                        // show a message to the user, there is some problem try to logout.
                        $signalProvider.signal('feedback', [ 'failure', 'ImpersonateUser' ]);
    
                    }).catch(function(error) {
                        // error handler
                        $log.log('User Not Switched:');
                        $log.log(error);
                        $signalProvider.signal('feedback', [ 'failure', 'apiError' ]);
                    })
    
                }
    
            } catch (err) {
                $log.log('user is not defined in root scope storage');
                $log.log(err);
            }
    
    
        }
    })();
    