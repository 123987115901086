'use strict';

/* Services: Search */

angular.module('pulse')
    .factory('Search',
        ['$resource',
            function ($resource) {
                return $resource(
                    config.baseURL + '/dashboard/:action', //default url,
                    {action: '@action'},
                    {
                        getMessageCount: { method: 'GET', params:{action: 'search', 'countOnly': true }},
                        getMessages: { method: 'GET', params:{action: 'search' }, isArray: true},
                        getSearchData: { method: 'GET', params:{action: 'searchData' }}
                    }
                );
            }
        ]
    );
