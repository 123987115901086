/**
 * @ngdoc directive
 * @name pulseProjectsMenu
 * @module pulse
 * @restrict E
 * @description
 * `pulseProjectsMenu` a dropdown menu to switch from one project to another while still in the application
 *
 * @param {object} current- The current project that is showing
 * @param {object} projects - The list of projects to select from the dropdown
 * @param {object} application - The current application using the projects dropdown
 *
 * @usage
 * $root.storage.project = [{
 *  "lastUpdatedOn": 1498599825751,
 *  "name": "Project Name",
 *  "createdOn": 1498599825750,
 *  "id": "5952d19184ef394658fc0abe",
 *  "isActive": true
 * }]
 *
 * $root.storage.projects = [{
 *  "lastUpdatedOn": 1498599825751,
 *  "name": "Project Name",
 *  "createdOn": 1498599825750,
 *  "id": "5952d19184ef394658fc0abe",
 *  "isActive": true
 * }]
 *
 * $root.application = [{
 *  "lastUpdatedOn": 1498599825751,
 *  "name": "File Manager",
 *  "perProject": true,
 *  "url": "/filemanager/",
 *  "createdOn": 1498599825750,
 *  "id": "5952d19184ef394658fc0abe",
 *  "isActive": true
 * }]
 *
 * <hljs lang="html">
 *      <pulse-projects-menu
 *        current="$root.storage.project"
 *        projects="$root.storage.projects"
 *        application="$root.application"
 *      ></pulse-projects-menu>
 * </hljs>
 *
 */

(function() {
    'use strict';

    angular
        .module('pulse')
        .directive('pulseProjectsMenu', pulseProjectsMenu);

    pulseProjectsMenu.$inject = ['$log', '$state', '$timeout', '$rootScope', 'ServiceManager'];

    function pulseProjectsMenu($log, $state, $timeout, $rootScope, ServiceManager) {

        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: "views/common/navigation/projects_menu.html",
            scope: {
                current: '=',
                projects: '=',
                application: '='
            },
            controller: PulseProjectsMenuCrtl,
            link: link
        };
        return directive;

        function link(scope, element, attrs) {

            //In case the users want to go to all the projects
            scope.allProjects = function(){
                $log.log('allProjects');
                if (scope.application.url === '/filemanager/' || scope.application.url === '/filemanagerv2/'){
                    $log.log('changeProject filemanager');
                    scope.resetBetweenProjects(false);
                }
                $state.go('root.projects');
            };

            //TODO: Remove once review becomes a new app
            scope.changeProject = function(project) {
                //$log.log('ServiceManager', ServiceManager);
                // we start the loading state when changing the project, this gets set to false when the transition finishes (on pulse_global.state.js)
                $rootScope.project_loading = true;

                //TODO: We can probably do this in a better way with configs at the start of the application
                //We need to check if we can switch to this project with this same context, in some cases its best to just redirect to the starting page
                if (!!scope.application && scope.application.url === '/reviewandapprove/') { //this is a special case that may go away when review app is separated

                    if(!project.review && ($state.current.name ==='root.app.dashboard.views' || $state.current.name === 'root.app.theater' || $state.current.name.indexOf('root.app.review') >-1 ) ) {
                        $state.go('root.app.playlists.views', { projectID: project.id });
                    } else if(project.review && ($state.current.name ==='root.app.playlists.views' || $state.current.name === 'root.app.theater' || $state.current.name.indexOf('root.app.review') >-1 ) ) {
                        $state.go('root.app.dashboard.views', { projectID: project.id });
                    } else {
                        $state.go(scope.state, { projectID: project.id, playlistID: null });
                    }
                } else if (scope.application.url === '/filemanager/' || scope.application.url === '/filemanagerv2/'){
                    $log.log('changeProject filemanager ---');
                    scope.resetBetweenProjects(project);
                } else if (scope.application.url === '/dipulls/') {
                  $log.log('changeProject dipulls');
                  $state.go('root.app.pulls', {
                    organizationID: project.organization.id,
                    divisionID: project.division.id,
                    projectID: project.id
                  });
                } else if (scope.application.name === 'VFX Pulls'){
                    $log.log('changeProject vfx');
                    $state.go('root.app.pulls', {
                      organizationID: project.organization.id,
                      divisionID: project.division.id,
                      projectID: project.id
                    });
                } else if (scope.application.url === '/vfx/'){
                    $log.log('changeProject vfx');
                    $state.go('root.app.pulls.views', {
                      organizationID: project.organization.id,
                      divisionID: project.division.id,
                      projectID: project.id
                    });
                } else if (scope.application.url === '/preconform/'){
                    $log.log('changeProject preconform');
                    $state.go('root.app.pulls.views', {
                      organizationID: project.organization.id,
                      divisionID: project.division.id,
                      projectID: project.id
                    });
                } else {
                    $state.go($state.current.name, { projectID: project.id });
                }
            }

            /**
             * This function invoked as soon as project change in FMV2
             * If so, set the flag IsProjectChangeInFmv2 to true
             * @param {object} project - the details of the project
             */
            scope.resetBetweenProjects = function(project){
               $log.log("resetBetweenProjects");
               if (ServiceManager.hasOwnProperty('FilemanagerBrowseService')){
                 $log.log("FilemanagerBrowseService --- ");
                 // this is for FMV2
                 ServiceManager.FilemanagerBrowseService.resetAll()
               }
               if (project){
                 ServiceManager.FilemanagerBrowseService.IsProjectChangeInFmv2 = true;
                 $state.go('root.app.browse.views', {
                   organizationID: project.organization.id,
                   divisionID: project.division.id,
                   projectID: project.id
                 });
               }
            }
        }
    }

    function PulseProjectsMenuCrtl() {}

})();
