'use strict';

/**
 * ng-file-select directive
 * A general purpose file uploader.
 */

angular.module('pulse')
    .directive('ngFileSelector',    //  this name needed a little extra because there are many default ng-file-select attributes throughout the apps and bowers
    [ '$log','Upload','$signalProvider','$compile','$rootScope',

        function ($log,Upload,$signalProvider,$compile,$rootScope) {

            return {
                restrict: 'EA',  //  needs to be element only, else angular will parse all 'ng-file-select' on input elements as a separate and overriding method
                replace: true,

                /**
                 *
                 */
                scope: {
                    accept: '@',        //  the value for accept attribute; default to ""; optional   NOTE: optional IFF a handler is used and has this feature in its declaration
                    completeUrl: '=',   //  the endpoint/URL where to submit the upload; mandatory   NOTE: optional IFF a handler is used and has this feature in its declaration
                    context: '@',       //  the context for the file upload; default to "name"; optional   NOTE: optional IFF a handler is used and has this feature in its declaration
                    destination: '=',   //  the JSON object to contain the results; mandatory   NOTE: optional IFF a handler is used and has this feature in its declaration
                    feedback: '@',      //  feedback/log message for success; default to "ImportFile"; optional - ELSE, developer must create a SUCCESS object in "services/feedback.js"   NOTE: optional IFF a handler is used and has this feature in its declaration
                    callback: '=',      //  the callback method in a controller; default to local "scope.onFileSelect()"; optional
                    onStart: '=',       //  the onStart function to be executed when selecting a file; optional
                    onFinish: '=',      //  the onfinish function to be executed when finishing a file upload; optional
                    label: '@'          //  the title and label for the element's button; default to "Upload File; optional
                },

                /**
                 *
                 * @param scope
                 * @param element
                 * @param attrs
                 */
                link: function (scope, element, attrs) {
                    $log.info('ngFileSelector Directive');

                    //  set defaults if "" values in attributes
                    if(scope.feedback === undefined) {
                        scope.feedback = 'FileSelect';
                    }
                    if(scope.context === undefined) {
                        scope.context = 'FileSelect';
                    }
                    if(scope.label === undefined) {
                        scope.label = 'Select File';
                    }

                    //  determine callback
                    if(typeof(scope.callback) === 'undefined' || typeof(scope.callback) !== 'function') {
                        if (!scope.completeUrl) {
                            $log.error('The ngFileSelector attribute "complete-url" is undefined. This is the URL destination for the upload.');
                            return;
                        }
                        if (!scope.destination) {
                            $log.error('The ngFileSelector attribute "destination" is undefined. This is the JSON object in the controller for the file.');
                            return;
                        }
                    }else{

                        if(typeof scope.callback !== 'function') {
                            $log.error('The ngFileSelector attribute "callback" must be a function that takes 1 argument.');
                            return;
                        }

                    }

                    /**
                     *
                     * @param $files
                     */
                    scope.onFileSelect = function($files) {
                        $rootScope.debounce = true;
                        // we run the onStart function if it exists
                        if(typeof scope.onStart !== 'undefined' || typeof(scope.onStart) === 'function') {
                            scope.onStart();
                        }
                        if(typeof scope.callback !== 'undefined' || typeof(scope.callback) === 'function') {
                            scope.callback($files);
                        }else{
                            $log.log('$files.length', $files.length);
                            //TODO: we should have a check here to determine if multi is selected
                            if($files.length > 1) {
                                for (var i = 0, l = $files.length; i < l; i = i + 1) {
                                    perform($files[i]);
                                }
                            } else {
                                perform($files[0]);
                            }
                        }
                        $rootScope.debounce = false;
                    };

                    /**
                     *
                     * @param data
                     */
                    var finishUpload = function(data) {
                        $rootScope.debounce = true;
                        // we run the onFinish function if it exists
                        if(typeof scope.onFinish !== 'undefined' || typeof(scope.onFinish) === 'function') {
                            scope.onFinish(data);
                        }
                        $rootScope.debounce = false;
                    };

                    /**
                     *
                     * @param _file
                     */
                    var perform = function(_file) {
                        if (_file) {
                          Upload.upload({
                                url: scope.completeUrl,
                                method: 'POST',
                                withCredentials: true,
                                file: _file
                            }).success(function (data) {
                                scope.destination = {'url': data.url};
                                $signalProvider.signal('feedback', [ 'success', scope.feedback]);
                                $signalProvider.signal('listUpdate', scope.context);
                                finishUpload(data);
                            }).error(function (error) {
                                $log.error('Error uploading image');
                                $signalProvider.signal('feedback', [ 'failure', scope.feedback]);
                                $signalProvider.signal('listUpdate', scope.context);
                                finishUpload(error);
                            });
                        }
                    };

                    /**
                     *
                     * @returns {string}
                     */
                    scope.getTemplate = function() {
                        return  '<form name="{{context}}Form" class="uploader" novalidate="">' +
                                    '<div class="fileSelect">' +
                                        '<div class="upload">' +
                                            '<span style="font-size:10px;cursor: pointer;">{{label}}</span>' +
                                            '<input type="file" class="input" ' +
                                                'ngf-select="true" ngf-change="onFileSelect($files)" title="{{label}}" ' +
                                                ((attrs.accept !== undefined) ? 'accept="{{accept}}" ' : '') +
                                                ((attrs.multiple !== undefined) ? 'ngf-multiple="true" ' : '') +
                                                'onclick="this.value = null" ' +
                                                'ng-disabled="this.value===null || $root.debounce"' +
                                                'style="height:100%;margin:0;cursor: pointer;" />' +
                                '</div></div><div class="clearfix visible-*"></div></form>';
                    };

                    element.html(scope.getTemplate()).show();
                    $compile(element.contents())(scope);
                }
            };
        }
    ]);
