/**
 * @ngdoc object
 * @name Authentication
 * @requires $resource
 */

(function(){

    'use strict';

    angular
        .module('pulse')
        .factory('Authentication', Authentication);

    Authentication.$inject = ['$resource'];

    function Authentication ($resource) {

        return $resource (
            config.baseURL + '/checkUser/:login',
            {login: '@login'},
            {
              checkUser: {method:'POST'}
            }
        );
    }
})();
