/**
 * @ngdoc object
 * @name ServerEditCtrl
 * @requires $scope, $stateParams, signalProvider
 */

'use strict';

angular.module('pulse')
    .controller('GroupsCtrl',
    ['$scope', '$signalProvider', '$state', '$stateParams', '$log', '$timeout', 'Groups', 'groups', 'userGroupSearchMetadata',
        function ($scope, $signalProvider, $state, $stateParams, $log, $timeout, Groups, groups, userGroupSearchMetadata) {

            $log.info('Groups Controller');

            $scope.groups = {};
            //Put the add new object into the array before we merge the two arrays
            var addTile = [{name:'Add Group', id:'create', icon:'icon-add-server'}];
            $scope.groups.searchMetadata = userGroupSearchMetadata.metadata;
            $scope.groups.loading = false;

            //Search the groups with the given search params
            function search(name, search){
                $log.info('Searching Groups ' + name);
                $scope.groups.loading = true;

                $signalProvider.signal('searchGroups_placeholder', 'Searching...');

                //Query for the count
                Groups.ProjectGroups.getGroupsCount({
                    projectID: $stateParams.projectID,
                    query: JSON.stringify(search)
                }).$promise.then(function (response){

                        $log.info('Found '+ response.count + ' groups');

                        $scope.groups.groupCount = response.count;
                        $signalProvider.signal('searchGroups_placeholder', $scope.groups.groupCount + ' groups found');

                        //If the count is falsey just return and dont do the actual search
                        if(!$scope.groups.groupCount) {
                            $scope.groups.list = addTile;
                            $scope.groups.loading = false;
                            return false;
                        }

                        //Query for the items
                        Groups.ProjectGroups.getGroups({
                            projectID: $stateParams.projectID,
                            query: JSON.stringify(search)
                        }).$promise.then(function(response){
                                $log.info('Done Searching Groups');
                                $scope.groups.loading = false;

                                //Add the files to angular
                                $scope.groups.list = addTile.concat(response);

                            });
                    });
            };

            search('init', ''); // Initialize the search

            //listen for when to search
            var currentSearch = $signalProvider.listen('searchGroups', search);

            //Destroy the function on search
            $scope.$on('$destroy', function(){
                $log.info('>>>>>> ONE <<<<<<<< Destroy Groups Controller');
                $signalProvider.unlisten('searchGroups', currentSearch);
            });

        }]);
