'use strict';

angular.module('pulse')
    .controller('TimeoutCtrl',
    ['$scope', '$log', '$modalInstance', '$signalProvider', '$window',
        function ($scope, $log, $modalInstance, $signalProvider, $window) {

            $log.log('Timeout Controller');
            $scope.timeout = {};
            $scope.timeout.timesup = false;

            $scope.timeout.logout = function() {
              $window.location = config.baseURL + '/logout';
            }

            function closeView(name,value) {
                $modalInstance.close();
            }

            function idleTimeout(name, data) {
                // the user has timed out (meaning idleDuration + warningDuration has passed without any activity)
                // this is where you'd log them
                $scope.timeout.timesup = true;

            }

            //listen for when to search
            var modalClose = $signalProvider.listen("modalClose", closeView);
            var $idleTimeout = $signalProvider.listen("$idleTimeout",idleTimeout);

            //Destroy the function on search
            $scope.$on('$destroy', function(){
                $log.info('Destroy Timeout Controller');
                $signalProvider.unlisten("modalClose",modalClose);
                $signalProvider.unlisten("$idleTimeout",$idleTimeout);
            });

        }]);
