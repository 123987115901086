'use strict';

angular.module('pulse')
  .directive('nselect', ['$log', function ($log) {

    return {
      restrict: 'E',
      require:'^ngModel',
      scope: {
        items: '=',
        textField: '@?',
        valueField: '@?',
        ngModel:'=',
        setDisabled: '=?', //We need to make the name different then "disabled" because IE gets confused with the web attribute.
        useValue: '=?',
        showValue: '='
      },
      template:  '<div class="nselect">' +
      '<div class="btn-group drop-down-container" ng-class="{ \'dropup\': isDropUp }">' +
      '<div class="form-control" data-toggle="dropdown" ng-click="toggleDropDown($event)">{{currentItemLabel}}' +
      '<span class="caretDown" style="position:absolute;right:10px;"></span></div>' +
      '<ul class="dropdown-menu">' +
      '<li class="item" ng-repeat="item in items" ' +
      'ng-class="{hover: hover, active:setChecked(item)}" ' +
      'ng-mouseenter="hover = true" ' +
      'ng-mouseleave="hover = false" ' +
      'ng-click="selectVal(item,$index)">' +
      '{{item[textField]}}' +
      '<span ng-hide="!showValue" class="show-value"> {{item.display}}</span>' +
      '</li> ' +
      '</ul> ' +
      '</div> ' +
      '</div>',

      link: function (scope, element, attrs, ngModelCtrl) {

        scope.isDropUp = false;
        var valueField, textField;
        if(scope.valueField) {
          valueField = scope.valueField.toString().trim();
        }
        if(scope.textField) {
          textField = scope.textField.toString().trim();
        }

        scope.toggleDropDown = function($event) {
          $log.log('A dropdown is being toggled ', $event);
          scope.isDropUp = false;
          var remainingDownSpace = 0;
          var containerHeight = 0;
          var trueDropDownPosition = 0;

          var dropdownToggle = $event.currentTarget;
          var buttonHeight = dropdownToggle.getBoundingClientRect().height;

          var dropdownMenu = $(dropdownToggle).closest('.drop-down-container').find('.dropdown-menu');
          var menuHeight = dropdownMenu.outerHeight();
          var totalDropDownHeight = buttonHeight + menuHeight;

          var scrollContainer = $(dropdownToggle).closest('.ngsb-wrap');
          if (!!scrollContainer && scrollContainer.length) {
            containerHeight = scrollContainer.height();
            var dropdownTrueTop = $(dropdownToggle).offset().top;
            var childOffset = dropdownTrueTop - scrollContainer.offset().top; //where is the child within its container
            trueDropDownPosition = childOffset;
            remainingDownSpace = containerHeight - childOffset; //do we have enough space left below?
          }
          else {
            var $win = $(window);
            containerHeight = $win.height();
            trueDropDownPosition = dropdownToggle.getBoundingClientRect().top;
            remainingDownSpace = containerHeight - trueDropDownPosition;
          }

          if ( (trueDropDownPosition > menuHeight) && remainingDownSpace < totalDropDownHeight ) {
              scope.isDropUp = true;
          }
        }

        scope.$watch('setDisabled',function(){
          if(scope.setDisabled) {
            angular.element(element).find('.form-control').addClass('disabled');
          }else{
            angular.element(element).find('.form-control').removeClass('disabled');
          }
        },true);

        //added a watch to update the text of the select
        scope.$watch('ngModel',function(){
          scope.setLabel();
          if (scope.ngModel && valueField && typeof scope.ngModel[valueField] !== 'undefined' && scope.useValue) {
            scope.ngModel = scope.ngModel[valueField];
          }
        },true);

        var modelIsValid = false;
        var selectedItemIsValid = false;

        //This is the validity check for angulars form
        scope.checkModelValidity = function (items) {
          if (typeof(items) === 'undefined' || !items) {
            return false;
          }

          if (items.length < 1) {
            return false;
          }

          return true;
        };

        modelIsValid = scope.checkModelValidity(scope.ngModel);

        //if something is selected and is valid then check validity; **NOTE we dont give them the option to select something false
        scope.setFormValidity = function () {
          if (typeof (attrs.required) !== 'undefined') {
            return modelIsValid;//modelIsValid must be set before we setFormValidity
          }
          return true;
        };

        ngModelCtrl.$setValidity('noItemsSet!', scope.setFormValidity());

        //This is probably overkill but it checks to item is a valid item
        scope.checkSelectedItemValidity = function (item) {
          if (!item) {
            return false;
          }
          if (valueField && !item[valueField]) {
            return false;
          }
          if (valueField && !item[valueField].toString().trim()) {
            return false;
          }
          return true;
        };

        //What is already selected will be displayed
        scope.setChecked = function (_item) {
          var found = false;
          if (typeof scope.ngModel !== 'undefined') {
            if(typeof _item[valueField] !== 'undefined') {

              if (_item[valueField] === scope.ngModel[valueField]) {
                found=true;
              }

              if(scope.useValue && _item[valueField] === scope.ngModel) {
                found=true;
              }

            }else{

              if (_item === scope.ngModel) {
                found=true;
              }

            }
          }
          return found;
        };

        scope.getItemName = function (item) {
          return item[textField];
        };

        //This setups what is currently being viewed in the select.
        scope.setLabel = function() {
          if (typeof (scope.ngModel) === 'undefined'
          || scope.ngModel === false
          || scope.ngModel === ''
          || scope.ngModel[textField] === ''
          || scope.ngModel[valueField] === ''
          || scope.ngModel.length < 1
          && !scope.useValue) {
            scope.currentItemLabel = attrs.defaultText;
          } else {
            if(typeof scope.ngModel[textField] !== 'undefined') {
              scope.currentItemLabel = scope.ngModel[textField].toString();
            }else if(typeof scope.ngModel !== 'undefined'){
              scope.currentItemLabel = scope.ngModel.toString();
            }else{
              $log.error('Can not find data');
            }
          }


          ///set an empty string when ngModel is undefined
          if(typeof scope.ngModel === 'undefined'){
              $log.log('scopengMOdel is undefined+++++');
              scope.ngModel = '';
          }

        };

        scope.setLabel();

        //On Click we are gonna set the value of the select
        scope.selectVal = function (_item) {

          var found = false;
          $log.log(_item);

          scope.ngModel = '';

          if (!found && valueField && scope.useValue) {
            scope.ngModel = _item[valueField];
          }else{
            scope.ngModel = _item;
          }

          modelIsValid = scope.checkModelValidity(scope.ngModel);
          selectedItemIsValid = scope.checkSelectedItemValidity(_item);
          ngModelCtrl.$setValidity('noItemsSet!', scope.setFormValidity() && selectedItemIsValid);
          scope.setLabel();
          ngModelCtrl.$setViewValue(scope.ngModel);

        };

        scope.cancelClose = function($event) {
          $event.stopPropagation();
        };


      }
    };
  }]);