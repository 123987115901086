/*
    @ngdoc overview
    @name LoadingCtrl
    @param {string} title - The title for what the modal is doing.
    @param {string} body - Extra information for the actions of the modal.
    @param {string} button - The executable action.

*/

'use strict';

angular.module('pulse')
    .controller('LoadingModalCtrl',
    ['$scope', '$log', '$rootScope', '$modalInstance', 'text',
        function ($scope, $log, $rootScope, $modalInstance, text) {

            $log.log('Loading  Modal Controller');
            $scope.text = text;

        }]);
