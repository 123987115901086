/**
 * @ngdoc object
 * @name RolesCtrl
 * @requires $scope, $state, $stateParams
 */

'use strict';

angular.module('pulse')
    .controller('RolesV2Ctrl',
    ['$scope', '$signalProvider', '$stateParams', '$log', '$state', 'modalManager', 'Users', 'Roles', 'Projects', 'usersSearchMetadata',
        function ($scope, $signalProvider, $stateParams, $log, $state, modalManager, Users, Roles, Projects, usersSearchMetadata) {

            $log.info('Roles V2 Controller');

            $scope.roles = {};

            $scope.roles.searchMetadata = usersSearchMetadata.metadata;
            $scope.roles.loading = false;

            function getListIndex(list, item) {
              for (var i = 0; i < list.length; i++) {
                if (list[i].id === item.id) {
                  return i;
                }
              }
            }

            //Search the groups with the given search params
            function search(name, search){
                $log.info('Searching Users ' + name);
                $scope.roles.loading = true;

                $signalProvider.signal('roles_placeholder', 'Searching...');

                //Query for the count
                var promise = Projects.Project.getRoles({
                    projectId: $stateParams.projectID
                }).$promise;

                promise.then(function (response){
                  $log.info('Done Searching Groups');
                  $scope.roles.loading = false;
                  $signalProvider.signal('roles_placeholder', response.length +' roles listed.');
                  $scope.roles.list = response;
                  //$signalProvider.signal('feedback', [ 'success', 'ReleaseSent']);
                },
                  function(error) {
                    $log.error('Failed Searching Groups');
                    $log.error(error);
                    $scope.roles.loading = false;

                    //$signalProvider.signal('feedback', [ 'failure', 'ReleaseSent']);
                });
            };

            search('init', ''); // Initialize the search

            // //NOTE: we needed to do this because ui-router doesnt add href tags if drawer is refreshed :/
            $scope.roles.createRole = function() {
              $state.go($state.current.name.replace(/[.]/gi, "/") + '/edit.role',{roleID:'create'});
            }
            //
            // //NOTE: we needed to do this because ui-router doesnt add href tags if drawer is refreshed :/
            $scope.roles.edit = function(role) {
              $state.go($state.current.name.replace(/[.]/gi, "/") + '/edit.role',{roleID:role.id });
            }

            $scope.roles.confirmDelete = function(object, $event) {    //  1
                $log.log('Toggle Delete Modal');

                var index = getListIndex($scope.roles.list, object);
                var item = $scope.roles.list[index];
                item.unassignEntityType = 'user';
                item.index = index; //Add it to the object

                var modal = modalManager.openModal({
                    templateUrl: 'views/common/modals/confirm.html',
                    controller: 'ConfirmModalCtrl',
                    windowClass: 'confirm',
                    resolve: {
                        title: function () {
                            return 'Are you sure you want to remove the assignment of this user?';
                        },
                        body: function () {
                            //This can accept html
                            return 'You will be removing the assignment of the user ' + item.name + '.';
                        },
                        button: function () {
                            return 'Unassign';
                        }
                    }
                });

                modal.result.then(function () {

                  var promise = Roles.ProjectRoles.delete({ projectID: $stateParams.projectID, roleID : item.id }).$promise.then(function() {
                      search('delete','');
                      $signalProvider.signal('feedback', [ 'success', 'RoleDelete' ]);
                  }, function(error) {
                      $log.log('Role Not Deleted');
                      $log.log(error);
                      $signalProvider.signal('feedback', [ 'failure', 'RoleDelete' ]);
                  });

              });

                //Prevent all other events
                if ($event.stopPropagation) {
                    $event.stopPropagation();
                }
                if ($event.preventDefault) {
                    $event.preventDefault();
                }

                $event.cancelBubble = true;
                $event.returnValue = false;

            };

            //listen for when to search
            var currentSearch = $signalProvider.listen('roles', search);
            var editSearch = $signalProvider.listen('role_edited', search);

            //Destroy the function on search
            $scope.$on('$destroy', function(){
                $log.info('Destroy Users Controller');
                $signalProvider.unlisten('roles', currentSearch);
                $signalProvider.unlisten('role_edited', editSearch);
            });

        }]);
