'use strict';

angular.module('pulse')
    .filter('unskippedFilesLength', function() {
        return function unskippedFilesLength(files){
            var notSkipped = [];
            for(var i = 0; i < files.length; i++){
              if(!files[i].skip){
                notSkipped.push(files[i]);
              }
            }

            return notSkipped.length;
        };
});
