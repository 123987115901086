/**
 * @ngdoc object
 * @name HttpInterceptor
 * @requires $resource
 */

'use strict';

angular.module('pulse')
    .config(['$provide', '$httpProvider', function ($provide, $httpProvider) {

        // Intercept http calls.
        $provide.factory('HttpInterceptor',
            ['$q', '$cookies', '$rootScope', '$signalProvider', '$log', '$localStorage',
                function ($q, $cookies, $rootScope, $signalProvider, $log, $localStorage) {
                    return {

                        // On request success
                        request: function (config) {
                            // Return the config or wrap it in a promise if blank.

                            //TODO: Not sure if this is the best way to do this but i see no other way currently.
                            if(config.method === 'DELETE' && typeof $cookies['XSRF-TOKEN'] !== 'undefined') {
                                config.headers['X-CSRF-Token'] = $cookies['XSRF-TOKEN'];
                            }

                            return $q.when(config);
                        },

                        // // On request failure
                        // requestError: function (rejection) {
                        //   //$log.log(rejection); // Contains the data about the error on the request.

                        //   // Return the promise rejection.
                        //   return $q.reject(rejection);
                        // },

                        // On response success
                        response: function (response) {

                            //$log.log('Response');
                            //TODO: Not sure if this is the best way to do this but i see no other way currently.
                            if(typeof $cookies['XSRF-TOKEN'] !== 'undefined') {
                                //$log.log('Cookie Set -- Now check validity');
                                $httpProvider.defaults.headers.post['X-CSRF-Token'] = $cookies['XSRF-TOKEN'];
                            }

                            return $q.when(response);
                        },

                        // On response failture
                        responseError: function (rejection) {
                            $log.log(rejection); // Contains the data about the error.

                            //If i wanted to return specific request errors we could return it here.

                            //Any header that we dont like will redirect to the header from here.
                            // 401 is unauthorized, so the user does not have access to the endpoint
                            if(rejection.status === 401) {
                                localStorage.setItem('previousAttemptURL', window.location.href);
                                window.location.href = '/landing/#/login?loggedOut=true';
                                //window.stop(); //This is suppose to stop all other requests
                            }

                            if($rootScope.resolving) {
                                $log.error('Error Resolving Modal');
                                $signalProvider.signal("resolveError", rejection);
                            }

                            // Return the promise rejection.
                            return $q.reject(rejection); // This will only display things from data object

                        }

                    };

                }]);

        // Add the interceptor to the $httpProvider.
        $httpProvider.interceptors.push('HttpInterceptor');

    }]);
