/**
 * @ngdoc directive
 * @name pulseTableScroll
 * @module pulse
 * @restrict A
 * @description
 *  we add arrows to the html wrapper of a big table so we can scroll the the sides.
 *
 * @usage
 * <div class="table-wrapper" pulse-table-scroll>
 *    <table>
 *        ...
 *    </table>
 * </div>
 *
 */

(function() {

  'use strict';

  angular
    .module('pulse')
    .directive('pulseTableScroll', pulseTableScroll);

  pulseTableScroll.$inject = ['$log', '$timeout', '$filter', '$compile', '$window'];

  function pulseTableScroll($log, $timeout, $filter, $compile, $window) {

    var directive = {
      restrict: 'A',
      scope: {},
      link: link
    };

    return directive;

    function link(scope, element, attrs) {
      // this handles the display of the left arrow
      scope.left_arrow = false;
      
      // this handles the display of the right arrow
      scope.right_arrow = false;
      
      // this handles the vertical scroll buffer with the arrows
      scope.buffer = false;

      // we create an angular element with the arrows and append it to the dom to be used, and then we check if we need to use them
      var arrows = angular.element('<div class="table-arrow-left" ng-click="scrollLeft()" ng-show="left_arrow"><span class="arrow"></span></div><div class="table-arrow-right" ng-click="scrollRight()" ng-show="right_arrow" ng-class="{buffer:buffer}"><span class="arrow"></span></div>');
      element.append(arrows);
      $compile(arrows)(scope);
      manageArrow();

      // this handles the scrolling to the left when clicking the arrow
      scope.scrollLeft = function() {
        element[0].scrollBy({ 
          left: -element.width(), 
          behavior: 'smooth' 
        });
      }

      // this handles the scrolling to the right when clicking the arrow
      scope.scrollRight = function() {
        element[0].scrollBy({ 
          left: element.width(), 
          behavior: 'smooth' 
        });
      }

      // this checks the position to know which arrow to show
      function manageArrow() {
        var x_position = element.scrollLeft(); // the position of the horizontal scroll
        var table_width = element.children('table').width();
        var frame_width = element.width();

        var table_height = element.children('table').height();
        var frame_height = element.height();


        // if the table doesnt have a scroll, hide arrows and then do nothing
        if(frame_width === table_width) {
          scope.left_arrow = false;
          scope.right_arrow = false;
          return;
        }

        // we check if the table has to many rows that we need to scroll down. if we do we need to move the arrow to account for the scroll bar
        if(table_height > frame_height) {
          scope.buffer = true;
        } else {
          scope.buffer = false;
        }

        // left arrow check: if the scroll is not all the way to the left, we show the arrow
        if(x_position > 0) {
          scope.left_arrow = true;
        } else {
          scope.left_arrow = false;
        }

        // right arrow check: if the scroll is not all the way to the right, we show the arrow
        if(frame_width + x_position < table_width) {
          scope.right_arrow = true;
        } else {
          scope.right_arrow = false;
        }
      }

      // when we scroll we need to check if we need the arrows
      element.bind("scroll", function() {
        manageArrow();
        scope.$apply();
      });

      // when we reizes the window we need to check if we need the arrows
      angular.element($window).bind('resize', function() {
        manageArrow();
        scope.$apply();
      });
    }
  }

})();
