/**
 * @ngdoc object
 * @name Projects
 * @requires $resource
 */

(function(){

    'use strict';

    angular
        .module('pulse')
        .factory('Projects', Projects);

    Projects.$inject = ['$resource'];

    function Projects ($resource) {

        return {

            DivisionProject: $resource( //Division-Project resources
                config.baseURL + '/organizations/:id/divisions/:divisionId/projects/:projectId/:action/:actionId',
                {id: '@id', divisionId: '@divisionId', projectId: '@projectId', action: '@action',  actionId: '@actionId'},
                {
                    /*getDivisionProjectRelations: {url: config.baseURL + '/organizations/:id/divisions/:divisionId/projects/all', method:'GET', isArray: true},
                    getDivisionProjects: {url: config.baseURL + '/organizations/:id/divisions/:divisionId/projects', method:'GET', isArray: true},
                    getDivisionProject: {url: config.baseURL + '/organizations/:id/divisions/:divisionId/projects/:projectId', method:'GET'},
                    deleteDivisionProject: {url: config.baseURL + '/organizations/:id/divisions/:divisionId/projects/:projectId', method:'DELETE'},
                    saveDivisionProject: {url: config.baseURL + '/organizations/:id/divisions/:divisionId/projects', method: 'POST'},
                    saveProjectDefaultRole: {url: config.baseURL + '/organizations/:id/divisions/:divisionId/projects/:projectId', method: 'POST'},
                    saveProject: {url: config.baseURL + '/organizations/:id/divisions/:divisionId/projects/:projectId', method: 'POST'},
                    sendProjectToAdmin: {url: config.baseURL + '/organizations/:id/divisions/:divisionId/projects/:projectId/handover', method: 'POST'},
                    addProjectFreelancer: {url: config.baseURL + '/organizations/:id/divisions/:divisionId/projects/:projectId/freelancer', method: 'POST'},
                    getDivisionProjectApps: {url: config.baseURL + '/organizations/:id/divisions/:divisionId/projects/:projectId/applications', method:'GET', isArray: true},
                    saveProjectApplication: {url: config.baseURL + '/organizations/:id/divisions/:divisionId/projects/:projectId/applications', method: 'POST'},
                    deleteProjectApplication: {url: config.baseURL + '/organizations/:id/divisions/:divisionId/projects/:projectId/applications/:deleteID', method: 'DELETE'}*/

                    getDivisionProjectRelations: {params: {projectId: 'all'}, method:'GET', isArray: true},

                    getDivisionProjects: {method:'GET', isArray: true},

                    getDivisionProject: {method:'GET'},

                    deleteDivisionProject: {method:'DELETE'},

                    saveDivisionProject: {method: 'POST'},
                    saveProjectDefaultRole: {method: 'POST'},
                    saveProject: {method: 'POST'}, //identical to the ones above, clean this up

                    sendProjectToAdmin: {params: {action: 'handover'}, method: 'POST'},

                    addProjectFreelancer: {params: {action: 'freelancer'}, method: 'POST'},

                    getDivisionProjectApps: {params: {action: 'applications'}, method:'GET', isArray: true},

                    saveProjectApplication: {params: {action: 'applications'}, method: 'POST'},

                    deleteProjectApplication: {params: {action: 'applications'}, method: 'DELETE'},

                    saveUserProject: {params: {action: 'users'}, method: 'POST'},

                    deleteUserProject: {params: {action: 'users'}, method: 'DELETE'},

                    //The projects/project$ID/clone
                    cloneProject: {params: {action: 'clone'}, method: 'POST'},

                    saveOnboardingDetails: {method: 'POST'},
                }
            ),

            OrganizationProject: $resource( //Organization-Project resources
                config.baseURL + '/organizations/:id/projects',
                {id: '@id'},
                {
                    getOrganizationProjects: {method:'GET', isArray: true}
                }
            ),

            Project: $resource( //Project - direct resources
                config.baseURL + '/projects/:projectId/:type/:typeId',
                {projectId: '@projectId', type: '@type', typeId: '@typeId'},
                {
                    update: {method: 'POST', params: {type: 'roles'}},
                    getRoles: {method: 'GET', params: {type: 'roles'}, isArray: true},
                    getUsers: {method:'GET', params: {type: 'users'}, isArray: true},
                    getUserGroups: {method:'GET', params: {type: 'groups'}, isArray: true},
                    getProjects: {method:'GET', isArray: true},
                    getOrganizations: {method: 'GET',params: {type:'organizations'}, isArray:true},

                    getCameras: {method: 'GET', params: {type:'cameras'}, isArray:true},
                    getSourceResolutions: {method: 'GET', params: {type:'resolutions'}, isArray:true},

                    getImports: {method: 'GET', params: {type: 'importsetups'}, isArray: true},
                    getImport: {method: 'GET', params: {type: 'importsetups'}},
                    createImport: {method: 'POST', params: {type: 'importsetups'}},
                    updateImport: {method: 'POST', params: {type: 'importsetups'}},
                    deleteImport: {method: 'DELETE', params: {type: 'importsetups'}},
                }
            ),
        }
    }
})();
