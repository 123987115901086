/**
 * @ngdoc object
 * @name Storage
 * @requires $resource
 */

(function(){

    'use strict';

    angular
        .module('pulse')
        .factory('Storage', Storage);

    Storage.$inject = ['$resource'];

    function Storage ($resource) {

        return $resource(
            config.baseURL + '/storages/:ext',
            {ext: '@ext'},
            {
                getNonSystemStorageOptions: {method:'GET', params: {ext:'user'},  isArray: true},
                getStorageBackends: {method:'GET', params: {ext:'backends'}, isArray: false}
            }
        );
    }
})();
