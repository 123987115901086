'use strict';

/**
 * @ngdoc Queued Polling
 * @name pulse.polling
 * @author IG 7.23.15
 * @summary Queued Polling to prevent very large data transfers
 */

//TODO: review and clean up module at some point

angular.module('pulse.polling', [])

.factory('pollingManager', ['$log', '$interval', function($log, $interval) {
    var activePollers = [];
    return {

        //add an active polling queueu
        add: function(pollQueue) {
            if (pollQueue && (pollQueue.id || pollQueue.id == 0)) {
                activePollers.push(pollQueue);
                $log.log('Polling Manager is now tracking an active poller: ',pollQueue);
                this.listActivePollers();
            } else {
                $log.warn('Polling Manager - Unable to add poll queue to the list', pollQueue);
            }
        },

        //remove inactive polling queue
        remove:function(pollQueueID) {
            var retVal = false;
            if (!!pollQueueID || pollQueueID === 0) {
                for (var pq=0; pq < activePollers.length; pq++) {
                    if (activePollers[pq].id === pollQueueID) {
                        activePollers.splice(pq,1);
                        retVal = true;
                        break;
                    }
                }
            }
            return retVal;
        },

        getActivePollers: function() {
            //TODO: We need to check if this is empty before we try to freeze it.
            return { activePollers: activePollers }.freeze();
        },

        getActivePollersCount: function() {
            return activePollers.length;
        },

        listActivePollers: function() {
            $log.log('Polling Manager - Listing of Active Pollers:');
            for (var pq=0; pq < activePollers.length; pq++) {
                $log.log('Active Poller: ', activePollers[pq]);
            }
        },

        terminateAllActivePollers: function() {

          //idk why we were clearing these individually since we
          // want to clear all pollers we can just clear the whole array.
          activePollers = [];


          // if(activePollers.length>0) {
          //   activePollers[0].clear();
          // }


          //TODO: This doesnt work because it is not a closure and does not return in order.
            // for (var pq=0; pq < activePollers.length; pq++) {
            //     $log.log('Polling Manager - Terminating Active Poller: ', activePollers[pq]);
            //     activePollers[pq].clear();
            // }
        }
    }
}])

.factory('polling', ['$log', '$interval', '$q', 'pollingManager', function($log, $interval, $q, pollingManager) {

    //generic, prototype based, object constructor factory
    var construct = function(constructor, args) {
        var obj = Object.create(constructor.prototype);
        constructor.apply(obj, args);
        return obj;
    };

    var previousUniqueID = 0;
    var getUniqueID = function() {
        var date = Date.now();
        // If created at same millisecond as previous
        if (date <= previousUniqueID) {
            date = ++previousUniqueID;
        } else {
            previousUniqueID = date;
        }
        return date;
    }


    //Polling queue Constructor
    var PollQueue = function() {
        this.queue = []; //the queue holding future poll requests
        this.timeoutPeriod = 32000; //milisecs
        this.runningPoll = null; //currently executing poll item
        this.runningTimeout = null; //the currently executing timeout's Promise object
        this.noPolling = false; //a global toggle for polling
        this.id = getUniqueID();
        pollingManager.add(this);

        $log.log('New Polling Queue Created at ', new Date().toTimeString());
    };

    //Polling queue Prototype
    PollQueue.prototype = {
        enqueue : function(poll, allowDuplicate) { //enqueue another poll. At any given time there should only be one item for a given identifier.
            if (!!allowDuplicate && allowDuplicate === true) {
                this.queue.push(poll);
            }
            else {
                var duplicate = false;
                for (var index =0; index < this.queue.length; index++) {
                    if (this.queue[index].id === poll.id) {
                        $log.error('Attempted insertion of duplicate poll detected. Poll ID ', poll.id);
                        duplicate = true;
                        break;
                    }
                }

                if (!duplicate) {
                    this.queue.push(poll); //not a duplicate
                }
            }

            return this.queue.length;
        },

        dequeue : function() { //pop the top item
            var retVal = null;

            if (!this.isEmpty()) {
                retVal = this.queue[0];
                this.queue = this.queue.slice(1); //remove the dequeued item from the queue
            }

            return retVal;
        },

        isEmpty : function() {
            return (this.queue.length === 0);
        },

        clear : function(keepAlive) { //keepAlive flag signals that this queue will be reused and should not be removed from the polling manager
            this.stop();
            if (!!this.runningPoll) {
                this.runningPoll.isValid = false;
            }
            this.runningPoll = null;
            for (var index =0; index < this.queue.length; index++) {
                this.queue[index] = null;
            }
            this.queue = [];

            if (!keepAlive) {
                pollingManager.remove(this.id);
            }

            $log.log('Poller ' + this.id + ' Cleared');
            $log.log('Polling Queue Cleared at ', new Date().toTimeString());
            pollingManager.terminateAllActivePollers();

            return false;
        },

        getLength : function() {
            return this.queue.length;
        },

        peek : function() {
            return (this.isEmpty() ? null : this.queue[0]);
        },

        start : function() { //Execute the first Poll item in the queue
            if (!this.noPolling) {
                $log.log('- Polling Queue Started -');
                $interval.cancel(this.runningTimeout); //stop whatever poll was counting down to execution, if any
                var run = function() {
                    var topPoll = this.peek();
                    if (topPoll != null) {
                        topPoll.execute();
                    }
                };
                this.runningTimeout = $interval(run.bind(this,[]), this.timeoutPeriod, 1, true, this);
            }
        },

        stop : function() {
            if (!!this.runningTimeout || !!this.runningPoll) $log.log('- Polling Queue Stopped -');
            $interval.cancel(this.runningTimeout); //stop whatever poll was counting down to execution, if any
            if (!!this.runningPoll) {
                this.runningPoll.terminate();
            }
            this.runningTimeout = null;
        },

        isRunning : function() {
            return (this.runningTimeout != null);
        },

        queryUpdate: function(newQuery) { //update each poll item when user's query changes
            for (var index =0; index < this.queue.length; index++) {
                this.queue[index].query = newQuery;
            }
        }
    }



    /*
      Poll Item Constructor.
      Initialize a new polling item with the following:
      - its ID (e.g. index 0, 200, etc.),
      - a method that executes a poll and returns a promise resolved on successful poll,
      - and the parent Queue that holds this poll item.
      - additionalMethods is an optional array of any additional methods that should be executed on each poll
    */
    var PollItem = function(id, method, parentQueue, query, additionalMethods) {
        this.created = new Date().toTimeString();
        this.isValid = true;
        this.isRunning = false;
        this.deferred = null;

        if (typeof id == 'undefined' || typeof method == 'undefined' || typeof parentQueue == 'undefined') {
            $log.error('A Poll item cannot be initialized - not all required parameters were provided. Please ensure that ID, Method, and ParentQueueu have been specified.');
            this.isValid = false;
            return;
        }
        else {
            $log.log('New Poll item ', id, ' initialized at ', this.created);
        }

        this.id = id;
        this.method = method;
        this.parentQueue = parentQueue;
        this.query = query;
        this.additionalMethods = additionalMethods;
    }

    //Poll Item Prototype
    PollItem.prototype = {
        execute : function() {
            $interval.cancel(this.parentQueue.runningTimeout); //stop whatever poll was counting down to execution, if any
            $log.log('Counting down to execution of Poll item ', this.id, ' at ', new Date().toTimeString());

            var runIt = function() {
                $log.log('Execution of Poll item ', this.id, ' started at ', new Date().toTimeString());
                this.deferred = $q.defer();
                this.isRunning = true;
                this.parentQueue.runningPoll = this; //notify the queue that this is the currently executing poll

                var thisPoll = this;

                //Defending ourselves against the scopes method being undefined
                if(typeof this.method === 'undefined') {
                    return false;
                }

                this.method.apply(null, [this.query, 'poll', this.id]).then( function() { //Success
                        $log.log('Execution of Poll item ', thisPoll.id, ' completed succesfully at ', new Date().toTimeString());
                        if (thisPoll.isRunning && !!thisPoll.deferred) { //still running (not terminated)
                            thisPoll.deferred.resolve(thisPoll.id);
                        }
                    }, function(reason) { //Failure
                        var errorStr = 'Execution of Poll item ' + thisPoll.id + ' failed at ' + new Date().toTimeString() + ' with reason: ' + reason;
                        $log.error(errorStr);
                        if (!!thisPoll.deferred) {
                            thisPoll.deferred.reject(errorStr);
                        }
                    })
                    .finally(function() { //Finished - cleanup and next steps (even on error)
                        thisPoll.isRunning = false;
                        if (thisPoll.isValid) {
                            $log.log('Repoll', thisPoll);
                            thisPoll.requeue(); //recreate this poll at the bottom of the queue
                            thisPoll.runNextPoll(); //start the next queued poll item
                        } else {
                            $log.warn('The executed poll was marked as invalid by an external process and will not be requeued.');
                        }
                    });

                 if (!!this.additionalMethods) {
                     for (var i=0; i<this.additionalMethods.length; i++) {
                         if (typeof this.additionalMethods[i].method === 'function') {
                            this.additionalMethods[i].method.apply(null, this.additionalMethods[i].args);
                         }
                     }
                 }
            };

            this.parentQueue.runningTimeout = $interval(runIt.bind(this,[]), this.parentQueue.timeoutPeriod, 1);
        },

        requeue : function() {
            $log.log('Poll item ', this.id, ' being re-queued at ', new Date().toTimeString());
            this.terminate();
            var thisPollInQueue = this.parentQueue.dequeue(); //remove this poll from top of the queue
            if (thisPollInQueue.id != this.id) {
                $log.error('The dequeued poll\'s id ', thisPollInQueue.id, ' did not match the executing poll id of ', this.id);
            }
            this.parentQueue.enqueue(this); //add this poll to the bottom of the queue
        },

        runNextPoll : function() { //start the next poll item in the queue
            var nextPoll = this.parentQueue.peek();
            if (nextPoll != null) {
                nextPoll.execute();
            }
        },

        terminate : function() {
            if (this.isRunning) {
                this.deferred.resolve("Execution of poll terminated at ", new Date().toTimeString());
                $log.info("Execution of poll terminated at ", new Date().toTimeString());
                this.deferred = null;
                this.isRunning = false;
            }
        }
    }



    //the service object
    return {

        //get a new queue class instance
        createQueue: function() {
            return construct(PollQueue, []);
        },

        //get a new instance of the PollItem class
        createPoller:function(id, method, parentQueue, query, additionalMethods) {
            return construct(PollItem, [id, method, parentQueue, query, additionalMethods]);
        }

	}
}]);
