/**
 * @ngdoc service
 * @name VendorsService
 * @module pulse
 * @description
 * `VendorsService` provides all services for DI pulls (possibly vfx too).
 *
 * @usage
 *
 */

//TODO: Fill in the docJS for this
// TODO: remove rootScope.loading and create a more global approach to manage loading of data and disabling functionality

(function () {
  'use strict';

  angular
    .module('pulse')
    .service('VendorsService', VendorsService);

  VendorsService.$inject = ['$log', '$timeout', '$stateParams', '$state', '$rootScope', '$signalProvider', 'VendorsResource', 'modalManager', 'Projects', 'Users', 'Groups', 'PoolsResource', 'Storage', '$http'];

  /* @ngInject */
  function VendorsService($log, $timeout, $stateParams, $state, $rootScope, $signalProvider, VendorsResource, modalManager, Projects, Users, Groups, PoolsResource, Storage, $http) {
    //START REVEALED METHODS
    var service = {
      init: init,
      setContext: setContext,
      addNewVendor: addNewVendor,
      showVendor: showVendor,
      updateProjectUsers: updateProjectUsers,
      addUser: addUser,
      removeUser: removeUser,
      updateProjectUserGroups: updateProjectUserGroups,
      addUserGroup: addUserGroup,
      removeUserGroup: removeUserGroup,
      goToServer: goToServer,
      removeServer: removeServer,
      deleteVendor: deleteVendor,
      createVendor: createVendor,
      updateVendorServer: updateVendorServer,
      updateVendor: updateVendor,
      // Server Methods
      initServer: initServer,
      updateServerInfo: updateServerInfo,
      updateExistingServerInfo: updateExistingServerInfo,
      updateExistingServer: updateExistingServer,
      updateServer: updateServer,
      backExistingServer: backExistingServer,
      validatePort: validatePort,
      back: back,
      save: save,
      delete: Delete,
      hasErrors: hasErrors,
      toggleServerCreation:toggleServerCreation,
      waitForTransition:waitForTransition,
      selectionErrorCheck:selectionErrorCheck,
      filterAvailableServer: filterAvailableServer,
    };

    service.wait = true;

    var ADD_SERVER = 'Add a Server';
    var UPDATE_SERVER = 'Update existing server\'s details?';
    var isEditExistingServer = false;
    var allVendorsList = [];
    var pool = {};
    var isAvailableServerFiltered = false;

    service.pageTitle = ADD_SERVER;
    service.isServerInOtherVendors = false;


    return service;
    //END REVEALED METHODS

    //START METHODS

    //Sets the context of the whole service as well as some filter, search params
    function setContext(context_key, context_val) {
      try {
        if (typeof context_key !== 'string' || !context_key) {
          throw 'context_key is required';
        }
        if (typeof context_val !== 'object') {
          context_val = {};
        }
        context_val['limit'] = (!context_val.hasOwnProperty('limit')) ? 0 : context_val.limit;
        context_val['skip'] = (!context_val.hasOwnProperty('skip')) ? 0 : context_val.skip;
        context_val['load_count'] = 0;
        context_val['search'] = (!context_val.hasOwnProperty('search')) ? false : context_val.search;
        context_val['list'] = (!context_val.hasOwnProperty('list')) ? [] : context_val.list;
        service[context_key] = context_val;
      } catch (e) {
        $log.error(e);
      }
    }

    // Gets the vendors
    function getVendors(context_key, object_key, method, callback_array) {

      object_key = object_key || 'vendors';

      var more_vendor_msgs = {
        'vfx': 'Expecting to see other vendors in this list? DI or File Manager vendors cannot be edited from the VFX Pulls application.',
        'dipulls': 'Expecting to see other vendors in this list? VFX or File Manager vendors cannot be edited from the DI Pulls application.',
        'filemanagerv2': 'Expecting to see other vendors in this list? VFX or DI vendors cannot be edited from the File Manager application.'
      };

      try {
        //start validation
        if (context_key === undefined || !service.hasOwnProperty(context_key)) {
          throw 'missing context_key';
        }

        //make the object key
        if (!service[context_key][object_key]) {
          service[context_key][object_key] = {};
        }

        //end validation

        //The Service state is in init.
        if (!service[context_key][object_key].state) {
          service[context_key][object_key].state = 'init';
        }

        //We are loading new information
        service[context_key][object_key].loading = true;

        //Get the application and project; add it to the query
        var query = {
          'projectID' : $stateParams.projectID
        };

        //Request resource to get vendors by project and application
        var promise = VendorsResource.getVendors(query).$promise;
        promise.then(function (vendors) {

          allVendorsList = Array.isArray(vendors) ? vendors : [];
          getAvailableServer(context_key, 'available_server');

          var vendor, final_vendors = [];
          var more_vendors_exists = false;
          for (var i=0; i<vendors.length; i++) {
            vendor = vendors[i];
            if (vendor.applicationID === $rootScope.storage.application.id) {
              final_vendors.push(vendor);
            }
            else {
              more_vendors_exists = true;
            }
          }

          service[context_key][object_key]['more_vendors_msg'] = more_vendor_msgs[$rootScope.storage.application.url.replace(/\//g, '')];
          service[context_key][object_key]['more_vendors_exists'] = more_vendors_exists;

          $log.log('get Vendors', final_vendors);
          updateObjects(context_key, object_key, final_vendors);

          if(callback_array && callback_array.length>0) {
            var callbacks_length = callback_array.length;
            for(var i = 0; callbacks_length > i; i++) {
              callback_array[i].callback(callback_array[i].context_key, final_vendors);
            }
          }

          //turn off loading
          service[context_key][object_key].loading = false;

          if(method === 'DELETE' || method === 'init') {
            selectVendor(context_key);
          }

        }, function (error) {
          $log.error('error', error);
          service[context_key][object_key].state = 'error';
          service[context_key][object_key].loading = false;
        });
      } catch (error) {
        $log.error('error', error);
        service[context_key][object_key].state = 'error';
        service[context_key][object_key].loading = false;
      }
    }

    // Gets the organizations
    function getOrganizations(context_key, object_key) {
      try {
        //start validation
        if (context_key === undefined || !service.hasOwnProperty(context_key)) {
          throw 'missing context_key';
        }

        //make the object key
        if (!service[context_key][object_key]) {
          service[context_key][object_key] = {};
        }

        //end validation

        //The Service state is in init.
        if (!service[context_key][object_key].state) {
          service[context_key][object_key].state = 'init';
        }

        //We are loading new information
        service[context_key][object_key].loading = true;

        var promise = Projects.Project.getOrganizations({projectId: $stateParams.projectID}).$promise;
        promise.then(function (data) {
          //IF we have some data we should
          if (data.length >= 1) {
            updateObjects(context_key, object_key, data);
          }

          //turn off loading
          service[context_key][object_key].loading = false;
        }, function (error) {
          $log.error('error', error);
          service[context_key][object_key].state = 'error';
          service[context_key][object_key].loading = false;
        });
      } catch (error) {
        $log.error('error', error);
        service[context_key][object_key].state = 'error';
        service[context_key][object_key].loading = false;
      }
    }

    // Gets the Users
    function getUsers(context_key, object_key) {
      try {
        //start validation
        if (context_key === undefined || !service.hasOwnProperty(context_key)) {
          throw 'missing context_key';
        }

        //make the object key
        if (!service[context_key][object_key]) {
          service[context_key][object_key] = {};
        }

        //end validation

        //The Service state is in init.
        if (!service[context_key][object_key].state) {
          service[context_key][object_key].state = 'init';
        }

        //We are loading new information
        service[context_key][object_key].loading = true;

        var promise = Users.ProjectUser.getUsers({projectID: $stateParams.projectID}).$promise;
        promise.then(function (data) {
          //IF we have some data we should
          if (data.length >= 1) {
            updateObjects(context_key, object_key, data);
            updateProjectUsers(context_key);
          }

          //turn off loading
          service[context_key][object_key].loading = false;
        }, function (error) {
          $log.error('error', error);
          service[context_key][object_key].state = 'error';
          service[context_key][object_key].loading = false;
        });
      } catch (error) {
        $log.error('error', error);
        service[context_key][object_key].state = 'error';
        service[context_key][object_key].loading = false;
      }
    }

    // Gets the user groups
    function getUserGroups(context_key, object_key) {
      try {
        //start validation
        if (context_key === undefined || !service.hasOwnProperty(context_key)) {
          throw 'missing context_key';
        }

        //make the object key
        if (!service[context_key][object_key]) {
          service[context_key][object_key] = {};
        }

        //end validation

        //The Service state is in init.
        if (!service[context_key][object_key].state) {
          service[context_key][object_key].state = 'init';
        }

        //We are loading new information
        service[context_key][object_key].loading = true;

        var promise = Groups.ProjectGroups.getGroups({projectID: $stateParams.projectID}).$promise;
        promise.then(function (user_groups) {
          //IF we have some userGroups we should
          if (user_groups.length >= 1) {
            updateObjects(context_key, object_key, user_groups);
            updateProjectUserGroups(context_key);
          }

          //turn off loading
          service[context_key][object_key].loading = false;
        }, function (error) {
          $log.error('error', error);
          service[context_key][object_key].state = 'error';
          service[context_key][object_key].loading = false;
        });
      } catch (error) {
        $log.error('error', error);
        service[context_key][object_key].state = 'error';
        service[context_key][object_key].loading = false;
      }
    }

    // Clears vendor form to create a new one
    function addNewVendor(context_key, $event) {
      $log.log('addNewVendor', context_key);
      if($rootScope.dirty) {

        $event.preventDefault();

        $log.log('Dirty Modal');
        var confirm = modalManager.openModal({
          templateUrl: 'views/common/modals/unsaved.html',
          controller: 'ConfirmModalCtrl',
          windowClass: 'confirm',
          resolve: {
            title: function () {
              return 'There are unsaved changes.';
            },
            body: function () {
              //This can accept html
              return "Are you sure you want to switch vendors without saving your changes?";
            },
            button: function () {
              return 'Continue';
            }
          }
        });
        //Remove the modal from the dom
        confirm.result.then(function() {
          service[context_key]['vendor_obj'].usersReset = true;
          service[context_key]['vendor_obj'].userGroupsReset = true;

          if(service[context_key]['vendor_obj'].vendorForm) {
             service[context_key]['vendor_obj'].vendorForm.$setPristine(); // We have to force form dirty on view switches
          }

          service[context_key]['vendor_obj'].selected = { users: [], pools: [], userGroups: [] };
          service[context_key]['vendor_obj'].vendorID = 'add';
          $rootScope.dirty = false;
          service[context_key]['vendor_obj'].errors = {};
          $state.go('.',{vendorID: 'add'});
        });
      } else {
        service[context_key]['vendor_obj'].usersReset = true;
        service[context_key]['vendor_obj'].userGroupsReset = true;

        if(service[context_key]['vendor_obj'].vendorForm) {
           service[context_key]['vendor_obj'].vendorForm.$setPristine(); // We have to force form dirty on view switches
        }

        service[context_key]['vendor_obj'].user = '';
        service[context_key]['vendor_obj'].userGroup = '';
        service[context_key]['vendor_obj'].selected = { users: [], pools: [], userGroups: [] };
        service[context_key]['vendor_obj'].vendorID = 'add';
        $rootScope.dirty = false;
        service[context_key]['vendor_obj'].errors = {};
        $state.go('.',{vendorID: 'add'});
      }
    }

    // Gets the vendor and displays it
    function showVendor(context_key, vendor) {

      if($rootScope.dirty) {

        event.preventDefault();

        $log.log('Dirty Modal');
        var confirm = modalManager.openModal({
          templateUrl: 'views/common/modals/unsaved.html',
          controller: 'ConfirmModalCtrl',
          windowClass: 'confirm',
          resolve: {
            title: function () {
              return 'There are unsaved changes.';
            },
            body: function () {
              //This can accept html
              return "Are you sure you want to switch vendors without saving your changes?";
            },
            button: function () {
              return 'Continue';
            }
          }
        });
        //Remove the modal from the dom
        confirm.result.then(function() {
          service[context_key]['vendor_obj'].usersReset = true;
          service[context_key]['vendor_obj'].user = '';
          service[context_key]['vendor_obj'].userGroupsReset = true;
          service[context_key]['vendor_obj'].userGroup = '';
          $rootScope.dirty = false;
          service[context_key]['vendor_obj'].selected = angular.copy(vendor);
          service[context_key]['vendor_obj'].vendorName = angular.copy(vendor.name);
          service[context_key]['vendor_obj'].vendorID = vendor.id;
          // We have to force form dirty on view switches
          if(typeof service[context_key]['vendor_obj'].vendorForm !== 'undefined') {
            service[context_key]['vendor_obj'].vendorForm.$setPristine();
          }
          updateProjectUsers(context_key);
          updateProjectUserGroups(context_key);
          service[context_key]['vendor_obj'].usersReset = false;
          service[context_key]['vendor_obj'].userGroupsReset = false;
          $state.go('.',{vendorID: vendor.id}, { notify: false }); //We are turning off the state event, and just updating the url
        });
      }else{
        service[context_key]['vendor_obj'].usersReset = true;
        service[context_key]['vendor_obj'].user = '';
        service[context_key]['vendor_obj'].userGroupsReset = true;
        service[context_key]['vendor_obj'].userGroup = '';
        service[context_key]['vendor_obj'].selected = angular.copy(vendor);
        service[context_key]['vendor_obj'].vendorName = angular.copy(vendor.name);
        service[context_key]['vendor_obj'].vendorID = vendor.id;
        // We have to force form dirty on view switches
        if(typeof service[context_key]['vendor_obj'].vendorForm !== 'undefined') {
          service[context_key]['vendor_obj'].vendorForm.$setPristine();
        }
        updateProjectUsers(context_key);
        updateProjectUserGroups(context_key);
        service[context_key]['vendor_obj'].usersReset = false;
        service[context_key]['vendor_obj'].userGroupsReset = false;
        $state.go('.',{vendorID: vendor.id}, { notify: false }); //We are turning off the state event, and just updating the url
      }
    }

    // Selects vendor and displays it on the form
    function selectVendor(context_key) {

      //If there is no vendors then we will push them to the add view.
      if (service[context_key]['vendors'].data && service[context_key]['vendors'].data.length < 1 || typeof $stateParams.vendorID !== 'undefined' && $stateParams.vendorID === 'add') {
        service[context_key]['vendor_obj'].selected = {};
        service[context_key]['vendor_obj'].selected.users = [];
        service[context_key]['vendor_obj'].selected.pools = [];
        service[context_key]['vendor_obj'].selected.userGroups = [];
        service[context_key]['vendor_obj'].vendorID = 'add';
        service[context_key]['vendor_obj'].usersReset = true;
        service[context_key]['vendor_obj'].user = '';
        service[context_key]['vendor_obj'].usersReset = false;
        service[context_key]['vendor_obj'].userGroupsReset = true;
        service[context_key]['vendor_obj'].userGroup = '';
        service[context_key]['vendor_obj'].userGroupsReset = false;
        // We have to force form dirty on view switches
        if(typeof service[context_key]['vendor_obj'].vendorForm !== 'undefined') {
          service[context_key]['vendor_obj'].vendorForm.$setPristine();
        }

        $state.go('.', {vendorID: 'add'}, {notify: false});
      } else if (typeof $stateParams.vendorID === 'undefined' || !$stateParams.vendorID) {
        service[context_key]['vendor_obj'].selected = angular.copy(service[context_key]['vendors'].data[0]);
        service[context_key]['vendor_obj'].vendorID = service[context_key]['vendors'].data[0].id;
        service[context_key]['vendor_obj'].vendorName = angular.copy(service[context_key]['vendors'].data[0].name);
        service[context_key]['vendor_obj'].usersReset = true;
        service[context_key]['vendor_obj'].user = '';
        service[context_key]['vendor_obj'].usersReset = false;
        service[context_key]['vendor_obj'].userGroupsReset = true;
        service[context_key]['vendor_obj'].userGroup = '';
        service[context_key]['vendor_obj'].userGroupsReset = false;
        // We have to force form dirty on view switches
        if(typeof service[context_key]['vendor_obj'].vendorForm !== 'undefined') {
          service[context_key]['vendor_obj'].vendorForm.$setPristine();
        }

        updateProjectUsers(context_key);
        updateProjectUserGroups(context_key);
        $state.go('.', {vendorID: service[context_key]['vendors'].data[0].id}, {notify: false});
      } else {

        service[context_key]['vendor_obj'].vendorID = $stateParams.vendorID;

        // Loop through all the vendors and match up by id
        for (var i = 0; i < service[context_key]['vendors'].data.length; i++) {
          if (service[context_key]['vendors'].data[i].id.toString() === $stateParams.vendorID) {
            service[context_key]['vendor_obj'].selected = angular.copy(service[context_key]['vendors'].data[i]);
            service[context_key]['vendor_obj'].vendorName = angular.copy(service[context_key]['vendors'].data[i].name);
            service[context_key]['vendor_obj'].usersReset = true;
            service[context_key]['vendor_obj'].user = '';
            service[context_key]['vendor_obj'].usersReset = false;
            service[context_key]['vendor_obj'].userGroupsReset = true;
            service[context_key]['vendor_obj'].userGroup = '';
            service[context_key]['vendor_obj'].userGroupsReset = false;
            // We have to force form dirty on view switches
            if(typeof service[context_key]['vendor_obj'].vendorForm !== 'undefined') {
              service[context_key]['vendor_obj'].vendorForm.$setPristine();
            }

            updateProjectUsers(context_key);
            updateProjectUserGroups(context_key);
            break;
          }
        }
      }
    }

    // Updates the project users object
    function updateProjectUsers(context_key) {

      var organization_users = [];
      var users_length = service[context_key]['users'].data ? service[context_key]['users'].data.length : 0;

      if(users_length===0) {
        return false;
      }

      for(var i=0; i<users_length; i++) {
        // if(service[context_key]['vendor_obj'].selected.organization.name === service[context_key]['users'].data[i].organization.name) {
        organization_users.push(service[context_key]['users'].data[i]);
        // }
      }

      service[context_key]['vendor_obj'].projectUsers = organization_users; //organization_users;
      removeExisitingUsers(context_key);
    }

    // Removes Users from a vendor
    function removeExisitingUsers(context_key) {
      var clean_users = [];

      if(service[context_key]['vendor_obj'].selected.users.length>0) {

        var vendor_length = service[context_key]['vendor_obj'].selected.users.length;
        var users_length = service[context_key]['vendor_obj'].projectUsers.length;

        if(vendor_length===0) {
          return false;
        }

        for(var i=0; i<users_length; i++) {
          var found = false;
          for(var x=0; x<vendor_length; x++) {
            if(service[context_key]['vendor_obj'].selected.users[x].id === service[context_key]['vendor_obj'].projectUsers[i].id) {
              found = true;
              break;
            }
          }

          if(!found) {
            clean_users.push(service[context_key]['vendor_obj'].projectUsers[i]); //IG 4.1.16 - wrong list was being used //users[i]);
          }

        }
      }else{
        clean_users = service[context_key]['vendor_obj'].projectUsers;
      }

      service[context_key]['vendor_obj'].projectUsers = clean_users;
      service[context_key]['vendor_obj'].cachedProjectUsers = angular.copy(service[context_key]['vendor_obj'].projectUsers);
    }

    // Adds a User to a vendor
    function addUser(context_key) {
      service[context_key]['vendor_obj'].usersReset = true;

      if(typeof service[context_key]['vendor_obj'].selected.users === 'undefined') {
        service[context_key]['vendor_obj'].selected.users = [];
      }

      service[context_key]['vendor_obj'].selected.users.push(service[context_key]['vendor_obj'].user);
      updateProjectUsers(context_key)
    }

    // Updates the project userGroups object
    function updateProjectUserGroups(context_key) {

      var organization_userGroups = [];
      var userGroups_length = service[context_key]['userGroups'].data ? service[context_key]['userGroups'].data.length : 0;

      if(userGroups_length===0) {
        return false;
      }

      for(var i=0; i<userGroups_length; i++) {
        // if(service[context_key]['vendor_obj'].selected.organization.name === service[context_key]['userGroups'].data[i].organization.name) {
        organization_userGroups.push(service[context_key]['userGroups'].data[i]);
        // }
      }

      service[context_key]['vendor_obj'].projectUserGroups = organization_userGroups; //organization_userGroups;
      removeExisitingUserGroups(context_key);
    }

    // Removes userGroups from a vendor
    function removeExisitingUserGroups(context_key) {
      var clean_userGroups = [];

      if(service[context_key]['vendor_obj'].selected.userGroups && service[context_key]['vendor_obj'].selected.userGroups.length>0) {

        var vendor_length = service[context_key]['vendor_obj'].selected.userGroups.length;
        var userGroups_length = service[context_key]['vendor_obj'].projectUserGroups.length;

        if(vendor_length===0) {
          return false;
        }

        for(var i=0; i<userGroups_length; i++) {
          var found = false;
          for(var x=0; x<vendor_length; x++) {
            if(service[context_key]['vendor_obj'].selected.userGroups[x].id === service[context_key]['vendor_obj'].projectUserGroups[i].id) {
              found = true;
              break;
            }
          }

          if(!found) {
            clean_userGroups.push(service[context_key]['vendor_obj'].projectUserGroups[i]); //IG 4.1.16 - wrong list was being used //userGroups[i]);
          }

        }
      }else{
        clean_userGroups = service[context_key]['vendor_obj'].projectUserGroups;
      }

      service[context_key]['vendor_obj'].projectUserGroups = clean_userGroups;
      service[context_key]['vendor_obj'].cachedProjectUserGroups = angular.copy(service[context_key]['vendor_obj'].projectUserGroups);
    }

    // Adds a userGroup to a vendor
    function addUserGroup(context_key) {
      service[context_key]['vendor_obj'].userGroupsReset = true;

      if(typeof service[context_key]['vendor_obj'].selected.userGroups === 'undefined') {
        service[context_key]['vendor_obj'].selected.userGroups = [];
      }

      service[context_key]['vendor_obj'].selected.userGroups.push(service[context_key]['vendor_obj'].userGroup);
      updateProjectUserGroups(context_key)
    }

    // Removes a userGroup from a vendor
    function removeUserGroup(context_key, $event, userGroup) {
      $event.stopPropagation();
      $event.preventDefault();

      $log.log('Delete Confirm Modal');
      var confirm = modalManager.openModal({
        templateUrl: 'views/common/modals/confirm.html',
        controller: 'ConfirmModalCtrl',
        windowClass: 'confirm',
        resolve: {
          title: function () {
            return 'Are you sure you want to delete this group from this vendor?';
          },
          body: function () {
            //This can accept html
            return 'You will be deleting group \"'+ userGroup.name +'\" from this vendor.';
          },
          button: function () {
            return 'Delete';
          }
        }
      });

      //Remove the modal from the dom
      confirm.result.then(function() {
        var userGroup_length = service[context_key]['vendor_obj'].selected.userGroups.length;
        for(var i=0;i<userGroup_length;i++) {
          if(service[context_key]['vendor_obj'].selected.userGroups[i].id===userGroup.id) {
            service[context_key]['vendor_obj'].selected.userGroups.splice(i,1);
            break;
          }
        }
        updateProjectUserGroups(context_key);
      });
    }

    // Updates the vendor information
    function updateVendor(context_key, callback_array) {
      var user_ids = [];
      var server_ids = [];
      var userGroup_ids = [];

      if(typeof service[context_key]['vendor_obj'].selected.users !== 'undefined') {
        var users_length = service[context_key]['vendor_obj'].selected.users.length;
        for (var i = 0; i < users_length; i++) {
          user_ids.push(service[context_key]['vendor_obj'].selected.users[i].id);
        }
      }

      if(typeof service[context_key]['vendor_obj'].selected.pools !== 'undefined') {
        var servers_length = service[context_key]['vendor_obj'].selected.pools.length;
        for (var x = 0; x < servers_length; x++) {
          server_ids.push(service[context_key]['vendor_obj'].selected.pools[x].id);
        }
      }

      if(typeof service[context_key]['vendor_obj'].selected.userGroups !== 'undefined') {
        var servers_length = service[context_key]['vendor_obj'].selected.userGroups.length;
        for (var x = 0; x < servers_length; x++) {
          userGroup_ids.push(service[context_key]['vendor_obj'].selected.userGroups[x].id);
        }
      }

      var payload = {
        vendorID: service[context_key]['vendor_obj'].selected.id,
        projectID: $stateParams.projectID,
        name: service[context_key]['vendor_obj'].selected.name,
        organizationID: $stateParams.organizationID,
        userIDs: user_ids,
        poolIDs: server_ids,
        userGroupIDs: userGroup_ids
      }

      var promise = VendorsResource.saveVendor(payload).$promise;
      promise.then(function(vendor) {
          $log.log('Update Vendor', vendor);
          // We have to force form dirty on view switches
          if(typeof service[context_key]['vendor_obj'].vendorForm !== 'undefined') {
            service[context_key]['vendor_obj'].vendorForm.$setPristine();
          }


          getVendors(context_key, 'vendors', false, callback_array);

          service[context_key]['vendor_obj'].vendorName = angular.copy(vendor.name);
          $rootScope.dirty = false;
          $signalProvider.signal("feedback", ['success', 'vendorUpdated']);
          $state.go('.',{'vendorID': vendor.id}); //We are turning off the state event, and just updating the url
        },
        function(error) {
          $log.log('Vendor Error', error);
          $rootScope.dirty = false;
          $signalProvider.signal("feedback", ['failure', 'vendorUpdated', error]);
        });
    }

    // Removes a User from a vendor
    function removeUser(context_key, $event, user) {
      $event.stopPropagation();
      $event.preventDefault();

      $log.log('Delete Confirm Modal');
      var confirm = modalManager.openModal({
        templateUrl: 'views/common/modals/confirm.html',
        controller: 'ConfirmModalCtrl',
        windowClass: 'confirm',
        resolve: {
          title: function () {
            return 'Are you sure you want to delete this user from this vendor?';
          },
          body: function () {
            //This can accept html
            return 'You will be deleting user \"'+ user.name +'\" from this vendor.';
          },
          button: function () {
            return 'Delete';
          }
        }
      });

      //Remove the modal from the dom
      confirm.result.then(function() {
        var user_length = service[context_key]['vendor_obj'].selected.users.length;
        for(var i=0;i<user_length;i++) {
          if(service[context_key]['vendor_obj'].selected.users[i].id===user.id) {
            service[context_key]['vendor_obj'].selected.users.splice(i,1);
            break;
          }
        }
        updateProjectUsers(context_key);
      });
    }

    // Open drawer with server form
    function goToServer(server) {
      var url = $state.current.name.replace(/[.]/gi, "/") + '/server.edit';
      $log.log("goToServer", url);
      $state.go(url, {vendorID: $stateParams.vendorID, poolID: server.id}, { notify: false });
    }

    // Removes server from vendor
    function removeServer(context_key, $event, server) {
      if($event!==null) {
        $event.stopPropagation();
        $event.preventDefault();

        $log.log('Delete Confirm Modal');
        var confirm = modalManager.openModal({
          templateUrl: 'views/common/modals/confirm.html',
          controller: 'ConfirmModalCtrl',
          windowClass: 'confirm',
          resolve: {
            title: function () {
              return 'Are you sure you want to delete this server from this vendor?';
            },
            body: function () {
              //This can accept html
              return 'You will be deleting server \"' + server.name + '\" from this vendor.';
            },
            button: function () {
              return 'Delete';
            }
          }
        });
        //Remove the modal from the dom
        confirm.result.then(function () {
          var pool_length = service[context_key]['vendor_obj'].selected.pools.length;
          for (var i = 0; i < pool_length; i++) {
            if (service[context_key]['vendor_obj'].selected.pools[i].id === server.id) {
              service[context_key]['vendor_obj'].selected.pools.splice(i, 1);
              break;
            }
          }
          //We need to validate if we can actually save this vendor
          updateVendor(context_key);
        });
      }else {
        var pool_length = service[context_key]['vendor_obj'].selected.pools.length;
        for (var i = 0; i < pool_length; i++) {
          if (service[context_key]['vendor_obj'].selected.pools[i].id === server.id) {
            service[context_key]['vendor_obj'].selected.pools.splice(i, 1);
            break;
          }
        }
        //We need to validate if we can actually save this vendor
        updateVendor(context_key);
      }
    }

    // Deletes a vendor
    function deleteVendor(context_key, callback_array) {
      $log.log('Delete Confirm Modal');
      var confirm = modalManager.openModal({
        templateUrl: 'views/common/modals/confirm.html',
        controller: 'ConfirmModalCtrl',
        windowClass: 'confirm',
        resolve: {
          title: function () {
            return 'Are you sure you want to delete this vendor?';
          },
          body: function () {
            //This can accept html
            return 'You will be deleting vendor \"'+ service[context_key]['vendor_obj'].selected.name +'\" from this project.';
          },
          button: function () {
            return 'Delete';
          }
        }
      });
      //Remove the modal from the dom
      confirm.result.then(function() {
        var promise = VendorsResource.deleteVendor({
          vendorID: service[context_key]['vendor_obj'].selected.id,
          projectID: $stateParams.projectID
        }).$promise;

        promise.then(function(data) {
            $log.log('Vendor Deleted');
            getVendors(context_key, 'vendors', 'DELETE', callback_array);
            $rootScope.dirty = false;
            service[context_key]['vendor_obj'].vendorID = '';
            addNewVendor(context_key, {})
          },
          function(error) {
            $log.log('Vendor Delete Error');
            $log.error(error);
            $rootScope.dirty = false;
          });
      });
    }

    // Creates a new vendor
    function createVendor(context_key, callback_array) {
      var user_ids = [];
      var server_ids = [];
      var userGroup_ids = [];

      if(typeof service[context_key]['vendor_obj'].selected.users !== 'undefined') {
        var users_length = service[context_key]['vendor_obj'].selected.users.length;
        for (var i = 0; i < users_length; i++) {
          user_ids.push(service[context_key]['vendor_obj'].selected.users[i].id);
        }
      }

      if(typeof service[context_key]['vendor_obj'].selected.pools !== 'undefined') {
        var servers_length = service[context_key]['vendor_obj'].selected.pools.length;
        for (var x = 0; x < servers_length; x++) {
          server_ids.push(service[context_key]['vendor_obj'].selected.pools[x].id);
        }
      }

      if(typeof service[context_key]['vendor_obj'].selected.userGroups !== 'undefined') {
        var servers_length = service[context_key]['vendor_obj'].selected.userGroups.length;
        for (var x = 0; x < servers_length; x++) {
          userGroup_ids.push(service[context_key]['vendor_obj'].selected.userGroups[x].id);
        }
      }

      var payload = {
        projectID: $stateParams.projectID,
        name: service[context_key]['vendor_obj'].selected.name,
        organizationID: $stateParams.organizationID,
        userIDs: user_ids,
        poolIDs: server_ids,
        applicationID: $rootScope.storage.application.id,
        userGroupIDs: userGroup_ids
      }

      var promise = VendorsResource.saveVendor(payload).$promise;
      promise.then(function(vendor) {
          $log.log('Vendor Saved', vendor);
          getVendors(context_key, false, false, callback_array);
          if(service[context_key]['vendor_obj'].vendorForm) {
             service[context_key]['vendor_obj'].vendorForm.$setPristine(); // We have to force form dirty on view switches
          }
          service[context_key]['vendor_obj'].selected = vendor;
          service[context_key]['vendor_obj'].vendorID = vendor.id;
          $rootScope.dirty = false;

          $state.go('.',{'vendorID': vendor.id}); //We are turning off the state event, and just updating the url
        },
        function(error) {
          $log.log('Vendor Error', error);
          $rootScope.dirty = false;
        });
    }

    // Updates a server on a vendor
    function updateVendorServer(context_key, name, value) {
      if(typeof service[context_key]['vendor_obj'].selected.pools === 'undefined') {
        service[context_key]['vendor_obj'].selected.pools = [];
      }

      if(value.method==='create') {
        //TODO: Change to vendors.vendor.servers once api catches up
        //$scope.vendors.servers.push(value.object);
        service[context_key]['vendor_obj'].selected.pools.push(value.object);
      }else if(value.method==='delete') {
        removeServer(context_key, null, value.data);
        return;
      }else{
        var server_length = service[context_key]['vendor_obj'].selected.pools.length;
        for(var i=0;i<server_length;i++) {
          if(service[context_key]['vendor_obj'].selected.pools[i].id === value.object.id) {
            service[context_key]['vendor_obj'].selected.pools[i] = value.object;
            break;
          }
        }
      }

      if($stateParams.vendorID === 'add') {
        // createVendor(context_key);
      }else{
        //updateVendor(context_key);
      }
    }

    //We need to wait for the transition to complete before selecting buttons
    function waitForTransition() {

      $timeout(function () {
        service.wait = false;
      }, 4000);

    }

    // initializes the services and gets all the data we need
    function init(context_key) {

      if (!service[context_key]){
        setContext(context_key, {});
      }
      resetVendor(context_key);
      getVendors(context_key, 'vendors', 'init');
      getOrganizations(context_key, 'organizations');
      getUsers(context_key, 'users');
      getUserGroups(context_key, 'userGroups');
      waitForTransition();
    }

    // Resets the vendor object to show the default data when creating a new vendor
    function resetVendor(context_key) {
      service[context_key]['vendor_obj'] = {
        selected: { users: [], pools: [], userGroups: [] },
        usersReset: false,
        userGroupsReset: false,
        errors: {},
        vendorName: 'vendor',
        user: '',
        userGroup: '',
        vendorID: 'add',
        projectUsers: {},
        cachedProjectUsers: {},
        projectUserGroups: {},
        cachedProjectUserGroups: {},
        vendorForm: ''
      };
    }



    ///////////////////////////
    // SERVER DRAWER METHODS //
    ///////////////////////////


    // initializes the services and gets all the data we need
    function initServer(context_key) {
      $log.log("VendorsService initServer --- ", context_key);
      if (!service[context_key]){
        setContext(context_key, {});
      }
      resetServer(context_key);
      getPools(context_key, 'pools');
      getStorageDevices(context_key, 'storage_devices');
      getPoolTypes(context_key, 'pool_types');
      getPool(context_key, 'pool');
      getOutboundPool(context_key, 'region_pool');
      // getAvailableServer(context_key, 'available_server');

      //TODO: I guess we need to init this function at the start
      updateServerInfo(context_key);

      if ($stateParams.poolID === 'add') {
        $log.log('User click on add button');
        service[context_key].userAction = 'EXISTING';
        service[context_key].showUserAction = true;
        isAvailableServerFiltered = false;
      }

    }

    function toggleServerCreation(context_key) {
      service[context_key].new_pool = !service[context_key].new_pool;
      service[context_key].existing_pool = undefined;
    }

    // Resets the vendor object to show the default data when creating a new vendor
    function resetServer(context_key) {
      service[context_key]['server_obj'] = {
        poolID: 'add',
        form: {
          list: [],
          currentPoolName: '',
          storageDevices: {}
        },
        pool: {},
        errors: {},
        err: {
          valid: [
            { 'host'   : null },
            { 'root'   : null },
            { 'port'   : null },
            { 'secure' : null }
          ],
          invalidText: [
            'Bad host.',
            'Bad root.',
            'You cannot enter a number less than 1 or greater than 65536.',
            'Bad secure.'
          ],
          getError: function(i) {
            if(i === 'host')    return service[context_key]['server_obj'].err.invalidText[0];
            if(i === 'root')    return service[context_key]['server_obj'].err.invalidText[1];
            if(i === 'port')    return service[context_key]['server_obj'].err.invalidText[2];
            if(i === 'secure')  return service[context_key]['server_obj'].err.invalidText[3];
            return '';
          }
        },
        deleting: false,
        saving: false,
        toggleEditName: false
      };

      service[context_key].showUserAction = false;
      service.isServerInOtherVendors = false;
      delete service[context_key].selected_server;
      isEditExistingServer = false;
      service[context_key].selected_server = undefined;
      pool = {};
    }

    // Gets the pools data
    function getPools(context_key, object_key) {
      try {
        //start validation
        if (context_key === undefined || !service.hasOwnProperty(context_key)) {
          throw 'missing context_key';
        }

        //make the object key
        if (!service[context_key][object_key]) {
          service[context_key][object_key] = {};
        }

        //end validation

        //The Service state is in init.
        if (!service[context_key][object_key].state) {
          service[context_key][object_key].state = 'init';
        }

        //We are loading new information
        service[context_key][object_key].loading = true;

        var promise = PoolsResource.getPools({projectID: $stateParams.projectID}).$promise;
        promise.then(function (data) {
          //IF we have some data we should
          if (data.length >= 1) {
            updateObjects(context_key, object_key, data);
          }

          //turn off loading
          service[context_key][object_key].loading = false;
        }, function (error) {
          $log.error('error', error);
          service[context_key][object_key].state = 'error';
          service[context_key][object_key].loading = false;
        });
      } catch (error) {
        $log.error('error', error);
        service[context_key][object_key].state = 'error';
        service[context_key][object_key].loading = false;
      }
    }

    // Gets the Storage Devices data
    function getStorageDevices(context_key, object_key) {
      try {
        //start validation
        if (context_key === undefined || !service.hasOwnProperty(context_key)) {
          throw 'missing context_key';
        }

        //make the object key
        if (!service[context_key][object_key]) {
          service[context_key][object_key] = {};
        }

        //end validation

        //The Service state is in init.
        if (!service[context_key][object_key].state) {
          service[context_key][object_key].state = 'init';
        }

        //We are loading new information
        service[context_key][object_key].loading = true;

        var promise = Storage.getStorageBackends().$promise;
        promise.then(function (data) {
          //IF we have some data we should
          if (data.backends.length >= 1) {
            var storageDevices = [];
            for (var n=0; n<data.backends.length; n++) {
              storageDevices[n] = {name: data.backends[n]};
            }
            updateObjects(context_key, object_key, storageDevices);
          }

          //turn off loading
          service[context_key][object_key].loading = false;
        }, function (error) {
          $log.error('error', error);
          service[context_key][object_key].state = 'error';
          service[context_key][object_key].loading = false;
        });
      } catch (error) {
        $log.error('error', error);
        service[context_key][object_key].state = 'error';
        service[context_key][object_key].loading = false;
      }
    }

    // Gets the Pool Types data
    function getPoolTypes(context_key, object_key) {
      try {
        //start validation
        if (context_key === undefined || !service.hasOwnProperty(context_key)) {
          throw 'missing context_key';
        }

        //make the object key
        if (!service[context_key][object_key]) {
          service[context_key][object_key] = {};
        }

        //end validation

        //The Service state is in init.
        if (!service[context_key][object_key].state) {
          service[context_key][object_key].state = 'init';
        }

        //We are loading new information
        service[context_key][object_key].loading = true;

        var promise = PoolsResource.getPoolTypes({projectID: $stateParams.projectID}).$promise;
        promise.then(function (data) {
          //IF we have some data we should

          if (data) {
            updateObjects(context_key, object_key, data);
          }

          //turn off loading
          service[context_key][object_key].loading = false;
        }, function (error) {
          $log.error('error', error);
          service[context_key][object_key].state = 'error';
          service[context_key][object_key].loading = false;
        })
        .finally(()=>{
          updateServerInfo(context_key);
        });
      } catch (error) {
        $log.error('error', error);
        service[context_key][object_key].state = 'error';
        service[context_key][object_key].loading = false;
      }
    }

    // Gets a single pool
    function getPool(context_key, object_key) {
      $log.log("VendorsService --- getPool", context_key, object_key);
      try {
        //start validation
        if (context_key === undefined || !service.hasOwnProperty(context_key)) {
          throw 'missing context_key';
        }

        //make the object key
        if (!service[context_key][object_key]) {
          service[context_key][object_key] = {};
        }

        //end validation

        //The Service state is in init.
        if (!service[context_key][object_key].state) {
          service[context_key][object_key].state = 'init';
        }

        //We are loading new information
        service[context_key][object_key].loading = true;
        service[context_key]['pool'].data = {};

        //We clear the pool object if we select to create a new server
        if($stateParams.poolID==='add') {
          updateObjects(context_key, object_key, {});
          return;
        }

        var promise = PoolsResource.getPool({projectID: $stateParams.projectID, poolID: $stateParams.poolID}).$promise;
        promise.then(function (data) {
          //IF we have some data we should
          if (data) {
            updateObjects(context_key, object_key, data);
          }

          //turn off loading
          service[context_key][object_key].loading = false;
        }, function (error) {
          $log.error('PoolsResource -- error', error);
          service[context_key][object_key].state = 'error';
          service[context_key][object_key].loading = false;
        })
        .finally(()=>{
          updateServerInfo(context_key);
        });
      } catch (error) {
        $log.error('PoolsResource -- catch error', error);
        service[context_key][object_key].state = 'error';
        service[context_key][object_key].loading = false;
      }
    }

    // Gets the outbound pool if it exits
    function getOutboundPool(context_key, object_key) {
      try {
        //start validation
        if (context_key === undefined || !service.hasOwnProperty(context_key)) {
          throw 'missing context_key';
        }

        //make the object key
        if (!service[context_key][object_key]) {
          service[context_key][object_key] = {};
        }

        //end validation

        //The Service state is in init.
        if (!service[context_key][object_key].state) {
          service[context_key][object_key].state = 'init';
        }

        //We are loading new information
        service[context_key][object_key].loading = true;
        service[context_key][object_key] = {};

        //If there is not region on the project do not show region selection
        if($rootScope.storage.project.region) {

          var region_pool_name = 'region_outbound_' + $rootScope.storage.project.region.name;

          var promise = PoolsResource.getAdminPool({
            'projectID': null,
            'name': region_pool_name
          }).$promise;
          promise.then(function (pools) {

            service[context_key][object_key] = pools;

            //turn off loading
            service[context_key][object_key].loading = false;

          }, function (error) {
            $log.error('error', error);
            service[context_key][object_key].state = 'error';
            service[context_key][object_key].loading = false;
          });
        }

      } catch (error) {
        $log.error('error', error);
        service[context_key][object_key].state = 'error';
        service[context_key][object_key].loading = false;
      }

    }

    // Checks for the type of server to know what to show on the dom
    function updateServerInfo(context_key) {
      if(!service[context_key]['pool'] || !service[context_key]['pool'].data) { return; }
      var type = service[context_key]['pool'].data.type;

      if (type
        && service[context_key]['pool_types'].data[type.name]
        && service[context_key]['pool_types'].data[type.name].properties.credential
        && service[context_key]['pool_types'].data[type.name].properties.credential.properties) {
        var credential = [];
        var credential_properties = service[context_key]['pool_types'].data[type.name].properties.credential.properties;

        for (var cred_key in credential_properties) {
          if (type.name.toLowerCase() === "aspera" && cred_key.toLowerCase() === 'issystem') {
            //skip isSystem - hardcoding. Remove when implemented on backend.
          } else if (credential_properties.hasOwnProperty(cred_key)) {
            credential_properties[cred_key].property_name = cred_key;
            credential.push(credential_properties[cred_key]);
          }
        }
        service[context_key]['server_obj'].form.credential = credential;
      } else {
        service[context_key]['server_obj'].form.credential = null;
      }

      if(type
        && service[context_key]['pool_types'].data[type.name]
        && service[context_key]['pool_types'].data[type.name].properties.connectionInformation
        && service[context_key]['pool_types'].data[type.name].properties.connectionInformation.properties){

        var connection_information = [];
        var connection_information_properties = service[context_key]['pool_types'].data[type.name].properties.connectionInformation.properties;

        for (var conn_key in connection_information_properties) {
          if (connection_information_properties.hasOwnProperty(conn_key)) {
            connection_information_properties[conn_key].property_name = conn_key;
            connection_information.push(connection_information_properties[conn_key]);
          }
        }
        service[context_key]['server_obj'].form.connectionInformation = connection_information;
      } else{
        service[context_key]['server_obj'].form.connectionInformation = null;
      }

      if(type
        && service[context_key]['pool_types'].data[type.name]
        && service[context_key]['pool_types'].data[type.name].properties.transitServerInformation
        && service[context_key]['pool_types'].data[type.name].properties.transitServerInformation.properties){
        var transit_server = [];
        var transit_server_properties = service[context_key]['pool_types'].data[type.name].properties.transitServerInformation.properties;
        for (var transitkey in transit_server_properties) {
          if (transit_server_properties.hasOwnProperty(transitkey)) {
            transit_server_properties[transitkey].property_name = transitkey;
            transit_server.push(transit_server_properties[transitkey]);
          }
        }
        service[context_key]['server_obj'].form.transitServerInformation = transit_server;
      } else{
        service[context_key]['server_obj'].form.transitServerInformation = null;
      }

    }

    // Validates the Port
    function validatePort(e) {
      if( e.currentTarget.value < 1 || e.currentTarget.value > 65536) {
        return false;
      }
      return true;
    }

    //TODO: we might need to work on how this is working
    function back() {
      $log.log('back btn clicked')

      // //TODO: its probably easier just to close the drawer then find the actual state
      // //TODO: we need to rethink this now that we have / in our state names
      // var toParent = $filter('findParentStateFromSlashes')($state.current.name);
      // var fromParent = $filter('findParentState')(currentState);
      //
      // //We need to check to see if we are going to the parent state.
      // if (!toParent || !fromParent || toParent === fromParent) {
      //   $state.go(currentState);
      // }
      //
      // var state_array = $state.current.name.split('.');
      // state_array.splice(state_array.length-2); // Because the drawer and the state are two states below vendor state
      // var state = state_array.join('.');
      // $state.go(state, $stateParams, {notify: false});

      $state.go('^.^', $stateParams, {notify: false});
    }

    // Saves the Server data
    //TODO: We need to update this name to say what it is saving
    function save(context_key) {

      $rootScope.debounce = true;

      if(service[context_key]['existing_pool']) {
        var data = {};
        data.object = service[context_key]['existing_pool'];
        data.method = "create";
        updateVendorServer(context_key, 'update_vendor_server', data);
        back();
      } else {

        service[context_key]['server_obj'].saving = true;
        service[context_key]['server_obj'].toggleEditName = false;
        service[context_key]['pool'].data.projectID = $stateParams.projectID;
        service[context_key]['pool'].data.applicationID = $rootScope.storage.application.id;

        //If we are submitting values here do not send the dropdown
        if (service[context_key]['pool'].data.credential
          && service[context_key]['pool'].data.credential.secret === ''
          && service[context_key]['pool'].data.credential.key === '') {
          service[context_key]['pool'].data.credentialID = service[context_key]['region_pool'].credential.id
        }

        //Override the object to match the schema
        pool = angular.copy(service[context_key]['pool'].data);
        pool.type = service[context_key]['pool'].data.type.name;

        if ($stateParams.poolID === 'add' && isEditExistingServer === false) { //If we are creating a new pool, first save the pool, then save the users
          //Save pool
          var promise = PoolsResource.updatePool(pool).$promise;
          promise.then(function (response) {
              //Creating the pool was successful, now we need to save the users
              $log.info('Created Pool');
              var data = {};
              data.method = 'create';
              data.object = response;
              service[context_key]['pool'].data = response;
              updateVendorServer(context_key, 'update_vendor_server', data);
              $stateParams.poolID = response.id;
              $rootScope.debounce = false;
              $rootScope.dirty = false;
              back();
              //$state.go('.',{poolID: response.id}, { notify: false }); //We are turning off the state event, and just updating the url
            },
            function (error) {
              $log.log(error);
              $rootScope.debounce = false;
              $rootScope.dirty = false;
            });
        } else {

          // If user is edit or select an existing server
          updateExistingServer(context_key);
          return false;
        }
      }
    }

    // Deletes the Server
    function Delete(context_key) {
      var item = service[context_key]['pool'].data;
      item.deleteEntityType = 'pool';

      var confirm = modalManager.openModal({
        templateUrl: 'views/common/modals/confirm.html',
        controller: 'ConfirmModalCtrl',
        windowClass: 'confirm',
        resolve: {
          title: function () {
            return 'Are you sure you want to delete this server?';
          },
          body: function () {
            //This can accept html
            return 'You will be deleting server \"'+ item.name +'\" from this project.';
          },
          button: function () {
            return 'Delete';
          }
        }
      });

      //Remove the modal from the dom
      confirm.result.then(function () {
        service[context_key]['server_obj'].deleting = true;
        PoolsResource.deletePool({ projectID: $stateParams.projectID, poolID: $stateParams.poolID}).$promise.then(function(data){
          service[context_key]['server_obj'].deleting = false;
          var server = {};
          server.data = item;
          server.method = 'delete';
          updateVendorServer(context_key, 'update_vendor_server', server);
          $rootScope.dirty = false;
          back();
        }, function(error){
          service[context_key]['server_obj'].deleting = false;
          $rootScope.dirty = false;
        });
      });
    }

    function selectionErrorCheck(item, selected) {
      //hasErrors('"vendors_list"');
    }

    // Checks for error on the form
    function hasErrors(context_key){
      if($rootScope.debounce){
        return true;
      }

      if(service[context_key]['existing_pool']) {
        return false;
      }

      if(!service[context_key]['pool']) {
        return true;
      }

      if(!service[context_key]['pool'].data) {
        return true;
      }

      if(!service[context_key]['pool'].data.name || service[context_key]['pool'].data.name.length === 0){
        return true;
      }

      if(!service[context_key]['pool'].data.type){
        return true;
      }

      if(service[context_key]['server_obj'].errors.name) {
        return true;
      }

      if(service[context_key]['server_obj'].form.credential
        && $stateParams.poolID === 'add'){
        for(var i = 0; i < service[context_key]['server_obj'].form.credential.length; i++){
          var required_cred_property = service[context_key]['server_obj'].form.credential[i].property_name;
          if(!service[context_key]['pool'].data.credential
            || !service[context_key]['pool'].data.credential.hasOwnProperty(required_cred_property)
            || typeof service[context_key]['pool'].data.credential[required_cred_property] === 'undefined'){
            return true;
          }
        }
      }

      if(service[context_key]['server_obj'].form.connectionInformation){
        for(var j = 0; j < service[context_key]['server_obj'].form.connectionInformation.length; j++){
          var required_conn_property = service[context_key]['server_obj'].form.connectionInformation[j].property_name;
          if(!service[context_key]['pool'].data.connectionInformation || !service[context_key]['pool'].data.connectionInformation.hasOwnProperty(required_conn_property) || typeof service[context_key]['pool'].data.connectionInformation[required_conn_property] === 'undefined'){
            return true;
          }
        }
      }

      return false;
    }

    /**
     * Append the list of pools used in the Project in the given object_key
     * @param {String} context_key 
     * @param {String} object_key 
     * @returns {void}
     * @author Pankaj Sharma
     */
    function getAvailableServer(context_key, object_key) {

      try {
        if (context_key === undefined || !service.hasOwnProperty(context_key)) {
          throw new Error('missing context_key');
        }

        if (!service[context_key][object_key]) {
          service[context_key][object_key] = {};
        }

        //The Service state is in init.
        if (!service[context_key][object_key].state) {
          service[context_key][object_key].state = 'init';
        }

        //We are loading new information
        service[context_key][object_key].loading = true;
        var response = getUniquePoolsFromVendors(allVendorsList);

        updateObjects(context_key, object_key, response.data);


      } catch(e) {
        $log.log('Error in getAvailableServer')
        $log.log(e);
        service[context_key][object_key].state = 'error';
        service[context_key][object_key].loading = false;
      }
    }


    /**
     * The function `getUniquePoolsFromVendors` takes an array of vendor objects, extracts unique pool
     * objects from each vendor's `pools` property, and returns an object with the unique pool objects
     * as the `data` property.
     * @param {Array} vendors - An array of objects representing vendors.
     * @returns { { loading, data, state } }
     * @author Pankaj Sharma
     */
    function getUniquePoolsFromVendors(vendors) {

      var pools = {};

      var result = {
        loading: false,
        data: pools,
        state: 'success',
      }


      vendors.forEach(function(vendor) {

        if (vendor.hasOwnProperty('pools')) {

          // loop over existing pools
          vendor.pools.forEach(function(pool) {
            if (!(pool.id in pools)) {
              pools[pool.id] = pool;
            }
          });
        }


      });

      result.data = (Object.keys(pools).length > 0) ? Object.values(pools) : [];

      return result;

    }



    /**
     * This function runs on @event of (Select Existing Server Dropdown) and store the user current selection in
     * `selected_server` key.
     * @param {String} context_key 
     * @returns {void}
     */
    function updateExistingServerInfo(context_key) {

      $log.log('Select existing server');
      service[context_key].pool.loading = false ;
      service[context_key].pool.data = angular.copy(service[context_key].selected_server);
      var type = service[context_key].pool.data.type;
      service[context_key].pool.data.type = {
        name: type
      }
      service[context_key].showUserAction = false;
      isEditExistingServer = true;
      updateServerInfo(context_key);

    }


    /**
     * The function `updateExistingServer` is used to update the information of an existing server in a
     * pool. If user is trying to add an existing server or update a server first we check user is modifying the server details
     * or not by checking with the existing-server API.
     * YES - Show vendors list where server is already attached
     * NO - Update existing server
     * @param context_key {string}
     * @returns {void}.
     */
    function updateExistingServer(context_key) {

      try {

        toggleLoader({ text: 'Validating. Please Wait...' }, 'show');

        /** @type {String} */
        var url = config.baseURL + '/projects/' + $stateParams.projectID + '/pools/existingServer/' + pool.id;

        $http.post(
          url,
          pool
        ).then(function (response) {

          var result = response.data.data;

          if (!result) {
            throw new Error('data key is missing in response');
          }

          if (result.data_updated === false) {
            // update existing server information
            updateServer(context_key);
            toggleLoader({}, 'hide');
            return false;
          } else {
            $log.log('User edit existing server details');
            service.isServerInOtherVendors = true;
            service.pageTitle = UPDATE_SERVER;
            service.serverVendorsList = result.vendors_data;
            toggleLoader({}, 'hide');
            $rootScope.debounce = false;
          }


        }).catch(function (error) {
          $log.log('Error in update existing server', error);
          $rootScope.debounce = false;

          $signalProvider.signal("feedback", ['failure', 'UpdatePool']);
          toggleLoader({}, 'hide');
        });

      } catch (error) {
        $log.log('Error in update existing server', error);
        $signalProvider.signal("feedback", ['failure', 'UpdatePool']);
        $rootScope.dirty = false;

        toggleLoader({}, 'hide');
      }

    }

    /**
     * The function `updateServer` updates a pool on the server and handles success and error cases.
     * @param context_key {string} - The `context_key` parameter is a key used to identify the context or
     * category of the server being updated. It is used to access and update the corresponding data in
     * the `service` object.
     */
    function updateServer(context_key) {

      try {

        $rootScope.debounce = true;
        var promise = PoolsResource.updatePool({poolID: pool.id}, pool).$promise;

        toggleLoader({ text: 'Validating. Please Wait...' }, 'show');

        promise.then(function (response) {
          $log.log('Pool updated successfully');
          var data = {};
          data.method = isEditExistingServer ? 'create' : 'update';
          data.object = response;
          service[context_key]['pool'].data = response;
          updateVendorServer(context_key, 'update_vendor_server', data);
          $rootScope.debounce = false;
          $rootScope.dirty = false;
          toggleLoader({}, 'hide');
          $signalProvider.signal("feedback", ['success', 'UpdatePool']);
          back();
        }, function (error) {
          $log.log(error);
          $rootScope.debounce = false;
          $rootScope.dirty = false;
          toggleLoader({}, 'hide');
          $signalProvider.signal("feedback", ['failure', 'UpdatePool']);
        });

      } catch (error) {
        $log.log('Error updating server', error);
        $rootScope.debounce = false;
        $rootScope.dirty = false;
        $signalProvider.signal("feedback", ['failure', 'UpdatePool']);
      }

    }

    function backExistingServer(context_key) {

      try {

        service.isServerInOtherVendors = false;
        service.pageTitle = ADD_SERVER;

      } catch (error) {
        console.log('Error', error);
      }

    }

    //END METHODS

    //START UTILS

    function updateObjects(context_key, object_key, model) {
      service[context_key][object_key].data = model;
      service[context_key][object_key].state = 'success';
      service[context_key][object_key].loading = false;
    }

    /**
     * The function toggleLoader is used to show or hide a loading modal based on the action parameter.
     * @param {{ text: string } | {}} data - The data object containing the text for the loader.
     * @param {'hide' | 'show'} action - The action to perform on the loader.
     *                                    - 'hide': To hide the loader.
     *                                    - 'show': To display the loader.
     * @returns {void}
     */
    function toggleLoader(data, action) {

      if (action === 'hide') {
        setTimeout(function() {
          modalManager.closeCurrentModal();
        }, 800);
        return;
      }

      modalManager.openModal({
        templateUrl: 'views/common/modals/loading.html',
        controller: 'LoadingModalCtrl',
        windowClass: 'loadingModal',
        resolve: {
          text: function () {
            return data.text;
          }
        }
      });

    }


    /**
     * filterAvailableServer method removes the already used server in selected vendor
     * from 'available_server'
     */
    function filterAvailableServer(context_key) {

      try {

        if (isAvailableServerFiltered === true) return;

        if (!context_key) {
          throw new Error('Context key not defined');
        }

        var available_server = service[context_key].available_server.data;
        var selected_vendor = service[context_key].vendor_obj.selected;

        if (!available_server) {
          $log.warn('Available server not found');
          return;
        }

        var selectedPoolIds = [];
        var updatedAvailableServer = [];

        selected_vendor.pools.forEach(function(pool) {
          selectedPoolIds.push(pool.id);
        });

        for (var i = 0; i < available_server.length; i++) {

          if (selectedPoolIds.indexOf(available_server[i].id) === -1) {
            updatedAvailableServer.push(available_server[i]);
          }
        }

        service[context_key].available_server.data = updatedAvailableServer;

        isAvailableServerFiltered = true;

      } catch (err) {
        $log.error('Error in filterAvailableServer', err);
        return false;
      }
    }

    //END UTILS
  }
})();
