'use strict';

/**
 * IG 6.30.15
 * 
 * Lazy loading (endless scroller)
 * 
 * Apply to any scrollable table / grid / div / etc. WITH the SCROLLBARZ directive on the same DOM element to auto-fetch 
 * additional data when scrolling reaches the bottom of the grid.
 * 
 * Required attributes: 
 * * lazy-load: This sets the method responsible for lazy loading of additional data
 * * scroll-data: This attribute sets the data that is being rendered and must be watched for changes
 * 
 * Optional attributes:
 * * lazy-load-options (override default directive configuration)
 */

angular.module('pulse')

.directive('lazyLoad', ['$window', '$log', '$timeout', '$rootScope', '$signalProvider', '$document',
     function($window, $log, $timeout, $rootScope, $signalProvider, $document) {
    
    var defaultOptions = {
      scrollThreshold: 20, //% value - how far from the bottom of the container we start another data fetch
      scrollThrottle: 600   //how frequently (in milisec) we can repeat the fetch call as the user continues to scroll
    };
    
    var lastCheck = new Date().getTime(),
        lastScrollBarPosition = 0; //most recent position of the scrollbar

    //Returns a function that only gets executed once within a given time period.
    function throttle(fn, delay) {
        var timeout,
            previous = 0;
        
        return function() { //note that this anonymous function is returned, not auto-executed in the throttle method
          var current = new Date().getTime(),
              remaining = delay - (current - previous), //the time remaining before our function can be executed is delay (in seconds) minus number of seconds since last execution of FN
              args = arguments; //arguments (if any) for the FN
        
          if (remaining <= 0) {
            if (timeout) {
              $timeout.cancel(timeout);
            }
        
            timeout = undefined;
            previous = current;        
            fn.apply(null, args);              
          } 
          else if (!timeout) {
            timeout = $timeout(function() { 
              timeout = undefined;
              previous = new Date().getTime();        
              fn.apply(null, args);
            }, remaining);
          }
        };
    }
    
    
    function tryFetch(scope, attrs) {
        scope.$apply(executeFunctionByName(attrs.lazyLoad, scope));
    }
    
    function executeFunctionByName(functionName, context /*, args */) {
        var args = Array.prototype.slice.call(arguments, 2);
        var namespaces = functionName.split(".");
        var func = namespaces.pop();
        context = buildContext(namespaces, context);
        return context[func].apply(context, args);
    }
    
    function buildContext(namespaces, context) {
        for (var i = 0; i < namespaces.length; i++) {
            context = context[namespaces[i]];
        }              
        return context;  
    }
    
    return {
        restrict: "A",
        link: function(scope, element, attrs) {
                        
            if (!attrs.hasOwnProperty('scrollData')) {
                $log.error("Endless Scroll - The scroll-data attribute was not detected. This attribute sets the data that is being rendered and must be watched for changes");
            }
            
            if (!attrs.hasOwnProperty('moreData')) {
                $log.error("Endless Scroll - The more-data attribute was not detected. This attribute identifies the scope property that the controller sets to inform of additional data being available");
            }            
                        
            if (!attrs.hasOwnProperty('scrollbarz')) {
                $log.error("Endless Scroll - The scrollbarz directive was not detected on this ", element.tagName, ". The endless scroll directive works in conjunction with the scrollbarz directive only");
            }
                        
            var options = angular.extend({}, defaultOptions, attrs.lazyLoadOptions);
            
            var mainElm = angular.element(element.children()[0]); //Parent of the innner container
            var container = angular.element(mainElm.children()[0]); //inner container of the scrollbarz directive (this wraps the ng-transclude element)                       
            
            var scroll = $signalProvider.listen("scrollbarz:scroll", checkScroll); //listen for scrolling events  
            var resetEvt = $signalProvider.listen("scrollbarz:reset", scrollBarReset); //listen for scrolling events  
            
            scope.$on('$destroy', function(){ //if parent scope goes away, so should our listener
                $log.info('Cleanup the lazyLoad directive');
                $signalProvider.unlisten("scrollbarz:scroll",scroll);
                $signalProvider.unlisten("scrollbarz:reset",resetEvt);
            });
            
            
             function scrollBarReset(name, value) {
                  if (value.id === attrs.scrollData && value.top) {
                      reset();
                  }
             }
                                    
            function reset() {
                $log.log(" - Endless Scroll Resetting - ");
                lastScrollBarPosition = 0;
            }
            
            //respond to user's scrolling if needed
            function checkScroll(name, value) {
                var now = new Date().getTime();
                var moreData = buildContext(attrs.moreData.split('.'), scope);
                if (value === attrs.scrollData && moreData === true && 
                        ( (now - options.scrollThrottle) >  lastCheck) ) {  //prevent continuous invocation
                    $log.log("Endless Scroll - Scrolling detected");
                    
                    /*
                        Scrollbarz directive arranges the following elements:
                        scrollbarz DIV - outer wrapper of the directive
	                       ngsb-wrap (fixed, short height)
		                      ngsb-container (may or may not reflect the height of the ng-transclude DIV. Don't rely on this element's height, only its top position, as it is what's scrolled by the user's action)
			                     ng-translude DIV (large height container of the actual data DOM elements. Use this with the 'top' position of its parent to determine when its to time to reload data.)
                    */
                    
                    //The height we check is the ng-transclude DIVs, as the ngsb-container may not reflect it.
                    var pixelThreshold = mainElm.height() * (options.scrollThreshold / 100);
                    if ( ($($(container).children()[0]).height() + container.position().top) <= (mainElm.height() + pixelThreshold) && //reached the bottom...
                         lastScrollBarPosition >  container.position().top) { //and actually moved the scrollbar position Downward
                      
                        //Notify user that additional data is about to load
                        var loading = angular.element("<div>");
                        loading.attr('id', attrs.scrollData + "_loader");
                        var barHeight = 40;
                        loading.css({   position:'absolute',
                                        backgroundColor: 'rgb(62,74,82)',
                                        height: barHeight + 'px',
                                        'vertical-align': 'middle',
                                        'text-align': 'left',
                                        'line-height': '1.8',
                                        padding: '2px 20px 0px 20px',
                                        'z-index': '399',
                                        width: mainElm.width()
                                   });
                        loading[0].left = loading[0].style.left = mainElm.offset().left + "px";
                        loading[0].top = loading[0].style.top = (mainElm.offset().top + mainElm.height() - barHeight) + "px";
                        loading.text('loading...');
                        
                        var body = $document[0].body;
                        $(body).append(loading);

                        lastScrollBarPosition = container.position().top;
                      
                        if (!this._throttledCheck) {
                          this._throttledCheck = throttle(tryFetch, options.scrollThrottle);
                        }

                        this._throttledCheck(scope, attrs); // fetch more data
                      
                        lastCheck = new Date().getTime();
                        $log.log("Endless Scroll - Lazy Loading activated at ", lastCheck);                                 
                    }
                }
            }
                  
            scope.$watch(function() { //watch for changes to the data to which we applied the endless scroll
                return buildContext(attrs.scrollData.split('.'), scope);
            },
            function(newValue, oldValue) { 
                if ((typeof newValue != 'undefined' && typeof oldValue == 'undefined') || (typeof newValue != 'undefined' && typeof oldValue != 'undefined' && newValue.length != oldValue.length)) {
                    $log.log("Endless Scroll - Data Update Detected");
                    scope.$broadcast(attrs.rebuildOn);
                    var loader = $("div[id='" + attrs.scrollData + "_loader" + "']");
                    loader.remove();
                }
                else {
                    $log.log("Endless Scroll - Data Identical, Scrollbar will not be Refreshed.");
                    var loaderHanging = $("div[id='" + attrs.scrollData + "_loader" + "']");
                    if (!!loaderHanging && loaderHanging.length) { loaderHanging.remove(); }
                }
            });
            
            var win = angular.element($window);
            win.on("resize",function(e) { //reset directive params on window resize
                reset();
            });
        }
    };
}]);