/**
 * @ngdoc directive
 * @name pulseSearch
 * @module pulse
 * @restrict E
 * @description
 * `pulseSearch` provides a searchbar for searching a list
 *
 * template attr is required
 *
 * @usage
 * <hljs lang="html">
        <pulse-search
            template = "views/templates/manager_search.html"
            searchquery = "search_pipelines"
            params = "false"
            init = "{'service' : 'Pipelines',
                    'method' : 'filterPipelines',
                    'context_key' : 'pipelines_list',
            }">
        </pulse-search>
 * </hljs>
 *
 */
(function() {
    'use strict';

    angular
        .module('pulse')
        .directive('pulseSearch', directive);

    /* @ngInject */
    function directive() {
        var directive = {
            restrict: 'EA',
            templateUrl: function(el, attr){
              if(attr.template !== undefined){
                return attr.template;
              }else{
                return '<h1>error loading searchbar : missing template</h1>';
              }
            },
            scope: {
              searchquery : '@searchquery',
              params : '=',
              field: '@',
              init : '='
            },
            controller: Controller
        };

        return directive;
    }

    Controller.$inject = ['$scope', '$log','ServiceManager'];

    /* @ngInject */
    function Controller($scope, $log, ServiceManager) {
      $log.log('pulse-search directive');
      $scope.vm = {
        params : $scope.params,
        search_field : '',
        search_methods : false,
        service : ServiceManager,
        searchData : searchData,
        init : init
      };

      if($scope.init){
        $scope.vm.init();
      }


      /*
       * Process the search form and calls the search method
       */
      function searchData(event) {
        $log.log('searchData Searching...', $scope.vm.service[$scope.init.service]);
        if(event) {
          event.preventDefault();
        }
        var params = {
            search: true,
            query: parseQuery($scope.vm.service[$scope.init.service][$scope.searchquery]),
            searchbar: {
              searched: true,
              value: $scope.vm.service[$scope.init.service][$scope.searchquery]
            }
          };
        if( Object.keys($scope.vm.extra_params).length !== 0) {
          var extraParamKey = Object.keys($scope.vm.extra_params)[0];
          var extraParamValue = Object.values($scope.vm.extra_params)[0]

          params[extraParamKey] = extraParamValue;
        }


        $scope.vm.search_method(params, $scope.init.context_key);
      }


      /*
       * Formats the search text into a correct format to send to the endpoint
       */
      function parseQuery(query) {
        $log.log('parseQuery', query);

        var search_array = [];
        var quotes_length = query ? query.split('"').length - 1 : 0;

        for (var i = 0; quotes_length > i; i += 2) {
          var extracted_text = extractText(query);
          var new_text = extracted_text.replace(/[:]/gi, '_@_');
          new_text = new_text.replace(/[ ]/gi, '_-_');
          query = query.replace('"' + extracted_text + '"', new_text);
        }

        //This is when they use any kind of compound searching
        var search_space_split = query ? query.split(' ') : [];
        var search_space_split_length = search_space_split.length;
        for (var i = 0; search_space_split_length > i; i++) {

          //We need to find the plus for compound searching
          if (search_space_split[i].indexOf('+') !== -1) {

            var compound_search_array = [];

            //split the compound search
            var search_plus_split = search_space_split[i].split('+');
            var search_plus_split_length = search_plus_split.length;

            for (var x = 0; search_plus_split_length > x; x++) {

              //Prevent users from searching something weird when they didnt finish their query
              if (search_plus_split[x] === '') {
                continue;
              }

              //look for key variables
              if (search_plus_split[x].indexOf(':') !== -1) {

                var search_colon_split = search_plus_split[x].split(':');

                //Add tokens back in
                var value = search_colon_split[1].replace(/_-_/g, ' ');
                value = value.replace(/_@_/g, ':');
                value = value.replace(/[+]/g, '');

                var search_query = {
                  'field': search_colon_split[0],
                  'value': value
                };

              } else {
                //Add tokens back in
                var value = search_plus_split[x].replace(/_-_/g, ' ');
                value = value.replace(/_@_/g, ':');
                value = value.replace(/[+]/g, '');

                var search_query = {
                  'field': '',
                  'value': value
                };

              }

              compound_search_array.push(search_query);
            }

            search_array.push(compound_search_array);

          } else {

            if (search_space_split[i].indexOf(':') !== -1) {
              //build the array
              var search_colon_split = search_space_split[i].split(':');

              //Add tokens back in
              var value = search_colon_split[1].replace(/_-_/g, ' ');
              value = value.replace(/_@_/g, ':');

              var search_query = {
                'field': search_colon_split[0],
                'value': value
              };

              search_array.push(search_query);

            } else {

              //if there is no key value pair just leave field blank

              //Add tokens back in
              var value = search_space_split[i].replace(/_-_/g, ' ');
              value = value.replace(/_@_/g, ':');

              search_array.push({
                'field': '',
                'value': value
              });

            }

          }
        }

        //Strip out blank values
        for (var i = search_array.length - 1; i >= 0; i--) {
          if (search_array[i].value === '') {
            search_array.splice(i, 1);
          }
        }

        return search_array;
      }


      /*
       * Extracts the texts from a string that has quotes
       */
      function extractText(string) {
        var substring = "";

        if (/"/.test(string) && string.match(/"(.*?)"/)) {
          substring = string.match(/"(.*?)"/)[1];
        } else {
          substring = string;
        }

        return substring;
      }


      /*
       * Initialized the directive default method and service
       */
      function init(){

         // bind the searchFunct
        $scope.vm.search_method = $scope.vm.service[$scope.init.service][$scope.init.method];

        // extra_params is undefined
        $scope.vm.extra_params = $scope.init.params ? $scope.init.params : {};

        // extra_params is undefined
        if (!$scope.vm.extra_params) {
          $scope.vm.extra_params = $scope.params;
        }

        // bind the searchquery property
        $scope.vm.service[$scope.init.service][$scope.searchquery] = '';

         // incase we want to only search one field, otherwise just leave blank
        if($scope.field) {
          $scope.vm.search_field = $scope.field;
        }
      }
    }
})();
