'use strict';

angular.module('pulse')
    .filter('isEmpty', function() {
            return function isEmpty(ob){
                for(var i in ob){ return false;}
                return true;
            };
        });

