/**
 * @ngdoc object
 * @name PermissionsSvc
 * @requires $resource
 */

'use strict';


//TODO: clean this up at some point
angular.module('pulse')

  .factory('PermissionsSvc', ['$resource', '$rootScope', '$q', 'UserPermissions', 'Users',
    function ($resource, $rootScope, $q, UserPermissions, Users) {

      var CACHE_EXPIRATION = 86400000; //miliseconds. 24 hours for now.

      $rootScope.userPermissions = {};
      var permissionNames = {
        SuperUser: 'super user',
        Provision: 'provision',
        Ingest: 'ingest structure',
        AdminStructure : 'admin structure',
        QueryStructure: 'query structure',
        CreateStructure: 'create structure',
        GetStructure: 'get structure',
        UpdateStructure: 'update structure',
        DeleteStructure: 'delete structure',
        AddRemoveUserFromProject: 'add remove user structure',
        FileManagerAdmin: 'filemanager:admin',
        FileManagerDownload: 'filemanager:download',
        FileManagerUpload: 'filemanager:upload',
        FileManagerRelease: 'filemanager:release',
        FileManagerView: 'filemanager:view',
        FileManagerDelete: 'filemanager:delete',
        StreamingDailiesAdmin: 'cloudtakes:admin',
        StreamingDailiesUpload: 'cloudtakes:upload',
        StreamingDailiesRelease: 'cloudtakes:release',
        StreamingDailiesViewClips: 'cloudtakes:view clips',
        StreamingDailiesViewPlaylists: 'cloudtakes:view playlists',
        StreamingDailiesDelete: 'cloudtakes:delete',
        StreamingDailiesEditComment: 'cloudtakes:edit comment',
        StreamingDailiesApproval: 'cloudtakes:approval',
        VFXAdminPull: 'admin pull',
        VFXCreatePull: 'create pull',
        VFXFinishPull: 'finish pull',
        VFXQueryPull: 'query pull',
        VFXAdminVendor: 'admin vendor',
        VFXUpdateVendor: 'update vendor',
        PreconformAdmin: 'preconform:admin',
        PreconformUpload: 'preconform:upload',
        PreconformTransfer: 'preconform:transfer',
        PreconformReels: 'preconform:reels',
        DIPullsAdminPull: 'di pulls:admin pull',
        DIPullsCreatePull: 'di pulls:create pull',
        DIPullsFinishPull: 'di pulls:finish pull',
        DIPullsQueryPull: 'di pulls:query pull',
        OCFMetadataAdmin: 'admin OCF Metadata'
      };
      var filemanagerPermissions = [
        'filemanager:admin',
        'filemanager:uploads',
        'filemanager:release',
        'filemanager:view'
      ];
      var streamingDailiesPermissions = [
        'cloudtakes:admin',
        'cloudtakes:upload',
        'cloudtakes:release',
        'cloudtakes:view clips',
        'cloudtakes:view playlists'
      ];
      var vfxPermissions = [
        'admin pull',
        'create pull',
        'finish pull',
        'query pull',
        'admin vendor',
        'update vendor'
      ];
      var preconformPermissions = [
        'preconform:admin',
        'preconform:upload',
        'preconform:transfer',
        'preconform:reels'
      ];
      var dipullsPermissions = [
        'di pulls:admin pull',
        'di pulls:create pull',
        'di pulls:finish pull',
        'di pulls:query pull'
      ];
      $rootScope.permissionNames = permissionNames;

      return {
        StreamingDailiesPermissions: streamingDailiesPermissions,
        FilemanagerPermissions: filemanagerPermissions,
        VFXPermissions: vfxPermissions,
        PreconformPermissions: preconformPermissions,
        PermissionNames: permissionNames,
        DIPullsPermissions: dipullsPermissions,

        //TODO: we need to get rid of this at some point, and give permissions back once for project
        checkUserPermissions: function(permissions){
          //Make sure these return promises so the ui-router completely resolves before reaching the controller
          if(permissions !== undefined) {
            return UserPermissions.findMeIncludePermissions({permissions: JSON.stringify(permissions)}).$promise;
          } else {
            return Users.User.findMe().$promise;
          }
        },

        //Resets all user permissions. This method will automatically get called on every route change.
        clearUserPermissions: function() {
          $rootScope.userPermissions = {};
        },

        //-- IG 10.28.15 -- DEPRECATED -- Get the currently populated user permissions.
        getUserPermissions: function(){
          return $rootScope.userPermissions;
        },


        //Append to the current list of user permissions. This is useful if permissions get calculated on different resolves in the same state.
        //This allows for the user permissions to persist for a short period of time (within the same route/state).
        //This method will automatically get called on every request for user permissions (e.g. findMe(permissions)).
        appendUserPermissions: function(permissions) {
          if(permissions !== undefined) {
            for (var target in permissions) {
              if (permissions.hasOwnProperty(target)) {
                for(var permission in permissions[target]){
                  if(permissions[target].hasOwnProperty(permission)){

                    if($rootScope.userPermissions[target] === undefined){
                      $rootScope.userPermissions[target] = {};
                    }

                    $rootScope.userPermissions[target][permission] = {
                      value : permissions[target][permission],
                      timeStamp : new Date().getTime()
                    };
                  }
                }
              }
            }
          }
        },

        //-- IG 10.28.15 -- DEPRECATED ORIGINAL VERSION, NOT CAPABLE OF CHECKING PERMISSIONS AGAINST THE DATABASE -- Checks if the current user has access in the targetID/permission context.
        hasPermission: function(targetID, permission){
          var permissions = this.getUserPermissions();

          if(permission === this.PermissionNames.SuperUser){
            targetID = null;
          }

          if(permissions[targetID] !== undefined && permissions[targetID][permission] !== undefined && permissions[targetID][permission].value != undefined && permissions[targetID][permission].value === true){
            return true;
          } else {
            return false;
          }
        },

        //-- IG 10.28.15 -- Checks if the current user has access in the targetID/permission context. Will verify against the database if permissions are not cached yet.
        hasPermission2: function(targetID, permission, kind) {
          var deferred = $q.defer();
          var thisInstance = this;
          var mulitple = false;

          if ( $.isFunction(permission) ) { //IG 12.11.15 - if request is based on an interpolation function, evaluate it now. This is used for verification of multiple permissions.
            permission = permission(permissionNames);
          }

          if(Array.isArray(permission)) {
            mulitple = true;
          }

          if(permission === this.PermissionNames.SuperUser){
            targetID = null;
          }

          var permissionObj = [
            {name: permission, targetID: targetID, kind: kind}
          ];

          if($rootScope.userPermissions[targetID] !== undefined && $rootScope.userPermissions[targetID][permission] !== undefined && $rootScope.userPermissions[targetID][permission].value != undefined && $rootScope.userPermissions[targetID][permission].value === true
            && $rootScope.userPermissions[targetID][permission].timeStamp + CACHE_EXPIRATION > new Date().getTime()){ //also verifying if cache item should be expired and re-fetched from DB

            deferred.resolve(true);

          } else {

            var promise = UserPermissions.getUserPermissions({permissions: JSON.stringify(permissionObj)}).$promise;
            promise.then(function(permissions) { //update the $rootScope.userPermissions object

              if (!!permissions) { thisInstance.appendUserPermissions(permissions); }
              if($rootScope.userPermissions[targetID] !== undefined && $rootScope.userPermissions[targetID][permission] !== undefined && $rootScope.userPermissions[targetID][permission].value != undefined && $rootScope.userPermissions[targetID][permission].value === true){
                deferred.resolve(true);
              } else {
                deferred.resolve(false);
              }

            });
          }

          return deferred.promise;
        },

        //This is a reusable method to check for common structure list type permissions. All structure list type permissions will need to check for Get and Delete structure permissions.
        //This method just builds out the permissions object to query for.
        getStructureEntityLevelPermissions: function(structureList){
          var permissions = [];
          for(var i = 0; i < structureList.length; i++){
            permissions.push({name: this.PermissionNames.GetStructure, targetID: structureList[i].id, kind: 1});
            //Delete Structure is now at the parent level instead of the entity level.
            //permissions.push({name: this.PermissionNames.DeleteStructure, targetID: structureList[i].id, kind: 1});
          }

          return permissions;
        },

        getStructurePermission: function(permission, target){
          return [{name: permission, targetID: target, kind: 1}];
        },

        getWAPI : function(){
          return $resource(
            config.baseURL + '/organizations/:organizationID/permissions',
            {organizationID: '@organizationID'},
            {
              getPermissions: {method: 'GET', transformResponse: function(data){
                  if(data === ''){
                    return [];
                  }

                  var permissions = angular.fromJson(data);
                  for (var i = permissions.length - 1; i >= 0; i--) {
                    var perm = permissions[i];

                    if(perm.target) {
                      perm.name = perm.name.replace(' structure', '') + ' (' + perm.target.name + ')';
                    }

                  }

                  return permissions;
                },
                isArray: true
              }
            }
          );
        },

        projectPermissions : function(){
          return $resource(
            config.baseURL + '/projects/:projectID/permissions',
            {projectID: '@projectID'},
            {
              getList: {method: 'GET', transformResponse: function(data){
                  if(data === ''){
                    return [];
                  }

                  var permissions = angular.fromJson(data);
                  for (var i = permissions.length - 1; i >= 0; i--) {
                    var perm = permissions[i];

                    if(perm.target) {
                      perm.name = perm.name.replace(' structure', '') + ' (' + perm.target.name + ')';
                    }

                  }

                  return permissions;
                },
                isArray: true
              }
            }
          );
        }

      };
    }
  ])



  .factory('UserPermissions',
    ['$resource', '$rootScope',
      function ($resource, $rootScope) {

        return $resource(
          config.baseURL +'/users/:id/:ext/:relationID',
          {id: '@id', ext: '@ext', permissions: '@permissions', userId: '@userId', divisionId: '@divisionId', projectId: '@projectId'},
          {
            getUserPermissions: {method: 'GET', params: {ext: 'me', 'permissions': '@permissions'},
              transformResponse: function(data){

                var user = angular.fromJson(data);
                // console.log('PERMISSION ++++++ user', user);
                var retVal = null;

                if(user !== undefined && user.hasOwnProperty('permissions')) {
                  retVal = user.permissions;
                }

                return retVal;
              }
            },
            //This requires you to passing permissions as a query string.
            findMeIncludePermissions: {method: 'GET', params: {
                ext: 'me',
                transformResponse: function(data){

                  if(!$rootScope.userPermissions) {
                    $rootScope.userPermissions = {};
                  }

                  var user = angular.fromJson(data);
                  if(user !== undefined && user.hasOwnProperty('permissions')) {
                    //PermissionsSvc.appendUserPermissions(user.permissions);
                    if(user.permissions !== undefined) {
                      for (var target in user.permissions) {
                        if (user.permissions.hasOwnProperty(target)) {
                          for(var permission in user.permissions[target]){
                            if(user.permissions[target].hasOwnProperty(permission)){

                              if($rootScope.userPermissions[target] === undefined){
                                $rootScope.userPermissions[target] = {};
                              }

                              $rootScope.userPermissions[target][permission] = {
                                value : user.permissions[target][permission],
                                timeStamp : new Date().getTime()
                              };
                            }
                          }
                        }
                      }
                    }
                  }
                  return user;
                }
              }}

          });

      }]);
