/**
 * @ngdoc directive
 * @name pulseInlineTimecode
 * @module pulse
 * @restrict E
 * @description
 * `pulseInlineTimecode` this helps with formating timecodes correctly by restricting the values that can be changed 
 *
 * @usage
    <pulse-inline-timecode 
        model="event.start"
        reset="vm.service[init.service][init.context_key].loading"
    ></pulse-inline-timecode>
 *
 * @param {string|integer} model - (required) This is the value to display and change
 * @param {boolean} (optional) reset - a flag to reset the dirty state and to reload the original model caching
 *
 */

(function() {
    'use strict';

    angular
        .module('pulse')
        .directive('pulseInlineTimecode', directive);

    /* @ngInject */

    directive.$inject = ['$log'];

    function directive($log) {
        var directive = {
            restrict: 'E',
            templateUrl: 'views/common/inputs/inline_timecode.html',
            scope: {
                model : '=',
                reset: '=?',
            },
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, el, attr) {

            // Always come in with scope dirty as false
            scope.dirty = false;

            // Always come in with scope reset as false
            scope.reset = false;

            // To keep track of errors on each input field of the timecode
            scope.error = false;

            // We are creating out own instance of the model so that we can tell when someone dirties the existing model
            var current_model = angular.copy(scope.model);

            // We check that the timecode is correct
            function isTimecodeValid() {
                var regex = /^(([0-9]{2}):([0-9]{2}):([0-9]{2}):([0-9]{2}))$/g;
                var is_valid = regex.test(scope.model);

                // update the error value to show on the dom
                scope.error = false;
                if(!is_valid) {
                    scope.error = true;
                }
                return is_valid;
            }


            // we update the timecode value after checking validity
            scope.update = function () {
                scope.model = scope.model.replace(/\s+/g, ''); // remove any whitespace

                // We check if the timecode is correct. if it is then we update the model and wait for it to be saved
                if(isTimecodeValid()) {

                    // if the timecode is different then we update the model and set the state to dirty
                    if(current_model !== scope.model) {
                        scope.dirty = true;
                    }
                } else {
                    scope.dirty = false;
                }
                current_model = angular.copy(scope.model);
            }

            // Sometimes we need to clear the dirty state from events outside of the directive
            scope.$watch('reset', function(new_reset, old_reset) {
                $log.log('Inline Timecode Input Watch Reset', new_reset);
                $log.log('Inline Timecode Input Watch Old Reset', old_reset);
                if(new_reset && new_reset !== old_reset) {
                    // Reset the dirty state
                    scope.dirty = false;

                    // update the model and remove errors
                    current_model = scope.model;
                    scope.error = false;
                    isTimecodeValid();
                }
            }, true);

            // we check if the first value we get is valid
            isTimecodeValid();
        }
    }
})();
