/**
 * @ngdoc object
 * @name ManifestsResource
 * @requires $resource
 */

(function(){

    'use strict';

    angular
        .module('pulse')
        .factory('ManifestsResource', ManifestsResource);

    ManifestsResource.$inject = ['$resource'];

    function ManifestsResource($resource) {

        return $resource (
            config.baseURL + '/projects/:projectID/:ext/:assetID/:action',
            {ext: '@ext', projectID: '@projectID', assetID: '@assetID', action: '@action'},
            {
              getManifests: {method: 'GET', params: {ext:'manifests'}, isArray: true},
              getManifestRecords: {method: 'GET', params: {ext:'manifests', action:'records'}, isArray: true},
              deleteMetadata: {
                method: 'POST',
                params: {ext:'manifests', assetID:'delete'},
                isArray: true
              },
            }
        );
    }
})();
