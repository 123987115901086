/**
 * @name: Select All Directive
 *
 */

'use strict';

angular.module('pulse')
    .directive('pulseSelectAll',
    [ '$log','$signalProvider','$state',
        function ($log,$signalProvider,$state) {

            return {
                restrict: 'E',
                replace: true,
                scope: {
                    multiple: '=', //If we have multiple select all directives using the same list (optional)
                    disabled: '=', //Disable the select all (optional)
                    items: '=',   //  the list of items to select from
                    list: '=',    //  the list of selected items
                    context: '@'  //  context is for signaling the directive's parent
                },

                /**
                 *
                 * @param scope
                 */
                link: function (scope) {
                    $log.log('Select All Directive');

                    //Validation for developing
                    if(!scope.context) {
                        $log.error('Select All Directive no scope context passed. You must pass the context for this directive to insure scope dependency.');
                        return false;
                    }

                    if(!scope.items) {
                        $log.error('Select All Directive no scope select-items passed. You must pass the selectable items array to this directive.');
                        return false;
                    }

                    if(!scope.list) {
                        $log.error('Select All Directive no scope select-list passed. You must pass the selected collection to this directive.');
                        return false;
                    }

                    var flag = false;
                    var click = false;
                    var selectText = 'Select All',
                        deselectText = 'Deselect All';

                    scope.state = $state.current.name; //Save the current state of the directive
                    scope.text = selectText;

                    //Check if we are already selecting all
                    if(scope.list.length>=scope.items.length && !scope.multiple) {
                        flag = true;
                        scope.text = deselectText;
                    }

                    //TODO: make a check to see if the list has updated
                    scope.$watch('list', function(newValue,oldValue) {
                        if(newValue.length !== oldValue.length && !click) {

                            $log.log('Update to list for '+scope.context);

                            var found = []; // Array of found objects

                            //Loop through this items list
                            var itemsLength = scope.items.length;
                            for(var i=0;i<itemsLength;i++) {
                                var itemAssetID = scope.items[i].assetID;
                                var listLength = scope.list.length;
                                for(var x=0;x<listLength;x++) {
                                    var listAssetID = scope.list[x].assetID;
                                    //If found item add it to the found array
                                    if(itemAssetID === listAssetID) {
                                        found.push(itemAssetID);
                                    }
                                }

                                //If dont find all of the items before we finish looping we can end the loop
                                if(i!==found.length-1 && found.length-1 !== -1) {
                                    $log.info('Select All Text');
                                    flag = false;
                                    scope.text = selectText;
                                    break;
                                }

                            }

                            //Determine if we have found all the items in the array.
                            if(itemsLength===found.length) {
                                $log.info('Deselect All Text');
                                flag = true;
                                scope.text = deselectText;
                            }else{
                                $log.info('Select All Text');
                                flag = false;
                                scope.text = selectText;
                            }

                        }
                    }, true);

                    /**
                     *  toggle the text of the button based on selection 'flag'
                     */
                    scope.toggleText = function() {
                        if(!flag) {
                            scope.text = selectText;
                        } else {
                            scope.text = deselectText;
                        }
                    };

                    /**
                     *  main process of selecting/deselecting clips
                     */
                    scope.process = function(keypress) {

                        click = true;
                        flag = !flag;

                        if(scope.multiple) {
                            if(!flag) {
                                //TODO: we can probably optimize this
                                var itemsLength = scope.items.length;
                                for(var i=0;i<itemsLength;i++) {
                                    var itemAssetID = scope.items[i].assetID;
                                    var deletedIndex = 0;
                                    var listLength = scope.list.length;
                                    for(var x=0;x<listLength;x++) {
                                        var listAssetID = scope.list[x-deletedIndex].assetID;
                                        if(itemAssetID === listAssetID) {
                                            scope.list.splice(x-deletedIndex,1);
                                            deletedIndex++;
                                        }
                                    }
                                }
                            }else{
                                //Recursively add the items to the list
                                var itemsLength = scope.items.length;
                                for(var i=0;i<itemsLength;i++) {
                                    scope.list.push(scope.items[i]);
                                }
                            }
                        }else{

                            if(!flag) {
                                //$log.log('Before Apply');

                                if(keypress) {
                                    //No DOM events have happened so we need to manually start digest
                                    scope.$apply(function() {
                                         scope.list = [];
                                    })
                                }else{
                                    scope.list = [];
                                }

                            }else{
                                scope.list = scope.items;
                            }
                        }

                        //Signal that select all has been toggled.
                        $signalProvider.signal(scope.context +'_selectAll', flag);
                        scope.toggleText();
                        click = false;

                    };

                    /**
                     *  handle CTL+'a' keyboard event
                     */
                    $signalProvider.listen('keydown', function(event, keyEvent) {
                        //If we arent in the same state as the directive don't run the short cut
                        //Also check to make sure we aren't need data

                        //TODO: Might make a warning for this
                        if(scope.disabled) {
                            $log.warn('Still collecting select all');
                        }

                        if(keyEvent.keyCode === 65
                            && $state.current.name === scope.state
                            && !scope.disabled
                            && (keyEvent.ctrlKey || keyEvent.metaKey)
                            && keyEvent.target.tagName !== 'INPUT'
                            && keyEvent.target.tagName !== 'TEXTAREA') {

                            $log.log('Keyboard Select All');
                            scope.process(true);

                        }
                    });
                },

                template: '<button ng-disabled="disabled" ng-click="process()" class="selectAll">{{text}}</button>'
            };
        }
    ]);
