/**
 * @ngdoc directive
 * @name pulseEditableText
 * @module pulse
 * @restrict E
 * @description
 * `pulseEditableText` makes text be editable by clicking on it
 *
 * @required
 * init is required with service, method
 *
 * @usage
 * <hljs lang="html">
 *   <pulse-editable-text
 *      value = "Title Name"
 *      init = "{'service' : 'PreconformEdls',
 *                'method' : 'updateEdlName'}">
 *   </pulse-editable-text>
 * </hljs>
 *
 * @param {object} init - this takes a few arguments
 * service - the service you want to run
 * method - the method on that service
 */


(function() {
  'use strict';

  angular
    .module('pulse')
    .directive('pulseEditableText', directive);

  /* @ngInject */

  directive.$inject = [];

  function directive() {

    var directive = {
      restrict: 'E',
      scope: {
        init: '=',
        value: '@',
        errorMsg: '@'
      },
      template: '<span class="locked-text"></span><input type="text" style="display:none;" /><span class="error-message">{{errorMsg}}</span>',
      link: linkFunc,
      controller: Controller
    };

    return directive;

    function linkFunc(scope, elem, attr, ctrl) {
      var textElem = elem[0].childNodes[0];
      var inputElem = elem[0].childNodes[1];
      var errorElem = elem[0].childNodes[2];
      var savedText = attr.value;
      setText(savedText);

      // When clicking on the text show input field and focus on it
      textElem.addEventListener('click', function () {
        showInput(true);
        inputElem.focus();
      }, false);

      // When clicking outside the input field hide the input field and check if the text has change and either save or just go back to original text
      inputElem.addEventListener('blur', function () {
        showInput(false);
        if(inputElem.value && inputElem.value.length > 0 && savedText !== inputElem.value) {
          scope.vm.service[scope.init.service][scope.init.context_key].pulseEditableText = inputElem.value;
          var updatedVal = scope.vm.service[scope.init.service][scope.init.method](scope.init.context_key, inputElem.value);
          setText(updatedVal)
        } else {
          setText(savedText);
        }
      }, false);

      // When clicking enter while on the input field hide the input field and this will trigger the blur event from above.
      inputElem.addEventListener('keyup', function(event) {
        event.preventDefault();
        if (event.keyCode == 13) { // enter key maps to keycode `27`
          showInput(false);
        } else if (event.keyCode == 27) { // escape key maps to keycode `27`
          setText(savedText);
          showInput(false);
        }
      });

      /**
       * @description
       * show or hide the input field to edit the text
       *
       * @param {boolean} status - to show or hide the input field
       */
      function showInput(status) {
        if(status === true) {
          errorElem.style.display = 'none';
          textElem.style.display = 'none';
          inputElem.style.display = 'block';
        } else {
          errorElem.style.display = '';
          textElem.style.display = 'inline-block';
          inputElem.style.display = 'none';
        }
      }

      /**
       * @description
       * this updates the text you are editing or showing
       *
       * @param {string} text - text to show
       */
      function setText(text) {
        textElem.innerHTML = text;
        inputElem.value = text;
        savedText = text;
      }
    }
  }

  Controller.$inject = ['$scope', '$log', 'ServiceManager'];

  /* @ngInject */
  function Controller($scope, $log, ServiceManager) {

    $log.info('Pulse Editable Text Controller');

    $scope.vm = {
      service : ServiceManager
    };

    $scope.$on('$destroy', function(){
      $log.info('Destroy Pulse Editable Text Controller');
    });
  }
})();
