/**
 * @ngdoc object
 * @name loading
 * @requires none
 */


(function(){
'use strict';
angular
    .module('pulse')
    .directive('loading', loadingDir);

    function loadingDir() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'views/common/loading/loading.html'
        };
        return directive;
    }

})();
