/**
 * @ngdoc object
 * @name PulseLoadingBarComponent
 * @requires $state
 * @description A resuable compent for a loading bar
 */

(function(){
  'use strict';

  angular.module('pulse').component('pulseLoadingBar', {
    templateUrl : 'views/common/components/pulse_loading_bar.html',
    bindings : {
      isLoading: '<',
      errorMessage: '<'
    }
  });
})();
