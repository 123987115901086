/* global $ */

(function(){

'use strict';

angular
  .module('pulse')
  .directive('player', pulsePlayer);

  pulsePlayer.$inject = ['$log', '$signalProvider']
  function pulsePlayer($log, $signalProvider) {

      var directive = {
        templateUrl: 'views/common/player/player.html',
        restrict: 'E',
        //transclude: true,
        replace: true,
        scope: {
          clips: '=', //List of clips to be played with player.

          playOnLoad: '=', //Plays the video on load

          user: '=', //We use the name for watermarking.
          arrows: '=', //Arrows for next and previous videos within playlist
          playlist: '=', //Takes a function for callback from click on playlist item

          comments: '=' //TODO: This needs a lot more work. we need to consider the anotations and also the carsousel data.

        },
        controller: Controller,
        link: linkFunc

      };

      return directive;

      function linkFunc(scope, element) {

        scope.mousePosition = false;
        element.on('touchstart', function() {});

        element.on('mouseenter', function() {
          scope.mousePosition = true;
        });

        element.on('mouseleave', function() {
          scope.mousePosition = false;
        });

        var key = {
          one: 49, //Back X2
          two: 50, //Forward X2
          three: 51, //back X1
          four: 52, //Forward X1
          five: 53, //Play

          j: 74, //Step back
          k: 75, // pause
          l: 76, //Step forward

          left: 37, //last clip
          up: 38,
          right: 39, // Next clip
          down: 40,

          space: 32 //toggle

        };

        var playerHotKeys = function(evt, e) {

          $log.log('Disble Key events ' + scope.disableKeyevents);
          if (e.target.tagName !== 'INPUT' && e.target.tagName !== 'TEXTAREA' && typeof scope.player !== 'undefined' && !scope.disableKeyevents) {

            var keycode = e.keyCode || e.which;

            switch (keycode) {
              case key.one:

                scope.rewind();

                break;
              case key.two:

                scope.forward();

                break;
              case key.three:

                scope.play();

                break;
              // case key.four:
              //
              //   scope.forward();
              //
              //   break;
              // case key.five:
              //
              //   scope.play();
              //
              //   break;
              case key.k:

                scope.pause(true);

                break;
              case key.j:

                scope.stepBackward(1);

                break;
              case key.l:

                scope.stepForward(1);

                break;
              case key.left:

                scope.stepBackward(1);
                //scope.previousVideo();

                break;
              case key.right:

                scope.stepForward(1);
                //scope.nextVideo(true);

                break;
              case key.up:

                scope.stepForward(10);

                break;
              case key.down:

                scope.stepBackward(10);

                break;
              case key.space:

                scope.togglePlay('keyboard', e);

                break;
              default:
                return;
            }
          }
        };

        var keyDownListener = $signalProvider.listen('keydown', playerHotKeys);

        scope.$on('$destroy', function() {
          $log.log('Player $destroy KEYS');
          $signalProvider.unlisten('keydown', keyDownListener);
        });
      }

    }

    Controller.$inject = ['$scope', '$element', '$rootScope', '$log', '$document', '$window', '$stateParams', '$timeout', '$interval', '$animate', '$signalProvider', '$filter', '$q', '$state', '$exceptionHandler', 'Clips']
    function Controller($scope, $element , $rootScope, $log, $document, $window, $stateParams, $timeout, $interval, $animate, $signalProvider, $filter, $q, $state, $exceptionHandler, Clips) {

      var speed = 1; //Set the speed of the playback
      var stopProgressBar; //Global to stop the process bar from updating.
      $scope.renderElement = $element; //Grab the element, just so we can make sure it is being removed later. TODO: Probably not needed.
      $scope.disableKeyevents = false; // We disable key events when inputs and textareas are focused.
      $scope.playerData = {}; //Need to store a bunch of attributes for the player

      function setSpeed() {

        //Sometimes angular doesn find the player?
        var videoElement = $element.find('video')[0];
        if (videoElement) {
          videoElement.playbackRate = speed;
          if (speed >= 1) {
            $scope.speed = speed;
          } else {
            $scope.speed = '1/' + (1 / speed);
          }
        } else {
          $scope.speed = speed;
        }

      }

      function isPlayer(){
        if(typeof $scope.player === 'object' && $scope.player.hasOwnProperty('play')){
          return true;
        }else{
          return false;
        }
      }

      $scope.selectVideo = function(lastClipInPlaylist) {

        //If for some reason there is no clip from the current clip, Return false *
        // we need to be aware of currentClip this global var could break the flow.
        /// Im not sure why the "lastClipInPlaylist" arg is not being used??
        var clip = $scope.clips[$scope.currentClip];
        if (!clip) {
          //TODO: This should throw errors. *
          ///NOTE: We now reset currentClip val
          //// to zero when we switch playlist.
          return;
        }

        $signalProvider.signal('player:selected_clip', $scope.clips[$scope.currentClip], true);
        //If we already have a player we just need to unload its current video.
        if (isPlayer()) {
          $scope.player.unload();
        } else {
          //TODO: This should throw errors
          return;
        }

        //Grab the video from the clip data
        var promise = Clips.getVideo({
          projectID: $stateParams.projectID,
          assetID: clip.assetID
        }).$promise;

        promise.then(function(data) {

          $log.log('Found Video');
          var source = {
            progressive: [{
              url: data.url,
              type: 'video/mp4'
            }]
          }

          //Reset all player data
          $scope.playerData.lastFrameCount = 0; //This is the last count for frames.
          $scope.playerData.framesTotal = 0; //total number of overall frames that can play
          $scope.playerData.frames = 0; //total number of frames that have been played
          $scope.playerData.framesDisplay = 0; //displays the fps count on the display.
          $scope.playerData.volume = 50;
          $scope.playerData.now = Date.now();
          $scope.playerData.cachedVolume = $scope.playerData.volume;
          $scope.playerData.volumeControl = false;
          $scope.playerData.timecode = '00:00:00:00'; //Just create a dummy timecode for the player, incase we dont get one back from the server.
          $scope.playerData.dropFrame = false; //Determines if the file we are playing is drop frame or not
          $scope.playerData.duration = 0; //The amount of time the player has to play a clip
          $scope.playerData.fps = 0; //The frames per second for a clip

          //Grab the video from the clip data
          var promise = Clips.getMetadataAll({
            projectID: $stateParams.projectID,
            assetID: clip.assetID
          }).$promise;
          promise.then(function(data) {

            $scope.videoMetadata = data; // cache this data for later

            //If for some reason we have a different fps
            if ($scope.videoMetadata.Framerate) {
              $scope.playerData.fps = $scope.videoMetadata.Framerate;
            } else {
              //TODO: This should throw errors
              return;
            }

            //Fetch comments for this video
            //TODO: this seems like a weird play to have this.
            if ($scope.comments) {
              getComments();
            }

            var clipMetadata = clip.metadata;
            if (clipMetadata && clipMetadata.Proxy_Duration) {
              if(clipMetadata.Timecode) {

                if(clipMetadata.Timecode.indexOf(';')!==-1) {
                  $scope.playerData.dropFrame = true;
                }

                var timecodeCheck = clipMetadata.Timecode.split(/[:;]/g);
                if(timecodeCheck.length>3) {
                  $scope.timecode = Timecode.init({framerate: $scope.playerData.fps, timecode: clipMetadata.Timecode, drop_frame:$scope.playerData.dropFrame});
                }else{
                  $scope.timecode = Timecode.init({framerate: $scope.playerData.fps, timecode: clipMetadata.Timecode + ":00", drop_frame:$scope.playerData.dropFrame});
                }
              }else{
                $scope.timecode = Timecode.init({framerate: $scope.playerData.fps, timecode: "00:00:00:00", drop_frame:$scope.playerData.dropFrame});
              }

              $scope.timecodeCopy = angular.copy($scope.timecode);
              $scope.playerData.timecode = $scope.timecode.toString(); //starting point

              $scope.playerData.duration = clipMetadata.Proxy_Duration;
              var durationInt = parseInt($scope.playerData.duration, 10);
              $scope.playerData.framesTotal = (durationInt * $scope.playerData.fps);

            }

            $scope.player.load(source);

            // If it is the last clip in the set and looping is off -- stop the set.
            if ($scope.loop === false && lastClipInPlaylist) {
              $scope.playerData.volume = 0;
              $scope.playing = false;
              return;
            }

            setSpeed();
            $scope.currentTime = 0;
            $scope.updateVolume();

            //Everything is set -- play the video.
            if ($scope.playing) {
              $scope.play();
            }else{
              $scope.first = true;
            }

          });
        });
      };

      //--- custom progress bar - setup progress bar to constantly increase, unless notified by any events to change ---

      $scope.getProgressBarInterval = function() {
        var progressBarInterval = 50; //miliseconds

        if ( ($scope.playerData.framesTotal /  $scope.playerData.fps) > 480 ) { //clips over 6 minutes get less frequent progress updates
          progressBarInterval = 200;
        }
        else if ( ($scope.playerData.framesTotal /  $scope.playerData.fps) > 60 ) { //clips over 3 minutes get less frequent progress updates
          progressBarInterval = 100;
        }

        $log.log('Progress Bar Interval Set To ', progressBarInterval);
        return progressBarInterval;
      }

      $scope.startProgressBar = function(delta, max) {
        if ($scope.playerData.frames >= max) {
          $scope.resetProgressBar();
        } else {
          $scope.stopProgressBar();
        }

        stopProgressBar = $interval(function() { //start the progress bar
          var nudgeFrames = $scope.playerData.frames + delta;
          if (nudgeFrames < max) {
            $scope.playerData.frames = nudgeFrames;
          } else {
            $scope.stopProgressBar();
            $log.log('frame over limit - stopping progress bar');
          }
        }, $scope.getProgressBarInterval());
      };

      $scope.stopProgressBar = function(resetToValue) {
        if (angular.isDefined(stopProgressBar)) {
          $interval.cancel(stopProgressBar);
          stopProgressBar = undefined;
        }

        if (resetToValue != undefined) {
          if (Math.round($scope.playerData.frames) != resetToValue) {
            $log.log('Reset frames from ', $scope.playerData.frames, ' to ', resetToValue);
            $scope.playerData.frames = resetToValue;
          }
        }
      };

      $scope.resetProgressBar = function() {
        $scope.stopProgressBar();
        $scope.playerData.frames = 0;
      };

      $scope.getProgrressBarDelta = function() {
        var frequency = 1000 / $scope.getProgressBarInterval(); //1 sec = 1000 ms
        return $scope.playerData.fps / frequency; // Math.round($scope.playerData.fps / frequency);
      }
      //--- end custom progress bar - setup progress bar to constantly increase, unless notified by any events to change ---

      function launchIntoFullscreen(element) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      }

      function exitFullscreen() {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }

      $scope.nextVideo = function(forced) {
        $log.log('nextVideo' , forced);
        var lastClip = $scope.currentClip;
        var lastClipInPlaylist = false;
        var stop = false;

        if ($scope.currentClip >= $scope.clips.length - 1) { //we've reached the end of a clips list

          if ($scope.currentClip === 0) { //only 1 clip in the clips list

            if ($scope.loop === true) { //loop the 1 clip we have
              $scope.player.seek(0);
              $scope.player.play();
            } else {
              stop = true;
            }
          } else { //more than one clip in clips list, and we have reached the clips list's end

            lastClipInPlaylist = true;
            if (forced) {
              lastClipInPlaylist = false;
            }

            if ($scope.loop === true) { //if looping is enabled, move to the first clip of the clips list. Else stay.
              $scope.currentClip = 0;
            } else {
              stop = true;
            }

          }

        } else {
          $scope.currentClip++;
        }

        if (stop && $scope.player) { //stop playback
          $scope.playing = false;
          if (!$scope.$$phase) {
            try {
              $scope.$digest();
            } catch (e) {}
          }
        }

        if (lastClip != $scope.currentClip) { //we are moving on to another clip - signal the app
          $scope.selectVideo(lastClipInPlaylist);

          //if there is a parent controller responsible for switching clips, notify it so we don't get out of sync.
          $scope.jumpComplete = true;

        }
      };

      $scope.previousVideo = function() {

        var lastClipInPlaylist = false;

        if ($scope.currentClip >= 1) {
          $scope.currentClip--;
        } else {
          $scope.currentClip = $scope.clips.length - 1;
        }

        $scope.selectVideo(lastClipInPlaylist);

        //if there is a parent controller responsible for switching clips, notify it so we don't get out of sync.
        $scope.jumpComplete = true;

      };

      $scope.play = function() {
        $log.log('Play');

        $interval.cancel($scope.intervalRewind);
        $scope.rewindTimes = 0;

        speed = 1;
        setSpeed();

        if (Math.ceil($scope.playerData.frames) >=$scope.playerData.framesTotal) {
          $scope.resetProgressBar();
          $log.log('frame at / over max - resetting progress bar');
        }
        else {
          $log.log('starting play - no progress reset');
          $log.log('frame at: ', $scope.playerData.frames , ' / ' , $scope.playerData.framesTotal);
        }

        $scope.player.play();
      };

      $scope.pause = function() {
        $log.log('Pause Video');

        $interval.cancel($scope.intervalRewind);
        $scope.rewindTimes = 0;

        speed = 1;
        setSpeed();

        $scope.player.pause();
      };

      $scope.togglePlay = function(type, e) {

        $log.log('*************Toggle Player*************');
        $log.log($scope.playing);
        $log.log(type);


        $interval.cancel($scope.intervalRewind);

        speed = 1;
        setSpeed();

        //find out what state we are in and toggle that
        if ($scope.playing === false) {
          $scope.player.play();
        } else {
          $scope.player.pause();
        }

        e.stopPropagation();
        e.preventDefault();
      };

      $scope.toggleLooping = function() {
        $scope.loop = !$scope.loop;
      };

      $scope.rewind = function(ready) {
        $log.log('Rewind');

        if (!$scope.playing && !ready && !$scope.rewindTimes) {
          $scope.updateSlider(false, $scope.rewind);
          return;
        } else {
          $scope.player.pause();
        }

        speed = 1;
        setSpeed();

        if ($scope.rewindTimes > 0) {
          $interval.cancel($scope.intervalRewind);
        }

        if (!$scope.rewindTimes) {
          $scope.rewindTimes = 1;
        } else {
          $scope.rewindTimes = $scope.rewindTimes * 2;
        }

        $scope.intervalRewind = $interval(function() {

          $log.log('Rewind interval');

          if ($scope.playerData.frames <= 0) {

            if ($scope.playerData.frames < 0) {
              $log.warn("Frames rewound below zero. Resetting from ", $scope.playerData.frames, ' to 0');
              $scope.playerData.frames = 0;
            }

            $interval.cancel($scope.intervalRewind);
            $scope.rewindTimes = 0;

          } else {

            //$scope.$apply(function () {
            $scope.currentTime = $scope.currentTime - ($scope.playerData.fps * $scope.rewindTimes);
            $scope.playerData.frames = $scope.playerData.frames - ($scope.playerData.fps * $scope.rewindTimes);
            //$scope.playerData.framesProgress = $scope.playerData.frames * 10;
            //$scope.playerData.framesDisplay = Math.round($scope.playerData.frames % Math.round($scope.playerData.fps));
            //});

            $scope.player.seek($scope.currentTime);
            $scope.updateSlider(true);

          }

        }, 400);
      };

      $scope.forward = function() {
        ////$log.log('forward');

        //If at end of clip, do not allow forward to get activated and jump to zero frame.
        if ($scope.playerData.frames >= $scope.playerData.framesTotal) {
          $log.warn('Fast Forward Not Allowed at Clip End');
          speed = 1;
          setSpeed();
          return;
        }

        $interval.cancel($scope.intervalRewind);
        $scope.rewindTimes = 0;

        if (speed === 1) {
          speed += 1;
        } else if (speed === 2) {
          speed = 3;
        } else {
          speed = speed * 2 - 1;
        }

        setSpeed();

        $scope.player.play();

      };

      $scope.stepBackward = function(step) {
        $log.log('step backward');

        $interval.cancel($scope.intervalRewind); // We need to cancel the rewind interval if it is running

        speed = 1;
        setSpeed();

        $scope.player.pause();

        if ($scope.loop !== false && Math.floor($scope.playerData.frames) === 0) {

          //This needs to go to the end of clip and then start going back frames from there
          $scope.currentTime = $scope.playerData.duration;
          $scope.playerData.frames = $scope.playerData.framesTotal;
          //$scope.playerData.framesProgress = $scope.playerData.frames * 10;
          $scope.updateSlider();

        } else if ($scope.playerData.frames !== 0) {

          //Step back
          $scope.currentTime = $scope.currentTime - (step / $scope.playerData.fps);
          $scope.playerData.frames = $scope.playerData.frames - step;
          //$scope.playerData.framesProgress = $scope.playerData.frames * 10;

          $scope.player.seek($scope.currentTime);

        }
      };

      $scope.stepForward = function(step) {
        $log.log('step forward');

        $interval.cancel($scope.intervalRewind); // We need to cancel the rewind interval if it is running

        speed = 1;
        setSpeed();

        $scope.player.pause();

        if ($scope.loop !== false && $scope.playerData.frames > $scope.playerData.framesTotal) {

          //This needs to go to the end of clip and then start going back frames from there
          $scope.currentTime = 0;
          $scope.playerData.frames = 0;
          //$scope.playerData.framesProgress = 0;
          $scope.updateSlider();

        } else if ($scope.playerData.frames !== $scope.playerData.framesTotal) {

          //Step forward

          $scope.currentTime = $scope.currentTime + (step / $scope.playerData.fps);
          $scope.playerData.frames = $scope.playerData.frames + step;
          //$scope.playerData.framesProgress = $scope.playerData.frames * 10;

          $scope.player.seek($scope.currentTime);

        }
      };

      $scope.destroyPlayer = function() {
        $log.log('++++++++++++++++++++++++ Destroying Player +++++++', $scope.player, isPlayer());

        //Just double check to see if the player even loaded
        if (isPlayer()) {
          $scope.player.pause();
          $scope.player.destroy();
        }

      };

      $scope.toggleFullscreen = function() {
        $scope.videoElement = document.getElementById('player');

        if ($scope.fullScreen) {
          exitFullscreen();
          $signalProvider.signal('player:exitFullScreen', {});
        } else {
          launchIntoFullscreen($scope.videoElement);

        }

      };

      $scope.toggleSound = function() {

        if ($scope.playerData.volume > 0) {
          $scope.playerData.cachedVolume = $scope.playerData.volume;
          $scope.playerData.volume = 0;
        } else {
          $scope.playerData.volume = $scope.playerData.cachedVolume;
        }

        $scope.player.setVolume($scope.playerData.volume);
      };

      $scope.toggleVolumeControl = function() {
        $scope.playerData.volumeControl = !$scope.playerData.volumeControl;
      };

      $scope.updateVolume = function() {
        $scope.player.setVolume($scope.playerData.volume);
      };

      $document.on('fullscreenchange webkitfullscreenchange mozfullscreenchange MSFullscreenChange', function() {

        $log.log('Fullscreen Change');
        if ($scope.fullScreen) {
          $scope.fullScreen = '';
          $signalProvider.signal('player:exitFullScreen', {});
        } else {
          $scope.fullScreen = 'fullScreen';
          $log.log('document on fullscreen change');
        }

        $scope.$apply();
      });

      $scope.updateSlider = function(noNudge, postExecute) {
        $log.log('Update Slider');

        $scope.currentTime = $scope.playerData.frames / $scope.playerData.fps;
        $scope.playerData.framesDisplay = Math.round($scope.playerData.frames % $scope.playerData.fps);

        if (!$scope.playing && !$scope.nudging && !noNudge) {
          nudge($scope.currentTime, true, postExecute);
        } else {
          $scope.player.seek($scope.currentTime);
        }

      };

      function logProgress() {
        var promise = Clips.videoProgress({
          projectID: $stateParams.projectID,
          assetID: $scope.clips[$scope.currentClip].assetID,
          frames: $scope.playerData.frames
        }).$promise;
        promise.then(function() {
          //$log.log('Paused Video Progress');
        });
      }

      function onStartBuffering(data) {
        $log.log('Player is Buffering');
        $log.log(data);
        //$scope.stopProgressBar();
      }

      function onStopBuffering(data) {
        $log.log('Player is DONE Buffering');
        $log.log(data);
        var delta = $scope.getProgrressBarDelta(); //each XYZ ms the frame # should change by this value
        //$scope.startProgressBar(delta, $scope.playerData.framesTotal);
      }

      function onReady(data) {
        $log.log('Player is Ready');
        $log.log(data);
      }

      function onPlay(data) {
        $log.log('Player is Playing');
        $log.log(data);

        //Prevent the player from nudging too far.
        if($scope.first) {
          $scope.player.pause();
          $scope.first = false;
          return false;
        }

        $scope.playing = true;
        if (!$scope.preventProgressUpdate) {
          var delta = $scope.getProgrressBarDelta(); //each XYZ ms the frame # should change by this value
          $scope.startProgressBar(delta, $scope.playerData.framesTotal);
        }
      }

      function onPause(data) {
        $log.log('Player is Paused');
        $log.log(data);
        $scope.playing = false;

        $scope.stopProgressBar();
      }

      function onSeek(data) {
        $log.log('Player is Seek');
        $log.log(data);

        $scope.stopProgressBar();

      }

      function onSeeked(data) {
        $log.log('Player is Seeked (Seek Done)');
        $log.log(data);

        if ($scope.playing && !$scope.preventProgressUpdate) {
          var delta =  $scope.getProgrressBarDelta(); //each XYZ ms the frame # should change by this value
          $scope.startProgressBar(delta, $scope.playerData.framesTotal);
        }
      }

      function onVolumeChange(data) {
        $log.log('Player is Volume Change');
        $log.log(data);
      }

      function onMute(data) {
        $log.log('Player is Muted');
        $log.log(data);
      }

      function onUnmute(data) {
        $log.log('Player is Unmuted');
        $log.log(data);
      }

      function onFullscreenEnter(data) {
        $log.log('Player is Fullscreen');
        $log.log(data);
      }

      function onFullscreenExit(data) {
        $log.log('Player is Exit Fullscreen');
        $log.log(data);
      }

      function onPlaybackFinished(data) {
        $log.log('Player is Paused');
        $log.log(data);

        speed=1;
        setSpeed();
        $scope.stopProgressBar($scope.playerData.framesTotal);
        $scope.nextVideo(null);

      }

      function onError(data) {
        $log.log('Player had an error');
        $log.log(data);
        $scope.error = true;
        $exceptionHandler( data );

        $scope.resetProgressBar();
      }

      function onTimeChanged(data) {
        $log.log('On time');
        $log.log(data);

        //Prevent an update if we are nudging
        if ($scope.preventProgressUpdate) {
          return;
        }

        //custom progress bar - if our internal logic somehow got out of sync with the actual progress, get the frame count back in sync with the actual player
        var nudgeFrames = data.time * $scope.playerData.fps;
        if (Math.abs(nudgeFrames - $scope.playerData.frames) > 30) {
          $log.warn('Syncing frames: ', $scope.playerData.frames, nudgeFrames);
          $scope.playerData.frames = nudgeFrames;
        }

        //Update frames and time.
        $scope.playerData.framesDisplay = Math.round(nudgeFrames % $scope.playerData.fps);
        $scope.currentTime = data.time;
        if (!$scope.$$phase) {
          try {
            $scope.$digest();
          } catch (e) { $log.warn("OnTimeChanged - Digest already in progress."); }
        }

        //We need to find the difference from our original time code and then add that to our master timecode for this clip.
        var currentFrame =  data.time * $scope.playerData.fps;
        var difference = currentFrame - $scope.playerData.lastFrameCount;
        $log.log('difference');
        $log.log(difference);
        $scope.playerData.lastFrameCount = currentFrame;
        $scope.timecode.add(difference);
        $scope.playerData.timecode = $scope.timecode.toString();

        //TODO: There might be a better way to do this by requesting the duration
        $signalProvider.signal('player:setTimecode', $scope.playerData.timecode);
        if (!$scope.$$phase) {
          try {
            $scope.$digest();
          } catch (e) { $log.warn("OnTimeChanged - Digest already in progress."); }
        }

      }

      function initPlayer() {

        var deferred = $q.defer();

        $log.log('Init Player');
        $scope.playing = false; //Angulars binding to tell if the player is playing.
        $scope.error = false; // Something wrong has happened, this flags the error dialog to show.
        $scope.ready = false; // when the player is considered ready to view.
        $scope.empty = true; // binds to the player html to display empty state
        $scope.currentClip = 0; // Sets the player to play the whatever clip
        $scope.currentTime = 0; // The amount of time since the player has played the clip
        $scope.rewindTimes = 0; //The number of times someone has tried to rewind the player. //TODO: This should probably be a limit of 8
        $scope.loop = false; //Makes the player just loops over all the clips
        $scope.first = true; //Prevent the player from nudging too far, this is just first init we have to stop the player hardcore.

        //This is importaant to because we subtract the last frame count by the current frame count to find the difference so we can add that difference to our timecode.
        $scope.playerData.lastFrameCount = 0; //This is the last count for frames.
        $scope.playerData.framesTotal = 0; //total number of overall frames that can play
        $scope.playerData.frames = 0; //total number of frames that have been played
        $scope.playerData.framesDisplay = 0; //displays the fps count on the display.
        $scope.playerData.volume = 50; //Base volume for the player
        $scope.playerData.cachedVolume = $scope.playerData.volume; //TODO: since we are toggling the volume slider now we probably dont need this
        $scope.playerData.volumeControl = false;
        $scope.playerData.now = Date.now(); //Displays the time with the user name
        $scope.playerData.timecode = '00:00:00:00'; //Just create a dummy timecode for the player, incase we dont get one back from the server.
        $scope.playerData.dropFrame = false; //Determines if the file we are playing is drop frame or not
        $scope.playerData.duration = 0; //The amount of time the player has to play a clip
        $scope.playerData.fps = 0; //The frames per second for a clip

        //If there is no clips at all -- just return ready.
        if (!$scope.clips || !$scope.clips.length) {
          $log.log('No clips', $scope);
          $scope.ready = true;
          return deferred.promise;
        }

        $scope.empty = false; //display empty state.

        //Grab the video from the clip data
        var promise = Clips.getVideo({
          projectID: $stateParams.projectID,
          assetID: $scope.clips[$scope.currentClip].assetID
        }).$promise;

        promise.then(function(data) {

          //Player Options
          var opts = {
            key: $rootScope.config.videoKey,
            source: {
              progressive: [{
                url: data.url,
                type: 'video/mp4'
              }]
            },

            style: {
              controls: false,
              height: '100%',
              width: '100%',
              bufferingOverlay: false,
              playOverlay: false,
              keyboard: false,
              mouse: false
            },

            skin: {
              screenLogoImage: ""
            },

            playback: {
              autoplay: true,
              muted: false
            },

            events: {
              onReady: onReady,
              onPlay: onPlay,
              onPause: onPause,
              onError: onError,
              onTimeChanged: onTimeChanged,
              onSeek: onSeek,
              onSeeked: onSeeked,
              onStartBuffering: onStartBuffering,
              onStopBuffering: onStopBuffering,
              onVolumeChange: onVolumeChange,
              onMute: onMute,
              onUnmute: onUnmute,
              onFullscreenEnter: onFullscreenEnter,
              onFullscreenExit: onFullscreenExit,
              onPlaybackFinished: onPlaybackFinished
            }

          };

          //Incase we want to auto play on start.
          if($scope.playOnLoad) {
            $scope.first = false // Remove the prevention from nudging and just let the video player play.
          }

          //Grab the video metadata from the clip
          var promise = Clips.getMetadataAll({
            projectID: $stateParams.projectID,
            assetID: $scope.clips[$scope.currentClip].assetID
          }).$promise;

          promise.then(function(data) {

            $scope.videoMetadata = data; // cache this data for later

            //Setup Framerate if we get any.
            if ($scope.videoMetadata.Framerate) {
              $scope.playerData.fps = $scope.videoMetadata.Framerate;
            } else {
              //TODO: This should throw errors
              return;
            }

            //Fetch comments for this video
            //TODO: this seems like a weird play to have this.
            if ($scope.comments) {
              getComments();
            }

            //Grab video duration from the proxy before we load the clip
            //TODO: If for some reason we dont have duration we need to setup a message to play the video first for the duration
            var clipMetdata = $scope.clips[$scope.currentClip].metadata;
            if (clipMetdata && clipMetdata.Proxy_Duration) {

              if(clipMetdata.Timecode) {
                if(clipMetdata.Timecode.indexOf(';')!==-1) {
                  $scope.playerData.dropFrame = true;
                }
                var timecodeCheck = clipMetdata.Timecode.split(/[:;]/g);
                if(timecodeCheck.length>3) {
                  $scope.timecode = Timecode.init({framerate: $scope.playerData.fps, timecode: clipMetdata.Timecode, drop_frame:$scope.playerData.dropFrame});
                }else{
                  $scope.timecode = Timecode.init({framerate: $scope.playerData.fps, timecode: clipMetdata.Timecode + ";00", drop_frame:$scope.playerData.dropFrame});
                }
              }else{
                $scope.timecode = Timecode.init({framerate: $scope.playerData.fps, timecode: "00:00:00:00", drop_frame:$scope.playerData.dropFrame});
              }

              $scope.timecodeCopy = angular.copy($scope.timecode); // We need to copy this so we can always come back to the original if you need to.
              $scope.playerData.timecode = $scope.timecode.toString(); //starting point

              $scope.playerData.duration = clipMetdata.Proxy_Duration;
              var durationInt = parseInt($scope.playerData.duration, 10); //Get the int for duration
              $scope.playerData.framesTotal = (durationInt * $scope.playerData.fps ); // + timecodeToFrames($scope.originalTimecode, Math.round($scope.playerData.fps) );
            }

            //Load Bitdash player
            $scope.player = $window.window.bitdash('videoPlayer');
            $log.log('TYPE isPLayer ++++++', typeof $scope.player);

            if(typeof $scope.player === 'object' && $scope.player.hasOwnProperty('setup')){

                $scope.player.setup(opts).then(function(value) {
                  // Success
                  $log.log('Successfully created bitdash player instance');

                  // // Let other things know this is the clip that is going to play
                  $signalProvider.signal('player:selected_clip', $scope.clips[$scope.currentClip], true);

                  //Notify any other controllers that the player is ready
                  $signalProvider.signal('player:ready', true, true);
                  $scope.ready = true; //Tell this scope that the player is ready.

                  deferred.resolve($scope.videoMetadata);

                }, function(reason) {
                  $log.log('Error while creating bitdash player instance');
                  $scope.error = true;
                  $exceptionHandler( reason );

                });
              }
                return deferred.promise;

              });



          // deferred.reject('We never finished ');
          //
          // return deferred.promise;

        });
        //
        // return deferred.promise;
      }

      var nudge = debounce(function(to, playRequired, postExecuteFunc) {
          $log.warn('nudging');
          var args = arguments;
          $scope.preventProgressUpdate = true;
          $scope.nudging = true;

          if (playRequired && !$scope.playing) {
            $scope.player.play();
          }

          $timeout(function() {
              $scope.pause();
              $timeout(function() {
                $scope.player.seek(to);
                $scope.preventProgressUpdate = false;
                $scope.nudging = false;
                if (!!postExecuteFunc) {
                  postExecuteFunc.apply(null,[true]);
                }
              });
          });
        }, this);

      function debounce (func, context, threshold) {
          var timeout;

          return function debounced () {
              var obj = context, args = arguments;
              function delayed () {
                $log.log('player executed delayed func', func, 'on', context);
                func.apply(obj, args);
                timeout = null;
              };

              if (timeout) {
                clearTimeout(timeout);
              }

              threshold =threshold || 500;
              timeout = setTimeout(delayed, threshold);
              $log.log('player debounced func', func, 'on', context, 'Threshold set at', threshold);
          };
      }

      function clipsListChanged(val) {

        if (val === undefined) {
          return;
        }

        $log.log('no player+++++', $scope.player, $scope.player === undefined);
        //If no player make one
        if (isPlayer()) {
          $scope.currentClip = 0; // since currentClip is a global var we
          // must reset the val back to zero
          $scope.selectVideo(); // Else just select the first video
        }else{
          initPlayer();
        }

      }

      $scope.$watchCollection('clips', clipsListChanged); // Signal when the clips list is ready

      function pause(name, value) {
        if ($scope.player && typeof $scope.player.pause === 'function') { //Check to see the player is actually there before doing anything.
          //$scope.disableKeyevents = value;
          $scope.pause();
        }
      }

      function play(name, value) {
        if ($scope.player && typeof $scope.player.player === 'function') { //Check to see the player is actually there before doing anything.
          $scope.disableKeyevents = false;
          $scope.play();
        }
      }

      function setTimecode(name, timecode) {

        var timecode = Timecode.init({framerate: $scope.playerData.fps, timecode: timecode, drop_frame:$scope.playerData.dropFrame});
        var seconds = (timecode.frame_count - $scope.timecodeCopy.frame_count) / $scope.playerData.fps;

        if (!$scope.playing) {
          $scope.play();
        }

        $timeout(function() {
          $scope.player.seek(seconds);
          $scope.pause();
        })
      }

      function disableKeyboard(name, value) {
        $scope.disableKeyevents = value;
      }

      function setComments(name, comments) {
        for (var n=0; n< comments.length; n++) {
          var timecode = Timecode.init({framerate: $scope.playerData.fps, timecode: comments[n].timecode, drop_frame:$scope.playerData.dropFrame});
          comments[n].position = (timecode.frame_count - $scope.timecodeCopy.frame_count); //relative to our max which is total frames of the actual clip
        }
        $scope.playerData.comments = comments; // This turns on and off the comments events on the time line
      }

      function getComments() {
            var comments = Clips.getComments({projectID: $stateParams.projectID, assetID: $scope.clips[$scope.currentClip].assetID}).$promise;
            comments.then(function (data) {
                setComments(null, data);
            });
        }

      function reportTimecode() {
        $signalProvider.signal('player:setTimecode', $scope.playerData.timecode);
      }

      function selectVideo(name, value) {
        $log.log('selectVideo', name , value);
        $scope.currentClip = value.index;
        $scope.selectVideo();
      }

      var videoPlayer_pause = $signalProvider.listen("videoPlayer_pause", pause);
      var videoPlayer_play = $signalProvider.listen("videoPlayer_play", play);
      var videoPlayer_setTimecode = $signalProvider.listen("videoPlayer_setTimecode", setTimecode);
      var videoPlayer_disableKeyboard = $signalProvider.listen("videoPlayer_disableKeyboard", disableKeyboard);
      var videoPlayer_setComments = $signalProvider.listen("videoPlayer_setComments", setComments);
      var videoPlayer_reportTimeCode = $signalProvider.listen("player:reportTimecode", reportTimecode);
      var videoPlayer_selectVideo = $signalProvider.listen("horizontal_scroll:select_item", selectVideo);

      $scope.$on('$destroy', function() {
        $scope.destroyPlayer();
        $($scope.renderElement).remove(); // Just making sure this element is removed.
        $log.info('Destroy Video Player Controller');
        $signalProvider.unlisten("videoPlayer_pause", videoPlayer_pause);
        $signalProvider.unlisten("videoPlayer_play", videoPlayer_play);
        $signalProvider.unlisten("videoPlayer_setTimecode", videoPlayer_setTimecode);
        $signalProvider.unlisten("videoPlayer_disableKeyboard", videoPlayer_disableKeyboard);
        $signalProvider.unlisten("videoPlayer_setComments", videoPlayer_setComments);
        $signalProvider.unlisten("player:reportTimecode", videoPlayer_reportTimeCode);
        $signalProvider.unlisten("horizontal_scroll:select_item", videoPlayer_selectVideo);
      });

    }

})();
