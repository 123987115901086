'use strict';

angular.module('pulse')
    .filter('timecode', function () {
        function digits(n) {
            if (n<10) {
                return '0'+n;
            }
            return n;
        }
        return function (secs, fps) {
            var terms=[0, 0, 0, 0, 0];
            var vals;
            var prefix='';

            if (!secs) {
                return '';
            }
            if (!fps) {
                return secs+' s';
            }
            fps=parseInt(fps+0.1, 10);
            vals=[24*60*60*fps, 60*60*fps, 60*fps, fps, 1];
            var frames=secs*fps;
            for (var i=0; i<vals.length; i++) {
                terms[i]=Math.floor(frames/vals[i]);
                frames-=terms[i]*vals[i];
            }
            if (terms[0]>0) {
                prefix=digits(terms[0])+':';
            }
            return prefix+digits(terms[1])+':'+digits(terms[2])+':'+digits(terms[3])+':'+digits(terms[4]);
        };
    });
