/**
 * @ngdoc directive
 * @name pulseLocalDropdownSearch
 * @module pulse
 * @restrict E
 * @description
 * `pulseLocalDropdownSearch` provides a searchbar for searching a list
 *
 * template attr is required
 *
 * @usage
 * <hljs lang="html">
        <pulse-search
            template = "views/templates/manager_search.html"
            placeholder = 'Search Pipelines'
            metadataSet = '['name','cameraRoll']'
            init = "{'service' : 'Pipelines',
                    'method' : 'filterPipelines',
                    'params' : false,
                    'context_key' : 'pipelines_list',
            }">
        </pulse-search>
 * </hljs>
 *
 *
 * @param {string} query - (optional) This pre-populates the directive with a specific query
 * @param {string} template - (optional) You can change whatever template you want with this
 *
 */
(function() {
    'use strict';

    angular
        .module('pulse')
        .directive('pulseLocalDropdownSearch', directive);

    directive.$inject = ['$log', '$window', '$timeout'];

    /* @ngInject */
    function directive($log, $window, $timeout) {
        var directive = {
            restrict: 'EA',
            templateUrl: function(el, attr){
              if(attr.template !== undefined){
                return attr.template;
              }else{
                return '<h1>error loading searchbar : missing template</h1>';
              }
            },
            scope: {
              query : '@?',
              placeholder: '@',
              metadataSet: '=',
              init : '='
            },
            link: linkFunc,
            controller: Controller
        };

        return directive;

        function linkFunc(scope, element, attr, ctrl) {

          scope.dropdown = false;
          var input = element.find('input');

          //THESE CLASS NAMES ARE IMPORTANT!! IF THEY ARE CHANGED THE DROPDOWN WILL CLOSE AFTER CLICKING!
          var search_window = angular.element($window);
          search_window.bind('click', function (event) {

            if (event.target !== element[0].querySelector('.dropdown')
              && event.target !== input[0]
              && !event.target.classList.contains('dropdown__item')) {
              $timeout(function () {
                scope.dropdown = false;
              }, 100);
            }

          });

          // this blurs the selected element and closes the dropdown on keypress enter, we can add more on the future if needed
          scope.blurOnKeypress = function (event) {
            var keyCode = event.keyCode || event.which;
            if (keyCode === '13') { // Enter Key code
              var target = event.target;
              target.blur();
              scope.dropdown = false;
            }
          };

          scope.submitQueryOnBlur = function(event) {
             $log.log('checkQueryOnBlur', event);
             if(scope.query === '') {
               scope.vm.search_method(scope.init.context_key, scope.query);
             }
          };

          scope.selectMetadata = function (metadata) {

            $log.log('selectMetadata', metadata);
            scope.query = scope.query + ' ' + metadata + ':';
            $log.log('model', scope.query);
            input.focus();

          };

          scope.toggleDropdown = function () {
              $timeout(function () {
                input.blur();
                scope.dropdown = false;
              }, 100);
          }

        }
    }

    Controller.$inject = ['$scope', '$log', '$timeout', '$window', 'ServiceManager'];

    /* @ngInject */
    function Controller($scope, $log, $timeout, $window, ServiceManager) {
      $log.log('pulseLocalDropdownSearch directive');

      //Virtual modal allows us to pass things to the template
      $scope.vm = {
        service : ServiceManager,
        search : search,
        clear : clear,
        clearText : clearText,
        init : init
      };

      // creates the virtual model
      if($scope.init){
        $scope.vm.init();
      }

      function clear(event){
        $log.log('pulseLocalDropdownSearch clear', $scope.query);
        event.stopPropagation();
        $scope.toggleDropdown();
        $scope.query = "";
        $scope.vm.search_method($scope.init.context_key, $scope.query);
      }

      function clearText(event){
        $log.log('pulseLocalDropdownSearch clearText', $scope.query);
        $scope.query = "";
      }

      function search(event){
        $log.log('pulseLocalDropdownSearch search', $scope.query);
        event.stopPropagation();
        $scope.toggleDropdown();
        $scope.vm.search_method($scope.init.context_key, $scope.query);
      }

      function init(){

         // bind the search method
        $scope.vm.search_method = $scope.vm.service[$scope.init.service][$scope.init.method];

        //If we have the query preset lets use it otherwise lets make it blank
        if(!$scope.query) {
          $scope.query = '';
        }

        // bind the search query property
        //$scope.vm.service[$scope.init.service][$scope.init.context_key].query = $scope.query;

      }

    }
})();
