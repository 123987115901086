/**
 * @ngdoc object
 * @name Files
 * @requires $resource
 */

(function(){

    'use strict';

    angular
        .module('pulse')
        .factory('Files', Files);

    Files.$inject = ['$resource'];

    function Files ($resource) {

        return $resource(
            config.baseURL +'/filemanager/:projectID/:ext/:assetID/:action',
            {projectID: '@projectID', ext: '@ext', assetID: '@assetID'},
            {
                getFiles: {method:'GET', params: {ext:'files'}, isArray: true},
                getFile: {method:'GET', params: {ext:'files'}},
                deleteAsset:{method:'DELETE', params: {ext:'files'}, isArray: true},
                getAccess: {method:'GET', params: {ext:'files', action:'access'}, isArray: true},
                getAssetUrl: {method:'GET', params: {ext:'files', action:'url'}}, // pass project and asset
                getFileCount: {method:'GET', params: {ext:'files', 'countOnly': true}}, // pass project
                releaseFiles: {method:'POST', params: {ext:'files', action:'release'}}, //pass project,
                getLog: {method:'GET', params: {ext:'files', action:'log'}, isArray: true}, //pass project,
                getAssetLocation: {method:'GET', params: {action:'locations', ext:'files'}, isArray:true}, //pass project,
                getSearchMetadata: {method:'GET', params: {action:'searchData', 'context': 'files'}}, // pass project
                searchFilesCount: {method:'GET', params: {action:'search', 'ext': 'files', 'countOnly':true}}, // pass project
                searchFiles: {method:'GET', params: {action:'search', 'ext': 'files'}, isArray: true}, // pass project

                getSmartSearches: {method:'GET', params: {action:'searches'}, isArray: true},// pass project

                saveMetadata: {method: 'POST', params: {ext: 'files', action:'metadata'}},

                getMetadata: {method: 'GET', params: {ext: 'files', action: 'metadata'}},
                getMetadataAll: {method: 'GET', params: {ext: 'files', action: 'allmetadata'}, isArray: true},

                getAvailableExtensions: {method: 'GET', isArray: true }
            }
        );
    }
})();
