/**
 * @ngdoc service
 * @name pulseBeforeUnload
 * @module pulse
 * @description
 * `pulseBeforeUnload` all events happening on unload.
 *
 * @usage
 *
 */

//TODO: Fill in the docJS for this
//TODO: remove rootScope once we create pulse Storage

(function() {
    'use strict';

    angular
        .module('pulse')
        .service('pulseBeforeUnload', Service);

    Service.$inject = ['$rootScope', 'pulseGlobalState', '$log'];

    /* @ngInject */
    function Service($rootScope, pulseGlobalState, $log) {

        var service = {
            onbeforeunload : onbeforeunload
        };

        function onbeforeunload(event) {

            $log.log('Before Unload:');
            $log.log('Event: '+ event);

            var app = $rootScope.application_name;

            //TODO: what is this?
            if($rootScope.downloading) {
                return;
            }

            //This is set whenever we want to NOT cache anything from frontend
            // The main purpose of this is to NOT cache data when we are loging out
            if(!$rootScope.nocache) {

                if(pulseGlobalState.rootToState) {
                    var rootToState = JSON.stringify(pulseGlobalState.rootToState);
                    localStorage.setItem(app +'toState',rootToState);
                }

                if(pulseGlobalState.rootToParams) {
                    var rootToParams = JSON.stringify(pulseGlobalState.rootToParams);
                    localStorage.setItem('toParams',rootToParams);
                    localStorage.setItem(app +'toParams',rootToParams); // We are saving this because we might need extra data that we dont get from the other application
                }

                //TODO: This is temp -- we currently arent gonna save left over unorganized files
                $rootScope.storage.uploads = [];
                $rootScope.storage.projects = [];

                var storage = JSON.stringify($rootScope.storage);
                localStorage.setItem('storage',storage);
            }

            //Warn the user that they have changed data, letting them know they might want to save before they leave
            if($rootScope.dirty) {
                return 'Are you sure you want to leave without saving your changes?';
            }

            // if downloads arent done warn user.
            if(!$rootScope.uploading.done) {
                return 'You are currently uploading files, leaving will NOT upload your files.';
            }

        }

        return service;
    }
})();
