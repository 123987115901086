/**
 * @ngdoc filter
 * @name decodeTokenizedText
 * @module pulse
 * @description
 * `decodeTokenizedText` decodes text from metadata that are tokenized because of illegal characters.
 *
 * Example: test--period--test is equal to test.test
 *
 * @usage
 * <div>{{testText | decodeTokenizedText}}</div>
 *
 */

'use strict';

angular.module('pulse')
    .filter('decodeTokenizedText',
    ['$log',
    function($log) {
        return function(string) {
          var new_string = string.replace('--period--', '.');
          return new_string;
        }
    }]);
