/**
 * @ngdoc object
 * @name RightsSvc
 * @requires $resource
 */

(function(){

    'use strict';

    angular
        .module('pulse')
        .factory('RightsSvc', RightsSvc);

    RightsSvc.$inject = ['$resource'];

    function RightsSvc ($resource) {

        return $resource(
            config.baseURL + '/organizations/:organizationID/rights/:rightID/:ext',
            { organizationID: '@organizationID', ext: '@ext', rightID: '@rightID' },
            {
                getList: {method:'GET', isArray: true},
                getRoles: {method:'GET', params:{ext: 'roles'}, isArray: true}
            }
        );
    }
})();