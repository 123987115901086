/**
 * @ngdoc object
 * @name SettingsDrawerCtrl
 * @requires $scope, $state, $stateParams
 */

'use strict';

angular.module('pulse')
    .controller('SettingsDrawerV2Ctrl',
    ['$scope', '$log', '$signalProvider', '$modalInstance', '$state', '$stateParams', '$timeout', '$rootScope', 'modalManager',
        function ($scope, $log, $signalProvider, $modalInstance, $state, $stateParams, $timeout, $rootScope, modalManager) {

            $log.info('Settings Drawer V2 Controller');

            //TODO: clean this up -- this will cause conflicts between other views.
            $scope.$state = $state;
            $scope.$stateParams = $stateParams;

            $scope.view = 'body';

            $scope.settings = {};
            $scope.settings.baseRoute = $state.current.name.substring(0, $state.current.name.lastIndexOf('.settings'));
            $scope.settings.goToServers = function(){
              $stateParams.dummyID = Math.random();
              $state.go($scope.settings.baseRoute + '.settings.types.servers', $stateParams, {reloadOnSearch: true});
            };

            $scope.settings.goToGroups = function(){
              $stateParams.dummyID = Math.random();
              $state.go($scope.settings.baseRoute + '.settings.types.groups', $stateParams, {reloadOnSearch: true});
            };

            function drawerClose(name,value) {
              $modalInstance.close();
            }

            $scope.close = function() {

              if($rootScope.dirty) {

                  $log.log('Dirty Modal');
                  var confirm = modalManager.openModal({
                      templateUrl: 'views/common/modals/unsaved.html',
                      controller: 'ConfirmModalCtrl',
                      windowClass: 'confirm',
                      resolve: {
                          title: function () {
                              return 'There are unsaved changes.';
                          },
                          body: function () {
                              //This can accept html
                              return "Are you sure you want to leave without saving your changes?";
                          },
                          button: function () {
                              return 'Continue';
                          }
                      }
                  });
                  //Remove the modal from the dom
                  confirm.result.then(function() {
                      $rootScope.dirty = false;
                      $modalInstance.close();
                  });
              }else{
                $modalInstance.close();
              }

            }

            //listen for when to search
            var drawerClose = $signalProvider.listen('drawerClose', drawerClose);
            //Destroy the function on search
            $scope.$on('$destroy', function(){
                $log.info('Destroy Settings Drawer Controller');
                $signalProvider.unlisten('drawerClose',drawerClose);
            });
        }]);
