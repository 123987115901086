/**
 * @ngdoc object
 * @name Configure
 * @requires $httpProvider, $idleProvider, $keepaliveProvider, $provide, $compileProvider
 * @description This file configures the application. Modifying http requests, idle providers, disabling debugging.
 */

(function() {
    'use strict';

    angular
        .module('pulse')
        .config(configure);

    configure.$inject = ['$httpProvider', '$provide', '$compileProvider', '$qProvider'];

    /* @ngInject */
    function configure($httpProvider, $provide, $compileProvider, $qProvider) {

        // This is needed to pass over the Cookie credentials
        $httpProvider.defaults.withCredentials = true;
        $httpProvider.defaults.xsrfHeaderName = 'X-CSRF-Token';

        //Disables debug, uncessary refferences to scopes/binds within the DOM
        //boolean true or false
        $compileProvider.debugInfoEnabled(config.disableDebugging);

        //TODO: This is really just masking an issue
        //We need to solve this by rewriting the drawers OR figuring this out here
        //https://github.com/angular-ui/bootstrap/issues/6501
        $qProvider.errorOnUnhandledRejections(false);

        //Disables $log from displaying on console
        if(config.disableJSLogging){
            $provide.decorator('$log', ['$delegate', function ($delegate) {
                $delegate.table = angular.noop;
                $delegate.log = angular.noop;
                $delegate.debug = angular.noop;
                $delegate.info = angular.noop;
                $delegate.warn = angular.noop;
                $delegate.error = angular.noop;
                return $delegate;
            }]);
        }

    }

})();

