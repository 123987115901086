/**
 * @ngdoc directive
 * @name pulseTableHeight
 * @module pulse
 * @restrict A
 * @description
 * `pulseTableHeight` provides the correct scrollable solution for table scrolling with thead.
 *
 * @usage
 * <hljs lang="html">
 *     <table pulse-table-height offset='10' table-key="table_height">
 *         <thead>
 *            <tr><td></td></tr>
 *         </thead>
 *         <tbody class="scroll" ng-style="{'height':vm.table_height}">
 *            <tr><td></td></tr>
 *         </tbody>
 *     </table>
 * </hljs>
 *
 * @param {int} offset - this is an int and it subtracts from the parent height
 * @param {string} tableKey - this is a string to change they key on the parent.vm in case we have multiple tables. so each table use a different height
 *
 */
(function(){
'use strict';
angular
    .module('pulse')
    .directive('pulseTableHeight', pulseTableHeight);

    pulseTableHeight.$inject = ['$log', '$window'];

    function pulseTableHeight($log, $window) {
      var directive = {
        restrict: 'A',
        scope: {
          offset: '=',
          tableKey: '@'
        },
        link: linkFunc,
        controller: PulseTableHeightCrtl
      };

      return directive;

      function linkFunc(scope, elem, attr, ctrl) {
        //If we have a tableKey for some reason we can manually add it
        var tableKey = 'table_height';
        if(scope.tableKey) {
          tableKey = scope.tableKey;
        }

        //If we have an offset for some reason we can manually add it
        var offsetHeight = 0;
        if(scope.offset) {
          offsetHeight = scope.offset;
        }

        //TODO: we could probably find all children under the parent and automatically subtract those child from the table view.

        //Add the variable to the parent scope.
        function applyHeight() {
          // TODO: We needed to add the 10 because of when we have an horizontal scroll bar that throws off the calculation. We need to rethink this or find a better solution
          var height_adjustment = $window.innerHeight - offsetHeight - 10; // Get widow height;
          if (scope.$parent.hasOwnProperty('$ctrl')){
            scope.$parent.$ctrl.vm[tableKey] = height_adjustment;
          } else if (scope.$parent.hasOwnProperty('vm')){
            scope.$parent.vm[tableKey] = height_adjustment;
          } else {
            scope.$parent.vm[tableKey] = elem.parent()[0].offsetHeight - elem.children()[0].offsetHeight - offsetHeight - 10; // Get parent height;
          }
        }

        applyHeight();

        angular.element($window).bind('resize', function() {
          scope.safeApply(function() {
            applyHeight();
          });
        });

        //TODO: This should be a utility some where
        //We need to use this because at some point we might have digest running from polling and also resizing
        scope.safeApply = function(fn) {
          var phase = this.$root.$$phase;
          if(phase === '$apply' || phase === '$digest') {
            if(fn && (typeof(fn) === 'function')) {
              fn();
            }
          } else {
            this.$apply(fn);
          }
        };


      }

    }

    PulseTableHeightCrtl.$inject = ['$scope'];

    /* @ngInject */
    function PulseTableHeightCrtl($scope) {}

})();
