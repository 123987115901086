/**
 * @ngdoc object
 * @name PulseSearchFilterComponent
 * @requires $state
 * @description A resuable compent to add search filters to your search query
 */

(function(){
  'use strict';

  angular.module('pulse').component('pulseSearchFilters', {
    templateUrl : 'views/common/components/pulse_search_filters.html',
    bindings : {
        rowOne: '<',
        rowTwo: '<',
        rowThree: '<',
        rowFourAction: '<'
    },
    controller : Controller
  });

  Controller.$inject = ['$log', '$state', '$rootScope'];

  function Controller($log, $state, $rootScope){
    var ctrl = this;

    ctrl.vm = {
        'is_tier': ($rootScope.storage.project.fileManagerPoolType === 'CloudAzure') ? true : false
    };

  }
})();
