'use strict';

/**
 *
 */
angular.module('pulse')
  .filter('nDateOnly', function() {
    return function(input) {
        var dateObject = new Date(input);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = dateObject.getFullYear();
        var month = months[dateObject.getMonth()];
        var day = dateObject.getDate();
        return month+' '+day+' '+year;
    };
});
