'use strict';

angular.module('pulse')
.filter('dropExtension', function () {
    return function (input) {
        if (!input) {
            return input;
        }
        var ind;
        ind=input.lastIndexOf('.');
        if (ind!==-1) {
            input=input.substring(0,ind);
        }
        return input;
    };
});
