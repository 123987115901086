'use strict';

/**
 *
 */
angular.module('pulse')
  .filter('nDate', function() {
    return function(input) {
        var dateObject = new Date(input);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = dateObject.getFullYear();
        var month = months[dateObject.getMonth()];
        var date = dateObject.getDate();
        var hour = dateObject.getHours();
        var hours = (hour > 12) ? (hour-12) : hour;
        var min = dateObject.getMinutes();

        return ( month + ' ' + date + ', ' + year + ' at ' + hours + ':' + (min < 10 ? '0' + min : min) + (hour < 12 ? ' AM' : ' PM') );
    };
});
